






























/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import { namespace } from 'vuex-class';
import { AssetCountsMetrics, CrewCountsByDay, ProjectCrewPlatform } from '@/store/metrics/types';
import { format } from 'date-fns';

const metricsModule = namespace('metrics');

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Roboto';

@Component({ components: { LineChartGenerator } })
export default class CrewBurndown extends Vue {
  @metricsModule.State('crewCountsByDay') crewCountsByDay: CrewCountsByDay;

  @metricsModule.State('assetCountsMetrics') assetCountsMetrics: AssetCountsMetrics;

  @metricsModule.State('loadingCrewCountsByDay') loadingCrewCountsByDay: boolean;

  @PropSync('calculatedProjectedFinish') synchedCalculatedProjectedFinish: string;

  @Prop() readonly selectedPlatformCrew: ProjectCrewPlatform;

  get metricsData(): any {
    let retVal = {
      total: '0',
      collected: '0',
      addressed: '0',
      remaining: '0',
    };
    if (this.selectedPlatformCrew && this.crewCountsByDay) {
      const crewDaily = this.crewCountsByDay.crews.find(
        (c) => c.operator === this.selectedPlatformCrew.crewName,
      );

      const collectionCounts = crewDaily.counts.find(
        (count) => count.collectionType === this.selectedPlatformCrew.platform,
      );

      if (collectionCounts) {
        retVal = {
          total: collectionCounts.total.toFixed(1),
          collected: collectionCounts.collected.toFixed(1),
          addressed: collectionCounts.addressed.toFixed(1),
          remaining: collectionCounts.remaining.toFixed(1),
        };
      }
    }

    return retVal;
  }

  get chartData(): ChartData {
    if (!this.crewCountsByDay || this.crewCountsByDay.crews.length === 0) {
      return null;
    }

    const collectedData = this.getCollectedData(
      this.selectedPlatformCrew.platform, this.selectedPlatformCrew.crewName,
    );
    const dates = collectedData.map((data) => new Date(data.x).getTime());
    const minDate = new Date(Math.min(...dates));
    let startDate = Date.parse(this.selectedPlatformCrew.startDate);
    let endDate = Date.parse(this.selectedPlatformCrew.endDate);

    if (!startDate) {
      // If there is no end date, then use the first data point
      startDate = minDate.getTime();
    }

    if (!endDate) {
      const proj = this.projectedFinish;
      if (!proj) return null;
      endDate = this.projectedFinish.getTime();
    }

    const projFinish = this.projectedFinish;

    const projFinishTime = projFinish != null ? projFinish.getTime() : 0;

    const maxDate = new Date(Math.max(...dates, endDate, projFinishTime));

    if (!startDate || !maxDate) {
      return null;
    }

    const dateArr = this.getDates(startDate, maxDate);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const burndownData: any = [
      { x: format(new Date(startDate), 'yyyy-MM-dd'), y: this.totalFootage },
      { x: format(maxDate, 'yyyy-MM-dd'), y: 0 },
    ];
    const crewCollectedData = this.getCollectedData(
      this.selectedPlatformCrew.platform, this.selectedPlatformCrew.crewName,
    );

    return {
      labels: dateArr,
      datasets: [
        {
          label: 'Burndown',
          backgroundColor: '#CCCCCC',
          borderColor: '#d9d9d9',
          data: burndownData,
          pointRadius: 0,
          pointHitRadius: 0,
          borderWidth: 1.5,
        },
        {
          label: 'Remaining',
          data: crewCollectedData,
          fill: false,
          backgroundColor: '#0C6599',
          borderColor: '#0C6599',
          pointRadius: 3,
          borderWidth: 1.5,
        },
      ],
    };
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            align: 'center',
            text: 'Remaining',
          },
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      elements: {
        point: {
          radius: 0.1,
        },
      },
    };
  }

  get remainingPercent(): number {
    if (this.metricsData.total === '0') return 0;
    return Math.round((this.metricsData.remaining * 100) / this.metricsData.total);
  }

  get addressedPercent(): number {
    if (this.metricsData.total === '0') return 0;
    return Math.round((this.metricsData.addressed * 100) / this.metricsData.total);
  }

  get collectedPercent(): number {
    if (this.metricsData.total === '0') return 0;
    return Math.round((this.metricsData.collected * 100) / this.metricsData.total);
  }

  get totalFootage(): number {
    return this.metricsData.total;
  }

  get projectedFinish(): Date | undefined {
    const startDate = Date.parse(this.selectedPlatformCrew.startDate);
    let endDate = Date.parse(this.selectedPlatformCrew.endDate);
    const collectedData = this.getCollectedData(
      this.selectedPlatformCrew.platform, this.selectedPlatformCrew.crewName,
    );
    const dates = collectedData.map((data) => new Date(data.x).getTime());

    if (!endDate) {
      endDate = Math.max(...dates);
    }
    const maxDate = Math.max(...dates, endDate);

    if (!startDate || !maxDate || !this.crewCountsByDay || collectedData.length === 0) {
      return;
    }

    const startValue = Math.max(...collectedData.map((data) => data.y));
    const endValue = Math.min(...collectedData.map((data) => data.y));
    const completed = startValue - endValue;
    const daysPassed = (maxDate - startDate) / (1000 * 60 * 60 * 24);

    if (completed === 0 || daysPassed < 1) {
      return;
    }

    const avg = (completed) / (daysPassed);
    const projectedTime = Math.ceil(startValue / avg);
    const result = new Date(startDate);
    result.setDate(result.getDate() + projectedTime);

    this.synchedCalculatedProjectedFinish = format(result.getTime(), 'MMM dd yyyy');

    // eslint-disable-next-line consistent-return
    return result;
  }

  getCollectedData(platform: string, crew: string): any {
    const newArr = [];
    const overviewTotalCountData = this.getTotalCountData(platform, crew);
    const sortedData = Object.keys(overviewTotalCountData)
      .map((key) => ({ x: key, y: overviewTotalCountData[key] }))
      .sort((x, y) => new Date(x.x).getTime() - new Date(y.x).getTime());

    // eslint-disable-next-line no-return-assign
    sortedData.reduce((prev, curr, i) => newArr[i] = { x: curr.x, y: Math.max(prev.y - curr.y, 0) }, { x: '', y: this.totalFootage });
    return newArr;
  }

  getTotalCountData(platformName: string, crewName: string): any {
    const totalCountData = {};
    let platform = platformName;

    if (platformName.toLowerCase().includes('solo')) {
      platform = 'SOLO';
    }

    if (!this.crewCountsByDay) return totalCountData;

    this.crewCountsByDay.crews.forEach((crew) => {
      if (crew.operator === crewName) {
        crew.platforms.forEach((p) => {
          if (p.platformName === platform) {
            p.dailyCounts.forEach((count) => {
              const deploymentDate = format(new Date(count.deploymentDate), 'yyyy-MM-dd');
              if (!(deploymentDate in totalCountData)) {
                totalCountData[deploymentDate] = 0;
              }
              totalCountData[deploymentDate] += count.counts;
            });
          }
        });
      }
    });

    return totalCountData;
  }

  getDates(startTime: number, endDate: Date, steps = 1): any[] {
    const dateArray = [];
    const startDate = new Date(startTime);

    while (startDate <= new Date(endDate)) {
      dateArray.push(format(new Date(startDate), 'yyyy-MM-dd'));
      startDate.setUTCDate(startDate.getUTCDate() + steps);
    }

    return dateArray;
  }

  formatNumber(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
