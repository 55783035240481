






import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import Cropper from 'cropperjs';

@Component({})
export default class ImageCrop extends Vue {
  @Prop() image: string;

  @PropSync('croppedImageURL') synchedCroppedImageURL: string;

  cropper: Cropper;

  mounted(): void {
    const image = document.getElementById('image-to-crop') as HTMLImageElement;

    if (image) {
      this.cropper = new Cropper(image, {
        viewMode: 1,
        aspectRatio: 4 / 3,
        autoCropArea: 1,
        scalable: false,
        zoomable: false,
        movable: false,
      });
    }

    document.addEventListener('keydown', this.crop, false);
  }

  crop(): void {
    this.synchedCroppedImageURL = this.cropper.getCroppedCanvas().toDataURL();
    this.$emit('image-cropped');
  }
}
