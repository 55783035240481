/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationTree } from 'vuex';
import {
  RoutingDTONode, RoutingData, RoutingState, SimpleRoutingData,
} from './types';
// eslint-disable-next-line no-shadow
export enum RoutingMutations {
  SET_ROUTING_DATA = 'SET_ROUTING_DATA',
  SET_ROUTING_ERROR = 'SET_ROUTING_ERROR',
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_DELETE_ROUTING_DATA = 'SET_DELETE_ROUTING_DATA',
  SET_DELETE_ROUTING_ERROR = 'SET_DELETE_ROUTING_ERROR',
  SET_DELETE_ROUTING_LOADING = 'SET_DELETE_ROUTING_LOADING',
  SET_SIMPLE_ROUTING_DATA = 'SET_SIMPLE_ROUTING_DATA',
  SET_ROUTE_ASSETS = 'SET_ROUTE_ASSETS',
}

export const mutations : MutationTree<RoutingState> = {
  [RoutingMutations.SET_ROUTING_DATA](state, payload: RoutingData[]) {
    state.routingData = payload;
  },
  [RoutingMutations.SET_ROUTING_ERROR](state, payload: string) {
    state.routingError = payload;
  },
  [RoutingMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.routingLoading = payload;
  },
  [RoutingMutations.SET_DELETE_ROUTING_DATA](state, guids: string[]) {
    state.deleteRoutingData = guids;
  },
  [RoutingMutations.SET_DELETE_ROUTING_ERROR](state, payload: string) {
    state.deleteRoutingError = payload;
  },
  [RoutingMutations.SET_DELETE_ROUTING_LOADING](state, payload: boolean) {
    state.deleteRoutingLoading = payload;
  },
  [RoutingMutations.SET_SIMPLE_ROUTING_DATA](state, payload: SimpleRoutingData[]) {
    state.simpleRoutingData = payload;
  },
  [RoutingMutations.SET_ROUTE_ASSETS](state, payload: RoutingDTONode[]) {
    state.routeAssets = payload;
  },
};
