























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
})
export default class ReportFooter extends Vue {
 @Prop() readonly assetName!: string;

 @Prop() readonly street!: string;

 @Prop() readonly cityState!: string;

 @Prop() readonly pageNumber!: number;
}
