







































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import {
  Component, Emit, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { DefectPanTiltZoom } from '@/store/defectCoding/types';
import { AssetData, InspectionData, IPDEntry } from '../../store/asset/types';
import getMaxDepth from '../../views/Asset/utils';
import Clock from '../Clock/Clock.vue';
import ModelViewer from '../ModelViewer/ModelViewer.vue';
import Compass from '../Compass/Compass.vue';
import FlatViewer from '../FlatViewer/FlatViewer.vue';
import Video360 from '../Video360/Video360.vue';
import FlatVideo from '../FlatVideo/FlatVideo.vue';
import MsiVideo from '../MsiVideo/MsiVideo.vue';

@Component({
  components: {
    Clock,
    ModelViewer,
    Compass,
    FlatViewer,
    Video360,
    FlatVideo,
    MsiVideo,
  },
})
export default class Media extends Vue {
  @PropSync('syncedInspection') realInspection!: InspectionData;

  @PropSync('selectedVideoOption') syncSelectedVideoOption: string;

  @PropSync('curentVideoTime') curentVideoTimeSync: number | undefined;

  @PropSync('isWmSync') isWm: boolean;

  @Prop() readonly jestInspection: InspectionData;

  @Prop() readonly asset!: AssetData;

  @Prop() readonly displayImperial: boolean;

  @Prop({ default: 0.0 }) readonly payout: number;

  @Prop() readonly ipd: IPDEntry[];

  @Prop({ default: true }) readonly toggleHUD: boolean;

  @Prop({ default: false }) readonly isMSIView: boolean;

  @Prop() readonly panTiltZoom: DefectPanTiltZoom;

  cctv = false;

  tab = 0;

  angle = 0.0;

  loading = false;

  loadProgress = '0';

  videoPercentComplete = 0;

  flatPecrentComplete = 0;

  fullVideoTime = 0;

  currentVideoTime = 0;

  currentVideoTimeClone = 0;

  videoState = 0;

  clockTime = '06:00';

  dpp = 0.1;

  imageHeight = 1;

  canvasHeight = 1;

  viewRectHeight = 1;

  distanceBetweenTicks = 1;

  numberOfTicksDR = 10;

  measurementList: Array<number> = [];

  measurementUnit = '';

  measureToolActive = false;

  util = getMaxDepth;

  modelSupported = true;

  localVideoUrl = '';

  videoAspectRatio = null;

  biggestVideoWidth = 0;

  biggestVideoHeight = 0;

  selectedVideoOptions = 'Video 360';

  initialLoadFlag = true;

  videoSpeed = 1;

  playbackSpeeds = [
    {
      title: '0.25x',
      value: 0.25,
    },
    {
      title: '0.5x',
      value: 0.5,
    },
    {
      title: '0.75x',
      value: 0.75,
    },
    {
      title: '1x',
      value: 1,
    },
    {
      title: '2x',
      value: 2,
    },
    {
      title: '3x',
      value: 3,
    },
    {
      title: '4x',
      value: 4,
    },
  ]

  @Watch('isMSIView')
  onMSIViewChange(): void {
    if (!this.isMSIView) {
      this.syncSelectedVideoOption = 'Video 360';
    }
    this.maintainAspectRatio();
  }

  get videoURL(): string {
    if (this.inspection != null) {
      if (this.isWm) {
        return this.inspection.wmVideo;
      }
      return `${this.inspection.video}#t=${this.util.getClosestTime(this.inspection.payoutIPD, 0) / 1000}`;
    }
    return '';
  }

  get maxDepth(): number {
    if (this.inspection != null) {
      return getMaxDepth.getMaxDepth(this.inspection.payoutIPD, this.fullVideoTime);
    }
    return 0;
  }

  get videoOptions(): string[] {
    const retArray = [];
    if (this.inspection.video != null && this.inspection.video !== '') retArray.push('Video 360');
    if (this.inspection.wmVideo != null && this.inspection.wmVideo !== '') retArray.push('Raw Front');
    return retArray;
  }

  get videoValid(): boolean {
    const http = new XMLHttpRequest();
    let retVal = true;

    try {
      http.open('HEAD', this.videoURL, false);
      http.send();
      if (http.status !== 200) retVal = false;
    } catch (e) {
      retVal = false;
    }

    return retVal;
  }

  get modelValid(): boolean {
    const http = new XMLHttpRequest();
    let retVal = true;

    try {
      http.open('HEAD', this.inspection.model.model, false);
      http.send();
      if (http.status !== 200) retVal = false;
    } catch (e) {
      retVal = false;
    }

    return retVal;
  }

  get inspection(): InspectionData {
    if (this.realInspection == null) {
      return this.jestInspection;
    }
    return this.realInspection;
  }

  @Watch('syncSelectedVideoOption', { deep: true })
  onVideoOptionChange(): void {
    if (this.syncSelectedVideoOption === 'Video 360') {
      this.isWm = false;
      this.prepare360();
    }
    if (this.syncSelectedVideoOption === 'Raw Front') {
      this.isWm = true;
      this.prepareMsiVideo();
    }
  }

  @Watch('isMSIView')
  onIsMSIView(): void {
    if (this.isWm === true) {
      this.isWm = false;
      this.prepare360();
    }
  }

  mounted(): void {
    if (this.inspection != null && this.inspection.deliverablesJson != null && this.inspection.deliverablesJson !== '') {
      try {
        const json = JSON.parse(this.inspection.deliverablesJson);
        if (json.Viewer != null && json.Viewer === 'Flat') {
          this.cctv = true;
          this.prepareFlatVideo();
        }
      } catch (ex) {
        console.error(ex);
      }
    }

    window.addEventListener('resize', this.maintainAspectRatio);

    if ((this.$refs.layoutGrid as any) != null) {
      const videoWidth = (this.$refs.layoutGrid as any).clientWidth;
      const videoHeight = (this.$refs.layoutGrid as any).clientHeight;
      this.setAspectRatio(videoWidth, videoHeight);
      this.biggestVideoWidth = videoWidth;
      this.biggestVideoHeight = videoHeight;
    }

    if (this.inspection?.model?.model) {
      this.prepareModel();
    }
  }

  setAspectRatio(width: number, height: number): void {
    this.videoAspectRatio = width / height;
  }

  maintainAspectRatio(): void {
    setTimeout(async () => {
      const aspectRef = this.$refs.aspectDiv as any;
      if (!aspectRef || !this.videoAspectRatio) {
        return;
      }
      const currentWidth = aspectRef.clientWidth;
      const currentHeight = aspectRef.clientHeight;
      let changedDimensions = false;

      if (aspectRef.clientWidth > this.biggestVideoWidth) {
        this.biggestVideoWidth = aspectRef.clientWidth;
        changedDimensions = true;
      }
      if (aspectRef.clientHeight > this.biggestVideoHeight) {
        this.biggestVideoHeight = aspectRef.clientHeight;
        changedDimensions = true;
      }
      if (changedDimensions) {
        this.setAspectRatio(currentWidth, currentHeight);
      }

      const calculatedWidth = currentHeight * this.videoAspectRatio;
      const calculatedHeight = currentWidth / this.videoAspectRatio;

      const layoutGrid = document.getElementById('layout-grid') as HTMLElement;
      if (calculatedWidth < currentWidth) {
        layoutGrid.style.maxWidth = `${calculatedWidth}px`;
        layoutGrid.style.maxHeight = '100%';
      } else if (calculatedHeight <= currentHeight) {
        layoutGrid.style.maxHeight = `${calculatedHeight}px`;
        layoutGrid.style.maxWidth = '100%';
      }
      await this.$forceUpdate();
      this.triggerVideoResize();
    }, 100);
  }

  isModelSupported(value: boolean): void {
    this.modelSupported = value;
  }

  changeCurrentTime(value: number): void {
    if (this.videoValid) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.video360 as any)?.pause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.flatvideo as any)?.onPause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.msivideo as any)?.pause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.video360 as any).changeVideoTime(value);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.flatvideo as any).changeVideoTime(value);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.msivideo as any).changeVideoTime(value);
    }
  }

  prepare360(): void {
    if (document.getElementsByClassName('layout-grid').length !== 0) {
      const v360 = document.getElementById('v360') as HTMLElement;
      const flatVideo = document.getElementById('flatvideo') as HTMLElement;
      const msiVideo = document.getElementById('msivideo') as HTMLElement;
      const model = document.getElementById('model') as HTMLElement;
      v360.style.display = 'flex';
      flatVideo.style.display = 'none';
      msiVideo.style.display = 'none';
      model.style.visibility = 'hidden';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.video360 as any).onResize();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.flatvideo as any)?.onPause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.msivideo as any)?.onPause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.video360 as any)?.pause();
      window.dispatchEvent(new Event('resize'));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.video360 as any).onSwitchView(this.currentVideoTime);
    }
    this.tab = 0;
  }

  prepareFlatVideo(): void {
    if (document.getElementsByClassName('layout-grid').length !== 0) {
      const v360 = document.getElementById('v360') as HTMLElement;
      const flatVideo = document.getElementById('flatvideo') as HTMLElement;
      const msiVideo = document.getElementById('msivideo') as HTMLElement;
      const model = document.getElementById('model') as HTMLElement;
      v360.style.display = 'none';
      msiVideo.style.display = 'none';
      flatVideo.style.display = 'flex';
      model.style.visibility = 'hidden';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.flatvideo as any).onResize();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.flatvideo as any)?.onPause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.msivideo as any)?.onPause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.video360 as any)?.pause();
      window.dispatchEvent(new Event('resize'));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.flatvideo as any).onSwitchView(this.currentVideoTime);
    }
    this.tab = 1;
  }

  prepareMsiVideo(): void {
    if (document.getElementsByClassName('layout-grid').length !== 0) {
      const v360 = document.getElementById('v360') as HTMLElement;
      const flatVideo = document.getElementById('flatvideo') as HTMLElement;
      const msiVideo = document.getElementById('msivideo') as HTMLElement;
      const model = document.getElementById('model') as HTMLElement;
      v360.style.display = 'none';
      flatVideo.style.display = 'none';
      msiVideo.style.display = 'flex';
      model.style.visibility = 'hidden';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.msivideo as any).onResize();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.msivideo as any)?.onPause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.flatvideo as any)?.onPause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.video360 as any)?.pause();
      window.dispatchEvent(new Event('resize'));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.msivideo as any).setPlaybackSpeed(this.videoSpeed);
    }
    this.tab = 1;
  }

  prepareModel(): void {
    this.tab = 2;
    if (document.getElementsByClassName('layout-grid').length !== 0) {
      const v360 = document.getElementById('v360') as HTMLElement;
      const flatVideo = document.getElementById('flatvideo') as HTMLElement;
      const model = document.getElementById('model') as HTMLElement;
      if (v360 != null) v360.style.display = 'none';
      if (flatVideo != null) flatVideo.style.display = 'none';
      model.style.visibility = 'visible';

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.model as any).canvasResizeEvent();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.flatvideo as any)?.pause();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
      (this.$refs.video360 as any)?.pause();
    }
  }

  triggerVideoResize(): void {
    if (this.isWm) {
      if (this.syncSelectedVideoOption === 'Video 360') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
        (this.$refs.video360 as any)?.onResize();
      }
      if (this.syncSelectedVideoOption === 'Raw Front') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
        (this.$refs.msivideo as any)?.onResize();
      }
    } else {
      if (this.tab === 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
        (this.$refs.video360 as any)?.onResize();
      }
      if (this.tab === 1) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
        (this.$refs.flatvideo as any)?.onResize();
      }
      if (this.tab === 2) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-expressions
        (this.$refs.model as any)?.canvasResizeEvent();
      }
    }
  }

  resetClockPosition(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.video360 as any).resetClockPosition();
    if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
  }

  get computedVideoTime() : number {
    const position = this.payout / this.maxDepth;
    const time = this.fullVideoTime * position;
    return time;
  }

  prepareFlatViewer(): void {
    this.tab = 2;
    this.fullVideoTime = 1000;
    this.currentVideoTime = this.fullVideoTime * (this.flatPecrentComplete / 100);
  }

  updateModelLoadProgress(value: number): void {
    if ((value < 200 || value > 200) && value !== 100) {
      this.loading = true;
    } else {
      this.loading = false;
    }
    if (value > 200) {
      switch (value) {
        case 201:
          this.loadProgress = 'Rendering Model...';
          break;
        case 202:
          this.loadProgress = 'Rendering Point Cloud...';
          break;
        default:
          break;
      }
    } else if (value < 100) {
      this.loadProgress = `Loading Model: ${String(Math.round(value))}%`;
    } else {
      this.loadProgress = `Loading Point Cloud: ${String(
        Math.round(value - 100),
      )}%`;
    }
  }

  updateAngle(value: number): void {
    this.angle = value;
  }

  update360Angle(value: number): void {
    this.updateAngle(value * -1);
  }

  update360TimePercent(value: number): void {
    this.videoPercentComplete = value;
    this.onTrackPercentageChange(value);
  }

  setVideoSize(videoSize: {height: number, width: number}): void {
    if (videoSize.width === 0 || videoSize.height === 0) {
      return;
    }
    if (!this.videoAspectRatio) {
      this.videoAspectRatio = videoSize.width / videoSize.height;
    }

    const layoutGrid = document.getElementById('layout-grid') as HTMLElement;
    if (this.cctv) {
      layoutGrid.style.maxWidth = `${videoSize.width}px`;
      layoutGrid.style.maxHeight = `${videoSize.width}px`;
      return;
    }
    if (videoSize.width > videoSize.height) {
      layoutGrid.style.maxWidth = '100%';
      layoutGrid.style.maxHeight = `${(videoSize.height / videoSize.width) * 100}%`;
    } else {
      layoutGrid.style.maxWidth = `${(videoSize.width / videoSize.height) * 100}%`;
      layoutGrid.style.maxHeight = '100%';
    }
  }

  updateFlatPercent(value: number): void {
    this.flatPecrentComplete = value;
    this.onTrackPercentageChange(value);
    if (this.tab === 2) {
      this.prepareFlatViewer();
    }
  }

  updateFullVideoTime(value: number): void {
    this.fullVideoTime = value;
    this.$emit('updateFullVideoTime', value);
  }

  updateFullVideoTime360(value: number): void {
    if (this.initialLoadFlag && !this.cctv) {
      this.initialLoadFlag = false;
      this.prepare360();
    }
    this.updateFullVideoTime(value);
  }

  updateCurrentVideoTime(value: number): void {
    if (!this.isWm) {
      this.currentVideoTime = value;
      this.$emit('updateCurrentVideoTime', value);
    }
  }

  updateCurrentVideoTimeMSI(value: number): void {
    if (this.isWm) {
      this.currentVideoTime = value;
      this.$emit('updateCurrentVideoTime', value);
    }
  }

  updateFlatAngle(value: number): void {
    this.updateAngle(value);
  }

  updateClock(value: string): void {
    this.clockTime = value;
  }

  updatePauseState(value: number): void {
    this.videoState = value;
  }

  depthPerPixel(value: number): void {
    this.dpp = value;
    this.calculateDistanceBetweenTicks();
  }

  updateImageHeight(value: number): void {
    this.imageHeight = value;
    this.calculateDistanceBetweenTicks();
  }

  updateCanvasHeight(value: number): void {
    this.canvasHeight = value;
    this.calculateDistanceBetweenTicks();
  }

  updateRectHeight(value: number): void {
    this.viewRectHeight = value;
    this.calculateDistanceBetweenTicks();
  }

  calculateDistanceBetweenTicks(): void {
    const viewPercent = this.viewRectHeight / this.canvasHeight;
    const pixels = this.imageHeight * viewPercent;
    this.distanceBetweenTicks = (pixels * this.dpp) / (this.numberOfTicksDR - 1);
  }

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  onTrackPercentageChange(e: number): number {
    return e;
  }

  timeChange(distance: number): void {
    if (this.tab === 0 && this.videoValid) {
      (this.$refs.video360 as any).timeChange(distance);
    } else if (this.tab === 1) {
      (this.$refs.flatvideo as any).timeChange(distance);
    }
  }
}
