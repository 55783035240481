
































































import { AssetActions } from '@/store/asset/actions';
import { AssetPayload } from '@/store/asset/types';
import { ProjectData } from '@/store/project/types';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const assetModule = namespace('asset');
const projectModule = namespace('project');

@Component({})
export default class ManholeEntryForm extends Vue {
    valid = true as boolean;

    successMessageStyle = '' as string;

    successMessage = '' as string;

    showMessage = false as boolean;

    assetId = '' as string;

    assetIdRules = [
      (v: string): string | boolean => v !== '' || 'Name is required',
      (v: string): string | boolean => !!v || 'Name is required',
      (v: string): string | boolean => (!!v && v.length <= 200) || 'Name cannot be more than 200 characters',
    ]

    latitude = 0 as number;

    latitudeRules = [
      (v: string): string | boolean => v !== '' || 'Latitude is required',
      (v: string): string | boolean => !!v || 'Latitude is required',
      (v: number): string | boolean => (v && v >= -90 && v <= 90) || 'Latitude must be -90 to 90',
    ];

    longitude = 0 as number;

    longitudeRules = [
      (v: string): string | boolean => v !== '' || 'Latitude is required',
      (v: string): string | boolean => !!v || 'Longitude is required',
      (v: number): string | boolean => (v && v >= -180 && v <= 180) || 'Longitude must be -180 to 180',
    ];

    mapPage = '' as string;

    @assetModule.State('loadError') loadError: string | undefined;

    @assetModule.Action(AssetActions.POST_SINGLE_ASSET_DATA) postSingleAssetData;

    @projectModule.State('data') project: ProjectData | undefined;

    save(): void {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
      if (!this.valid) return;

      const manholeData: AssetPayload = {
        projectGuid: this.project.guids[0],
        assetId: this.assetId,
        assetType: 'Manhole',
        longitude: this.longitude,
        latitude: this.latitude,
        mapPage: this.mapPage,
      };

      this.postSingleAssetData(manholeData).then(() => {
        if (this.loadError) {
          this.successMessage = 'Manhole creation failed.';
          this.successMessageStyle = `background-color: #e61e25;
                                    color: white;
                                    padding: 8px 16px;
                                    border-radius: 4px;
                                    width: 100%;
                                    display: flex;
                                    margin-top: 24px;
                                    justify-content: space-between;`;
          this.showMessage = true;
        } else {
          this.successMessage = 'Manhole successfully added.';
          this.successMessageStyle = `background-color: #4caf50;
                                    color: white;
                                    padding: 8px 16px;
                                    border-radius: 4px;
                                    width: 100%;
                                    display: flex;
                                    margin-top: 24px;
                                    justify-content: space-between;`;
          this.showMessage = true;
          (this.$refs.form as Vue & { reset: () => void }).reset();
        }
      });
    }

    reset(): void {
      (this.$refs.form as Vue & { reset: () => void }).reset();
      this.successMessage = '';
      this.successMessageStyle = '';
    }
}
