import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { FlatGraphState } from './types';

const state: FlatGraphState = {
  data: undefined,
  loading: false,
  loadError: undefined,
};

const flatGraph: Module<FlatGraphState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default flatGraph;
