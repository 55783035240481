
/* eslint-disable no-underscore-dangle */
import { Component, Vue } from 'vue-property-decorator';

export interface MappingFieldData {
  redzoneFieldName: string;
  model: string;
  isRequired: boolean;
}

// eslint-disable-next-line no-shadow
export enum RedzoneMappingFieldNames {
  // MH
  ASSED_ID = 'Asset ID',
  LATITUDE = 'Latitude',
  LONGITUDE = 'Longitude',
  // LS
  NAME = 'Name',
  UPMANHOLE = 'Up Manhole',
  UPLAT = 'Up Latitude',
  UPLONG = 'Up Longitude',
  DOWNMANHOLE = 'Down Manhole',
  DOWNLAT = 'Down Latitude',
  DOWNLONG = 'Down Longitude',
  PIPESHAPE = 'Pipe Shape',
  PIPEHEIGHT = 'Pipe Height',
  PIPEWIDTH = 'Pipe Width',
  LENGTH = 'Length',
  CITY = 'City',
  STREET = 'Street',
  MAPPAGE = 'Map Page',
  PIPEMATERIAL = 'Pipe Material',
  SEWERUSE = 'Sewer Use',
  BASIN = 'Basin',
}

@Component
export default class AgolConstantsMixin extends Vue {
  mappingFieldsPerRow = 2;

  manholeMappingFieldData: MappingFieldData[] = [
    {
      redzoneFieldName: RedzoneMappingFieldNames.ASSED_ID,
      model: null,
      isRequired: true,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.LATITUDE,
      model: null,
      isRequired: true,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.LONGITUDE,
      model: null,
      isRequired: true,
    },
  ];

  lineSegmentMappingFieldData: MappingFieldData[] = [
    {
      redzoneFieldName: RedzoneMappingFieldNames.NAME,
      model: null,
      isRequired: true,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.UPMANHOLE,
      model: null,
      isRequired: true,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.UPLAT,
      model: null,
      isRequired: true,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.UPLONG,
      model: null,
      isRequired: true,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.DOWNMANHOLE,
      model: null,
      isRequired: true,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.DOWNLAT,
      model: null,
      isRequired: true,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.DOWNLONG,
      model: null,
      isRequired: true,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.LENGTH,
      model: null,
      isRequired: true,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.PIPESHAPE,
      model: null,
      isRequired: false,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.PIPEHEIGHT,
      model: null,
      isRequired: false,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.PIPEWIDTH,
      model: null,
      isRequired: false,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.CITY,
      model: null,
      isRequired: false,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.STREET,
      model: null,
      isRequired: false,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.MAPPAGE,
      model: null,
      isRequired: false,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.PIPEMATERIAL,
      model: null,
      isRequired: false,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.SEWERUSE,
      model: null,
      isRequired: false,
    },
    {
      redzoneFieldName: RedzoneMappingFieldNames.BASIN,
      model: null,
      isRequired: false,
    },
  ];

  _mappingFieldDataReset: MappingFieldData[] = [];

  isManhole = false;

  get mappingFieldData(): MappingFieldData[] {
    return this.isManhole ? this.manholeMappingFieldData : this.lineSegmentMappingFieldData;
  }

  set mappingFieldData(data: MappingFieldData[]) {
    if (this.isManhole) {
      this.manholeMappingFieldData = data;
    } else {
      this.lineSegmentMappingFieldData = data;
    }
  }

  get groupedMappingFieldData(): MappingFieldData[][] {
    return this.mappingFieldData.reduce((result, value, index) => {
      const newArrayIndex = Math.floor(index / this.mappingFieldsPerRow);

      if (result[newArrayIndex] == null) {
        result.push([]);
      }

      result[newArrayIndex].push(value);

      return result;
    }, []);
  }

  get isFieldMappingsValid(): boolean {
    return (
      this.mappingFieldData
        .filter((value) => value.isRequired)
        .find((value) => value.model == null) == null
    );
  }

  get isFieldMappingFormEmpty(): boolean {
    return (
      this.mappingFieldData.find((value) => value.model != null) == null
    );
  }

  mounted(): void {
    this._mappingFieldDataReset = JSON.parse(
      JSON.stringify(this.mappingFieldData),
    );
  }

  resetMappingFieldData(): void {
    this.mappingFieldData = JSON.parse(
      JSON.stringify(this.isManhole ? this.manholeMappingFieldData
        : this.lineSegmentMappingFieldData),
    );
  }
}
