import { MutationTree } from 'vuex';
import { AssetRef } from '@/types';
import {
  CollectionMetrics,
  PlanningAnalytics, ProjectData, ProjectGraphics, ProjectHeaders, ProjectListing, ProjectState,
} from './types';
import { ImportMappingContainer } from '../agol/types';

// eslint-disable-next-line no-shadow
export enum ProjectMutations {
    SET_LOADING_STATE = 'SET_LOADING_STATE',
    SET_PROJECT_DATA = 'SET_PROJECT_DATA',
    SET_PROJECT_GRAPHICS = 'SET_PROJECT_GRAPHICS',
    SET_LOADING_GRAPHICS = 'SET_LOADING_GRAPHICS',
    SET_GRAPHICS_ERROR = 'SET_GRAPHICS_ERROR',
    MERGE_PROJECT_DATA = 'MERGE_PROJECT_DATA',
    CLEAR_PROJECT_DATA = 'CLEAR_PROJECT_DATA',
    SET_ALL_PROJECTS_DATA = 'SET_ALL_PROJECTS_DATA',
    SET_LOAD_ERROR = 'SET_LOAD_ERROR',
    SET_PLANNING_ANALYTICS_DATA = 'SET_PLANNING_ANALYTICS_DATA',
    SET_LOADING_ANALYTICS_DATA = 'SET_LOADING_ANALYTICS_DATA',
    SET_ANALYTICS_DATA = 'SET_ANALYTICS_DATA',
    SET_PROJECT_METRICS = 'SET_PROJECT_METRICS',
    SET_LOADING_METRICS = 'SET_LOADING_METRICS',
    SET_LOADING_DASHBOARD_METRICS = 'SET_LOADING_DASHBOARD_METRICS',
    SET_DASHBOARD_PROJECT_METRICS = 'SET_DASHBOARD_PROJECT_METRICS',
    SET_RESPONSE = 'SET_RESPONSE',
    SET_LOADING_HEADERS = 'SET_LOADING_HEADERS',
    SET_PROJECT_HEADERS = 'SET_PROJECT_HEADERS',
    SET_HEADERS_ERROR = 'SET_HEADERS_ERROR',
    SET_LOADING_ASSET = 'SET_LOADING_ASSET',
    SET_SELECTED_ASSET = 'SET_SELECTED_ASSET',
    SET_ASSET_ERROR = 'SET_ASSET_ERROR',
    SET_PROJECT_NAMES = 'SET_PROJECT_NAMES',
    SET_NAMES_ERROR = 'SET_NAMES_ERROR',
    SET_LOADING_NAMES = 'SET_LOADING_NAMES',
    SET_GEO_JSON_LOADED = 'SET_GEO_JSON_LOADED',
    SET_GEO_JSON_ERROR = 'SET_GEO_JSON_ERROR',
    SET_AGOL_MAPPING_DATA = 'SET_AGOL_MAPPING_DATA',
    SET_AGOL_MAPPING_DATA_ERROR = 'SET_AGOL_MAPPING_DATA_ERROR',
    SET_AGOL_MAPPING_DATA_LOADING = 'SET_AGOL_MAPPING_DATA_LOADING',
    SET_AGOL_POST_MAPPING_DATA_ERROR = 'SET_AGOL_POST_MAPPING_DATA_ERROR',
    SET_AGOL_POST_MAPPING_DATA_LOADING = 'SET_AGOL_POST_MAPPING_DATA_LOADING',
    SET_AGOL_PATCH_MAPPING_DATA_ERROR = 'SET_AGOL_PATCH_MAPPING_DATA_ERROR',
    SET_AGOL_PATCH_MAPPING_DATA_LOADING = 'SET_AGOL_PATCH_MAPPING_DATA_LOADING',
    SET_AGOL_DELETE_MAPPING_DATA_ERROR = 'SET_AGOL_DELETE_MAPPING_DATA_ERROR',
    SET_AGOL_DELETE_MAPPING_DATA_LOADING = 'SET_AGOL_DELETE_MAPPING_DATA_LOADING',
    SET_MAP_NEEDS_UPDATED = 'SET_MAP_NEEDS_UPDATED',
    SET_TURN_OFF_AGOL_MAPPING_LOADING = 'SET_TURN_OFF_AGOL_MAPPING_LOADING',
    SET_TURN_OFF_AGOL_MAPPING_ERROR = 'SET_TURN_OFF_AGOL_MAPPING_ERROR',
}

export const mutations : MutationTree<ProjectState> = {
  [ProjectMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },

  [ProjectMutations.SET_PROJECT_DATA](state, payload: ProjectData) {
    state.data = payload;
  },

  [ProjectMutations.CLEAR_PROJECT_DATA](state) {
    state.data = null;
  },

  [ProjectMutations.SET_PROJECT_GRAPHICS](state, payload: ProjectGraphics[]) {
    state.graphics = payload;
  },

  [ProjectMutations.SET_LOADING_GRAPHICS](state, payload: boolean) {
    state.loadGraphics = payload;
  },

  [ProjectMutations.SET_GRAPHICS_ERROR](state, payload: string) {
    state.graphicsError = payload;
  },

  [ProjectMutations.MERGE_PROJECT_DATA](state) {
    // merge graphics
    state.graphics.forEach((graphic) => {
      const asset = state.data.assets.find((a) => a.guid === graphic.guid);
      asset.location = graphic.location;
      asset.laterals = graphic.laterals;
      asset.type = graphic.type;
      asset.taskResult = graphic.taskResult;
    });
    // merge headers
    state.data.names = state.headers.names;
    state.data.cities = state.headers.cities;
    state.data.states = state.headers.states;
    state.data.guids = state.headers.guids;
  },

  [ProjectMutations.SET_ALL_PROJECTS_DATA](state, payload: ProjectListing[]) {
    state.allProjectsData = payload;
  },

  [ProjectMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },

  [ProjectMutations.SET_PLANNING_ANALYTICS_DATA](state, payload: PlanningAnalytics) {
    state.planningAnalytics = payload;
  },

  [ProjectMutations.SET_LOADING_ANALYTICS_DATA](state, payload: boolean) {
    state.loadingAnalyticsData = payload;
  },

  [ProjectMutations.SET_PROJECT_METRICS](state, payload: CollectionMetrics[]) {
    state.detailedProjectMetrics = payload;
  },

  [ProjectMutations.SET_LOADING_METRICS](state, payload: boolean) {
    state.loadingMetrics = payload;
  },
  [ProjectMutations.SET_LOADING_DASHBOARD_METRICS](state, payload: boolean) {
    state.loadingDashboardMetrics = payload;
  },

  [ProjectMutations.SET_DASHBOARD_PROJECT_METRICS](state, payload: ProjectListing) {
    state.dashboardMetrics = payload;
  },
  [ProjectMutations.SET_RESPONSE](state, payload: string) {
    state.response = payload;
  },
  [ProjectMutations.SET_PROJECT_HEADERS](state, payload: ProjectHeaders) {
    state.headers = payload;
  },

  [ProjectMutations.SET_LOADING_HEADERS](state, payload: boolean) {
    state.loadHeader = payload;
  },

  [ProjectMutations.SET_HEADERS_ERROR](state, payload: string) {
    state.headerError = payload;
  },
  [ProjectMutations.SET_PROJECT_NAMES](state, payload: string[]) {
    state.names = payload;
  },

  [ProjectMutations.SET_LOADING_NAMES](state, payload: boolean) {
    state.loadNames = payload;
  },

  [ProjectMutations.SET_NAMES_ERROR](state, payload: string) {
    state.namesError = payload;
  },
  [ProjectMutations.SET_SELECTED_ASSET](state, payload: AssetRef) {
    state.selectedAsset = payload;
  },

  [ProjectMutations.SET_LOADING_ASSET](state, payload: boolean) {
    state.loadAsset = payload;
  },

  [ProjectMutations.SET_ASSET_ERROR](state, payload: string) {
    state.selectedAssetError = payload;
  },
  [ProjectMutations.SET_GEO_JSON_LOADED](state, payload: boolean) {
    state.loadGeoJson = payload;
  },

  [ProjectMutations.SET_GEO_JSON_ERROR](state, payload: string) {
    state.geoJsonError = payload;
  },

  [ProjectMutations.SET_AGOL_MAPPING_DATA](state, payload: ImportMappingContainer[]) {
    state.agolMappingData = payload;
  },

  [ProjectMutations.SET_AGOL_MAPPING_DATA_ERROR](state, payload: string) {
    state.agolMappingDataError = payload;
  },

  [ProjectMutations.SET_AGOL_MAPPING_DATA_LOADING](state, payload: boolean) {
    state.agolMappingDataLoading = payload;
  },

  [ProjectMutations.SET_AGOL_POST_MAPPING_DATA_ERROR](state, payload: string) {
    state.postAgolMappingDataError = payload;
  },

  [ProjectMutations.SET_AGOL_POST_MAPPING_DATA_LOADING](state, payload: boolean) {
    state.postAgolMappingDataLoading = payload;
  },

  [ProjectMutations.SET_AGOL_PATCH_MAPPING_DATA_ERROR](state, payload: string) {
    state.patchAgolMappingDataError = payload;
  },

  [ProjectMutations.SET_AGOL_PATCH_MAPPING_DATA_LOADING](state, payload: boolean) {
    state.patchAgolMappingDataLoading = payload;
  },

  [ProjectMutations.SET_AGOL_DELETE_MAPPING_DATA_ERROR](state, payload: string) {
    state.deleteAgolMappingDataError = payload;
  },

  [ProjectMutations.SET_AGOL_DELETE_MAPPING_DATA_LOADING](state, payload: boolean) {
    state.deleteAgolMappingDataLoading = payload;
  },

  [ProjectMutations.SET_MAP_NEEDS_UPDATED](state, payload: boolean) {
    state.mapNeedsUpdated = payload;
  },

  [ProjectMutations.SET_TURN_OFF_AGOL_MAPPING_LOADING](state, payload: boolean) {
    state.turnOffAgolMappingLoading = payload;
  },

  [ProjectMutations.SET_TURN_OFF_AGOL_MAPPING_ERROR](state, payload: string) {
    state.turnOffAgolMappingError = payload;
  },

};
