





























































































import { ClientActionItem } from '@/store/metrics/types';
import { format } from 'date-fns';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Bar } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartData,
} from 'chart.js';

interface ChartOptions {
  indexAxis: string;
  responsive: boolean;
  maintainAspectRatio: boolean;
  layout: {
    margin: {
      bottom: number;
      top: number;
    };
  };
  scales: {
    x: {
      stacked: boolean;
      display: boolean;
      grid: {
        display: boolean;
      };
    };
    y: {
      stacked: boolean;
      grid: {
        display: boolean;
      };
    };
  };
  plugins: {
    legend: {
      display: boolean;
    };
    tooltip: {
      callbacks: {
        title: (context: any) => string;
        label: (context: any) => string;
      };
    };
  };
}

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Roboto';

@Component({ components: { Bar } })
export default class ClientActions extends Vue {
  @Prop() readonly clientActionItems: ClientActionItem[];

  @Prop() readonly projectGuid: string;

  @Watch('selectedDateOption')
  onSelectedDateOptionChange(): void {
    const dateNow = new Date();
    let dateFrom: Date;
    let dateTo: Date;

    switch (this.selectedDateOption) {
      case 'Today':
        dateTo = new Date();
        dateFrom = new Date(new Date().setDate(dateNow.getDate() - 1));
        break;
      case 'Yesterday':
        dateFrom = new Date(new Date().setDate(dateNow.getDate() - 2));
        dateTo = new Date(new Date().setDate(dateNow.getDate() - 1));
        break;
      case 'Week':
        dateFrom = new Date(new Date().setDate(dateNow.getDate() - 7));
        dateTo = new Date();
        break;
      case 'Month':
        dateFrom = new Date(new Date().setMonth(dateNow.getMonth() - 1));
        dateTo = new Date();
        break;
      default:
        break;
    }

    if (dateFrom && dateTo) {
      this.dateFrom = dateFrom.toISOString().substring(0, 10).replace(/-/g, '/');
      this.dateTo = dateTo.toISOString().substring(0, 10).replace(/-/g, '/');
      this.selectedDateValues = [this.dateFrom, this.dateTo];
    }
  }

  async mounted(): Promise<void> {
    this.selectedDateOption = 'Today';
  }

  dateOptions = ['Today', 'Yesterday', 'Week', 'Month', 'Custom'];

  selectedDateOption = '';

  dateFrom = new Date(0).toISOString().substring(0, 10);

  dateTo = new Date().toISOString().substring(0, 10);

  selectedDateValues = [this.dateFrom, this.dateTo];

  taskResults = [
    {
      name: 'CNO', value: 'cno', desc: ['Unable To Open Access Point'], color: '#f0f0f0',
    },
    {
      name: 'CNL', value: 'cnl', desc: ['Could Not Locate'], color: '#ddeefa',
    },
    {
      name: 'CNA', value: 'cna', desc: ['Could Not Access', 'Could Not Traverse'], color: '#add6f4',
    },
    {
      name: 'Traffic Control', value: 'trafficControl', desc: ['Traffic Prevented Access'], color: '#4594dc',
    },
    {
      name: 'Permits', value: 'permits', desc: [''], color: '#2d6495',
    },
    {
      name: 'Easements', value: 'easements', desc: [''], color: '#153148',
    },
    {
      name: 'DOT', value: 'dot', desc: [''], color: '#000000',
    },
  ]

  get baseChartOptions(): ChartOptions {
    return {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        margin: {
          bottom: 0,
          top: 0,
        },
      },
      scales: {
        x: {
          stacked: true,
          display: false,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              if (context[0].label === '') return 'Total';
              return context[0].label;
            },
            label: (context) => {
              let retVal = context.formattedValue;
              if (context.label !== '') {
                const total = context.label === 'Manholes' ? this.mhTotal : this.lsTotal;
                retVal = context.formattedValue * total;
              }
              return `${context.dataset.label}: ${Math.round(retVal)}`;
            },
          },
        },
      },
    };
  }

  get customDateRange(): string {
    if (this.selectedDateOption === 'Custom') {
      return `(${this.dateFrom.concat(' to ', this.dateTo)})`;
    }
    return '';
  }

  get chartDataByAsset(): ChartData | undefined {
    const datasets = [];

    this.taskResults.forEach((tr) => {
      datasets.push({
        label: tr.name,
        data: [
          this.mhTotal != null && this.mhTotal !== 0
            ? this.metricsData[tr.value].manholes / this.mhTotal : 0,
          this.lsTotal != null && this.lsTotal !== 0
            ? this.metricsData[tr.value].lineSegments / this.lsTotal : 0,
        ],
        backgroundColor: tr.color,
      });
    });

    let foundManholeData: number;
    let foundLSData: number;

    datasets.forEach((value) => {
      if (!foundManholeData) {
        foundManholeData = value.data[0] > 0 ? value.data[0] : undefined;
      }
      if (!foundLSData) {
        foundLSData = value.data[1] > 0 ? value.data[1] : undefined;
      }
    });

    if (!foundManholeData || !foundLSData) {
      datasets.push({
        label: 'No Data',
        data: [
          foundManholeData ? 0 : 1,
          foundLSData ? 0 : 1],
        backgroundColor: '#CCCCCC',
      });
    }

    return {
      labels: ['Manholes', 'Line Segments'],
      datasets,
    };
  }

  get chartDataTotal(): ChartData | undefined {
    const datasets = [];

    this.taskResults.forEach((tr) => {
      datasets.push({
        label: tr.name,
        data: [this.metricsData[tr.value].manholes + this.metricsData[tr.value].lineSegments],
        backgroundColor: tr.color,
      });
    });

    let foundData: number;

    datasets.forEach((value) => {
      if (foundData) {
        return;
      }
      foundData = value.data.find((data) => data > 0);
    });

    if (!foundData) {
      datasets.push({
        label: 'No Data',
        data: [1],
        backgroundColor: '#CCCCCC',
      });
    }

    return {
      labels: [''],
      datasets,
    };
  }

  get mhTotal(): number {
    let mhTotal = 0;
    this.taskResults.forEach((tr) => {
      mhTotal += this.metricsData[tr.value].manholes;
    });

    return mhTotal;
  }

  get lsTotal(): number {
    let lsTotal = 0;
    this.taskResults.forEach((tr) => {
      lsTotal += this.metricsData[tr.value].lineSegments;
    });

    return lsTotal;
  }

  get chartOptions(): unknown {
    return {
      ...this.baseChartOptions,
      onClick: this.handleChartClick,
    };
  }

  get metricsData(): unknown {
    const retVal = {};

    if (this.clientActionItems) {
      this.taskResults.forEach((tr) => {
        const actionItems = [];

        tr.desc.forEach((description) => {
          actionItems.push(...this.clientActionItems.filter((cai) => cai.name === description));
        });

        const aiByDate = actionItems.filter(
          (ai) => new Date(ai.date).setHours(0, 0, 0, 0) >= new Date(this.dateFrom).getTime()
          && new Date(ai.date).setHours(0, 0, 0, 0) <= new Date(this.dateTo).getTime(),
        );
        const aiMh = aiByDate.filter((cm) => cm.assetType === 'Manhole');
        const aiLs = aiByDate.filter((cl) => cl.assetType === 'Line Segment');

        retVal[tr.value] = {
          manholes: aiMh.length,
          lineSegments: aiLs.length,
        };
      });
    }

    return retVal;
  }

  get chartOptionsTotalNoClick(): ChartOptions {
    return {
      ...this.baseChartOptions,
    };
  }

  updateSelection(item: string): void {
    this.selectedDateOption = item;
  }

  updateCustom(dates: string[]): void {
    const newDateFrom = dates[0];
    const newDateTo = dates[1];
    this.dateFrom = newDateFrom;
    this.dateTo = newDateTo;
  }

  handleChartClick(evt: MouseEvent, elements: any): void {
    if (elements?.length == null) {
      return;
    }
    const taskResult = this.chartDataByAsset.datasets[elements[0].datasetIndex].label;
    if (taskResult === 'No Data') {
      return;
    }

    const taskResultDescription = encodeURI(JSON.stringify(this.taskResults
      .find((item) => item.name === taskResult).desc));

    const dateBefore = this.dateFrom;
    const dateAfter = this.dateTo;

    this.$router.push({
      path: `/workOrders/${this.projectGuid}`,
      query: {
        taskResult: taskResultDescription,
        assetType: this.chartDataByAsset.labels[elements[0].index] as string,
        workOrderStatus: 'Incomplete',
        dateBefore,
        dateAfter,
      },
    }).then(() => {
      this.$router.go(0);
    });
  }
}
