


































/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkOrderStatusCount } from '@/store/metrics/types';
import { Bar } from 'vue-chartjs/legacy';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartData,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

ChartJS.defaults.font.size = 14;
ChartJS.defaults.font.family = 'Roboto';

@Component({ components: { Bar } })
export default class CustomerDashboardWorkOrderBarChart extends Vue {
  @Prop() readonly workOrderStatusCount: WorkOrderStatusCount;

  @Prop() readonly type: string;

  @PropSync('selectedGroup') synchedSelectedGroup: string;

  @Prop({ default: 0 }) readonly selectedTab: number;

  chartId = 'workOrderChart';

  chartClass = 'work-order-chart';

  get tableData(): any[] {
    const tableCounts = Object.entries(
      this.workOrderStatusCount.taskTypeStatuses,
    ).map((obj) => ({ name: obj[0], ...obj[1] }));
    return tableCounts;
  }

  getRowValue(rowVal: any): string {
    return typeof rowVal === 'object' ? rowVal.count : rowVal;
  }

  selectGroup(groupName: string): void {
    this.synchedSelectedGroup = groupName !== this.synchedSelectedGroup ? groupName : '';
  }

  get chartData(): ChartData | undefined {
    // console.log(JSON.stringify(this.workOrderStatusCount));
    if (!this.workOrderStatusCount) {
      return null;
    }

    const statuses = ['unassigned', 'assigned', 'followUpReq', 'complete'];
    const tts = this.workOrderStatusCount.taskTypeStatuses;
    const labels = Object.keys(tts);

    const returnDatasets = [];
    statuses.forEach((status) => {
      returnDatasets.push({
        label: status,
        data: this.getPlatformCounts(labels, status),
        backgroundColor: this.getColor(status),
      });
    });

    return {
      labels,
      datasets: returnDatasets,
    };
  }

  get chartOptions(): any {
    return {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 0.9,
      layout: {
        padding: {
          bottom: 4,
          top: 4,
        },
      },
      scales: {
        x: {
          stacked: true,
          display: false,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const total = this.taskTypeTotal(context.label);
              return `${this.prettyLabel(context.dataset.label)}: ${Math.round(
                context.raw * total,
              )}`;
            },
          },
        },
      },
    };
  }

  getPlatformCounts(keys: string[], status: string): number[] {
    const retVal = [];
    const tts = this.workOrderStatusCount.taskTypeStatuses;
    keys.forEach((key) => {
      retVal.push(tts[key][status].percent);
    });
    return retVal;
  }

  getColor(status: string) {
    switch (status) {
      case 'unassigned':
        return '#FFC300';
      case 'assigned':
        return '#CCCCCC';
      case 'followUpReq':
        return '#0C6599';
      case 'complete':
        return '#F0F0F0';
      default:
        return '#000000';
    }
  }

  taskTypeTotal(taskType: string): number {
    const tts = this.workOrderStatusCount.taskTypeStatuses;
    if (this.workOrderStatusCount) {
      return tts[taskType].total;
    }
    return 0;
  }

  prettyLabel(label: string): string {
    switch (label) {
      case 'unassigned':
        return 'Unassigned';
      case 'assigned':
        return 'Assigned';
      case 'followUpReq':
        return 'Follow-Up Req';
      case 'complete':
        return 'Complete';
      default:
        return 'Can\'t find label';
    }
  }
}
