

























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import { Component, Prop } from 'vue-property-decorator';
import { Pie as PieGenerator } from 'vue-chartjs/legacy';
import {
  WorkOrderStatusCount,
  WorkOrderStatusCountByType,
} from '@/store/metrics/types';
import { ChartData, ChartOptions } from 'chart.js';

@Component({ components: { PieGenerator } })
export default class WorkOrderStatusChart extends CustomerDashboardMixin {
  @Prop() readonly selectedStatus: string;

  @Prop() readonly selectedTab: number;

  @Prop({ default: false }) showLabels: boolean;

  HIGHLIGHTED_OFFSET = 10;

  HIGHLIGHTED_COLOR = '#55ffdd';

  statusOptions = [
    { name: 'unassigned', label: 'Unassigned', color: '#FFC300' },
    { name: 'assigned', label: 'Assigned', color: '#CCCCCC' },
    { name: 'followUpReq', label: 'Follow-Up Required', color: '#0C6599' },
    { name: 'complete', label: 'Complete', color: '#F0F0F0' },
  ];

  followUpOptions = [
    { name: 'cannotLocate', label: 'CNL', color: '#D9EFFC' },
    { name: 'cannotAccess', label: 'CNA', color: '#A1D8F7' },
    { name: 'cannotOpen', label: 'CNO', color: '#55B8F1' },
    { name: 'lightCleaning', label: 'Cleaning (Light)', color: '#1296E2' },
    { name: 'heavyCleaning', label: 'Cleaning (Heavy)', color: '#0C6599' },
    { name: 'trafficControl', label: 'Traffic Control', color: '#06324B' },
    { name: 'other', label: 'Other', color: '#000000' },
  ];

  get chartClass(): string {
    return this.showLabels ? 'pdf-status' : 'inspection-status';
  }

  pieLabelLinePlugin = {
    id: 'pieLabelsLine',
    afterDraw(chart: any): void {
      const { ctx } = chart;

      ctx.save();

      const noDataString = 'No Data';

      let color = 'black';
      let labelColor = 'black';

      const leftLabelCoordinates = [];
      const rightLabelCoordinates = [];

      const getSuitableY = (y, yArray = [], direction) => {
        let result = y;
        yArray.forEach((existedY) => {
          if (existedY - 14 < result && existedY + 14 > result) {
            if (direction === 'right') {
              result = existedY + 14;
            } else {
              result = existedY - 14;
            }
          }
        });

        return result;
      };

      const chartCenterPoint = {
        x:
          (chart.chartArea.right - chart.chartArea.left) / 2
          + chart.chartArea.left,
        y:
          (chart.chartArea.bottom - chart.chartArea.top) / 2
          + chart.chartArea.top,
      };

      chart.config.data.labels.forEach((label, i) => {
        if (label === noDataString) {
          return;
        }

        const meta = chart.getDatasetMeta(0);
        const arc = meta.data[i];
        const dataset = chart.config.data.datasets[0];

        const centerPoint = arc.getCenterPoint();
        if (dataset.polyline && dataset.polyline.color) {
          color = dataset.polyline.color;
        }

        if (dataset.polyline && dataset.polyline.labelColor) {
          labelColor = dataset.polyline.labelColor;
        }

        const angle = Math.atan2(
          centerPoint.y - chartCenterPoint.y,
          centerPoint.x - chartCenterPoint.x,
        );

        const point2X = chartCenterPoint.x + Math.cos(angle) * (arc.outerRadius + 5);
        let point2Y = chartCenterPoint.y + Math.sin(angle) * (arc.outerRadius + 5);

        let suitableY;
        if (point2X < chartCenterPoint.x) {
          // on the left
          suitableY = getSuitableY(point2Y, leftLabelCoordinates, 'left');
        } else {
          // on the right
          suitableY = getSuitableY(point2Y, rightLabelCoordinates, 'right');
        }

        point2Y = suitableY;

        let value = dataset.data[i];
        if (value === 0) {
          return;
        }
        if (dataset.polyline && dataset.polyline.formatter) {
          value = dataset.polyline.formatter(value);
        }
        const edgePointX = point2X < chartCenterPoint.x ? 10 : chart.width - 10;

        if (point2X < chartCenterPoint.x) {
          leftLabelCoordinates.push(point2Y);
        } else {
          rightLabelCoordinates.push(point2Y);
        }

        // Draw lines/text
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.arc(centerPoint.x, centerPoint.y, 2, 0, 2 * Math.PI, true);
        ctx.fill();
        ctx.moveTo(centerPoint.x, centerPoint.y);
        ctx.lineTo(point2X, point2Y);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(point2X, point2Y);
        ctx.lineTo(edgePointX, point2Y);
        ctx.stroke();
        const labelAlignStyle = edgePointX < chartCenterPoint.x ? 'left' : 'right';
        const labelX = edgePointX;
        const labelY = point2Y;
        ctx.textAlign = labelAlignStyle;
        ctx.textBaseline = 'bottom';

        ctx.fillStyle = labelColor;
        ctx.fillText(label, labelX, labelY);
      });
      ctx.restore();
    },
  };

  get chartOptions(): any {
    return {
      responsive: true,
      aspectRatio: this.showLabels ? 1.25 : 1,
      maintainAspectRatio: true,
      layout: {
        padding: {
          top: this.showLabels ? 40 : 0,
          bottom: this.showLabels ? 40 : 0,
          left: this.showLabels ? 40 : 0,
          right: this.showLabels ? 40 : 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: !this.showLabels,
          bodyFont: {
            size: 12,
          },
        },
      },
    };
  }

  get plugins(): any[] {
    if (!this.showLabels) {
      return [];
    }
    return [this.pieLabelLinePlugin];
  }

  get workOrderData(): WorkOrderStatusCount | undefined {
    if (!this.customerDataWorkOrderCounts) return null;

    return this.customerDataWorkOrderCounts[this.selectedTab ? 'lineSegments' : 'manholes'];
  }

  get statusChartData(): ChartData {
    return this.getChartData('status');
  }

  get followUpChartData(): ChartData {
    return this.getChartData('followUp');
  }

  get statusChartOptions(): ChartOptions {
    const options = { ...this.chartOptions };

    options['onClick'] = this.createClickFunction(this.statusChartData);

    return options;
  }

  get followUpChartOptions(): ChartOptions {
    const options = { ...this.chartOptions };

    options['onClick'] = this.createClickFunction(this.followUpChartData);

    return options;
  }

  get statusTableData(): WorkOrderStatusCountByType {
    return this.workOrderData.totalWorkOrderCounts;
  }

  get followUpReqTableData(): any {
    return this.workOrderData.totalWorkOrderCounts.followUpReqReason;
  }

  createClickFunction(chartDataType: ChartData): (chart: any, item: any) => void {
    return (chart, item) => {
      if (!item || item.length === 0) {
        return;
      }

      const statusValue = chartDataType.labels[item[0].index];
      this.$emit('statusChartClick', statusValue);
    };
  }

  getChartData(dataType: string): ChartData {
    let optionSet = [];
    let isFollowUp = false;

    switch (dataType) {
      case 'status':
        optionSet = this.statusOptions;
        break;
      case 'followUp':
        optionSet = this.followUpOptions;
        isFollowUp = true;
        break;
      default:
        break;
    }

    const offsets = new Array(optionSet.length).fill(0);
    const selectedOptionSetIndex = optionSet
      .map((o) => o.label)
      .indexOf(this.selectedStatus);
    if (selectedOptionSetIndex >= 0) {
      offsets[selectedOptionSetIndex] = this.HIGHLIGHTED_OFFSET;
    }

    const chartData = {
      labels: optionSet.map((o) => o.label),
      datasets: [
        {
          backgroundColor: optionSet.map((o) => o.color),
          data: optionSet.map((o) => this.getValue(o.name, isFollowUp)),
          borderColor: offsets.map((off) => (off === 0 ? '#ffffff' : this.HIGHLIGHTED_COLOR)),
          offset: offsets,
        },
      ],
    };

    if (
      chartData.datasets[0].data.reduce((a, b) => Math.max(a, b), -Infinity)
        === 0
      && chartData.labels.find((value) => value === 'No Data') === undefined
    ) {
      chartData.labels = ['No Data'];
      chartData.datasets[0].backgroundColor = ['gray'];
      chartData.datasets[0].data = [1];
    }

    return chartData;
  }

  getValue(option: string, followUpReqReason = false): number {
    return followUpReqReason
      ? this.workOrderData.totalWorkOrderCounts.followUpReqReason[option].count
      : this.workOrderData.totalWorkOrderCounts[option].count;
  }

  formatPercentage(value: number): number {
    return Math.round((value + Number.EPSILON) * 100);
  }
}
