// eslint-disable-next-line no-shadow
export enum MarkerType {
    CONDITION = 'condition',
    INFO = 'info'
}

// eslint-disable-next-line no-shadow
export enum MSIDefectGraphState{
    ORIGINAL = 'MACP/PACP',
    MSI = 'MSI',
    ALL = 'All'
}

export interface Marker {
    Type: MarkerType;
    getTitle() : string;
    getDescription() : string;
    getDistance() : number;
    getColor(): string;
    getReportData() : Record<string, unknown | undefined>;
    getGrade() : number;
    getGuid() : string;
}
