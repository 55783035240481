import { DataStandardViewReturn } from '@/components/DataStandards/types';
import { MutationTree } from 'vuex';
import {
  MPSState,
} from './types';
import { iMPS } from '../../views/MasterProjectSummary/iMPS';

// eslint-disable-next-line no-shadow
export enum MPSMutations {
    SET_LOADING_STATE = 'SET_LOADING_STATE',
    SET_LOAD_ERROR = 'SET_LOAD_ERROR',
    SET_MACP_DATA_STANDARDS = 'SET_MACP_DATA_STANDARDS',
    SET_PACP_DATA_STANDARDS = 'SET_PACP_DATA_STANDARDS',
    SET_MPS_DATA = 'SET_MPS_DATA',
}

export const mutations : MutationTree<MPSState> = {
  [MPSMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
  [MPSMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
  [MPSMutations.SET_MACP_DATA_STANDARDS](state, payload: DataStandardViewReturn) {
    state.macpStandards = payload;
  },
  [MPSMutations.SET_PACP_DATA_STANDARDS](state, payload: DataStandardViewReturn) {
    state.pacpStandards = payload;
  },
  [MPSMutations.SET_MPS_DATA](state, payload: iMPS) {
    state.mpsData = payload;
  },
};
