





















import { ProjectTotalsObject } from '@/store/metrics/types';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

interface FormattedProjectTotals {
    name: string;
    value: number;
    percentage?: number;
    style: string;
}

@Component({
  components: {
  },
})
export default class ProjectTotalsCard extends Vue {
  @Prop() readonly projectTotals: ProjectTotalsObject;

  get projectTotalsFormatted(): FormattedProjectTotals[] {
    const total = this.projectTotals?.total;
    return [
      {
        name: 'Total',
        value: total,
        percentage: undefined,
        style: 'font-weight: bold;',
      },
      {
        name: 'Remaining',
        value: this.projectTotals?.remaining,
        percentage: Math.round((this.projectTotals?.remaining / total) * 1000) / 10,
        style: '',
      },
      {
        name: 'Addressed',
        value: this.projectTotals?.addressed,
        percentage: Math.round((this.projectTotals?.addressed / total) * 1000) / 10,
        style: '',
      },
      {
        name: 'Collected',
        value: this.projectTotals?.collected,
        percentage: Math.round((this.projectTotals?.collected / total) * 1000) / 10,
        style: '',
      },
      {
        name: 'Issues',
        value: this.projectTotals?.issues,
        percentage: Math.round((this.projectTotals?.issues / total) * 1000) / 10,
        style: '',
      },
    ];
  }

  formatNumber(value: number): string {
    const rounded = Math.round(value * 100) / 100;
    return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
