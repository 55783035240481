import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { FlatGraphState } from './types';
import { FlatGraphMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum FlatGraphActions {
  GET_FLAT_GRAPH = 'GET_FLAT_GRAPH',
  CLEAR_FLAT_GRAPH = 'CLEAR_FLAT_GRAPH',
}

export const actions: ActionTree<FlatGraphState, RootState> = {
  async [FlatGraphActions.GET_FLAT_GRAPH]({ commit }, payload: string): Promise<void> {
    commit(FlatGraphMutations.SET_LOADING_STATE, true);
    commit(FlatGraphMutations.SET_FLAT_GRAPH_DATA, undefined);
    commit(FlatGraphMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getFlatGraphImage(payload);

      commit(FlatGraphMutations.SET_FLAT_GRAPH_DATA, response.data);
    } catch (e) {
      commit(FlatGraphMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(FlatGraphMutations.SET_LOADING_STATE, false);
    }
  },

  async [FlatGraphActions.CLEAR_FLAT_GRAPH]({ commit }): Promise<void> {
    commit(FlatGraphMutations.SET_LOADING_STATE, true);
    commit(FlatGraphMutations.SET_LOAD_ERROR, undefined);
    try {
      commit(FlatGraphMutations.SET_FLAT_GRAPH_DATA, undefined);
    } catch (e) {
      commit(FlatGraphMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(FlatGraphMutations.SET_LOADING_STATE, false);
    }
  },
};
