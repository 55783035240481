







































































































































































import { BasicSelect } from 'vue-search-select';
import { ProjectActions } from '@/store/project/actions';
import { ProjectListing } from '@/store/project/types';
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { getRoleGuidById } from '@/auth/roles';
import AdminPanelMixin from '../AdminPanelMixin.vue';

const projectModule = namespace('project');

@Component({
  components: {
    BasicSelect,
  },
})
export default class EditUsers extends AdminPanelMixin {
  @projectModule.Action(ProjectActions.FETCH_ALL_PROJECTS_DATA)
  fetchAllProjectsData;

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  @projectModule.State('loadError') loadError;

  @projectModule.State('loading') loading;

  @Prop() readonly selectedUsers: any[];

  @Prop() readonly canManageUsers: any[];

  @Prop({ default: false }) editSelectOpen;

  @Prop() readonly organizations: Array<{value: number; text: string, guid: string}>;

  @Prop() readonly customers: Array<{value: number; text: string, guid: string}>;

  selectedOragnization = { value: 0, text: '', guid: '' } as {
    value: number;
    text: string;
    guid: string;
  };

  selectedCustomer = { value: 0, text: '', guid: '' } as {
    value: number;
    text: string;
    guid: string;
  };

  projects: Array<{ value: number; text: string; guid: string }> = [];

  snack = false;

  snackBarMessage = '';

  snackColor = 'black';

  addProjectOpen = false;

  addManagedUserOpen = false;

  selectedProjects = [] as {
    value: number;
    text: string;
    guid: string;
  }[];

  selectedManageUsers: Array<{
    value: number;
    text: string;
    guid: string;
  }> = [];

  editUsersDialog = false as boolean;

  addProjectDialog = false as boolean;

  addProjectErrorDialog = false;

  valid = false as boolean;

  requiredField = [(v) => !!v || v === 0 || 'This is a required field.'];

  /**
   * @description check if all selected users have a common customer
   * @returns {boolean} true if selected users have a common customer, false otherwise
   */
  get selectedUsersHaveSameCustomer(): boolean {
    // get selected user customer guids as arrays
    const selectedUserCustomers = this.selectedUsers.map(
      (user) => user.detailedUserData.customerGuids,
    );

    // filter selected user customer guids down to ones that exist in all arrays
    // this should result in an array of customer guids common to all selected users
    // if there are no common customers between all selected users, commonCustomer would be empty
    const commonCustomer: string[] = selectedUserCustomers
      .shift()
      .filter((c) => selectedUserCustomers.every((uc) => uc.indexOf(c) !== -1));

    return this.selectedUsers.length <= 1 ? true : commonCustomer.length > 0;
  }

  /**
   * @description get the common customers between selected users
   * @returns an array of objects containing a customer name and any users associated with it
   */
  get customerGroupings(): { customer: string, users: string[] }[] {
    const retVal: { customer: string, users: string[] }[] = [];
    let customerName = '';

    this.selectedUsers.forEach((user) => {
      if (user.detailedUserData.customerGuids.length === 0) {
        customerName = 'no assigned customer';
        this.populateCustomeerGroupings(user, customerName, retVal);
      } else {
        user.detailedUserData.customerGuids.forEach((customerGuid: string) => {
          customerName = this.customerDataList.find((c) => c.guid === customerGuid).name;
          this.populateCustomeerGroupings(user, customerName, retVal);
        });
      }
    });

    return retVal;
  }

  @Watch('allProjects')
  allProjectsChange(): void {
    this.projects = [];
    if (this.allProjects != null && this.allProjects.length > 0) {
      this.allProjects.forEach((proj, i) => {
        this.projects.push({
          value: i,
          text: proj.name,
          guid: proj.guid,
        });
      });
    }

    this.$forceUpdate();
  }

  get canManageUsersSelect(): Array<{ value: number; text: string; guid: string }> {
    if (this.canManageUsers.length > 0
        && this.customers.length > 0
        && this.organizations.length > 0) {
      if (this.customers.length === 1) [this.selectedCustomer] = this.customers;
      if (this.organizations.length === 1) [this.selectedOragnization] = this.organizations;
      if (this.selectedCustomer.guid !== '') {
        const matchedUsers = this.canManageUsers
          .filter((user) => user.detailedUserData.customerGuids.includes(this.selectedCustomer.guid)
        || user.detailedUserData.organizationGuids.includes(this.selectedOragnization.guid));
        const mappedUsers = matchedUsers.map(
          (user, i) => ({ value: i, text: user.fullName, guid: user.guid }),
        );
        return mappedUsers;
      }
    }
    return [];
  }

  @Watch('selectedUsers')
  onSelectedUsersChange(): void {
    if (this.selectedUsers.length === 0) return;

    this.allProjectsChange();
    const selectedUserCustomers = this.selectedUsers.map(
      (user) => user.detailedUserData.customerGuids,
    );

    const commonCustomer: string[] = selectedUserCustomers
      .shift()
      .filter((c) => selectedUserCustomers.every((uc) => uc.indexOf(c) !== -1));

    this.projects = this.projects.filter((p) => {
      const projCheck = this.allProjects.find((proj) => proj.guid === p.guid);
      return commonCustomer.includes(projCheck.customerGuid);
    });
  }

  async mounted(): Promise<void> {
    this.fetchAllProjectsData().catch(() => {
      this.$router.push({
        name: 'Error',
        params: { catchAll: 'Error', message: 'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.' },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch((e) => {});
    });
  }

  async submit(): Promise<void> {
    try {
      this.selectedUsers.forEach(async (user) => {
        this.selectedProjects.forEach(async (project) => {
          if (!user.detailedUserData.role.find((role) => role.projectGuid === project.guid)) {
            user.detailedUserData.role.push({
              projectGuid: project.guid,
              roleGuid: getRoleGuidById(user.role[0]),
              startDate: null,
              endDate: null,
            });

            await this.patchUser({
              Guid: user.detailedUserData.guid,
              Firstname: user.detailedUserData.firstname,
              Lastname: user.detailedUserData.lastname,
              nickname: user.detailedUserData.nickname,
              userRoleObjects: user.detailedUserData.role,
              nasscoId: user.detailedUserData.nasscoid,
            });
          }
        });
      });

      this.snackColor = 'green';
      this.snackBarMessage = 'Project Added';
      this.snack = true;
    } catch (ex) {
      this.snackBarMessage = (ex as Error).message;
      this.snackColor = '#e61e25';
      this.snack = true;
      return;
    }
    this.$forceUpdate();
    await this.resetForm();
    this.addProjectDialog = false;
  }

  async resetForm(): Promise<void> {
    this.selectedProjects = [];
    await (this.$refs.editUsersForm as HTMLFormElement).resetValidation();
  }

  async addManagedUsers(): Promise<void> {
    if (this.customers.length === 1) {
      [this.selectedCustomer] = this.customers;
    }
    if (this.organizations.length === 1) {
      [this.selectedOragnization] = this.organizations;
    }
    const selectedUserGuids = this.selectedManageUsers.map((u) => u.guid);
    const selectedManagedUsers = this.canManageUsers.filter(
      (user) => selectedUserGuids.includes(user.guid),
    );

    // eslint-disable-next-line no-restricted-syntax
    for await (const user of selectedManagedUsers) {
      await this.postUserManagement({
        managerUserGuid: this.selectedUsers[0].guid,
        subjectUserGuid: user.guid,
        organizationGuid: this.selectedOragnization.guid,
        customerGuid: this.selectedCustomer.guid,
      });
    }
    this.addManagedUserOpen = false;
  }

  editSelect(): void {
    this.$emit('editSelect');
  }

  addProject(): void {
    this.addProjectOpen = true;
    this.$emit('editSelect');
  }

  addManagedUser(): void {
    this.addManagedUserOpen = true;
    this.$emit('editSelect');
  }

  customerGroupingsToString(customerGroup: { customer: string, users: string[] }): string {
    const users = customerGroup.users.toString().replaceAll(',', ', ');
    let predicate = '';

    if (customerGroup.customer === 'no assigned customer') {
      predicate = customerGroup.users.length > 1 ? 'have' : 'has';
    } else {
      predicate = customerGroup.users.length > 1 ? 'are from' : 'is from';
    }

    return `${users} ${predicate} ${customerGroup.customer}`;
  }

  populateCustomeerGroupings(
    user: any, customerName: string, retVal: { customer: string, users: string[] }[],
  ): void {
    const existingCustomerUser = retVal.find((cu) => cu.customer === customerName);

    if (!existingCustomerUser) {
      const newCustomerUsers = {
        customer: customerName,
        users: [user.fullName],
      };

      retVal.push(newCustomerUsers);
    } else {
      existingCustomerUser.users.push(user.fullName);
    }
  }
}
