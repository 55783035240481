


























































































import { TASK_TYPE_STRING } from '@/common/Constants';
import { AssignmentActions } from '@/store/assignments/actions';
import { AssignmentPost } from '@/store/assignments/types';
import { RoutingData } from '@/store/routing/types';
import { AssetData } from '@/types';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  AssignmentTaskType,
  commonTaskTypes, macpTaskTypes, pacpTaskTypes,
} from './Constants';

export interface AssignmentType {
  name: string,
  value: boolean,
  subValue?: AssignmentType[],
}

const assignmentsModule = namespace('assignments');

@Component({})
export default class NewAssignmentDialog extends Vue {
  @assignmentsModule.Action(AssignmentActions.POST_ASSIGNMENTS) postAssignments;

  @assignmentsModule.Action(AssignmentActions.POST_ROUTE_ASSIGNMENTS) postRouteAssignments;

  @assignmentsModule.State('loading') loading;

  @assignmentsModule.State('loadError') loadError;

  @assignmentsModule.State('postAssignmentData') postAssignmentData;

  @Prop({ default: false }) showNewAssignDialog: boolean;

  @Prop() selectedAssets: AssetData[] | RoutingData[];

  @Prop() activeTabString: string;

  @Prop() isRoutingTab: boolean;

  displayDupeMessage = false;

  macpCheckBox: AssignmentType[] = this.createAssignmentType(macpTaskTypes);

  pacpCheckBox: AssignmentType[] = this.createAssignmentType(pacpTaskTypes);

  commonCheckBox: AssignmentType[] = this.createAssignmentType(commonTaskTypes);

  showSnackBar = false;

  snackColor = 'green';

  snackBarMessage = '';

  createAssignmentType(taskType: AssignmentTaskType[], isSubVal = false): AssignmentType[] {
    const retVal = taskType.map((x) => ({
      name: x.name,
      subValue: x.subValue ? this.createAssignmentType(x.subValue, true) : null,
      value: false,
    } as AssignmentType));
    return retVal;
  }

  /**
   * @description Gets if the component is loading
   */
  get isLoading(): boolean {
    return this.loading != null ? this.loading : false;
  }

  // return options for Manhole or Pipes
  get checkBoxForm(): AssignmentType[] {
    let retVal = [];
    switch (this.activeTabString) {
      case 'Manhole':
      case 'Manhole Routes':
        retVal = this.macpCheckBox;
        break;
      case 'Line Segment':
      case 'Line Segment Routes':
        retVal = this.pacpCheckBox;
        break;
      default:
        break;
    }

    return retVal.concat(this.commonCheckBox);
  }

  closeDialog(): void {
    this.displayDupeMessage = false;
    this.$emit('closeDialog');
  }

  async submitAssignments(): Promise<void> {
    // Check if we have any task type groups with no sub value selected.
    // We want to show an error since we don't have an actual task type from that grouping
    // Avoids task type group being selected but no work order is created.
    if (!this.isInvalidSubValueSelection()) {
      return;
    }
    const selectedAssignmentTypes: string[] = this.getSelectedAssignmentTypes();
    const payload: AssignmentPost[] = [];
    this.selectedAssets.forEach((asset) => {
      payload.push({
        assetGuid: asset.guid,
        workOrderTypes: selectedAssignmentTypes,
      });
    });

    if (this.isRoutingTab) {
      await this.postRouteAssignments(payload);
    } else {
      await this.postAssignments(payload);
    }

    if (this.postAssignmentData != null && this.postAssignmentData !== 'Success') {
      this.displayDupeMessage = true;
    }
    this.pushSnackBar();
    // reload data on a clean add
    if (this.loadError == null) {
      await this.$emit('refresh');
    }
  }

  getSelectedAssignmentTypes(): string[] {
    const selectedAssignmentTypes: string[] = [];
    this.checkBoxForm.forEach((taskType) => {
      if (taskType.value === true) {
        if (taskType.subValue == null) {
          selectedAssignmentTypes.push(this.getTaskTypeGuid(taskType.name));
        } else {
          const subValue = taskType.subValue.filter((sv) => sv.value === true);
          subValue.forEach(
            (value) => selectedAssignmentTypes.push(this.getTaskTypeGuid(value.name)),
          );
        }
      }
    });
    return selectedAssignmentTypes;
  }

  /**
   * @description Checks if there is a any task type group which doesn't have a sub value
   * Will display an error message in the snackbar
   * @returns False if there is a task type group without a sub value or True
   */
  isInvalidSubValueSelection(): boolean {
    for (let i = 0; i < this.checkBoxForm.length; i += 1) {
      const taskType = this.checkBoxForm[i];
      if (taskType.value === true && taskType.subValue != null) {
        const hasValidSubValue = taskType.subValue.find((sv) => sv.value === true) != null;
        if (!hasValidSubValue) {
          this.snackBarSubValueNotSelected(taskType.name);
          return false;
        }
      }
    }
    return true;
  }

  /**
   * @description Displays an error message in the snackbar
   * for missing sub value on a task type grouping.
   */
  snackBarSubValueNotSelected(parentName: string): void{
    this.showSnackBar = true;
    this.snackColor = '#e61e25';
    this.snackBarMessage = `You must select a work order type for "${parentName}"`;
  }

  pushSnackBar(): void {
    this.showSnackBar = true;
    this.snackColor = this.loadError != null ? '#e61e25' : 'green';
    this.snackBarMessage = this.loadError != null ? 'Error Adding Assignments' : 'Assignments Added';
  }

  getTaskTypeGuid(desc: string): string {
    return TASK_TYPE_STRING.find((tts) => tts.desc === desc).guid;
  }

  getAssetName(asset: AssetData | RoutingData): string {
    if (!asset) {
      return '';
    }
    return this.isRoutingTab ? (asset as RoutingData).routeName : (asset as AssetData).name;
  }
}
