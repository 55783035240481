import { MutationTree } from 'vuex';
import { OrganizationData, OrganizationState } from './types';

// eslint-disable-next-line no-shadow
export enum OrganizationMutations {
  SET_GET_LOADING = 'SET_GET_LOADING',
  SET_GET_LOAD_ERROR = 'SET_GET_LOAD_ERROR',
  SET_ORGANIZATION_DATA = 'SET_ORGANIZATION_DATA',
  SET_POST_LOADING = 'SET_POST_LOADING',
  SET_POST_LOAD_ERROR = 'SET_POST_LOAD_ERROR',
  SET_POST_ORGANIZATION_OBJECT = 'SET_POST_ORGANIZATION_OBJECT',
  SET_PATCH_LOADING = 'SET_PATCH_LOADING',
  SET_PATCH_LOAD_ERROR = 'SET_PATCH_LOAD_ERROR',
  SET_PATCH_ORGANIZATION_OBJECT = 'SET_PATCH_ORGANIZATION_OBJECT',
  SET_DELETE_LOADING = 'SET_DELETE_LOADING',
  SET_DELETE_LOAD_ERROR = 'SET_DELETE_LOAD_ERROR',
}

export const mutations: MutationTree<OrganizationState> = {
  [OrganizationMutations.SET_GET_LOADING](state, payload: boolean) {
    state.getLoading = payload;
  },
  [OrganizationMutations.SET_GET_LOAD_ERROR](state, payload: string | undefined) {
    state.getLoadError = payload;
  },
  [OrganizationMutations.SET_ORGANIZATION_DATA](state, payload: OrganizationData[] | undefined) {
    state.organizationData = payload;
  },
  [OrganizationMutations.SET_POST_LOADING](state, payload: boolean) {
    state.postLoading = payload;
  },
  [OrganizationMutations.SET_POST_LOAD_ERROR](state, payload: string | undefined) {
    state.postLoadError = payload;
  },
  [OrganizationMutations.SET_POST_ORGANIZATION_OBJECT](state,
    payload: OrganizationData | undefined) {
    state.postorganizationObject = payload;
  },
  [OrganizationMutations.SET_PATCH_LOADING](state, payload: boolean) {
    state.patchLoading = payload;
  },
  [OrganizationMutations.SET_PATCH_LOAD_ERROR](state, payload: string | undefined) {
    state.patchLoadError = payload;
  },
  [OrganizationMutations.SET_PATCH_ORGANIZATION_OBJECT](state,
    payload: OrganizationData | undefined) {
    state.patchorganizationObject = payload;
  },
  [OrganizationMutations.SET_DELETE_LOADING](state, payload: boolean) {
    state.deleteLoading = payload;
  },
  [OrganizationMutations.SET_DELETE_LOAD_ERROR](state, payload: string | undefined) {
    state.deleteLoadError = payload;
  },
};
