import { MutationTree } from 'vuex';
import { UserPrefsState } from './types';

// eslint-disable-next-line no-shadow
export enum UserPrefsMutations {
    SET_DISPLAY_METRIC_STATE = 'SET_DISPLAY_METRIC_STATE',
    SET_SELECTED_MAP_ASSET = 'SET_SELECTED_MAP_ASSET',
    SET_ZOOM_FILTER = 'SET_ZOOM_FILTER',
    SET_DEFECT_CODEDESC = 'SET_DEFECT_CODEDESC'
}

export const mutations : MutationTree<UserPrefsState> = {
  [UserPrefsMutations.SET_DISPLAY_METRIC_STATE](state, payload: boolean) {
    state.displayImperial = payload;
  },
  [UserPrefsMutations.SET_SELECTED_MAP_ASSET](state, payload: string) {
    state.selectedMapAssetGuid = payload;
  },
  [UserPrefsMutations.SET_ZOOM_FILTER](state, payload: boolean) {
    state.useZoomFilter = payload;
  },
  [UserPrefsMutations.SET_DEFECT_CODEDESC](state, payload: boolean) {
    state.useDefectCodeDesc = payload;
  },
};
