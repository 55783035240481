





























import { Component, Prop, Vue } from 'vue-property-decorator';

export interface Counts {
  categoryID: number;
  count: number;
}

@Component({})
export default class WorkOrderCounts extends Vue {
  @Prop() categories;

  @Prop() readonly counts: Counts[] | undefined;

  selectedCategoryIndex = 0;

  mounted(): void {
    this.getRouteParams();
  }

  getCategoryCount(categoryID: number): string {
    if (!this.counts || this.counts.length === 0) {
      return 'loading';
    }
    const category = this.counts[categoryID];
    if (!category || category.count <= 0) {
      return '-';
    }
    return category.count.toString();
  }

  onCategoryChange(val: boolean, index: number): void {
    this.$emit('setCreateNewAssign', val);
    this.selectedCategoryIndex = index;
    this.$emit('setSelectedCategoryIndex', index);
  }

  getRouteParams(): void {
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      queries.forEach((pair) => {
        const [key, value] = pair;
        if (key === 'category') {
          this.selectedCategoryIndex = parseInt(value as string, 10);
        } else if (key === 'workOrderStatus') {
          const task = this.categories.findIndex((item) => item.label === value);
          this.onCategoryChange(false, task);
        }
      });
    }
  }
}
