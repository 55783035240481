










import { CREW_LEAD } from '@/auth/roles';
import { DeploymentsActions } from '@/store/deployments/actions';
import { DeploymentData, EditFieldObject } from '@/store/deployments/types';
import { ProjectData } from '@/store/project/types';
import { DetailedUserData } from '@/store/users/types';
import {
  Vue, Component, Prop, PropSync,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TASK_RESULT_DETAILS } from '@/common/Constants';
import EditDeploymentForm from './EditDeploymentForm.vue';

const deploymentsModule = namespace('deployments');
const projectModule = namespace('project');
const userModule = namespace('users');

@Component({ components: { EditDeploymentForm } })
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
export default class DeploymentEditSoloPipe extends Vue {
  @Prop() readonly deployment: DeploymentData;

  @PropSync('isOpen') showDialog: boolean;

  @projectModule.State('data') project: ProjectData | undefined;

  @projectModule.State('loading') loading: boolean;

  @userModule.State('loading') allUsersLoading: boolean;

  @userModule.State('allDetailedUserData') allUsers: DetailedUserData[];

  @deploymentsModule.Action(DeploymentsActions.PATCH_DEPLOYMENT_DATA) patchDeploymentData;

  showSnackbar = false;

  snackbarColor = '';

  snackbarMessage = '';

  get editFields(): EditFieldObject[] {
    return [
      { name: 'Asset', value: '', inputType: 'combo', desc: 'name', options: this.assetOptions },
      { name: 'Entry Manhole', value: '', inputType: 'select', desc: 'entryMH', options: this.entryMhOptions },
      { name: 'Upstream Manhole', value: '', inputType: 'combo', desc: 'usmh', options: this.manholeOptions },
      { name: 'Downstream Manhole', value: '', inputType: 'combo', desc: 'dsmh', options: this.manholeOptions },
      { name: 'Direction', value: '', inputType: 'select', desc: 'direction', options: ['Upstream', 'Downstream'] },
      { name: 'Date Inspected', value: '', inputType: 'date', desc: 'date' },
      { name: 'Operator', value: '', inputType: 'select', desc: 'operator', options: this.operatorOptions },
      { name: 'Result', value: '', inputType: 'select', desc: 'result', options: this.resultOptions },
      { name: 'Additional Information', value: '', inputType: 'text', desc: 'additionalInfo' },
    ];
  }

  showInspDatePicker = false;

  doNotCode = false;

  showWarning = false;

  get assetOptions(): string[] {
    if (!this.project) return [];
    return this.project.assets
      .filter((asset) => asset.type === 'Line Segment')
      .map((asset) => asset.name)
      .sort();
  }

  get entryMhOptions(): string[] {
    if (!this.deployment) return [];
    return [this.deployment.usmh, this.deployment.dsmh];
  }

  get manholeOptions(): string [] {
    if (!this.project) return [];
    return this.project.assets
      .filter((asset) => asset.type === 'Manhole')
      .map((asset) => asset.name)
      .sort();
  }

  get operatorOptions(): string[] {
    if (!this.allUsers) return [];
    return this.allUsers
      .filter((u) => u.role.find((r) => r.roleGuid === CREW_LEAD))
      .map((u) => `${u.firstname} ${u.lastname}`)
      .sort();
  }

  get resultOptions(): string[] {
    return TASK_RESULT_DETAILS.map((tr) => tr.description);
  }

  updateDeploymentData(deploymentUpdate: DeploymentData): void {
    this.$emit('updateDeploymentData', deploymentUpdate);
  }
}
