var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"id":"new-assign-table","show-select":"","fixed-header":"","item-key":"guid","headers":_vm.assignmentHeaders,"items":_vm.filteredTableData,"sort-by":_vm.sortBy,"sort-desc":_vm.sortByDesc,"items-per-page":500,"footer-props":{
    'disable-items-per-page': false,
    'items-per-page-options':[500, 1000, -1],
  }},on:{"update:sortDesc":function($event){_vm.sortByDesc=$event},"update:sort-desc":function($event){_vm.sortByDesc=$event}},scopedSlots:_vm._u([_vm._l((_vm.assignmentHeaders),function(headerData,index){return {key:("header." + (headerData.value)),fn:function(ref){
  var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{key:("asset-menu-" + index),staticStyle:{"z-index":"100000"},attrs:{"value":_vm.matchFilters.find(function (f) { return f.header === headerData.value; }).open,"offset-y":"","max-height":"500","max-width":"300","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(headerData.text !== 'Inspection')?_c('v-btn',_vm._g(_vm._b({key:("asset-btn-" + index),staticClass:"filter-btn",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.setFilterMenu(header.value, true)}}},'v-btn',attrs,false),on),[(!_vm.columnFiltersActive(header.value))?_c('v-icon',{staticClass:"filter-icon"},[_vm._v(" mdi-filter-outline ")]):_c('v-icon',{staticClass:"filter-icon"},[_vm._v("mdi-filter")])],1):_vm._e()]}}],null,true)},[_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"menu-close"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setFilterMenu(header.value, false)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{attrs:{"id":"match-filter"}},[_c('v-select',{attrs:{"items":_vm.getMatchFilterMethod(header.value),"label":"Match","hide-details":true,"append-icon":"mdi-chevron-down"},model:{value:(
              _vm.matchFilters.find(function (f) { return f.header === header.value; }).method
            ),callback:function ($$v) {_vm.$set(_vm.matchFilters.find(function (f) { return f.header === header.value; }), "method", $$v)},expression:"\n              matchFilters.find((f) => f.header === header.value).method\n            "}}),_c('v-text-field',{class:{ 'my-text-style': _vm.isUpdated(header.value) },attrs:{"type":_vm.matchFilters.find(function (f) { return f.header === header.value; }).type,"label":"Term","hide-details":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchFeatures(header.value)}},model:{value:(
              _vm.matchFilters.find(function (f) { return f.header === header.value; }).tempValue
            ),callback:function ($$v) {_vm.$set(_vm.matchFilters.find(function (f) { return f.header === header.value; }), "tempValue", $$v)},expression:"\n              matchFilters.find((f) => f.header === header.value).tempValue\n            "}})],1),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","min-width":"30%"},on:{"click":function($event){return _vm.clearMatchFilter(header.value)}}},[_vm._v(" Clear ")]),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","color":"#0c6599","min-width":"60%"},on:{"click":function($event){return _vm.searchFeatures(header.value)}}},[_vm._v(" Apply Filter ")])],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.filterValues[headerData.value]),function(item,index){return _c('v-list-item',{key:((headerData.value) + "-filter-item-" + index)},[_c('v-checkbox',{staticClass:"filter-option-checkbox",attrs:{"dense":"","hide-details":true,"label":item.toString(),"value":item,"v-model":_vm.matchFilters.find(function (f) { return f.header === header.value; }).options}})],1)}),1)],1),(header.value === _vm.sortBy)?_c('v-btn',{key:("sort-active-" + index),attrs:{"small":"","icon":""},on:{"click":function($event){_vm.sortByDesc = !_vm.sortByDesc}}},[(_vm.sortByDesc)?_c('v-icon',[_vm._v("mdi-arrow-down")]):_c('v-icon',[_vm._v("mdi-arrow-up")])],1):(headerData.text !== 'Inspection')?_c('v-btn',{key:("sort-inactive-" + index),staticClass:"sort-inactive",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.sortBy = headerData.value;
        _vm.sortByDesc = false;}}},[_c('v-icon',[_vm._v("mdi-minus")])],1):_vm._e()]}}}),{key:"item.score",fn:function(ref){
        var item = ref.item;
return [_c('span',[_c('v-chip',{staticClass:"defect-score-chip",attrs:{"x-small":"","color":_vm.getScoreColorTable(item.score)}},[_vm._v(" "+_vm._s(item.score == -1 ? "-" : item.score)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedAssets),callback:function ($$v) {_vm.selectedAssets=$$v},expression:"selectedAssets"}})}
var staticRenderFns = []

export { render, staticRenderFns }