





























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectData } from '@/store/project/types';
import { AssetActions } from '@/store/asset/actions';
import { UploadMappingResults } from '@/store/types';
import { iBaseCampFileMapper, iBasecampColumn } from '../ScopeAddition/iBaseCampFileMapper';
import filemapper from '../ScopeAddition/FileMapper.vue';
import iFileMapper from '../ScopeAddition/iFileMapper';

const ALERT_TYPE_ERROR = 'error';
const ALERT_TYPE_SUCESS = 'success';
const assetModule = namespace('asset');
const projectModule = namespace('project');

  @Component({
    components: {
      filemapper,
    },
  })
export default class ImportMsi extends Vue {
    valid = true as boolean;

    hideDetails = true as boolean;

    selectedInputMethod = '' as string;

    selectedType = '' as string;

    showAlert = false as boolean;

    alertType = 'error' as string;

    alertText = '' as string;

    selectedFile = [];

    inputItems = ['Import File'];

    snackbar = false as boolean;

    snackbarText = 'Input submitted';

    assetChosen = '' as string;

    uploadFileColumns = [];

    rzFileColumns = [];

    uploadFileName = '' as string;

    selectedUploadFileColumns = '' as string;

    haveuploadFileColumns = false as boolean;

    files: string[] = [];

    selectedCrossSectionFile = '';

    selectedWallLossFile = '';

    selectedAsset = '';

    selectedInspection = '';

    // TODO: replace actions with new import methods
    @assetModule.Action(AssetActions.POST_FILE_CROSS_SECTION_DATA) postFileData;

    @assetModule.Action(AssetActions.POST_FILE_CROSS_SECTION_MAPPING_DATA) postMappingData;

    @projectModule.State('data') project: ProjectData | undefined;

    isValidInput(): boolean {
      if ((this.$refs.formAddScope as Vue & { validate: () => boolean }).validate()) {
        this.showAlert = false;
        return true;
      }
      this.alertType = ALERT_TYPE_ERROR;
      this.alertText = 'Please Enter Required Fields.';
      this.showAlert = true;
      return false;
    }

    onClickSubmit(): void {
      if (this.isValidInput()) {
        this.snackbar = true;
        (this.$refs.formAddScope as Vue & { reset: () => void }).reset();
        // TODO: Handle valid form/CSV submission
      }
    }

    async onClickUpload(): Promise<void> {
      if (!this.isValidInput()) return;
      if (!(this.$refs.formAddScope as any).validate()) return;
      const payload = { selectedFile: this.selectedFile, assetChosen: this.assetChosen };
      // TODO: replace actions with new import methods
      const status = await this.postFileData(payload);
      if (status.Complete) {
        this.alertType = ALERT_TYPE_SUCESS;
        this.alertText = 'Please map the columns';
        this.showAlert = true;
        this.uploadFileName = status.FileName;
        this.files = this.formatFileList(status.Files);
      } else {
        this.alertType = ALERT_TYPE_ERROR;
        this.alertText = status.ErrorMessage;
        this.showAlert = true;
      }
    }

    onClickClear(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.formAddScope as Vue & { reset: () => void }).reset();
      this.selectedFile = [];
      this.haveuploadFileColumns = false;
      this.showAlert = false;
    }

    onClickCancel(): void {
      this.onClickClear();
      this.$emit('close');
    }

    clearAlertBar(): void {
      this.alertText = '';
      this.showAlert = false;
    }

    clear(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.formAddScope as any).reset();
    }

    async ColumnsMappedComplete(fileMapper: iFileMapper[]): Promise<void> {
      // const uploadFileMapping = new UploadFile();
      const basecampFileMapper = {} as iBaseCampFileMapper;
      basecampFileMapper.FileName = this.uploadFileName;
      // eslint-disable-next-line prefer-destructuring
      basecampFileMapper.ProjectGuid = this.project.guids[0];
      basecampFileMapper.AssetType = fileMapper[0].AssetSelected;
      basecampFileMapper.Columns = [];
      for (let i = 0; i < fileMapper.length; i += 1) {
        const bc = {} as iBasecampColumn;
        bc.RedzoneColumn = fileMapper[i].RzColumn;
        bc.FileColumn = fileMapper[i].FileColumn;
        basecampFileMapper.Columns.push(bc);
      }

      // const status = await uploadFileMapping.ReturnMapper(JSON.stringify(basecampFileMapper),
      //   this.$store.getters.GetAuth0Token);
      const status = await this.postMappingData(JSON.stringify(basecampFileMapper));
      if (status.Complete) {
        this.showAlert = true;
        this.alertText = 'Submission Succesful';
        this.alertType = ALERT_TYPE_SUCESS;
        this.snackbar = true;
        this.snackbarText = 'Submitted Scope Successfully';
        this.haveuploadFileColumns = false;
        this.selectedFile = [];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.formAddScope as any).reset();

        this.$emit('reload');
        this.$emit('refreshProject');
      } else {
        this.alertType = ALERT_TYPE_ERROR;
        this.alertText = status.ErrorMessage;
        this.showAlert = true;
      }
    }

    formatFileList(files: string[]): string[] {
      const fileList: string[] = [];
      files.forEach((file) => {
        const extensionSplit = file.split('.csv');
        if (extensionSplit[1] != null) {
          fileList.push(file);
        }
      });
      return fileList;
    }

    async sendFileMapping(): Promise<void> {
      const asset = this.project.assets.find((a) => a.name === this.selectedAsset);
      const results: UploadMappingResults = await this.postMappingData({
        AssetGuid: asset.guid,
        InspectionGuid: this.selectedInspection !== '' ? this.selectedInspection : null,
        ZipFile: this.uploadFileName,
        CrossSectionFile: this.selectedCrossSectionFile,
        WallLossFile: this.selectedWallLossFile === '' ? undefined : this.selectedWallLossFile,
      });
      if (results.Complete === true) {
        this.snackbar = true;
        this.snackbarText = results.Data ? results.Data : results.ErrorMessage;
        this.selectedAsset = '';
        this.selectedFile = [];
        this.files = [];
        this.selectedInspection = '';
        this.selectedCrossSectionFile = '';
        this.showAlert = false;
      }
    }
}
