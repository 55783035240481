




































import Vue from 'vue';
import LineSegmentEntryForm from './LineSegmentEntryForm.vue';
import ManholeEntryForm from './ManholeEntryForm.vue';
import UploadScopeFile from './UploadScopeFile.vue';

export default Vue.extend({
  components: {
    ManholeEntryForm,
    LineSegmentEntryForm,
    UploadScopeFile,
  },
  data() {
    return {
      inputOptions: ['Upload File', 'Form Entry'],
      assetTypeOptions: ['Manhole', 'Line Segment'],
      selectedMethod: 'Upload File' as string,
      selectedAssetType: '' as string,
      selectedComponent: '' as string,
    };
  },
  computed: {
    activeComponent() {
      switch (this.selectedMethod) {
        case 'Form Entry':
          switch (this.selectedAssetType) {
            case 'Manhole':
              // Return manhole form entry component
              return 'ManholeEntryForm';
            case 'Line Segment':
              // Return line segment form entry component
              return 'LineSegmentEntryForm';
            default:
              return '';
          }
        case 'Upload File':
          // Return file upload component
          return 'UploadScopeFile';
        default:
          return '';
      }
    },
  },
  methods: {
    refreshProject() {
      this.$emit('refreshProject');
    },
  },
});
