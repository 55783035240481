











































































import StackedHorizontalGraph from '@/components/MSIGraph/StackedHorizontalGraph.vue';
import { CrewCounts, CrewCountsDTO, RobotCounts } from '@/store/metrics/types';
import { format } from 'date-fns';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component({
  components: {
    StackedHorizontalGraph,
  },
})
export default class CrewCountsCard extends Vue {
  @Prop() readonly crewCountDTO: CrewCountsDTO;

  @Prop() readonly expectedJobName: string;

  @Prop() readonly projectStartDate: Date;

  @Prop() readonly projectEndDate: Date;

  startDate = new Date();

  endDate = new Date();

  selectedCustomRange = [
    format(this.startDate, 'yyyy-MM-dd'),
    format(this.startDate, 'yyyy-MM-dd'),
  ];

  dateSelectionOptions = [
    {
      text: 'Today',
      func: (): void => {
        const date = new Date();
        this.startDate = date;
        this.endDate = date;
        this.setDate(this.startDate, this.endDate);
      },
    },
    {
      text: 'Yesterday',
      func: (): void => {
        const date = new Date();
        date.setDate(new Date().getDate() - 1);
        this.startDate = date;
        this.endDate = date;
        this.setDate(this.startDate, this.endDate);
      },
    },
    {
      text: 'This Month',
      func: (): void => {
        const date = new Date();
        this.startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.setDate(this.startDate, this.endDate);
      },
    },
    {
      text: 'Project Duration',
      func: (): void => {
        this.startDate = this.projectStartDate;
        this.endDate = this.projectEndDate;
        this.setDate(this.startDate, this.endDate);
      },
    },
    {
      text: 'Custom',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      func: (): void => {
        if (this.startDate != null && this.endDate != null) {
          this.setDate(this.startDate, this.endDate);
        }
      },
    },
  ]

  robotColors = [
    '#D9EFFC',
    '#A1D8F7',
    '#55B8F1',
    '#0C6599',
    '#06324B',
    '#000000',
  ]

  selection: string = this.dateSelectionOptions[0].text;

  get jobName(): string {
    return this.crewCountDTO?.jobName != null && this.crewCountDTO?.jobName !== '' ? this.crewCountDTO.jobName : this.expectedJobName;
  }

  get crewCounts(): CrewCounts[] {
    return this.crewCountDTO?.crewCounts != null ? this.crewCountDTO.crewCounts : [];
  }

  reset(): void {
    const firstOptions = this.dateSelectionOptions[0];
    this.selection = firstOptions.text;
    firstOptions.func();
  }

  get crewCollectionTotalCount(): number {
    return this.crewCounts
      .flatMap((cc) => cc.robotCounts)
      .reduce((a, b) => a + b.completed, 0);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getChartDataByCrew(crew: CrewCounts): any {
    const labels: string[] = [];
    const datasets = [];

    this.getFilteredRobots([...crew.robotCounts]).forEach((robot, index) => {
      labels.push(robot.robotName);
      datasets.push({
        data: [robot.completed],
        backgroundColor: this.getColorFromIndex(index),
        label: robot.robotName,
      });
    });

    return { labels, datasets };
  }

  getColorFromIndex(index: number): string {
    return index <= this.robotColors.length - 1
      ? this.robotColors[index]
      : this.robotColors[this.robotColors.length - 1];
  }

  getFilteredRobots(robots: RobotCounts[]): RobotCounts[] {
    return robots.sort((a, b) => a.completed - b.completed);
  }

  confirmDateRange(): void {
    if (this.selectedCustomRange == null || this.selectedCustomRange.length !== 2) {
      return;
    }
    this.setDate(new Date(this.selectedCustomRange[0]), new Date(this.selectedCustomRange[1]));
  }

  setDate(startDate: Date, endDate: Date): void {
    if (startDate <= endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
    } else {
      this.startDate = endDate;
      this.endDate = startDate;
    }
    this.selectedCustomRange = [
      this.startDate.toISOString().substring(0, 10), this.endDate.toISOString().substring(0, 10)];
    this.emitNewDates();
  }

  emitNewDates(): void {
    this.$emit('refresh',
      this.jobName,
      this.startDate.toISOString().substring(0, 10),
      this.endDate.toISOString().substring(0, 10));
  }

  pickDateSelection(val: string): void {
    this.selection = val;
    const selectedOption = this.dateSelectionOptions.find((value) => value.text === this.selection);
    if (selectedOption != null) {
      selectedOption.func();
    }
  }

  get customRangeDateText(): string {
    if (this.selection === 'Custom' && this.startDate != null && this.endDate != null) {
      const formatStart = this.startDate.toISOString().substring(0, 10);
      const formatEnd = this.endDate.toISOString().substring(0, 10);
      const formattedDates = [formatStart, formatEnd];
      return ` (${formattedDates.join(' to ')})`;
    }
    return '';
  }
}
