/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-shadow
export enum ReleaseAssetType {
    MANHOLE = 'MH',
    PIPE = 'Pipe',
}

export interface ReleaseTableTab {
    text: string;
    assetType: ReleaseAssetType;
    monthNumber: number;
    yearNumber: number;
}

export interface ReleaseTableMetrics {
    releaseTotals: number;
    inspections: number;
    earliestInspectionDate: string;
    latestInspectionDate: string;
}
