var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"grid-container"},[(!_vm.canLoadWorker)?_c('div',{staticClass:"workerBrowserSupport"},[_vm._v(" Please enable web workers to use Integrity ")]):_vm._e(),(!_vm.canLoadWorker || _vm.importLoad)?_c('div',{staticClass:"cover"}):_vm._e(),(_vm.importLoad)?_c('div',{staticClass:"spinner"}):_vm._e(),(_vm.canLoadWorker)?_c('v-navigation-drawer',{attrs:{"app":"","dark":"","rail":"","permanent":"","width":"56px"}},[_c('div',{attrs:{"id":"app-nav-container"}},[_c('div',{attrs:{"id":"menu-items"}},[_c('v-btn',{staticClass:"logo-btn",attrs:{"img":"","to":"/"}},[_c('img',{staticClass:"header-logo",attrs:{"src":require("../src/assets/rzcirclelogo.png")}})]),(_vm.$route.name)?_c('v-list',{attrs:{"nav":""}},_vm._l((_vm.navItems),function(item,index){return _c('v-tooltip',{key:index,attrs:{"right":"","z-index":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({class:_vm.selectedPage === item.title ? 'menu-item-selected' : 'menu-item',attrs:{"href":_vm.$router.resolve(item.link).href},on:{"mouseover":function($event){_vm.activeMenuItem = item}}},'v-list-item',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])}),1):_vm._e()],1),_c('user-menu',{staticStyle:{"z-index":"100"},attrs:{"id":"user-menu","dense":"","user":_vm.$auth.user,"isAuthenticated":_vm.$auth.isAuthenticated,"error":_vm.$auth.error,"isLoading":_vm.$auth.loading,"hasIssuesGettingUser":_vm.$auth.hasIssuesGettingUser},on:{"onLogin":function($event){return _vm.$auth.loginWithRedirect({})},"onLogout":function($event){return _vm.$auth.logout({returnTo: _vm.baseURI})},"onProfile":function($event){return _vm.$router.push('/profile')},"onAdminSelect":function($event){return _vm.$router.push('/admin')}}})],1)]):_vm._e(),(_vm.showExpandMenu && _vm.canLoadWorker)?_c('div',{attrs:{"id":"expand-menu"},on:{"mouseleave":function($event){return _vm.resetExpand()}}},[_c('h3',{attrs:{"id":"expand-menu-header"}},[_vm._v(_vm._s(_vm.activeMenuItem.title))]),(_vm.activeMenuItem.value === 'projects')?_c('v-list',{attrs:{"nav":"","color":"#666666","dark":""}},[_c('v-list-item',{staticClass:"expand-item",attrs:{"href":_vm.$router.resolve({ name: 'projectSelection' }).href}},[_c('v-list-item-title',[_vm._v("Project Selection")])],1),(_vm.hasPermissionProjectSelectionCreateProject
          || _vm.hasPermissionProjectSelectionEditProject)?_c('v-list-group',{attrs:{"color":"white","value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{attrs:{"id":"section-title"}},[_vm._v("MPS")])]},proxy:true}],null,false,3118555500)},[(_vm.hasPermissionProjectSelectionCreateProject)?_c('v-list-item',{staticClass:"expand-item",attrs:{"href":_vm.$router.resolve({ name: 'MPS' }).href}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('v-list-item-title',[_vm._v("Add")])],1):_vm._e(),(_vm.routeParamIds !== '' && _vm.hasPermissionProjectSelectionEditProject)?_c('v-list-item',{staticClass:"expand-item",attrs:{"href":_vm.$router.resolve({
            name: 'MPS',
            params: {
              guid: _vm.routeParamIds ? JSON.parse(_vm.routeParamIds)[0] : ''
            }
          }).href}},[_c('v-icon',[_vm._v("mdi-pencil-outline")]),_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e()],1):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.breadcrumbItems && _vm.breadcrumbItems.length > 1 && _vm.canLoadWorker)?_c('v-breadcrumbs',{attrs:{"id":"breadcrumbs","large":"","items":_vm.breadcrumbItems},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [(item.text.includes('...'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-breadcrumbs-item',_vm._g(_vm._b({attrs:{"href":item.to !== undefined ? _vm.$router.resolve(item.to).href : ''}},'v-breadcrumbs-item',attrs,false),on),[_vm._v(_vm._s(item.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.fullProjectName))])]):_c('v-breadcrumbs-item',{attrs:{"href":item.to !== undefined ? _vm.$router.resolve(item.to).href : ''}},[_vm._v(_vm._s(item.text))])]}}],null,false,2499218500)}):_vm._e(),(_vm.canLoadWorker)?_c('v-main',[_c('v-container',{staticClass:"px-4",attrs:{"fluid":""}},[_c('router-view')],1)],1):_vm._e(),(_vm.canLoadWorker)?_c('v-footer',{staticClass:"footer"},[_c('div',[_c('img',{staticClass:"footer-logo-img",attrs:{"src":require("../src/assets/integritylogo.png")}})]),_c('div',{staticClass:"copyright-text"},[_vm._v(" © "+_vm._s(_vm.year)+" ⋅ RedZone Robotics, Inc. ⋅ All Rights Reserved. ")]),_c('div',{staticClass:"social-links"},[_c('a',{staticClass:"social-img",attrs:{"href":"https://www.linkedin.com/company/redzone-robotics"}},[_c('img',{staticClass:"social-img",attrs:{"src":require("../src/assets/linkedin.png")}})]),_c('a',{staticClass:"social-img",attrs:{"href":"https://www.facebook.com/RedZoneRoboticsInc/"}},[_c('img',{staticClass:"social-img",attrs:{"src":require("../src/assets/facebook.png")}})]),_c('a',{staticClass:"social-img",attrs:{"href":"https://twitter.com/redzonepgh?lang=en"}},[_c('img',{staticClass:"social-img",attrs:{"src":require("../src/assets/twitter.png")}})])])]):_vm._e(),(_vm.canLoadWorker)?_c('IntegritySnackBar'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }