import { AssetRef } from '@/types';
import convert from 'convert-units';

function getScoreColor(score: number): string {
  switch (score) {
    case 0:
    case 1:
    case 2:
      return '#008000';
    case 3:
      return '#FFFF00';
    case 4:
      return '#FFA500';
    case 5:
      return '#FF0000';
    default:
      return '#0C6599';
  }
}

function getCollectionColor(status: string): string {
  switch (status) {
    case 'Succeeded':
      return '#008000';
    case 'Incomplete':
    case 'Failed':
    case 'Could Not Locate':
    case 'Could Not Access':
    case 'Does Not Exist':
      return '#FF0000';
    case 'Complete':
      return '#008000';
    case 'Traffic Prevented Access':
      return '#6A0DAD';
    case 'Located But Buried':
      return '#0C6599';
    case 'Unable To Open Access Point':
      return '#0C6599';
    case 'Access Point Severely Surcharged Too Much Debris':
      return '#0C6599';
    case 'Inspection Pending':
      return '#0C6599';
    case 'Component Pending':
      return '#33E0FF';
    case 'Unknown':
      return '#0C6599';
    case 'hidden':
      return '#66666633';
    default:
      return '#0C6599';
  }
}

function getHighestScore(assetCopy: AssetRef): number {
  let returnValue = -1;

  if (assetCopy.inspections !== null) {
    assetCopy.inspections.forEach((a) => {
      if (a.defectScore != null && returnValue < a.defectScore) {
        returnValue = a.defectScore;
      }
    });
  }

  return returnValue;
}

function getDistanceUnitsFtM(displayImperial: boolean): string {
  return displayImperial ? 'ft' : 'm';
}

function getDistanceUnitsInMM(displayImperial: boolean): string {
  return displayImperial ? 'in' : 'mm';
}

function getDisplayDistanceFtM(displayImperial: boolean, value: number): number {
  return displayImperial
    ? Math.round(value * 10) / 10
    : Math.round(convert(value).from('ft').to('m') * 10) / 10;
}

function getDisplayDistanceInMM(displayImperial: boolean, value: number): number {
  return displayImperial
    ? Math.round(value * 10) / 10
    : Math.round(convert(value).from('in').to('mm') * 10) / 10;
}
export default {
  getScoreColor,
  getCollectionColor,
  getHighestScore,
  getDistanceUnitsFtM,
  getDistanceUnitsInMM,
  getDisplayDistanceInMM,
  getDisplayDistanceFtM,
};
