









































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Pie as PieGenerator } from 'vue-chartjs/legacy';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartData,
} from 'chart.js';
import { NasscoScoreMetrics } from '@/store/metrics/types';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Roboto';

// eslint-disable-next-line no-shadow
const enum ScoreType {
  structuralQuick = 'structuralQuick',
  omQuick = 'omQuick',
}

@Component({ components: { PieGenerator } })
export default class InspectionScoresChart extends Vue {
  @Prop() readonly nasscoInspectionScoresData: NasscoScoreMetrics | undefined;

  @Prop({ default: 0 }) readonly selectedTab: number;

  @Prop() readonly selectedGrade: number | undefined;

  @Prop() readonly isStructural: boolean | undefined;

  @Prop({ default: false }) showLabels: boolean;

  @Prop({ default: true }) enableTooltips: boolean;

  chartId = 'inspectionScores';

  get chartClass(): string {
    return this.enableTooltips ? 'inspection-scores' : 'pdf-scores';
  }

  pieLabelLinePlugin = {
    id: 'pieLabelsLine',
    afterDraw(chart: any): void {
      const { ctx } = chart;

      ctx.save();

      const noDataString = 'No Data';

      let color = 'black';
      let labelColor = 'black';

      const leftLabelCoordinates = [];
      const rightLabelCoordinates = [];

      const getSuitableY = (y, yArray = [], direction) => {
        let result = y;
        yArray.forEach((existedY) => {
          if (existedY - 14 < result && existedY + 14 > result) {
            if (direction === 'right') {
              result = existedY + 14;
            } else {
              result = existedY - 14;
            }
          }
        });

        return result;
      };

      const chartCenterPoint = {
        x:
          (chart.chartArea.right - chart.chartArea.left) / 2
          + chart.chartArea.left,
        y:
          (chart.chartArea.bottom - chart.chartArea.top) / 2
          + chart.chartArea.top,
      };

      chart.config.data.labels.forEach((label, i) => {
        if (label === noDataString) {
          return;
        }

        const meta = chart.getDatasetMeta(0);
        const arc = meta.data[i];
        const dataset = chart.config.data.datasets[0];

        const centerPoint = arc.getCenterPoint();
        if (dataset.polyline && dataset.polyline.color) {
          color = dataset.polyline.color;
        }

        if (dataset.polyline && dataset.polyline.labelColor) {
          labelColor = dataset.polyline.labelColor;
        }

        const angle = Math.atan2(
          centerPoint.y - chartCenterPoint.y,
          centerPoint.x - chartCenterPoint.x,
        );

        const point2X = chartCenterPoint.x + Math.cos(angle) * (arc.outerRadius + 15);
        let point2Y = chartCenterPoint.y + Math.sin(angle) * (arc.outerRadius + 15);

        let suitableY;
        if (point2X < chartCenterPoint.x) {
          // on the left
          suitableY = getSuitableY(point2Y, leftLabelCoordinates, 'left');
        } else {
          // on the right
          suitableY = getSuitableY(point2Y, rightLabelCoordinates, 'right');
        }

        point2Y = suitableY;

        let value = dataset.data[i];
        if (value === 0) {
          return;
        }
        if (dataset.polyline && dataset.polyline.formatter) {
          value = dataset.polyline.formatter(value);
        }
        const edgePointX = point2X < chartCenterPoint.x ? 10 : chart.width - 10;

        if (point2X < chartCenterPoint.x) {
          leftLabelCoordinates.push(point2Y);
        } else {
          rightLabelCoordinates.push(point2Y);
        }

        // Draw lines/text
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.arc(centerPoint.x, centerPoint.y, 2, 0, 2 * Math.PI, true);
        ctx.fill();
        ctx.moveTo(centerPoint.x, centerPoint.y);
        ctx.lineTo(point2X, point2Y);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(point2X, point2Y);
        ctx.lineTo(edgePointX, point2Y);
        ctx.stroke();
        const labelAlignStyle = edgePointX < chartCenterPoint.x ? 'left' : 'right';
        const labelX = edgePointX;
        const labelY = point2Y;
        ctx.textAlign = labelAlignStyle;
        ctx.textBaseline = 'bottom';

        ctx.fillStyle = labelColor;
        ctx.fillText(label, labelX, labelY);
      });
      ctx.restore();
    },
  };

  get plugins(): any[] {
    if (!this.showLabels) {
      return [];
    }
    return [this.pieLabelLinePlugin];
  }

  HIGHLIGHTED_OFFSET = 10;

  HIGHLIGHTED_COLOR = '#55ffdd';

  get structuralCountData(): any {
    return this.CountDataForType(ScoreType.structuralQuick);
  }

  get omCountData(): any {
    return this.CountDataForType(ScoreType.omQuick);
  }

  CountDataForType(scoreType: ScoreType): any {
    const currentData = this.MappedChartData(scoreType);

    if (!currentData) {
      return {};
    }

    const total = currentData.reduce((partialSum, a) => partialSum + a, 0);
    const structData = currentData.map((val, key) => ({
      color: this.scoreColors[key],
      label: this.chartLabels[key],
      value: val,
      percent: total === 0 ? 0 : Math.round((val * 100) / total),
    }));
    return { ...structData };
  }

  tabOptions = [
    {
      name: 'Manholes',
      dataEntry: 'macpScores',
      headerValue: 'MACP',
    },
    {
      name: 'Line Segments',
      dataEntry: 'pacpScores',
      headerValue: 'PACP',
    },
  ];

  get currentTab(): any {
    return this.tabOptions[this.selectedTab];
  }

  chartLabels = ['Unscored', '0', '1', '2', '3', '4', '5'];

  scoreColors = [
    '#cccccc',
    '#acd7f5',
    '#6eb8ed',
    '#4196dd',
    '#2b6495',
    '#f6be42',
    '#d53731',
  ];

  offsetArray(scoreType: ScoreType): number[] {
    const arr = new Array(7).fill(0);
    if (
      this.isStructural !== null
      && this.selectedGrade !== null
      && (scoreType === ScoreType.structuralQuick) === this.isStructural
    ) {
      arr[this.selectedGrade + 1] = this.HIGHLIGHTED_OFFSET;
    }
    return arr;
  }

  get StructuralChartData(): ChartData {
    return this.getChartDataWithScoreType(ScoreType.structuralQuick);
  }

  get OMChartData(): ChartData {
    return this.getChartDataWithScoreType(ScoreType.omQuick);
  }

  MappedChartData(scoreType: ScoreType): number[] {
    if (!this.nasscoInspectionScoresData) {
      return [];
    }
    const tab = this.currentTab;
    const nasscoChartData = {
      ...this.nasscoInspectionScoresData[scoreType][tab.dataEntry],
    };
    const mappedData = [
      ...this.chartLabels.map((label) => nasscoChartData[label] ?? 0),
    ];
    if (nasscoChartData[-1] != null) {
      mappedData[0] = nasscoChartData[-1];
    }
    return [...mappedData];
  }

  getChartDataWithScoreType(scoreType: ScoreType): ChartData {
    const noDataString = 'No Data';
    const mappedChartData = this.MappedChartData(scoreType);
    const offsets = this.offsetArray(scoreType);
    const chartDataObj = {
      labels: [...this.chartLabels],
      datasets: [
        {
          backgroundColor: [...this.scoreColors],
          data: [...mappedChartData],
          borderColor: offsets.map((off) => (off === 0 ? '#ffffff' : this.HIGHLIGHTED_COLOR)),
          offset: offsets,
        },
      ],
    };
    if (
      chartDataObj.datasets[0].data.reduce(
        (a, b) => Math.max(a, b),
        -Infinity,
      ) === 0
      && chartDataObj.labels.find((value) => value === noDataString) == null
    ) {
      chartDataObj.labels.push(noDataString);
      chartDataObj.datasets[0].backgroundColor.push('gray');
      chartDataObj.datasets[0].data.push(1);
    }

    return { ...chartDataObj };
  }

  get chartOptions(): any {
    return {
      responsive: true,
      maintainAspectRatio: true,
      layout: {
        padding: {
          top: this.showLabels ? 24 : 0,
          left: this.showLabels ? 40 : 0,
          right: this.showLabels ? 40 : 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: this.enableTooltips,
        },
      },

      onClick: (chart, item) => {
        if (!item || item.length === 0) {
          return;
        }

        const isStructural = chart.chart.canvas.id === ScoreType.structuralQuick;
        const scoreValue = parseInt(this.chartLabels[item[0].index], 10);
        const taskType = this.currentTab.headerValue;

        if (
          this.isStructural !== null
          && this.selectedGrade !== null
          && isStructural === this.isStructural
          && this.selectedGrade === scoreValue
        ) {
          this.$emit('InspectionScoreClick', null, null, taskType);
        } else {
          this.$emit(
            'InspectionScoreClick',
            isStructural,
            scoreValue,
            taskType,
          );
        }
      },
    };
  }
}
