var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projectOverviewContainer"},[_c('div',{staticClass:"header boldFont"},[_vm._v("Project Overview")]),_c('div',{staticClass:"percentData"},[_c('v-tooltip',{staticClass:"complete",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"percentLabelPair"},'div',attrs,false),on),[_c('div',{staticClass:"percent bigFont completePercent"},[_vm._v(_vm._s(_vm.completedCount)+"%")]),_c('div',{staticClass:"label"},[_vm._v("Complete")])])]}}])},[_c('span',[_vm._v("Percentage of the total scope that is Addressed")])]),_c('v-tooltip',{staticClass:"successRate",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"percentLabelPair"},'div',attrs,false),on),[_c('div',{staticClass:"percent bigFont successPercent"},[_vm._v(_vm._s(_vm.addressedCount)+"%")]),_c('div',{staticClass:"label"},[_vm._v("Success")])])]}}])},[_c('span',[_vm._v("Percentage of the total scope that was successfully collected")])]),_c('v-tooltip',{staticClass:"issues",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"percentLabelPair"},'div',attrs,false),on),[_c('div',{staticClass:"percent bigFont issuesPercent"},[_vm._v(_vm._s(_vm.incompleteCount)+"%")]),_c('div',{staticClass:"label"},[_vm._v("Issues")])])]}}])},[_c('span',[_vm._v("Percentage of the total scope that had a failed inspection")])])],1),_c('div',{staticClass:"dateData"},[_c('div',{staticClass:"startDate boldFont"},[_vm._v("Start Date")]),_c('div',{staticClass:"endDate boldFont"},[_vm._v("End Date")]),_c('div',{staticClass:"crewDays boldFont"},[_vm._v("Crew Days")]),_c('div',{staticClass:"projectedFinish boldFont"},[_vm._v("Projected Finish")]),_c('div',{staticClass:"startDateValue"},[_vm._v(_vm._s(_vm.startDate))]),_c('div',{staticClass:"endDateValue"},[_vm._v(_vm._s(_vm.endDate))]),_c('div',{staticClass:"crewDaysValue"},[_vm._v(_vm._s(_vm.crewDays))]),_c('div',{staticClass:"projectedFinishValue"},[_vm._v(_vm._s(_vm.projectedFinishFormated))])]),(Object.keys(_vm.chartData).length !== 0)?_c('div',{staticClass:"burnDownChart"},[_c('ProjectOverviewBurndownGraph',{attrs:{"chartOptions":_vm.chartOptions,"chartData":_vm.chartData,"datasetIdKey":_vm.datasetIdKey}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }