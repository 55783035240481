









































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { DEFECT_CATEGORIES } from '@/common/Constants';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { DashboardDefectsByAsset, FormattedDashboardDefects } from '@/store/metrics/types';
import { UserPrefsActions } from '@/store/userPrefs/actions';
import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import Vue from 'vue';
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class DashboardDefectTable extends CustomerDashboardMixin {
    @userPrefsModule.State('useDefectCodeDesc') useDefectCodeDesc: boolean;

    @userPrefsModule.Action(UserPrefsActions.SET_DEFECT_CODEDESC) setDefectCodeDesc;

    @Prop() readonly projectGuid: string;

    @Prop() readonly type: string;

    @Prop() readonly selectedGroup: string;

    @Prop() readonly selectedGrade: number | undefined;

    @Prop() readonly isStructural: boolean | undefined;

    @Prop() readonly assetGuidsFilter: string[];

    filterValues = {};

    search = '';

    headers = [
      {
        text: 'Asset',
        value: 'assetName',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Defect Code',
        value: 'codeDisplay',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Grade',
        value: 'grade',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Type',
        value: 'type',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Action',
        value: 'dashboardDefectInfo',
        sortable: false,
        filterable: false,
      },
    ];

    matchFilters = [
      {
        header: 'assetName',
        type: 'string',
        value: '',
        method: '',
        options: [],
        tempValue: '',
        open: false,
      },
      {
        header: 'codeDisplay',
        type: 'string',
        value: '',
        method: '',
        options: [],
        tempValue: '',
        open: false,
      },
      {
        header: 'grade',
        type: 'number',
        value: '',
        method: '',
        options: [],
        tempValue: '',
        open: false,
      },
      {
        header: 'type',
        type: 'string',
        value: '',
        method: '',
        options: [],
        tempValue: '',
        open: false,
      },
    ];

    get assetGuidsToFilter(): string[] {
      return this.assetGuidsFilter != null ? this.assetGuidsFilter : [];
    }

    get tableData(): FormattedDashboardDefects[] {
      let returnValue: FormattedDashboardDefects[] = [];
      if (this.dashboardDefects == null) {
        return returnValue;
      }
      this.dashboardDefects.forEach((value) => {
        // Check for a filtered asset first
        if (this.assetGuidsToFilter.length === 0
          || this.assetGuidsToFilter.includes(value.assetGuid)
        ) {
          if (value.assetType === this.assetType) {
            value.defects.forEach((defect) => {
              returnValue.push({
                assetName: value.assetName,
                assetGuid: value.assetGuid,
                codeDescription: defect.codeDescription,
                code: defect.code,
                codeDisplay: this.useDefectCodeDesc
                  ? defect.codeDescription
                  : defect.code,
                grade: defect.grade,
                type: defect.type,
                inspectionGuid: defect.inspectionGuid,
                defectGuid: defect.defectGuid,
                payout: defect.payout,
              });
            });
          }
        }
      });

      if (this.selectedGroup && this.selectedGroup !== '') {
        const defectCodes = DEFECT_CATEGORIES.find((cat) => cat.name === this.selectedGroup);
        returnValue = returnValue.filter((rv) => defectCodes.defects.includes(rv.code));
      }

      if (this.isStructural !== null && this.selectedGrade !== null) {
        const isStructuralCodes = DEFECT_CATEGORIES
          .filter((cat) => cat.isStructural === this.isStructural)
          .map((cat) => cat.defects)
          .flat();
        returnValue = returnValue
          .filter((rv) => rv.grade === this.selectedGrade
          && isStructuralCodes.includes(rv.code));
      }

      return returnValue;
    }

    get assetType(): string | undefined {
      return this.type !== 'Manhole' && this.type !== 'Line Segment' ? undefined : this.type;
    }

    @Watch('tableData')
    async onTableDataChange(): Promise<void> {
      this.updateFilterValues();
    }

    async mounted(): Promise<void> {
      await this.onTableDataChange();
      this.matchFilters.find((f) => f.header === 'grade').options = [4, 5];
    }

    getTableInfoLink(defect: FormattedDashboardDefects): string {
      return `/assets/["${this.projectGuid}"]/${defect.assetGuid}?`
      + `defectGuid=${defect.defectGuid}`
      + `&defectInspectionGuid=${defect.inspectionGuid}`
      + `&defectCode=${defect.code}`
      + `&defectPayout=${defect.payout.toString()}`;
    }

    updateFilterValues(): void {
      this.matchFilters.forEach((filter) => {
        filter.options = [];
      });

      if (this.tableData.length <= 0) return;

      this.headers.forEach((header) => {
        if (header.value === 'assetName') return;
        if (header.value === 'codeDisplay' || !this.filterValues[header.value]) this.filterValues[header.value] = [];
        if (header.value === 'grade') this.filterValues[header.value] = [-1, 0, 1, 2, 3, 4, 5];

        this.tableData.forEach((defect: FormattedDashboardDefects) => {
          let checkVal: string;

          try {
            checkVal = defect[header.value];
          } catch {
            checkVal = '';
          }

          if (
            checkVal
            && checkVal !== ''
            && Array.isArray(this.filterValues[header.value])
            && !this.filterValues[header.value].includes(checkVal)
          ) {
            this.filterValues[header.value].push(checkVal);
          }

          this.filterValues[header.value].sort();
        });
      });
    }

    handleOnAssetInventoryClick(): void{
      this.$router.push({
        path: `/projects/["${this.projectGuid}"]/inventory`,
        query: {
          name: [...new Set(this.getFilteredDataFromTable().map((value) => value.assetName))],
        },
      }).then(() => {
        this.$router.go(0);
      });
    }

    clearFilters(): void {
      this.$emit('clearFilters');
    }

    handleOnProjectPageClick(): void {
      this.$router.push({
        path: `/projects/["${this.projectGuid}"]`,
        query: {
          name: [...new Set(this.getFilteredDataFromTable().map((value) => value.assetName))],
        },
      }).then(() => {
        this.$router.go(0);
      });
    }

    getFilteredDataFromTable(): FormattedDashboardDefects[] | undefined {
      return [...(this.$refs.defectdashboardtable as any)?.tableData != null
        ? (this.$refs.defectdashboardtable as any)?.tableData as FormattedDashboardDefects[]
        : [],
      ];
    }
}
