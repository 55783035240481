/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationTree } from 'vuex';
import { ReleasesState, ReleaseOverviewObject, ReleaseTableData } from './types';

// eslint-disable-next-line no-shadow
export enum ReleasesMutations {
  SET_RELEASES_DATA = 'SET_RELEASES_DATA',
  SET_RELEASES_ERROR = 'SET_RELEASES_ERROR',
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_POST_RELEASE_INSPECTION_ERROR = 'SET_POST_RELEASE_INSPECTION_ERROR',
  SET_POST_RELEASE_INSPECTION_LOADING = 'SET_POST_RELEASE_INSPECTION_LOADING',
  SET_POST_RELEASE_INSPECTION = 'SET_POST_RELEASE_INSPECTION',
  SET_RELEASE_TABLE_DATA = 'SET_RELEASE_TABLE_DATA',
  SET_RELEASE_TABLE_DATA_LOADING = 'SET_RELEASE_TABLE_DATA_LOADING',
  SET_RELEASE_TABLE_DATA_ERROR = 'SET_RELEASE_TABLE_DATA_ERROR',
  SET_INSPECTION_RELEASES_LOADING = 'SET_INSPECTION_RELEASES_LOADING',
  SET_INSPECTION_RELEASES_DATA = 'SET_INSPECTION_RELEASES_DATA',
  SET_SEND_CSV_EMAIL_LOADING = 'SET_SEND_CSV_EMAIL_LOADING',
  SET_SEND_CSV_EMAIL_ERROR = 'SET_SEND_CSV_EMAIL_ERROR',
  SET_DELETE_RELEASE_LOADING = 'SET_DELETE_RELEASE_LOADING',
  SET_DELETE_RELEASE_RESPONSE = 'SET_DELETE_RELEASE_RESPONSE',
  SET_DELETE_RELEASE_INSPECTION_LOADING = 'SET_DELETE_RELEASE_INSPECTION_LOADING',
  SET_DELETE_RELEASE_INSPECTION_ERROR = 'SET_DELETE_RELEASE_INSPECTION_ERROR',
  SET_PATCH_RELEASE_INSPECTION_LOADING = 'SET_PATCH_RELEASE_INSPECTION_LOADING',
  SET_PATCH_RELEASE_INSPECTION_ERROR = 'SET_PATCH_RELEASE_INSPECTION_ERROR',
  SET_PATCH_RELEASE_INSPECTION = 'SET_PATCH_RELEASE_INSPECTION',
  SET_POST_SEND_EXPORT_EMAIL_LOADING = 'SET_POST_SEND_EXPORT_EMAIL_LOADING',
  SET_POST_SEND_EXPORT_EMAIL_ERROR = 'SET_POST_SEND_EXPORT_EMAIL_ERROR',
  SET_POST_SEND_NOTIFICATION_EMAIL_LOADING = 'SET_POST_SEND_NOTIFICATION_EMAIL_LOADING',
  SET_POST_SEND_NOTIFICATION_EMAIL_ERROR = 'SET_POST_SEND_NOTIFICATION_EMAIL_ERROR',
}

export const mutations: MutationTree<ReleasesState> = {
  [ReleasesMutations.SET_RELEASES_ERROR](state, payload: string) {
    state.error = payload;
  },
  [ReleasesMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
  [ReleasesMutations.SET_RELEASES_DATA](state, payload: ReleaseOverviewObject[]) {
    state.releaseOverview = payload;
  },
  [ReleasesMutations.SET_POST_RELEASE_INSPECTION_ERROR](state, payload: string) {
    state.postReleaseError = payload;
  },
  [ReleasesMutations.SET_POST_RELEASE_INSPECTION_LOADING](state, payload: boolean) {
    state.postReleaseLoading = payload;
  },
  [ReleasesMutations.SET_POST_RELEASE_INSPECTION](state, payload: string) {
    state.postReleaseOverview = payload;
  },
  [ReleasesMutations.SET_RELEASE_TABLE_DATA](state, payload: ReleaseTableData[]) {
    state.releaseTableData = payload;
  },
  [ReleasesMutations.SET_RELEASE_TABLE_DATA_LOADING](state, payload: boolean) {
    state.releaseTableDataLoading = payload;
  },
  [ReleasesMutations.SET_RELEASE_TABLE_DATA_ERROR](state, payload: string | undefined) {
    state.releaseTableDataError = payload;
  },
  [ReleasesMutations.SET_INSPECTION_RELEASES_LOADING](state, payload: boolean) {
    state.loadingInspectionReleases = payload;
  },
  [ReleasesMutations.SET_INSPECTION_RELEASES_DATA](state, payload: any) {
    state.inspectionReleases = payload;
  },
  [ReleasesMutations.SET_SEND_CSV_EMAIL_LOADING](state, payload: boolean) {
    state.postCsvEmailLoading = payload;
  },
  [ReleasesMutations.SET_SEND_CSV_EMAIL_ERROR](state, payload: string | undefined) {
    state.postCsvEmailError = payload;
  },
  [ReleasesMutations.SET_DELETE_RELEASE_LOADING](state, payload: boolean) {
    state.deleteReleaseLoading = payload;
  },
  [ReleasesMutations.SET_DELETE_RELEASE_RESPONSE](state, payload: string) {
    state.deleteReleaseResponse = payload;
  },
  [ReleasesMutations.SET_DELETE_RELEASE_INSPECTION_LOADING](state, payload: boolean) {
    state.deleteReleaseInspectionLoading = payload;
  },
  [ReleasesMutations.SET_DELETE_RELEASE_INSPECTION_ERROR](state, payload: string) {
    state.deleteReleaseInspectionError = payload;
  },
  [ReleasesMutations.SET_PATCH_RELEASE_INSPECTION_LOADING](state, payload: boolean) {
    state.patchReleaseLoading = payload;
  },
  [ReleasesMutations.SET_PATCH_RELEASE_INSPECTION_ERROR](state, payload: string | undefined) {
    state.patchReleaseError = payload;
  },
  [ReleasesMutations.SET_PATCH_RELEASE_INSPECTION](state, payload: any) {
    state.patchRelease = payload;
  },
  [ReleasesMutations.SET_POST_SEND_EXPORT_EMAIL_LOADING](state, payload: boolean) {
    state.exportEmailLoading = payload;
  },
  [ReleasesMutations.SET_POST_SEND_EXPORT_EMAIL_ERROR](state, payload: string | undefined) {
    state.exportEmailError = payload;
  },
  [ReleasesMutations.SET_POST_SEND_NOTIFICATION_EMAIL_LOADING](state, payload: boolean) {
    state.notificationEmailLoading = payload;
  },
  [ReleasesMutations.SET_POST_SEND_NOTIFICATION_EMAIL_ERROR](state, payload: string | undefined) {
    state.notificationEmailError = payload;
  },
};
