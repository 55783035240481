



























































































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { AssetActions } from '@/store/asset/actions';
import { ReportActions } from '@/store/report/actions';
import { namespace } from 'vuex-class';
import { AssetData, InspectionData } from '@/store/asset/types';

import { AssetMutations } from '@/store/asset/mutations';
import MACP from '@/components/Report/MACP2/MACP2.vue';
import { ReportType } from '@/types';
import VueHtml2pdf from 'vue-html2pdf';
import ObservationFullReport from '@/components/Report/ObservationFullReport/ObservationFullReport.vue';
import OtherInfoReport from '../../components/AdditionalInformation/OtherInfoReport.vue';

const assetModule = namespace('asset');
const reportModule = namespace('report');

@Component({
  components: {
    MACP,
    ObservationFullReport,
    OtherInfoReport,
    VueHtml2pdf,
  },
})
export default class Report extends Vue {
  @Prop() readonly assetId!: string;

  @Prop() readonly inspectionId!: string;

  @Prop({ default: 2 }) readonly reportType: ReportType;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop({ default: false }) readonly msiExist: boolean;

  @Prop({ default: -1 }) readonly fullVideoTime: number;

  @Prop() readonly canExport: boolean;

  @assetModule.State('inspection') inspectionData: InspectionData | undefined =
    undefined;

  @assetModule.State('asset') asset: AssetData | undefined = undefined;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @assetModule.Mutation(AssetMutations.SET_INSPECTION_BY_ID) setInspectionById;

  @reportModule.State('data') cannedPDFUri: string | undefined;

  @reportModule.Action(ReportActions.GET_CANNED_PDF_URI) fetchCannedPDFURI;

  selectedType = this.reportTypeOptions[0];

  hasDownloaded = true;

  generateReport(): void {
    this.hasDownloaded = false;
    // eslint-disable-next-line dot-notation
    this.$refs.html2Pdf['generatePdf']();
  }

  selectType(option: string): void {
    this.selectedType = option;
  }

  get inspection(): InspectionData | undefined {
    return this.inspectionData;
  }

  get reportTypeOptions(): string[] {
    return [
      (this.reportType === ReportType.MACP) && 'MACP 7.0',
      (this.reportType === ReportType.Observation) && 'Observation',
      (this.msiExist) && 'MSI',
    ].filter(Boolean);
  }

  // If the following data has not been populated the we need to fetch the data
  mounted(): void {
    if (this.msiExist) {
      this.fetchCannedPDFURI(this.inspectionId);
    }
    const request = {
      assetId: this.assetId,
      inspectionId: this.inspectionId,
    };

    if (!this.asset || this.asset.guid !== this.assetId) {
      this.fetchAssetData(request);
    }

    if (!this.inspection || this.inspection.guid !== this.inspectionId) {
      this.setInspectionById(this.inspectionId);
    }
  }
}
