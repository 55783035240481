

































































































































































import { DataTableHeader } from '@/components/AssetTable/types';
import { AssetData, AssetFilter, MatchFilter } from '@/types';
import util from '@/components/Maps/utils';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';

@Component({})
export default class NewAssignmentTable extends Vue {
  @PropSync('selectedAssetsSync', { default: () => ([]) }) selectedAssets: AssetData[];

  @Prop() tableData!: AssetData[];

  @Prop() activeTabString!: string;

  @PropSync('assignmentHeadersSync') assignmentHeaders!: DataTableHeader[];

  filteredTableData: AssetData[] = [];

  filters = {
    selectedAssets: [],
    score: [-1, 0, 1, 2, 3, 4, 5],
    status: [] as string[],
    matchFilters: [] as MatchFilter[],
  } as AssetFilter;

  sortBy = 'name';

  sortByDesc = false;

  filterValues = {};

  timeRequestMade = 0;

  matchFilterMethod = {
    number: ['>', '>=', '<=', '<', '=', '!='],
    string: ['Exactly', 'Includes', 'Does Not Include'],
  };

  matchFilters = [
    {
      header: 'score',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'taskResult',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'codingStatus',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'overallQuick',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'overallIndex',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'overallRating',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'structuralQuick',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'structuralIndex',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'structuralRating',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'omQuick',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'omIndex',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'omRating',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'instDate',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'owner',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'sewerUse',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'comments',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'assetId',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'type',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'upstreamId',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'downstreamId',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'street',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'city',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'basin',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'pipeSize',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'pipeMaterial',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'lastInspected',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'guid',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  @Watch('selectedAssets')
  onSelectedAssetsChange(): void {
    this.$emit('updateSelectedAssets', this.selectedAssets);
  }

  @Watch('filters', { deep: true, immediate: true })
  onFiltersChange(): void {
    // Set each asset's visible flag based on filter
    if (window.Worker) {
      const worker = new Worker('../../../views/Project/webworker.js', {
        type: 'module',
      });
      this.timeRequestMade = Date.now();

      worker.postMessage({
        type: 'filterAsset',
        timeRequestMade: this.timeRequestMade,
        filters: JSON.stringify(this.filters),
        assetData: JSON.stringify(this.tableData),
      });

      worker.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (
          data.type != null
          && data.type === 'filterAsset'
          && this.timeRequestMade === data.timeRequestMade
        ) {
          this.filteredTableData = data.assetData.filter(
            (asset) => asset.visible === true,
          );
        }
      };
    } else {
      this.filteredTableData = this.tableData;
      this.filteredTableData.forEach((asset) => this.filterAsset(asset));
      this.filteredTableData = this.filteredTableData.filter(
        (asset) => asset.visible === true,
      );
    }
  }

  @Watch('activeTabString')
  onTabChange(): void {
    this.onFiltersChange();
    this.selectedAssets = [];
  }

  // eslint-disable-next-line class-methods-use-this
  getScoreColorTable(score: number): string {
    return util.getScoreColor(score);
  }

  searchFeatures(headerValue: string): void {
    const currentHeader = this.matchFilters.find(
      (f) => f.header === headerValue,
    );
    currentHeader.value = currentHeader.tempValue;

    // see if filter is already in matchfilters
    const matchingFilters = this.filters.matchFilters.find(
      (f) => f.header === currentHeader.header,
    );
    if (matchingFilters != null) {
      // update match filter
      matchingFilters.value = currentHeader.value;
      matchingFilters.header = currentHeader.header;
      matchingFilters.type = currentHeader.type;
      matchingFilters.method = currentHeader.method;
      matchingFilters.options = currentHeader.options;
    } else {
      // addmatch filter
      this.filters.matchFilters.push({
        header: currentHeader.header,
        type: currentHeader.type,
        value: currentHeader.value,
        method: currentHeader.method,
        options: currentHeader.options,
      } as MatchFilter);
    }

    this.setFilterMenu(headerValue, false);
  }

  clearMatchFilter(headerValue: string): void {
    const matchFilter = this.matchFilters.find((m) => m.header === headerValue);
    matchFilter.value = '';
    matchFilter.method = '';
    matchFilter.tempValue = '';
    matchFilter.options = [];

    // see if filter is already in matchfilters
    const matchingFilters = this.filters.matchFilters.find(
      (f) => f.header === matchFilter.header,
    );
    if (matchingFilters != null) {
      // update match filter
      matchingFilters.value = matchFilter.value;
    }

    this.setFilterMenu(headerValue, false);
  }

  setFilterMenu(headerValue: string, value: boolean): boolean {
    const filter = this.matchFilters.find((f) => f.header === headerValue);
    filter.open = value;
    return filter.open;
  }

  isUpdated(headerValue: string): boolean {
    const currentHeader = this.filters.matchFilters.find(
      (f) => f.header === headerValue,
    );
    if (currentHeader == null) return false;

    return currentHeader.value !== currentHeader.tempValue;
  }

  columnFiltersActive(headerValue: string): boolean {
    let retVal = false;
    const filter = this.matchFilters.find((f) => f.header === headerValue);

    if (filter.value !== '' || filter.options.length > 0) retVal = true;

    return retVal;
  }

  getMatchFilterMethod(headerValue: string): string[] {
    const filter = this.matchFilters.find((f) => f.header === headerValue);
    return filter.type === 'number'
      ? this.matchFilterMethod.number
      : this.matchFilterMethod.string;
  }

  async filterAsset(assetProp: AssetData): Promise<void> {
    const asset = assetProp;
    if (
      this.filters.selectedAssets.length !== 0
      && !this.filters.selectedAssets.includes(asset.guid)
    ) {
      // eslint-disable-next-line no-param-reassign
      asset.visible = false;
      return;
    }

    const scoreCompare = this.filters.score;

    if (scoreCompare) asset.visible = scoreCompare.includes(asset.score);

    this.filters.matchFilters.forEach((m) => {
      if (m.options.length > 0) { asset.visible = m.options.includes(asset[m.header]); }

      if (m.value !== '') {
        if (m.type === 'number') {
          const term = parseFloat(m.value as string);

          switch (m.method) {
            case '>':
              asset.visible = parseFloat(asset[m.header]) > term;
              break;
            case '>=':
              asset.visible = parseFloat(asset[m.header]) >= term;
              break;
            case '<=':
              asset.visible = parseFloat(asset[m.header]) <= term;
              break;
            case '<':
              asset.visible = parseFloat(asset[m.header]) < term;
              break;
            case '=':
              asset.visible = parseFloat(asset[m.header]) === term;
              break;
            case '!=':
              asset.visible = parseFloat(asset[m.header]) !== term;
              break;
            default:
              break;
          }
        } else {
          if (asset[m.header] === null) return;

          const searchTerm = m.value.toString().toLowerCase();
          switch (m.method) {
            case 'Exactly':
              asset.visible = searchTerm === asset[m.header].toLowerCase();
              break;
            case 'Includes':
              asset.visible = asset[m.header]
                .toString()
                .toLowerCase()
                .includes(searchTerm);
              break;
            case 'Does Not Include':
              asset.visible = !asset[m.header]
                .toString()
                .toLowerCase()
                .includes(searchTerm);
              break;
            default:
              break;
          }
        }
      }
    });
  }
}
