export interface AssignmentTaskType {
  name: string,
  subValue?: AssignmentTaskType[],
}

export const macpTaskTypes: AssignmentTaskType[] = [
  {
    name: 'MACP',
    subValue: [
      { name: 'Level 1' },
      { name: 'Level 2' },
    ],
  },
  { name: 'GPS' },
  { name: 'Vertue' },
  { name: 'Solo Manhole' },
  { name: 'Premium' },
];

export const pacpTaskTypes: AssignmentTaskType[] = [
  {
    name: 'Robot Inspection',

    subValue: [
      { name: 'Solo' },
      { name: 'Responder' },
      { name: 'Profiler' },
      { name: 'CCTV' },
      { name: 'P3D' },
    ],
  },
  { name: 'PACP Inspection' },
  { name: 'MSI Collection' },
];

export const commonTaskTypes: AssignmentTaskType[] = [
  { name: 'Cleaning Work Order' },
  { name: 'General Maintenance' },
  { name: 'Service Call' },
  { name: 'Structure Inspection' },
  { name: 'SSO Report' },
  { name: 'FOG Inspection' },
  { name: 'Smoke Test' },
  { name: 'Repair' },
  { name: 'Generic' },
];
