





































import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { Component, Prop } from 'vue-property-decorator';
import { OrganizationData } from '@/store/organizations/types';
import phoneNumberToPhoneInfo from '@/components/IntegrityPhoneNumberInput/types';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import OrganizationEditDialog from './OrganizationEditDialog.vue';

@Component({
  components: {
    IntegrityTable,
    OrganizationEditDialog,
    IntegrityDelete,
  },
})
export default class OrganizationsTable extends AdminPanelMixin {
  @Prop() readonly search: string;

  showEditDialog = false;

  currentEditItem = {};

  headers = [
    {
      text: 'Organization',
      value: 'name',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Address',
      value: 'address',
      sortable: true,
      filterable: true,
    },
    {
      text: 'System Owner',
      value: 'systemOwner',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Email',
      value: 'email',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Phone',
      value: 'phoneNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'left',
      sortable: false,
    },
  ];

  matchFilters = [
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'address',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'systemOwner',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'email',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'phoneNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  async mounted(): Promise<void> {
    this.$root.$on('clearUserTableFilters', () => {
      (this.$refs.organizationTable as IntegrityTable).clearAllMatchFilters();
    });
  }

  /**
   * @description Get the data for the table that includes the system owner's name.
   * @returns {OrganizationData[]} List of organizations
   */
  get organizationTableData(): OrganizationData[] {
    if (!this.organizationDataList) {
      return [];
    }
    return this.organizationDataList.map((org) => ({
      ...org,
      systemOwner: this.findUserFromUserGuid(org.systemOwnerGuid),
    }));
  }

  /**
   * @description Find the user in the all users object based on their guid.
   * @param {string} userGuid User guid to search
   * @returns {string} first and last name of user
   */
  findUserFromUserGuid(userGuid: string): string {
    if (!this.allUsers) {
      return '';
    }
    const foundUser = this.allUsers.find((u) => u.guid === userGuid);
    if (foundUser) {
      return `${foundUser.firstname} ${foundUser.lastname}`;
    }
    return '';
  }

  /**
   * @description fill out the filter values for the table.
   * @returns filter values
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get filterValues(): any {
    if (!this.organizationTableData) {
      return;
    }

    const localFilterValues = {};

    this.headers.forEach((header) => {
      if (!header.filterable) return;

      localFilterValues[header.value] = [];

      this.organizationTableData.forEach((workOrder: OrganizationData) => {
        let checkVal: string;

        try {
          checkVal = workOrder[header.value];
        } catch {
          checkVal = '';
        }

        if (
          checkVal
          && checkVal !== ''
          && Array.isArray(localFilterValues[header.value])
          && !localFilterValues[header.value].includes(checkVal)
        ) {
          localFilterValues[header.value].push(checkVal);
        }
      });
      localFilterValues[header.value].sort();
    });
    // eslint-disable-next-line consistent-return
    return localFilterValues;
  }

  /**
   * @description Sets currentEditItem from the selected item guid.
   * @param {string} itemGuid
   */
  handleEdit(itemGuid: string): void {
    const foundItem = this.organizationDataList.find(
      (org) => org.guid === itemGuid,
    );
    const foundUser = this.allUsers.find(
      (user) => user.guid === foundItem.systemOwnerGuid,
    );

    this.currentEditItem = {
      guid: foundItem.guid,
      organizationName: foundItem.name,
      address: foundItem.address,
      systemOwner: foundUser,
      email: foundItem.email,
      phoneNumber: phoneNumberToPhoneInfo(foundItem.phoneNumber),
    };
    this.showEditDialog = true;
  }

  /**
   * @description Calls endpoint to delete organization and removes it from organizationDataList.
   * @param {OrganizationData} item OrganizationData object to remove.
   * @returns {Promise<boolean>} Returns true if organization was successfully deleted.
   */
  async handleDelete(item: OrganizationData): Promise<boolean> {
    try {
      const deleteIndex = this.organizationDataList.findIndex((value) => value.guid === item.guid);

      if (deleteIndex === -1) {
        // If we can not find the item in the organizationDataList
        return false;
      }

      await this.deleteOrganizationData(item.guid);
      this.organizationDataList.splice(
        deleteIndex,
        1,
      );
    } catch (e) {
      return false;
    }
    return true;
  }
}
