



/* eslint-disable @typescript-eslint/no-explicit-any,
@typescript-eslint/explicit-module-boundary-types */
import { AdditionalFilterFunction } from '@/components/IntegrityTable/IntegrityTable.vue';
import {
  Component, Vue,
} from 'vue-property-decorator';
import { uuid } from 'vue-uuid';

/**
 * @member items {any[]} The list of items to pass as a prop to the functions
 * @member functions {any[]} The list of functions which take in the item list
 * and return a value to put in the return array.
 */
export interface ListWorkerRequest {
  items: any[],
  functions: AdditionalFilterFunction,
  callback?: any,
}

export interface WorkerData {
  callback?: any,
  currentWorkerId: string,
  currentWorker: Worker | undefined
}

export function Mutex() {
  let current = Promise.resolve();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.lock = () => {
    // eslint-disable-next-line no-underscore-dangle
    let _resolve;
    const p = new Promise<void>((resolve) => {
      _resolve = () => resolve();
    });
    const rv = current.then(() => _resolve);
    current = p;
    return rv;
  };
}

@Component({
  components: {},
})
export default class WorkerUtilMixin extends Vue {
  destroy(): void {
    this.listWorkers.forEach((worker) => {
      if (worker != null) {
        worker.currentWorker.terminate();
      }
    });
    this.listWorkers = [];
  }

  // #region List Worker
  private listWorkers: WorkerData[] = [];

  /**
   * @description Runs a list of items through a list of filter functions.
   * Returns an array of each function's result on the item dataset through the callback.
   * The index in the returned array is the same as the index of the function that ran the function.
   * @param request {ListWorkerRequest} The process request object
   * @returns {string | undefined} The uuid of the process or undefined if no process was created
   */
  async startListWorkerRequest(request: ListWorkerRequest): Promise<string | undefined> {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return undefined;
    }
    if (
      request?.items != null
      && request.functions?.filterFunctions != null
      && request.functions.filterFunctions.length > 0
    ) {
      const worker: WorkerData = {
        callback: request.callback,
        currentWorkerId: uuid.v4(),
        currentWorker: undefined,
      };
      try {
        worker.currentWorker = this.createListWorker();
        worker.currentWorker.onmessage = this.onListWorkerMessage;
        worker.currentWorker.postMessage({
          type: 'start',
          functionsToCall: JSON.stringify(request.functions),
          currentWorkerId: worker.currentWorkerId,
          data: JSON.stringify(request.items),
        });
      } catch (e) {
        return undefined;
      }
      this.listWorkers.push(worker);
      return worker.currentWorkerId;
    }
    return undefined;
  }

  private async onListWorkerMessage(event: any): Promise<void> {
    const workerId = event?.data?.currentWorkerId;
    if (workerId == null) {
      return;
    }
    const foundWorkerindex = this.listWorkers
      .findIndex((worker) => worker.currentWorkerId === workerId);
    if (foundWorkerindex !== -1) {
      const foundWorker = this.listWorkers.splice(foundWorkerindex, 1)[0];
      if (
          event?.data?.type === 'endTask'
          && foundWorker?.callback
      ) {
        if (foundWorker.currentWorker) {
          foundWorker.currentWorker.terminate();
          foundWorker.currentWorker = undefined;
        }
        if (foundWorker.callback) {
          const eventDataString = event?.data?.data;
          let eventData = [];
          if (eventDataString != null) {
            eventData = JSON.parse(eventDataString);
          }
          foundWorker.callback(eventData);
        }
      }
    }
  }

  private createListWorker(): Worker {
    const w = new Worker('./WorkerUtilMixinWorker.js', { type: 'module' });
    return w;
  }
  // #endregion
}
