




















































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';

import {
  OverviewTotalCounts,
  ProjectOverviewMetrics,
} from '@/store/metrics/types';
import { format } from 'date-fns';
import ProjectOverviewBurndownGraph from './PmDashboardComponents/ProjectOverviewGraph/ProjectOverviewBurndownGraph.vue';

@Component({ components: { ProjectOverviewBurndownGraph } })
export default class ProjectOverviewGraph extends Vue {
  @Prop({ default: 'label' }) readonly datasetIdKey: string;

  @Prop() readonly projectOverviewMetrics: ProjectOverviewMetrics | undefined;

  @Prop() readonly overviewTotalCounts: OverviewTotalCounts | undefined;

  @Prop() readonly totalFootage: number | undefined;

  get chartData(): any {
    const defaultObject = {
      labels: [],
      datasets: [],
    };

    if (
      !this.overviewTotalCounts
      || this.overviewTotalCounts.platforms.length === 0
    ) {
      return defaultObject;
    }

    const collectedData = this.getCollectedData();

    const dates = collectedData.map((data) => new Date(data.x).getTime());

    const minDate = new Date(Math.min(...dates));

    let startDate = this.startDate === '' ? undefined : Date.parse(this.startDate);
    let endDate = this.endDate === '' ? undefined : Date.parse(this.endDate);

    if (!startDate) {
      // If there is no end date, then use the first data point
      startDate = minDate.getTime();
    }

    if (!endDate) {
      // If there is no end date, then use the projected finish for the trend line
      const proj = this.projectedFinish;
      if (!proj) {
        return defaultObject;
      }
      endDate = this.projectedFinish.getTime();
    }

    const maxDate = new Date(Math.max(...dates, endDate));

    if (!startDate || !maxDate) {
      return defaultObject;
    }

    const dateArr = this.getDates(startDate, maxDate);

    const burnDownData = [
      { x: format(new Date(startDate), 'yyyy-MM-dd'), y: this.totalFootage },
      { x: format(endDate, 'yyyy-MM-dd'), y: 0 },
    ];

    return {
      labels: dateArr,
      datasets: [
        {
          label: 'Burndown',
          backgroundColor: '#CCCCCC',
          borderColor: '#CCCCCC',
          pointRadius: 0,
          pointHitRadius: 0,
          data: burnDownData,
        },
        {
          label: 'Remaining',
          backgroundColor: '#0C6599',
          borderColor: '#0C6599',
          pointRadius: 3,
          data: collectedData,
        },
      ],
    };
  }

  get chartOptions(): any {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            align: 'center',
            text: 'Remaining',
          },
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },

      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }

  get startDate(): string {
    return this.projectOverviewMetrics?.startDate != null
      ? this.parseDate(this.projectOverviewMetrics.startDate)
      : '';
  }

  get endDate(): string {
    return this.projectOverviewMetrics?.endDate != null
      ? this.parseDate(this.projectOverviewMetrics.endDate)
      : this.projectedFinishFormated;
  }

  get crewDays(): string {
    // return this.projectOverviewMetrics?.crewDays != null ? crewDays: '';
    return '';
  }

  get projectedFinishFormated(): string {
    const finishDate = this.projectedFinish;
    return !finishDate
      ? ''
      : this.parseDate(this.projectedFinish.toDateString());
  }

  get projectedFinish(): Date | undefined {
    if (this.projectOverviewMetrics?.startDate == null) {
      return undefined;
    }
    const collectedData = this.getCollectedData();
    const dates = collectedData?.map((data) => new Date(data.x).getTime());
    let endDate: number = Math.max(...dates);
    if (this.projectOverviewMetrics?.endDate != null) {
      endDate = Date.parse(this.projectOverviewMetrics.endDate);
    }
    const startDate = Date.parse(this.projectOverviewMetrics.startDate);
    const maxDate = Math.max(...dates, endDate);

    if (
      !startDate
      || !maxDate
      || !this.overviewTotalCounts
      || collectedData.length === 0
    ) {
      return undefined;
    }

    const startValue = Math.max(...collectedData.map((data) => data.y));

    const endValue = Math.min(...collectedData.map((data) => data.y));

    const completed = startValue - endValue;
    const daysPassed = (maxDate - startDate) / (1000 * 60 * 60 * 24);

    if (completed === 0 || daysPassed < 1) {
      return undefined;
    }

    const avg = completed / daysPassed;
    const projectedTime = Math.ceil(startValue / avg);
    const result = new Date(startDate);
    result.setDate(result.getDate() + projectedTime);

    // eslint-disable-next-line consistent-return
    return result;
  }

  get completedCount(): number {
    if (!this.isTotalMetricsGreaterThanZero()) {
      return 0;
    }
    return Math.round(
      (this.projectOverviewMetrics.completeCount * 100)
        / this.projectOverviewMetrics.totalCount,
    );
  }

  get addressedCount(): number {
    if (!this.isTotalMetricsGreaterThanZero()) {
      return 0;
    }
    return Math.round(
      (this.projectOverviewMetrics.successCount * 100)
        / this.projectOverviewMetrics.totalCount,
    );
  }

  get incompleteCount(): number {
    if (!this.isTotalMetricsGreaterThanZero()) {
      return 0;
    }
    return Math.round(
      (this.projectOverviewMetrics.issuesCount * 100)
        / this.projectOverviewMetrics.totalCount,
    );
  }

  isTotalMetricsGreaterThanZero(): boolean {
    return (
      this.projectOverviewMetrics && this.projectOverviewMetrics.totalCount > 0
    );
  }

  parseDate(dateString: string | undefined): string {
    if (!dateString) {
      return '';
    }
    return format(Date.parse(dateString), 'yyyy-MM-dd');
  }

  getDates(startTime: number, endDate: Date, steps = 1): any[] {
    const dateArray = [];
    const startDate = new Date(startTime);

    while (startDate <= new Date(endDate)) {
      dateArray.push(format(new Date(startDate), 'yyyy-MM-dd'));
      startDate.setUTCDate(startDate.getUTCDate() + steps);
    }

    return dateArray;
  }

  getOverviewTotalCountData(): any {
    if (!this.overviewTotalCounts) {
      return {};
    }
    const totalCountData = {};

    this.overviewTotalCounts.platforms.forEach((platform) => {
      platform.dailyCounts.forEach((count) => {
        const deploymentDate = format(
          new Date(count.deploymentDate),
          'yyyy-MM-dd',
        );
        if (!(deploymentDate in totalCountData)) {
          totalCountData[deploymentDate] = 0;
        }
        totalCountData[deploymentDate] += count.counts;
      });
    });
    return totalCountData;
  }

  getCollectedData(): any {
    const newArr = [];
    const overviewTotalCountData = this.getOverviewTotalCountData();
    const sortedData = Object.keys(overviewTotalCountData)
      .map((key) => ({ x: key, y: overviewTotalCountData[key] }))
      .sort((x, y) => new Date(x.x).getTime() - new Date(y.x).getTime());

    // eslint-disable-next-line no-return-assign
    sortedData.reduce((prev, curr, i) => newArr[i] = { x: curr.x, y: Math.max(prev.y - curr.y, 0) }, { x: '', y: this.totalFootage });
    return newArr;
  }
}
