











































import {
  Component, Prop, VModel, Watch,
} from 'vue-property-decorator';
import { CustomerData } from '@/store/customers/types';
import { OrganizationData } from '@/store/organizations/types';
import { UserPermission } from '@/store/userpermissions/types';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import CustomerEditDialog from './CustomerEditDialog.vue';

@Component({
  components: {
    CustomerEditDialog,
  },
})
export default class CustomerDialogSelectCustomer extends AdminPanelMixin {
  @VModel() modelValue!: CustomerData | undefined;

  @Prop({ default: null }) organization: OrganizationData | undefined;

  @Prop({ default: false }) requireOrganization: boolean | undefined;

  @Prop({ default: false }) readonly: boolean;

  showNewCustomerDialog = false;

  customerValue = '';

  customerNameSearch = null;

  currentEditItem = {};

  valid = false as boolean;

  mounted(): void {
    if (!this.customerDataList) {
      this.fetchCustomerData();
    }
    if (this.modelValue) {
      this.setLocalValues();
    }
  }

  @Watch('modelValue')
  onModelValueChange(): void {
    this.setLocalValues();
  }

  @Watch('organization')
  onOrganizationChange(): void {
    if (
      this.requireOrganization
      && this.modelValue
      && this.organization?.guid !== this.modelValue.organizationGuid
    ) {
      // If the required organization is changed
      // and it does not match the organization guid of modelValue
      // then reset to make sure the customer's organization matches the intended organization
      this.modelValue = null;
    }
  }

  /**
   * @description Sets all local values from the modelValue.
   */
  setLocalValues(): void {
    this.customerValue = this.modelValue?.name;
    this.customerNameSearch = this.modelValue?.name;
  }

  @Watch('showNewCustomerDialog')
  onShowNewCustomerDialogChange(): void {
    if (!this.showNewCustomerDialog) {
      // If closing the dialog, then reset the search
      this.setLocalValues();
      this.currentEditItem = {};
    }
  }

  /**
   * @description Checks for a new user in the combobox
   * @remarks If an existing user is selected, then select them. If not, then open up the popup
   */
  checkForNewCustomerAdd(): void {
    const foundValue = this.customerDataList.find(
      (u) => u.name === this.customerValue,
    );
    if (foundValue) {
      this.modelValue = foundValue;
    }
  }

  /**
   * @description Sets modelValue to value of newCustomer and sets local values
   * @param {CustomerData} newCustomer
   */
  updateLocalCustomer(newCustomer: CustomerData): void {
    this.modelValue = this.customerDataList.find(
      (u) => u.name === newCustomer.name,
    );
    this.setLocalValues();
  }

  /**
   * @description Get a list of all user's full names.
   * @returns {string[]} list of user full names
   */
  get customersList(): string[] {
    if (!this.customerDataList) {
      return [];
    }
    if (this.requireOrganization) {
      if (!this.organization) {
        return [];
      }
      return this.customerDataList
        .filter((org) => org.organizationGuid === this.organization.guid)
        .map((org) => org.name);
    }
    return this.customerDataList.map((org) => org.name);
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_CREATE_CUSTOMER
   */
  get hasPermissionCreateCustomer(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_CREATE_CUSTOMER);
  }

  /**
   * @description On pressing enter, open up new org dialog if the search is not a valid item
   */
  createNewOrganization(): void {
    if (this.hasPermissionCreateCustomer) {
      const foundValue = this.customerDataList.find(
        (u) => u.name === this.customerValue,
      );

      if (!foundValue) {
        const existingOrg = this.requireOrganization ? this.organization : null;
        this.currentEditItem = {
          customerName: this.customerValue,
          address: '',
          systemOwner: null,
          email: '',
          phoneNumber: null,
          organization: existingOrg,
        };
        this.modelValue = null;
        this.setLocalValues();
        this.showNewCustomerDialog = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.customerValueComboBox as any).isFocused = false;
      }
    }
  }

  /**
   * @description when leaving focus, make sure the search is a valid value
   */
  onBlur(): void {
    if (!this.customerNameSearch) {
      this.modelValue = null;
      return;
    }
    if (this.modelValue?.name !== this.customerNameSearch) {
      this.checkForNewCustomerAdd();
      this.setLocalValues();
    }
  }
}
