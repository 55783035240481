import { ActionTree } from 'vuex';
import { RootState } from '../types';
import storeUtility from '../utils';
import { OrganizationData, OrganizationState } from './types';
import { OrganizationMutations } from './mutations';

// eslint-disable-next-line no-shadow
export enum OrganizationActions {
  FETCH_ORGANIZATION_DATA = 'FETCH_ORGANIZATION_DATA',
  POST_ORGANIZATION_DATA = 'POST_ORGANIZATION_DATA',
  PATCH_ORGANIZATION_DATA = 'PATCH_ORGANIZATION_DATA',
  DELETE_ORGANIZATION_DATA = 'DELETE_ORGANIZATION_DATA',
}

export const actions: ActionTree<OrganizationState, RootState> = {

  async [OrganizationActions.FETCH_ORGANIZATION_DATA]({ commit }): Promise<void> {
    commit(OrganizationMutations.SET_GET_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getOrganizationData();

      commit(OrganizationMutations.SET_ORGANIZATION_DATA, response.data.data);
    } catch (e) {
      commit(OrganizationMutations.SET_GET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(OrganizationMutations.SET_GET_LOADING, false);
    }
  },
  async [OrganizationActions.POST_ORGANIZATION_DATA]({ commit }, payload: OrganizationData):
    Promise<void> {
    commit(OrganizationMutations.SET_POST_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postOrganizationData(payload);

      commit(OrganizationMutations.SET_POST_ORGANIZATION_OBJECT, response.data.data);
    } catch (e) {
      commit(OrganizationMutations.SET_POST_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(OrganizationMutations.SET_POST_LOADING, false);
    }
  },
  async [OrganizationActions.PATCH_ORGANIZATION_DATA]({ commit }, payload: OrganizationData):
    Promise<void> {
    commit(OrganizationMutations.SET_PATCH_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.patchOrganizationData(payload);

      commit(OrganizationMutations.SET_PATCH_ORGANIZATION_OBJECT, response.data.data);
    } catch (e) {
      commit(OrganizationMutations.SET_PATCH_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(OrganizationMutations.SET_PATCH_LOADING, false);
    }
  },
  async [OrganizationActions.DELETE_ORGANIZATION_DATA]({ commit }, payload: string): Promise<void> {
    commit(OrganizationMutations.SET_DELETE_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.deleteOrganizationData(payload);
    } catch (e) {
      commit(OrganizationMutations.SET_DELETE_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(OrganizationMutations.SET_DELETE_LOADING, false);
    }
  },
};
