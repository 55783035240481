
import { AuthUserRoles } from '@/auth/roles';
import { UserPermissionsActions } from '@/store/userpermissions/actions';
import { UserPermission } from '@/store/userpermissions/types';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const userPermissionsModule = namespace('userPermissions');

@Component
export default class UserPermissionsMixin extends Vue {
  @userPermissionsModule.Action(UserPermissionsActions.FETCH_USER_PERMISSIONS) fetchUserPermissions;

  @userPermissionsModule.State('permissions') userPermissions: UserPermission[];

  @userPermissionsModule.State('loading') userPermissionsLoading: boolean;

  @userPermissionsModule.State('error') userPermissionsError: string | undefined;

  permissions = UserPermission;

  /**
 * @description Check if user has a single permission
 * @returns true if user has a single permission
 */
  hasPermission(permission: UserPermission): boolean {
    return this.userPermissions.includes(permission);
  }

  /**
 * @description Check if user has a list of permissions
 * @returns true if user has all requested permissions
 */
  hasPermissions(permissions: UserPermission[]): boolean {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return true;
    }
    const userPermissionsSet = new Set(this.userPermissions);
    const requestedPermissionsSet = new Set(permissions);
    const difference = [...requestedPermissionsSet]
      .filter((element) => !userPermissionsSet.has(element));
    return difference.length === 0;
  }

  /**
   * @description Check if user has any permissions from a group
   * @returns true if user has any permissions from a group
  */
  hasPermissionGroup(permissionGroup: string): boolean {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return true;
    }
    try {
      return this.userPermissions.some((p) => (p as string).split(':')[0].includes(permissionGroup));
    } catch {
      return false;
    }
  }

  /**
   * @description Check if user has any permissions from a group
   * @returns true if user has any permissions from a group
  */
  hasPermissionGroupFromPermissions(permissions: any, permissionGroup: string): boolean {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return true;
    }
    try {
      const permissionGroups = Object.keys(permissions);
      return permissionGroups.includes(permissionGroup);
    } catch {
      return false;
    }
  }

  /**
   * @description Returns list of permissions user has from a group
   * @returns list of permissions user has from a group
  */
  permissionsFromGroupFromPermissions(permissions: any, permissionGroup: string): UserPermission[] {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return [];
    }
    try {
      return permissions[permissionGroup].map((p) => `${permissionGroup}:${p.replace(' ', '')}`);
    } catch {
      return [];
    }
  }

  /**
   * @description Send user to error page
   */
  goToErrorPage(): void {
    this.$router.push({
      name: 'Error',
      params: {
        catchAll: 'Error',
        message:
          'You do not have permission to view this page. If this an error, please contact support.',
      },
    });
  }
}
