



























































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import PlanningTable from '@/components/ProjectManager/PlanningTable.vue';
import NewAssignmentDialog from '@/components/ScopeNavigation/NewAssignmentDialog/NewAssignmentDialog.vue';
import NewAssignmentTable from '@/components/ScopeNavigation/NewAssignmentTable/NewAssignmentTable.vue';
import {
  Component, Mixins, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { DataTableHeader } from '@/components/AssetTable/types';
import { AssetData } from '@/store/asset/types';
import { namespace } from 'vuex-class';
import { RoutingActions } from '@/store/routing/actions';
import { RoutingData } from '@/store/routing/types';
import { TASK_TYPE_STRING, MACP_LEVEL_1_COLLECTION_TASK, MACP_LEVEL_2_COLLECTION_TASK, ALLOWED_DEPLOYMENT_FORM_TASK_TYPES } from '@/common/Constants';
import { MPSActions } from '@/store/mps/actions';
import { ProjectHeaders } from '@/store/project/types';
import { AssetActions } from '@/store/asset/actions';
import { UserPermission } from '@/store/userpermissions/types';
import { WorkOrderTableData } from '@/store/planning/types';
import WorkerUtilMixin, { ListWorkerRequest } from '@/common/utils/WorkerUtilMixin.vue';
import { UserData } from '@/store/users/types';
import { uuid } from 'vue-uuid';
import NewRoutingAssignmentTable from './NewAssignmentTable/NewRoutingAssignmentTable.vue';
import WorkOrderCounts, { Counts } from './WorkOrderCounts/WorkOrderCounts.vue';
import { WorkOrderListPatchDTO } from '../ProjectManager/types';
import DeploymentForm from '../ProjectManager/DeploymentForm.vue';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';
import { AdditionalFilterFunction, FilterFunction } from '../IntegrityTable/IntegrityTable.vue';

const routingModule = namespace('routing');
const mpsModule = namespace('mps');
const assetModule = namespace('asset');
const userModule = namespace('users');
@Component({
  components: {
    PlanningTable,
    NewAssignmentDialog,
    NewAssignmentTable,
    NewRoutingAssignmentTable,
    WorkOrderCounts,
    DeploymentForm,
  },
})
export default class PlanningBase extends Mixins(UserPermissionsMixin, WorkerUtilMixin) {
  categories = [
    {
      name: 'unassigned',
      label: 'Unassigned',
      filterFunction: function hasCategory(x: WorkOrderTableData): boolean { return x.scheduledDueDate == null && x.scheduledStartDate == null && x.status !== 'Complete' && x.status !== 'Completed' && !(x.status).toLowerCase().includes('incomplete') && x.status !== 'Follow-Up Required'; },
    },
    {
      name: 'Pending',
      label: 'Pending',
      filterFunction: function hasCategory(x: WorkOrderTableData): boolean { return x.scheduledDueDate != null && x.scheduledStartDate != null && x.status !== 'Complete' && x.status !== 'Completed' && !(x.status).toLowerCase().includes('incomplete') && x.status !== 'Follow-Up Required'; },
    },
    {
      name: 'completed',
      label: 'Completed',
      filterFunction: function hasCategory(x: WorkOrderTableData): boolean { return x.status === 'Complete' || x.status === 'Completed'; },
    },
    {
      name: 'incomplete',
      label: 'Incomplete',
      filterFunction: function hasCategory(x: WorkOrderTableData): boolean { return (x.status).toLowerCase().includes('incomplete') && x.status !== 'Follow-Up Required'; },
    },
    {
      name: 'followUpRequired',
      label: 'Follow-Up Required',
      filterFunction: function hasCategory(x: WorkOrderTableData): boolean { return x.status === 'Follow-Up Required'; },
    },
    {
      name: 'all',
      label: 'All',
      filterFunction: function hasCategory(x: WorkOrderTableData): boolean { return true; },
    },
  ];

  categoryCounts: Counts[] = [];

  selectedCategoryIndex = 0;

  snackbarMessage = '';

  snackbarColor = '';

  snackbarVisible = false;

  polling = 0;

  taskTypeGuids = TASK_TYPE_STRING.map((tts) => tts.guid)

  createNewAssign = false;

  showNewAssignDialog = false;

  tabOptions = ['Manhole', 'Line Segment', 'Manhole Routes', 'Line Segment Routes']

  newWorkOrderHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'name', sortable: false, class: 'headcol', cellClass: 'headcol', editable: true },
    { text: 'Score', value: 'score', sortable: false },
    { text: 'Street', value: 'street', sortable: false, editable: true },
    { text: 'City', value: 'city', sortable: false, editable: true },
    { text: 'Drainage Area / Basin', value: 'basin', sortable: false, editable: true },
    { text: 'Owner', value: 'owner', sortable: false, editable: true },
  ]

  activeTab = 0;

  selectedAssets = [];

  selectedAssetType = 0;

  @Prop() project: ProjectHeaders;

  @Prop() analyticsData: WorkOrderTableData[] | undefined;

  @Prop() loadingAnalyticsData;

  @Prop() fetchAssignmentsData;

  @Prop() deleteAssignment;

  @Prop() editAssignment;

  @Prop() patchData: string | undefined;

  @Prop() deleteLoading: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() patchAssignmentList: any;

  @Prop() readonly assetData: AssetData[];

  @routingModule.Action(RoutingActions.FETCH_ROUTING_DATA) fetchRoutingData;

  @routingModule.State('routingData') routingData: RoutingData[] | undefined;

  @mpsModule.Action(MPSActions.FETCH_MPS_DATA) fetchMpsData;

  @assetModule.Action(AssetActions.FETCH_AVAILABLE_MANHOLES) fetchAvailableManholes;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  openDialog = false;

  selectedItems = [];

  newDeploymentDialog = false;

  defaultDeploymentFormButtonTooltipText = 'Create deployment for the selected work orders.'

  deploymentFormButtonTooltip = '';

  processedData = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get filtered(): any[] {
    if (!this.analyticsData || !this.processedData) return [];
    return this.processedData;
  }

  get filteredTableData(): AssetData[] | RoutingData[] {
    if (this.isRoutingTab) {
      return [];
    }
    return this.assetData.filter((asset) => asset.type === this.tabOptions[this.activeTab]);
  }

  get isPlanningTableLineSegmentTab(): boolean {
    return this.selectedAssetType === 1;
  }

  get isRoutingTab(): boolean {
    return this.activeTab >= 2;
  }

  get activeTabString(): string {
    return this.tabOptions[this.activeTab];
  }

  get isDeploymentFormButtonVisible(): boolean {
    return !this.createNewAssign
    && this.isPlanningTableLineSegmentTab
    && this.hasPermissionWorkOrderViewNewDeploymentForm;
  }

  get selectedCategory(): any {
    return this.categories[this.selectedCategoryIndex];
  }

  /**
   * @returns true if the user has the permission WORK_ORDER_CREATE_WORK_ORDER
   */
  get hasPermissionWorkOrderCreateWorkOrder(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_CREATE_WORK_ORDER);
  }

  /**
   * @returns true if the user has the permission WORK_ORDER_VIEW_NEW_DEPLOYMENT_FORM
   */
  get hasPermissionWorkOrderViewNewDeploymentForm(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_VIEW_NEW_DEPLOYMENT_FORM);
  }

  @Watch('patchData')
  onPatchDataChange(): void {
    let url = null;
    try {
      url = new URL(this.patchData);
    } catch (ex) {
      const silence = ex;
    }
    if (url != null) {
      this.openDialog = true;
    }
    this.$forceUpdate();
  }

  @Watch('allUsers', { deep: true })
  onAllUsersChange(): void {
    this.processTableData();
  }

  @Watch('routingData', { deep: true })
  onRoutingDataChange(): void {
    this.processTableData();
  }

  @Watch('analyticsData', { deep: true })
  onAnalyticsDataChange(newAnalyticsData: WorkOrderTableData[] | undefined): void {
    if (!newAnalyticsData) return;
    this.processedData = this.processedData.slice().concat([...newAnalyticsData]);
    this.processTableData(true);
  }

  @Watch('selectedAssetType')
  onActiveTabChange(): void {
    this.selectedItems = [];
  }

  created(): void {
    this.pollData();
  }

  async mounted(): Promise<void> {
    await this.getRouteParams();
    this.fetchAvailableManholes(this.project.guids[0]);
    this.fetchMpsData(this.project.guids[0]);
    this.processTableData();
  }

  processTableData(isAnalyticDataUpdate = false): void {
    if (isAnalyticDataUpdate) {
      const categoryCountsRequest: ListWorkerRequest = {
        items: this.analyticsData,
        functions: this.categoryCountFunctions,
        callback: this.setCategoryCounts,
      };
      this.categoryCounts = [];
      this.startListWorkerRequest(categoryCountsRequest);
    }
  }

  setCategoryCounts(counts: number[]): void {
    const returnValue: Counts[] = [];
    counts.forEach((count: number, index) => {
      returnValue.push({
        categoryID: index,
        count,
      });
    });
    this.categoryCounts = returnValue;
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  get categoryCountFunctions(): AdditionalFilterFunction {
    const returnArray = [];
    const categoryCountsAdditionFunctions: AdditionalFilterFunction = {
      updateKey: uuid.v4(),
      filterFunctions: [],
    };
    let processedFilterFunctions = [];
    if (this.extraFilterFunctions) {
      processedFilterFunctions = this.extraFilterFunctions
        .map((filter: FilterFunction): FilterFunction => ({
          functionVariables: filter.functionVariables,
          filterFunction: filter.filterFunction.toString(),
        }));
    }
    function defaultFunction(
      item,
      categoryFilterFunctionString,
      additionalFilterFunction,
    ) {
      let returnValue = false;
      // eslint-disable-next-line no-eval, no-new-func
      returnValue = new Function(`return ${categoryFilterFunctionString};`)()(item);
      if (!returnValue) {
        return returnValue;
      }
      additionalFilterFunction.forEach((func) => {
        if (returnValue) {
          // eslint-disable-next-line no-eval, no-new-func, prefer-spread
          returnValue = new Function(`return ${func.filterFunction};`)().apply(null, [item].concat(func.functionVariables));
        }
      });
      return returnValue;
    }
    this.categories.forEach((cat) => {
      categoryCountsAdditionFunctions.filterFunctions.push({
        functionVariables: [
          cat.filterFunction.toString(),
          processedFilterFunctions,
        ],
        filterFunction: defaultFunction.toString(),
      });
    });
    return categoryCountsAdditionFunctions;
  }

  pollData(): void {
    if (!this.project || !this.project.guids) {
      return;
    }
    if (this.fetchAssignmentsData) {
      this.refresh();
    }
  }

  get isDeploymentFormButtonDisabled(): boolean {
    const taskTypesSelected = new Set(this.selectedItems.map((value) => value.taskTypeGuid));
    if (taskTypesSelected.size === 0) {
      this.deploymentFormButtonTooltip = 'Select a CCTV, Profiler, or Responder work order.';
      return true;
    }
    if (taskTypesSelected.size !== 1) {
      this.deploymentFormButtonTooltip = 'Select only work orders with the same task type.';
      return true;
    }
    if (!ALLOWED_DEPLOYMENT_FORM_TASK_TYPES.includes(taskTypesSelected
      .values().next().value)
    ) {
      this.deploymentFormButtonTooltip = 'You can only create a deployment on CCTV, Profiler, and Responder work orders.';
      return true;
    }
    this.deploymentFormButtonTooltip = this.defaultDeploymentFormButtonTooltipText;

    return false;
  }

  get extraFilterFunctions(): FilterFunction[] {
    return [
      {
        functionVariables: [this.taskTypeGuids],
        filterFunction:
          function hasValidTaskType(item, validTaskTypes) {
            return validTaskTypes.includes(item.taskTypeGuid);
          },
      },
      {
        functionVariables: [],
        filterFunction:
          function hasValidNodeName(item) {
            return item.nodeName != null && item.nodeName !== '';
          },
      },
    ];
  }

  isMACPTaskType(taskTypeGuid: string): boolean {
    return taskTypeGuid && (taskTypeGuid === MACP_LEVEL_1_COLLECTION_TASK
    || taskTypeGuid === MACP_LEVEL_2_COLLECTION_TASK);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async onEditAssignmentItem(editAssignment: any, shouldEditMACPLevels: boolean): Promise<void> {
    try {
      const editAssignmentList = [editAssignment];
      if (shouldEditMACPLevels) {
        this.analyticsData.forEach(
          (data) => {
            if (this.isMACPTaskType(data['taskTypeGuid']) && data['guid'] !== editAssignment.guid) {
              editAssignmentList.push({
                guid: data['guid'],
                startDate: data['scheduledStartDate'],
                endDate: data['scheduledDueDate'],
                priorityItemGuid: data['priorityItemGuid'],
                taskTypeGuid: editAssignment.taskTypeGuid,
                resourceGroupGuid: data['resourceGroupGuid'],
              });
            }
          },
        );
      }

      await this.editAssignment(editAssignmentList);

      await this.refresh();
      this.showSnackBarSuccess('Edit Successful');
    } catch {
      this.showSnackBarFailue('Edit Error');
    }
  }

  async onRemoveAssignment(workOrderGuids: string[]): Promise<void> {
    try {
      workOrderGuids.forEach((workOrderGuid) => {
        const itemIndex = this.analyticsData
          .findIndex((dataItem: WorkOrderTableData) => dataItem.guid
           === workOrderGuid);
        this.analyticsData.splice(itemIndex, 1);
      });
      this.$forceUpdate();
      this.showSnackBarSuccess('Delete Successful');
    } catch {
      this.showSnackBarFailue('Delete Error');
    }
  }

  async submit(data: WorkOrderListPatchDTO): Promise<void> {
    try {
      await this.patchAssignmentList(data);
      // check success
      if (!this.patchData.includes('error')) {
        this.snackbarMessage = data.createPCM
          ? 'Work Order Assigned. Generated PCMs will be sent to attached addresses'
          : 'Work Order Updated';
        this.snackbarColor = 'green';
        this.snackbarVisible = true;

        this.refresh();

        this.resetEdit();

        // this.fetchPlanningAnalytics(this.project.guids);
        setTimeout(() => {
          this.snackbarMessage = '';
          this.snackbarColor = '';
          this.snackbarVisible = false;
        }, 3000);
      } else { console.log('failure occcured'); this.fail(); }
    } catch {
      // api error
      this.fail();
    }
  }

  async reset(data: unknown): Promise<void> {
    try {
      await this.patchAssignmentList(data);
      this.refresh();
      this.resetEdit();
    } catch {
      this.fail();
    }
  }

  resetEdit(): void{
    const dataTable = this.$refs.dataTable as any;
    if (dataTable.resetEdit) { dataTable.resetEdit(); }
  }

  // eslint-disable-next-line class-methods-use-this
  fail(): void {
    this.showSnackBarFailue('Save Failed');
  }

  cancel(): void {
    this.resetEdit();
  }

  refreshFollowUpWorkOrder(
    followUpMsg: {
      followUpWorkOrder: string,
      isSuccessful: boolean,
    },
  ): void {
    if (followUpMsg.isSuccessful) {
      this.refresh();
      this.showSnackBarSuccess(`Created Follow Up Work Order ${followUpMsg.followUpWorkOrder}`);
    } else {
      this.showSnackBarFailue('Unable To Create Follow Up Work Order');
    }
  }

  async refresh(): Promise<void> {
    // Get the earlies possible date since we
    // Don't want a limit
    const date = new Date(0).toISOString();
    await this.fetchAssignmentsData({
      dateCutoff: date,
      taskTypeGuids: this.taskTypeGuids,
      projectGuids: this.project.guids,
    });
    this.$forceUpdate();
  }

  closeDialog(): void {
    this.showNewAssignDialog = false;
  }

  updateSelectedAssets(newAssetSelection: AssetData[]): void {
    this.selectedAssets = newAssetSelection;
  }

  editWorkOrderPass(): void {
    this.showSnackBarSuccess('Work Order Saved');
  }

  setCreateNewAssign(val: boolean): void {
    this.createNewAssign = val;
  }

  setSelectedCategoryIndex(val: number): void {
    this.selectedCategoryIndex = val;
  }

  getRouteParams(): void {
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      queries.forEach((pair) => {
        const [key, value] = pair;
        if (key === 'category') {
          this.selectedCategoryIndex = parseInt(value as string, 10);
        }
      });
    }
  }

  showSnackBarSuccess(msg: string): void {
    this.showSnackbarMessage(msg, true);
  }

  showSnackBarFailue(msg: string): void {
    this.showSnackbarMessage(msg, false);
  }

  showSnackbarMessage(msg: string, isSuccessful: boolean): void {
    this.snackbarMessage = msg;
    this.snackbarColor = isSuccessful ? 'green' : '#e61e25';
    this.snackbarVisible = true;
    setTimeout(() => {
      this.snackbarMessage = '';
      this.snackbarColor = '';
      this.snackbarVisible = false;
    }, 3000);
  }

  async deploymentSaveResults(results: string): Promise<void> {
    if (results === 'OK') {
      this.showSnackbarMessage('Success adding deployment!', true);
    } else {
      this.showSnackbarMessage(results, false);
    }
    this.refresh();

    this.resetEdit();

    this.newDeploymentDialog = false;
  }
}
