















import { SnackBarMessage } from '@/store/integritySnackBar/types';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const integritySnackBar = namespace('integritySnackBar');
@Component({})
export default class IntegritySnackBar extends Vue {
    @integritySnackBar.State('snackBarMessage') snackBarMessage: SnackBarMessage | undefined;

    timer = 3000;
}
