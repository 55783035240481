var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"routing"},[_c('div',{ref:"top",staticClass:"top"},[_c('div',{attrs:{"id":"asset-title"}},[_c('a',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"tag":"button","href":_vm.$router.resolve({
            name: 'projects',
            params: {
              ids: JSON.stringify([_vm.$route.params.id]),
            },
          }).href}},[_c('v-icon',{staticClass:"back-button",attrs:{"large":""}},[_vm._v("mdi-chevron-left")])],1),(_vm.projectHeaders && _vm.projectHeaders.names)?_c('div',{staticClass:"project-name"},[_vm._v(" "+_vm._s(_vm.projectHeaders.names[0])+" ")]):_vm._e()])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"table-header"},[_c('v-tabs',{staticClass:"routing-tabs",attrs:{"height":"100%","color":"#0C6599"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabOptions),function(tab){return _c('v-tab',{key:tab.name,staticClass:"routing-table-tab py-2",attrs:{"id":tab.name}},[_vm._v(" "+_vm._s(tab.value)+" ")])}),1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-bar",attrs:{"outlined":"","dense":"","hide-details":"","clearable":"","placeholder":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.hasPermissionRoutingDelete)?_c('IntegrityDelete',{attrs:{"deleteFunction":_vm.removeSelectedRoutes,"loading":_vm.deleteLoading},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_c('v-btn',{staticClass:"outline-btn",attrs:{"outlined":"","color":"#0c6599","disabled":_vm.selectedItems.length === 0}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v(" Delete ")],1)]},proxy:true},{key:"optionalSelectorContent",fn:function(){return [_c('v-checkbox',{staticClass:"deleteWorkOrderCheckBox",attrs:{"label":'Delete Associated Work Orders'},model:{value:(_vm.deleteWorkOrders),callback:function ($$v) {_vm.deleteWorkOrders=$$v},expression:"deleteWorkOrders"}})]},proxy:true}],null,false,1320871168)}):_vm._e()],1),_c('div',{staticClass:"routing-table-container"},[_c('IntegrityTable',{ref:"routingTable",attrs:{"tableID":'routing-table',"headers":_vm.headers,"data":_vm.filteredRoutingData,"height":"70vh","filterValues":_vm.filterValues,"loading":_vm.routingLoading,"itemsPerPage":-1,"footerProps":{
          'disable-items-per-page': false,
          'items-per-page-options': [25, 50, -1],
        },"multiSelect":true,"showSelect":true,"selectedItems":_vm.selectedItems,"matchFilters":_vm.matchFilters,"search":_vm.search},on:{"update:data":function($event){_vm.filteredRoutingData=$event},"update:selectedItems":function($event){_vm.selectedItems=$event},"update:selected-items":function($event){_vm.selectedItems=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event},"edit":_vm.handleEdit},scopedSlots:_vm._u([{key:"routeName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.routeName)+" ")]}},{key:"color",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","color":_vm.getRouteColor(item.color),"dark":""}})]}},{key:"delete",fn:function(ref){
        var item = ref.item;
return [(_vm.hasPermissionRoutingDelete)?_c('IntegrityDelete',{attrs:{"deleteFunction":_vm.deleteRoutes.bind(this, [item]),"loading":_vm.deleteLoading},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]},proxy:true},{key:"optionalSelectorContent",fn:function(){return [_c('v-checkbox',{staticClass:"deleteWorkOrderCheckBox",attrs:{"label":'Delete Associated Work Orders'},model:{value:(_vm.deleteWorkOrders),callback:function ($$v) {_vm.deleteWorkOrders=$$v},expression:"deleteWorkOrders"}})]},proxy:true}],null,true)}):_vm._e()]}}])})],1)]),_c('v-dialog',{attrs:{"max-width":"1000px"},model:{value:(_vm.isDeleteError),callback:function ($$v) {_vm.isDeleteError=$$v},expression:"isDeleteError"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Routing Delete Error ")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.deleteRoutingError)+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.routesNotDeletedString)+" ")])],1),_c('v-row',{attrs:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"right",attrs:{"outlined":"","color":"#0c6599"},on:{"click":function($event){_vm.isDeleteError = false}}},[_vm._v(" Done ")])],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"plain":"","small":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v("Dismiss")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackBarMessage)+" ")]),(_vm.hasPermissionRoutingEdit)?_c('RouteOptions',{attrs:{"type":_vm.routeAssetType,"open":_vm.isRouteActive,"assets":_vm.routeAssets,"synchedColor":_vm.editColor,"synchedRouteDescription":_vm.editDescription,"synchedRouteName":_vm.editName,"synchedRouteGuid":_vm.routeGuid,"isEditing":true},on:{"update:synchedColor":function($event){_vm.editColor=$event},"update:synched-color":function($event){_vm.editColor=$event},"update:synchedRouteDescription":function($event){_vm.editDescription=$event},"update:synched-route-description":function($event){_vm.editDescription=$event},"update:synchedRouteName":function($event){_vm.editName=$event},"update:synched-route-name":function($event){_vm.editName=$event},"update:synchedRouteGuid":function($event){_vm.routeGuid=$event},"update:synched-route-guid":function($event){_vm.routeGuid=$event},"toggleRoute":_vm.toggleRoute,"removeAsset":_vm.removeAsset,"postResult":_vm.postResult},scopedSlots:_vm._u([{key:"routeDialog",fn:function(){return [_c('v-card-title',[_vm._v("Edit Route")])]},proxy:true}],null,false,4089438908)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }