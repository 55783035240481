var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"defect-coding-card"},[_c('div',{staticClass:"defect-coding-card-title"},[_c('h2',[_vm._v("Capture Observation")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.displayDefectCodingPopup = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',[_vm._v(" Payout: "+_vm._s(((_vm.isEditing ? parseFloat(_vm.editablePayout.toFixed(1)) : parseFloat(_vm.payout.toFixed(1))) + " " + (_vm.getDistanceUnits())))+" ")]),_c('v-form',{ref:"defectCodingCardInternal",staticClass:"defectCodingCardInternal",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"cropped-image"},[((_vm.isEditing ? _vm.editableImage : _vm.imageLink) !== null)?_c('img',{staticClass:"defect-image",attrs:{"src":_vm.isEditing ? _vm.editableImage : _vm.imageLink}}):_c('div',{staticClass:"defect-image-spinner-container"},[_c('div',{staticClass:"defect-image-spinner"})])]),_c('header',{staticClass:"defect-coding-header"},[_vm._v("Select a Defect")]),_c('v-autocomplete',{ref:"selectDefect",attrs:{"items":_vm.defectItems,"item-text":"text","return-object":"","dense":"","outlined":"","autofocus":"","placeholder":"","append-icon":"mdi-chevron-down","rules":!Array.isArray(_vm.getValidDefectCode())
            ? [function (v) { return _vm.getValidDefectCode(); }]
            : _vm.getValidDefectCode(),"search-input":_vm.searchInput},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"change":_vm.validate},model:{value:(_vm.selectedDefectCode),callback:function ($$v) {_vm.selectedDefectCode=$$v},expression:"selectedDefectCode"}}),_c('header',{staticClass:"defect-coding-header"},[_vm._v("Select a Component")]),(_vm.asset != null && _vm.asset.type == 'Manhole')?_c('v-select',{ref:"selectComponent",attrs:{"items":_vm.defectComponents,"label":"","dense":"","outlined":"","placeholder":"","append-icon":"mdi-chevron-down","rules":!Array.isArray(_vm.getValidComponent())
            ? [function (v) { return _vm.getValidComponent(); }]
            : _vm.getValidComponent(),"disabled":_vm.selectedDefectCode != null ? false : 'disabled'},on:{"change":_vm.validate},model:{value:(_vm.selectedComponent),callback:function ($$v) {_vm.selectedComponent=$$v},expression:"selectedComponent"}}):_vm._e(),_c('div',{staticClass:"defect-coding-row"},[_c('div',{staticClass:"item"},[_c('header',{staticClass:"defect-coding-header"},[_vm._v("Clock From")]),_c('v-text-field',{ref:"clockFrom",attrs:{"validate-on-blur":"","dense":"","outlined":"","placeholder":" ","type":"number","oninput":"if(this.value < 1) this.value = 1; if(this.value > 12) this.value = 12","rules":[
              function (v) { return _vm.getValid(
                  _vm.DVO != null ? _vm.DVO.ClockAtFrom : null,
                  _vm.DVO != null && _vm.DVO.Requirements != null
                    ? _vm.DVO.Requirements.ClockAtFrom != null
                      ? _vm.DVO.Requirements.ClockAtFrom
                      : _vm.displayImperial
                      ? _vm.DVO.Requirements.Imperial != null
                        ? _vm.DVO.Requirements.Imperial.ClockAtFrom != null
                          ? _vm.DVO.Requirements.Imperial.ClockAtFrom
                          : ''
                        : ''
                      : _vm.DVO.Requirements.Metric != null
                      ? _vm.DVO.Requirements.Metric.ClockAtFrom != null
                        ? _vm.DVO.Requirements.Metric.ClockAtFrom
                        : ''
                      : ''
                    : '',
                  v,
                  'INTEGER',
                  0,
                  _vm.fieldTypeClockFrom
                ); } ],"disabled":_vm.DVO != null ? _vm.getDisabled(_vm.DVO.ClockAtFrom) : 'disabled'},on:{"change":_vm.validate},model:{value:(_vm.clockFrom),callback:function ($$v) {_vm.clockFrom=$$v},expression:"clockFrom"}})],1),_c('div',{staticClass:"item"},[_c('header',{staticClass:"defect-coding-header"},[_vm._v("Clock To")]),_c('v-text-field',{ref:"clockTo",attrs:{"validate-on-blur":"","dense":"","outlined":"","placeholder":" ","type":"number","oninput":"if(this.value < 1) this.value = 1; if(this.value > 12) this.value = 12","rules":[
              function (v) { return _vm.getValid(
                  _vm.DVO != null ? _vm.DVO.ClockTo : null,
                  _vm.DVO != null && _vm.DVO.Requirements != null
                    ? _vm.DVO.Requirements.ClockTo != null
                      ? _vm.DVO.Requirements.ClockTo
                      : _vm.displayImperial
                      ? _vm.DVO.Requirements.Imperial != null
                        ? _vm.DVO.Requirements.Imperial.ClockTo != null
                          ? _vm.DVO.Requirements.Imperial.ClockTo
                          : ''
                        : ''
                      : _vm.DVO.Requirements.Metric != null
                      ? _vm.DVO.Requirements.Metric.ClockTo != null
                        ? _vm.DVO.Requirements.Metric.ClockTo
                        : ''
                      : ''
                    : '',
                  v,
                  'INTEGER',
                  0,
                  _vm.fieldTypeClockTo
                ); } ],"disabled":_vm.DVO != null ? _vm.getDisabled(_vm.DVO.ClockTo) : 'disabled'},on:{"change":_vm.validate},model:{value:(_vm.clockTo),callback:function ($$v) {_vm.clockTo=$$v},expression:"clockTo"}})],1),_c('div',{staticClass:"item"},[_c('header',{staticClass:"defect-coding-header"},[_vm._v("Percentage")]),_c('v-text-field',{ref:"percentage",attrs:{"validate-on-blur":"","dense":"","outlined":"","placeholder":" ","type":"number","min":"0","rules":[
              function (v) { return _vm.getValid(
                  _vm.DVO != null ? _vm.DVO.ValuePercent : null,
                  _vm.DVO != null && _vm.DVO.Requirements != null
                    ? _vm.DVO.Requirements.ValuePercent != null
                      ? _vm.DVO.Requirements.ValuePercent
                      : _vm.displayImperial
                      ? _vm.DVO.Requirements.Imperial != null
                        ? _vm.DVO.Requirements.Imperial.ValuePercent != null
                          ? _vm.DVO.Requirements.Imperial.ValuePercent
                          : ''
                        : ''
                      : _vm.DVO.Requirements.Metric != null
                      ? _vm.DVO.Requirements.Metric.ValuePercent != null
                        ? _vm.DVO.Requirements.Metric.ValuePercent
                        : ''
                      : ''
                    : '',
                  v,
                  'DOUBLE',
                  3,
                  _vm.fieldTypePercentage
                ); } ],"disabled":_vm.DVO != null ? _vm.getDisabled(_vm.DVO.ValuePercent) : 'disabled',"step":_vm.percentageIncrement},on:{"change":_vm.handlePercentageChange},model:{value:(_vm.percentage),callback:function ($$v) {_vm.percentage=$$v},expression:"percentage"}})],1)]),_c('div',{staticClass:"defect-coding-row"},[_c('div',{staticClass:"item"},[_c('header',{staticClass:"defect-coding-header"},[_vm._v(" Dimension 1 "+_vm._s(_vm.getDimensionUnits())+" ")]),_c('v-text-field',{ref:"dimensionOne",attrs:{"validate-on-blur":"","dense":"","outlined":"","placeholder":" ","type":"number","rules":[
              function (v) { return _vm.getValid(
                  _vm.DVO != null ? _vm.DVO.Value1stDimension : null,
                  _vm.DVO != null && _vm.DVO.Requirements != null
                    ? _vm.DVO.Requirements.Value1stDimension != null
                      ? _vm.DVO.Requirements.Value1stDimension
                      : _vm.displayImperial
                      ? _vm.DVO.Requirements.Imperial != null
                        ? _vm.DVO.Requirements.Imperial.Value1stDimension != null
                          ? _vm.DVO.Requirements.Imperial.Value1stDimension
                          : ''
                        : ''
                      : _vm.DVO.Requirements.Metric != null
                      ? _vm.DVO.Requirements.Metric.Value1stDimension != null
                        ? _vm.DVO.Requirements.Metric.Value1stDimension
                        : ''
                      : ''
                    : '',
                  v,
                  'DOUBLE',
                  3,
                  _vm.fieldTypeDimension
                ); } ],"disabled":_vm.DVO != null ? _vm.getDisabled(_vm.DVO.Value1stDimension) : 'disabled'},on:{"change":_vm.validate},model:{value:(_vm.dimensionOne),callback:function ($$v) {_vm.dimensionOne=$$v},expression:"dimensionOne"}})],1),_c('div',{staticClass:"item"},[_c('header',{staticClass:"defect-coding-header"},[_vm._v(" Dimension 2 "+_vm._s(_vm.getDimensionUnits())+" ")]),_c('v-text-field',{ref:"dimensionTwo",attrs:{"validate-on-blur":"","dense":"","outlined":"","placeholder":" ","type":"number","rules":[
              function (v) { return _vm.getValid(
                  _vm.DVO != null ? _vm.DVO.Value2ndDimension : null,
                  _vm.DVO != null && _vm.DVO.Requirements != null
                    ? _vm.DVO.Requirements.Value2ndDimension != null
                      ? _vm.DVO.Requirements.Value2ndDimension
                      : _vm.displayImperial
                      ? _vm.DVO.Requirements.Imperial != null
                        ? _vm.DVO.Requirements.Imperial.Value2ndDimension != null
                          ? _vm.DVO.Requirements.Imperial.Value2ndDimension
                          : ''
                        : ''
                      : _vm.DVO.Requirements.Metric != null
                      ? _vm.DVO.Requirements.Metric.Value2ndDimension != null
                        ? _vm.DVO.Requirements.Metric.Value2ndDimension
                        : ''
                      : ''
                    : '',
                  v,
                  'DOUBLE',
                  3,
                  _vm.fieldTypeDimension
                ); } ],"disabled":_vm.DVO != null ? _vm.getDisabled(_vm.DVO.Value2ndDimension) : 'disabled'},on:{"change":_vm.validate},model:{value:(_vm.dimensionTwo),callback:function ($$v) {_vm.dimensionTwo=$$v},expression:"dimensionTwo"}})],1),_c('div',{staticClass:"item"})]),_c('header',{staticClass:"defect-coding-header"},[_vm._v("Defect Position")]),_c('div',{staticClass:"defect-coding-checkboxes"},[_c('v-checkbox',{attrs:{"validate-on-blur":"","hide-details":"","dense":"","label":"At Joint","rules":_vm.DVO != null && _vm.DVO.Joint != null && _vm.DVO.Joint == _vm.requiredString
              ? _vm.requiredField
              : [function (v) { return true; }],"disabled":_vm.DVO != null ? _vm.getDisabled(_vm.DVO.Joint) : 'disabled'},on:{"change":_vm.validate},model:{value:(_vm.joint),callback:function ($$v) {_vm.joint=$$v},expression:"joint"}}),_c('v-checkbox',{attrs:{"validate-on-blur":"","hide-details":"","dense":"","label":"Step","rules":_vm.DVO != null && _vm.DVO.Step != null && _vm.DVO.Step == _vm.requiredString
              ? _vm.requiredField
              : [function (v) { return true; }],"disabled":_vm.DVO != null ? _vm.getDisabled(_vm.DVO.Step) : 'disabled'},on:{"change":_vm.validate},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}}),_c('v-checkbox',{attrs:{"validate-on-blur":"","hide-details":"","dense":"","label":"Continuous Defect","rules":_vm.DVO != null &&
            _vm.DVO.Continuous != null &&
            _vm.DVO.Continuous == _vm.requiredString &&
            _vm.endContinuous != true
              ? _vm.requiredField
              : [function (v) { return true; }],"disabled":_vm.DVO != null ? _vm.getDisabled(_vm.DVO.Continuous) : 'disabled'},on:{"change":_vm.validate},model:{value:(_vm.continuous),callback:function ($$v) {_vm.continuous=$$v},expression:"continuous"}}),(_vm.isEndContinuousActive)?_c('v-checkbox',{attrs:{"validate-on-blur":"","hide-details":"","dense":"","label":"End Continuous Defect","rules":_vm.DVO != null &&
            _vm.DVO.Continuous != null &&
            _vm.DVO.Continuous == _vm.requiredString &&
            _vm.continuous != true
              ? _vm.requiredField
              : [function (v) { return true; }]},on:{"change":_vm.validate},model:{value:(_vm.endContinuous),callback:function ($$v) {_vm.endContinuous=$$v},expression:"endContinuous"}}):_vm._e(),(_vm.isNewDefectForContinuous)?_c('v-checkbox',{attrs:{"validate-on-blur":"","hide-details":"","dense":"","label":"Edit Matching Continuous Defect","rules":_vm.DVO != null &&
            _vm.DVO.Continuous != null &&
            _vm.DVO.Continuous == _vm.requiredString &&
            _vm.continuous != true
              ? _vm.requiredField
              : [function (v) { return true; }]},on:{"change":_vm.validate},model:{value:(_vm.editMatchingContinuousDefect),callback:function ($$v) {_vm.editMatchingContinuousDefect=$$v},expression:"editMatchingContinuousDefect"}}):_vm._e()],1),(_vm.endContinuous)?_c('v-select',{ref:"selectStartingIndex",attrs:{"validate-on-blur":"","items":_vm.nonEndDefects,"label":"Choose the Starting Index","append-icon":"mdi-chevron-down","dense":"","outlined":"","placeholder":"","rules":_vm.requiredField},on:{"change":function($event){_vm.validate();
          _vm.fillStartingDefectCode();}},model:{value:(_vm.selectedStartingIndex),callback:function ($$v) {_vm.selectedStartingIndex=$$v},expression:"selectedStartingIndex"}}):_vm._e(),_c('header',{staticClass:"defect-coding-header"},[_vm._v("Remarks")]),_c('v-textarea',{ref:"remarks",attrs:{"validate-on-blur":"","dense":"","outlined":"","placeholder":" ","rules":[
          function (v) { return _vm.getValid(
              _vm.DVO != null ? _vm.DVO.Remarks : null,
              _vm.DVO != null && _vm.DVO.Requirements != null
                ? _vm.DVO.Requirements.Remarks != null
                  ? _vm.DVO.Requirements.Remarks
                  : _vm.displayImperial
                  ? _vm.DVO.Requirements.Imperial != null
                    ? _vm.DVO.Requirements.Imperial.Remarks != null
                      ? _vm.DVO.Requirements.Imperial.Remarks
                      : ''
                    : ''
                  : _vm.DVO.Requirements.Metric != null
                  ? _vm.DVO.Requirements.Metric.Remarks != null
                    ? _vm.DVO.Requirements.Metric.Remarks
                    : ''
                  : ''
                : '',
              v,
              'STRING'
            ); } ],"disabled":_vm.DVO != null ? _vm.getDisabled(_vm.DVO.Remarks) : 'disabled'},on:{"change":_vm.validate},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_c('header',{staticClass:"defect-coding-header"},[_vm._v("Miscellaneous")]),_c('div',{staticClass:"defect-coding-card-title"},[_c('v-checkbox',{attrs:{"validate-on-blur":"","hide-details":"","dense":"","label":"Rear Camera"},model:{value:(_vm.isRearCamera),callback:function ($$v) {_vm.isRearCamera=$$v},expression:"isRearCamera"}}),(_vm.defectCodingPostError != null)?_c('p',{staticStyle:{"color":"#e61e25","text-align":"center"},attrs:{"id":"defectCodingError"}},[_vm._v(" There was an error with the request ")]):_vm._e(),(_vm.asset.priority != null && _vm.confirmationFlag == false)?_c('div',[_c('IntegrityConfirm',{attrs:{"disabled":_vm.selectedDefectCode !== null && _vm.isFormValid ? false : true,"loading":_vm.defectCodingPostLoading || _vm.defectCodingPatchLoading,"confirmFunction":_vm.submit,"confirmText":_vm.confirmText},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_c('v-btn',{staticClass:"integrity-blue-button",attrs:{"dark":_vm.selectedDefectCode == null || !_vm.isFormValid ? false : true,"disabled":_vm.selectedDefectCode !== null && _vm.isFormValid ? false : true}},[(!_vm.defectCodingPostLoading && !_vm.defectCodingPatchLoading)?_c('div',[_vm._v(" Submit ")]):_c('div',{staticClass:"spinner"})])]},proxy:true}],null,false,3122717848)})],1):_c('div',[_c('v-btn',{staticClass:"integrity-blue-button",attrs:{"dark":_vm.selectedDefectCode == null || !_vm.isFormValid ? false : true,"disabled":_vm.selectedDefectCode !== null && _vm.isFormValid
          ? false
          : true},on:{"click":_vm.submit}},[(!_vm.defectCodingPostLoading && !_vm.defectCodingPatchLoading)?_c('div',[_vm._v(" Submit ")]):_c('div',{staticClass:"spinner"})])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }