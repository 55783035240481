
































import utils from '@/components/IntegrityTable/utils';
import { DataDeliveryCountPlatform, DataDeliveryProjectMetrics, ProjectTotalsObject } from '@/store/metrics/types';
import {
  Component, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const metricsModule = namespace('metrics');
const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
  },
})
export default class PlatformCollectionTable extends Vue {
  @metricsModule.State('projectTotalsData') projectTotalsList: ProjectTotalsObject[];

  @metricsModule.State('dataDeliveryProjectMetrics') dataDeliveryProjectMetrics: DataDeliveryProjectMetrics;

  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  get validDataCheck(): boolean {
    return this.projectTotalsList != null && this.dataDeliveryProjectMetrics != null;
  }

  get nullSafeProjectTotalsList(): ProjectTotalsObject[] {
    if (this.projectTotalsList == null || this.projectTotalsList.length === 0) {
      return [
        {
          platformName: 'No Name Found',
          total: 0,
          remaining: 0,
          addressed: 0,
          collected: 0,
          issues: 0,
        },
      ];
    }
    return [...this.projectTotalsList];
  }

  getPlatformName(totals: ProjectTotalsObject): string {
    return totals?.platformName != null ? totals?.platformName : 'No Name Found';
  }

  getTotal(totals: ProjectTotalsObject): string {
    return totals?.total != null
      ? this.getFormattedNumber(totals?.total) : '0';
  }

  getAddressed(totals: ProjectTotalsObject): string {
    return totals?.addressed != null
      ? this.getFormattedNumber(totals?.addressed) : '0';
  }

  getCollected(totals: ProjectTotalsObject): string {
    return totals?.collected != null
      ? this.getFormattedNumber(totals?.collected) : '0';
  }

  getRemaining(totals: ProjectTotalsObject): string {
    return totals?.remaining != null
      ? this.getFormattedNumber(totals?.remaining) : '0';
  }

  getIssues(totals: ProjectTotalsObject): string {
    return totals?.issues != null
      ? this.getFormattedNumber(totals?.issues) : '0';
  }

  getDataDelivered(totals: ProjectTotalsObject): number {
    const foundDataDeliveryObject = this.getMatchingDataDeliveryObject(totals?.platformName);
    return foundDataDeliveryObject?.arrived != null
      ? foundDataDeliveryObject.arrived
      : 0;
  }

  round(value: number): number {
    return Math.round(value * 100) / 100;
  }

  getMatchingDataDeliveryObject(platformName: string): DataDeliveryCountPlatform {
    if (platformName == null || this.dataDeliveryProjectMetrics?.platforms == null) {
      return undefined;
    }
    const foundValue = this.dataDeliveryProjectMetrics.platforms.find(
      (value) => value.platformName === platformName,
    );
    return foundValue ? {
      ...foundValue,
    } : undefined;
  }

  getFormattedNumber(value: number): string {
    return utils.formatNumber(this.round(value));
  }
}
