import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { UserPrefsState } from './types';
import getters from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state : UserPrefsState = {
  displayImperial: false,
  selectedMapAssetGuid: undefined,
  useZoomFilter: false,
  useDefectCodeDesc: false,
};

const mapPages: Module<UserPrefsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default mapPages;
