import { MutationTree } from 'vuex';
import { AGOLFeatureService, AgolLoginData, AgolState } from './types';

// eslint-disable-next-line no-shadow
export enum AgolMutations {
  SET_AGOL_DATA = 'SET_AGOL_DATA',
  SET_AGOL_DATA_LOADING_STATE = 'SET_AGOL_DATA_LOADING_STATE',
  SET_AGOL_DATA_LOAD_ERROR = 'SET_AGOL_DATA_LOAD_ERROR',
  SET_AGOL_FEATURE_SERVICE_DATA = 'SET_AGOL_FEATURE_SERVICE_DATA',
  SET_AGOL_FEATURE_SERVICE_DATA_LOADING_STATE = 'SET_AGOL_FEATURE_SERVICE_DATA_LOADING_STATE',
  SET_AGOL_FEATURE_SERVICE_DATA_LOAD_ERROR = 'SET_AGOL_FEATURE_SERVICE_DATA_LOAD_ERROR',
  SET_AGOL_LOGIN_DATA = 'SET_AGOL_LOGIN_DATA',
}

export const mutations: MutationTree<AgolState> = {
  [AgolMutations.SET_AGOL_DATA](state, payload: string) {
    state.agolData = payload;
  },

  [AgolMutations.SET_AGOL_DATA_LOADING_STATE](state, payload: boolean) {
    state.agolDataLoading = payload;
  },

  [AgolMutations.SET_AGOL_DATA_LOAD_ERROR](state, payload: string) {
    state.agolDataLoadError = payload;
  },

  [AgolMutations.SET_AGOL_FEATURE_SERVICE_DATA](state, payload: AGOLFeatureService) {
    state.agolFeatureServiceData = payload;
  },

  [AgolMutations.SET_AGOL_FEATURE_SERVICE_DATA_LOADING_STATE](state, payload: boolean) {
    state.agolFeatureServiceDataLoading = payload;
  },

  [AgolMutations.SET_AGOL_FEATURE_SERVICE_DATA_LOAD_ERROR](state, payload: string) {
    state.agolFeatureServiceDataError = payload;
  },

  [AgolMutations.SET_AGOL_LOGIN_DATA](state, payload: AgolLoginData) {
    state.agolLoginData = payload;
  },
};
