









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs/legacy';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  ChartData,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale);

@Component({ components: { Bar } })
export default class StackedHorizontalGraph extends Vue {
    @Prop({ default: 'label' }) readonly datasetIdKey: string;

    @Prop({ default: false }) readonly smallGraph: boolean;

    @Prop() readonly chartData;

    @Prop() readonly title: string;

    @Prop() readonly height: number | undefined;

    @Prop() readonly totalCount: number | undefined;

    delta = {
      backgroundColor: '#cccccc',
      data: [0],
      label: 'delta',
    }

    get chartClass(): string {
      return this.smallGraph === true ? 'stackedHorizontalGraphSmall' : 'stackedHorizontalGraph';
    }

    get normalizedChartData(): ChartData {
      const count = this.chartData.datasets
        .map((dataset) => dataset.data[0])
        .reduce((prev, next) => prev + next, 0);
      const normalizedData = { ...this.chartData };
      this.delta.data = [this.totalCount - count];
      normalizedData.datasets.push(this.delta);
      normalizedData.labels.push(this.delta.label);
      return normalizedData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get chartOptions(): any {
      return {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        layout: {
          padding: {
            bottom: -200,
            left: -200,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => null,
            },
            filter: (tooltipItem) => (tooltipItem.dataset.label !== this.delta.label),
            bodyFont: {
              size: 12,
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            display: false,
            gridLines: {
              display: false,
              tickMarkLength: 0,
            },
          },
          y: {
            stacked: true,
            display: false,
            gridLines: {
              display: false,
              tickMarkLength: 0,
            },
          },
        },
      };
    }

  plugins = [];
}

