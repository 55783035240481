import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { DataStandardState } from './types';
import { DataStandardMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum DataStandardActions {
  GET_DATA_STANDARD = 'GET_DATA_STANDARD',
  POST_DATA_STANDARD = 'POST_DATA_STANDARD',
  PATCH_DATA_STANDARD = 'PATCH_DATA_STANDARD',
}

export const actions: ActionTree<DataStandardState, RootState> = {
  async [DataStandardActions.GET_DATA_STANDARD]({ commit }, payload: string): Promise<void> {
    commit(DataStandardMutations.SET_LOADING_STATE, true);
    commit(DataStandardMutations.SET_DATA_STANDARD_DATA, undefined);
    commit(DataStandardMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDataStandard(payload);

      commit(DataStandardMutations.SET_DATA_STANDARD_DATA, response.data);
    } catch (e) {
      commit(DataStandardMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(DataStandardMutations.SET_LOADING_STATE, false);
    }
  },

  async [DataStandardActions.POST_DATA_STANDARD]({ commit }, payload: unknown): Promise<void> {
    commit(DataStandardMutations.SET_LOADING_STATE, true);
    commit(DataStandardMutations.SET_LOAD_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postDataStandard(payload);
    } catch (e) {
      commit(DataStandardMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(DataStandardMutations.SET_LOADING_STATE, false);
    }
  },
  async [DataStandardActions.PATCH_DATA_STANDARD]({ commit }, payload: unknown): Promise<void> {
    commit(DataStandardMutations.SET_LOADING_STATE, true);
    commit(DataStandardMutations.SET_LOAD_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.patchDataStandard(payload);
    } catch (e) {
      commit(DataStandardMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(DataStandardMutations.SET_LOADING_STATE, false);
    }
  },
};
