var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-x":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({ref:"initiate-export-button",staticClass:"integrity-blue-button initiate-export-button",attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedAssets ? 'Export Project' : 'Export Data')+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{attrs:{"dense":""}},[_c('div',{attrs:{"id":"export-dialog"}},[_c('v-subheader',{staticClass:"export-header"},[_vm._v("Export Data")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.menu = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-list-item',[_c('v-text-field',{attrs:{"label":"Email to send export link","dense":"","outlined":"","placeholder":""},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('v-list-item',[_c('v-select',{attrs:{"label":"File Name Formatting","dense":"","outlined":"","append-icon":"mdi-chevron-down","placeholder":" ","items":_vm.videoFileNameOptions},model:{value:(_vm.videoFileNameScheme),callback:function ($$v) {_vm.videoFileNameScheme=$$v},expression:"videoFileNameScheme"}})],1),_c('v-divider'),_c('v-subheader',{staticClass:"export-header"},[_vm._v("Export Type")]),_vm._l((_vm.exportTypeCheckboxes),function(item,index){return _c('v-list-item',{key:'export'+index,staticClass:"reduce-height",attrs:{"value":item.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"reduce-margin"},[_c('v-checkbox',{attrs:{"input-value":active,"disabled":item.disabled},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}],null,true)})}),_c('v-divider'),_c('v-subheader',{staticClass:"export-header"},[_vm._v("Media Options")]),_vm._l((_vm.mediaTypeCheckboxes),function(item,index){return _c('v-list-item',{key:'export'+index,staticClass:"reduce-height",attrs:{"value":item.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"reduce-margin"},[_c('v-checkbox',{attrs:{"input-value":active,"disabled":item.disabled || !_vm.isDbExport},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}],null,true)})}),_c('v-divider'),_c('v-subheader',{staticClass:"export-header"},[_vm._v("Validation Options")]),_vm._l((_vm.exportOptions),function(item,index){return _c('v-list-item',{key:'validation'+index,staticClass:"reduce-height",attrs:{"value":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"reduce-margin"},[_c('v-checkbox',{attrs:{"input-value":active},model:{value:(_vm.exportOptionsCheckboxes[index]),callback:function ($$v) {_vm.$set(_vm.exportOptionsCheckboxes, index, $$v)},expression:"exportOptionsCheckboxes[index]"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)]}}],null,true)})}),_c('div',{staticClass:"export-footer"},[_c('v-btn',{ref:"export-button",staticClass:"integrity-blue-button",attrs:{"loading":_vm.exportState === 'loading'},on:{"click":_vm.exportData}},[_vm._v(" Export ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }