

























































































































































































































































































































































import { IPDEntry } from '@/store/asset/types';
import {
  CrossSectionData, Line, MSIInspectionData, Point, PVDData,
} from '@/store/crossSections/types';
import Vue from 'vue';
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CrossSectionMeasurementPoints } from './MSICrossSectionTypes';
import util from '../Report/util';

// eslint-disable-next-line no-shadow
export enum RefrenceShape {
  CIRCLE = 'CIRCLE',
}

const crossSectionModule = namespace('crossSections');
const msiPayoutModule = namespace('msiPayout');
const userPrefsModule = namespace('userPrefs');

@Component({})
export default class MSICrossSection extends Vue {
  @Prop() readonly crossSectionData!: CrossSectionData[];

  @Prop() readonly msiInspection!: MSIInspectionData | undefined;

  @Prop() curentVideoTime: number;

  @Prop() readonly isMSIView: boolean;

  @Prop() readonly jestOnlyDisplayImperial: boolean;

  @userPrefsModule.State('displayImperial') storeDisplayImperial: boolean;

  @crossSectionModule.State('pvdData') pvdData: PVDData | undefined;

  @msiPayoutModule.State('currentPayout') currentPayout: IPDEntry | undefined;

  zeroX = 0;

  zeroY = 0;

  zeroWater = 0;

  zeroWaterSet = false;

  waterLevelX1 = 0;

  waterLevelX2 = 0;

  waterLevelY = 0;

  currentDataLines: Array<Line> = [];

  currentData:CrossSectionData = null;

  refrenceShape: RefrenceShape = RefrenceShape.CIRCLE;

  refrenceShapes = {
    CIRCLE: RefrenceShape.CIRCLE,
  }

  MAX_ZOOM_VALUE = 2;

  MIN_ZOOM_VALUE = 0.5;

  ZOOM_STEP = 0.1;

  zoomValue = 1;

  measurementPoints: CrossSectionMeasurementPoints = new CrossSectionMeasurementPoints();

  measurementPointerCords: DOMPoint;

  measureFreeform = false;

  measureSnapToCircle = false;

  measureSnapToPipe = false;

  waterLevelMarker = true;

  get ratioToShrink(): number {
    if (this.currentData != null) {
      return 50 / this.currentData.refrenceDiameter;
    }
    return 1;
  }

  get displayImperial(): boolean {
    return this.storeDisplayImperial != null
      ? this.storeDisplayImperial : this.jestOnlyDisplayImperial;
  }

  @Watch('curentVideoTime')
  onCurrentFrameChange(): void {
    let returnValue: CrossSectionData = null;
    if (this.crossSectionData != null) {
      let lastData: CrossSectionData = null;

      this.crossSectionData.forEach((data) => {
        // TODO: maybe a more refined algorithm
        if (returnValue == null) {
          if (data.distance < (this.currentPayout.payout / 100)) {
            lastData = data;
          } else if (this.curentVideoTime === (this.currentPayout.payout / 100)) {
            returnValue = data;
          } else if (lastData != null) {
            returnValue = lastData;
          } else {
            returnValue = data;
          }
        }
      });
    }
    this.updateCanvas(returnValue);
  }

  /**
   * @description Getter for the scale of the cross section for zooming purposes
   * @returns The transform style for the cross section
   */
  get svgTransform(): string {
    return `transform: scale(${this.zoomValue});`;
  }

  /**
   * @description Getter for the poly line strings of the lines in the cross section.
   * Lines with one point get retrieved in crossSectionPoints getter and filtered out here.
   * @returns The poly line strings of all the cross section lines
   */
  get crossSectionLines(): string[] {
    const returnValue: string[] = [];
    let skipSpace = true;
    if (this.currentDataLines != null) {
      this.currentDataLines.forEach((line) => {
        let appendValue = '';
        // Checking if there is only 1 or less points because that means it's just a point.
        // We get that data in "get crossSectionPoints"
        if (line.points != null && line.points.length > 1) {
          line.points.forEach((point) => {
            if (!skipSpace) {
              appendValue += ' ';
            } else {
              skipSpace = false;
            }
            appendValue += `${this.zeroX + (point.x * this.ratioToShrink)},${this.zeroY + (this.ratioToShrink * point.y)}`;
          });
        }
        if (appendValue.trim() !== '') {
          returnValue.push(appendValue);
        }
      });
    }

    return returnValue;
  }

  /**
   * @description Gets the lines with only one point to render as a circle instead of a line.
   * @returns The points to render
   */
  get crossSectionPoints(): Point[] {
    const returnValue: Point[] = [];
    if (this.currentDataLines != null) {
      this.currentDataLines.forEach((line) => {
        // Checking if there is only 1 because that means it's just a point and not a line.
        if (line.points != null && line.points.length === 1) {
          line.points.forEach((point) => {
            // We can't just push the point because we have to factor in scaling
            returnValue.push({
              x: this.zeroX + (point.x * this.ratioToShrink),
              y: this.zeroY + (this.ratioToShrink * point.y),
            });
          });
        }
      });
    }
    return returnValue;
  }

  mounted(): void {
    const canvasDiv = document.getElementById('cross-section-layout');
    if (canvasDiv) {
      const resizeObserver = new ResizeObserver(() => { this.setZeroes(); });
      resizeObserver.observe(canvasDiv);
      this.measurementPointerCords = new DOMPoint(0, 0);
    }

    if (this.crossSectionData != null && this.curentVideoTime != null) {
      this.onCurrentFrameChange();
    }

    window.addEventListener('resize', () => {
      setTimeout(async () => {
        await this.$forceUpdate();
        this.onCurrentFrameChange();
        this.setZeroes();
      }, 100);
    });
  }

  updateCanvas(data: CrossSectionData): void {
    this.currentDataLines = data.lines;
    this.currentData = data;

    if (!this.zeroWaterSet) this.setZeroes();

    const waterLevel = util.getDisplayDistanceInMM(
      this.displayImperial, Math.round((this.currentData.waterLevel + Number.EPSILON) * 10) / 10,
    );
    const ratio = waterLevel / this.currentData.refrenceDiameter;
    const waterLevelTranslation = (this.currentData.refrenceDiameter * this.ratioToShrink) * ratio;
    this.waterLevelY = this.zeroWater - waterLevelTranslation;
  }

  getDisplayDistanceInM3(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceInM3(
        this.displayImperial, Math.round((value + Number.EPSILON) * 10) / 10,
      )
      + util.getDistanceUnitsFtM(this.displayImperial) : '';
  }

  getDisplayDistanceInMM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceInMM(
        this.displayImperial, Math.round((value + Number.EPSILON) * 10) / 10,
      )
      + util.getDistanceUnitsInMM(this.displayImperial) : '';
  }

  getDisplayDistanceFtM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceFtM(
        this.displayImperial, Math.round((value + Number.EPSILON) * 10) / 10,
      )
        + util.getDistanceUnitsFtM(this.displayImperial) : '';
  }

  setZeroes(): void {
    if (!this.isMSIView) return;

    const canvasDiv = document.getElementById('cross-section-layout');

    if (canvasDiv != null) {
      // get shorter side of the canvas
      const minLength = Math.min(canvasDiv.offsetWidth, canvasDiv.offsetHeight);

      // super janky way to calculate scale, could do with fine tuning but works for now
      const scale = (minLength * 1.7) / 100;

      this.zeroX = canvasDiv.offsetWidth / scale;
      this.zeroY = canvasDiv.offsetHeight / scale;

      this.waterLevelX1 = this.zeroX - 28;
      this.waterLevelX2 = this.zeroX + 28;

      this.zeroWater = this.zeroY + (this.currentData.refrenceDiameter * this.ratioToShrink) / 2;
      this.zeroWaterSet = true;
    }
  }

  // #region Cross Section Control Functions
  /**
   * @description Gets the active button style if button is active
   * @returns Active button style
   */
  getCrossSectionControlButtonStyle(active: boolean): string {
    return active ? 'cross-section-control-button-active' : '';
  }

  /**
   * @description Gets if a measurement button is active
   * @returns Active button status
   */
  get isMeasurementActive(): boolean {
    return this.measureFreeform
    || this.measureSnapToCircle
    || this.measureSnapToPipe;
  }

  /**
   * @description Set all the measurement buttons to not active
   */
  setAllMeasurementButtonsToFalse(): void {
    this.measureFreeform = false;
    this.measureSnapToCircle = false;
    this.measureSnapToPipe = false;
  }

  /**
   * @description Zooms in the cross section
   */
  onZoomInClick(): void {
    const newZoomValue = this.zoomValue + this.ZOOM_STEP;

    if (newZoomValue <= this.MAX_ZOOM_VALUE) {
      this.zoomValue = newZoomValue;
    } else {
      this.zoomValue = this.MAX_ZOOM_VALUE;
    }
  }

  /**
   * @description Zooms out the cross section
   */
  onZoomOutClick(): void {
    const newZoomValue = this.zoomValue - this.ZOOM_STEP;

    if (newZoomValue >= this.MIN_ZOOM_VALUE) {
      this.zoomValue = newZoomValue;
    } else {
      this.zoomValue = this.MIN_ZOOM_VALUE;
    }
  }

  /**
   * @description On freeform button click, activate or deactivate
   */
  onMeasureFreeformClick(): void {
    const setTo = !this.measureFreeform;
    this.setAllMeasurementButtonsToFalse();
    this.measureFreeform = setTo;
    if (!setTo) {
      this.measurementPoints.resetPoints();
    }
  }

  /**
   * @description On snap to circle button click, activate or deactivate
   */
  onMeasureSnapToCircleClick(): void {
    const setTo = !this.measureSnapToCircle;
    this.setAllMeasurementButtonsToFalse();
    this.measureSnapToCircle = setTo;
    if (!setTo) {
      this.measurementPoints.resetPoints();
    }
  }

  /**
   * @description On snap to pipe button click, activate or deactivate
   */
  onMeasureSnapToPipeClick(): void {
    const setTo = !this.measureSnapToPipe;
    this.setAllMeasurementButtonsToFalse();
    this.measureSnapToPipe = setTo;
    if (!setTo) {
      this.measurementPoints.resetPoints();
    }
  }

  /**
   * @description On water level marker button click, activate or deactivate
   */
  onWaterLevelMarkerClick(): void {
    this.waterLevelMarker = !this.waterLevelMarker;
  }

  /**
   * @description Get freeform position clicked on svg
   * @returns Point clicked
   */
  getClickedPosition(event: MouseEvent): DOMPointReadOnly {
    const svg = this.$refs.svgField as SVGAElement;
    const domPoint = new DOMPointReadOnly(event.clientX, event.clientY);
    const pt = domPoint.matrixTransform(svg.getScreenCTM().inverse());
    return pt;
  }

  /**
   * @description Get closest circle position clicked on svg
   * @returns Closest circle point clicked
   */
  getClickedPositionSnapCircle(event: MouseEvent): DOMPointReadOnly {
    const svg = this.$refs.svgField as SVGAElement;
    const domPoint = new DOMPointReadOnly(event.clientX, event.clientY);
    const pt = domPoint.matrixTransform(svg.getScreenCTM().inverse());

    const vX = pt.x - this.zeroX;
    const vY = pt.y - this.zeroY;
    const magV = Math.sqrt(vX ** 2 + vY ** 2);
    const radius = (this.currentData.refrenceDiameter * this.ratioToShrink) / 2;
    const aX = this.zeroX + (vX / magV) * radius;
    const aY = this.zeroY + (vY / magV) * radius;

    return new DOMPoint(aX, aY);
  }

  /**
   * @description Get closest pipe position clicked on svg
   * @returns Closest pipe point clicked
   */
  getClickedPositionSnapPipe(event: MouseEvent): DOMPointReadOnly {
    const svg = this.$refs.svgField as SVGAElement;
    const domPoint = new DOMPointReadOnly(event.clientX, event.clientY);
    const pt = domPoint.matrixTransform(svg.getScreenCTM().inverse());

    let closestPoint = new DOMPoint(0, 0);
    let currentDistance: number | undefined;
    if (this.currentDataLines != null && this.currentDataLines.length > 0) {
      // Loop through all the lines then all the points
      this.currentDataLines.forEach((line) => {
        if (line.points != null && line.points.length > 0) {
          line.points.forEach((point) => {
            const currentPoint = new DOMPoint(this.zeroX + (point.x * this.ratioToShrink),
              this.zeroY + (this.ratioToShrink * point.y));
            if (currentDistance == null
              || this.getDistance(currentPoint, pt) < currentDistance
            ) {
              closestPoint = currentPoint;
              currentDistance = this.getDistance(currentPoint, pt);
            }
          });
        }
      });
    }

    return closestPoint;
  }

  /**
   * @description Changes svg pointer ball to correct location when mouse moved on svg
   */
  onMeasurementMouseMove(event: MouseEvent): void {
    if (this.measureFreeform) {
      this.measurementPointerCords = this.getClickedPosition(event);
      this.$forceUpdate();
    } else if (this.measureSnapToCircle) {
      this.measurementPointerCords = this.getClickedPositionSnapCircle(event);
      this.$forceUpdate();
    } else if (this.measureSnapToPipe) {
      this.measurementPointerCords = this.getClickedPositionSnapPipe(event);
      this.$forceUpdate();
    }
  }

  /**
   * @description Sets the starting or ending point to the mouse position point on click
   * (May be snapped to circle or pipe)
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMeasurementClick(event: MouseEvent): void {
    if (this.isMeasurementActive) {
      this.measurementPoints.addPoint(this.measurementPointerCords);
    }
  }

  /**
   * @description Resets measurement on right click
   */
  onMeasurementRightClick(event: MouseEvent): void {
    // Right mouse button
    if (event.button === 2) {
      event.preventDefault();
      this.measurementPoints.resetPoints();
    }
  }

  /**
   * @description Returns the distance between 2 points
   * @param pointStart The starting point
   * @param pointEnd The ending point
   * @returns The distance between the given points
   */
  getDistance(pointStart: DOMPoint | DOMPointReadOnly,
    pointEnd: DOMPoint | DOMPointReadOnly): number {
    const deltaX = pointEnd.x - pointStart.x;
    const deltaY = pointEnd.y - pointStart.y;
    return Math.sqrt(deltaX ** 2 + deltaY ** 2);
  }
  // #endregion
}
