




















































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { namespace } from 'vuex-class';
import { ReleasesActions } from '@/store/releases/actions';
import { ReleaseOverviewObject, ReleaseTableData } from '@/store/releases/types';
import utils from '@/components/Report/util';
import { format } from 'date-fns';
import { ScopeHistoryActions } from '@/store/scopeHistory/actions';
import ReleaseEdit from '../ProjectManager/ReleaseEdit/ReleaseEdit.vue';
import { DeliveryTotal, ProjectDeliveryTotals } from '../ScopeHistory/types';

const releaseModule = namespace('releases');
const scopeHistoryModule = namespace('scopeHistory');
const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
    IntegrityTable,
    ReleaseEdit,
  },
})
export default class ReleaseOverview extends Vue {
    @Prop() readonly unitLabel;

    @Prop() readonly projectGuids;

    @Prop({ default: true }) readonly canEdit: boolean;

    @Prop({ default: true }) readonly canDelete: boolean;

    @releaseModule.State('releaseTableData') releaseTableData: ReleaseTableData[];

    @releaseModule.State('releaseOverview') releaseOverview: ReleaseOverviewObject[];

    @releaseModule.Action(ReleasesActions.FETCH_RELEASES_OVERVIEW) fetchAssignmentsData;

    @releaseModule.State('deleteReleaseLoading') deleteReleaseLoading: boolean;

    @releaseModule.Action(ReleasesActions.DELETE_RELEASE) deleteRelease;

    @releaseModule.State('deleteReleaseResponse') deleteReleaseResponse: string;

    @releaseModule.Action(ReleasesActions.GET_INSPECTION_RELEASES) getInspectionReleases;

    @scopeHistoryModule.Action(ScopeHistoryActions.FETCH_PROJECT_DELIVERY_TOTALS)
    fetchProjectDeliveryTotals;

    @scopeHistoryModule.State('projectDeliveryTotalsLoading') projectDeliveryTotalsLoading: boolean;

    @scopeHistoryModule.State('projectDeliveryTotalsError') projectDeliveryTotalsError: string | undefined;

    @scopeHistoryModule.State('projectDeliveryTotals') projectDeliveryTotals: ProjectDeliveryTotals;

    @userPrefsModule.State('displayImperial') displayImperial: boolean;

    activeTab = 0;

    openReleaseEdit = false;

    editReleaseModel = {};

    search = '';

    tabOptions = [
      { name: 'Manhole', value: 'Manhole' },
      { name: 'Line Segment', value: 'Line Segment' },
    ];

    tableHeaders = [
      {
        text: 'Release', value: 'releaseName', sortable: true, filterable: false, class: 'sticky', cellClass: 'sticky',
      },
      {
        text: '', value: 'navigation', sortable: false, filterable: false, class: 'sticky', cellClass: 'sticky',
      },
      {
        text: 'Total', value: 'totalReleased', sortable: true, filterable: true,
      },
      {
        text: 'Date Released', value: 'dateReleased', sortable: true, filterable: true,
      },
      {
        text: 'Date Shipped', value: 'dateShipped', sortable: true, filterable: true,
      },
      {
        text: 'Tracking Number', value: 'trackingInformation', sortable: true, filterable: true,
      },
      {
        text: 'Export Type', value: 'exportType', sortable: true, filterable: true,
      },
      {
        text: 'Transmittal Letter', value: 'url', sortable: true, filterable: true,
      },
      {
        text: 'Actions', value: 'actions', sortable: false, filterable: false, class: 'sticky-end', cellClass: 'sticky-end',
      },
    ];

    matchFilters = [
      {
        header: 'totalReleased', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'dateReleased', type: 'date', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'dateShipped', type: 'date', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'trackingInformation', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'exportType', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'url', type: 'string', value: '', method: '', options: [], tempValue: '', open: false, conversionFunction: this.getTransmittalFileName,
      },
    ]

    sortBy = 'dateShipped';

    tableHeight = '100%';

    showUndoDialog = false;

    releaseToUndo = '';

    releaseGuidToUndo = '';

    filterValues = {};

    blankMatchFilters = {}

    snackbarMessage = '';

    snackbarColor = '';

    snackbarShow = false;

    get filteredTableData(): ReleaseOverviewObject[] {
      const assetTypes = ['Manhole', 'Line Segment'];
      if (this.releaseOverview) {
        return this.releaseOverview.filter(
          (release) => release.assetType === assetTypes[this.activeTab],
        );
      }
      return [];
    }

    mounted(): void {
      this.fetchProjectDeliveryTotals(this.projectGuidsList);
      this.fetchAssignmentsData(this.projectGuidsList[0]);
      this.updateFilterValues();
      this.updateTotalHeaderString();
      this.updateTotalValues();
    }

    @Watch('releaseOverview')
    onReleaseOverviewChange(): void{
      this.updateFilterValues();
      this.updateTotalValues();
    }

    @Watch('displayImperial')
    onDisplayImperialChange(): void {
      this.updateTotalHeaderString();
      this.updateTotalValues();
    }

    @Watch('releaseTableData')
    onReleaseTableDataChange(): void {
      this.updateTotalValues();
    }

    @Watch('activeTab')
    onActiveTabChange(): void {
      this.updateFilterValues();
      this.updateTotalHeaderString();
    }

    updateFilterValues(): void {
      this.filterValues = {};

      function compareNumbers(a, b) {
        return a - b;
      }
      this.tableHeaders.forEach((header) => {
        this.filteredTableData.forEach((entry: any) => {
          // Populate filterValues with unique values for each column
          let checkVal: string;
          if (this.matchFilters.find((value) => value.header === header.value)) {
            if (!this.filterValues[header.value]) {
              this.filterValues[header.value] = [];
            }

            try {
              checkVal = entry[header.value];
            } catch {
              checkVal = '';
            }

            if (
              checkVal != null
                && checkVal !== ''
                && Array.isArray(this.filterValues[header.value])
                && !this.filterValues[header.value].includes(checkVal)
            ) {
              this.filterValues[header.value].push(checkVal);
            }

            if (
              header.value === 'url'
                && !this.filterValues[header.value].includes(entry['transmittalFileName'])
            ) {
              this.filterValues[header.value].push(entry['transmittalFileName']);
            }
          }
        });
        if (this.filterValues[header.value]) {
          this.filterValues[header.value].sort();
          this.filterValues[header.value].sort(compareNumbers);
        }
      });
    }

    getTransmittalFileName(item: any): string {
      return item.transmittalFileName;
    }

    clearAllFilters(): void {
      (this.$refs.releaseOverviewTable as IntegrityTable).clearAllMatchFilters();
    }

    get projectGuidsList(): string[] {
    // TODO: ... ew
      return JSON.parse(this.projectGuids);
    }

    getFormattedDate(date: string): string {
      return format(new Date(date), 'yyyy-MM-dd');
    }

    edit(releaseOverviewObject: ReleaseOverviewObject): void {
      this.editReleaseModel = { ...releaseOverviewObject };
      if (this.editReleaseModel['dateShipped']) {
        this.editReleaseModel['dateShipped'] = this.getFormattedDate(releaseOverviewObject['dateShipped']);
      }
      this.openReleaseEdit = true;
    }

    toggleUndoDialog(name: string, guid: string): void {
      this.showUndoDialog = true;
      this.releaseToUndo = name;
      this.releaseGuidToUndo = guid;
    }

    async undo(): Promise<void> {
      try {
        await this.deleteRelease(this.releaseGuidToUndo);
        this.snackbarMessage = `${this.releaseToUndo} deleted`;
        this.snackbarColor = 'success';
      } catch (e) {
        this.snackbarMessage = (e as Error).message;
        this.snackbarColor = 'error';
      } finally {
        this.snackbarShow = true;
        this.showUndoDialog = false;
        await this.fetchAssignmentsData(JSON.parse(this.projectGuids)[0]);
        await this.getInspectionReleases(JSON.parse(this.projectGuids)[0]);
      }
    }

    /**
    * @description gets the delivery metrics from the current tab
    * @returns Delivery metrics for tab
    */
    get currentDeliveryTab(): DeliveryTotal | undefined {
      if (!this.projectDeliveryTotals) {
        return null;
      }
      if (this.activeTab === 0) {
        return this.projectDeliveryTotals.manhole;
      }
      return this.projectDeliveryTotals.lineSegment;
    }

    /**
    * @description format the delivery count to add commas and convert feet/meters
    * @returns formatted delivery count string
    */
    formatDeliveryValue(count: number): string {
      if (this.activeTab === 0) return utils.numberWithCommas(count);
      return `${utils.numberWithCommas(
        utils.getDisplayDistanceFtM(this.displayImperial, count),
      )} ${utils.getDistanceUnitsFtM(this.displayImperial)}`;
    }

    /**
    * @description gets the formatted delivery totals from the current tab
    * @returns formatted delivery total
    */
    get deliveryTotal(): string {
      return this.formatDeliveryValue(this.currentDeliveryTab?.total ?? 0);
    }

    /**
    * @description gets the formatted delivery coded values from the current tab
    * @returns formatted delivery coded values
    */
    get deliveryCoded(): string {
      return this.formatDeliveryValue(this.currentDeliveryTab?.coded ?? 0);
    }

    /**
    * @description gets the formatted delivery released values from the current tab
    * @returns formatted delivery released values
    */
    get deliveryReleased(): string {
      return this.formatDeliveryValue(this.currentDeliveryTab?.released ?? 0);
    }

    /**
    * @description Updates the 'totalReleased' values
    */
    updateTotalValues(): void {
      if (!this.releaseOverview) {
        return;
      }
      this.releaseOverview.forEach((data) => {
        // eslint-disable-next-line no-param-reassign
        data['totalReleased'] = this.calculateReleaseTotal(data);
      });
      this.updateFilterValues();
    }

    /**
    * @description Updates the 'totalReleased' header to include units for line segments
    */
    updateTotalHeaderString(): void {
      const totalString = 'Total';
      const isManholeTab = this.tabOptions[this.activeTab].name === 'Manhole';
      const totalHeaderString = isManholeTab ? totalString : `${totalString} (${utils.getDistanceUnitsFtM(this.displayImperial)})`;
      const totalReleasedHeader = this.tableHeaders.find((header) => header.value === 'totalReleased');
      if (totalReleasedHeader) {
        totalReleasedHeader.text = totalHeaderString;
      }
    }

    /**
    * @description gets the 'totalReleased' value for the table
    * @returns the 'totalReleased' for the table
    */
    calculateReleaseTotal(item: ReleaseOverviewObject): number {
      const releases = this.releaseTableData.filter(
        (value) => value.releaseGuid === item.releaseGuid,
      );

      if (item.assetType === 'Manhole') {
        return releases.length;
      }

      const totalLength = releases.reduce((a, b) => a + b.length ?? 0, 0);
      return utils.getDisplayDistanceFtM(this.displayImperial, totalLength);
    }
}
