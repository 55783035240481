var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('IntegrityTable',{ref:"routingTable",attrs:{"tableID":'routing-assignment-table',"headers":_vm.headers,"data":_vm.filteredRouteData,"height":"100%","filterValues":_vm.filterValues,"loading":_vm.routingLoading,"itemsPerPage":-1,"footerProps":{
    'disable-items-per-page': false,
    'items-per-page-options': [25, 50, -1],
  },"multiSelect":true,"showSelect":true,"selectedItems":_vm.selectedItems,"matchFilters":_vm.matchFilters,"id":"new-routing-assign-table"},on:{"update:data":function($event){_vm.filteredRouteData=$event},"update:selectedItems":function($event){_vm.selectedItems=$event},"update:selected-items":function($event){_vm.selectedItems=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event}},scopedSlots:_vm._u([{key:"routeName",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.routeName)+" "),_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","color":_vm.getRouteColor(item.color),"dark":""}},[_vm._v(" "+_vm._s("")+" ")])]}},{key:"delete",fn:function(ref){
  var item = ref.item;
return [_c('IntegrityDelete',{attrs:{"deleteFunction":_vm.deleteRoutes.bind(this, [item]),"loading":_vm.deleteLoading},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_c('v-btn',{staticClass:"delete-btn",attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]},proxy:true},{key:"optionalSelectorContent",fn:function(){return [_c('v-checkbox',{staticClass:"deleteWorkOrderCheckBox",attrs:{"label":'Delete Associated Work Orders'},model:{value:(_vm.deleteWorkOrders),callback:function ($$v) {_vm.deleteWorkOrders=$$v},expression:"deleteWorkOrders"}})]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }