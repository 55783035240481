




























































































































































// import { mapState } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AssetActions } from '@/store/asset/actions';
import { ProjectData } from '@/store/project/types';
import { AssetPayload } from '@/store/asset/types';
// import { NODEITEM_MANHOLE_GUID } from './Constants';

const assetModule = namespace('asset');
const projectModule = namespace('project');

@Component({})
export default class LineSegmentEntryForm extends Vue {
  successMessageStyle = '';

  successMessage = '';

  valid = true;

  assetId = '';

  showMessage = false;

  assetIdRules = [
    (v: string): string | boolean => v !== '' || 'Name is required',
    (v: string): string | boolean => !!v || 'Name is required',
    (v: string): string | boolean => (!!v && v.length <= 200) || 'Name cannot be more than 200 characters',
  ];

  mapPage = '';

  mapPageRules = [];

  upstreamManhole = '';

  upstreamManholeRules = [
    (v: string): string | boolean => v !== '' || 'Upstream manhole is required',
    (v: string): string | boolean => !!v || 'Upstream manhole is required',
  ];

  usmhLat = '';

  usmhLongRules = [
    (v: string): string | boolean => v !== '' || 'Upstream manhole latitude is required',
    (v: string): string | boolean => !!v || 'Upstream manhole latitude is required',
    (v: number): string | boolean => (v && v >= -90 && v <= 90) || 'Latitude must be -90 to 90',
  ];

  usmhLong = '';

  usmhLatRules = [
    (v: string): string | boolean => v !== '' || 'Upstream manhole longitude is required',
    (v: string): string | boolean => !!v || 'Upstream manhole longitude is required',
    (v: number): string | boolean => (v && v >= -180 && v <= 180) || 'Longitude must be -180 to 180',
  ];

  downstreamManhole = '';

  downstreamManholeRules = [
    (v: string): string | boolean => v !== '' || 'Downstream manhole is required',
    (v: string): string | boolean => !!v || 'Downstream manhole is required',
  ];

  dsmhLat = '';

  dsmhLatRules = [
    (v: string): string | boolean => v !== '' || 'Downstream manhole latitude is required',
    (v: string): string | boolean => !!v || 'Downstream manhole latitude is required',
    (v: number): string | boolean => (v && v >= -90 && v <= 90) || 'Latitude must be -90 to 90',
  ];

  dsmhLong = '';

  dsmhLongRules = [
    (v: string): string | boolean => v !== '' || 'Downstream manhole longitude is required',
    (v: string): string | boolean => !!v || 'Downstream manhole longitude is required',
    (v: number): string | boolean => (v && v >= -180 && v <= 180) || 'Longitude must be -180 to 180',
  ];

  sizeWidth = '';

  sizeHeight = '';

  sizeRules = [(v: string): string | boolean => v === undefined || /^[0-9]*$/.test(v) || 'Size must be numerical'];

  shape = '';

  shapeRules = [];

  pipeMaterial = '';

  pipeMaterialRules = [];

  gisLength = 0;

  gisLengthRules = [
    (v: string): string | boolean => v !== '' || 'GIS length is required',
    (v: string): string | boolean => !!v || 'GIS length is required',
    (v: string): string | boolean => (!!v && /^[0-9]*$/.test(v)) || 'GIS length must be numerical',
  ];

  comments = '';

  commentsRules = [];

  isImperial = true;

  @assetModule.State('loadError') loadError: string | undefined;

  @assetModule.Action(AssetActions.POST_SINGLE_ASSET_DATA) postSingleAssetData;

  @projectModule.State('data') project: ProjectData | undefined;

  // eslint-disable-next-line class-methods-use-this
  get manholes(): string[] {
    const manholeNames = [];
    // if (this.nodes) {
    //   const manholes = this.nodes.filter((i) => i.nodeItemGuid === NODEITEM_MANHOLE_GUID);
    //   manholes.forEach((manhole) => {
    //     manholeNames.push(manhole.name);
    //   });
    // }
    return manholeNames;
  }

  get gisSuffix(): string {
    return this.isImperial ? 'ft' : 'm';
  }

  get sizeSuffix(): string {
    return this.isImperial ? 'in' : 'mm';
  }

  save(): void {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (!this.valid) return;

    const lineSegmentData: AssetPayload = {
      projectGuid: this.project.guids[0],
      assetId: this.assetId,
      assetType: 'Line Segment',
      mapPage: this.mapPage,
      upstreamMH: this.upstreamManhole,
      downstreamMH: this.downstreamManhole,
      pipeSizeWidth: this.sizeWidth,
      pipeSizeHeight: this.sizeHeight,
      shape: this.shape,
      pipeMaterial: this.pipeMaterial,
      lengthGIS: this.gisLength,
    };

    this.postSingleAssetData(lineSegmentData).then(() => {
      if (this.loadError) {
        this.successMessage = 'Line segment creation failed.';
        this.successMessageStyle = `background-color: #e61e25;
                                    color: white;
                                    padding: 8px 16px;
                                    border-radius: 4px;
                                    width: 100%;
                                    display: flex;
                                    margin-top: 24px;
                                    justify-content: space-between;`;
        this.showMessage = true;
      } else {
        this.successMessage = 'Line segment successfully added.';
        this.successMessageStyle = `background-color: #4caf50;
                                    color: white;
                                    padding: 8px 16px;
                                    border-radius: 4px;
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;`;
        this.showMessage = true;
        (this.$refs.form as Vue & { reset: () => void }).reset();
      }
    });
  }

  reset(): void {
    (this.$refs.form as Vue & { reset: () => void }).reset();
    this.successMessage = '';
    this.successMessageStyle = '';
  }
}
