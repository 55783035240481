/* eslint-disable import/prefer-default-export */

import util from '../Report/util';

/**
 * @description Contains methods to control and get measurement details for 2 points.
 * @member {DOMPoint | undefined} pointStart
 * The starting point. Recommended to set using setPointStart or addPoint.
 * @member {DOMPoint | undefined} pointEnd
 * The ending point. Recommended to set using setPointEnd or addPoint.
 */
export class CrossSectionMeasurementPoints {
    pointStart?: DOMPoint = undefined;

    pointEnd?: DOMPoint = undefined;

    /**
     * @description Sets the starting point of the measurement
     * @param point The point to set
     */
    setPointStart(point: DOMPointReadOnly | DOMPoint): void {
      this.pointStart = DOMPoint.fromPoint(point);
    }

    /**
     * @description Sets the ending point of the measurement
     * @param point The point to set
     */
    setPointEnd(point: DOMPointReadOnly | DOMPoint): void {
      this.pointEnd = DOMPoint.fromPoint(point);
    }

    /**
     * @description Adds a point in the correct position while measuring (Either start or end point)
     * @param point The point to add
     */
    addPoint(point: DOMPointReadOnly | DOMPoint): void {
      if (this.pointStart == null) {
        this.setPointStart(point);
      } else if (this.pointEnd == null) {
        this.setPointEnd(point);
      } else {
        this.resetPoints();
      }
    }

    /**
     * @description Sets all points back to undefined
     */
    resetPoints(): void {
      this.pointStart = undefined;
      this.pointEnd = undefined;
    }

    /**
     * @description Gets the measurement text to display with imperial/metric conversions
     * @param ratioToShrink The ratio that we are using to scale the diameter of the circle
     * @param isImperial If we want imperial or metric conversaions
     * @returns The measurement text ("# Ft", "# M", or instructional text if missing points)
     */
    getMeasurement(ratioToShrink: number, isImperial: boolean): string {
      if (this.pointStart == null) {
        return 'No Measurement';
      }
      if (this.pointEnd == null) {
        return 'Choose an End Point';
      }

      return util.getDisplayDistanceInMM(isImperial, this.getDistance() / ratioToShrink)
      + util.getDistanceUnitsInMM(isImperial);
    }

    /**
     * @description Gets the distance between the two points
     * @returns The distance between the two points
     */
    getDistance(): number {
      const deltaX = this.pointEnd.x - this.pointStart.x;
      const deltaY = this.pointEnd.y - this.pointStart.y;
      return Math.sqrt(deltaX ** 2 + deltaY ** 2);
    }
}
