import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { InspectionState } from '@/store/inspection/types';
import { mutations } from '@/store/inspection/mutations';
import { actions } from '@/store/inspection/actions';

const state : InspectionState = {
  codingDetail: undefined,
  codingState: false,
  codingError: undefined,
  conditionDetails: undefined,
  conditionState: false,
  conditionError: undefined,
  fullCodingForm: [],
  subFullCodingForm: [],
};

const inspection: Module<InspectionState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default inspection;
