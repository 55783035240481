var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-report-card"},[_c('div',{staticClass:"header-layout"},[_c('div',{staticClass:"asset-label"},[_c('div',{staticClass:"asset-name"},[_vm._v(_vm._s(_vm.assetName))]),_c('v-btn',{staticClass:"download-button",attrs:{"text":"","icon":""},on:{"click":_vm.downloadReport}},[_c('v-icon',{staticClass:"download-icon"},[_vm._v("mdi-file-download-outline")])],1)],1),(_vm.report)?_c('v-tabs',{attrs:{"center-active":"","show-arrows":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((Object.entries(_vm.report)),function(ref){
var key = ref[0];
return _c('v-tab',{key:key,staticClass:"tab-item"},[_vm._v(" "+_vm._s(key)+" ")])}),1):_vm._e()],1),(_vm.report)?_c('v-tabs-items',{staticClass:"tab-item-contents",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((Object.entries(_vm.report)),function(ref){
var reportSectionName = ref[0];
var reportSectionValues = ref[1];
return _c('v-tab-item',{key:reportSectionName,attrs:{"transition":"no-transition"}},[_c('div',{staticClass:"tab-contents"},_vm._l((Object.entries(reportSectionValues)),function(ref){
var reportSectionField = ref[0];
var reportSectionFieldEntry = ref[1];
return _c('div',{key:reportSectionField},[(reportSectionName === 'photos')?_c('div',[_c('v-dialog',{staticClass:"dialog",attrs:{"width":"76vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"image",attrs:{"src":reportSectionFieldEntry.url},on:{"click":function($event){return _vm.expandImage(reportSectionFieldEntry.url)}}},'v-img',attrs,false),on)),_c('p',[_vm._v(_vm._s(reportSectionFieldEntry.description))])]}}],null,true),model:{value:(_vm.showExpandedImage),callback:function ($$v) {_vm.showExpandedImage=$$v},expression:"showExpandedImage"}},[_c('v-card',[_c('v-img',{staticClass:"expanded-image",attrs:{"src":_vm.expandedImageUrl},on:{"click":function($event){return _vm.closeExpandedImage()}}})],1)],1)],1):(
              typeof reportSectionFieldEntry === 'string' ||
              typeof reportSectionFieldEntry === 'number' ||
              reportSectionFieldEntry === null
            )?_c('v-text-field',{staticClass:"field",attrs:{"label":reportSectionField,"value":reportSectionFieldEntry,"readonly":"","dense":"","outlined":""}}):_c('div',_vm._l((reportSectionFieldEntry),function(value,key){return _c('v-text-field',{key:key,staticClass:"field",attrs:{"label":key,"value":value,"readonly":"","dense":"","outlined":""}})}),1)],1)}),0)])}),1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":"1000"},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" Downloading report... ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }