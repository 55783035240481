




































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SchedulingData } from '@/store/planning/types';
import {
  Component, Prop, Watch, Vue, PropSync,
} from 'vue-property-decorator';

@Component({})
export default class ScheduleWorkOrder extends Vue {
  @PropSync('valid') synchedValid: boolean;

  @PropSync('scheduleDialog') synchedScheduleDialog: boolean;

  @Prop({ default: () => [] }) editItems: Array<any>;

  periodOptions = ['Weeks', 'Months', 'Years'];

  selectedDate = '';

  endDate = '';

  onMenu = false as boolean;

  numberRules = [
    (v: string): string | boolean => (!!v && /^[0-9]*$/.test(v)) || 'Field must only include digits 0-9',
    (v: string): string | boolean => v !== '0' || 'Field must be greater than 0',
  ];

  requiredRule = [(v: string): string | boolean => !!v || 'Item is required'];

  DoesNotEndString = 'Does Not End';

  OnADateString = 'On A Date';

  AfterNOccurencesString = 'After # Occurences';

  endsModel = [this.DoesNotEndString];

  periodOfTime = '' as string;

  periodNumber = '' as string;

  occurencesNumber = '' as string;

  get currentEndMethod(): string {
    if (this.endsModel.length === 0) {
      return '';
    }
    return this.endsModel[0];
  }

  get WarningText(): string | undefined {
    if (!this.selectedDate) {
      return null;
    }
    const selectedDate = this.parseDate(this.selectedDate).getDate();
    if (this.periodOfTime === 'Months' && selectedDate > 28) {
      return `Work order will repeat on the last day of the month if there are not ${selectedDate} days in the month`;
    }
    return null;
  }

  get minEndDate(): string {
    if (this.selectedDate === '') {
      return this.today;
    }
    return this.parseDate(this.selectedDate).toISOString().slice(0, 10);
  }

  parseDate(dateString: string): Date {
    const d = new Date(dateString);
    const userTimezoneOffset = d.getTimezoneOffset() * 60000;
    return new Date(d.getTime() + userTimezoneOffset);
  }

  @Watch('endsModel')
  onEnds(): void {
    while (this.endsModel.length > 1) {
      this.endsModel.shift();
    }
  }

  get today(): string {
    return new Date().toISOString().slice(0, 10);
  }

  reset(): void {
    this.endsModel = [this.DoesNotEndString];
    this.periodOfTime = '' as string;
    this.periodNumber = '' as string;
    this.occurencesNumber = '' as string;
    this.selectedDate = '';
    this.endDate = '';
    (this.$refs.form as HTMLFormElement).resetValidation();
  }

  submitScheduling(): void {
    const schedule = this.createSchedule();
    this.$emit('saveScheduling', schedule);
    this.synchedScheduleDialog = false;
  }

  createSchedule(): SchedulingData {
    const schedulingData: SchedulingData = {
      interval: parseInt(this.periodNumber, 10),
      period: this.periodOfTime,
      startDate: new Date(this.selectedDate).toISOString().slice(0, -1),
      endType: '',
      endValue: '',
    };
    switch (this.currentEndMethod) {
      case this.OnADateString:
        schedulingData.endType = 'end_date';
        schedulingData.endValue = new Date(this.endDate).toISOString();
        break;
      case this.AfterNOccurencesString:
        schedulingData.endType = 'num_occurrences';
        schedulingData.endValue = this.occurencesNumber;
        break;
      case this.DoesNotEndString:
      default:
        schedulingData.endType = 'never';
        schedulingData.endValue = null;
    }
    return schedulingData;
  }
}
