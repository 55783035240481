






import Vue from 'vue';

export default Vue.extend({
  name: 'Compass',
  props: {
    angle: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    north: './compassNorth.png',
    south: './compassSouth.png',
    east: './compassEast.png',
    west: './compassWest.png',
    oldInnerHtml: '',
    imgContext: require.context('../../assets/', false, /\.png$/),
  }),
  mounted() {
    if ((document.getElementById('pointerhtml') as HTMLElement) !== null) {
      this.changeAngle(this.angle);
    }
  },
  watch: {
    angle: {
      immediate: false,
      handler(value:number) {
        if (!this.disabled) {
          this.changeAngle(value);
        }
      },
    },
    disabled: {
      immediate: false,
      handler(value:boolean) {
        const pointer = (document.getElementById('pointerhtml') as HTMLElement);
        if (!value) {
          if (this.oldInnerHtml !== '') {
            pointer.innerHTML = this.oldInnerHtml;
            this.changeAngle(this.angle);
          }
        } else {
          this.oldInnerHtml = pointer.innerHTML;
          pointer.innerHTML = '';
        }
      },
    },
  },
  methods: {
    changeAngle(value:number) {
      const pointer = (document.getElementById('pointer') as HTMLImageElement);
      pointer.style.transform = `rotate(${value}deg)`;

      let pointerImg;
      if (value > -45 && value < 45) {
        pointerImg = this.north;
      } else if (value > 45 && value < 135) {
        pointerImg = this.east;
      } else if (value > 135 && value < 225) {
        pointerImg = this.south;
      } else if (value > 225 && value < 315) {
        pointerImg = this.west;
      } else if (value > 315 && value < 360) {
        pointerImg = this.north;
      } else if (value < -45 && value > -135) {
        pointerImg = this.west;
      } else if (value < -135 && value > -225) {
        pointerImg = this.south;
      } else if (value < -225 && value > -315) {
        pointerImg = this.east;
      } else if (value < -315 && value > -360) {
        pointerImg = this.north;
      }

      pointer.src = this.imgContext(pointerImg);
    },
  },
});
