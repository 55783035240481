




/* eslint-disable @typescript-eslint/explicit-module-boundary-types,
@typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import {
  Chart as ChartJS,
  Title,
  LineController,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  LinearScale,
  PointElement,
  BarController,
} from 'chart.js';
import { uuid } from 'vue-uuid';

ChartJS.register(
  BarElement, BarController,
  LineController, LineElement, PointElement, LinearScale, Title, Legend, Tooltip,
);

@Component({ })
export default class VerticalBarChart extends Vue {
    @Prop({ default: false }) readonly smallGraph: boolean;

    @Prop() readonly chartData;

    @Prop() readonly title: string;

    @Prop() readonly height: number | undefined;

    count = 0;

    randomUUID = uuid.v4()

    chart: ChartJS;

    get chartClass(): string {
      return this.smallGraph === true ? 'stackedHorizontalGraphSmall' : 'stackedHorizontalGraph';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get chartOptions(): any {
      return {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'x',
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => '',
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            display: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 10,
              },
            },
          },
          y: {
            stacked: false,
            display: false,
            grid: {
              display: false,
            },
          },
        },
      };
    }

    mounted(): void {
      this.chart = new ChartJS((document.getElementById(`barlinechart${this.randomUUID}`) as HTMLCanvasElement).getContext('2d'), {
        data: this.chartData,
        options: this.chartOptions,
      });
    }

  plugins = [];
}

