
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ProjectHeaders,
  ProjectListing,
} from '@/store/project/types';

import { ProjectActions } from '@/store/project/actions';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { MetricsActions } from '@/store/metrics/actions';
import {
  ProjectOverviewMetrics,
  OverviewTotalCounts,
  WorkOrderProjectMetrics,
  AssetCountsMetrics,
  DataDeliveryProjectMetrics,
  ProjectTotalsObject,
  CrewCountsDTO,
  CrewCountsWithAverages,
} from '@/store/metrics/types';
import { MetricsMutations } from '@/store/metrics/mutations';

const metricsModule = namespace('metrics');
const projectModule = namespace('project');
const userPrefsModule = namespace('userPrefs');

@Component({
  components: {},
})
export default class PmDashboardMixin extends Vue {
  @projectModule.State('headers') projectHeaders: ProjectHeaders | undefined;

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchProjectHeaders;

  @projectModule.State('dashboardMetrics') selectedProject: ProjectListing | undefined;

  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  @metricsModule.State('dataDeliveryProjectMetrics') dataDeliveryProjectMetrics: DataDeliveryProjectMetrics;

  @metricsModule.State('workOrderProjectMetrics') workOrderProjectMetrics: WorkOrderProjectMetrics;

  @metricsModule.Action(MetricsActions.FETCH_PROJECT_OVERVIEW_METRICS) fetchProjectOverviewMetrics;

  @metricsModule.Action(MetricsActions.FETCH_OVERVIEW_TOTAL_COUNTS) fetchOverviewTotalCounts;

  @metricsModule.State('projectOverviewMetrics') projectOverviewMetrics: ProjectOverviewMetrics;

  @metricsModule.State('overviewTotalCounts') overviewTotalCounts: OverviewTotalCounts;

  @metricsModule.Action(MetricsActions.FETCH_DATA_DELIVERY_METRICS) fetchDataDeliveryMetrics;

  @metricsModule.Action(MetricsActions.FETCH_WORK_ORDER_PROJECT_METRICS)
    fetchWorkOrderProjectMetrics;

  @metricsModule.Action(MetricsActions.FETCH_ASSET_COUNTS_METRICS) fetchAssetCountsMetrics;

  @metricsModule.State('loadingAssetCountsMetrics') loadingAssetCountsMetrics: boolean;

  @metricsModule.State('assetCountsMetrics') assetCountsMetrics: AssetCountsMetrics;

  @metricsModule.State('projectTotalsData') projectTotalsList: ProjectTotalsObject[];

  @metricsModule.Action(MetricsActions.FETCH_PROJECT_TOTALS) fetchProjectTotals;

  @metricsModule.Action(MetricsActions.FETCH_CREW_COUNTS_METRICS) fetchCrewCounts;

  @metricsModule.Action(MetricsActions.FETCH_UPDATE_CREW_COUNT_METRIC) fetchSingleCrewCount;

  @metricsModule.Action(MetricsActions.FETCH_CREW_COUNTS_AVERAGES_METRICS) fetchCrewCountAverages;

  @metricsModule.Action(MetricsActions.FETCH_UPDATE_CREW_COUNT_AVERAGES_METRIC)
    fetchSingleCrewCountAverage;

  @metricsModule.Mutation(MetricsMutations.SET_CREW_COUNTS_DATA) setCrewCountsData;

  @metricsModule.Mutation(MetricsMutations.SET_CREW_COUNTS_AVERAGES_DATA)
    setCrewCountAveragesData;

  @metricsModule.State('crewCountsData') crewCountsDTOList: CrewCountsDTO[];

  @metricsModule.State('crewCountsUpdateData') updateCrewCountsDTO: CrewCountsDTO;

  @metricsModule.State('crewCountsAveragesData') crewCountsWithAverages: CrewCountsWithAverages[];

  @metricsModule.State('crewCountAveragesUpdateData') updateCrewCountAverageDTO: CrewCountsWithAverages;
}
