import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { ProjectListing, ProjectState } from './types';

const getters: GetterTree<ProjectState, RootState> = {
  getProjectString(state): string[] {
    return state.data?.names.map((name, i) => `${name} - ${state.data?.cities[i]}, ${state.data?.states[i]}`);
  },
  getAllProjects(state): ProjectListing[] {
    return state.allProjectsData;
  },
};

export default getters;
