



















































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from 'vue';
import {
  Prop, Component,
} from 'vue-property-decorator';
import { AssetData, InspectionData } from '../../store/asset/types';
import utils from '../../views/Asset/utils';
import util from '../Report/util';
import mapUtils from '../Maps/utils';

@Component
export default class PipeConditionReport extends Vue {
  @Prop({ default: 'MH-00000' }) readonly FirstManhole!: string;

  @Prop({ default: 'MH-00001' }) readonly SecondManhole!: string;

  @Prop({ default: false }) readonly observationReport!: boolean;

  @Prop({ default: 'Downstream' }) readonly flowDirection!: string;

  @Prop({ default: 0 }) readonly startingDepth!: number;

  @Prop({ default: false }) readonly stopAtLastDefect!: boolean;

  @Prop() readonly inspection!: InspectionData;

  @Prop() readonly asset!: AssetData;

  @Prop({ default: true }) readonly isPipegraph!: boolean;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop({ default: -1 }) readonly maxVideoTime!: number;

  // eslint-disable-next-line class-methods-use-this
  computeBackgroundColor(value: number): string {
    if (value % 2 === 0) {
      return 'background-color: #cccccc;';
    }
    return '';
  }

  mounted(): void {
    this.fillPipeConditionReport();
    this.setFlowDirectionGraphic();
  }

  // eslint-disable-next-line class-methods-use-this
  getWidth(): number {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth,
    );
  }

  setFlowDirectionGraphic(): void {
    // Default values will point down
    if (this.flowDirection === 'Upstream') {
      const flowDirectionSVG = (this.$refs as any).svgArrow;
      if (flowDirectionSVG != null) {
        flowDirectionSVG.style.transform = 'scale(1,-1)';
        flowDirectionSVG.style.height = '80%';
      }
    }
  }

  fillPipeConditionReport(): void {
    const svgns = 'http://www.w3.org/2000/svg';
    const svg = (this.$refs as any).svgField;

    const textSpacing = 5;
    const minLineSpace = 20;

    if (svg === null) {
      return;
    }
    const { svgposition } = this.$refs as any;
    const { svgcode } = this.$refs as any;
    const { svgobservation } = this.$refs as any;
    const { svgvideo } = this.$refs as any;
    const { svggrade } = this.$refs as any;

    const rect = document.createElementNS(svgns, 'rect');
    rect.setAttribute('x', '0.08in');
    rect.setAttribute('y', '0.2in');
    rect.setAttribute('width', '50');
    rect.setAttribute('height', '8.4in');
    rect.setAttribute('fill', 'transparent');
    rect.setAttribute('stroke', 'black');
    svg.appendChild(rect);

    const manHoleEnterText = document.createElementNS(svgns, 'text');
    manHoleEnterText.setAttribute('x', '0.075in');
    manHoleEnterText.setAttribute('y', '0.1in');
    manHoleEnterText.setAttribute('fill', '#000');
    manHoleEnterText.textContent = this.FirstManhole != null ? `${this.FirstManhole}` : '';
    manHoleEnterText.style.fontWeight = 'bold';
    manHoleEnterText.style.fontSize = '12px';
    svg.appendChild(manHoleEnterText);

    if (this.observationReport) {
      const manHoleExitText = document.createElementNS(svgns, 'text');
      manHoleExitText.setAttribute('x', '0.075in');
      manHoleExitText.setAttribute('y', '8.8in');
      manHoleExitText.setAttribute('fill', '#000');
      manHoleExitText.textContent = this.SecondManhole != null ? `${this.SecondManhole}` : '';
      manHoleExitText.style.fontWeight = 'bold';
      manHoleExitText.style.fontSize = '12px';
      svg.appendChild(manHoleExitText);
    }

    let reports = this.inspection.conditionReport;
    reports = reports.sort((a, b) => a.distance - b.distance);
    const lastReport = reports[reports.length - 1];

    // eslint-disable-next-line no-nested-ternary
    let actualMaxDepth = this.inspection.maxPayout != null
      ? (this.inspection.maxPayout < lastReport.distance
        ? lastReport.distance
        : this.inspection.maxPayout)
      : (utils.getMaxDepth(this.inspection.payoutIPD, this.maxVideoTime) < lastReport.distance
        ? lastReport.distance
        : utils.getMaxDepth(this.inspection.payoutIPD, this.maxVideoTime));

    if (this.stopAtLastDefect) actualMaxDepth = lastReport.distance;

    const depthText = document.createElementNS(svgns, 'text');
    depthText.setAttribute('x', '-.55in');
    depthText.setAttribute('y', '8.8in');
    depthText.setAttribute('fill', '#000');
    depthText.textContent = this.getDisplayDistanceFtM(actualMaxDepth);
    svg.appendChild(depthText);

    const height = parseInt(
      window.getComputedStyle(rect, null).getPropertyValue('height'),
      10,
    );

    let yPos = 15;
    const sortedArr = [...this.inspection.conditionReport].sort((a, b) => a.distance - b.distance);
    if (this.inspection.maxPayout != null
      || utils.getMaxDepth(this.inspection.payoutIPD, this.maxVideoTime) != null) {
      let dpp = (height / (actualMaxDepth - this.startingDepth));
      if (actualMaxDepth - this.startingDepth === 0) {
        dpp = 0;
      }

      sortedArr.forEach((e) => {
        const line = document.createElementNS(svgns, 'line');
        line.setAttribute('x1', '0.08in');

        let y1Val = ((dpp * Math.min(e.distance, actualMaxDepth)) + (96 * 0.2));
        if (this.startingDepth !== 0) {
          const offset = dpp * this.startingDepth;
          y1Val -= offset;
        }
        line.setAttribute('y1', y1Val.toString());

        // calculating distance in pixels from 0.08in
        const x2Val = 50 + (96 * 0.08);
        line.setAttribute('x2', x2Val.toString());
        line.setAttribute('y2', y1Val.toString());
        line.setAttribute('stroke', this.severityLookup(e.report));
        svg.appendChild(line);

        const angleLine = document.createElementNS(svgns, 'line');
        angleLine.setAttribute('x1', x2Val.toString());
        angleLine.setAttribute('y1', y1Val.toString());
        angleLine.setAttribute('x2', '100%');
        angleLine.setAttribute('y2', (yPos + textSpacing).toString());
        angleLine.setAttribute('stroke', this.severityLookup(e.report));
        svg.appendChild(angleLine);

        const tempHeight = height / (this.inspection.conditionReport.length - 1);
        yPos += tempHeight < minLineSpace ? minLineSpace : tempHeight;
      });
    }

    const underLine = document.createElementNS(svgns, 'line');
    underLine.setAttribute('x1', '0');
    underLine.setAttribute('x2', '100%');

    yPos = 15;
    sortedArr.forEach((e) => {
      underLine.setAttribute('stroke', e.severity != null ? this.severityLookup(e.report) : '#e61e25');
      underLine.setAttribute('y1', (yPos + textSpacing).toString());
      underLine.setAttribute('y2', (yPos + textSpacing).toString());
      const position = document.createElementNS(svgns, 'text');
      position.setAttribute('x', '25');
      position.setAttribute('y', yPos.toString());
      position.setAttribute('fill', '#000');
      position.textContent = e.distance != null ? this.getDisplayDistanceFtM(Math.round((e.distance + Number.EPSILON) * 10) / 10) : '';
      svgposition.appendChild(position);
      svgposition.appendChild(underLine.cloneNode(true));
      const code = document.createElementNS(svgns, 'text');
      code.setAttribute('x', '0');
      code.setAttribute('y', yPos.toString());
      code.setAttribute('fill', '#000');
      code.textContent = e.code != null ? e.code : '';
      svgcode.appendChild(code);
      svgcode.appendChild(underLine.cloneNode(true));
      const obersavation = document.createElementNS(svgns, 'text');
      obersavation.setAttribute('x', '0');
      obersavation.setAttribute('y', yPos.toString());
      obersavation.setAttribute('fill', '#000');
      obersavation.textContent = e.description != null ? e.description : '';
      svgobservation.appendChild(obersavation);
      svgobservation.appendChild(underLine.cloneNode(true));
      const time = document.createElementNS(svgns, 'text');
      time.setAttribute('x', '25');
      time.setAttribute('y', yPos.toString());
      time.setAttribute('fill', '#000');
      if (e.distance != null && this.asset != null && this.asset.payoutIPD != null) {
        time.textContent = (Math.round(
          (utils.getClosestTime(this.inspection.payoutIPD, e.distance) / 1000) * 100,
        ) / 100).toString();
      } else {
        time.textContent = '';
      }
      svgvideo.appendChild(time);
      svgvideo.appendChild(underLine.cloneNode(true));
      const grade = document.createElementNS(svgns, 'text');
      grade.setAttribute('x', '0');
      grade.setAttribute('y', yPos.toString());
      grade.setAttribute('fill', '#000');
      if (e.report['structuralGrade'] != null
        && parseInt(e.report['structuralGrade'] as string, 10) >= parseInt(e.report['omGrade'] as string, 10)) {
        grade.textContent = e.report['structuralGrade'] != null ? `S ${e.report['structuralGrade']}` : '';
      } else if (e.report['omGrade'] != null && parseInt(e.report['omGrade'] as string, 10) > parseInt(e.report['structuralGrade'] as string, 10)) {
        grade.textContent = e.report['omGrade'] != null ? `M ${e.report['omGrade']}` : '';
      } else {
        grade.textContent = 'NA';
      }
      svggrade.appendChild(grade);
      svggrade.appendChild(underLine.cloneNode(true));
      const tempHeight = height / (this.inspection.conditionReport.length - 1);
      yPos += tempHeight < minLineSpace ? minLineSpace : tempHeight;
    });

    const startingText = document.createElementNS(svgns, 'text');
    startingText.setAttribute('x', '-.55in');
    startingText.setAttribute('y', '0.1in');
    startingText.setAttribute('fill', '#000');
    startingText.textContent = this.getDisplayDistanceFtM(this.startingDepth);
    svg.appendChild(startingText);
  }

  severityLookup(report: any): string {
    if (report['structuralGrade'] == null && report['omGrade'] == null) {
      return '#0C6599';
    }

    if (report['structuralGrade'] != null && report['omGrade'] == null) {
      return mapUtils.getScoreColor(parseInt(report['structuralGrade'], 10));
    }

    if (report['structuralGrade'] == null && report['omGrade'] != null) {
      return mapUtils.getScoreColor(parseInt(report['omGrade'], 10));
    }

    const om = parseInt(report['omGrade'], 10);
    const structural = parseInt(report['structuralGrade'], 10);
    if (om > structural) {
      return mapUtils.getScoreColor(om);
    }
    return mapUtils.getScoreColor(structural);
  }

  getDisplayDistanceInMM(value: number): string {
    try {
      return value || value === 0
        ? util.getDisplayDistanceInMM(
          this.displayImperial, Math.round((value + Number.EPSILON) * 10) / 10,
        )
      + util.getDistanceUnitsInMM(this.displayImperial) : '';
    } catch {
      return '';
    }
  }

  getDisplayDistanceFtM(value: number): string {
    try {
      return value || value === 0
        ? util.getDisplayDistanceFtM(
          this.displayImperial, Math.round((value + Number.EPSILON) * 10) / 10,
        )
        + util.getDistanceUnitsFtM(this.displayImperial) : '';
    } catch {
      return '';
    }
  }
}
