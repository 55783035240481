import { MutationTree } from 'vuex';
import { LineGraphState, LineGraphData, GasTempData } from './types';

// eslint-disable-next-line no-shadow
export enum LineGraphMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_LINE_GRAPH_DATA = 'SET_LINE_GRAPH_DATA',
  SET_GAS_TEMP_DATA = 'SET_GAS_TEMP_DATA',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
}

export const mutations: MutationTree<LineGraphState> = {
  [LineGraphMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
  [LineGraphMutations.SET_LINE_GRAPH_DATA](state, payload: LineGraphData) {
    state.lineGraphData = payload;
  },
  [LineGraphMutations.SET_GAS_TEMP_DATA](state, payload: GasTempData) {
    state.gasTempData = payload;
  },
  [LineGraphMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
};
