var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-container"},[_c('div',{staticClass:"title"},[_c('a',{staticClass:"back-btn",attrs:{"tag":"button","href":_vm.$router.resolve({
          name: 'projects',
          params: { ids: _vm.projectGuids },
          }).href}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"back-btn-text",attrs:{"id":"menu-title-id"}},[_vm._v("AI Coding")])]),_c('div',{attrs:{"id":"aicoding-layout"}},[_c('div',{staticClass:"aicoding-table-layout"},[_c('div',[_c('div',{attrs:{"id":"table-header"}},[_c('v-tabs',{staticClass:"aicodingTabs",attrs:{"height":"100%","color":"#0C6599"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabOptions),function(tab){return _c('v-tab',{key:tab.name,staticClass:"aicodingTableTab py-2",attrs:{"id":tab.name}},[_vm._v(" "+_vm._s(tab.value)+" ")])}),1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-bar",attrs:{"outlined":"","dense":"","hide-details":"","clearable":"","placeholder":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.editPermissions)?_c('v-btn',{ref:"editButton",class:!_vm.isEditTable() ? 'outline-btn' : 'blue-btn',attrs:{"outlined":""},on:{"click":_vm.toggleEdit}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil-outline")]),_vm._v(" Edit ")],1):_vm._e()],1),_c('IntegrityTable',{ref:"aicodingTable",attrs:{"loading":_vm.loadingData,"tableID":'aicoding-table',"data":_vm.filteredTableData,"headers":_vm.featureHeaders,"matchFilters":_vm.matchFilters,"filterValues":_vm.filterValues,"blankMatchFilters":_vm.blankMatchFilters,"itemsPerPage":-1,"canEdit":_vm.isEditTable(),"footer-props":{
            'disable-items-per-page': false,
            'items-per-page-options':[100, 250, 500, -1],
          },"textSearchEnabled":true,"search":_vm.search},on:{"update:data":function($event){_vm.filteredTableData=$event},"update:headers":function($event){_vm.featureHeaders=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event},"update:filterValues":function($event){_vm.filterValues=$event},"update:filter-values":function($event){_vm.filterValues=$event},"update:blankMatchFilters":function($event){_vm.blankMatchFilters=$event},"update:blank-match-filters":function($event){_vm.blankMatchFilters=$event},"inlineEdit":_vm.saveWorkOrder}})],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"plain":"","small":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Dismiss ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }