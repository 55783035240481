import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { DataStandardState } from './types';

const state: DataStandardState = {
  data: undefined,
  loading: false,
  loadError: undefined,
};

const dataStandards: Module<DataStandardState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default dataStandards;
