


































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { BasicSelect } from 'vue-search-select';
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AdminActions } from '@/store/admin/actions';
import { UserRole } from '@/store/users/types';
import { ProjectListing } from '@/store/project/types';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import { UserPermission } from '@/store/userpermissions/types';
import AdminPanelMixin, { EditUserData } from '../AdminPanelMixin.vue';

const adminModule = namespace('admin');

@Component({
  components: {
    BasicSelect,
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class ProjectList extends AdminPanelMixin {
  @PropSync('user') readonly synchedSelectedUser: EditUserData;

  @Prop() readonly projects!: ProjectListing[] | undefined;

  @Prop() readonly search!: string;

  @Prop({ default: true }) readonly patchUserOnSave: boolean;

  @adminModule.Action(AdminActions.DELETE_USER_ROLE) deleteUserRoleAction;

  @adminModule.Action(AdminActions.PATCH_USER_ROLE) patchUserRoleAction;

  @adminModule.State('loading') loading;

  projectsCopy =
    this.projects == null ? ([] as ProjectListing[]) : this.projects;

  editDialogVisible = false;

  startDateMenu = false as boolean;

  endDateMenu = false as boolean;

  startDate = null as string | undefined;

  endDate = null as string;

  userRoleToEdit = {} as UserRole;

  userRoleToDelete = {} as UserRole;

  snackbarMessage = '';

  snackbarColor = '';

  snackbarVisible = false;

  allHeaders = [
    {
      text: 'Project Name',
      value: 'projectName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Project Number',
      value: 'projectNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Customer',
      value: 'projectCustomer',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Access Start Date',
      value: 'scheduledStartTime',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Access End Date',
      value: 'scheduledEndTime',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'left',
      sortable: false,
    },
  ];

  matchFilters = [
    {
      header: 'projectName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'projectNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'projectCustomer',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'scheduledStartTime',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'scheduledEndTime',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  mounted(): void {
    if (!this.customerDataList) {
      this.fetchCustomerData();
    }
  }

  @Watch('projects')
  onProjectChange(): void {
    if (this.projects != null) {
      this.projectsCopy = this.projects;
    }
  }

  get filteredRoles(): any[] {
    return this.synchedSelectedUser?.detailedUserData?.role != null
      ? this.synchedSelectedUser.detailedUserData.role.map((value) => ({
        ...value,
        ...this.getProjectInfo(value.projectGuid),
        scheduledStartTime: this.getDateString(value.startDate),
        scheduledEndTime: this.getDateString(value.endDate),
      }))
      : [];
  }

  getProjectInfo(guidToMatch: string): {
    projectName: string;
    projectNumber: string;
    projectCustomer: string;
  } {
    // eslint-disable-next-line no-nested-ternary
    if (this.projectsCopy.length > 0) {
      const foundProject = this.projectsCopy.find(
        (value) => value.guid === guidToMatch,
      );
      if (foundProject) {
        return {
          projectName: foundProject.name,
          projectNumber: foundProject.projectNumber,
          projectCustomer:
            this.customerDataList?.find(
              (cust) => cust.guid === foundProject.customerGuid,
            )?.name ?? '',
        };
      }
    }
    return {
      projectName: '',
      projectNumber: '',
      projectCustomer: '',
    };
  }

  get headers(): any[] {
    let list = this.allHeaders;
    if (!this.hasPermissionAdminDeleteUser) {
      list = list.filter((item) => item.value !== 'delete');
    }
    if (!this.hasPermissionAdminSetDateRangeForActivation) {
      list = list.filter((item) => item.value !== 'edit');
    }
    return list;
  }

  editDialog(userRoleToEdit: UserRole): void {
    this.startDate = this.getDateString(userRoleToEdit.startDate);
    this.endDate = this.getDateString(userRoleToEdit.endDate);
    this.userRoleToEdit = userRoleToEdit;
    this.editDialogVisible = true;
    this.$forceUpdate();
  }

  async deleteUserRole(userRoleToDelete: UserRole): Promise<boolean> {
    try {
      this.synchedSelectedUser.detailedUserData.role.splice(
        this.synchedSelectedUser.detailedUserData.role.findIndex(
          (value) => value === userRoleToDelete,
        ),
        1,
      );
      if (this.synchedSelectedUser.detailedUserData.role.length <= 0) {
        this.$emit(
          'deleteUser',
          this.synchedSelectedUser.detailedUserData.guid,
        );
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  async editUserRole(userRoleToEdit: UserRole): Promise<void> {
    // eslint-disable-next-line no-param-reassign
    userRoleToEdit.startDate = !this.startDate
      ? null
      : new Date(this.startDate);
    // eslint-disable-next-line no-param-reassign
    userRoleToEdit.endDate = !this.endDate ? null : new Date(this.endDate);

    try {
      if (this.patchUserOnSave) {
        await this.patchUserRoleAction(userRoleToEdit);
      }
      const userRoleIndex = this.synchedSelectedUser.detailedUserData.role.findIndex(
        (r) => r.guid === userRoleToEdit.guid,
      );
      this.synchedSelectedUser.detailedUserData.role.splice(
        userRoleIndex,
        1,
        userRoleToEdit,
      );

      this.snackbarMessage = 'User role edited';
      this.snackbarColor = 'green';
    } catch (e) {
      this.snackbarMessage = (e as Error).message;
      this.snackbarColor = '#e61e25';
    }

    this.snackbarVisible = true;
    this.editDialogVisible = false;
  }

  getDateString(date: Date): string {
    return !date ? null : new Date(date).toISOString().substr(0, 10);
  }

  /**
   * @description fill out the filter values for the table.
   * @returns filter values
   */
  get filterValues(): any {
    if (!this.filteredRoles) {
      return;
    }

    const localFilterValues = {};

    this.headers.forEach((header) => {
      if (!header.filterable) return;

      localFilterValues[header.value] = [];

      this.filteredRoles.forEach((roleData: any) => {
        let checkVal: string;

        try {
          checkVal = roleData[header.value];
        } catch {
          checkVal = '';
        }

        if (
          checkVal
          && checkVal !== ''
          && Array.isArray(localFilterValues[header.value])
          && !localFilterValues[header.value].includes(checkVal)
        ) {
          localFilterValues[header.value].push(checkVal);
        }
      });
      localFilterValues[header.value].sort();
    });
    // eslint-disable-next-line consistent-return
    return localFilterValues;
  }

  /**
   * @returns true if the user has the permission ADMIN_DELETEUSER
   */
  get hasPermissionAdminDeleteUser(): boolean {
    return this.hasPermission(UserPermission.ADMIN_DELETEUSER);
  }

  /**
   * @returns true if the user has the permission ADMIN_SETDATERANGEFORACTIVATION
   */
  get hasPermissionAdminSetDateRangeForActivation(): boolean {
    return this.hasPermission(UserPermission.ADMIN_SETDATERANGEFORACTIVATION);
  }
}
