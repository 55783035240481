












































import {
  Component, VModel, Vue, Watch,
} from 'vue-property-decorator';
import { PhoneInfo } from './types';

@Component({})
export default class IntegrityPhoneNumberInput extends Vue {
  @VModel() modelValue!: PhoneInfo | undefined;

  mounted(): void {
    if (this.modelValue) {
      this.setLocalValues();
    }
  }

  /**
   * @description Sets all local values from the modelValue.
   */
  setLocalValues(): void {
    this.phoneNumber = this.modelValue.phone;
    this.newPhoneCode = this.modelValue.phoneCode;
    this.newPhoneRegion = this.phoneRegions[+this.modelValue.isInternational];
  }

  phoneNumber = '';

  newPhoneCode = '+1';

  newPhoneRegion = {
    name: 'United States',
    placeholder: '(123) 456-7890',
    rules: [
      (v: string): string | boolean | number => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v)
        || 'Phone number not valid',
    ],
  };

  phoneRegions = [
    {
      name: 'United States',
      placeholder: '(123) 456-7890',
      rules: [
        (v: string): string | boolean | number => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v)
          || 'Phone number not valid',
      ],
    },
    {
      name: 'International',
      placeholder: '123 456 7890',
      rules: [
        (v: string): string | boolean | number => /^(\+[1-9]{1,2}\s)?(\d{3}\s){2}\d{4}(\s\d{4})?$/.test(v)
          || 'Phone number not valid',
      ],
    },
  ];

  @Watch('phoneNumber')
  onPhoneNumberChange(): void {
    this.formatPhone();
  }

  @Watch('newPhoneCode')
  onNewPhoneRegionCodeChange(): void {
    this.formatRegionCode();
    this.formatPhone();
  }

  @Watch('newPhoneRegion')
  onNewPhoneRegionChange(): void {
    this.formatRegionCode();
    this.formatPhone();
  }

  @Watch('modelValue')
  onModelValueChange(): void {
    if (!this.modelValue) {
      this.resetPhone();
    } else {
      this.setLocalValues();
    }
  }

  /**
   * @description Reset all fields in the form.
   */
  resetPhone(): void {
    [this.newPhoneRegion] = this.phoneRegions;
    this.newPhoneCode = '+1';
    this.phoneNumber = '';
    this.formatPhone();
  }

  /**
   * @description Format the region code.
   */
  formatRegionCode(): void {
    if (this.newPhoneRegion.name === 'United States') {
      this.newPhoneCode = '+1';
    } else if (!this.newPhoneCode.includes('+')) {
      this.newPhoneCode = `+${this.newPhoneCode}`;
    }
  }

  /**
   * @description Format the phone number and modelValue.
   */
  formatPhone(): void {
    this.phoneNumber = this.formatPhoneNumber();
    this.modelValue = {
      phone: this.phoneNumber,
      phoneCode: this.newPhoneCode,
      isInternational: this.newPhoneRegion.name !== 'United States',
    };
  }

  /**
   * @description Format the phone number to include multiple styles of inputs.
   * @returns {string} formatted phone number
   */
  formatPhoneNumber(): string {
    if (this.newPhoneRegion.name === 'United States') {
      const x = this.phoneNumber
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
    }

    if (this.newPhoneRegion.name === 'International') {
      const x = this.phoneNumber
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/);
      // eslint-disable-next-line no-nested-ternary
      return !x[2]
        ? x[1]
        : x[4]
          ? `${x[1]} ${x[2]} ${x[3]} ${x[4]}`
          : `${x[1]} ${x[2]} ${x[3]}`;
    }

    return '';
  }
}
