/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { RootState } from '../types';
import { AssignmentState } from './types';
import { AssignmentMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum AssignmentActions {
  POST_ASSIGNMENTS = 'POST_ASSIGNMENTS',
  GET_READY_FOR_CODING_ASSIGNMENTS = 'GET_READY_FOR_CODING_ASSIGNMENTS',
  GET_AICODING_WORKORDERS = 'GET_AICODING_WORKORDERS',
  PATCH_AICODING_WORKORDERS = 'PATCH_AICODING_WORKORDERS',
  POST_ROUTE = 'POST_ROUTE',
  PATCH_ROUTE = 'PATCH_ROUTE',
  POST_ROUTE_ASSIGNMENTS = 'POST_ROUTE_ASSIGNMENTS',
  POST_FOLLOW_UP_WORK_ORDER = 'POST_FOLLOW_UP_WORK_ORDER',
}

export const actions: ActionTree<AssignmentState, RootState> = {
  async [AssignmentActions.POST_ASSIGNMENTS]({ commit }, payload): Promise<void> {
    commit(AssignmentMutations.SET_LOADING_STATE, true);
    commit(AssignmentMutations.SET_POST_ASSIGNMENT_DATA, undefined);
    commit(AssignmentMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      commit(AssignmentMutations.SET_POST_ASSIGNMENT_DATA,
        (await api.postAssignments(payload)).data);
    } catch (e) {
      let message = (e as any)?.data?.Message;
      if (message == null) {
        message = 'Unable to add work orders to requested assets.';
      }
      commit(AssignmentMutations.SET_LOAD_ERROR, message);
    } finally {
      commit(AssignmentMutations.SET_LOADING_STATE, false);
    }
  },
  async [AssignmentActions.GET_READY_FOR_CODING_ASSIGNMENTS]({ commit }): Promise<void> {
    commit(AssignmentMutations.SET_LOADING_STATE, true);
    commit(AssignmentMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const assignments = await api.getReadyForCodingAssignments();

      commit(AssignmentMutations.SET_READY_FOR_CODING_ASSIGNMENT_DATA, assignments.data);
    } catch (e) {
      commit(AssignmentMutations.SET_LOAD_ERROR, (e as Error).message);
    } finally {
      commit(AssignmentMutations.SET_LOADING_STATE, false);
    }
  },
  async [AssignmentActions.GET_AICODING_WORKORDERS]({ commit }, payload): Promise<void> {
    commit(AssignmentMutations.SET_GET_AICODING_WORKORDER_LOADING, true);
    commit(AssignmentMutations.SET_GET_AICODING_WORKORDER_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const assignments = await api.getAICodingWorkOrderByProject(payload);

      commit(AssignmentMutations.SET_GET_AICODING_WORKORDER_DATA, assignments.data);
    } catch (e) {
      commit(AssignmentMutations.SET_GET_AICODING_WORKORDER_ERROR, (e as Error).message);
    } finally {
      commit(AssignmentMutations.SET_GET_AICODING_WORKORDER_LOADING, false);
    }
  },
  async [AssignmentActions.PATCH_AICODING_WORKORDERS]({ commit }, payload): Promise<void> {
    commit(AssignmentMutations.SET_PATCH_AICODING_WORKORDER_LOADING, true);
    commit(AssignmentMutations.SET_PATCH_AICODING_WORKORDER_ERROR, undefined);
    commit(AssignmentMutations.SET_PATCH_AICODING_WORKORDER_DATA, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const result = await api.patchAICodingWorkOrderByProject(payload);

      commit(AssignmentMutations.SET_PATCH_AICODING_WORKORDER_DATA, result.data);
    } catch (e) {
      commit(AssignmentMutations.SET_PATCH_AICODING_WORKORDER_ERROR, (e as Error).message);
    } finally {
      commit(AssignmentMutations.SET_PATCH_AICODING_WORKORDER_LOADING, false);
    }
  },
  async [AssignmentActions.POST_ROUTE]({ commit }, payload): Promise<void> {
    commit(AssignmentMutations.SET_ROUTE_LOADING_STATE, true);
    commit(AssignmentMutations.SET_ROUTE_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postRoute(payload);
    } catch (e: any) {
      if (storeUtility.isDuplicateDbEntry(e)) {
        commit(AssignmentMutations.SET_ROUTE_LOAD_ERROR, 'DuplicateDbEntry');
      } else {
        commit(AssignmentMutations.SET_ROUTE_LOAD_ERROR, e?.response?.data?.errorType);
      }
    } finally {
      commit(AssignmentMutations.SET_ROUTE_LOADING_STATE, false);
    }
  },
  async [AssignmentActions.PATCH_ROUTE]({ commit }, payload): Promise<void> {
    commit(AssignmentMutations.SET_ROUTE_LOADING_STATE, true);
    commit(AssignmentMutations.SET_ROUTE_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.patchRoute(payload);
    } catch (e) {
      commit(AssignmentMutations.SET_ROUTE_LOAD_ERROR, (e as any).data.Message);
    } finally {
      commit(AssignmentMutations.SET_ROUTE_LOADING_STATE, false);
    }
  },
  async [AssignmentActions.POST_ROUTE_ASSIGNMENTS]({ commit }, payload): Promise<void> {
    commit(AssignmentMutations.SET_LOADING_STATE, true);
    commit(AssignmentMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      commit(AssignmentMutations.SET_POST_ASSIGNMENT_DATA,
        (await api.postRouteAssignments(payload)).data);
    } catch (e) {
      let message = (e as any)?.data?.Message;
      if (message == null) {
        message = 'Unable to add work orders to requested assets.';
      }
      commit(AssignmentMutations.SET_LOAD_ERROR, message);
    } finally {
      commit(AssignmentMutations.SET_LOADING_STATE, false);
    }
  },
  async [AssignmentActions.POST_FOLLOW_UP_WORK_ORDER]({ commit }, payload): Promise<void> {
    commit(AssignmentMutations.SET_FOLLOW_UP_WORK_ORDER_LOADING_STATE, true);
    commit(AssignmentMutations.SET_FOLLOW_UP_WORK_ORDER_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const result = await api.postFollowUpWorkOrder(
        payload.parentWorkOrderGuid, payload.taskTypeGuid,
      );

      commit(AssignmentMutations.SET_POST_FOLLOW_UP_WORK_ORDER_ASSIGNMENT_DATA, result.data);
    } catch (e) {
      commit(AssignmentMutations.SET_FOLLOW_UP_WORK_ORDER_LOAD_ERROR, (e as any).data.Message);
    } finally {
      commit(AssignmentMutations.SET_FOLLOW_UP_WORK_ORDER_LOADING_STATE, false);
    }
  },
};
