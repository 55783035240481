











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  ChartData,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
);

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Roboto';

@Component({ components: { LineChartGenerator } })
export default class LineChart extends Vue {
  @Prop({ default: 'label' }) readonly datasetIdKey: string;

  @Prop() readonly chartData: ChartData | undefined;

  @Prop({ default: undefined }) readonly maxYValue: number | undefined;

  @Prop({ default: undefined }) readonly secondaryMaxYValue: number | undefined;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  chartId = 'lineChart';

  chartClass = 'line-chart';

  plugins = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get chartOptions(): any {
    return {
      onClick: (e) => {
        this.$emit('handleClick', e);
      },
      pointRadius: 0,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            filter: (label) => label.text !== 'Video Position',
          },
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              let label = '';
              if (context[0].parsed.x !== null) {
                label += (`${context[0].parsed.x} ${this.xUnit}`);
              }
              return label;
            },
            label: (context) => {
              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += (`${context.parsed.y} ${this.getYUnit(context.dataset.label)}`);
              }
              return label;
            },
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: this.maxYValue,
          position: 'left',
        },
        y1: {
          display: !!this.secondaryMaxYValue,
          max: this.secondaryMaxYValue,
          position: 'right',
        },
        x: {
          type: 'linear',
          title: {
            display: true,
            text: `Distance (${this.xUnit})`,
          },
          ticks: {
            callback(value: string): number {
              return parseInt(value, 10);
            },
            autoSkip: true,
            stepSize: 10,
          },
        },
      },
      // animation: false,
      spanGaps: true,

    };
  }

  get xUnit(): string {
    return this.displayImperial ? 'ft' : 'm';
  }

  getYUnit(yLabel: string): string {
    switch (yLabel) {
      case 'Ovality':
        return '%';
      case 'Gas':
        return 'ppm';
      case 'Temperature':
        return this.displayImperial ? 'F' : 'C';
      default:
        return this.displayImperial ? 'in' : 'mm';
    }
  }
}

