


































































import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectListing } from '../../store/project/types';
import { ProjectActions } from '../../store/project/actions';
import User from '../../auth/User';

const projectModule = namespace('project');

@Component
export default class UserMenu extends Vue {
  @Prop(User) readonly user: User | undefined;

  @Prop(Boolean) readonly isAuthenticated: boolean | undefined;

  @Prop(Error) readonly error: Error | undefined;

  @Prop(Boolean) readonly isLoading: boolean | undefined;

  @Prop(Boolean) readonly dense: boolean | undefined;

  @Prop(Boolean) readonly hasIssuesGettingUser: boolean;

  @projectModule.Action(ProjectActions.FETCH_ALL_PROJECTS_DATA) fetchAllProjectsData;

  @projectModule.State('allProjectsData') allProjects: ProjectListing[] | undefined;

  @Watch('user')
  onUserChange(): void{
    if (this.user == null || this.user.id == null) {
      return;
    }

    if (this.allProjects === undefined) {
      this.fetchAllProjectsData().catch(() => {
        this.$router.push({
          name: 'Error',
          params: { catchAll: 'Error', message: 'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.' },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        }).catch((e) => {});
      });
    }
  }

  @Watch('hasIssuesGettingUser')
  onIssuesWithUserChange(): void {
    if (this.hasIssuesGettingUser) {
      this.$router.push({
        name: 'Error',
        params: { catchAll: 'Error', message: 'There was an error during authentication. Please try again later. If this issue persists, please contact support.' },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch((e) => {});
    }
  }

  get multiProjects(): boolean {
    if (this.allProjects != null && this.allProjects.length > 1) {
      return true;
    }
    return false;
  }

  get userPicture(): string {
    if (this.user?.picture == null) {
      return './favicon-96x96.png';
    }
    return this.user.picture;
  }

  get userName(): string {
    if (this.user?.name == null) {
      return 'Unknown User';
    }
    return this.user.name;
  }

  get userEmail(): string {
    if (this.user?.email == null) {
      return 'No Email Found';
    }
    return this.user.email;
  }

  login(): void {
    this.$emit('onLogin');
  }

  logout(): void {
    this.$emit('onLogout');
  }

  profile(): void {
    this.$emit('onProfile');
  }
}
