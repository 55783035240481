import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { ReportState } from './types';

const state: ReportState = {
  data: undefined,
  loading: false,
  loadError: undefined,
};

const cannedPdf: Module<ReportState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default cannedPdf;
