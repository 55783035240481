























/* eslint-disable @typescript-eslint/no-explicit-any */
import { NasscoCodeCategoryCounts } from '@/store/metrics/types';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import DefectCountTable from './DefectCountTable.vue';

export type TableData = {
  name: string;
  value: string;
  defectCount: number;
  assetCount: number;
  length: number;
};

@Component({
  components: {
    DefectCountTable,
  },
})
export default class DefectCountTableContainer extends Vue {
  @Prop() readonly projectDefectsData: NasscoCodeCategoryCounts;

  @Prop() readonly type: string;

  @PropSync('selectedGroup') synchedSelectedGroup: string;

  tabs = ['O&M', 'Structural'];

  selectedTab = 0;
}
