

















import { Component, Prop, PropSync } from 'vue-property-decorator';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { SelectManagedUsersData } from '@/store/users/types';
import { canUserManageRole, getRoleGuidString } from '@/auth/roles';
import AdminPanelMixin from '../AdminPanelMixin.vue';

@Component({
  components: {
    IntegrityTable,
  },
})
export default class SelectManagedUsers extends AdminPanelMixin {
  @PropSync('selectedManagedUsers')
  synchedSelectedManagedUsers: SelectManagedUsersData[];

  @Prop() newUserRole: string;

  @Prop() newUserCustomerGuid: string;

  get managedUserData(): SelectManagedUsersData[] {
    if (!this.allUsers) {
      return [];
    }
    return this.allUsers
      .map((user) => ({
        user: `${user.firstname} ${user.lastname}`.trim(),
        userGuid: user.guid,
        userRole: user.role.length > 0
          ? getRoleGuidString(user.role[0].roleGuid)
          : '',
        customers: user.customerGuids,
      }))
      .filter(
        (user) => canUserManageRole(this.newUserRole, user.userRole)
          && user.customers.includes(this.newUserCustomerGuid),
      );
  }

  getCustomerNames(customerGuids?: string[]): string[] | undefined {
    if (!customerGuids || !this.customerDataList) {
      return [];
    }
    return customerGuids
      .map(
        (customerGuid) => this.customerDataList.find((cd) => cd.guid === customerGuid)?.name,
      )
      .filter((x) => x);
  }

  headers = [
    {
      text: 'User',
      value: 'user',
      sortable: true,
      filterable: true,
    },
    {
      text: 'User Role',
      value: 'userRole',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Customer(s)',
      value: 'customers',
      sortable: true,
      filterable: true,
      conversionFunction: this.getCustomers,
    },
  ];

  matchFilters = [
    {
      header: 'user',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'userRole',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'customers',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  /**
   * @description fill out the filter values for the table.
   * @returns filter values
   */
  get filterValues(): any {
    if (!this.managedUserData) {
      return;
    }

    const localFilterValues = {};

    this.headers.forEach((header) => {
      if (!header.filterable) return;

      localFilterValues[header.value] = [];

      this.managedUserData.forEach((userData: SelectManagedUsersData) => {
        let checkVal: string;

        try {
          checkVal = userData[header.value];
        } catch {
          checkVal = '';
        }
        if (header.value === 'customers' && userData.customers) {
          userData.customers.forEach((element) => {
            if (
              element
              && element !== ''
              && Array.isArray(localFilterValues[header.value])
              && !localFilterValues[header.value].includes(element)
            ) {
              localFilterValues[header.value].push(element);
            }
          });
        } else if (
          checkVal
          && checkVal !== ''
          && Array.isArray(localFilterValues[header.value])
          && !localFilterValues[header.value].includes(checkVal)
        ) {
          localFilterValues[header.value].push(checkVal);
        }
      });
      localFilterValues[header.value].sort();
    });
    // eslint-disable-next-line consistent-return
    return localFilterValues;
  }

  getCustomers(item: SelectManagedUsersData): any {
    return item.customers;
  }
}
