import { MutationTree } from 'vuex';
import { AICodingWorkOrder, AssignmentState, ReadyForCodingAssignmentData } from './types';

// eslint-disable-next-line no-shadow
export enum AssignmentMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
  SET_POST_ASSIGNMENT_DATA = 'SET_POST_ASSIGNMENT_DATA',
  SET_READY_FOR_CODING_ASSIGNMENT_DATA = 'SET_READY_FOR_CODING_ASSIGNMENT_DATA',
  SET_GET_AICODING_WORKORDER_LOADING = 'SET_GET_AICODING_WORKORDER_LOADING',
  SET_GET_AICODING_WORKORDER_ERROR = 'SET_GET_AICODING_WORKORDER_ERROR',
  SET_GET_AICODING_WORKORDER_DATA = 'SET_GET_AICODING_WORKORDER_DATA',
  SET_PATCH_AICODING_WORKORDER_LOADING = 'SET_PATCH_AICODING_WORKORDER_LOADING',
  SET_PATCH_AICODING_WORKORDER_ERROR = 'SET_PATCH_AICODING_WORKORDER_ERROR',
  SET_PATCH_AICODING_WORKORDER_DATA = 'SET_PATCH_AICODING_WORKORDER_DATA',
  SET_ROUTE_LOADING_STATE = 'SET_ROUTE_LOADING_STATE',
  SET_ROUTE_LOAD_ERROR = 'SET_ROUTE_LOAD_ERROR',
  SET_FOLLOW_UP_WORK_ORDER_LOADING_STATE = 'SET_FOLLOW_UP_WORK_ORDER_LOADING_STATE',
  SET_FOLLOW_UP_WORK_ORDER_LOAD_ERROR = 'SET_FOLLOW_UP_WORK_ORDER_LOAD_ERROR',
  SET_POST_FOLLOW_UP_WORK_ORDER_ASSIGNMENT_DATA = 'SET_POST_FOLLOW_UP_WORK_ORDER_ASSIGNMENT_DATA',
}

export const mutations: MutationTree<AssignmentState> = {
  [AssignmentMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },

  [AssignmentMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },

  [AssignmentMutations.SET_POST_ASSIGNMENT_DATA](state, payload: string) {
    state.postAssignmentData = payload;
  },

  [AssignmentMutations.SET_READY_FOR_CODING_ASSIGNMENT_DATA](state,
    payload: ReadyForCodingAssignmentData[]) {
    state.readyForCodingAssignmentData = payload;
  },
  [AssignmentMutations.SET_GET_AICODING_WORKORDER_LOADING](state, payload: boolean) {
    state.getAICodingWorkOrderLoading = payload;
  },

  [AssignmentMutations.SET_GET_AICODING_WORKORDER_ERROR](state, payload: string) {
    state.getAICodingWorkOrderError = payload;
  },

  [AssignmentMutations.SET_GET_AICODING_WORKORDER_DATA](state,
    payload: AICodingWorkOrder[]) {
    state.getAICodingWorkOrderData = payload;
  },

  [AssignmentMutations.SET_PATCH_AICODING_WORKORDER_LOADING](state, payload: boolean) {
    state.patchAICodingWorkOrderLoading = payload;
  },

  [AssignmentMutations.SET_PATCH_AICODING_WORKORDER_ERROR](state, payload: string) {
    state.patchAICodingWorkOrderError = payload;
  },

  [AssignmentMutations.SET_PATCH_AICODING_WORKORDER_DATA](state,
    payload: string) {
    state.patchAICodingWorkOrderData = payload;
  },
  [AssignmentMutations.SET_ROUTE_LOADING_STATE](state, payload: boolean) {
    state.routeLoading = payload;
  },

  [AssignmentMutations.SET_ROUTE_LOAD_ERROR](state, payload: string) {
    state.routeError = payload;
  },

  [AssignmentMutations.SET_FOLLOW_UP_WORK_ORDER_LOADING_STATE](state, payload: boolean) {
    state.followUpLoading = payload;
  },

  [AssignmentMutations.SET_FOLLOW_UP_WORK_ORDER_LOAD_ERROR](state, payload: string) {
    state.followUpError = payload;
  },

  [AssignmentMutations.SET_POST_FOLLOW_UP_WORK_ORDER_ASSIGNMENT_DATA](state, payload: string) {
    state.followUpWorkOrder = payload;
  },
};
