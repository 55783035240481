




























/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs/legacy';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartData,
  ChartDataset,
} from 'chart.js';
import {
  CrewDataDeliveryProjectMetrics,
} from '@/store/metrics/types';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Roboto';

@Component({ components: { Bar } })
export default class DataDeliveryChartCompact extends Vue {
  @Prop({ default: 'label' }) readonly datasetIdKey: string;

  @Prop() readonly platformName: string;

  @Prop() readonly dataDeliveryProjectMetrics:
    | CrewDataDeliveryProjectMetrics
    | undefined;

  chartId = 'compactDataDeliveryChart';

  chartClass = 'compact-data-delivery-chart';

  isShowingIssues = false;

  LABELS = [''];

  plugins = [];

  get chartOptions(): any {
    return {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      barThickness: 15,
      scales: {
        x: {
          stacked: true,
          display: false,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          display: false,
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: () => (''),
          },
        },
      },
    };
  }

  get chartData(): ChartData | undefined {
    const platformData = this.dataDeliveryProjectMetrics?.platformCounts?.find(
      (value) => value.platformName === this.platformName,
    );
    const arrived = platformData?.arrived;
    const pending = platformData?.pending;
    const late = platformData?.late;
    const datasets: ChartDataset[] = [
      {
        label: 'Arrived',
        data: [arrived != null ? arrived : 0],
        backgroundColor: '#0C6599',
      },
      {
        label: 'Pending',
        data: [pending != null ? pending : 0],
        backgroundColor: '#CCCCCC',
      },
      {
        label: 'Late',
        data: [late != null ? late : 0],
        backgroundColor: '#0C6599',
      },
    ];

    return { labels: this.LABELS, datasets };
  }
}
