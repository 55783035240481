var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"retain-focus":false,"max-width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"class-button",attrs:{"color":"#0c6599","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Project ")],1)]}}]),model:{value:(_vm.addProjectDialog),callback:function ($$v) {_vm.addProjectDialog=$$v},expression:"addProjectDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Project")]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveEditUser($event)}},model:{value:(_vm.saveEditUserValid),callback:function ($$v) {_vm.saveEditUserValid=$$v},expression:"saveEditUserValid"}},[_c('div',[_c('div',{staticClass:"textbox-header"},[_vm._v("Project")]),_c('v-select',{attrs:{"items":_vm.filteredProjects,"label":"Project Name","dense":"","outlined":"","append-icon":"mdi-chevron-down","placeholder":"","rules":_vm.requiredField},model:{value:(_vm.selectedProjectName),callback:function ($$v) {_vm.selectedProjectName=$$v},expression:"selectedProjectName"}}),_c('div',{staticClass:"textbox-header"},[_vm._v("Access Start Date")]),(_vm.hasPermissionAdminSetDateRangeForActivation)?_c('v-menu',{ref:"startDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.startDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.startDate=$event},"update:return-value":function($event){_vm.startDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Start Date","readonly":"","dense":"","outlined":"","placeholder":" ","append-icon":"mdi-calendar-blank-outline"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}],null,false,999840234),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"#0c6599"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-btn',{attrs:{"outlined":"","color":"#0c6599"},on:{"click":function($event){_vm.startDateMenu = false}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"integrity-blue-button",on:{"click":function($event){return _vm.$refs.startDateMenu.save(_vm.startDate)}}},[_vm._v("Save")])],1)],1):_vm._e(),_c('div',{staticClass:"textbox-header"},[_vm._v("Access End Date")]),(_vm.hasPermissionAdminSetDateRangeForActivation)?_c('v-menu',{ref:"endDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.endDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.endDate=$event},"update:return-value":function($event){_vm.endDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"End Date","readonly":"","dense":"","outlined":"","placeholder":" ","append-icon":"mdi-calendar-blank-outline"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}],null,false,144907690),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"#0c6599"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-btn',{attrs:{"outlined":"","color":"#0c6599"},on:{"click":function($event){_vm.endDateMenu = false}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"integrity-blue-button",on:{"click":function($event){return _vm.$refs.endDateMenu.save(_vm.endDate)}}},[_vm._v("Save")])],1)],1):_vm._e()],1)])],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","color":"#0c6599"},on:{"click":function($event){_vm.addProjectDialog = false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{staticClass:"integrity-blue-button",attrs:{"disabled":!_vm.saveEditUserValid,"id":"AddButton","dark":_vm.saveEditUserValid},on:{"click":function($event){return _vm.saveEditUser()}}},[_vm._v("Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }