/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from 'vuex';
import { EMPTY_GUID } from '@/common/Constants';
import { RootState } from '../types';
import { MPSMutations } from './mutations';
import { MPSState } from './types';
import { iMPS } from '../../views/MasterProjectSummary/iMPS';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum MPSActions {
    POST_INSERT_MPS = 'POST_INSERT_MPS',
    GET_MACP_DATA_STANDARDS = 'GET_MACP_DATA_STANDARDS',
    GET_PACP_DATA_STANDARDS = 'GET_PACP_DATA_STANDARDS',
    FETCH_MPS_DATA = 'FETCH_MPS_DATA',
    PATCH_MPS = 'PATCH_MPS',
}

export const actions: ActionTree<MPSState, RootState> = {

  async [MPSActions.POST_INSERT_MPS]({ commit }, mpsData: iMPS):
  Promise<string> {
    try {
      commit(MPSMutations.SET_LOADING_STATE, true);
      commit(MPSMutations.SET_LOAD_ERROR, undefined);

      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postInsertMPS(mpsData);
      return response.data;
    } catch (e) {
      commit(MPSMutations.SET_LOAD_ERROR, (e as any).data.Message);
      throw (e as any).data.Message;
    } finally {
      commit(MPSMutations.SET_LOADING_STATE, false);
    }
  },
  async [MPSActions.FETCH_MPS_DATA]({ commit }, guid): Promise<void> {
    try {
      commit(MPSMutations.SET_MPS_DATA, undefined);
      commit(MPSMutations.SET_LOADING_STATE, true);
      commit(MPSMutations.SET_LOAD_ERROR, undefined);

      const api = await storeUtility.useIntegrityAPI();
      const retData = await api.getMPS(guid);

      commit(MPSMutations.SET_MPS_DATA, retData.data);
    } catch (e) {
      commit(MPSMutations.SET_LOAD_ERROR, (e as any).data.Message);
      throw (e as any).data.Message;
    } finally {
      commit(MPSMutations.SET_LOADING_STATE, false);
    }
  },
  async [MPSActions.PATCH_MPS]({ commit }, mpsData: iMPS):
  Promise<void> {
    try {
      commit(MPSMutations.SET_LOADING_STATE, true);
      commit(MPSMutations.SET_LOAD_ERROR, undefined);

      const api = await storeUtility.useIntegrityAPI();
      await api.patchMPS(mpsData);
    } catch (e) {
      commit(MPSMutations.SET_LOAD_ERROR, (e as any).data.Message);
      throw (e as any).data.Message;
    } finally {
      commit(MPSMutations.SET_LOADING_STATE, false);
    }
  },

  async [MPSActions.GET_MACP_DATA_STANDARDS]({ commit }, macpGuid: string | null):
  Promise<void> {
    commit(MPSMutations.SET_LOADING_STATE, true);
    commit(MPSMutations.SET_LOAD_ERROR, undefined);
    try {
      if (macpGuid !== null && macpGuid !== '' && macpGuid !== EMPTY_GUID) {
        const api = await storeUtility.useIntegrityAPI();
        const response = await api.getDataStandard(macpGuid);
        commit(MPSMutations.SET_MACP_DATA_STANDARDS, response.data);
      }
    } catch (e) {
      commit(MPSMutations.SET_LOAD_ERROR, (e as any).data.Message);
      throw (e as any).data.Message;
    } finally {
      commit(MPSMutations.SET_LOADING_STATE, false);
    }
  },

  async [MPSActions.GET_PACP_DATA_STANDARDS]({ commit }, pacpGuid: string | null):
  Promise<void> {
    commit(MPSMutations.SET_LOADING_STATE, true);
    commit(MPSMutations.SET_LOAD_ERROR, undefined);
    try {
      if (pacpGuid !== null && pacpGuid !== '' && pacpGuid !== EMPTY_GUID) {
        const api = await storeUtility.useIntegrityAPI();
        const response = await api.getDataStandard(pacpGuid);
        commit(MPSMutations.SET_PACP_DATA_STANDARDS, response.data);
      }
    } catch (e) {
      commit(MPSMutations.SET_LOAD_ERROR, (e as any).data.Message);
      throw (e as any).data.Message;
    } finally {
      commit(MPSMutations.SET_LOADING_STATE, false);
    }
  },
};
