




























































import { Component, Prop, PropSync } from 'vue-property-decorator';
import { DetailedUserData } from '@/store/users/types';
import { PhoneInfo } from '@/components/IntegrityPhoneNumberInput/types';
import { OrganizationData } from '@/store/organizations/types';
import NewUserPopup from '../NewUserPopup.vue';
import IntegrityPhoneNumberInput from '../../IntegrityPhoneNumberInput/IntegrityPhoneNumberInput.vue';
import AdminPanelMixin from '../AdminPanelMixin.vue';

@Component({
  components: {
    NewUserPopup,
    IntegrityPhoneNumberInput,
  },
})
export default class OrganizationEditDialog extends AdminPanelMixin {
  @PropSync('show') showDialog: boolean;

  @Prop() saveOrganizationData;

  @Prop({ default: false }) organizationLoading: boolean;

  @Prop() title: string;

  @Prop({
    default: () => ({
      organizationName: '',
      address: '',
      systemOwner: null,
      email: '',
      phoneNumber: null,
    }),
  })
  editItem: {
    guid: string | undefined;
    organizationName: string;
    address: string;
    systemOwner: DetailedUserData | undefined;
    email: string;
    phoneNumber: PhoneInfo | undefined;
  };

  valid = false as boolean;

  /**
   * @description Calls endpoint to post or patch an organization and fetches new organization data.
   */
  async saveOrganization(): Promise<void> {
    const phoneCode = this.editItem.phoneNumber.isInternational
      ? `${this.editItem.phoneNumber.phoneCode} `
      : '';

    const organizationSaveObject: OrganizationData = {
      guid: this.editItem.guid,
      name: this.editItem.organizationName,
      address: this.editItem.address,
      systemOwnerGuid: this.editItem.systemOwner.guid,
      email: this.editItem.email,
      phoneNumber: `${phoneCode}${this.editItem.phoneNumber.phone}`,
    };

    await this.saveOrganizationData(organizationSaveObject);
    await this.fetchOrganizationData();
    this.$emit('organizationSaved', organizationSaveObject);
    this.showDialog = false;
  }
}
