import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { DetailedUserData, UserData, UserState } from './types';

const getters: GetterTree<UserState, RootState> = {
  getAllUsers(state): UserData[] {
    return state.allUserData;
  },
  getAllDetailedUsers(state): DetailedUserData[] {
    return state.allDetailedUserData;
  },
};

export default getters;
