import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { ExportState } from '@/store/reportExports/types';
import getters from '@/store/reportExports/getters';
import { mutations } from '@/store/reportExports/mutations';
import { actions } from '@/store/reportExports/actions';

const state : ExportState = {
  loading: false,
  loadError: undefined,
  data: undefined,
};

const reportExports: Module<ExportState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default reportExports;
