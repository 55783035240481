






















































import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { AssignmentActions } from '@/store/assignments/actions';
import { ReadyForCodingAssignmentData } from '@/store/assignments/types';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const assignmentsModule = namespace('assignments');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class Reporting extends UserPermissionsMixin {
  @assignmentsModule.Action(AssignmentActions.GET_READY_FOR_CODING_ASSIGNMENTS) getRFCAssignments;

  @assignmentsModule.State('readyForCodingAssignmentData') readyForCodingAssignmentData: ReadyForCodingAssignmentData[];

  search = '';

  headers = [
    {
      text: 'Asset Name',
      value: 'assetName',
      sortable: true,
      filterable: true,
      class: 'sticky',
      cellClass: 'sticky',
    },
    {
      text: '',
      align: 'center',
      sortable: false,
      filterable: false,
      value: 'guid',
      class: 'sticky',
      cellClass: 'sticky',
    },
    {
      text: 'Asset Type',
      value: 'assetType',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Project Number',
      value: 'projectNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Project Name',
      value: 'projectName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Task',
      value: 'workOrderTask',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Inspection Date',
      value: 'inspectionDate',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Feedback',
      value: 'feedback',
      sortable: false,
      filterable: true,
    },
  ];

  matchFilters = [
    {
      header: 'assetName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'guid',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'assetType',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'projectNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'projectName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'workOrderTask',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'inspectionDate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      conversionFunction: this.processDate,
    },
    {
      header: 'feedback',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  filterValues = {};

  mounted(): void {
    if (!this.hasPermissionReportingCommon) {
      this.goToErrorPage();
    }
    this.getRFCAssignments();
  }

  @Watch('readyForCodingAssignmentData')
  onReadyForCodingAssignmentDataChange(): void{
    this.filterValues = {};
    this.matchFilters.forEach((header) => {
      this.filterValues[header.header] = [];
    });
    this.readyForCodingAssignmentData.forEach((element) => {
      this.matchFilters.forEach((header) => {
        const elementHeaderValue = header.conversionFunction
          ? header.conversionFunction(element)
          : element[header.header];
        this.filterValues[header.header].push(elementHeaderValue);
      });
    });
    this.matchFilters.forEach((header) => {
      this.filterValues[header.header] = this.filterValues[header.header].filter(this.uniqueValues);
    });
  }

  uniqueValues(value: string, index: number, arr: string[]): boolean {
    return arr.indexOf(value) === index;
  }

  processDate(item: ReadyForCodingAssignmentData): string {
    return item?.inspectionDate ? item.inspectionDate.substring(0, 10) : '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAssetParams(item: ReadyForCodingAssignmentData): any {
    return { idList: JSON.stringify([item.projectGuid]), id: item.guid };
  }

  /**
   * @returns true if the user has the permission REPORTING_COMMON
   */
  get hasPermissionReportingCommon(): boolean {
    return this.hasPermission(UserPermission.REPORTING_COMMON);
  }
}
