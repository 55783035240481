import { ActionTree } from 'vuex';
import cookies from 'cookiesjs';
import { AssetRef } from '@/types';
import { RootState } from '../types';
import { UserPrefsState } from './types';
import { UserPrefsMutations } from './mutations';

// eslint-disable-next-line no-shadow
export enum UserPrefsActions {
  GET_USER_PREFS_DATA = 'GET_USER_PREFS_DATA',
  SET_METRIC_PREFS = 'SET_METRIC_PREFS',
  GET_SELECTED_MAP_ASSET = 'GET_SELECTED_MAP_ASSET',
  SET_SELECTED_MAP_ASSET = 'SET_SELECTED_MAP_ASSET',
  GET_ZOOM_FILTER = 'GET_ZOOM_FILTER',
  SET_ZOOM_FILTER = 'SET_ZOOM_FILTER',
  GET_DEFECT_CODEDESC = 'GET_DEFECT_CODEDESC',
  SET_DEFECT_CODEDESC = 'SET_DEFECT_CODEDESC',
}

export const actions: ActionTree<UserPrefsState, RootState> = {

  // eslint-disable-next-line spaced-comment
  [UserPrefsActions.GET_USER_PREFS_DATA]({ commit }): void {
    const token = cookies('displayImperial');
    if (token === undefined) {
      cookies({ displayImperial: true });
    }

    commit(UserPrefsMutations.SET_DISPLAY_METRIC_STATE, cookies('displayImperial'));
  },
  // eslint-disable-next-line spaced-comment
  [UserPrefsActions.SET_METRIC_PREFS]({ commit }, payload: boolean): void {
    cookies({ displayImperial: payload });
    commit(UserPrefsMutations.SET_DISPLAY_METRIC_STATE, payload);
  },

  [UserPrefsActions.GET_SELECTED_MAP_ASSET]({ commit }): void {
    const token = cookies('selectedMapAssetGuid');
    commit(UserPrefsMutations.SET_SELECTED_MAP_ASSET, token);
  },

  [UserPrefsActions.SET_SELECTED_MAP_ASSET]({ commit }, payload: AssetRef): void {
    const assetGuid = payload ? payload.guid : undefined;
    cookies({ selectedMapAssetGuid: assetGuid });
    commit(UserPrefsMutations.SET_SELECTED_MAP_ASSET, assetGuid);
  },

  [UserPrefsActions.GET_ZOOM_FILTER]({ commit }): void {
    const token = cookies('useZoomFilter');
    commit(UserPrefsMutations.SET_ZOOM_FILTER, token);
  },

  [UserPrefsActions.SET_ZOOM_FILTER]({ commit }, payload: boolean): void {
    cookies({ useZoomFilter: payload });
    commit(UserPrefsMutations.SET_ZOOM_FILTER, payload);
  },

  [UserPrefsActions.GET_DEFECT_CODEDESC]({ commit }): void {
    const token = cookies('useDefectCodeDesc');
    commit(UserPrefsMutations.SET_DEFECT_CODEDESC, token);
  },

  [UserPrefsActions.SET_DEFECT_CODEDESC]({ commit }, payload: boolean): void {
    cookies({ useDefectCodeDesc: payload });
    commit(UserPrefsMutations.SET_DEFECT_CODEDESC, payload);
  },
};
