import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { AssignmentState } from './types';

const state: AssignmentState = {
  loading: undefined,
  loadError: undefined,
  postAssignmentData: undefined,
  readyForCodingAssignmentData: undefined,
  getAICodingWorkOrderLoading: false,
  getAICodingWorkOrderError: undefined,
  getAICodingWorkOrderData: [],
  patchAICodingWorkOrderLoading: false,
  patchAICodingWorkOrderError: undefined,
  patchAICodingWorkOrderData: undefined,
  routeLoading: false,
  routeError: undefined,
  followUpLoading: false,
  followUpError: undefined,
  followUpWorkOrder: undefined,
};

const Assignment: Module<AssignmentState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default Assignment;
