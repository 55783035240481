











































































































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import { namespace } from 'vuex-class';
import { AssetData } from '@/store/asset/types';
import { AssetActions } from '@/store/asset/actions';
import ReportObservation from '../ReportObservation.vue';
import PipeConditionReport from '../../PipeConditionReport/PipeConditionReport.vue';
import ReportFooter from '../ReportFooter.vue';
import util from '../util';
import { cleaningReportFields } from './types';

const assetModule = namespace('asset');

@Component({
  components: {
    ReportFooter,
    ReportObservation,
    PipeConditionReport,
  },
})
export default class CleaningWorkOrderReport extends Vue {
  @assetModule.State('asset') asset!: AssetData;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  //   @Prop() readonly assetName!: string;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  cleaningReportFields: cleaningReportFields | any = {};

  workOrderJson: any = {};

  get workOrderType(): string {
    return this.cleaningReportFields.workOrderNumber
      ? this.cleaningReportFields.workOrderNumber.split('-')[0]
      : '';
  }

  // eslint-disable-next-line class-methods-use-this
  formatText(text: string): string {
    if (!text) return '';
    let newText = text;

    newText = newText.replace(/&gt;/g, '>');
    newText = newText.replace(/&lt;/g, '<');
    newText = newText.replace(/&quot;/g, '"');

    return newText;
  }

  getDisplayDistanceInMM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceInMM(this.displayImperial, value)
          + util.getDistanceUnitsInMM(this.displayImperial)
      : '';
  }

  getDisplayDistanceFtM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceFtM(this.displayImperial, value)
          + util.getDistanceUnitsFtM(this.displayImperial)
      : '';
  }

  mounted(): void {
    this.workOrderJson = JSON.parse(this.codingDetail.inspections[0].jsonData);
    // fill fields
    this.fillCleaningReportFields();
    // this.fetchAsset();
    this.$forceUpdate();
  }

  fillCleaningReportFields(): void {
    this.cleaningReportFields = {
      name: this.item && this.item.nodeName ? this.item.nodeName : '',
      assignedTo: this.workOrderJson ? this.workOrderJson.Users : '',
      route: '', // ???
      frequency: this.workOrderJson
        ? this.workOrderJson.FrequencyChange_FC
        : '',
      status: this.item && this.item.status ? this.item.status : '',
      workOrderNumber:
        this.item && this.item.workOrderNumber ? this.item.workOrderNumber : '',
      cleaningInspTask:
        this.workOrderJson && this.workOrderJson.CleaningInspTask ? this.workOrderJson.CleaningInspTask : '',
      dateIssued: this.workOrderJson ? this.formatDate(this.workOrderJson.DateIssued) : '',
      completedBy: '', // ???
      workDescription: this.workOrderJson ? this.workOrderJson.Description : '',
      specialInstructions: this.workOrderJson
        ? this.workOrderJson.Instructions
        : '',
      weatherCondition: this.workOrderJson
        ? this.workOrderJson.WeatherType
        : '',
      structUp: this.workOrderJson
        ? this.workOrderJson.Wastewater_Structure_Up
        : '',
      structDown: this.workOrderJson
        ? this.workOrderJson.Wastewater_Structure_Dn
        : '',
      address: this.workOrderJson ? this.workOrderJson.Address : '',
      size: this.workOrderJson ? this.workOrderJson.PipeSize : '',
      material: this.workOrderJson ? this.workOrderJson.PipeMaterial : '',
      length: this.workOrderJson ? this.workOrderJson.Length : '',
      specialConditions: this.workOrderJson
        ? this.workOrderJson.SpecialConditions
        : '',
      dateCompleted:
        this.item && this.item.completeDate ? this.formatDate(this.item.completeDate) : '',
      roots: this.workOrderJson ? this.workOrderJson.ConditionLMH_Obs1 : '',
      greese: this.workOrderJson ? this.workOrderJson.ConditionLMH_Obs2 : '',
      odor: this.workOrderJson ? this.workOrderJson.ConditionLMH_Obs3 : '',
      observationOther: this.workOrderJson
        ? this.workOrderJson.ConditionLMH_Obs4
        : '',
      gravel: this.workOrderJson ? this.workOrderJson.ConditionLMH_Obs8 : '',
      grit: this.workOrderJson ? this.workOrderJson.ConditionLMH_Obs7 : '',
      sand: this.workOrderJson ? this.workOrderJson.ConditionLMH_Obs6 : '',
      cleaningComments: this.workOrderJson
        ? this.workOrderJson.CleanComments
        : '',
      rootCut: this.workOrderJson ? this.workOrderJson.FURootTreat : false,
      cctv: this.workOrderJson ? this.workOrderJson.FUCCTV : false,
      repair: this.workOrderJson ? this.workOrderJson.FURepair : false,
      manholeInpsection: this.workOrderJson
        ? this.workOrderJson.FUMHInspect
        : false,
      perfromInvestigation: this.workOrderJson
        ? this.workOrderJson.FUInvestigate
        : false,
      mapChanges: this.workOrderJson ? this.workOrderJson.FUMapChanges : false,
      followUpOther: this.workOrderJson ? this.workOrderJson.FUOther : false,
      increaseFrequency: false, // ???
      decreaseFrequency: false, // ???
      none: false, // ???
      followUpComments: this.workOrderJson
        ? this.workOrderJson.FollowUpComments
        : '',
      workOrderType:
        this.item && this.item.taskTypeDefinition
          ? this.item.taskTypeDefinition
          : '',
    };
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }

    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    const year = date.getFullYear();
    const month = (1 + date.getMonth());
    const day = date.getDate();

    return `${month}/${day}/${year}`;
  }

  fetchAsset(): void {
    this.fetchAssetData({
      assetId: this.item.nodeGuid,
      inspectionId: this.item.guid,
    }).then(() => {
      this.$forceUpdate();
    });
  }
}
