











import Vue from 'vue';

export default Vue.extend({
  name: 'Clock',
  props: {
    angle: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    lastAngleDiv: 0,
    oldInnerHtml: '',
    numbers: ['03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '01:00', '02:00'],
  }),
  watch: {
    angle: {
      immediate: false,
      handler(value:number) {
        if (!this.disabled) {
          this.moveClock(value);
        }
      },
    },
    disabled: {
      immediate: false,
      handler(value:boolean) {
        const clock = (document.getElementById('clock') as HTMLTableElement);
        if (!value) {
          if (this.oldInnerHtml !== '') {
            clock.innerHTML = this.oldInnerHtml;
            this.moveClock(this.angle);
          }
        } else {
          this.oldInnerHtml = clock.innerHTML;
          clock.innerHTML = '';
        }
      },
    },
  },
  methods: {
    moveClock(value:number) {
      const currentAngle = value;
      let doRecursion = true;

      if (currentAngle < this.lastAngleDiv) {
        if (Math.abs(currentAngle - this.lastAngleDiv) >= (360 / 12)) {
          this.lastAngleDiv -= (360 / 12);
          if (this.lastAngleDiv <= -360) {
            this.lastAngleDiv += 360;
            doRecursion = false;
          }
          this.numbers.unshift((this.numbers.pop() as string));
          if (doRecursion) {
            return this.moveClock(value);
          }
        }
      } else if (currentAngle > this.lastAngleDiv) {
        if (Math.abs(currentAngle - this.lastAngleDiv) >= (360 / 12)) {
          this.lastAngleDiv += (360 / 12);
          if (this.lastAngleDiv >= 360) {
            this.lastAngleDiv -= 360;
            doRecursion = false;
          }
          this.numbers.push((this.numbers.shift() as string));
          if (doRecursion) {
            return this.moveClock(value);
          }
        }
      }
      this.$emit('updateClock', this.numbers[3]);
      return null;
    },
  },
});
