import { ActionTree } from 'vuex';
import { RootState } from '../types';
import storeUtility from '../utils';
import { CustomerData, CustomerState } from './types';
import { CustomerMutations } from './mutations';

// eslint-disable-next-line no-shadow
export enum CustomerActions {
  FETCH_CUSTOMER_DATA = 'FETCH_CUSTOMER_DATA',
  POST_CUSTOMER_DATA = 'POST_CUSTOMER_DATA',
  PATCH_CUSTOMER_DATA = 'PATCH_CUSTOMER_DATA',
  DELETE_CUSTOMER_DATA = 'DELETE_CUSTOMER_DATA',
}

export const actions: ActionTree<CustomerState, RootState> = {

  async [CustomerActions.FETCH_CUSTOMER_DATA]({ commit }): Promise<void> {
    commit(CustomerMutations.SET_GET_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getCustomerData();

      commit(CustomerMutations.SET_CUSTOMER_DATA, response.data.data);
    } catch (e) {
      commit(CustomerMutations.SET_GET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(CustomerMutations.SET_GET_LOADING, false);
    }
  },
  async [CustomerActions.POST_CUSTOMER_DATA]({ commit }, payload: CustomerData):
    Promise<void> {
    commit(CustomerMutations.SET_POST_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postCustomerData(payload);

      commit(CustomerMutations.SET_POST_CUSTOMER_OBJECT, response.data.data);
    } catch (e) {
      commit(CustomerMutations.SET_POST_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(CustomerMutations.SET_POST_LOADING, false);
    }
  },
  async [CustomerActions.PATCH_CUSTOMER_DATA]({ commit }, payload: CustomerData):
    Promise<void> {
    commit(CustomerMutations.SET_PATCH_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.patchCustomerData(payload);

      commit(CustomerMutations.SET_PATCH_CUSTOMER_OBJECT, response.data.data);
    } catch (e) {
      commit(CustomerMutations.SET_PATCH_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(CustomerMutations.SET_PATCH_LOADING, false);
    }
  },
  async [CustomerActions.DELETE_CUSTOMER_DATA]({ commit }, payload: string[]): Promise<void> {
    commit(CustomerMutations.SET_DELETE_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.deleteCustomerData(payload);
    } catch (e) {
      commit(CustomerMutations.SET_DELETE_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(CustomerMutations.SET_DELETE_LOADING, false);
    }
  },
};
