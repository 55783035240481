/* eslint-disable max-classes-per-file */
export interface RootState {
    title: string
}

export interface Mapping {
    databaseName: string;
    frontEndName: string;
    required: boolean;
}

export class UploadFileResults {
    FileName = '';

    Complete = false;

    ErrorMessage = '';

    FileHeaders: string[] = [];

    FileFirstRow: string[] = [];

    RzColumns: Mapping[] = [];

    Files?: string[] = [];
}

export class UploadMappingResults {
    Complete = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Data: any = {};

    ErrorMessage = '';
}

export interface ZipFileMapping {
    AssetGuid: string;
    ZipFile: string;
    InspectionGuid?: string;
    CrossSectionFile?: string;
}
