













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import { namespace } from 'vuex-class';
import { AssetData } from '@/store/asset/types';
import { AssetActions } from '@/store/asset/actions';
import ReportObservation from '../ReportObservation.vue';
import ReportFooter from '../ReportFooter.vue';
import util from '../util';
import { ssoReportFields } from './types';

const assetModule = namespace('asset');

@Component({
  components: {
    ReportFooter,
    ReportObservation,
  },
})
export default class SSOReportReport extends Vue {
  @assetModule.State('asset') asset!: AssetData;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  ssoReportFields: ssoReportFields | any = {};

  workOrderJson: any;

  // eslint-disable-next-line class-methods-use-this
  formatText(text: string): string {
    if (!text) return '';
    let newText = text;

    newText = newText.replace(/&gt;/g, '>');
    newText = newText.replace(/&lt;/g, '<');
    newText = newText.replace(/&quot;/g, '"');

    return newText;
  }

  getDisplayDistanceInMM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceInMM(this.displayImperial, value)
          + util.getDistanceUnitsInMM(this.displayImperial)
      : '';
  }

  getDisplayDistanceFtM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceFtM(this.displayImperial, value)
          + util.getDistanceUnitsFtM(this.displayImperial)
      : '';
  }

  mounted(): void {
    this.workOrderJson = JSON.parse(this.codingDetail.inspections[0].jsonData);
    // fill fields
    this.fillSSOReportFields();
    // this.fetchAsset();
    this.$forceUpdate();
  }

  fillSSOReportFields(): void {
    this.ssoReportFields = {
      name: this.item && this.item.nodeName ? this.item.nodeName : '',
      reportedBy: '', // ?
      status: '', // ?
      workOrderNumber:
        this.item && this.item.workOrderNumber ? this.item.workOrderNumber : '',
      reportedDate: this.workOrderJson
        ? this.formatDate(this.workOrderJson.DeptNotifiedDate)
        : '',
      spillLocation: this.workOrderJson ? this.workOrderJson.SpillLocation : '',
      latitude: this.workOrderJson
        ? this.formatCoordinates(
          this.workOrderJson.SpillLatHours,
          this.workOrderJson.SpillLatMinutes,
          this.workOrderJson.SpillLatSeconds,
        )
        : '',
      longitude: this.workOrderJson
        ? this.formatCoordinates(
          this.workOrderJson.SpillLongHours,
          this.workOrderJson.SpillLongMinutes,
          this.workOrderJson.SpillLongSeconds,
        )
        : '',
      address: this.workOrderJson ? this.workOrderJson.SpillAddress : '',
      crossStreet: this.workOrderJson
        ? this.workOrderJson.SpillCrossStreet
        : '',
      city: this.workOrderJson ? this.workOrderJson.City : '',
      county: this.workOrderJson ? this.workOrderJson.SpillCounty : '',
      locationDescription: this.workOrderJson
        ? this.workOrderJson.SpillLocationDesc
        : '',
      regionalWaterQualityControlBoard: this.workOrderJson
        ? this.workOrderJson.SpillRWCB
        : '',
      specifyAppearancePointOther: this.workOrderJson
        ? this.workOrderJson.SpillAppearancePointOther
        : '',
      responsiblePartyName: this.workOrderJson
        ? this.workOrderJson.NameOfResponsibleParty
        : '',
      specifyFinalSpillDesination: this.workOrderJson
        ? this.workOrderJson.SpillFinalDestOther
        : '',
      methodUsedToEstimateSpillVolumes: this.workOrderJson
        ? this.workOrderJson.EstimateCalculationMethod
        : '',
      estimatedSpillStartDateTime: this.workOrderJson
        ? this.formatDateTime(
          this.workOrderJson.EstStartDate,
          this.workOrderJson.EstStartTime,
        )
        : '',
      esitmatedSpillVolume: this.workOrderJson
        ? this.workOrderJson.SpillVolume
        : '',
      departmentNotifiedDateTime: this.workOrderJson
        ? this.formatDateTime(
          this.workOrderJson.DeptNotifiedDate,
          this.workOrderJson.DeptNotifiedTime,
        )
        : '',
      estimatedVolumeRecovered: this.workOrderJson
        ? this.workOrderJson.RecoveredVollume
        : '',
      estimatedDepartmentArrivalDateTime: this.workOrderJson
        ? this.formatDateTime(
          this.workOrderJson.DepartArrivalDate,
          this.workOrderJson.DepartArrivalTime,
        )
        : '',
      estimatedSpillStopDateTime: this.workOrderJson
        ? this.formatDateTime(
          this.workOrderJson.SpillStopDate,
          this.workOrderJson.SpillStopTime,
        )
        : '',
      estimatedVolumeRecoveredNotStormDrain: this.workOrderJson
        ? this.workOrderJson.NotRecoveredVolume
        : '',
      estimatedCurrentSpillRate: this.workOrderJson
        ? this.workOrderJson.EstSpillRate
        : '',
      spillCauseExplanation: this.workOrderJson
        ? this.workOrderJson.SpillCauseOther
        : '',
      sizeOfStorm: this.workOrderJson ? this.workOrderJson.SSOStormSize : '',
      pipe: this.workOrderJson ? this.workOrderJson.PipeSize : '',
      diameter: '', // ?
      length: this.workOrderJson ? this.workOrderJson.Length : '',
      material: this.workOrderJson ? this.workOrderJson.PipeMaterial : '',
      structure: '', // ?
      pipeAge: this.workOrderJson ? this.workOrderJson.PipeAge : '',
      structureAge: this.workOrderJson ? this.workOrderJson.StructAge : '',
      spillResponseOtherMessage: this.workOrderJson
        ? this.workOrderJson.SpillResponseDetails
        : '',
      spillResponseCompletionDate: this.workOrderJson
        ? this.formatDateTime(
          this.workOrderJson.SpillRespCompDate,
          this.workOrderJson.SpillRespCompTime,
        )
        : '',
      imapctedBeaches: this.workOrderJson
        ? this.workOrderJson.ImpactedBeaches
        : '',
      impactedSurfaceWaters: this.workOrderJson
        ? this.workOrderJson.ImpactedSurfaceWaters
        : '',
      analyzedForExplain: '', // ?
      resultsReportedToExplanation: this.workOrderJson
        ? this.workOrderJson.SpillSampleOtherResponse
        : '',
      spillCorrectiveActionExplanation: this.workOrderJson
        ? this.workOrderJson.SpillCorrectiveActionOther
        : '',
      overallSpillDescription: this.workOrderJson
        ? this.workOrderJson.DescriptionOfSpill
        : '',
      oesContact: '', // ?
      contactedBy: '', // ?
      oesNotifiedDate: '', // ?
      oesControlNumber: '', // ?
      healthServices: '', // ?
      healthServicesNotifiedDate: '', // ?
      healthServicesCaseNumber: '', // ?
      RWQCBTrackingNumber: '', // ?
      RWQCBDate: '', // ?
      SWQCBEventID: '', // ?
      SWQCBDate: '', // ?
      SWQCBCertificationNumber: '', // ?
      appearancePointBuildingOrStructure: this.workOrderJson
        ? this.workOrderJson.SpillBuildingOrStructure
        : false,
      appearancePointOtherSewerSystemStructure: this.workOrderJson
        ? this.workOrderJson.SpillOtherSewerSystemStructure
        : false,
      appearancePointForceMainOrPressureSewer: this.workOrderJson
        ? this.workOrderJson.SpillForceMainOrPessureSewer
        : false,
      appearancePointPumpStation: this.workOrderJson
        ? this.workOrderJson.SpillPumpStation
        : false,
      appearancePointGravitySewer: this.workOrderJson
        ? this.workOrderJson.SpillGravitySewer
        : false,
      appearancePointOther: false, // ?
      appearancePointManhole: this.workOrderJson
        ? this.workOrderJson.SpillManhole
        : false,
      specifyAppearancePointDrainageChannel: this.workOrderJson
        ? this.workOrderJson.DischargeReachedDrain
        : false,
      specifyAppearancePointLateralSpill: this.workOrderJson
        ? this.workOrderJson.PrivateLateral
        : false,
      specifyAppearancePointNotCaptured: this.workOrderJson
        ? this.workOrderJson.DischargeNotReturnedToSS
        : false,
      finalSpillDesinationBeach: this.workOrderJson
        ? this.workOrderJson.SpillReachedBeach
        : false,
      finalSpillDesinationStreet: this.workOrderJson
        ? this.workOrderJson.SpillReachedStreetCurbAndGutter
        : false,
      finalSpillDesinationBuilding: this.workOrderJson
        ? this.workOrderJson.SpillReachedBuildingOrStructure
        : false,
      finalSpillDesinationSurface: this.workOrderJson
        ? this.workOrderJson.SpillReachedSurfaceWater
        : false,
      finalSpillDesinationPaved: this.workOrderJson
        ? this.workOrderJson.SpillReachedOtherPavedSurface
        : false,
      finalSpillDesinationUnpaved: this.workOrderJson
        ? this.workOrderJson.SpillReachedUnpavedSurface
        : false,
      finalSpillDesinationStormDrain: this.workOrderJson
        ? this.workOrderJson.SpillReachedStormDrain
        : false,
      finalSpillDesinationOther: this.workOrderJson
        ? this.workOrderJson['SpillReachedOther(Specifiy)']
        : false,
      photosTaken: this.workOrderJson ? this.workOrderJson.PhotosTaken : false,
      spillCauseDebris: this.workOrderJson
        ? this.workOrderJson.SpillCauseDebris
        : false,
      spillCausePumpFailure: this.workOrderJson
        ? this.workOrderJson.SpillCausePumpStationFailure
        : false,
      spillCauseFlow: this.workOrderJson
        ? this.workOrderJson.SpillCauseFlowExceededCapacity
        : false,
      spillCauseRainfall: this.workOrderJson
        ? this.workOrderJson.SpillCauseRainfallExceededDesign
        : false,
      spillCauseGreaseDeposition: this.workOrderJson
        ? this.workOrderJson.SpillCauseGreaseDeposition
        : false,
      spillCauseRootIntrusion: this.workOrderJson
        ? this.workOrderJson.SpillCauseRootIntrusion
        : false,
      spillCauseOperatorError: this.workOrderJson
        ? this.workOrderJson.SpillCauseOperatorError
        : false,
      spillCauseVandalism: this.workOrderJson
        ? this.workOrderJson.SpillCauseVandalism
        : false,
      spillCausePipeProblem: this.workOrderJson
        ? this.workOrderJson.SpillCausePipeStructuralProblemFailure
        : false,
      spillCauseOther: this.workOrderJson
        ? this.workOrderJson.SpillOther
        : false,
      flat: this.workOrderJson
        ? this.workOrderJson.SSOTerrain === 'FLAT'
        : false,
      mixed: this.workOrderJson
        ? this.workOrderJson.SSOTerrain === 'MIXED'
        : false,
      steep: this.workOrderJson
        ? this.workOrderJson.SSOTerrain === 'STEEP'
        : false,
      spillResponseCleanedUp: this.workOrderJson
        ? this.workOrderJson['SpillResponseCleaned-Up']
        : false,
      spillResponseRestoredFlow: this.workOrderJson
        ? this.workOrderJson.SpillResponseRestoredFlow
        : false,
      spillResponseContained: this.workOrderJson
        ? this.workOrderJson.SpillResponseContainedAllOrPortionOfSpill
        : false,
      spillResponseReturnedToSewer: this.workOrderJson
        ? this.workOrderJson
          .SpillResponseReturnedAllOrPortionToSanitarySewerSystem
        : false,
      spillResponseCCTV: this.workOrderJson
        ? this.workOrderJson
          .SpillResponseInspectedSewerUsingCCTVToDetermineCause
        : false,
      spillResponseOther: this.workOrderJson
        ? this.workOrderJson.SpillResponseOther
        : false,
      healthWarningsPosted: this.workOrderJson
        ? this.workOrderJson.HealthWarningsPosted
        : false,
      isOngoingInvestigation: this.workOrderJson
        ? this.workOrderJson.OngoingInvestigation
        : false,
      anyFishKilled: this.workOrderJson ? this.workOrderJson.FishKilled : false,
      analyzedForDissolvedOxygen: this.workOrderJson
        ? this.workOrderJson.SampleAnalyzedForDissolvedOxygen
        : false,
      analyzedForNoSamples: this.workOrderJson
        ? this.workOrderJson.SampleAnalyzedForNoWaterQualitySamplesTaken
        : false,
      analyzedForOtherChemicalIndicators: this.workOrderJson
        ? this.workOrderJson.SampleAnalyzedForOtherChemicalIndicators
        : false,
      analyzedForOther: this.workOrderJson
        ? this.workOrderJson.SampleAnalyzedForOther
        : false,
      analyzedForBiologicalIndicators: this.workOrderJson
        ? this.workOrderJson.SampleAnalyzedForBiologicalIndicators
        : false,
      resultsReportedToCountyHealth: this.workOrderJson
        ? this.workOrderJson.ReportedToCountyHealthAgency
        : false,
      resultsReportedToNoSamples: this.workOrderJson
        ? this.workOrderJson.ReportedToNoWaterQualitySamplesTaken
        : false,
      resultsReportedToRegionalBoard: this.workOrderJson
        ? this.workOrderJson.ReportedToRegionalWaterQualityControlBoard
        : false,
      resultsReportedToNotApplicable: this.workOrderJson
        ? this.workOrderJson.ReportedToNotApplicableToThisSpill
        : false,
      resultsReportedToNoneOfTheAbove: this.workOrderJson
        ? this.workOrderJson.ReportedToNoneOfTheAbove
        : false,
      spillCorrectiveActionAddedSewer: this.workOrderJson
        ? this.workOrderJson.CorrectiveActionAddedSewerToPMProgram
        : false,
      spillCorrectiveActionPlanRehabilitation: this.workOrderJson
        ? this.workOrderJson.CorrectiveActionPlanRehabilitationOfSewer
        : false,
      spillCorrectiveActionAdjustedSchedule: this.workOrderJson
        ? this.workOrderJson.CorrectiveActionAdjustedScheduleMethodOfPM
        : false,
      spillCorrectiveActionRepair: this.workOrderJson
        ? this.workOrderJson.CorrectiveActionRepairSewer
        : false,
      spillCorrectiveActionEnfmtAction: this.workOrderJson
        ? this.workOrderJson.CorrectiveActionEnfmntActionAgainstFogSource
        : false,
      spillCorrectiveActionOther: this.workOrderJson
        ? this.workOrderJson.CorrectiveActionOther
        : false,
      healthServicesNotified: false, // ?
      RWQCBFax: false, // ?
      SWQCBElectronicFiled: false, // ?
    };
  }

  fetchAsset(): void {
    this.fetchAssetData({
      assetId: this.item.nodeGuid,
      inspectionId: this.item.guid,
    }).then(() => {
      this.$forceUpdate();
    });
  }

  formatDateTime(dateString: string, timeString: string): string {
    return `${this.formatDate(dateString)} ${this.formatTime(timeString)}`;
  }

  formatTime(timeString: string): string {
    if (!timeString) {
      return '';
    }

    const date = new Date(timeString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }
    const formattedTimeString = timeString
      .substring(11, timeString.length - 3)
      .split(':');

    const hours = Number(
      formattedTimeString.length > 1 ? formattedTimeString[0] : '',
    );
    const minutes = Number(
      formattedTimeString.length > 1 ? formattedTimeString[1] : '',
    );

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(hours) || isNaN(minutes)) {
      return '';
    }

    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleTimeString([], { timeStyle: 'short' });
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }

    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    const year = date.getFullYear();
    const month = 1 + date.getMonth();
    const day = date.getDate();

    return `${month}/${day}/${year}`;
  }

  formatCoordinates(hours: string, minutes: string, seconds: string): string {
    if (!hours || !minutes || !seconds) {
      return '';
    }
    return `${hours}°${minutes}'${seconds}"`;
  }
}
