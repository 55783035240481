










































































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import {
  PROJECT_MANAGER, REPORTING_TECH_AUTH0, SUPER_USER, SYS_ADMIN_AUTH0,
} from '@/auth/roles';
import { namespace } from 'vuex-class';
import { AssignmentActions } from '@/store/assignments/actions';
import { AICodingWorkOrder } from '@/store/assignments/types';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { TableMode } from '../../components/ExportDataPopout/types';
import { DataTableHeader } from '../../components/AssetTable/types';
import IntegrityTable from '../../components/IntegrityTable/IntegrityTable.vue';
import IntegrityDelete from '../../components/IntegrityDelete/IntegrityDelete.vue';

const assignmentModule = namespace('assignments');

@Component({
  components: {
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class WinCanWorkOrder extends UserPermissionsMixin {
  @assignmentModule.Action(AssignmentActions.GET_AICODING_WORKORDERS) getAiWorkorders;

  @assignmentModule.Action(AssignmentActions.PATCH_AICODING_WORKORDERS) patchAiWorkorders;

  @assignmentModule.State('getAICodingWorkOrderError') loadError : string | undefined;

  @assignmentModule.State('getAICodingWorkOrderLoading') loadingData : boolean;

  @assignmentModule.State('getAICodingWorkOrderData') tableData : AICodingWorkOrder[];

  @assignmentModule.State('patchAICodingWorkOrderError') patchError : string | undefined;

  @assignmentModule.State('patchAICodingWorkOrderLoading') patchLoading : boolean;

  @assignmentModule.State('patchAICodingWorkOrderData') patchData;

  @Prop() readonly id!: string;

  projectGuidList: string[] = [];

  activeTab = 0;

  roles: string[] = [];

  isPM = false;

  isSysAdmin = false;

  isReportingTech = false;

  search = '';

  snack = false;

  snackColor = 'black';

  snackText = '';

  tableMode = TableMode.NORMAL;

  expandTableLocal = false;

  tableModel = [];

  filterValues = {};

  blankMatchFilters = {};

  manholeHeaders: DataTableHeader[] = [
    {
      text: 'Asset Name', value: 'assetName', sortable: true, filterable: true, class: 'sticky', cellClass: 'sticky', editable: true,
    },
    {
      text: 'Status', value: 'status', sortable: true, filterable: true,
    },
    {
      text: 'Deployment', value: 'deployment', sortable: true, filterable: true,
    },
    {
      text: 'Total Footage', value: 'totalFootage', sortable: true, filterable: true,
    },
    {
      text: 'Direction', value: 'direction', sortable: true, filterable: true, editable: true,
    },
    {
      text: 'Deployment Date/Time', value: 'dateTime', sortable: true, filterable: true, editable: true,
    },
  ];

  pipeHeaders: DataTableHeader[] = [
    {
      text: 'Asset Name', value: 'assetName', sortable: true, filterable: true, class: 'sticky', cellClass: 'sticky', editable: true,
    },
    {
      text: 'Status', value: 'status', sortable: true, filterable: true,
    },
    {
      text: 'Deployment', value: 'deployment', sortable: true, filterable: true,
    },
    {
      text: 'Total Footage', value: 'totalFootage', sortable: true, filterable: true,
    },
    {
      text: 'Direction', value: 'direction', sortable: true, filterable: true, editable: true,
    },
    {
      text: 'Deployment Date/Time', value: 'dateTime', sortable: true, filterable: true, editable: true,
    },
  ];

  matchFilters = [
    {
      header: 'assetName', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'status', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'deployment', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'totalFootage', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'direction', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'dateTime', type: 'date', value: '', method: '', options: [], tempValue: '', open: false,
    },
  ]

  displayHeaders: DataTableHeader[][] = [
    this.manholeHeaders,
    this.pipeHeaders,
  ];

  tabOptions = [
    { name: 'Manhole', value: 'Manhole' },
    { name: 'Line Segment', value: 'Line Segment' },
  ]

  get editPermissions(): boolean | undefined {
    return this.isPM || this.isSysAdmin || this.isReportingTech;
  }

  get featureHeaders(): DataTableHeader[] {
    return this.displayHeaders[this.activeTab];
  }

  get filteredTableData(): AICodingWorkOrder[] {
    const assetTypes = ['Manhole', 'Line Segment'];
    return this.tableData.filter((asset) => asset.type === assetTypes[this.activeTab]);
  }

  get showSelect(): boolean {
    return this.isEditTable();
  }

  get hasPermissionAICommon(): boolean {
    return this.hasPermission(UserPermission.AI_COMMON);
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  async mounted(): Promise<void> {
    try {
      this.projectGuidList = (JSON.parse(this.id) as string[]);
    } catch {
      this.projectGuidList = [this.id];
    }
    this.getAiWorkorders(this.id);
    this.expandTableLocal = false;
    this.updateFilterValues();

    if (this.$auth.user.id !== null) {
      this.roles = await this.getRoles();
      this.isPM = this.roles.includes(PROJECT_MANAGER) || this.roles.includes(SUPER_USER);
      this.isSysAdmin = this.roles.includes(SYS_ADMIN_AUTH0) || this.roles.includes(SUPER_USER);
      this.isReportingTech = this.roles.includes(REPORTING_TECH_AUTH0);
    }
    // Do not have the ai coding permission
    if (!this.hasPermissionAICommon) {
      this.goToErrorPage();
    }
  }

  updateFilterValues(): void{
    if (this.tableData.length <= 0) {
      return;
    }
    this.tableData.forEach((data) => {
      // Populate filterValues with unique values for each column
      this.displayHeaders[this.activeTab].forEach((header) => {
        if (header.value === 'assetName') return;

        let checkVal: string;

        if (!this.filterValues[header.value]) this.filterValues[header.value] = [];

        try {
          checkVal = data[header.value];
        } catch {
          checkVal = '';
        }

        if (
          checkVal
          && checkVal !== ''
          && Array.isArray(this.filterValues[header.value])
          && !this.filterValues[header.value].includes(checkVal)
        ) {
          this.filterValues[header.value].push(checkVal);
        }

        this.filterValues[header.value].sort();
      });
    });
  }

  isEditTable(): boolean {
    return this.tableMode === TableMode.EDIT;
  }

  toggleEdit(): void {
    this.expandTableLocal = true;
    this.tableMode = this.tableMode !== TableMode.EDIT ? TableMode.EDIT : TableMode.NORMAL;

    if (this.isEditTable()) {
      this.tableModel = [];
    } else {
      this.tableModel = [];
    }
  }

  async saveWorkOrder(workOrder: AICodingWorkOrder): Promise<void> {
    // Call edit API
    try {
      if (typeof workOrder.dateTime === 'string' || workOrder.dateTime instanceof String) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(Date.parse(workOrder.dateTime as unknown as string))) {
          this.snack = true;
          this.snackColor = '#e61e25';
          this.snackText = 'Entered date/time is not valid';
          this.$forceUpdate();
          return;
        }
      }
      await this.patchAiWorkorders(
        { projectGuid: this.projectGuidList[0], payload: [workOrder] },
      );
      if (this.patchData === 'Success') {
        // set snack bar
        this.snack = true;
        this.snackColor = 'green';
        this.snackText = 'Data saved';
        this.$forceUpdate();
      } else {
        this.snack = true;
        this.snackColor = '#e61e25';
        this.snackText = this.patchData === undefined || this.patchData === '' ? 'Data not saved' : this.patchData;
        this.$forceUpdate();
      }
    } catch {
      this.snack = true;
      this.snackColor = '#e61e25';
      this.snackText = 'Edit Failed';
      this.$forceUpdate();
    }
  }
}
