

























































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import { namespace } from 'vuex-class';
import { AssetData } from '@/store/asset/types';
import ReportFooter from '../ReportFooter.vue';
import { GenericWorkFields } from './types';

const assetModule = namespace('asset');

@Component({
  components: {
    ReportFooter,
  },
})
export default class GenericWorkReport extends Vue {
  @assetModule.State('asset') asset!: AssetData;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  genericWorkFields: GenericWorkFields | any = {};

  workOrderJson: any;

  mounted(): void {
    this.workOrderJson = JSON.parse(this.codingDetail.inspections[0].jsonData);
    // fill fields
    this.fillGenericWorkFields();
    this.$forceUpdate();
  }

  fillGenericWorkFields(): void {
    this.genericWorkFields = {
      workOrderNumber: this?.item?.workOrderNumber ?? '',
      name: this.item?.nodes?.name ?? '',
      dateIssued: this.workOrderJson
        ? this.formatDate(this.workOrderJson.CreatedDate)
        : '',
      planDueDate: this.workOrderJson
        ? this.formatDate(this.workOrderJson.AssignDate)
        : '',
      assignedTo: '', // ?
      priority: this?.item?.priorityDescription ?? '',
      activity: '', // ?
      status: this?.item?.status ?? '',
      workDescription: this?.workOrderJson?.WorkPerformed ?? '',
      comments: this?.workOrderJson?.Comments ?? '',
      specialInstructions: '', // ?
      dateCompleted: this.workOrderJson
        ? this.formatDate(this.workOrderJson.InspDate)
        : '',
      specialConditions: '', // ?
      streetAddress: this?.workOrderJson?.Address ?? '',
      notCompletedWhy:
        this.item.status === 'Incomplete' && this.item?.taskResultDesc
          ? this.item.taskResultDesc
          : '',
      followUp: this?.workOrderJson?.FollowUp ?? '',
    };
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }

    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    const year = date.getFullYear();
    const month = 1 + date.getMonth();
    const day = date.getDate();

    return `${month}/${day}/${year}`;
  }
}
