

















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({})
export default class Legend extends Vue {
  legendItems = {
    score: [{
      category: 'NASSCO Scoring Legend',
      items: [
        { label: '0', color: '#008000' },
        { label: '1', color: '#008000' },
        { label: '2', color: '#008000' },
        { label: '3', color: '#FFFF00' },
        { label: '4', color: '#FFA500' },
        { label: '5', color: '#FF0000' },
      ],
    }],
    collection: [{
      category: 'Collection Status',
      items: [
        { label: 'No Collection', color: '#0C6599' },
        { label: 'Completed', color: '#008000' },
        { label: 'Traffic Control', color: '#6A0DAD' },
        { label: 'MSA/Reversal Required', color: '#FFFF00' },
        { label: 'CNL/CNA/CNO/DNE/Failed', color: '#FF0000' },
        { label: 'Component Collected', color: '#33E0FF' },
      ],
    }],
  };

  menu = false;

  selectedLegend = this.legendItems['score'];

  setLegend(legendValue: string): void{
    this.selectedLegend = this.legendItems[legendValue];
  }
}
