import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { ExportState, ExportPayload } from './types';
import { ExportMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum ExportActions {
    POST_INSPECTION_REPORTS = 'POST_INSPECTION_REPORTS',
    CLEAR_EXPORT_DATA = 'CLEAR_EXPORT_DATA',
}

export const actions: ActionTree<ExportState, RootState> = {

  async [ExportActions.POST_INSPECTION_REPORTS]({ commit },
    exportPayload:ExportPayload): Promise<void> {
    commit(ExportMutations.SET_LOADING_STATE, true);
    commit(ExportMutations.SET_LOAD_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postExportInspections(exportPayload);
      commit(ExportMutations.SET_EXPORT_DATA, response.data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response) {
        commit(ExportMutations.SET_LOAD_ERROR, e.response.data.Message);
      }
      throw Error('API Error occurred.');
    } finally {
      commit(ExportMutations.SET_LOADING_STATE, false);
    }
  },
  async [ExportActions.CLEAR_EXPORT_DATA]({ commit }): Promise<void> {
    commit(ExportMutations.SET_LOADING_STATE, true);
    try {
      commit(ExportMutations.CLEAR_EXPORT_DATA);
    } catch (e) {
      commit(ExportMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ExportMutations.SET_LOADING_STATE, false);
    }
  },
};
