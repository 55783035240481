










/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectListing } from '@/store/project/types';
import { ProjectActions } from '@/store/project/actions';
import AdminPanelMixin, { EditUserData } from '../AdminPanelMixin.vue';
import ProjectList from '../User/ProjectList.vue';

const projectModule = namespace('project');

@Component({
  components: {
    ProjectList,
  },
})
export default class EditUserProject extends AdminPanelMixin {
  @PropSync('selectedUser') readonly synchedSelectedUser: EditUserData;

  selectedProjectName = '';

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  @projectModule.Action(ProjectActions.FETCH_ALL_PROJECTS_DATA)
  fetchAllProjectsData;

  mounted(): void {
    if (!this.allProjects) {
      this.fetchAllProjectsData().catch(() => {
        this.$router.push({
          name: 'Error',
          params: {
            catchAll: 'Error',
            message:
              'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.',
          },
        });
      });
    }
  }
}
