import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { ExportState, AccessPayload } from './types';
import { ExportMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum ExportActions {
    POST_NASSCO_DB = 'POST_NASSCO_DB',
    CLEAR_EXPORT_DATA = 'CLEAR_EXPORT_DATA',
}

export const actions: ActionTree<ExportState, RootState> = {

  async [ExportActions.POST_NASSCO_DB]({ commit },
    payload: AccessPayload): Promise<void> {
    commit(ExportMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postAccessDB(payload);
      commit(ExportMutations.SET_EXPORT_DATA, response.data);
    } catch (e) {
      commit(ExportMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ExportMutations.SET_LOADING_STATE, false);
    }
  },
  async [ExportActions.CLEAR_EXPORT_DATA]({ commit }): Promise<void> {
    commit(ExportMutations.SET_LOADING_STATE, true);
    try {
      commit(ExportMutations.CLEAR_EXPORT_DATA);
    } catch (e) {
      commit(ExportMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ExportMutations.SET_LOADING_STATE, false);
    }
  },
};
