









/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js';
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import { Component, Prop, Vue } from 'vue-property-decorator';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Roboto';

@Component({ components: { LineChartGenerator } })
export default class ProjectOverviewBurndownGraph extends Vue {
    @Prop() readonly chartData: any;

    @Prop() readonly chartOptions: any;

    @Prop({ default: 'label' }) readonly datasetIdKey: string;

    chartId = 'projectOverviewChart';

    plugins = [];
}
