import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { UserManagement, UserState } from './types';
import { UserMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum UserActions {
    FETCH_ALL_USER_DATA = 'FETCH_ALL_USER_DATA',
    FETCH_ALL_DETAILED_USER_DATA = 'FETCH_ALL_DETAILED_USER_DATA',
    FETCH_INACTIVE_DETAILED_USER_DATA = 'FETCH_INACTIVE_DETAILED_USER_DATA',
    POST_CUSTOMER_USER = 'POST_CUSTOMER_USER',
    POST_CUSTOMER_ORGANIZATION = 'POST_CUSTOMER_ORGANIZATION',
    POST_USER_MANAGEMENT = 'POST_USER_MANAGEMENT',
    FETCH_ALL_USER_MANAGEMENTS = 'FETCH_ALL_USER_MANAGEMENTS',
    DELETE_USER_MANAGEMENT = 'DELETE_USER_MANAGEMENT',
}

export const actions: ActionTree<UserState, RootState> = {

  async [UserActions.FETCH_ALL_USER_DATA]({ commit }): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAllUsers();

      commit(UserMutations.SET_ALL_USER_DATA, response.data);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },

  async [UserActions.FETCH_ALL_DETAILED_USER_DATA]({ commit }): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAllDetailedUsers();

      commit(UserMutations.SET_ALL_DETAILED_USER_DATA, response.data);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },

  async [UserActions.FETCH_INACTIVE_DETAILED_USER_DATA]({ commit }): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getInactiveDetailedUsers();

      commit(UserMutations.SET_INACTIVE_DETAILED_USER_DATA, response.data);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },

  async [UserActions.POST_CUSTOMER_USER](
    { commit },
    payload: {customerGuid: string, userGuid: string},
  ): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postCustomerUser(payload);

      commit(UserMutations.SET_RESPONSE, response);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },

  async [UserActions.POST_CUSTOMER_ORGANIZATION](
    { commit },
    payload: {organizationGuid: string, userGuid: string },
  ): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postOrganizationUser(payload);

      commit(UserMutations.SET_RESPONSE, response);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },

  async [UserActions.FETCH_ALL_USER_MANAGEMENTS]({ commit }): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.fetchUserManagements();

      commit(UserMutations.SET_USER_MANAGEMENTS, response.data.data);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },

  async [UserActions.POST_USER_MANAGEMENT]({ commit }, payload: UserManagement): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postUserManagement(payload);

      commit(UserMutations.SET_RESPONSE, response);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },

  async [UserActions.DELETE_USER_MANAGEMENT]({ commit }, payload: string[]): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.deleteUserManagement(payload);

      commit(UserMutations.SET_RESPONSE, response);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },
};
