
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ImportMappingContainer } from '../agol/types';
import { ProjectData } from './types';
import { ProjectActions } from './actions';

const projectModule = namespace('project');

@Component
export default class ProjectStoreMixin extends Vue {
    @projectModule.Action(ProjectActions.GET_AGOL_MAPPING_DATA) getAgolMappings;

    @projectModule.Action(ProjectActions.POST_AGOL_MAPPING_DATA) postAgolMappings;

    @projectModule.Action(ProjectActions.PATCH_AGOL_MAPPING_DATA) patchAgolMappings;

    @projectModule.Action(ProjectActions.DELETE_AGOL_MAPPING_DATA) deleteAgolMappings;

    @projectModule.Action(ProjectActions.FETCH_PROJECT_DATA) fetchProjectData;

    @projectModule.Action(ProjectActions.TURN_OFF_AGOL_MAPPING) turnOffAgolMapping;

    @projectModule.State('data') project: ProjectData | undefined;

    @projectModule.State('agolMappingData') agolMappingData: ImportMappingContainer[] | undefined;

    @projectModule.State('agolMappingDataError') agolMappingDataError: string | undefined;

    @projectModule.State('agolMappingDataLoading') agolMappingDataLoading: boolean;

    @projectModule.State('postAgolMappingDataError') postAgolMappingDataError: string | undefined;

    @projectModule.State('postAgolMappingDataLoading') postAgolMappingDataLoading: boolean;

    @projectModule.State('patchAgolMappingDataError') patchAgolMappingDataError: string | undefined;

    @projectModule.State('patchAgolMappingDataLoading') patchAgolMappingDataLoading: boolean;

    @projectModule.State('deleteAgolMappingDataError') deleteAgolMappingDataError: string | undefined;

    @projectModule.State('deleteAgolMappingDataLoading') deleteAgolMappingDataLoading: boolean;

    @projectModule.State('turnOffAgolMappingLoading') turnOffAgolMappingLoading: boolean;

    @projectModule.State('turnOffAgolMappingError') turnOffAgolMappingError: string | undefined;
}
