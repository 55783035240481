


































































import { InspectionData, IPDEntry } from '@/store/asset/types';
import Vue from 'vue';
import {
  Component, Emit, Prop, Watch,
} from 'vue-property-decorator';
import { ChartData } from 'chart.js';
import { CrossSectionData } from '@/store/crossSections/types';
import { namespace } from 'vuex-class';
import { FlatGraphActions } from '@/store/flatGraph/actions';
import { LineGraphActions } from '@/store/lineGraph/actions';
import { WallLossActions } from '@/store/wallLoss/actions';
import { GasTempData, LineGraphData } from '@/store/lineGraph/types';
import { WallLossData } from '@/store/wallLoss/types';
import LineChart from './LineChart.vue';
import Doughnut from './Doughnut.vue';
import Distribution from './Distribution.vue';
import util from '../../views/Asset/utils';

const flatGraphModule = namespace('flatGraph');
const lineGraphModule = namespace('lineGraph');
const wallLossModule = namespace('wallLoss');
const msiPayoutModule = namespace('msiPayout');

@Component({ components: { LineChart, Doughnut, Distribution } })
export default class MSIGraph extends Vue {
  @Prop() readonly ipd!: IPDEntry[];

  @Prop() readonly inspection!: InspectionData;

  @Prop() readonly currentVideoPercent!: number;

  @Prop() readonly fullVideoTime!: number;

  @Prop() readonly maxDepth!: number;

  @Prop() readonly crossSectionData: CrossSectionData[] | undefined;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @flatGraphModule.Action(FlatGraphActions.GET_FLAT_GRAPH) getFlatGraph;

  @flatGraphModule.State('data') flatGraphUrl: string | undefined;

  @flatGraphModule.State('loading') flatGraphLoading: string | undefined;

  @lineGraphModule.Action(LineGraphActions.GET_LINE_GRAPH_DATA) getLineGraphData;

  @lineGraphModule.State('lineGraphData') lineGraphData: LineGraphData | undefined;

  @lineGraphModule.Action(LineGraphActions.GET_GAS_TEMP_DATA) getGasTempData;

  @lineGraphModule.State('gasTempData') gasTempData: GasTempData | undefined;

  @lineGraphModule.State('loading') lineGraphLoading: boolean | undefined;

  @wallLossModule.Action(WallLossActions.GET_WALL_LOSS_DATA) getWallLossData;

  @wallLossModule.State('wallLossData') wallLossData: WallLossData | undefined;

  @msiPayoutModule.State('currentPayout') currentPayout: IPDEntry | undefined;

  waterLevelArray = [];

  debrisArray = [];

  xArray = [];

  yArray = [];

  pipeDiameterArray = [];

  corrosionArray = [];

  distanceArray = [];

  ovalitiesArray = [];

  distancesArray = [];

  dataLoaded = false;

  chartHeight = 0;

  selectedGraph = 'Flat';

  graphOptions = ['Flat', 'Debris / Water', 'XY', 'Max Corrosion'];

  flatRetData = null;

  debrisWaterRetData = null;

  corrosionRetData = null

  xyRetData = null;

  ovalityRetData = null;

  gasTempRetData = null;

  maxYValue = undefined;

  secondaryMaxYValue = undefined;

  // Estimate of where the red bar should start on the flat graph in order to account for
  // whitespace and labels on the left hand side of the image
  leftLimitPercent = 6 / 100;

  markerStyle = '';

  regenerateGraphsFlag = false;

  maxCrossSectionDepth = 0;

  minCrossSectionDepth = 0;

  // scrubbing = false;

  mounted(): void {
    this.getFlatGraph(this.inspection.guid);
    this.getLineGraphData(this.inspection.guid);
    this.getGasTempData(this.inspection.guid);
    this.getWallLossData(this.inspection.guid);
    if (this.crossSectionData && !this.dataLoaded) {
      this.onCrossSectionDataChange();
    }

    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.updateMarker();
      }, 100);
    });
  }

  @Watch('selectedGraph')
  onSelectedGraphChange(): void {
    const graphContainer = document.querySelector('#graphs-container') as HTMLElement;
    const graphSelector = document.querySelector('#graph-select-container') as HTMLElement;

    if (graphContainer) {
      const xLabelHeight = 50;
      this.chartHeight = graphContainer.offsetHeight - graphSelector.offsetHeight - xLabelHeight;
    }
    this.regenerateGraphsFlag = true;
  }

  @Watch('crossSectionData')
  onCrossSectionDataChange(): void {
    this.createGraphData();
    this.getMinAndMaxDepth();
    this.dataLoaded = true;
  }

  @Watch('displayImperial')
  onDisplayImperialChange(): void{
    this.regenerateGraphsFlag = true;
    this.createGraphData();
  }

  @Watch('currentVideoPercent')
  onVideoPercentChange(): void {
    this.updateMarker();
  }

  @Watch('lineGraphData')
  onLineGraphDataChange(): void{
    if (this.lineGraphData?.ovalities.some((item) => item)) {
      for (let i = 0; i < this.lineGraphData.ovalities.length - 1; i += 1) {
        if (i % 15 === 0) {
          this.ovalitiesArray.push(this.lineGraphData.ovalities[i]);
          this.distancesArray.push(this.lineGraphData.distances[i]);
        }
      }
      this.graphOptions.push('Ovality');
      this.$forceUpdate();
    }
  }

  @Watch('gasTempData')
  onGasTempDataChange(): void{
    if (this.gasTempData?.distances.some((item) => item)) {
      this.graphOptions.push('Gas / Temp');
      this.$forceUpdate();
    }
  }

  @Watch('wallLossData')
  onWallLossDataChange(): void {
    if (this.wallLossData.corrosionPieValues?.some((item) => item)) {
      this.graphOptions.push('Wall Loss');
      this.graphOptions.push('Corrosion Distribution');
      this.$forceUpdate();
    }
  }

  createGraphData(): void{
    this.distanceArray = [];
    this.waterLevelArray = [];
    this.debrisArray = [];
    this.xArray = [];
    this.yArray = [];
    this.pipeDiameterArray = [];
    this.corrosionArray = [];

    let pointCount = 100;

    this.crossSectionData.forEach((element) => {
      if (pointCount < 15) {
        pointCount += 1;
        return;
      }
      pointCount = 0;
      this.distanceArray.push(util
        .getDisplayDistanceFtM(this.displayImperial, element.distance));

      this.waterLevelArray.push({
        x: util.getDisplayDistanceFtM(this.displayImperial, element.distance),
        y: util.getDisplayDistanceInMM(this.displayImperial, element.waterLevel),
      });

      this.debrisArray.push({
        x: util.getDisplayDistanceFtM(this.displayImperial, element.distance),
        y: util.getDisplayDistanceInMM(this.displayImperial, element.debris),
      });

      this.xArray.push(util
        .getDisplayDistanceInMM(this.displayImperial, element.xDiameter));

      this.yArray.push(util
        .getDisplayDistanceInMM(this.displayImperial, element.yDiameter));

      this.pipeDiameterArray.push(util
        .getDisplayDistanceInMM(this.displayImperial, element.trueDiameter));

      if (element.maxCorrosion >= 0) {
        this.corrosionArray.push(util
          .getDisplayDistanceInMM(this.displayImperial, element.maxCorrosion));
      }
    });
  }

  get depthPercentage(): number {
    return (this.currentPayout.payout / 100) > this.minCrossSectionDepth
      ? ((
        (this.currentPayout.payout / 100) - this.minCrossSectionDepth
      ) / (
        this.maxCrossSectionDepth - this.minCrossSectionDepth)
      ) * 100 : 0.0;
  }

  get miscGraphDepthPercentage(): number {
    return ((this.currentPayout.payout / 100) / this.maxDepth) * 100;
  }

  get filteredGraphOptions(): string[] {
    return this.graphOptions;
  }

  get chartData(): ChartData {
    if (this.regenerateGraphsFlag) {
      this.maxYValue = undefined;
      this.secondaryMaxYValue = undefined;
    }

    if (!this.dataLoaded) {
      return {} as ChartData;
    }

    if (this.selectedGraph === 'Debris / Water') {
      if (this.debrisWaterRetData !== null && this.regenerateGraphsFlag === false) {
        const dataSetArr = this.debrisWaterRetData.datasets;
        const xPos = this.updateVideoPositionX();
        dataSetArr[dataSetArr.length - 1].data[0].x = xPos;
        dataSetArr[dataSetArr.length - 1].data[1].x = xPos;
        return this.debrisWaterRetData;
      }
      this.maxYValue = util
        .getDisplayDistanceInMM(this.displayImperial, this.crossSectionData[0].refrenceDiameter);
      this.debrisWaterRetData = {
        datasets: [
          {
            label: 'Water Level',
            borderColor: 'blue',
            data: this.waterLevelArray,
            parsing: false,
            animation: false,
            pointHitRadius: 2,
            pointHoverRadius: 4,
          },
          {
            label: 'Debris Level',
            borderColor: '#e61e25',
            data: this.debrisArray,
            parsing: false,
            animation: false,
            pointHitRadius: 2,
            pointHoverRadius: 4,
          },
          {
            label: 'Video Position',
            borderColor: '#e61e25',
            data: [
              {
                x: this.currentPayout.payout / 100,
                y: 0,
              },
              {
                x: this.currentPayout.payout / 100,
                y: this.maxYValue,
              },
            ],
            pointRadius: 0,
            pointHitRadius: 0,
            parsing: false,
            animation: true,
          },

        ],
      };
      this.regenerateGraphsFlag = false;
      return this.debrisWaterRetData;
    }
    if (this.selectedGraph === 'Ovality') {
      const convertedDistance = this.distancesArray.map(
        (dist) => util.getDisplayDistanceFtM(this.displayImperial, dist),
      );
      if (this.ovalityRetData !== null && this.regenerateGraphsFlag === false) {
        const dataSetArr = this.ovalityRetData.datasets;
        const xPos = (Math.ceil(
          (convertedDistance[convertedDistance.length - 1] + 1)
                   / 10,
        ) * 10) * (this.miscGraphDepthPercentage / 100);
        dataSetArr[dataSetArr.length - 1].data[0].x = xPos;
        dataSetArr[dataSetArr.length - 1].data[1].x = xPos;
        return this.ovalityRetData;
      }
      const limit = 5;
      const ovalityMaxYLimit = 12;
      this.maxYValue = Math.min(...this.lineGraphData.ovalities, ovalityMaxYLimit);
      if (this.maxYValue < limit) {
        this.maxYValue = undefined;
      }
      this.ovalityRetData = {
        labels: convertedDistance,
        datasets: [
          {
            label: 'Ovality',
            borderColor: 'blue',
            data: this.ovalitiesArray,
            animation: false,
            pointHitRadius: 2,
            pointHoverRadius: 4,
          },
          {
            label: 'Limit',
            borderColor: '#e61e25',
            data: [
              {
                x: 0,
                y: limit,
              },
              {
                // Round up to nearest 10 to fit graph better
                x: Math.ceil(
                  (convertedDistance[convertedDistance.length - 1] + 1)
                   / 10,
                ) * 10,
                y: limit,
              }],
            pointRadius: 0,
            pointHitRadius: 0,
            animation: false,
          },
          {
            label: 'Video Position',
            borderColor: '#e61e25',
            data: [
              {
                x: (Math.ceil(
                  (convertedDistance[convertedDistance.length - 1] + 1)
                   / 10,
                ) * 10) * (this.miscGraphDepthPercentage / 100),
                y: 0,
              },
              {
                x: (Math.ceil(
                  (convertedDistance[convertedDistance.length - 1] + 1)
                   / 10,
                ) * 10) * (this.miscGraphDepthPercentage / 100),
                y: this.maxYValue ? this.maxYValue : 10,
              },
            ],
            pointRadius: 0,
            pointHitRadius: 0,
            parsing: false,
            animation: true,
          },
        ],
      };
      this.regenerateGraphsFlag = false;
      return this.ovalityRetData;
    }
    if (this.selectedGraph === 'Gas / Temp') {
      if (this.gasTempRetData !== null && this.regenerateGraphsFlag === false) {
        const dataSetArr = this.gasTempRetData.datasets;
        const xPos = this.updateVideoPositionX();
        dataSetArr[dataSetArr.length - 1].data[0].x = xPos;
        dataSetArr[dataSetArr.length - 1].data[1].x = xPos;
        return this.gasTempRetData;
      }
      const convertedDistance = this.gasTempData.distances.map(
        (dist) => util.getDisplayDistanceFtM(this.displayImperial, dist),
      );
      const convertedTemps = this.gasTempData.temperatureMeasurements.map(
        (temp) => util.getDisplayTemperatureFtC(this.displayImperial, temp),
      );
      this.maxYValue = Math.max(...this.gasTempData.gasMeasurements) + 10;
      this.secondaryMaxYValue = Math.max(...convertedTemps) + 10;
      this.gasTempRetData = {
        labels: convertedDistance,
        datasets: [
          {
            label: 'Gas',
            yAxisID: 'y',
            borderColor: 'blue',
            data: this.gasTempData.gasMeasurements,
            animation: false,
            pointHitRadius: 2,
            pointHoverRadius: 4,
          },
          {
            label: 'Temperature',
            yAxisID: 'y1',
            borderColor: '#e61e25',
            data: convertedTemps,
            animation: false,
            pointHitRadius: 2,
            pointHoverRadius: 4,
          },
          {
            label: 'Video Position',
            borderColor: '#e61e25',
            data: [
              {
                x: this.distanceArray[this.distanceArray.length - 1]
                  * (this.miscGraphDepthPercentage / 100),
                y: 0,
              },
              {
                x: this.distanceArray[this.distanceArray.length - 1]
                  * (this.miscGraphDepthPercentage / 100),
                y: this.maxYValue > this.secondaryMaxYValue
                  ? this.maxYValue : this.secondaryMaxYValue,
              },
            ],
            pointRadius: 0,
            pointHitRadius: 0,
            parsing: false,
            animation: true,
          },
        ],
      };
      this.regenerateGraphsFlag = false;
      return this.gasTempRetData;
    }
    if (this.selectedGraph === 'XY') {
      if (this.xyRetData !== null && this.regenerateGraphsFlag === false) {
        const dataSetArr = this.xyRetData.datasets;
        const xPos = this.updateVideoPositionX();
        dataSetArr[dataSetArr.length - 1].data[0].x = xPos;
        dataSetArr[dataSetArr.length - 1].data[1].x = xPos;
        return this.xyRetData;
      }
      const pipeDiameter = util
        .getDisplayDistanceInMM(this.displayImperial, this.crossSectionData[0].refrenceDiameter);
      this.xyRetData = {
        labels: this.distanceArray,
        datasets: [
          {
            label: 'Pipe Diameter',
            borderColor: 'grey',
            data: [
              {
                x: 0,
                y: pipeDiameter,
              },
              {
                // Round up to nearest 10 to fit graph better
                x: Math.ceil(
                  (this.distanceArray[this.distanceArray.length - 1] + 1)
                   / 10,
                ) * 10,
                y: pipeDiameter,
              }],
            pointRadius: 0,
            pointHitRadius: 0,
            animation: false,
          },
          {
            label: 'X Measurement',
            borderColor: 'blue',
            data: this.xArray,
            animation: false,
            pointHitRadius: 2,
            pointHoverRadius: 4,
          },
          {
            label: 'Y Measurement',
            borderColor: '#e61e25',
            data: this.yArray,
            animation: false,
            pointHitRadius: 2,
            pointHoverRadius: 4,
          },
          {
            label: 'Video Position',
            borderColor: '#e61e25',
            data: [
              {
                x: this.distanceArray[this.distanceArray.length - 1]
                  * (this.miscGraphDepthPercentage / 100),
                y: 0,
              },
              {
                x: this.distanceArray[this.distanceArray.length - 1]
                  * (this.miscGraphDepthPercentage / 100),
                y: pipeDiameter * 2,
              },
            ],
            pointRadius: 0,
            pointHitRadius: 0,
            parsing: false,
            animation: true,
          },
        ],
      };
      this.regenerateGraphsFlag = false;
      return this.xyRetData;
    }
    if (this.selectedGraph === 'Max Corrosion') {
      if (this.corrosionRetData !== null && this.regenerateGraphsFlag === false) {
        const dataSetArr = this.corrosionRetData.datasets;
        const xPos = this.updateVideoPositionX();
        dataSetArr[dataSetArr.length - 1].data[0].x = xPos;
        dataSetArr[dataSetArr.length - 1].data[1].x = xPos;
        return this.corrosionRetData;
      }
      this.maxYValue = Math.max(...this.corrosionArray)
      + util.getDisplayDistanceInMM(this.displayImperial, 1);
      this.corrosionRetData = {
        labels: this.distanceArray,
        datasets: [
          {
            label: 'Max Corrosion',
            borderColor: '#e61e25',
            data: this.corrosionArray,
            animation: false,
            pointHitRadius: 2,
            pointHoverRadius: 4,
          },
          {
            label: 'Video Position',
            borderColor: '#e61e25',
            data: [
              {
                x: this.distanceArray[this.distanceArray.length - 1]
                  * (this.currentVideoPercent / 100),
                y: 0,
              },
              {
                x: this.distanceArray[this.distanceArray.length - 1]
                  * (this.currentVideoPercent / 100),
                y: this.maxYValue,
              },
            ],
            pointRadius: 0,
            pointHitRadius: 0,
            parsing: false,
            animation: true,
          },
        ],
      };
      this.regenerateGraphsFlag = false;
      return this.corrosionRetData;
    }
    if (this.selectedGraph === 'Wall Loss') {
      return {
        labels: ['0-15%', '16-33%', '34-50%', '>50%'],
        datasets: [
          {
            backgroundColor: ['#f0f0f0', '#fee599', '#f7b16a', '#e06766'],
            data: this.wallLossData.corrosionPieValues,
          },
        ],
      };
    }
    if (this.selectedGraph === 'Corrosion Distribution') {
      return {
        labels: this.wallLossData.corrosionWallPercentages,
        datasets: [
          {
            label: 'Corrosion Distribution %',
            borderColor: 'black',
            pointRadius: 0,
            pointHitRadius: 4,
            backgroundColor: 'rgba(160,190,211,.7)',
            fill: true,
            data: this.wallLossData.corrosionPercentages,
          },
        ],
      };
    }
    return {} as ChartData;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateVideoPositionX(): number {
    return this.currentPayout.payout / 100;
  }

  updateMarker(): void {
    try {
      const { height } = document.getElementById('graph') as HTMLImageElement;
      // if current depth is lower than cross section minimum hold marker at 0%
      const IMAGE_HEIGHT = 492;
      const heightRatio = height / IMAGE_HEIGHT;
      const top = `top: ${24 * heightRatio}px;`;
      const heightOut = 'height: 82.9%;';
      this.markerStyle = `left: calc(${this.depthPercentage}%); ${top} ${heightOut}`;
    } catch {
      this.markerStyle = '';
    }
  }

  /* scrub is currently inconsistent, we should take a closer look when it's not a jpg */
  // handleScrub(event: MouseEvent): void {
  //   if (this.scrubbing) {
  //     const percent = this.mousePositionToPercent(event);
  //   }
  // }

  handleFlatClick(event: MouseEvent): void {
    if (this.ipd.length > 0) {
      const cColumn = this.$refs.flatGraphTimeline as Element;
      const { width, x } = cColumn.getBoundingClientRect();
      const relX = event.clientX - x;
      const percent = (relX / width);
      this.onPayoutRequest(this.getDesiredDepth(percent));
    }
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  handleClick(event: any): void {
    if (this.ipd.length > 0) {
      const trackingbar = event.x - event.chart.chartArea.left;
      const width = event.chart.chartArea.right - event.chart.chartArea.left;
      const percent = (trackingbar / width);
      this.onPayoutRequest(this.getDesiredDepth(percent));
    }
  }

  getDesiredDepth(percent: number): number {
    const desiredDepth = (
      (this.maxCrossSectionDepth - this.minCrossSectionDepth) * percent
    ) + this.minCrossSectionDepth;
    return desiredDepth > 0 ? desiredDepth : 0;
  }

  getMinAndMaxDepth(): void {
    const { crossSectionData } = this;
    if (crossSectionData == null || crossSectionData.length === 0) {
      this.minCrossSectionDepth = 0;
      this.maxCrossSectionDepth = 1;
    }
    let maxDepth = crossSectionData[0].distance;
    let minDepth = crossSectionData[0].distance;
    // eslint-disable-next-line no-unused-expressions
    crossSectionData.forEach((val) => {
      if (val.distance > maxDepth) maxDepth = val.distance;
      if (val.distance < minDepth) minDepth = val.distance;
    });
    this.minCrossSectionDepth = minDepth;
    this.maxCrossSectionDepth = maxDepth;
  }

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  onPayoutRequest(payout: number): number {
    return payout;
  }
}
