var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-container"},[_c('div',{staticClass:"types-menu"},[_c('v-card',[_c('v-card-title',{attrs:{"id":"menu-title"}},[_vm._v(" REPORTS ")]),_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"menu-btn-selected"},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}},_vm._l((_vm.reportTypeOptions),function(item,i){return _c('v-list-item',{key:i,staticClass:"list-item",attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1),_c('v-card-title',{attrs:{"id":"menu-title"}},[_vm._v(" ADDITIONAL INFO ")]),_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"menu-btn-selected"},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}},[_c('v-list-item',{attrs:{"value":"Other"}},[_vm._v(" Other ")])],1)],1)],1)],1),(_vm.selectedType === 'MSI' && _vm.cannedPDFUri)?_c('iframe',{attrs:{"src":_vm.cannedPDFUri,"id":"pdf-frame"}}):_c('div',{staticClass:"report-container"},[_c('div',{staticClass:"report-layout"},[(_vm.reportTypeOptions.indexOf(_vm.selectedType) > -1 && _vm.canExport)?_c('v-btn',{ref:"export-btn",attrs:{"id":"export-btn","loading":!_vm.hasDownloaded},on:{"click":_vm.generateReport}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1):_vm._e(),(_vm.reportTypeOptions.indexOf(_vm.selectedType) > -1)?_c('vue-html2pdf',{ref:"html2Pdf",staticClass:"vue-html",attrs:{"show-layout":true,"float-layout":false,"enable-download":false,"preview-modal":true,"filename":"report","pdf-quality":2,"manual-pagination":true,"pdf-format":"letter","pdf-orientation":"portrait","pdf-content-width":"100%","paginate-elements-by-height":1300,"html-to-pdf-options":{
          pagebreak: {
            mode: 'legacy',
          },
          margin: [1, 0, 1, 0],
          filename: 'report.pdf',
          image: {
            type: 'jpeg',
            quality: 1
          },
          html2canvas: {
            scale: 2,
            useCORS: true,
            allowTaint: true,
            scrollX: 0,
            scrollY: 0
          },
        }},on:{"hasDownloaded":function($event){_vm.hasDownloaded = true}}},[(_vm.selectedType === 'MACP 7.0')?_c('MACP',{attrs:{"slot":"pdf-content","inspection":_vm.inspectionData,"assetName":_vm.asset.name,"asset":_vm.asset,"displayImperial":_vm.displayImperial,"fullVideoTime":_vm.fullVideoTime},slot:"pdf-content"}):_vm._e(),(_vm.selectedType === 'Observation')?_c('ObservationFullReport',{attrs:{"slot":"pdf-content","inspection":_vm.inspectionData,"assetType":_vm.asset.type,"assetName":_vm.asset.name,"asset":_vm.asset,"displayImperial":_vm.displayImperial,"fullVideoTime":_vm.fullVideoTime},slot:"pdf-content"}):_vm._e()],1):_vm._e(),(_vm.selectedType === 'Other')?_c('OtherInfoReport',{staticClass:"vue-html",attrs:{"robotSerialNumber":_vm.inspectionData['robot serial number'],"robotDetail":_vm.asset.robotDetail}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }