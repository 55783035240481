var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataLoaded)?_c('div',[_c('v-btn',{ref:"open-pdf-btn",attrs:{"id":"open-pdf-btn","outlined":"","color":"#0c6599"},on:{"click":_vm.openDrawer}},[_c('v-icon',{staticClass:"asset-icon",attrs:{"left":""}},[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1),_c('SideDrawer',{attrs:{"drawerOpen":_vm.drawerOpen},on:{"update:drawerOpen":function($event){_vm.drawerOpen=$event},"update:drawer-open":function($event){_vm.drawerOpen=$event}}},[_c('v-btn',{ref:"export-btn",attrs:{"id":"export-btn","loading":!_vm.hasDownloaded},on:{"click":_vm.generateReport}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1),_c('div',{attrs:{"id":"pdfContainer"}},[_c('vue-html2pdf',{ref:"customerDashboardHtml2Pdf",staticClass:"vue-html",attrs:{"show-layout":true,"float-layout":false,"enable-download":false,"preview-modal":true,"filename":'System Assessment ' + _vm.projectName,"pdf-quality":2,"manual-pagination":true,"pdf-format":"letter","pdf-orientation":"portrait","pdf-content-width":"100%","html-to-pdf-options":{
            margin: [1, 0, 1, 0],
            filename: 'report.pdf',
            image: {
              type: 'jpeg',
              quality: 1,
            },
            html2canvas: {
              scale: 2,
              useCORS: true,
              allowTaint: true,
              scrollX: 0,
              scrollY: 0,
            },
          }},on:{"hasDownloaded":function($event){_vm.hasDownloaded = true}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('section',{staticClass:"pdf-item"},[_c('CustomerDashboardPagePdf',{attrs:{"type":'Manhole',"reportType":_vm.reportType,"projectName":_vm.projectName}})],1),_c('div',{staticClass:"html2pdf__page-break"}),_c('section',{staticClass:"pdf-item"},[_c('CustomerDashboardPagePdf',{attrs:{"type":'Line Segments',"reportType":_vm.reportType,"projectName":_vm.projectName}})],1)])])],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }