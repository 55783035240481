













































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import utils from '@/components/IntegrityTable/utils';
import { AssetCountsMetrics } from '@/store/metrics/types';

@Component({
  components: {
  },
})
export default class CustomerDashboardCollectionTable extends Vue {
  @Prop() readonly assetCountsMetrics: AssetCountsMetrics | undefined;

  @Prop() readonly displayImperial: boolean;

  @Prop({ default: false }) readonly isCustomerView: boolean;

  getConvertedDistance(distance: number): string {
    return utils.getConvertedDistance(distance, this.displayImperial);
  }

  get assetCountsMetricsManholeTotal(): number {
    return this.assetCountsMetrics?.manholes?.total != null
      ? this.assetCountsMetrics.manholes.total : 0;
  }

  get assetCountsMetricsManholeAddressed(): number {
    return this.assetCountsMetrics?.manholes?.addressed != null
      ? this.assetCountsMetrics.manholes.addressed : 0;
  }

  get assetCountsMetricsManholeCollected(): number {
    return this.assetCountsMetrics?.manholes?.collected != null
      ? this.assetCountsMetrics.manholes.collected : 0;
  }

  get assetCountsMetricsManholeDelivered(): number {
    return this.assetCountsMetrics?.manholes?.delivered != null
      ? this.assetCountsMetrics.manholes.delivered : 0;
  }

  get assetCountsMetricsLineSegmentTotal(): number {
    return this.assetCountsMetrics?.lineSegments?.total != null
      ? this.assetCountsMetrics.lineSegments.total : 0;
  }

  get assetCountsMetricsLineSegmentAddressed(): number {
    return this.assetCountsMetrics?.lineSegments?.addressed != null
      ? this.assetCountsMetrics.lineSegments.addressed : 0;
  }

  get assetCountsMetricsLineSegmentCollected(): number {
    return this.assetCountsMetrics?.lineSegments?.collected != null
      ? this.assetCountsMetrics.lineSegments.collected : 0;
  }

  get assetCountsMetricsLineSegmentDelivered(): number {
    return this.assetCountsMetrics?.lineSegments?.delivered != null
      ? this.assetCountsMetrics.lineSegments.delivered : 0;
  }

  get assetCountsMetricsGPSTotal(): number {
    return this.assetCountsMetrics?.gps?.total != null
      ? this.assetCountsMetrics.gps.total : 0;
  }

  get assetCountsMetricsGPSAddressed(): number {
    return this.assetCountsMetrics?.gps?.addressed != null
      ? this.assetCountsMetrics.gps.addressed : 0;
  }

  get assetCountsMetricsGPSCollected(): number {
    return this.assetCountsMetrics?.gps?.collected != null
      ? this.assetCountsMetrics.gps.collected : 0;
  }

  get assetCountsMetricsGPSDelivered(): number {
    return this.assetCountsMetrics?.gps?.delivered != null
      ? this.assetCountsMetrics.gps.delivered : 0;
  }

  getAssetCountsMetricsRemaining(assetType: string): number {
    if (!this.assetCountsMetrics || !(assetType in this.assetCountsMetrics)) {
      return 0;
    }
    const assets = this.assetCountsMetrics[assetType];
    const { total } = assets ?? 0;
    const done = assets ? assets.addressed : 0;

    return total - done < 0 ? 0 : total - done;
  }

  getAssetCountsIssues(assetType: string): number {
    if (!this.assetCountsMetrics || !(assetType in this.assetCountsMetrics)) {
      return 0;
    }
    const assets = this.assetCountsMetrics[assetType];
    const issues = assets ? assets.issues : null;
    return issues
      ? issues.canNotOpen
      + issues.couldNotAccess
      + issues.couldNotLocate
      + issues.doesNotExist
      + issues.requiresTraffic
      + issues.other
      : 0;
  }
}
