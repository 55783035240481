import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { mutations } from './mutations';
import { ReleasesState } from './types';

const state : ReleasesState = {
  postReleaseError: undefined,
  postReleaseLoading: false,
  postReleaseOverview: undefined,
  loading: false,
  error: undefined,
  releaseOverview: undefined,
  releaseTableData: [],
  releaseTableDataLoading: false,
  releaseTableDataError: undefined,
  loadingInspectionReleases: false,
  inspectionReleases: undefined,
  postCsvEmailLoading: false,
  postCsvEmailError: undefined,
  deleteReleaseLoading: false,
  deleteReleaseResponse: undefined,
  deleteReleaseInspectionLoading: false,
  deleteReleaseInspectionError: undefined,
  patchReleaseLoading: false,
  patchReleaseError: undefined,
  patchRelease: undefined,
  exportEmailLoading: false,
  exportEmailError: undefined,
  notificationEmailLoading: false,
  notificationEmailError: undefined,
};

const integrityRouting: Module<ReleasesState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default integrityRouting;
