import { MutationTree } from 'vuex';
import { FlatGraphState } from './types';

// eslint-disable-next-line no-shadow
export enum FlatGraphMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_FLAT_GRAPH_DATA = 'SET_FLAT_GRAPH_DATA',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
}

export const mutations: MutationTree<FlatGraphState> = {
  [FlatGraphMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
  [FlatGraphMutations.SET_FLAT_GRAPH_DATA](state, payload: string) {
    state.data = payload;
  },
  [FlatGraphMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
};
