import { InspectionDetails } from './types';

function formatDesc(item: InspectionDetails, type: string): string {
  let desc = '';
  switch (type) {
    case 'GPS':
      desc = `${item.accuracy.type} ${item.accuracy.vertical}${item.accuracy.unit} vertical and
        ${item.accuracy.horizontal}${item.accuracy.unit} horizontal accuracy`;
      break;
    case 'MACP':
      desc = item.type;
      break;
    case 'Vertue':
      desc = item.type;
      break;
    case 'Solo CCTV':
      desc += `${item.estFootage} feet of `;
      if (item.sizeSelection === 'Single Size') {
        desc += `${item.pipeSize}" `;
      } else {
        desc += `${item.pipeSizeMin}" to ${item.pipeSizeMax}" Diameter for `;
      }
      desc += `${type} Inspection`;
      break;
    case 'CCTV':
      desc += `${item.estFootage} feet of `;
      if (item.sizeSelection === 'Single Size') {
        desc += `${item.pipeSize}" `;
      } else {
        desc += `${item.pipeSizeMin}" to ${item.pipeSizeMax}" Diameter for `;
      }
      desc += `${type} Inspection`;
      break;
    case 'Multi-Sensor Inspection':
      desc += `${item.estFootage} feet of `;
      if (item.sizeSelection === 'Single Size') {
        desc = `${item.pipeSize}" `;
      } else {
        desc = `${item.pipeSizeMin}" to ${item.pipeSizeMax}" Diameter using `;
      }
      if (item.sensors.CCTV) {
        desc += 'CCTV';
      }
      if (item.sensors.Laser) {
        desc += `, ${item.sensors.LaserOption} Laser`;
      }
      if (item.sensors.Gas) {
        desc += ', Gas';
      }
      if (item.sensors.Temperature) {
        desc += ', Temperature';
      }

      desc += ` sensors for ${type} Inspection`;
      break;
    default:
      break;
  }

  return desc;
}

export default { formatDesc };
