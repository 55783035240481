




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CodingFormData, FormPageData, ValidationError } from '../../types';

@Component({})
export default class checkBoxGroup extends Vue {
  @Prop() checkBoxFormPageData: FormPageData[];

  @Prop() checkBoxCodingForm: CodingFormData[];

  @Prop() checkBoxGroupTitle: string;

  @Prop() errors: ValidationError[];

  @Prop({ default: 'Yes' }) trueLabel: string;

  @Prop({ default: 'No' }) falseLabel: string;

  @Prop({ default: false }) readonly: boolean;

  @Prop() readonly canEdit: boolean;

  checkBoxChange(headername:string, val:boolean, headernumber: number): void {
    this.$emit('checkBoxChange', headername, val, headernumber);
  }

  getItemText(item: FormPageData): string {
    return this.isMultipleOptions(item) ? item.description.split(':')[1] : item.description;
  }

  isMultipleOptions(item: FormPageData): boolean {
    return item.description.includes(':');
  }

  get checkboxClasses(): string {
    const fpd = this.checkBoxFormPageData.find((fp) => fp.required != null && fp.required);
    const noErrors = fpd && !this.errors.find((e) => e.instancePath === `/${fpd.headername}`);
    const required = (fpd === undefined || noErrors) ? 'checkbox-title' : 'required-title';
    const multipleOptions = this.isMultipleOptions(this.checkBoxFormPageData[0]) ? 'multi-line' : 'single-line';

    return `${required} ${multipleOptions}`;
  }
}
