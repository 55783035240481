






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class EmbeddedViewer extends Vue {
    @Prop() readonly embedLink: string | undefined;

    @Prop({ default: 'Default Title' }) readonly embedTitle: string | undefined;
}
