



























































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component } from 'vue-property-decorator';
import AdminUser from '@/views/Admin/AdminUser.vue';
import CustomersTable from './Customers/CustomersTable.vue';
import OrganizationsTable from './Organizations/OrganizationsTable.vue';
import NewOrganizationPopup from './Organizations/NewOrganizationPopup.vue';
import AdminPanelMixin from './AdminPanelMixin.vue';
import NewCustomerPopup from './Customers/NewCustomerPopup.vue';

@Component({
  components: {
    AdminUser,
    CustomersTable,
    OrganizationsTable,
    NewOrganizationPopup,
    NewCustomerPopup,
  },
})
export default class AdminPanel extends AdminPanelMixin {
  adminPanelSearch = '';

  activeTab = 0;

  tabOptions = [
    {
      name: 'Organizations',
      value: 'organizations',
      permission: this.hasPermissionAdminCanSeeOrganizationTab,
    },
    {
      name: 'Customers',
      value: 'customers',
      permission: this.hasPermissionAdminCanSeeCustomerTab,
    },
    {
      name: 'Users',
      value: 'users',
      permission: this.hasPermissionAdminCanSeeUserTab,
    },
  ];

  /**
   * @description Get the value from the current tab.
   * @returns {string} 'value' from the current tab
   */
  get activeTabValue(): string {
    return this.tabOptions[this.activeTab].value;
  }

  /**
   * @description Get all tabs that the given user has permission to.
   * @returns all tabs user has permission to
   */
  get availableTabOptions(): any {
    return this.tabOptions.filter((tab) => tab.permission());
  }

  async mounted(): Promise<void> {
    await this.fetchCustomerData();
    await this.fetchAllDetailedUserData();
    await this.fetchInactiveDetailedUserData();
    this.fetchOrganizationData();
    this.fetchCustomerData();
  }

  /**
   * @description Emit 'clearUserTableFilters' for all tabs to clear their filters.
   */
  clearFilters(): void {
    this.$root.$emit('clearUserTableFilters');
  }

  /**
   * @returns true if the user has the permission TODO
   */
  hasPermissionAdminCanSeeOrganizationTab(): boolean {
    return true;
    // return this.hasPermission(UserPermission.TODO);
  }

  /**
   * @returns true if the user has the permission TODO
   */
  hasPermissionAdminCanSeeCustomerTab(): boolean {
    return true;
    // return this.hasPermission(UserPermission.TODO);
  }

  /**
   * @returns true if the user has the permission TODO
   */
  hasPermissionAdminCanSeeUserTab(): boolean {
    return true;
    // return this.hasPermission(UserPermission.TODO);
  }
}
