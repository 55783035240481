











































































































































































/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment */
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import { AssetData } from '@/store/asset/types';
import { Route } from 'vue-router';
import UserMenu from './components/UserMenu/UserMenu.vue';
import { NavItem } from './types';
import { UserPrefsActions } from './store/userPrefs/actions';
import IntegritySnackBar from './components/IntegritySnackBar/IntegritySnackBar.vue';
import { ProjectActions } from './store/project/actions';
import UserPermissionsMixin from './components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from './store/userpermissions/types';

const assetModule = namespace('asset');
const projectModule = namespace('project');
const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
    UserMenu,
    IntegritySnackBar,
  },
})
export default class App extends UserPermissionsMixin {
  @Watch('$route')
  onRouteChange(to: Route, from: Route): void {
    this.fromRoute = from;
  }

  @userPrefsModule.Action(UserPrefsActions.GET_USER_PREFS_DATA) getUserPrefs;

  @assetModule.State('asset') asset!: AssetData;

  @assetModule.State('importLoad') importLoad: boolean;

  @projectModule.State('names') projectNames: string[];

  @projectModule.Action(ProjectActions.FETCH_NAMES) getProjectNames;

  toggleHUD = false;

  activePage = '';

  selectedPage = '';

  baseURI = window.location.origin;

  year = new Date().getFullYear();

  activeMenuItem = {
    title: '',
    icon: '',
    value: '',
    expand: false,
    link: '',
  };

  showExpandMenu = false;

  fromRoute: Route = null;

  innerBreadcrumbItems = [];

  fullProjectName = '';

  appPermissions: UserPermission[] = [];

  token: any = null;

  navLoaded = false;

  get canLoadWorker(): boolean {
    return typeof (Worker) !== 'undefined' || process.env.JEST_WORKER_ID !== undefined;
  }

  get title(): string {
    const titleName = this.asset !== undefined
      && this.asset?.project.city !== null
      && this.asset?.project.state !== null
      ? `${this.asset?.project.city}, ${this.asset?.project.state}`
      : ' ';
    return titleName;
  }

  get currentRoute(): string {
    return this.$route.name;
  }

  get breadcrumbItems(): any[] {
    // semi janky solution cuz can't have async get item() :-(
    let retVal: {text: string, to: any}[];
    if (typeof this.$route.meta.breadCrumb === 'function') {
      retVal = this.$route.meta.breadCrumb.call(this, this.$route);
    } else {
      retVal = this.$route.meta.breadCrumb;
    }
    if (retVal != null) {
      const currProject = retVal.find((proj) => proj.text === 'Project');
      if (currProject !== undefined
         && currProject.to !== undefined
         && currProject.to.params !== undefined
         && currProject.to.params.ids !== undefined) {
        const projGuids = JSON.parse(currProject.to.params.ids);
        if (this.projectNames === undefined) {
          if (projGuids[0] !== 'undefined') {
            this.getProjectNames(projGuids).then(() => {
            // eslint-disable-next-line prefer-destructuring
              currProject.text = this.projectNames[0];
              if (currProject.text.length >= 30) {
                this.fullProjectName = currProject.text;
                currProject.text = `${currProject.text.substring(0, 27)}...`;
              }
              this.innerBreadcrumbItems = retVal;
              this.$forceUpdate();
            });
          }
        } else {
          // eslint-disable-next-line prefer-destructuring
          currProject.text = this.projectNames[0];
          if (currProject.text.length >= 30) {
            this.fullProjectName = currProject.text;
            currProject.text = `${currProject.text.substring(0, 27)}...`;
          }
          this.innerBreadcrumbItems = retVal;
        }
      } else if (this.$route.params.ids !== undefined) {
        if (this.projectNames === undefined) {
        // if project guid isn't in the breadcrumb get it from the url
          const projGuids = JSON.parse(this.$route.params.ids);
          this.getProjectNames(projGuids).then(() => {
            // eslint-disable-next-line prefer-destructuring
            currProject.text = this.projectNames[0];
            if (currProject.text.length >= 30) {
              this.fullProjectName = currProject.text;
              currProject.text = `${currProject.text.substring(0, 27)}...`;
            }
            this.innerBreadcrumbItems = retVal;
            this.$forceUpdate();
          });
        } else {
        // eslint-disable-next-line prefer-destructuring
          currProject.text = this.projectNames[0];
          if (currProject.text.length >= 30) {
            this.fullProjectName = currProject.text;
            currProject.text = `${currProject.text.substring(0, 27)}...`;
          }
          this.innerBreadcrumbItems = retVal;
        }
      } else {
        // on a page with no path or something
        this.innerBreadcrumbItems = [];
      }
    }
    return this.innerBreadcrumbItems;
  }

  get routeParamIds(): string {
    let retVal = '';

    const routeParams = this.$route.name === 'profile'
      ? this.fromRoute.params
      : this.$route.params;

    if (routeParams) {
      if (routeParams.ids) retVal = routeParams.ids;
      else if (routeParams.idList) retVal = routeParams.idList;
      else if (routeParams.projectGuids) retVal = routeParams.projectGuids;
      else if (routeParams.guid) retVal = `["${routeParams.guid}"]`;
      else if (routeParams.id) retVal = `["${routeParams.id}"]`;
      else if (routeParams.projectGuid) retVal = `["${routeParams.projectGuid}"]`;
    }

    return retVal;
  }

  get navItems(): NavItem[] {
    const items = [
      {
        title: 'Home',
        icon: 'mdi-home-map-marker',
        value: 'home',
        expand: false,
        link: {
          name: 'projects',
          params: { ids: this.routeParamIds },
        },
        permission: this.hasPermissionGISCommon,
        hide: ['projectSelection', 'admin', 'MPS', 'reporting', 'editUser'],
      },
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard-outline',
        value: 'dashboard',
        expand: false,
        link: {
          name: 'dashboard',
          params: { ids: this.routeParamIds },
        },
        permission: this.hasPermissionDashboardCommon,
        hide: ['projectSelection', 'admin', 'MPS', 'reporting', 'editUser'],
      },
      {
        title: 'Asset Inventory',
        icon: 'mdi-clipboard-list-outline',
        value: 'assetInventory',
        expand: false,
        link: {
          name: 'inventory',
          params: { ids: this.routeParamIds },
        },
        permission: this.hasPermissionAssetInventoryCommon,
        hide: ['projectSelection', 'admin', 'MPS', 'reporting', 'editUser'],
      },
      {
        title: 'Routing',
        icon: 'mdi-group',
        value: 'routing',
        expand: false,
        link: {
          name: 'routing',
          params: {
            id: this.routeParamIds ? JSON.parse(this.routeParamIds)[0] : '',
          },
        },
        permission: this.hasPermissionRoutingCommon,
        hide: ['projectSelection', 'admin', 'MPS', 'reporting', 'editUser'],
      },
      {
        title: 'Work Orders',
        icon: 'mdi-clipboard-text-clock-outline',
        value: 'workOrders',
        expand: false,
        link: {
          name: 'workOrders',
          params: {
            id: this.routeParamIds ? JSON.parse(this.routeParamIds)[0] : '',
          },
        },
        permission: this.hasPermissionWorkOrderCommon,
        hide: ['projectSelection', 'admin', 'MPS', 'reporting', 'editUser'],
      },
      {
        title: 'AI Coding',
        icon: 'mdi-memory',
        value: 'aiCoding',
        expand: false,
        link: {
          name: 'aicoding',
          params: {
            id: this.routeParamIds ? JSON.parse(this.routeParamIds)[0] : '',
          },
        },
        permission: this.hasPermissionAICommon,
        hide: ['projectSelection', 'admin', 'MPS', 'reporting', 'editUser'],
      },
      {
        title: 'Asset History',
        icon: 'mdi-cog-outline',
        value: 'assetManagement',
        expand: false,
        link: {
          name: 'assetManagement',
          params: { projectGuids: this.routeParamIds },
        },
        permission: this.hasPermissionAssetHistoryCommon,
        hide: ['projectSelection', 'admin', 'MPS', 'reporting', 'editUser'],
      },
      {
        title: 'Deployments',
        icon: 'mdi-clipboard-arrow-right-outline',
        value: 'deployments',
        expand: false,
        link: {
          name: 'deployments',
          params: { ids: this.routeParamIds },
        },
        permission: this.hasPermissionDeploymentsCommon,
        hide: ['projectSelection', 'admin', 'MPS', 'reporting', 'editUser'],
      },
      {
        title: 'Import',
        icon: 'mdi-cloud-upload-outline',
        value: 'import',
        expand: false,
        link: {
          name: 'import',
          params: {
            projectGuid: this.routeParamIds ? JSON.parse(this.routeParamIds)[0] : '',
          },
        },
        permission: this.hasPermissionImportCommon,
        hide: ['projectSelection', 'admin', 'MPS', 'reporting', 'editUser'],
      },
      {
        title: 'Projects',
        icon: 'mdi-clipboard-text-outline',
        value: 'projects',
        expand: true,
        link: { name: 'projectSelection' },
        permission: this.hasPermissionProjectSelectionCommon,
        hide: [],
        paramsRequired: false,
      },
      {
        title: 'Reporting',
        icon: 'mdi-square-edit-outline',
        value: 'reporting',
        expand: false,
        link: { name: 'reporting' },
        permission: this.hasPermissionReportingCommon,
        hide: [],
        paramsRequired: false,
      },
      {
        title: 'Admin',
        icon: 'mdi-account-multiple-outline',
        value: 'admin',
        expand: false,
        link: { name: 'admin' },
        permission: this.hasPermissionAdminCommon,
        hide: [],
        paramsRequired: false,
      },
    ];
    return items.filter((item) => {
      const show = item.permission;
      const paramsMet = !item.paramsRequired || item.link.params[Object.keys(item.link.params)[0]] !== '';
      const hidden = item.hide.some((hid) => this.$route.name === hid);

      return show && !hidden && paramsMet;
    });
  }

  /**
   * @returns true if the user has the permission GIS_COMMON
   */
  get hasPermissionGISCommon(): boolean {
    return this.appHasPermission(UserPermission.GIS_COMMON);
  }

  /**
   * @returns true if the user has the permission WORK_ORDER_COMMON
   */
  get hasPermissionWorkOrderCommon(): boolean {
    return this.appHasPermission(UserPermission.WORK_ORDER_COMMON);
  }

  /**
   * @returns true if the user has the permission ASSET_INVENTORY_COMMON
   */
  get hasPermissionAssetInventoryCommon(): boolean {
    return this.appHasPermission(UserPermission.ASSET_INVENTORY_COMMON);
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_COMMON
   */
  get hasPermissionDeploymentsCommon(): boolean {
    return this.appHasPermission(UserPermission.DEPLOYMENTS_COMMON);
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_COMMON
   */
  get hasPermissionProjectSelectionCommon(): boolean {
    return this.appHasPermission(UserPermission.PROJECT_SELECTION_COMMON);
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_CREATE_A_PROJECT
   */
  get hasPermissionProjectSelectionCreateProject(): boolean {
    return this.appHasPermission(UserPermission.PROJECT_SELECTION_CREATE_A_PROJECT);
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_EDIT_A_PROJECT
   */
  get hasPermissionProjectSelectionEditProject(): boolean {
    return this.appHasPermission(UserPermission.PROJECT_SELECTION_EDIT_A_PROJECT);
  }

  /**
   * @returns true if the user has the permission ADMIN_COMMON
   */
  get hasPermissionAdminCommon(): boolean {
    return this.appHasPermission(UserPermission.ADMIN_COMMON);
  }

  /**
   * @returns true if the user has the permission PROJECT_MANAGEMENT_DASHBOARD_VIEW_DASHBOARD
   * or CUSTOMER_PROJECT_PROGRESS_DASHBOARD_VIEW_DASHBOARD
   * or CSA_DASHBOARD_VIEW_DASHBOARD
   * or CWO_DASHBOARD_VIEW_DASHBOARD
   */
  get hasPermissionDashboardCommon(): boolean {
    return this.appHasPermission(UserPermission.PROJECT_MANAGEMENT_DASHBOARD_VIEW_DASHBOARD)
      || this.appHasPermission(UserPermission.CUSTOMER_PROJECT_PROGRESS_DASHBOARD_VIEW_DASHBOARD)
      || this.appHasPermission(UserPermission.CSA_DASHBOARD_VIEW_DASHBOARD)
      || this.appHasPermission(UserPermission.CWO_DASHBOARD_VIEW_DASHBOARD);
  }

  /**
   * @returns true if the user has the permission ASSET_HISTORY_TAB_COMMON
   */
  get hasPermissionAssetHistoryCommon(): boolean {
    return this.appHasPermission(UserPermission.ASSET_HISTORY_TAB_COMMON);
  }

  /**
   * @returns true if the user has the permission ROUTING_COMMON
   */
  get hasPermissionRoutingCommon(): boolean {
    return this.appHasPermission(UserPermission.ROUTING_COMMON);
  }

  /**
   * @returns true if the user has the permission IMPORT_COMMON
   */
  get hasPermissionImportCommon(): boolean {
    return this.appHasPermission(UserPermission.IMPORT_COMMON);
  }

  /**
   * @returns true if the user has the permission AI_COMMON
   */
  get hasPermissionAICommon(): boolean {
    return this.appHasPermission(UserPermission.AI_COMMON);
  }

  /**
   * @returns true if the user has the permission REPORTING_COMMON
   */
  get hasPermissionReportingCommon(): boolean {
    return this.appHasPermission(UserPermission.REPORTING_COMMON);
  }

  async mounted(): Promise<void> {
    if (window.location.href.includes('%20')) {
      window.location.href = window.location.href.replaceAll('%20', '');
    }
    this.getUserPrefs();
  }

  created(): void {
    this.getPermissions();
  }

  async getPermissions(): Promise<void> {
    const tempToken = this.token == null ? await this.$auth.getTokenSilently({}) : this.token;
    if (tempToken != null) {
      this.token = tempToken;
      this.fetchUserPermissions(tempToken);
    }
  }

  async updated(): Promise<void> {
    if (this.navItems.length > 0 && this.navLoaded === false) {
      this.navLoaded = true;
    }
    if (this.token == null) {
      this.getPermissions();
    }
  }

  /**
   * @description Returns if the app has tracked if current user has a permission
   * (work around, calling hasPermission directly was causing an infinite loop D:)
   * @param {UserPermission} permission Permision to check
   * @returns True if permission found, false otherwise.
   */
  appHasPermission(permission: UserPermission): boolean {
    if (this.appPermissions.includes(permission)) {
      return true;
    }

    if (this.hasPermission(permission)) {
      this.appPermissions.push(permission);
      return true;
    }
    return false;
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(): void {
    this.activePage = this.$router.currentRoute.name;
    this.selectedPage = this.getSelectedPage();
  }

  @Watch('activeMenuItem')
  onActiveMenuItemChange(): void {
    this.showExpandMenu = this.activeMenuItem.expand;
  }

  resetExpand(): void {
    this.showExpandMenu = false;
    this.activeMenuItem = {
      title: '', icon: '', value: '', expand: false, link: '',
    };
  }

  /**
   * @description Redirects if there was a timeout issue with getting a user from Auth0
   * @returns True if no redirect occured, false otherwise.
   */
  checkForUserTimeoutAndRedirect(): boolean {
    if (this.$auth.user?.id == null && this.$auth.hasIssuesGettingUser) {
      this.$router.push({
        name: 'Error',
        params: { catchAll: 'Error', message: 'There was an error during authentication. Please try again later. If this issue persists, please contact support.' },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      });
      return false;
    }
    return true;
  }

  /**
   * @description Maps page route with matching navItem so we know which charm to highlight
   * @returns String of route's matching nav item, otherwise empty string
   */
  getSelectedPage(): string {
    switch (this.activePage) {
      case 'projects': return 'Home';
      case 'dashboard': return 'Dashboard';
      case 'customerDashboard': return 'Dashboard';
      case 'inventory': return 'Asset Inventory';
      case 'routing': return 'Routing';
      case 'workOrders': return 'Work Orders';
      case 'aicoding': return 'AI Coding';
      case 'assetManagement': return 'Asset History';
      case 'deployments': return 'Deployments';
      case 'import': return 'Import';
      case 'reporting': return 'Reporting';
      case 'admin': return 'Admin';
      case 'MPS': return 'Projects';
      case 'projectSelection': return 'Projects';
      default: return '';
    }
  }
}
