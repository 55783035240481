
















































import { RoutingData } from '@/store/routing/types';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import {
  NODEITEM_LINESEGMENT_GUID,
  NODEITEM_MANHOLE_GUID,
} from '@/common/Constants';
import { namespace } from 'vuex-class';
import IntegrityDelete from '../../IntegrityDelete/IntegrityDelete.vue';

const routingModule = namespace('routing');

@Component({
  components: {
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class NewRoutingAssignmentTable extends Vue {
  @routingModule.State('routingData') routingData: RoutingData[] | undefined;

  @routingModule.State('routingLoading') routingLoading: boolean | undefined;

  @PropSync('selectedItemsSync', { default: () => ([]) }) selectedItems:
    | RoutingData[]
    | undefined;

  @Prop() activeTab!: number;

  headers = [
    {
      text: 'Route Name',
      value: 'routeName',
      align: 'start',
      filterable: true,
      class: 'sticky',
      cellClass: 'sticky',
    },
    {
      text: 'Task Types',
      value: 'taskType',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Next Due Date',
      value: 'nextDueDate',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Created By',
      value: 'createdBy',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Created Date',
      value: 'createdDate',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Description',
      value: 'description',
      align: 'start',
      filterable: true,
    },
  ];

  matchFilters = [
    {
      header: 'routeName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'taskType',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'nextDueDate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'createdBy',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'createdDate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'description',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  mounted(): void {
    this.onRoutingDataChange();
  }

  @Watch('routingData')
  onRoutingDataChange(): void {
    this.routingData.forEach((data) => {
      // eslint-disable-next-line no-return-assign, no-param-reassign
      data['createdDate'] = this.processDate(data['createdDate']);
      // eslint-disable-next-line no-return-assign, no-param-reassign
      data['nextDueDate'] = this.processDate(data['nextDueDate']);
    });
    (this.$refs.routingTable as IntegrityTable).onResize();
  }

  get filterValues(): unknown {
    if (!this.routingData || this.routingData.length <= 0) return {};

    const retVal = {};

    this.routingData.forEach((asset) => {
      this.headers.forEach((header) => {
        let checkVal: string;

        if (!retVal[header.value]) retVal[header.value] = [];

        try {
          checkVal = asset[header.value];
        } catch {
          checkVal = '';
        }

        if (
          checkVal
          && checkVal !== ''
          && Array.isArray(retVal[header.value])
          && !retVal[header.value].includes(checkVal)
        ) {
          retVal[header.value].push(checkVal);
        }

        retVal[header.value].sort();
      });
    });

    return retVal;
  }

  processDate(dateString: string): string {
    return !dateString ? null : dateString.substring(0, 10);
  }

  getRouteColor(color: string): string | undefined {
    const hexReg = /^#([0-9a-f]{3,4}){1,2}$/i;
    if (color == null || color === '') {
      return '#FFFFFFFF';
    }
    if (hexReg.test(color)) {
      return color;
    }
    if (hexReg.test(`#${color}`)) {
      return `#${color}`;
    }
    return '#FFFFFFFF';
  }

  get filteredRouteData(): RoutingData[] {
    if (!this.routingData) {
      return [];
    }
    if (this.activeTab === 2) {
      return this.routingData.filter(
        (asset) => asset.categoryItemGuid === NODEITEM_MANHOLE_GUID,
      );
    }
    return this.routingData.filter(
      (asset) => asset.categoryItemGuid === NODEITEM_LINESEGMENT_GUID,
    );
  }
}
