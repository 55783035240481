import { MutationTree } from 'vuex';
import { ExportState } from './types';

// eslint-disable-next-line no-shadow
export enum ExportMutations {
    SET_LOADING_STATE = 'SET_LOADING_STATE',
    SET_EXPORT_DATA = 'SET_EXPORT_DATA',
    CLEAR_EXPORT_DATA = 'CLEAR_EXPORT_DATA',
    SET_LOAD_ERROR = 'SET_LOAD_ERROR',
}

export const mutations : MutationTree<ExportState> = {
  [ExportMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },

  [ExportMutations.SET_EXPORT_DATA](state, payload: string) {
    state.data = payload;
  },

  [ExportMutations.CLEAR_EXPORT_DATA](state) {
    state.data = '';
  },

  [ExportMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
};
