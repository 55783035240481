





































import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { Component, Prop } from 'vue-property-decorator';
import { CustomerData } from '@/store/customers/types';
import phoneNumberToPhoneInfo from '@/components/IntegrityPhoneNumberInput/types';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import CustomerEditDialog from './CustomerEditDialog.vue';

@Component({
  components: {
    IntegrityTable,
    CustomerEditDialog,
    IntegrityDelete,
  },
})
export default class CustomersTable extends AdminPanelMixin {
  @Prop() readonly search: string;

  showEditDialog = false;

  currentEditItem = {};

  headers = [
    {
      text: 'Customer',
      value: 'name',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Organization',
      value: 'organizationName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Address',
      value: 'address',
      sortable: true,
      filterable: true,
    },
    {
      text: 'System Owner',
      value: 'systemOwner',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Email',
      value: 'email',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Phone',
      value: 'phoneNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'left',
      sortable: false,
    },
  ];

  matchFilters = [
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'organizationName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'address',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'systemOwner',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'email',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'phoneNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  async mounted(): Promise<void> {
    if (!this.customerDataList) await this.fetchCustomerData();
    this.$root.$on('clearUserTableFilters', () => {
      (this.$refs.customerTable as IntegrityTable).clearAllMatchFilters();
    });
  }

  /**
   * @description Get the data for the table that includes the system owner's name.
   * @returns {CustomerData[]} List of customers
   */
  get customerTableData(): CustomerData[] {
    if (!this.customerDataList) {
      return [];
    }
    return this.customerDataList.map((org) => ({
      ...org,
      systemOwner: this.findUserFromUserGuid(org.systemOwnerGuid),
      organizationName: this.findOrganizationFromOrganizationGuid(
        org.organizationGuid,
      ),
    }));
  }

  /**
   * @description Find the user in the all users object based on their guid.
   * @param {string} userGuid User guid to search
   * @returns {string} first and last name of user
   */
  findUserFromUserGuid(userGuid: string): string {
    if (!this.allUsers) {
      return '';
    }
    const foundUser = this.allUsers.find((u) => u.guid === userGuid);
    if (foundUser) {
      return `${foundUser.firstname} ${foundUser.lastname}`;
    }
    return '';
  }

  /**
   * @description Find the organization in the all organization object based on its guid.
   * @param {string} organizationGuid organization guid to search
   * @returns {string} organization name
   */
  findOrganizationFromOrganizationGuid(organizationGuid: string): string {
    if (!this.organizationDataList) {
      return '';
    }
    const foundOrganization = this.organizationDataList.find(
      (org) => org.guid === organizationGuid,
    );
    return foundOrganization?.name ?? '';
  }

  /**
   * @description fill out the filter values for the table.
   * @returns filter values
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get filterValues(): any {
    if (!this.customerTableData) {
      return;
    }

    const localFilterValues = {};

    this.headers.forEach((header) => {
      if (!header.filterable) return;

      localFilterValues[header.value] = [];

      this.customerTableData.forEach((workOrder: CustomerData) => {
        let checkVal: string;

        try {
          checkVal = workOrder[header.value];
        } catch {
          checkVal = '';
        }

        if (
          checkVal
          && checkVal !== ''
          && Array.isArray(localFilterValues[header.value])
          && !localFilterValues[header.value].includes(checkVal)
        ) {
          localFilterValues[header.value].push(checkVal);
        }
      });
      localFilterValues[header.value].sort();
    });
    // eslint-disable-next-line consistent-return
    return localFilterValues;
  }

  handleEdit(itemGuid: string): void {
    const customerData = this.customerTableData.find((i) => i.guid === itemGuid);
    this.currentEditItem = {
      guid: customerData.guid,
      customerName: customerData.name,
      address: customerData.address,
      systemOwner: customerData.systemOwnerGuid ? {
        guid: customerData.systemOwnerGuid,
        firstname: customerData.systemOwner && customerData.systemOwner !== '' ? customerData.systemOwner.split(' ')[0] : '',
        lastname: customerData.systemOwner && customerData.systemOwner !== '' ? customerData.systemOwner.split(' ')[1] : '',
      } : null,
      email: customerData.email,
      phoneNumber: phoneNumberToPhoneInfo(customerData.phoneNumber),
      organization: {
        guid: customerData.organizationGuid,
        name: customerData.organizationName,
      },
      sameAsOrganization: customerData.organizationName === customerData.name,
    };
    this.showEditDialog = true;
  }

  /**
   * @description Calls endpoint to delete customer and removes it from customerTableData.
   * @param {CustomerData} item CustomerData object to remove.
   * @returns {Promise<boolean>} Returns true if customer was successfully deleted.
   */
  async handleDelete(item: CustomerData): Promise<boolean> {
    try {
      const deleteIndex = this.customerTableData.findIndex(
        (value) => value.guid === item.guid,
      );

      if (deleteIndex === -1) {
        // If we can not find the item in the customerTableData
        return false;
      }

      await this.deleteCustomerData([item.guid]);
      this.customerDataList.splice(deleteIndex, 1);
    } catch (e) {
      return false;
    }
    return true;
  }
}
