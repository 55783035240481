












































/* eslint-disable @typescript-eslint/no-explicit-any */
import { SnackBarActions } from '@/store/integritySnackBar/actions';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const integritySnackBar = namespace('integritySnackBar');

@Component({})
export default class IntegrityDelete extends Vue {
  @Prop({ default: 'WARNING: Deletion is a permanent action' }) readonly deleteHeader: string;

  @Prop({ default: 'This operation is not reversable.' }) readonly deleteText: string;

  @Prop() deleteFunction;

  @Prop({ default: false }) readonly loading: boolean;

  @Prop({ default: false }) readonly disabled: boolean;

  @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

  dialogVisible = false;

  async deleteConfirm(): Promise<void> {
    // delete function should return a boolean of the success of the delete
    const res = await this.deleteFunction();

    this.dialogVisible = false;
    if (res === undefined) {
      return;
    }
    let snackBarMessage;
    let snackColor;
    if (res) {
      snackBarMessage = 'Delete Successful';
      snackColor = 'green';
    } else {
      snackBarMessage = 'Delete Error';
      snackColor = 'red';
    }

    this.showSnackBar({
      message: snackBarMessage,
      color: snackColor,
      showSnackBar: true,
    });
  }
}
