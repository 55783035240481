






























import { ProjectCrewPlatform } from '@/store/metrics/types';
import { format } from 'date-fns';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CrewDashboardOverview extends Vue {
  @Prop() platformCrewOverview: ProjectCrewPlatform;

  @Prop() projectedFinish: string;

  get complete(): string {
    if (
      !this.platformCrewOverview
      || this.platformCrewOverview.totalCount === 0
    ) {
      return '0%';
    }
    const percent = Math.round(
      (this.platformCrewOverview.completedCount * 100)
        / this.platformCrewOverview?.totalCount,
    );
    return `${percent}%`;
  }

  get success(): string {
    if (
      !this.platformCrewOverview
      || this.platformCrewOverview.totalCount === 0
    ) {
      return '0%';
    }
    const percent = Math.round(
      (this.platformCrewOverview.addressedCount * 100)
        / this.platformCrewOverview?.totalCount,
    );
    return `${percent}%`;
  }

  get issues(): string {
    if (
      !this.platformCrewOverview
      || this.platformCrewOverview.totalCount === 0
    ) {
      return '0%';
    }
    const percent = Math.round(
      (this.platformCrewOverview.incompleteCount * 100)
        / this.platformCrewOverview?.totalCount,
    );
    return `${percent}%`;
  }

  get crewDays(): number {
    if (
      !this.platformCrewOverview.startDate
      || !this.platformCrewOverview.endDate
    ) {
      return 0;
    }
    const difference = Date.parse(this.platformCrewOverview.endDate)
      - Date.parse(this.platformCrewOverview.startDate);
    return Math.round(difference / (1000 * 60 * 60 * 24));
  }

  parseDate(dateString: string | undefined): string {
    if (!dateString) {
      return '';
    }
    return format(Date.parse(dateString), 'yyyy-MM-dd');
  }
}
