import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { ProjectState } from './types';
import { ProjectMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum ProjectActions {
    FETCH_PROJECT_DATA = 'FETCH_PROJECT_DATA',
    FETCH_PROJECT_TABLE_DATA = 'FETCH_PROJECT_TABLE_DATA',
    CLEAR_PROJECT_DATA = 'CLEAR_PROJECT_DATA',
    FETCH_GRAPHICS_DATA = 'FETCH_GRAPHICS_DATA',
    FETCH_HEADER_DATA = 'FETCH_HEADER_DATA',
    MERGE_PROJECT_DATA = 'MERGE_PROJECT_DATA',
    FETCH_ALL_PROJECTS_DATA = 'FETCH_ALL_PROJECTS_DATA',
    FETCH_PLANNING_ANALYTICS_DATA = 'FETCH_PLANNING_ANALYTICS_DATA',
    FETCH_DETAILED_PROJECT_METRICS = 'FETCH_DETAILED_PROJECT_METRICS',
    FETCH_DASHBOARD_PROJECT_METRICS = 'FETCH_DASHBOARD_PROJECT_METRICS',
    DELETE_PROJECT = 'DELETE_PROJECT',
    FETCH_SELECTED_ASSET = 'FETCH_SELECTED_ASSET',
    FETCH_NAMES = 'FETCH_NAMES',
    POST_GEOJSON = 'POST_GEOJSON',
    GET_AGOL_MAPPING_DATA = 'GET_AGOL_MAPPING_DATA',
    POST_AGOL_MAPPING_DATA = 'POST_AGOL_MAPPING_DATA',
    PATCH_AGOL_MAPPING_DATA = 'PATCH_AGOL_MAPPING_DATA',
    DELETE_AGOL_MAPPING_DATA = 'DELETE_AGOL_MAPPING_DATA',
    TURN_OFF_AGOL_MAPPING = 'TURN_OFF_AGOL_MAPPING',
}

export const actions: ActionTree<ProjectState, RootState> = {

  async [ProjectActions.FETCH_PROJECT_DATA]({ commit }, projectIDs: string[]): Promise<void> {
    commit(ProjectMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getProject(projectIDs);
      commit(ProjectMutations.SET_PROJECT_DATA, response.data);
    } catch (e) {
      commit(ProjectMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_STATE, false);
    }
  },

  async [ProjectActions.FETCH_PROJECT_TABLE_DATA]({ commit }, projectIDs: string[]): Promise<void> {
    commit(ProjectMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getProjectTable(projectIDs);
      commit(ProjectMutations.SET_PROJECT_DATA, response.data);
    } catch (e) {
      commit(ProjectMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_STATE, false);
    }
  },

  async [ProjectActions.FETCH_GRAPHICS_DATA]({ commit }, projectIDs: string[]): Promise<void> {
    commit(ProjectMutations.SET_LOADING_GRAPHICS, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getProjectGraphics(projectIDs);
      commit(ProjectMutations.SET_PROJECT_GRAPHICS, response.data);
    } catch (e) {
      commit(ProjectMutations.SET_GRAPHICS_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_GRAPHICS, false);
    }
  },

  async [ProjectActions.FETCH_HEADER_DATA]({ commit }, projectIDs: string[]): Promise<void> {
    commit(ProjectMutations.SET_LOADING_HEADERS, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getProjectHeaders(projectIDs);
      commit(ProjectMutations.SET_PROJECT_HEADERS, response.data);
    } catch (e) {
      commit(ProjectMutations.SET_HEADERS_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_HEADERS, false);
    }
  },

  async [ProjectActions.FETCH_NAMES]({ commit }, projectIDs: string[]): Promise<void> {
    commit(ProjectMutations.SET_LOADING_NAMES, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getProjectNames(projectIDs);
      commit(ProjectMutations.SET_PROJECT_NAMES, response.data);
    } catch (e) {
      commit(ProjectMutations.SET_NAMES_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_NAMES, false);
    }
  },

  async [ProjectActions.MERGE_PROJECT_DATA]({ commit }): Promise<void> {
    try {
      commit(ProjectMutations.MERGE_PROJECT_DATA);
    } catch (e) {
      commit(ProjectMutations.SET_GRAPHICS_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    }
  },

  async [ProjectActions.CLEAR_PROJECT_DATA]({ commit }): Promise<void> {
    commit(ProjectMutations.SET_LOADING_STATE, true);
    try {
      commit(ProjectMutations.CLEAR_PROJECT_DATA);
    } catch (e) {
      commit(ProjectMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_STATE, false);
    }
  },

  async [ProjectActions.FETCH_ALL_PROJECTS_DATA]({ commit }): Promise<void> {
    commit(ProjectMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getProjects();
      commit(ProjectMutations.SET_ALL_PROJECTS_DATA, response.data);
    } catch (e) {
      commit(ProjectMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_STATE, false);
    }
  },

  async [ProjectActions.FETCH_PLANNING_ANALYTICS_DATA]({ commit }, projectGuids: string[]):
    Promise<void> {
    commit(ProjectMutations.SET_LOAD_ERROR, undefined);
    commit(ProjectMutations.SET_PLANNING_ANALYTICS_DATA, undefined);
    commit(ProjectMutations.SET_LOADING_ANALYTICS_DATA, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getPlanningAnalytics(projectGuids);
      commit(ProjectMutations.SET_PLANNING_ANALYTICS_DATA, response.data);
    } catch (e) {
      commit(ProjectMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_ANALYTICS_DATA, false);
    }
  },

  async [ProjectActions.FETCH_DETAILED_PROJECT_METRICS]({ commit }, projectGuid: string):
    Promise<void> {
    commit(ProjectMutations.SET_LOADING_METRICS, true);
    commit(ProjectMutations.SET_LOAD_ERROR, undefined);
    commit(ProjectMutations.SET_PROJECT_METRICS, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDetailedProjectMetrics(projectGuid);
      commit(ProjectMutations.SET_PROJECT_METRICS, response.data);
    } catch (e) {
      commit(ProjectMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_METRICS, false);
    }
  },

  async [ProjectActions.FETCH_DASHBOARD_PROJECT_METRICS]({ commit }, projectGuid: string):
    Promise<void> {
    commit(ProjectMutations.SET_LOADING_DASHBOARD_METRICS, true);
    commit(ProjectMutations.SET_LOAD_ERROR, undefined);
    commit(ProjectMutations.SET_DASHBOARD_PROJECT_METRICS, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDashboardProjectMetrics(projectGuid);
      commit(ProjectMutations.SET_DASHBOARD_PROJECT_METRICS, response.data);
    } catch (e) {
      commit(ProjectMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_DASHBOARD_METRICS, false);
    }
  },

  async [ProjectActions.DELETE_PROJECT]({ commit }, projectGuid: string): Promise<void> {
    commit(ProjectMutations.SET_LOADING_STATE, true);
    commit(ProjectMutations.SET_LOAD_ERROR, undefined);
    commit(ProjectMutations.SET_RESPONSE, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.deleteProject(projectGuid);
      commit(ProjectMutations.SET_RESPONSE, response);
    } catch (e) {
      commit(ProjectMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_STATE, false);
    }
  },

  async [ProjectActions.FETCH_SELECTED_ASSET]({ commit }, assetGuid: string): Promise<void> {
    commit(ProjectMutations.SET_LOADING_ASSET, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAssetPopout(assetGuid);
      commit(ProjectMutations.SET_SELECTED_ASSET, response.data);
    } catch (e) {
      commit(ProjectMutations.SET_ASSET_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_LOADING_ASSET, false);
    }
  },

  async [ProjectActions.POST_GEOJSON]({ commit }, payload): Promise<void> {
    commit(ProjectMutations.SET_GEO_JSON_LOADED, false);
    commit(ProjectMutations.SET_GEO_JSON_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postGeoJson(payload);
    } catch (e) {
      commit(ProjectMutations.SET_GEO_JSON_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_GEO_JSON_LOADED, true);
    }
  },

  async [ProjectActions.GET_AGOL_MAPPING_DATA]({ commit }, payload): Promise<void> {
    commit(ProjectMutations.SET_AGOL_MAPPING_DATA_LOADING, true);
    commit(ProjectMutations.SET_AGOL_MAPPING_DATA_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const result = await api.getAGOLMapping(payload);
      commit(ProjectMutations.SET_AGOL_MAPPING_DATA, result.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ProjectMutations.SET_AGOL_MAPPING_DATA_ERROR, 'Specified Project Not Found');
        throw new Error('API Error occurred. Specified Project Not Found');
      }
      commit(ProjectMutations.SET_AGOL_MAPPING_DATA_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_AGOL_MAPPING_DATA_LOADING, false);
    }
  },

  async [ProjectActions.POST_AGOL_MAPPING_DATA]({ commit }, payload): Promise<void> {
    commit(ProjectMutations.SET_AGOL_POST_MAPPING_DATA_LOADING, true);
    commit(ProjectMutations.SET_AGOL_POST_MAPPING_DATA_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postAGOLMapping(payload);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ProjectMutations.SET_AGOL_POST_MAPPING_DATA_ERROR, 'Specified Project Not Found');
        throw new Error('API Error occurred. Specified Project Not Found');
      }
      if (storeUtility.isDuplicateDbEntry(e)) {
        commit(ProjectMutations.SET_AGOL_POST_MAPPING_DATA_ERROR, 'Specified Mapping Name Already Exists on this Project');
        throw new Error('API Error occurred. Specified Mapping Name Already Exists on this Project');
      }
      commit(ProjectMutations.SET_AGOL_POST_MAPPING_DATA_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_AGOL_POST_MAPPING_DATA_LOADING, false);
    }
  },

  async [ProjectActions.PATCH_AGOL_MAPPING_DATA]({ commit }, payload): Promise<void> {
    commit(ProjectMutations.SET_AGOL_PATCH_MAPPING_DATA_LOADING, true);
    commit(ProjectMutations.SET_AGOL_PATCH_MAPPING_DATA_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.patchAGOLMapping(payload);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ProjectMutations.SET_AGOL_PATCH_MAPPING_DATA_ERROR, 'Specified Mapping Not Found');
        throw new Error('API Error occurred. Specified Mapping Not Found');
      }
      if (storeUtility.isDuplicateDbEntry(e)) {
        commit(ProjectMutations.SET_AGOL_PATCH_MAPPING_DATA_ERROR, 'Specified Mapping Name Already Exists on this Project');
        throw new Error('API Error occurred. Specified Mapping Name Already Exists on this Project');
      }
      commit(ProjectMutations.SET_AGOL_PATCH_MAPPING_DATA_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_AGOL_PATCH_MAPPING_DATA_LOADING, false);
    }
  },

  async [ProjectActions.DELETE_AGOL_MAPPING_DATA]({ commit }, payload): Promise<void> {
    commit(ProjectMutations.SET_AGOL_DELETE_MAPPING_DATA_LOADING, true);
    commit(ProjectMutations.SET_AGOL_DELETE_MAPPING_DATA_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.deleteAGOLMapping(payload);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ProjectMutations.SET_AGOL_DELETE_MAPPING_DATA_ERROR, 'Specified Mapping Not Found');
        throw new Error('API Error occurred. Specified Mapping Not Found');
      }
      commit(ProjectMutations.SET_AGOL_DELETE_MAPPING_DATA_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_AGOL_DELETE_MAPPING_DATA_LOADING, false);
    }
  },

  async [ProjectActions.TURN_OFF_AGOL_MAPPING]({ commit }, payload): Promise<void> {
    commit(ProjectMutations.SET_TURN_OFF_AGOL_MAPPING_LOADING, true);
    commit(ProjectMutations.SET_TURN_OFF_AGOL_MAPPING_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.turnOffAgolMapping(payload);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ProjectMutations.SET_TURN_OFF_AGOL_MAPPING_ERROR, 'Specified Mapping Not Found');
        throw new Error('API Error occurred. Specified Mapping Not Found');
      }
      commit(ProjectMutations.SET_TURN_OFF_AGOL_MAPPING_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ProjectMutations.SET_TURN_OFF_AGOL_MAPPING_LOADING, false);
    }
  },
};
