import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { ScopeHistoryState } from './types';
import { ScopeHistoryMutations } from './mutations';
import SampleHistory from '../../views/ScopeBase/SampleHistory.json';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum ScopeHistoryActions {
  FETCH_SCOPE_HISTORY_DATA = 'FETCH_SCOPE_HISTORY_DATA',
  FETCH_ASSET_HISTORY_DATA = 'FETCH_ASSET_HISTORY_DATA',
  PATCH_HISTORY_DATA = 'PATCH_HISTORY_DATA',
  FETCH_ASSET_WORKORDER_HISTORY_DATA = 'FETCH_ASSET_WORKORDER_HISTORY_DATA',
  PATCH_ASSET_WORKORDER_HISTORY_DATA = 'PATCH_ASSET_WORKORDER_HISTORY_DATA',
  FETCH_PROJECT_DELIVERY_TOTALS = 'FETCH_PROJECT_DELIVERY_TOTALS',
  FETCH_CUSTOMER_PREP_RELEASE_EMAILS = 'FETCH_CUSTOMER_PREP_RELEASE_EMAILS',
}

export const actions: ActionTree<ScopeHistoryState, RootState> = {
  async [ScopeHistoryActions.FETCH_SCOPE_HISTORY_DATA]({ commit }, payload): Promise<void> {
    commit(ScopeHistoryMutations.SET_LOADING_STATE, true);
    try {
      let response;
      if (payload === 'a38f78d1-ce57-4941-b3d6-107387bc8d80') {
        response = { data: SampleHistory };
      } else {
        const api = await storeUtility.useIntegrityAPI();
        response = await api.getScopeHistory(payload);
      }

      commit(ScopeHistoryMutations.SET_SCOPE_HISTORY_DATA, response.data);
    } catch (e) {
      commit(ScopeHistoryMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(ScopeHistoryMutations.SET_LOADING_STATE, false);
    }
  },

  async [ScopeHistoryActions.FETCH_ASSET_HISTORY_DATA]({ commit }, payload): Promise<void> {
    commit(ScopeHistoryMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAssetHistory(payload);

      commit(ScopeHistoryMutations.SET_ASSET_HISTORY_DATA, response.data);
    } catch (e) {
      commit(ScopeHistoryMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(ScopeHistoryMutations.SET_LOADING_STATE, false);
    }
  },
  async [ScopeHistoryActions.PATCH_HISTORY_DATA]({ commit }, payload): Promise<void> {
    commit(ScopeHistoryMutations.SET_PATCH_HISTORY_MESSAGE, '');
    commit(ScopeHistoryMutations.SET_PATCH_HISTORY_ERROR, false);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.patchHistoryData(payload);
      commit(ScopeHistoryMutations.SET_PATCH_HISTORY_MESSAGE, response.data);
    } catch (e) {
      commit(ScopeHistoryMutations.SET_PATCH_HISTORY_MESSAGE, (e as Error).message);
      commit(ScopeHistoryMutations.SET_PATCH_HISTORY_ERROR, true);
      throw new Error('API Error occurred.');
    }
  },
  async [ScopeHistoryActions.FETCH_ASSET_WORKORDER_HISTORY_DATA]({ commit },
    payload): Promise<void> {
    commit(ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_LOADING, true);
    commit(ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_ERROR, undefined);
    commit(ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAssetWorkOrderHistory(payload);

      commit(ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_DATA, response.data.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_ERROR, 'Asset could not be found.');
        throw new Error('API Error occurred. Asset could not be found.');
      }
      commit(ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_LOADING, false);
    }
  },
  async [ScopeHistoryActions.PATCH_ASSET_WORKORDER_HISTORY_DATA]({ commit },
    payload: {
      guid: string,
      dto: {
        active: boolean,
      },
    }): Promise<void> {
    commit(ScopeHistoryMutations.SET_PATCH_ASSET_WORKORDER_HISTORY_LOADING, true);
    commit(ScopeHistoryMutations.SET_PATCH_ASSET_WORKORDER_HISTORY_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.patchAssetWorkOrderHistory(payload);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ScopeHistoryMutations.SET_PATCH_ASSET_WORKORDER_HISTORY_ERROR, 'Deployment could not be found.');
        throw new Error('API Error occurred. Deployment could not be found.');
      }
      commit(ScopeHistoryMutations.SET_PATCH_ASSET_WORKORDER_HISTORY_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ScopeHistoryMutations.SET_PATCH_ASSET_WORKORDER_HISTORY_LOADING, false);
    }
  },
  async [ScopeHistoryActions.FETCH_PROJECT_DELIVERY_TOTALS]({ commit }, payload): Promise<void> {
    commit(ScopeHistoryMutations.SET_FETCH_PROJECT_DELIVERY_TOTALS_LOADING, true);
    commit(ScopeHistoryMutations.SET_FETCH_PROJECT_DELIVERY_TOTALS_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getprojectDeliveryTotals(payload);
      commit(ScopeHistoryMutations.SET_FETCH_PROJECT_DELIVERY_TOTALS_DATA, response.data.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ScopeHistoryMutations.SET_FETCH_PROJECT_DELIVERY_TOTALS_ERROR, 'Project could not be found.');
        throw new Error('API Error occurred. Project could not be found.');
      }
      commit(ScopeHistoryMutations.SET_FETCH_PROJECT_DELIVERY_TOTALS_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ScopeHistoryMutations.SET_FETCH_PROJECT_DELIVERY_TOTALS_LOADING, false);
    }
  },
  async [ScopeHistoryActions.FETCH_CUSTOMER_PREP_RELEASE_EMAILS]({ commit },
    projectGuid: string): Promise<void> {
    commit(ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS_LOADING, true);
    commit(ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS_ERROR, undefined);
    commit(ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS, []);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getCustomerPrepReleaseEmails(projectGuid);
      commit(ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS, response.data.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS_ERROR, 'Project could not be found.');
        throw new Error('API Error occurred. Project could not be found.');
      }
      commit(ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS_LOADING, false);
    }
  },
};
