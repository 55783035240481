import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { RoutingMutations } from './mutations';
import { DeleteRoutingDataDTO, RoutingDuplicateObject, RoutingState } from './types';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum RoutingActions {
    FETCH_ROUTING_DATA = 'FETCH_ROUTING_DATA',
    DELETE_ROUTING = 'DELETE_ROUTING',
    FETCH_SIMPLE_ROUTING_DATA = 'FETCH_SIMPLE_ROUTING_DATA',
    FETCH_ROUTE_ASSETS = 'FETCH_ROUTE_ASSETS',
    CHECK_ASSET_DUPLICATES = 'CHECK_ASSET_DUPLICATES',
}

export const actions: ActionTree<RoutingState, RootState> = {

  // eslint-disable-next-line spaced-comment
  async [RoutingActions.FETCH_ROUTING_DATA]({ commit }, projectIDs: string): Promise<void> {
    commit(RoutingMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.fetchRoutingData(projectIDs);
      commit(RoutingMutations.SET_ROUTING_DATA, response.data);
    } catch (e) {
      commit(RoutingMutations.SET_ROUTING_ERROR, (e as Error).message);
      throw Error('Fetch Routing Error occurred.');
    } finally {
      commit(RoutingMutations.SET_LOADING_STATE, false);
    }
  },
  async [RoutingActions.DELETE_ROUTING]({ commit },
    routingGuids: DeleteRoutingDataDTO): Promise<void> {
    commit(RoutingMutations.SET_DELETE_ROUTING_DATA, null);
    commit(RoutingMutations.SET_DELETE_ROUTING_ERROR, null);
    commit(RoutingMutations.SET_DELETE_ROUTING_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.deleteRoutingData(routingGuids);
      commit(RoutingMutations.SET_DELETE_ROUTING_DATA, response.data.data);
    } catch (e) {
      if (storeUtility.isPartialProcessingError(e)) {
        commit(RoutingMutations.SET_DELETE_ROUTING_ERROR, 'Some routes were not deleted.');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        commit(RoutingMutations.SET_DELETE_ROUTING_DATA, (e as any).response?.data?.data);
        throw new Error('API Error occurred. Some routes were not deleted. See error data for routes not deleted.');
      }
      commit(RoutingMutations.SET_DELETE_ROUTING_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(RoutingMutations.SET_DELETE_ROUTING_LOADING, false);
    }
  },
  // eslint-disable-next-line spaced-comment
  async [RoutingActions.FETCH_SIMPLE_ROUTING_DATA]({ commit }, projectIDs: string): Promise<void> {
    commit(RoutingMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.fetchSimpleRoutingData(projectIDs);
      commit(RoutingMutations.SET_SIMPLE_ROUTING_DATA, response.data);
    } catch (e) {
      commit(RoutingMutations.SET_ROUTING_ERROR, (e as Error).message);
      throw Error('Fetch Routing Error occurred.');
    } finally {
      commit(RoutingMutations.SET_LOADING_STATE, false);
    }
  },
  // eslint-disable-next-line spaced-comment
  async [RoutingActions.FETCH_ROUTE_ASSETS]({ commit }, routeId: string): Promise<void> {
    commit(RoutingMutations.SET_LOADING_STATE, true);
    commit(RoutingMutations.SET_ROUTE_ASSETS, []);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.fetchRouteAssets(routeId);
      commit(RoutingMutations.SET_ROUTE_ASSETS, response.data);
    } catch (e) {
      commit(RoutingMutations.SET_ROUTING_ERROR, (e as Error).message);
      throw Error('Fetch Routing Assets Error occurred.');
    } finally {
      commit(RoutingMutations.SET_LOADING_STATE, false);
    }
  },
  // eslint-disable-next-line spaced-comment
  async [RoutingActions.CHECK_ASSET_DUPLICATES](
    { commit }, assetIds: string[],
  ): Promise<RoutingDuplicateObject[]> {
    // commit(RoutingMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postRouteAssetDuplicates(assetIds);
      return response.data;
    } catch (e) {
      commit(RoutingMutations.SET_ROUTING_ERROR, (e as Error).message);
      throw Error('Fetch Routing Assets Error occurred.');
    } finally {
      // commit(RoutingMutations.SET_LOADING_STATE, false);
    }
  },
};
