import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { CrossSectionsState } from './types';
import { CrossSectionsMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum CrossSectionsActions {
  GET_CROSS_SECTIONS = 'GET_CROSS_SECTIONS',
  CLEAR_CROSS_SECTIONS = 'CLEAR_CROSS_SECTIONS',
  GET_DEFECT_DATA = 'GET_DEFECT_DATA',
  GET_PVD_DETAILS = 'GET_PVD_DETAILS',
}

export const actions: ActionTree<CrossSectionsState, RootState> = {
  async [CrossSectionsActions.GET_CROSS_SECTIONS]({ commit }, payload: string): Promise<void> {
    commit(CrossSectionsMutations.SET_LOADING_STATE, true);
    commit(CrossSectionsMutations.SET_CROSS_SECTIONS_DATA, undefined);
    commit(CrossSectionsMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      if (window.Worker) {
        const response = await api.getCrossSectionsString(payload);
        const worker = new Worker('./worker.js', { type: 'module' });
        worker.postMessage({
          type: 'parseCrossSections',
          jsonString: response.data,
        });

        worker.onmessage = (e) => {
          commit(CrossSectionsMutations.SET_CROSS_SECTIONS_DATA, e.data);
        };
      } else {
        const response = await api.getCrossSectionsList(payload);
        commit(CrossSectionsMutations.SET_CROSS_SECTIONS_DATA, response.data);
      }
    } catch (e) {
      commit(CrossSectionsMutations.SET_LOAD_ERROR, (e as Error).message);
    } finally {
      commit(CrossSectionsMutations.SET_LOADING_STATE, false);
    }
  },

  async [CrossSectionsActions.GET_DEFECT_DATA]({ commit }, payload: string): Promise<void> {
    commit(CrossSectionsMutations.SET_DEFECT_LOADING, true);
    commit(CrossSectionsMutations.SET_DEFECT_DATA, undefined);
    commit(CrossSectionsMutations.SET_DEFECT_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getMSIData(payload);

      commit(CrossSectionsMutations.SET_DEFECT_DATA, response.data);
    } catch (e) {
      commit(CrossSectionsMutations.SET_DEFECT_ERROR, (e as Error).message);
    } finally {
      commit(CrossSectionsMutations.SET_DEFECT_LOADING, false);
    }
  },

  async [CrossSectionsActions.CLEAR_CROSS_SECTIONS]({ commit }): Promise<void> {
    commit(CrossSectionsMutations.SET_LOADING_STATE, true);
    commit(CrossSectionsMutations.SET_LOAD_ERROR, undefined);
    try {
      commit(CrossSectionsMutations.SET_CROSS_SECTIONS_DATA, undefined);
    } catch (e) {
      commit(CrossSectionsMutations.SET_LOAD_ERROR, (e as Error).message);
    } finally {
      commit(CrossSectionsMutations.SET_LOADING_STATE, false);
    }
  },

  async [CrossSectionsActions.GET_PVD_DETAILS]({ commit }, payload: string): Promise<void> {
    commit(CrossSectionsMutations.SET_PVD_LOADING, true);
    commit(CrossSectionsMutations.SET_PVD_DATA, undefined);
    commit(CrossSectionsMutations.SET_PVD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getPVDDetails(payload);

      commit(CrossSectionsMutations.SET_PVD_DATA, response.data.result);
    } catch (e) {
      commit(CrossSectionsMutations.SET_PVD_ERROR, (e as Error).message);
    } finally {
      commit(CrossSectionsMutations.SET_PVD_LOADING, false);
    }
  },
};
