import { MutationTree } from 'vuex';
import { msiPayoutState } from './types';
import { IPDEntry } from '../asset/types';

// eslint-disable-next-line no-shadow
export enum msiPayoutMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_CURRENT_IPD = 'SET_CURRENT_IPD',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
}

export const mutations: MutationTree<msiPayoutState> = {
  [msiPayoutMutations.SET_CURRENT_IPD](state, payload: IPDEntry) {
    state.currentPayout = payload;
  },
  [msiPayoutMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
  [msiPayoutMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
};
