import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { ReportState } from './types';
import { ReportMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum ReportActions {
  GET_CANNED_PDF_URI = 'GET_CANNED_PDF_URI',
}

export const actions: ActionTree<ReportState, RootState> = {
  async [ReportActions.GET_CANNED_PDF_URI]({ commit }, payload: string): Promise<void> {
    commit(ReportMutations.SET_LOADING_STATE, true);
    commit(ReportMutations.SET_PDF_URI, undefined);
    commit(ReportMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getCannedPDF(payload);

      commit(ReportMutations.SET_PDF_URI, response.data);
    } catch (e) {
      commit(ReportMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(ReportMutations.SET_LOADING_STATE, false);
    }
  },
};
