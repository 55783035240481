










































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import { BasicSelect } from 'vue-search-select';

@Component({
  components: {
    BasicSelect,
  },
})
export default class MPSSectionProjects extends Vue {
  @Prop() readonly canEditMPS: boolean;

  @Prop() readonly requiredField;

  @PropSync('valid') synchedValid;

  @PropSync('contigencyItems') synchedContigencyItems;

  @PropSync('reconRequiredSync') reconRequired;

  @PropSync('onsiteMeetingAddrSync') onsiteMeetingAddr;

  @PropSync('workHourOptionsSync') workHoursOptions;

  @PropSync('safetyPlanOptions') synchedSafetyPlanOptions;

  @PropSync('siteSafetyOptions') synchedSiteSafetyOptions;

  @PropSync('rescueTeamOptions') synchedRescueTeamOptions;

  @PropSync('permitsAndAuthorizationLettersOptions') synchedPermitsAndAuthorizationLettersOptions;

  @PropSync('permitsAndAuthorizationLettersText') synchedPermitsAndAuthorizationLettersText;

  @PropSync('signageOptions') synchedSignageOptions;

  @PropSync('signageText') synchedSignageText;

  @PropSync('doorHangersOptions') synchedDoorHangersOptions;

  @PropSync('trafficControlOptions') synchedTrafficControlOptions;

  @PropSync('trafficControlResponsibleOptions') synchedTrafficControlResponsibleOptions;

  @PropSync('jetTruckOptions') synchedJetTruckOptions;

  @PropSync('pipeCleaningOptions') synchedPipeCleaningOptions;

  @PropSync('pipeCleaningResponsibilityOptions') synchedPipeCleaningResponsibilityOptions;

  addContigencyItemDialog = false;

  editMode = false;

  editIndex = 0;

  contigencyOptions = [
    'Mobilization',
    'Demoibilization',
    'Traffic control',
    'Cleaning',
    'Standby rates',
    'Insertions',
    'Tagging Qty',
  ];

  unitOfMeasureList: string[][] = [
    [],
    [],
    ['HR', 'Day', 'Lump Sum', 'Other'],
    ['HR', 'Site', 'Day'],
    ['HR', 'Day'],
    [],
    [],
  ];

  selectedUnitOfMeasure = '';

  selectedContigency = '';

  selectedQuantity = 0;

  selectedPrice = 0;

  editContigencyItem(index: number): void {
    const selectedItem = this.synchedContigencyItems[index];
    this.selectedContigency = selectedItem.contigencyName;
    this.selectedQuantity = selectedItem.quantity;
    this.selectedUnitOfMeasure = selectedItem.unitOfMeasure;
    this.selectedPrice = selectedItem.price;

    this.editMode = true;
    this.editIndex = index;

    this.addContigencyItemDialog = true;
  }

  removeContigencyItem(index: number): void {
    this.synchedContigencyItems.splice(index, 1);
  }

  addContigencyItem(): void{
    this.synchedContigencyItems.push({
      contigencyName: this.selectedContigency,
      unitOfMeasure: this.unitOfMeasureItems.length === 0 ? '' : this.selectedUnitOfMeasure,
      quantity: this.selectedQuantity,
      price: this.selectedPrice,
    });
    this.resetContigencyItemForm();
  }

  saveEditContigencyItem(): void{
    this.synchedContigencyItems.splice(this.editIndex, 1, {
      contigencyName: this.selectedContigency,
      unitOfMeasure: this.unitOfMeasureItems.length === 0 ? '' : this.selectedUnitOfMeasure,
      quantity: this.selectedQuantity,
      price: this.selectedPrice,
    });
    this.resetContigencyItemForm();
  }

  resetContigencyItemForm(): void{
    this.addContigencyItemDialog = false;
    this.selectedContigency = '';
    this.selectedQuantity = 0;
    this.selectedPrice = 0;

    this.selectedUnitOfMeasure = '';
  }

  get unitOfMeasureItems(): string[] {
    if (this.selectedContigency === '') {
      return [];
    }
    const index = this.contigencyOptions.indexOf(this.selectedContigency);
    return this.unitOfMeasureList[index];
  }

  mounted(): void{
    this.synchedValid = true;
  }

  resetValidation(): void {
    (this.$refs.ProjectsForm as any).reset();
  }
}
