























































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { InvoicingInfo } from './types';

@Component({})
export default class MPSSectionInvoicing extends Vue {
  @Prop() readonly canEditMPS: boolean;

  @Prop() readonly requiredField;

  @PropSync('valid') synchedValid;

  @PropSync('invoicingInfo') syncedInvoicingInfo;

  @PropSync('billingFrequency') syncedBillingFrequency;

  @PropSync('termsAndConditions') syncedTermsAndConditions;

  @PropSync('currencyType') syncedCurrencyType;

  @PropSync('additionalBillingDetails') syncedAdditionalBillingDetails;

  @PropSync('ratesAndWages') syncedRatesAndWages;

  @PropSync('leinWaver') syncedLeinWaver;

  @PropSync('certifiedPayroll') syncedCertifiedPayroll;

  @PropSync('payrollFrequency') syncedPayrollFrequency;

  @PropSync('redzoneW9') syncedRedzoneW9;

  @PropSync('redzoneACH') syncedRedzoneACH;

  @Watch('newInvoicingPhone')
  onNewInvoicingPhoneChange(): void {
    this.formatPhone();
  }

  @Watch('newPhoneRegion')
  onNewPhoneRegionChange(): void {
    this.formatPhone();
    this.formatRegionCode();
  }

  @Watch('newPhoneCode')
  onNewPhoneRegionCodeChange(): void {
    this.formatRegionCode();
  }

  newInvoicingFirstName = '';

  newInvoicingLastName = '';

  newInvoicingTitle = '';

  newPhoneRegion = {
    name: 'United States',
    placeholder: '(123) 456-7890',
    rules: [(v: string): string | boolean | number => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || 'Phone number not valid'],
  };

  newPhoneCode = '+1';

  newInvoicingPhone = '';

  newInvoicingEmail = '';

  newInvoicingDept = '';

  newInvoicingAddress = '';

  newInvoicingCompany = '';

  addInvoicingInfoDialog = false;

  isEditing = false;

  editIndex = -1;

  billingIntervals = [
    'Weekly',
    'BiWeekly',
    'Monthly',
    'BiMonthly',
    'Other (Requires Additional Details)',
  ];

  currencies = ['US Dollar ($)', 'Canadian Dollar ($)'];

  emailRules = [(v: string): string | boolean => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email not valid'];

  phoneRegions = [
    {
      name: 'United States',
      placeholder: '(123) 456-7890',
      rules: [(v: string): string | boolean | number => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || 'Phone number not valid'],
    },
    {
      name: 'International',
      placeholder: '123 456 7890',
      rules: [(v: string): string | boolean | number => /^(\+[1-9]{1,2}\s)?(\d{3}\s){2}\d{4}(\s\d{4})?$/.test(v) || 'Phone number not valid'],
    },
  ];

  removeInvoicingInfo(index: number): void {
    this.syncedInvoicingInfo.splice(index, 1);
  }

  addInvoicingInfo(): void {
    const newInvoicingInfo = {
      firstName: this.newInvoicingFirstName,
      lastName: this.newInvoicingLastName,
      title: this.newInvoicingTitle,
      phoneDetails: this.newPhoneRegion,
      phoneCode: this.newPhoneCode,
      phone: this.newInvoicingPhone,
      email: this.newInvoicingEmail,
      dept: this.newInvoicingDept,
      address: this.newInvoicingAddress,
      company: this.newInvoicingCompany,
    };

    if (this.isEditing) {
      this.removeInvoicingInfo(this.editIndex);
    }

    this.syncedInvoicingInfo.push(newInvoicingInfo);
    this.isEditing = false;
    this.resetForm();
  }

  editInvoicing(info: InvoicingInfo, index: number): void {
    this.newInvoicingFirstName = info.firstName;
    this.newInvoicingLastName = info.lastName;
    this.newInvoicingTitle = info.title;
    this.newInvoicingDept = info.dept;
    this.newPhoneRegion = info.phoneDetails;
    this.newPhoneCode = info.phoneCode;
    this.newInvoicingPhone = info.phone;
    this.newInvoicingEmail = info.email;
    this.newInvoicingAddress = info.address;
    this.newInvoicingCompany = info.company;
    this.addInvoicingInfoDialog = true;
    this.isEditing = true;
    this.editIndex = index;
  }

  formatRegionCode(): void {
    if (!this.newPhoneCode.includes('+')) {
      this.newPhoneCode = `+${this.newPhoneCode}`;
    }
  }

  formatPhone(): void {
    if (this.newPhoneRegion.name === 'United States') {
      const x = this.newInvoicingPhone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.newInvoicingPhone = !x[2]
        ? x[1]
        : `(${x[1]}) ${x[2]}${x[3]
          ? `-${x[3]}`
          : ''}`;
    } else if (this.newPhoneRegion.name === 'International') {
      const x = this.newInvoicingPhone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/);
      // eslint-disable-next-line no-nested-ternary
      this.newInvoicingPhone = !x[2]
        ? x[1]
        : x[4]
          ? `${x[1]} ${x[2]} ${x[3]} ${x[4]}`
          : `${x[1]} ${x[2]} ${x[3]}`;
    }
  }

  resetForm(): void {
    this.newInvoicingFirstName = '';
    this.newInvoicingLastName = '';
    this.newInvoicingTitle = '';
    this.newInvoicingPhone = '';
    [this.newPhoneRegion] = this.phoneRegions;
    this.newPhoneCode = '+1';
    this.newInvoicingEmail = '';
    this.newInvoicingDept = '';
    this.newInvoicingCompany = '';
    this.newInvoicingAddress = '';
    this.addInvoicingInfoDialog = false;
  }

  resetValidation(): void {
    (this.$refs.invoicingForm as any).reset();
  }
}
