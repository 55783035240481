import convert from 'convert-units';

function getDistanceUnitsFtM(displayImperial: boolean): string {
  return displayImperial ? 'ft' : 'm';
}

function getDistanceUnitsInMM(displayImperial: boolean): string {
  return displayImperial ? '"' : 'mm';
}

function getDisplayDistanceFtM(displayImperial: boolean, value: number): number {
  return displayImperial
    ? Math.round(value * 10) / 10
    : Math.round(convert(value).from('ft').to('m') * 10) / 10;
}

function getDisplayDistanceInMM(displayImperial: boolean, value: number): number {
  return displayImperial
    ? Math.round(value * 10) / 10
    : Math.round(convert(value).from('in').to('mm') * 10) / 10;
}

function getDisplayDistanceInM3(displayImperial: boolean, value: number): number {
  return displayImperial
    ? Math.round(value * 10) / 10
    : Math.round(convert(value).from('ft3').to('m3') * 10) / 10;
}

function getScoreColor(score: number): string {
  switch (score) {
    case 0:
    case 1:
    case 2:
      return '#008000';
    case 3:
      return '#FFFF00';
    case 4:
      return '#FFA500';
    case 5:
      return '#FF0000';
    default:
      return '#0C6599';
  }
}

function numberWithCommas(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default {
  getDistanceUnitsFtM,
  getDistanceUnitsInMM,
  getDisplayDistanceFtM,
  getDisplayDistanceInMM,
  getDisplayDistanceInM3,
  getScoreColor,
  numberWithCommas,
};
