



















import { Component } from 'vue-property-decorator';
import IntegrityPhoneNumberInput from '../../IntegrityPhoneNumberInput/IntegrityPhoneNumberInput.vue';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import CustomerEditDialog from './CustomerEditDialog.vue';

@Component({
  components: {
    IntegrityPhoneNumberInput,
    CustomerEditDialog,
  },
})
export default class NewCustomerPopup extends AdminPanelMixin {
  showNewOrgPopupDialog = false;
}
