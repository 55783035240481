






























/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs/legacy';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartData,
} from 'chart.js';
import {
  DataDeliveryCount,
  DataDeliveryProjectMetrics,
} from '@/store/metrics/types';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Roboto';

@Component({ components: { Bar } })
export default class DataDeliveryChart extends Vue {
  @Prop({ default: 'label' }) readonly datasetIdKey: string;

  @Prop() readonly projectGuid: string;

  @Prop() readonly dataDeliveryProjectMetrics:
    | DataDeliveryProjectMetrics
    | undefined;

  chartId = 'dataDeliveryChart';

  chartClass = 'data-delivery-chart';

  isShowingIssues = false;

  plugins = [];

  get chartOptions(): any {
    return {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 0.7,
      scales: {
        x: {
          stacked: true,
          display: false,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
        },
      },
      onClick: (e, items, chart) => {
        const { datasetIndex } = items[0];
        const dataIndex = items[0].index;
        const deliveryStatus = chart.data.datasets[datasetIndex].label;
        const platform = chart.data.labels[dataIndex];

        const query = {
          deliveryStatus,
        };

        if (platform !== 'Total') {
          query['platform'] = platform;
        }

        this.$router
          .push({
            path: `/projects/["${this.projectGuid}"]/deployments`,
            query,
          })
          .then(() => {
            this.$router.go(0);
          });
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              let total = 0;
              if (context.dataIndex === 0) {
                total = this.platfromTotal(
                  this.dataDeliveryProjectMetrics.overall,
                );
              } else {
                total = this.platfromTotal(
                  this.dataDeliveryProjectMetrics.platforms[
                    context.dataIndex - 1
                  ],
                );
              }
              return `${context.dataset.label}: ${Math.round(
                context.raw * total,
              )}`;
            },
          },
        },
      },
    };
  }

  platfromTotal(platform: DataDeliveryCount): number {
    if (!this.platformHasNeededData(platform)) {
      return undefined;
    }
    return platform.arrived + platform.late + platform.pending;
  }

  get chartData(): ChartData | undefined {
    if (!this.dataDeliveryProjectMetrics) {
      return null;
    }

    const total = this.platfromTotal(this.dataDeliveryProjectMetrics.overall);

    const arrivedTotal = this.dataDeliveryProjectMetrics.overall.arrived / total;
    const pendingTotal = this.dataDeliveryProjectMetrics.overall.pending / total;
    const lateTotal = this.dataDeliveryProjectMetrics.overall.late / total;

    let labels = [...this.dataDeliveryProjectMetrics.platforms.map(
      (platform) => platform.platformName.replace(' Collection', ''),
    )];

    labels.unshift('Total');

    const arrivedCounts = this.dataDeliveryProjectMetrics?.platforms
    && this.dataDeliveryProjectMetrics.platforms.length > 0
      ? this.dataDeliveryProjectMetrics.platforms.map(
        (platform) => (this.platfromTotal(platform) != null
          ? platform.arrived / this.platfromTotal(platform) : null),
      )
      : undefined;

    if (arrivedCounts) {
      arrivedCounts.unshift(arrivedTotal);
    }

    const pendingCounts = this.dataDeliveryProjectMetrics?.platforms
    && this.dataDeliveryProjectMetrics.platforms.length > 0
      ? this.dataDeliveryProjectMetrics.platforms.map(
        (platform) => (this.platfromTotal(platform) != null
          ? platform.pending / this.platfromTotal(platform) : null),
      )
      : undefined;

    if (pendingCounts) {
      pendingCounts.unshift(pendingTotal);
    }

    const lateCounts = this.dataDeliveryProjectMetrics?.platforms
    && this.dataDeliveryProjectMetrics.platforms.length > 0
      ? this.dataDeliveryProjectMetrics.platforms.map(
        (platform) => (this.platfromTotal(platform) != null
          ? platform.late / this.platfromTotal(platform) : null),
      )
      : undefined;

    if (lateCounts) {
      lateCounts.unshift(lateTotal);
    }

    let returnDatasets = [
      {
        label: 'Arrived',
        data: arrivedCounts,
        backgroundColor: '#0C6599',
      },
      {
        label: 'Pending',
        data: pendingCounts,
        backgroundColor: '#CCCCCC',
      },
      {
        label: 'Late',
        data: lateCounts,
        backgroundColor: '#E61E25',
      },
    ];

    if ((arrivedCounts == null || arrivedCounts.length === 0)
      && (pendingCounts == null || pendingCounts.length === 0)
      && (lateCounts == null || lateCounts.length === 0)) {
      returnDatasets = [
        {
          label: 'No Data',
          data: [1],
          backgroundColor: 'gray',
        },
      ];
      labels = ['Total'];
    }

    return {
      labels,
      datasets: returnDatasets,
    };
  }

  platformHasNeededData(platform: DataDeliveryCount): boolean {
    return platform.arrived != null && platform.late != null && platform.pending != null;
  }
}
