import { InspectionResponse } from '@/components/CodingForm/types';
import { MutationTree } from 'vuex';
import {
  InspectionState, Condition,
} from './types';

// eslint-disable-next-line no-shadow
export enum InspectionMutations {
    SET_CODING_DETAIL = 'SET_CODING_DETAIL',
    SET_CODING_STATE = 'SET_CODING_STATE',
    SET_CODING_ERROR = 'SET_CODING_ERROR',
    SET_CONDITION_DETAIL = 'SET_CONDITION_DETAIL',
    SET_CONDITION_STATE = 'SET_CONDITION_STATE',
    SET_CONDITION_ERROR = 'SET_CONDITION_ERROR',
    SET_CODING_FORM = 'SET_CODING_FORM',
    SET_SUB_CODING_FORM = 'SET_SUB_CODING_FORM'
}

export const mutations : MutationTree<InspectionState> = {
  [InspectionMutations.SET_CODING_DETAIL](state, payload: InspectionResponse) {
    state.codingDetail = payload;
    payload.inspections.forEach((insp, index) => {
      if (insp.dataStandard_Json != null && insp.dataStandard_Json !== '') {
        state.codingDetail.inspections[index].dataStandard_Json = typeof insp.dataStandard_Json === 'string'
          ? JSON.parse(insp.dataStandard_Json as string)
          : insp.dataStandard_Json;
      }
    });
  },
  [InspectionMutations.SET_CODING_STATE](state, payload: boolean) {
    state.codingState = payload;
  },
  [InspectionMutations.SET_CODING_ERROR](state, payload: string) {
    state.codingError = payload;
  },
  [InspectionMutations.SET_CONDITION_DETAIL](state, payload: Condition[]) {
    state.conditionDetails = payload;
  },
  [InspectionMutations.SET_CONDITION_STATE](state, payload: boolean) {
    state.conditionState = payload;
  },
  [InspectionMutations.SET_CONDITION_ERROR](state, payload: string) {
    state.conditionError = payload;
  },
  [InspectionMutations.SET_CODING_FORM](state, payload) {
    state.fullCodingForm = payload;
  },
  [InspectionMutations.SET_SUB_CODING_FORM](state, payload) {
    state.subFullCodingForm = payload;
  },
};
