






























































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AssetRef } from '@/types';
import { uuid } from 'vue-uuid';
import {
  AGOLFeatureService,
  ImportMapping,
  ImportMappingContainer,
} from '@/store/agol/types';
import ProjectStoreMixin from '@/store/project/ProjectStoreMixin.vue';
import AgolStoreMixin from '@/store/agol/AgolStoreMixin.vue';
import { loadModules } from 'esri-loader';
import cookies from 'cookiesjs';
import AgolConstantsMixin, {
  RedzoneMappingFieldNames,
} from './AgolConstantsMixin.vue';
import AgolMappingDialog from './AgolMappingDialog.vue';
import IntegrityDelete from '../IntegrityDelete/IntegrityDelete.vue';

// eslint-disable-next-line no-shadow
const enum LoggedInEnum {
  LOADING,
  LOGGED_IN,
  LOGGED_OUT,
}

@Component({
  components: {
    AgolMappingDialog,
  },
})
export default class ImportAgol extends Mixins(
  AgolStoreMixin,
  ProjectStoreMixin,
  AgolConstantsMixin,
  IntegrityDelete,
) {
  @Prop() readonly projectGuid: string | undefined;

  snack = false;

  snackBarMessage = '';

  snackColor = 'black';

  agolAssets = [];

  featureServerLink = '';

  features = [];

  fieldNames = [];

  selectedId = null;

  hasValidFeatureServerLink = false;

  selectingMappings = false;

  selectedMappingName?: string = null;

  selectedMappingAllowDataPolling?: boolean = null;

  selectedMappingKey = false;

  selectedMapping?: ImportMappingContainer = null;

  appliedMapping?: ImportMappingContainer;

  hasAppliedMapping = false;

  validMappingSelection = false;

  errorDialog = false;

  deleteConfirmDialog = false;

  addMappingDialog = false;

  editMappingDialog = false;

  updating = false;

  userToken = '';

  refreshToken = '';

  Portal = undefined;

  oAuthInfo = undefined;

  identityManager = undefined;

  loggedIn = LoggedInEnum.LOGGED_OUT;

  get loginText(): string {
    if (this.loggedIn === LoggedInEnum.LOADING) { return 'Checking ArcGIS Sign in status...'; }
    if (this.loggedIn === LoggedInEnum.LOGGED_OUT) return 'Connect to ArcGIS';
    return 'Logout of ArcGIS';
  }

  filterMappingType(value: ImportMappingContainer): boolean {
    return !value.mappingType || (value.mappingType === 'Manhole') === this.isManhole;
  }

  get mappingNames(): string[] {
    return this.agolMappingData != null
      ? this.agolMappingData
        .filter((value) => this.filterMappingType(value))
        .map((value) => value.name)
      : [];
  }

  get agolMappingDataHasError(): boolean {
    return this.agolMappingDataError != null;
  }

  async mounted(): Promise<void> {
    this.checkArcGisLoginStatus();
    this.getAgolMappings(this.projectGuid).catch(() => this.updateSnackBar(this.agolMappingDataError, 'red'));
    await this.fetchProjectData([this.projectGuid]);
  }

  async onClickDownloadFields(isManhole: boolean): Promise<void> {
    this.isManhole = isManhole;
    this.hasValidFeatureServerLink = false;
    await this.$forceUpdate();
    await this.fetchAgolFields({
      payload: this.featureServerLink,
      auth: this.userToken,
    }).catch((error) => error);
    if (this.agolFeatureServiceDataError) {
      this.updateSnackBar('Invalid feature link', 'red');
    } else {
      this.applyDownloadedFields(this.agolFeatureServerData);
      this.hasValidFeatureServerLink = true;
    }
  }

  applyDownloadedFields(value: AGOLFeatureService): void {
    this.selectedId = value.objectIdField;
    this.agolFeatureServerData.fields.forEach((field) => {
      this.fieldNames.push(field.name);
    });
  }

  async onClickDownloadFeatures(): Promise<void> {
    if (this.selectedId === null) return;

    await this.fetchAgolFeatures({
      payload: this.featureServerLink,
      auth: this.userToken,
      primaryId: this.selectedId,
    });
    this.features = (this.agolData as any).map((x) => x.attributes);
  }

  async onClickImportFeatures(): Promise<void> {
    if (this.selectedId === null || !this.isFieldMappingsValid) {
      return;
    }

    this.agolAssets = [];

    this.features.forEach((asset) => {
      // build a compliant asset
      const newAsset = this.buildAsset(asset);
      // check if it's in project assets already
      const assetIndex = this.project.assets.findIndex(
        (x) => x.name === newAsset.name,
      );
      this.agolAssets.push(newAsset);
      if (assetIndex === -1) {
        this.project.assets.push(newAsset);
      } else {
        this.project.assets.splice(assetIndex, 1);
        this.project.assets.push(newAsset);
        this.validMappingSelection = true;
        this.applySelectedMapping();
      }
    });
  }

  updateMappingTextWithNameWithMappingGuid(mappingGuid: string = null): void {
    this.selectedMapping = this.agolMappingData.find(
      (value) => value.mappingGuid === mappingGuid,
    );
    this.validMappingSelection = true;
    this.applySelectedMapping();
  }

  applySelectedMapping(): void {
    this.clearMapping(false);
    this.closeSelectMapping(false);
    this.setMapping(this.selectedMapping);
    this.updateUIFieldsWithMapping(this.selectedMapping.mappings);
  }

  setMapping(mapping: ImportMappingContainer): void {
    this.appliedMapping = mapping;
    this.hasAppliedMapping = true;
    this.selectedMappingAllowDataPolling = mapping.allowDataPolling;
  }

  updateUIFieldsWithMapping(mappings: ImportMapping[]): void {
    let unableToFullyUpdate = false;

    mappings.forEach((map) => {
      const field = this.mappingFieldData.find(
        (value) => value.redzoneFieldName === map.redzoneField,
      );
      if (
        field == null
        || !this.isValidExternalField(field.isRequired, map.externalField)
      ) {
        unableToFullyUpdate = true;
      } else {
        field.model = map.externalField;
      }
    });

    if (unableToFullyUpdate || !this.isFieldMappingsValid) {
      this.warnUserBadMappingPreset();
    }
  }

  closeSelectMapping(reset = true): void {
    this.selectingMappings = false;
    if (reset) {
      this.reset();
    }
  }

  clearMapping(reset = true): void {
    this.resetMappingFieldData();

    this.appliedMapping = null;
    this.hasAppliedMapping = false;

    if (reset) this.reset();
  }

  async deleteMapping(): Promise<void> {
    this.deleteConfirmDialog = false;

    await this.deleteAgolMappings(this.appliedMapping.mappingGuid)
      .then(this.cleanupDeleteMapping)
      .catch(() => {
        this.updateSnackBar(this.deleteAgolMappingDataError, 'red');
      });
  }

  async cleanupDeleteMapping(): Promise<void> {
    this.clearMapping();
    await this.getAgolMappings(this.projectGuid);
    this.updateSnackBar('Deleted Mapping', 'green');
  }

  createMapping(): void {
    this.addMappingDialog = true;
  }

  async submit(
    valueMappingName: string,
    allowDataPolling: boolean,
  ): Promise<void> {
    if (this.updating) {
      await this.submitUpdate(valueMappingName, allowDataPolling);
    } else {
      await this.submitCreate(valueMappingName, allowDataPolling);
    }
    this.updating = false;
  }

  closeSelectingMapping(): void {
    this.selectingMappings = false;
    this.closeSelectMapping();
  }

  updateMappingTextWithName(forceSelectMappingName: string = null): void {
    if (forceSelectMappingName != null) {
      this.selectedMappingName = forceSelectMappingName;
    }
    this.selectedMapping = this.agolMappingData.find(
      (value) => value.name === this.selectedMappingName,
    );
    this.validMappingSelection = true;
    this.applySelectedMapping();
  }

  isValidExternalField(
    isRequired: boolean,
    field: string | undefined,
  ): boolean {
    if (field == null && !isRequired) {
      return true;
    }
    return this.fieldNames.find((value) => value === field) != null;
  }

  warnUserBadMappingPreset(): void {
    this.errorDialog = true;
  }

  openSelectMapping(): void {
    if (this.appliedMapping != null) {
      this.selectedMappingName = this.appliedMapping.name;
      this.updateMappingTextWithName();
    }
  }

  reset(): void {
    this.validMappingSelection = false;
    this.selectedMappingName = null;
    this.selectedMapping = null;
    this.selectedMappingKey = !this.selectedMappingKey;
    this.selectedMappingAllowDataPolling = false;
  }

  updateMapping(): void {
    this.updating = true;
  }

  updateSnackBar(message: string, color: string): void {
    this.snackBarMessage = message;
    this.snackColor = color;
    this.snack = true;
  }

  async isValidWithSnackbar(
    errorMessage: string,
    valueMappingName: string,
  ): Promise<boolean> {
    if (
      !this.isFieldMappingsValid
      || valueMappingName == null
      || valueMappingName === ''
    ) {
      this.updateSnackBar(errorMessage, 'red');
      return false;
    }
    return true;
  }

  async submitCreate(
    valueMappingName: string,
    allowDataPolling: boolean,
  ): Promise<void> {
    this.addMappingDialog = false;
    if (
      !(await this.isValidWithSnackbar(
        'Cannot Create New Mapping with Incomplete Mappings',
        valueMappingName,
      ))
    ) { return; }
    const apiObject = this.formMappingObject(
      valueMappingName,
      allowDataPolling,
    );
    await this.postAgolMappings({
      projectGuid: this.projectGuid,
      payload: apiObject,
    })
      .then(() => this.submitCleanup(valueMappingName, 'Mapping Created'))
      .catch(() => this.handleSubmitErrorCleanup(this.postAgolMappingDataError));
  }

  async submitUpdate(
    valueMappingName: string,
    allowDataPolling: boolean,
  ): Promise<void> {
    this.editMappingDialog = false;
    if (
      !(await this.isValidWithSnackbar(
        'Cannot Update Incomplete Mappings',
        valueMappingName,
      ))
    ) { return; }
    this.appliedMapping.name = valueMappingName;
    const apiObject = this.formMappingObject(
      valueMappingName,
      allowDataPolling,
    );
    await this.patchAgolMappings({
      mappingGuid: apiObject.mappingGuid,
      payload: apiObject,
    })
      .then(() => this.submitCleanup(valueMappingName, 'Mapping Updated'))
      .catch(() => this.handleSubmitErrorCleanup(this.patchAgolMappingDataError));
  }

  async handleSubmitErrorCleanup(error: string): Promise<void> {
    this.clearMapping();
    const guid = this.selectedMapping?.mappingGuid;
    await this.getAgolMappings(this.projectGuid);
    if (guid) this.updateMappingTextWithNameWithMappingGuid(guid);
    this.updateSnackBar(error, 'red');
  }

  async submitCleanup(
    valueMappingName: string,
    snackBarMessage: string,
  ): Promise<void> {
    this.clearMapping();
    await this.getAgolMappings(this.projectGuid);
    this.updateMappingTextWithName(valueMappingName);
    this.updateSnackBar(snackBarMessage, 'green');
  }

  formMappingObject(
    valueMappingName: string,
    allowDataPolling: boolean,
  ): ImportMappingContainer {
    return {
      mappingGuid:
        this.appliedMapping != null ? this.appliedMapping.mappingGuid : null,
      name: valueMappingName,
      allowDataPolling,
      mappingType: this.isManhole ? 'Manhole' : 'Line Segment',
      mappings: this.mappingFieldData.map(
        (value): ImportMapping => ({
          redzoneField: value.redzoneFieldName,
          externalField: value.model,
        }),
      ),
    };
  }

  buildAsset(asset: any): AssetRef {
    return this.isManhole
      ? this.buildManhole(asset)
      : this.buildLineSegment(asset);
  }

  addAttributeIndex(
    attr: any,
    index: string,
    asset: any,
    mappingField: RedzoneMappingFieldNames,
  ): void{
    const mappingValue = this.findModelForMappingField(mappingField);
    const assetValue = asset[mappingValue];
    if (!assetValue) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    attr[index] = assetValue;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  buildLineSegment(asset: any): AssetRef {
    const attr = {};
    this.addAttributeIndex(attr, 'Wastewater', asset, RedzoneMappingFieldNames.NAME);
    this.addAttributeIndex(attr, 'RZMapPage', asset, RedzoneMappingFieldNames.MAPPAGE);
    this.addAttributeIndex(attr, 'Wastewater_Structure_Up', asset, RedzoneMappingFieldNames.UPMANHOLE);
    this.addAttributeIndex(attr, 'Wastewater_Structure_Up_Latitiude', asset, RedzoneMappingFieldNames.UPLAT);
    this.addAttributeIndex(attr, 'Wastewater_Structure_Dn_Longitude', asset, RedzoneMappingFieldNames.UPLONG);
    this.addAttributeIndex(attr, 'Wastewater_Structure_Dn', asset, RedzoneMappingFieldNames.DOWNMANHOLE);
    this.addAttributeIndex(attr, 'Wastewater_Structure_Dn_Latitiude', asset, RedzoneMappingFieldNames.DOWNLAT);
    this.addAttributeIndex(attr, 'Wastewater_Structure_Up_Longitude', asset, RedzoneMappingFieldNames.DOWNLONG);
    this.addAttributeIndex(attr, 'PipeSize_Width', asset, RedzoneMappingFieldNames.PIPEWIDTH);
    this.addAttributeIndex(attr, 'PipeShape', asset, RedzoneMappingFieldNames.PIPESHAPE);
    this.addAttributeIndex(attr, 'PipeMaterial', asset, RedzoneMappingFieldNames.PIPEMATERIAL);
    this.addAttributeIndex(attr, 'LengthGIS', asset, RedzoneMappingFieldNames.LENGTH);
    return {
      name: `${
        asset[this.findModelForMappingField(RedzoneMappingFieldNames.NAME)]
      }`,
      guid: uuid.v4(),
      priority: 0,
      status: '',
      type: 'Line Segment',
      inspectionDate: 'Invalid Date',
      latitude:
        asset[this.findModelForMappingField(RedzoneMappingFieldNames.UPLAT)],
      longitude:
        asset[this.findModelForMappingField(RedzoneMappingFieldNames.UPLONG)],
      location: [
        {
          latitude:
            asset[
              this.findModelForMappingField(RedzoneMappingFieldNames.UPLAT)
            ],
          longitude:
            asset[
              this.findModelForMappingField(RedzoneMappingFieldNames.UPLONG)
            ],
          source: 'CustomerData',
        },
        {
          latitude:
            asset[
              this.findModelForMappingField(RedzoneMappingFieldNames.DOWNLAT)
            ],
          longitude:
            asset[
              this.findModelForMappingField(RedzoneMappingFieldNames.DOWNLONG)
            ],
          source: 'CustomerData',
        },
      ],
      upstream: `${
        asset[this.findModelForMappingField(RedzoneMappingFieldNames.UPMANHOLE)]
      }`,
      downstream: `${
        asset[
          this.findModelForMappingField(RedzoneMappingFieldNames.DOWNMANHOLE)
        ]
      }`,
      attributes: JSON.stringify(attr),
      inspections: [],
      laterals: null,
      overallScoring: null,
      hasCustomerDeliverables: false,
      visible: false,
      owner: null,
      sewerUse: `${
        asset[this.findModelForMappingField(RedzoneMappingFieldNames.SEWERUSE)]
      }`,
      assetType: null,
      validation: '',
      photos: [],
    };
  }

  buildManhole(asset: any): AssetRef {
    return {
      name: `${
        asset[this.findModelForMappingField(RedzoneMappingFieldNames.ASSED_ID)]
      }`,
      guid: uuid.v4(),
      priority: 0,
      status: '',
      type: 'Manhole',
      inspectionDate: 'Invalid Date',
      latitude:
        asset[this.findModelForMappingField(RedzoneMappingFieldNames.LATITUDE)],
      longitude:
        asset[
          this.findModelForMappingField(RedzoneMappingFieldNames.LONGITUDE)
        ],
      location: [
        {
          latitude:
            asset[
              this.findModelForMappingField(RedzoneMappingFieldNames.LATITUDE)
            ],
          longitude:
            asset[
              this.findModelForMappingField(RedzoneMappingFieldNames.LONGITUDE)
            ],
          source: 'CustomerData',
        },
      ],
      upstream: '',
      downstream: '',
      attributes: '',
      inspections: [],
      laterals: null,
      overallScoring: null,
      hasCustomerDeliverables: false,
      visible: false,
      owner: null,
      sewerUse: null,
      assetType: null,
      validation: '',
      photos: [],
    };
  }

  findModelForMappingField(redzoneField: RedzoneMappingFieldNames): string {
    return this.mappingFieldData.find(
      (value) => value.redzoneFieldName === redzoneField,
    ).model;
  }

  async onClickUploadFeatures(): Promise<void> {
    await this.onClickDownloadFeatures();
    await this.onClickImportFeatures();
    const mapping = [];
    Object.entries(this.groupedMappingFieldData).forEach(([_, value]) => {
      value.forEach((v) => {
        mapping.push(v);
      });
    });

    await this.postAgolFeatures({
      projectGuid: this.projectGuid,
      mappingGuid: this.selectedMapping?.mappingGuid,
      assets: this.agolAssets,
      refreshToken: this.refreshToken,
      fieldMappings: mapping,
      featureServerLink: this.featureServerLink,
    });
    this.clearMapping();
    this.updateSnackBar('Assets Imported Successfully', 'green');
  }

  async arcGisLoginButton(): Promise<void> {
    if (this.loggedIn === LoggedInEnum.LOGGED_OUT) {
      const verifier = this.generateRandomString();
      cookies({ agolVerifier: verifier });
      const codeChallenge = await this.generateCodeChallengeFromVerifier(verifier);

      // Go to esri login page
      window.location.href = `${this.oAuthInfo.portalUrl}/sharing/rest/oauth2/authorize?client_id=${this.oAuthInfo.appId}`
      + `&response_type=code&redirect_uri=${window.location.href}`
      + `&code_challenge=${codeChallenge}&code_challenge_method=S256`;
    } else if (this.loggedIn === LoggedInEnum.LOGGED_IN) {
      cookies({ agolVerifier: null });
      this.loggedIn = LoggedInEnum.LOGGED_OUT;
      this.reset();
      this.featureServerLink = '';
      this.hasValidFeatureServerLink = false;
      this.userToken = '';
      this.refreshToken = '';
    }
  }

  async checkArcGisLoginStatus(): Promise<void> {
    loadModules([
      'esri/portal/Portal',
      'esri/identity/OAuthInfo',
    ]).then(([Portal, OAuthInfo]) => {
      this.Portal = Portal;
      // Sets up requests
      this.oAuthInfo = new OAuthInfo({
        // Integrity client id
        appId: '0MkS4ghjJvPflHui',
        popup: false, // the default
      });
      const regex = /code=(.*?)(?=&|$)/g;
      const foundResults = regex.exec(window.location.href);
      const verifier = cookies('agolVerifier');

      if (foundResults === null) {
        return;
      }
      if (foundResults.length > 1 && verifier != null) {
        this.$router.replace({ query: null });
        this.loggedIn = LoggedInEnum.LOADING;
        this.getAgolLoginData({
          url: `${this.oAuthInfo.portalUrl}/sharing/rest/oauth2/token`,
          client_id: this.oAuthInfo.appId,
          code: foundResults[1],
          code_verifier: verifier,
          redirect_uri: window.location.href.split('?')[0],
        }).then(() => {
          if (this.agolLoginData?.access_token == null
          || this.agolLoginData?.refresh_token == null) {
            this.loggedIn = LoggedInEnum.LOGGED_OUT;
          } else {
            this.refreshToken = this.agolLoginData.refresh_token;
            this.userToken = this.agolLoginData.access_token;
            this.loggedIn = LoggedInEnum.LOGGED_IN;
          }
          cookies({ agolVerifier: null });
        }).catch(() => {
          this.loggedIn = LoggedInEnum.LOGGED_OUT;
          cookies({ agolVerifier: null });
        });
      } else if (foundResults.length > 1) {
        this.$router.replace({ query: null });
        cookies({ agolVerifier: null });
      }
    });
  }

  // Unused, afaict only useful for getting user email/username
  // getCredentials(): void {
  //   const portal = new this.Portal();
  //   portal.load().then(() => {
  //     const results = {
  //       name: portal.user.fullName,
  //       username: portal.user.username,
  //     };
  //     console.log(portal);
  //     console.log(JSON.stringify(results, null, 2));
  //     this.loggedIn = LoggedInEnum.LOGGED_IN;
  //   });
  // }

  // Code Verifier from https://docs.cotter.app/sdk-reference/api-for-other-mobile-apps/api-for-mobile-apps#step-1-create-a-code-verifier
  dec2hex(dec: number): string {
    return (`0${dec.toString(16)}`).substr(-2);
  }

  generateRandomString(): string {
    const array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    const returnArray: string[] = [];
    array.forEach((value) => returnArray.push(this.dec2hex(value)));
    return returnArray.join('');
  }
  // end Code Verifier

  // CodeChallenge from https://docs.cotter.app/sdk-reference/api-for-other-mobile-apps/api-for-mobile-apps#step-1-create-a-code-verifier
  async sha256(plain: string): Promise<ArrayBuffer> {
    // returns promise ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }

  base64urlencode(a: ArrayBuffer): string {
    let str = '';
    const bytes = new Uint8Array(a);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i += 1) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  async generateCodeChallengeFromVerifier(v): Promise<string> {
    const hashed = await this.sha256(v);
    const base64encoded = this.base64urlencode(hashed);
    return base64encoded;
  }
  // End CodeChallenge

  async turnOffAgol(): Promise<boolean> {
    await this.turnOffAgolMapping(this.projectGuid);
    return !this.turnOffAgolMappingError;
  }
}
