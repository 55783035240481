












































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Mapping } from '@/store/types';
import Vue from 'vue';
import iFileMapper, { iFileMapperOptions, iFileMapperWithOptions } from './iFileMapper';

export default Vue.extend({
  props: {
    rzColumns: { type: Array, required: true },
    FileColumns: { type: Array, required: true },
    AssetChosen: { type: String, required: true },
    hasGISData: { type: Boolean, required: true },
  },
  data() {
    return {
      valid: false,
      selectedFileColumn: [] as Array<string>,
      requiredField: [(v) => !!v || 'This is a required field.'],
      emailValidator: [(v: string): string | boolean => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email must be valid'],
      showAlert: false,
      alertText: '',
      emailToSendResults: this.$auth?.user?.email,
    };
  },
  watch: {
    rzColumns(newValue) {
      this.selectedFileColumn = new Array(newValue.length);
    },
  },
  methods: {
    Validate() {
      (this.$refs.formFileMap as any).validate();
    },
    onClickMatchingCanceled() {
      (this.$refs.formFileMap as any).reset();
      this.$emit('canceled');
    },
    onClickMatchingComplete() {
      if ((this.$refs.formFileMap as any).validate()) {
        // make sure the file columns are only used once
        if (this.haveDups(this.selectedFileColumn)) {
          this.alertText = 'A file column has been used multiple times.';
          this.showAlert = true;
          return;
        }

        const fileMapper: iFileMapper[] = [];
        for (let i = 0; i < this.$props.rzColumns.length; i += 1) {
          const fm: iFileMapper = {} as iFileMapper;
          if (i === 0) {
            fm.AssetSelected = this.AssetChosen;
          }
          fm.RzColumn = this.$props.rzColumns[i];
          fm.FileColumn = this.selectedFileColumn[i];
          fileMapper.push(fm);
        }

        const fileMapperOptions: iFileMapperOptions = {
          Email: this.emailToSendResults,
        };

        const fileMapperWithOptions: iFileMapperWithOptions = {
          Options: fileMapperOptions,
          Mappings: fileMapper,
        };

        this.$emit('mapped', fileMapperWithOptions);
      }
    },
    haveDups(arrayToCheck: string[]): boolean {
      let hasDups = false;
      const holdArray: string[] = [];
      arrayToCheck.every((i) => {
        if (holdArray.includes(i)) {
          hasDups = true;
          return false;
        }
        holdArray.push(i);
        return true;
      });
      return hasDups;
    },
    getRules(column: Mapping): unknown[] {
      return column.required ? this.requiredField : [];
    },
    getRequiredStyle(column: Mapping): string {
      return column.required ? 'color: red;' : '';
    },
  },
});
