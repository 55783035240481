/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from 'vuex';
import DateHelper from '../../components/ScopeAddition/DateHelper';
import {
  Mapping,
  RootState, UploadFileResults, UploadMappingResults, ZipFileMapping,
} from '../types';
import { AssetMutations } from './mutations';
import { AssetState, DeploymentFormDoNotInspectDTO, ManholeAccessPoint } from './types';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum AssetActions {
  FETCH_ASSET_DATA = 'FETCH_ASSET_DATA',
  POST_SINGLE_ASSET_DATA = 'POST_SINGLE_ASSET_DATA',
  POST_FILE_DATA = 'POST_FILE_DATA',
  POST_FILE_MAPPING_DATA = 'POST_FILE_MAPPING_DATA',
  EDIT_ASSET_DATA = 'EDIT_ASSET_DATA',
  DELETE_ASSET = 'DELETE_ASSET',
  EXPORT_ASSETS = 'EXPORT_ASSETS',
  SET_ASSET_SCREENSHOT_URL = 'SET_ASSET_SCREENSHOT_URL',
  POST_FILE_CROSS_SECTION_DATA = 'POST_FILE_CROSS_SECTION_DATA',
  POST_FILE_CROSS_SECTION_MAPPING_DATA = 'POST_FILE_CROSS_SECTION_MAPPING_DATA',
  POST_VIDEO_ORIENTATION = 'POST_VIDEO_ORIENTATION',
  POST_REPORTING_FEEDBACK = 'POST_REPORTING_FEEDBACK',
  FETCH_LINE_SEGMENT_ACCESSPOINTS = 'FETCH_LINE_SEGMENT_ACCESSPOINTS',
  PATCH_DEPLOYMENT_FORM_WORK_ORDER = 'PATCH_DEPLOYMENT_FORM_WORK_ORDER',
  FETCH_AVAILABLE_MANHOLES = 'FETCH_AVAILABLE_MANHOLES',
  POST_CREATE_NEW_LINE_SEGMENT_ACCESS_POINT = 'POST_CREATE_NEW_LINE_SEGMENT_ACCESS_POINT',
}

export const actions: ActionTree<AssetState, RootState> = {

  async [AssetActions.FETCH_ASSET_DATA]({ commit }, payload): Promise<void> {
    commit(AssetMutations.SET_LOADED_SATE, true);
    commit(AssetMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAsset(payload.assetId);

      commit(AssetMutations.SET_ASSET, response.data);
      commit(AssetMutations.SET_INITIAL_INSPECTION, payload.inspectionId);
    } catch (e) {
      commit(AssetMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AssetMutations.SET_LOADED_SATE, false);
    }
  },

  async [AssetActions.POST_SINGLE_ASSET_DATA]({ commit }, payload): Promise<void> {
    commit(AssetMutations.SET_LOADED_SATE, true);
    commit(AssetMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postSingleAsset(payload);
    } catch (e) {
      commit(AssetMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(AssetMutations.SET_LOADED_SATE, false);
    }
  },

  async [AssetActions.POST_FILE_DATA]({ commit }, masterPayload): Promise<UploadFileResults> {
    const payload = masterPayload.selectedFile;
    const selectedAsset: string = masterPayload.assetChosen;
    const { hasGISData } = masterPayload;
    const { hasInspections } = masterPayload;
    commit(AssetMutations.SET_LOAD_ERROR, undefined);
    const formData = new FormData();
    const uploadFileResults = new UploadFileResults();
    const pIdx: number = payload.name.lastIndexOf('.');
    const part1: string = payload.name.substr(0, pIdx);
    const part2: string = payload.name.substr(pIdx);
    const dateTime: string = DateHelper.Format(new Date(), 'yyyyMMddhhmmss');
    const newFileName = `${part1}_${dateTime}${part2}`;
    formData.append('files', payload, newFileName);
    formData.append('selectedAsset', selectedAsset);
    formData.append('hasGISData', hasGISData);
    formData.append('hasInspections', hasInspections);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postFile(formData);

      try {
        uploadFileResults.Complete = true;
        uploadFileResults.FileName = response.data.fileName;
        uploadFileResults.FileHeaders = response.data.headers;
        uploadFileResults.FileFirstRow = response.data.firstRow;
        uploadFileResults.RzColumns = response.data.mapFields as Mapping[];
      } catch (e: any) {
        uploadFileResults.ErrorMessage = (e.response && e.response.data) ? e.response.data : e;
      }
    } catch (e) {
      commit(AssetMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error((e as Error).message);
    }
    return uploadFileResults;
  },

  async [AssetActions.POST_FILE_MAPPING_DATA]({ commit }, payload: string) {
    commit(AssetMutations.SET_LOAD_ERROR, undefined);
    commit(AssetMutations.SET_IMPORT_LOAD, true);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postMappings(payload);
    } catch (e) {
      commit(AssetMutations.SET_LOAD_ERROR, (e as Error).message);
      commit(AssetMutations.SET_IMPORT_LOAD, false);
      throw Error((e as Error).message);
    }

    commit(AssetMutations.SET_IMPORT_LOAD, false);
  },

  async [AssetActions.EDIT_ASSET_DATA]({ commit }, payload): Promise<void> {
    commit(AssetMutations.SET_EDIT_STATE, true);
    commit(AssetMutations.SET_EDIT_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postAsset(payload.assetGuid, payload.assetOut);

      commit(AssetMutations.SET_EDIT_ASSET, response);
    } catch (e: any) {
      const { message } = e;
      commit(AssetMutations.SET_EDIT_ERROR, message);
      throw new Error(message);
    } finally {
      commit(AssetMutations.SET_EDIT_STATE, false);
    }
  },

  async [AssetActions.DELETE_ASSET]({ commit }, assetIds): Promise<void> {
    commit(AssetMutations.SET_DELETE_STATE, true);
    commit(AssetMutations.SET_LOADED_SATE, true);
    commit(AssetMutations.SET_DELETE_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.deleteAsset(assetIds);

      commit(AssetMutations.DELETE_ASSET, response);
    } catch (e: any) {
      const { message } = e;
      commit(AssetMutations.SET_DELETE_ERROR, message);
      throw new Error(message);
    } finally {
      commit(AssetMutations.SET_LOADED_SATE, false);
    }
  },

  async [AssetActions.EXPORT_ASSETS]({ commit }, payload): Promise<void> {
    commit(AssetMutations.SET_EXPORT_STATE, 'loading');
    try {
      const api = await storeUtility.useIntegrityAPI();
      const formData = new FormData();
      formData.append('joboptions', JSON.stringify(payload.exportOptions.jobOptions));
      formData.append('projectExportObjectList', JSON.stringify({ projectExportObject: payload.exportOptions.projectExportObjectList }));
      const response = await api.exportAssets(formData);
      commit(AssetMutations.SET_EXPORT_STATE, response);
    } catch (e) {
      commit(AssetMutations.SET_EXPORT_STATE, (e as Error).message);
      throw new Error('API Error Occurred');
    }
  },

  async [AssetActions.SET_ASSET_SCREENSHOT_URL]({ commit }, payload: string): Promise<void> {
    try {
      commit(AssetMutations.SET_ASSET_SCREENSHOT_URL, payload);
    } catch (e) {
      commit(AssetMutations.SET_ASSET_SCREENSHOT_URL, (e as Error).message);
      throw new Error('API Error Occurred');
    }
  },

  async [AssetActions.POST_FILE_CROSS_SECTION_DATA](
    { commit }, masterPayload,
  ): Promise<UploadFileResults> {
    const payload = masterPayload.selectedFile;
    const selectedAsset = 'Cross Section';
    commit(AssetMutations.SET_LOAD_ERROR, undefined);
    const formData = new FormData();
    const uploadFileResults = new UploadFileResults();
    const pIdx: number = payload.name.lastIndexOf('.');
    const part1: string = payload.name.substr(0, pIdx);
    const part2: string = payload.name.substr(pIdx);
    const dateTime: string = DateHelper.Format(new Date(), 'yyyyMMddhhmmss');
    const newFileName = `${part1}_${dateTime}${part2}`;
    formData.append('files', payload, newFileName);
    formData.append('selectedAsset', selectedAsset);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postFile(formData);

      try {
        uploadFileResults.Complete = true;
        uploadFileResults.FileName = response.data.fileName;
        uploadFileResults.FileHeaders = response.data.headers;
        uploadFileResults.FileFirstRow = response.data.firstRow;
        uploadFileResults.RzColumns = response.data.mapFields;
        uploadFileResults.Files = response.data.zipFiles;
      } catch (e: any) {
        uploadFileResults.ErrorMessage = (e.response && e.response.data) ? e.response.data : e;
      }
    } catch (e) {
      commit(AssetMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error((e as Error).message);
    }
    return uploadFileResults;
  },

  async [AssetActions.POST_FILE_CROSS_SECTION_MAPPING_DATA]({ commit }, payload: ZipFileMapping):
    Promise<UploadMappingResults> {
    commit(AssetMutations.SET_LOAD_ERROR, undefined);
    const uploadMappingCompleted = new UploadMappingResults();

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postFileMappings(payload);

      try {
        uploadMappingCompleted.Complete = true;
        uploadMappingCompleted.Data = response.data;
      } catch (e: any) {
        uploadMappingCompleted.ErrorMessage = (e.response && e.response.data) ? e.response.data : e;
      }
    } catch (e) {
      commit(AssetMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error((e as Error).message);
    }

    return uploadMappingCompleted;
  },

  async [AssetActions.POST_VIDEO_ORIENTATION]({ commit }, payload): Promise<void> {
    commit(AssetMutations.SET_VIDEO_ORIENTATION_LOADED, false);
    commit(AssetMutations.SET_VIDEO_ORIENTATION_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postVideoOrientation(payload.inspectionGuid, payload.orientation);
    } catch (e) {
      commit(AssetMutations.SET_VIDEO_ORIENTATION_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AssetMutations.SET_VIDEO_ORIENTATION_LOADED, true);
    }
  },

  async [AssetActions.POST_REPORTING_FEEDBACK]({ commit }, payload): Promise<void> {
    commit(AssetMutations.SET_REPORTING_FEEDBACK_LOADED, true);
    commit(AssetMutations.SET_REPORTING_FEEDBACK_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postReportingFeedback(payload.assetGuid, payload.reportingData);
    } catch (e) {
      commit(AssetMutations.SET_REPORTING_FEEDBACK_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AssetMutations.SET_REPORTING_FEEDBACK_LOADED, false);
    }
  },

  /**
   *
   * @param param0
   * @param payload Array of asset guids, presumably line segments
   */
  async [AssetActions.FETCH_LINE_SEGMENT_ACCESSPOINTS]({ commit }, payload): Promise<void> {
    commit(AssetMutations.SET_LOADED_SATE, true);
    commit(AssetMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.fetchLineSegmentAccessPoints(payload);
      commit(AssetMutations.SET_LINE_SEGMENT_ACCESSPOINTS, response.data.data);
    } catch (e) {
      commit(AssetMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AssetMutations.SET_LOADED_SATE, false);
    }
  },

  /**
   *
   * @param param0
   * @param payload Array of asset guids, presumably line segments
   */
  async [AssetActions.PATCH_DEPLOYMENT_FORM_WORK_ORDER]({ commit },
    payload: DeploymentFormDoNotInspectDTO[]): Promise<void> {
    commit(AssetMutations.SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_LOADING, true);
    commit(AssetMutations.SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.patchDeploymentFormWorkOrders(payload);
    } catch (e) {
      commit(AssetMutations.SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AssetMutations.SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_LOADING, false);
    }
  },

  async [AssetActions.FETCH_AVAILABLE_MANHOLES]({ commit },
    payload: string): Promise<void> {
    commit(AssetMutations.SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_LOADING, true);
    commit(AssetMutations.SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.fetchAvailableManholes(payload);
      commit(AssetMutations.SET_AVAILABLE_MANHOLES, response.data.data);
    } catch (e) {
      commit(AssetMutations.SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AssetMutations.SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_LOADING, false);
    }
  },

  async [AssetActions.POST_CREATE_NEW_LINE_SEGMENT_ACCESS_POINT]({ commit },
    payload: {
      USMH: ManholeAccessPoint,
      DSMH: ManholeAccessPoint,
      taskType: string,
      projectGuid: string,
    }): Promise<void> {
    commit(AssetMutations.SET_NEW_LINE_SEGMENT_ACCESS_POINT_LOADING, true);
    commit(AssetMutations.SET_NEW_LINE_SEGMENT_ACCESS_POINT_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postCreateNewLineSegmentAccessPoint(payload);
      commit(AssetMutations.SET_NEW_LINE_SEGMENT_ACCESS_POINT, response.data.data);
    } catch (e) {
      commit(AssetMutations.SET_NEW_LINE_SEGMENT_ACCESS_POINT_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AssetMutations.SET_NEW_LINE_SEGMENT_ACCESS_POINT_LOADING, false);
    }
  },

};
