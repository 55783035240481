// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum VideoActionCommands {
    BACKWARD_10 = 1,
    FORWARD_10 = 2,
    BACKWARD_STEP = 3,
    FORWARD_STEP = 4,
    BACKWARD_SKIP = 5,
    FORWARD_SKIP = 6,
    PAUSE_PLAY = 7,
    REVERSE_PAUSE_PLAY = 8,
}
