
























import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@/store/project/actions';

import { ProjectGraphics } from '@/store/project/types';
import {
  AssetRef, AssetData, Location, LocationLS, AssetFilter,
} from '@/types';
import MapGIS from '@/components/Maps/MapGIS.vue';
import { UserPrefsActions } from '@/store/userPrefs/actions';

const projectModule = namespace('project');
const assetModule = namespace('asset');
const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
    MapGIS,
  },
})
export default class AssetSelector extends Vue {
  @projectModule.State('graphics') project: ProjectGraphics[] | undefined;

  @assetModule.State('data') asset: AssetData | undefined;

  @projectModule.State('loadError') loadError;

  @projectModule.State('loading') loading;

  @projectModule.Action(ProjectActions.FETCH_GRAPHICS_DATA) fetchProjectData;

  @userPrefsModule.Action(UserPrefsActions.GET_SELECTED_MAP_ASSET) getSelectedMapAsset;

  @userPrefsModule.Action(UserPrefsActions.SET_SELECTED_MAP_ASSET) setSelectedMapAsset;

  @userPrefsModule.State('selectedMapAssetGuid') selectedMapAssetGuid;

  @Prop() readonly id!: string;

  // @Prop() readonly assetGuidList!: string[];

  @PropSync('selectedAsset') synchedSelectedAsset!: AssetRef;

  loadedSelectedAsset = {} as AssetRef;

  syncedUseZoomFilter = false;

  mapData: AssetData[] = [];

  filteredMapData: AssetData[] = [];

  visibleFeatures: AssetData[] = [];

  fullyLoaded = false;

  filters = {
    selectedAssets: [],
    score: [-1, 0, 1, 2, 3, 4, 5],
    status: [] as string[],
    matchFilters: [],
  } as AssetFilter;

  @Watch('id', { immediate: true })
  onProjectIdChanged(): void {
    if (!this.project) {
      this.fetchProjectData([this.id]).then(() => {
        if (this.project) {
          this.fillMapData();
          if (document.getElementById(this.id) != null) {
            document.getElementById(this.id).style.visibility = 'visible';
          }
        }
      });
    } else {
      this.fillMapData();
      if (document.getElementById(this.id) != null) {
        document.getElementById(this.id).style.visibility = 'visible';
      }
    }
  }

  fillMapData(): void {
    // Populating the mapData array using the project asset data
    this.project.forEach((asset) => {
      // initial validation
      if (asset.guid == null || asset.guid === ''
              || asset.name == null || asset.name === ''
              || asset.priority == null
              || asset.type == null) {
        // bad asset, continue
        return;
      }
      const assetMapData: AssetData = {
        guid: asset.guid,
        name: asset.name,
        score: asset.priority,
        type: asset.type,
        location: [],
        visible: true,
        hasCustomerDeliverables: false,
      };

      switch (asset.type) {
        case 'Manhole':
          assetMapData.location.push(this.getLocationManhole(asset) as Location & LocationLS);
          break;
        case 'Line Segment':
          assetMapData.location.push(this.getLocationLineSegment(asset) as Location & LocationLS);
          break;
        case 'Lateral':
          assetMapData.location = asset.location as Location[] & LocationLS[];
          break;
        default:
          // unknown type, continue
          return;
      }

      // location validation
      if (assetMapData.location.length !== 0
              && assetMapData.location[0] !== null
              && assetMapData.location[0].source !== ''
      ) {
        this.mapData.push(assetMapData);
      }
    });
    this.filteredMapData = this.mapData;
    this.visibleFeatures = this.mapData;
  }

  getLocationManhole(asset: AssetRef | ProjectGraphics): Location {
    const location: Location = {
      latitude: 0,
      longitude: 0,
      source: '',
    };

    if (asset.location) {
      if (asset.location.length > 0) {
        // check for customer data
        const cdLocation = asset.location.find((loc) => loc.source === 'CustomerData');
        if (cdLocation !== undefined) {
          location.latitude = cdLocation.latitude;
          location.longitude = cdLocation.longitude;
          location.source = cdLocation.source;
          return location;
        }
      } else {
        // no customer data use inspection
        const inspLocations = asset.location.find((loc) => loc.source === 'Inspections');
        if (inspLocations !== undefined) {
          location.latitude = inspLocations.latitude;
          location.longitude = inspLocations.longitude;
          location.source = inspLocations.source;
          return location;
        }
      }
    }
    return null;
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  getLocationLineSegment(asset: any): LocationLS {
    const locationLS: LocationLS = {
      upstream: {} as Location,
      downstream: {} as Location,
      hasFlowData: 0,
    };

    try {
      if (asset.location) {
        if (asset.location.length > 1) {
          // check for customer data
          const cdLocations = asset.location.filter((loc) => loc.source === 'CustomerData');
          if (cdLocations.length > 1) {
            [locationLS.upstream, locationLS.downstream] = cdLocations;
          } else {
            // no customer data use inspection
            const inspLocations = asset.location.filter((loc) => loc.source === 'Inspections');
            if (inspLocations.length > 1) {
              [locationLS.upstream, locationLS.downstream] = inspLocations;
            }
          }
        }
      }
    } catch (e) {
      console.error(`No location data found: ${asset.name}`);
      console.error(e);
    }
    return locationLS;
  }

  assetLaunchRequested(response: {selectedAssets: AssetData[], multiSelect: boolean}): void {
    const assets = response.selectedAssets;
    if (assets[0] != null && assets[0].guid != null && assets[0].guid !== this.$route.params.id) {
      const assetRef = this.project.find((a) => a.guid === assets[0].guid) as AssetRef;
      this.setSelectedMapAsset(assetRef);
      if (!this.id) {
        this.$router.push({ name: 'assets', params: { id: assets[0].guid } });
      } else {
        this.$router.push({
          name: 'assetsList',
          params: {
            idList: JSON.stringify([this.id]),
            id: assets[0].guid,
          },
        });
      }
    }
  }

  loadSelectedAsset(): void {
    this.loadedSelectedAsset = this.synchedSelectedAsset;
  }

  loaded(): void {
    this.fullyLoaded = true;
  }
}
