import { InspectionResponse } from '@/components/CodingForm/types';
import { MutationTree } from 'vuex';
import {
  Crew,
  PlanningState,
  WorkOrderTableData,
} from './types';

// eslint-disable-next-line no-shadow
export enum PlanningMutations {
    SET_LOADING_STATE = 'SET_LOADING_STATE',
    SET_ASSIGNMENTS_DATA = 'SET_ASSIGNMENTS_DATA',
    SET_LOAD_ERROR = 'SET_LOAD_ERROR',
    SET_PATCH_STATE = 'SET_PATCH_STATE',
    SET_DELETE_STATE = 'SET_DELETE_STATE',
    SET_PATCH_DATA = 'SET_PATCH_DATA',
    SET_PATCH_ERROR = 'SET_PATCH_ERROR',
    SET_ASSIGNMENT_WORK_ORDER_STATE = 'SET_ASSIGNMENT_WORK_ORDER_STATE',
    SET_ASSIGNMENT_WORK_ORDER_DATA = 'SET_ASSIGNMENT_WORK_ORDER_DATA',
    SET_ASSIGNMENT_WORK_ORDER_ERROR = 'SET_ASSIGNMENT_WORK_ORDER_ERROR',
    SET_PATCH_ASSIGNMENT_WORK_ORDER_STATE = 'SET_PATCH_ASSIGNMENT_WORK_ORDER_STATE',
    SET_PATCH_ASSIGNMENT_WORK_ORDER_DATA = 'SET_PATCH_ASSIGNMENT_WORK_ORDER_DATA',
    SET_PATCH_ASSIGNMENT_WORK_ORDER_ERROR = 'SET_PATCH_ASSIGNMENT_WORK_ORDER_ERROR',
    SET_WORK_ORDER_CREW_ERROR = 'SET_WORK_ORDER_CREW_ERROR',
    SET_WORK_ORDER_CREW_DATA = 'SET_WORK_ORDER_CREW_DATA',
    SET_WORK_ORDER_CREW_LOADING = 'SET_WORK_ORDER_CREW_LOADING',
}

export const mutations : MutationTree<PlanningState> = {
  [PlanningMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },

  [PlanningMutations.SET_ASSIGNMENTS_DATA](state, payload: WorkOrderTableData[]) {
    state.data = payload;
  },

  [PlanningMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },

  [PlanningMutations.SET_PATCH_STATE](state, payload: boolean) {
    state.patchLoading = payload;
  },

  [PlanningMutations.SET_DELETE_STATE](state, payload: boolean) {
    state.deleteLoading = payload;
  },

  [PlanningMutations.SET_PATCH_DATA](state, payload: string) {
    state.patchData = payload;
  },

  [PlanningMutations.SET_PATCH_ERROR](state, payload: string) {
    state.patchError = payload;
  },

  [PlanningMutations.SET_ASSIGNMENT_WORK_ORDER_STATE](state, payload: boolean) {
    state.workOrderLoading = payload;
  },

  [PlanningMutations.SET_ASSIGNMENT_WORK_ORDER_DATA](state, payload: InspectionResponse) {
    state.workOrderData = payload;
  },

  [PlanningMutations.SET_ASSIGNMENT_WORK_ORDER_ERROR](state, payload: string) {
    state.workOrderError = payload;
  },

  [PlanningMutations.SET_PATCH_ASSIGNMENT_WORK_ORDER_STATE](state, payload: boolean) {
    state.patchWorkOrderLoading = payload;
  },

  [PlanningMutations.SET_PATCH_ASSIGNMENT_WORK_ORDER_DATA](state, payload: InspectionResponse) {
    state.patchWorkOrderData = payload;
  },

  [PlanningMutations.SET_PATCH_ASSIGNMENT_WORK_ORDER_ERROR](state, payload: string) {
    state.patchWorkOrderError = payload;
  },

  [PlanningMutations.SET_WORK_ORDER_CREW_LOADING](state, payload: boolean) {
    state.workerOrderCrewLoading = payload;
  },

  [PlanningMutations.SET_WORK_ORDER_CREW_DATA](state, payload: Crew[]) {
    state.workOrderCrewData = payload;
  },

  [PlanningMutations.SET_WORK_ORDER_CREW_ERROR](state, payload: string) {
    state.workOrderCrewError = payload;
  },
};
