












































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import {
  Component, PropSync,
} from 'vue-property-decorator';

@Component
export default class HotkeysInfoPopup extends Vue {
  @PropSync('popupModel') displayDefectCodingPopup: boolean;
}
