import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { SnackBarMutations } from './mutations';
import { SnackBarMessage, SnackBarState } from './types';

// eslint-disable-next-line no-shadow
export enum SnackBarActions {
    SHOW = 'SHOW',
}

export const actions: ActionTree<SnackBarState, RootState> = {

  // eslint-disable-next-line spaced-comment
  async [SnackBarActions.SHOW]({ commit }, message: SnackBarMessage): Promise<void> {
    try {
      commit(SnackBarMutations.SET_SNACK_BAR_MESSAGE, message);
    } catch (e) {
      commit(SnackBarMutations.SET_ERROR, (e as Error).message);
      throw Error('Snackbar Error occurred.');
    }
  },
};
