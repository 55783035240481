import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { LineGraphState } from './types';
import { LineGraphMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum LineGraphActions {
  GET_LINE_GRAPH_DATA = 'GET_LINE_GRAPH_DATA',
  CLEAR_LINE_GRAPH_DATA = 'CLEAR_LINE_GRAPH_DATA',
  GET_GAS_TEMP_DATA = 'GET_GAS_TEMP_DATA',
  CLEAR_GAS_TEMP_DATA = 'CLEAR_GAS_TEMP_DATA',
}

export const actions: ActionTree<LineGraphState, RootState> = {
  async [LineGraphActions.GET_LINE_GRAPH_DATA]({ commit }, payload: string): Promise<void> {
    commit(LineGraphMutations.SET_LOADING_STATE, true);
    commit(LineGraphMutations.SET_LINE_GRAPH_DATA, undefined);
    commit(LineGraphMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getLineGraphData(payload);

      commit(LineGraphMutations.SET_LINE_GRAPH_DATA, response.data.result);
    } catch (e) {
      commit(LineGraphMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(LineGraphMutations.SET_LOADING_STATE, false);
    }
  },

  async [LineGraphActions.CLEAR_LINE_GRAPH_DATA]({ commit }): Promise<void> {
    commit(LineGraphMutations.SET_LOADING_STATE, true);
    commit(LineGraphMutations.SET_LOAD_ERROR, undefined);
    try {
      commit(LineGraphMutations.SET_LINE_GRAPH_DATA, undefined);
    } catch (e) {
      commit(LineGraphMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(LineGraphMutations.SET_LOADING_STATE, false);
    }
  },

  async [LineGraphActions.GET_GAS_TEMP_DATA]({ commit }, payload: string): Promise<void> {
    commit(LineGraphMutations.SET_LOADING_STATE, true);
    commit(LineGraphMutations.SET_GAS_TEMP_DATA, undefined);
    commit(LineGraphMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getGasTempData(payload);

      commit(LineGraphMutations.SET_GAS_TEMP_DATA, response.data.result);
    } catch (e) {
      commit(LineGraphMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(LineGraphMutations.SET_LOADING_STATE, false);
    }
  },

  async [LineGraphActions.CLEAR_GAS_TEMP_DATA]({ commit }): Promise<void> {
    commit(LineGraphMutations.SET_LOADING_STATE, true);
    commit(LineGraphMutations.SET_LOAD_ERROR, undefined);
    try {
      commit(LineGraphMutations.SET_GAS_TEMP_DATA, undefined);
    } catch (e) {
      commit(LineGraphMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(LineGraphMutations.SET_LOADING_STATE, false);
    }
  },
};
