




















































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import {
  CustomerDashboardWorkOrder,
} from '@/store/metrics/types';
import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';

@Component({
  components: {
    IntegrityTable,
  },
})
export default class DashboardWorkOrderTable extends CustomerDashboardMixin {
  @Prop() readonly projectGuid: string;

  @Prop() readonly type: string;

  @PropSync('selectedTaskType') synchedSelectedTaskType: string;

  @PropSync('selectedStatus') synchedSelectedStatus: string;

  filterValues = {};

  dashboardWorkOrders = [];

  search = '';

  headers = [
    {
      text: 'Asset',
      value: 'assetName',
      sortable: true,
      filterable: false,
    },
    {
      text: 'WO #',
      value: 'workOrderNumber',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Status',
      value: 'status',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Task Type',
      value: 'taskType',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Issue',
      value: 'issue',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Crew',
      value: 'crewName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Days Open',
      value: 'daysOpen',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Action',
      value: 'workOrderGuid',
      sortable: false,
      filterable: false,
    },
  ];

  matchFilters = [
    {
      header: 'assetName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'workOrderNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'status',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'taskType',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'issue',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'crewName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'daysOpen',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  mounted(): void {
    this.updateDashboardWorkOrders();
    this.updateFilterValues();
  }

  @Watch('customerDashboardWorkOrders')
  onCustomerDashboardWorkOrdersChange(): void {
    this.updateDashboardWorkOrders();
  }

  @Watch('dashboardWorkOrders')
  async onDashboardWorkOrdersChange(): Promise<void> {
    this.updateFilterValues();
  }

  updateDashboardWorkOrders(): void {
    if (!this.customerDashboardWorkOrders) {
      return;
    }
    this.dashboardWorkOrders = this.customerDashboardWorkOrders.filter(
      (wo) => wo.assetType === this.type,
    );
  }

  updateFilterValues(): void {
    if (this.dashboardWorkOrders.length <= 0) return;

    this.headers.forEach((header) => {
      if (header.value === 'assetName') return;

      this.filterValues[header.value] = [];

      this.dashboardWorkOrders.forEach(
        (workOrder: CustomerDashboardWorkOrder) => {
          let checkVal: string;

          try {
            checkVal = workOrder[header.value];
          } catch {
            checkVal = '';
          }

          if (
            checkVal
            && checkVal !== ''
            && Array.isArray(this.filterValues[header.value])
            && !this.filterValues[header.value].includes(checkVal)
          ) {
            this.filterValues[header.value].push(checkVal);
          }
        },
      );
      this.filterValues[header.value].sort();
    });

    // Set table to filter by follow up work orders by default
    const statusFilter = this.matchFilters.find((m) => m.header === 'status');
    const statusFilterValue = this.filterValues['status'];
    if (!statusFilter) {
      return;
    }

    const followUpRequired = 'Follow-Up Required';

    // If there are no follow up required,
    // add to filter values so user can see that it is selected
    if (!statusFilterValue.includes(followUpRequired)) {
      statusFilterValue.push(followUpRequired);
      statusFilterValue.sort();
    }
    statusFilter.options = [followUpRequired];
  }

  get assetType(): string | undefined {
    return this.type !== 'Manhole' && this.type !== 'Line Segment'
      ? undefined
      : this.type;
  }

  getTableInfoLink(workOrder: CustomerDashboardWorkOrder): void {
    this.$router
      .push({
        path: `/workOrders/${this.projectGuid}`,
        query: {
          guid: workOrder.workOrderGuid,
        },
      })
      .then(() => {
        this.$router.go(0);
      });
  }

  getDaysOpenString(item: CustomerDashboardWorkOrder): string {
    return `${!item.daysOpen ? '-' : item.daysOpen}`;
  }

  clearTable(): void {
    this.clearFilters();
    this.synchedSelectedStatus = '';
    this.synchedSelectedTaskType = '';
  }

  clearFilters(): void {
    this.matchFilters.forEach((m) => {
      m.options = [];
    });
  }

  handleOnProjectPageClick(): void {
    this.$router
      .push({
        path: `/projects/["${this.projectGuid}"]`,
        query: {
          name: [
            ...new Set(
              this.getFilteredDataFromTable().map((value) => value.assetName),
            ),
          ],
        },
      })
      .then(() => {
        this.$router.go(0);
      });
  }

  handleOnWorkOrderProjectPageClick(): void {
    this.$router
      .push({
        path: `/workOrders/${this.projectGuid}`,
        query: {
          // replace spaces cuz route does not respect spaces
          matchFilters: encodeURI(JSON.stringify(this.matchFilters)),
          category: '5', // all
        },
      })
      .then(() => {
        this.$router.go(0);
      });
  }

  getFilteredDataFromTable(): CustomerDashboardWorkOrder[] | undefined {
    return [
      ...((this.$refs.customerDataWorkOrderTable as any)?.tableData != null
        ? ((this.$refs.customerDataWorkOrderTable as any)
          ?.tableData as CustomerDashboardWorkOrder[])
        : []),
    ];
  }

  followUpRequiredConversion = {
    CNL: 'Could Not Locate',
    CNA: 'Could Not Access',
    CNO: 'Unable To Open Access Point',
    'Cleaning (Light)': 'Cleaning Required (Light)',
    'Cleaning (Heavy)': 'Cleaning Required (Heavy)',
    'Traffic Control': 'Traffic Prevented Access',
  };

  @Watch('synchedSelectedTaskType')
  onSynchedSelectedTaskTypeChange(): void {
    this.clearFilters();
    if (!this.synchedSelectedTaskType) {
      return;
    }
    const taskTypeMatchFilter = this.matchFilters.find(
      (mf) => mf.header === 'taskType',
    );
    taskTypeMatchFilter.options = [this.synchedSelectedTaskType];
  }

  @Watch('synchedSelectedStatus')
  onSynchedSelectedStatusChange(): void {
    this.clearFilters();
    if (!this.synchedSelectedStatus || this.synchedSelectedStatus === 'Other') {
      return;
    }
    if (this.synchedSelectedStatus in this.followUpRequiredConversion) {
      const issueMatchFilter = this.matchFilters.find(
        (mf) => mf.header === 'issue',
      );
      issueMatchFilter.options = [
        this.followUpRequiredConversion[this.synchedSelectedStatus],
      ];
      const statusMatchFilter = this.matchFilters.find(
        (mf) => mf.header === 'status',
      );
      statusMatchFilter.options = ['Follow-Up Required'];
    }
  }

  get workOrdersOfAssetType(): CustomerDashboardWorkOrder[] | undefined {
    if (!this.customerDashboardWorkOrders) {
      return [];
    }
    return this.customerDashboardWorkOrders.filter(
      (wo) => wo.assetType === this.type,
    );
  }

  get filteredCustomerDashboardWorkOrders():
    | CustomerDashboardWorkOrder[]
    | undefined {
    if (!this.customerDashboardWorkOrders) {
      return [];
    }
    const workOrdersOfType = this.workOrdersOfAssetType;

    if (this.synchedSelectedStatus) {
      return workOrdersOfType.filter(
        (wo) => wo.workOrderStatus === this.synchedSelectedStatus,
      );
    }
    return workOrdersOfType;
  }
}
