import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { AssetState } from './types';

const getters: GetterTree<AssetState, RootState> = {
  getAssetString(state): string {
    return `${state.asset?.name} - ${state.asset?.name}`;
  },
  getAssetScreenshotUrl(state): string {
    return state.assetScreenshotUrl;
  },
};

export default getters;
