import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { CustomerState } from './types';
import getters from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state : CustomerState = {
  getLoading: false,
  getLoadError: null,
  customerData: null,
  postLoading: false,
  postLoadError: null,
  postcustomerObject: null,
  patchLoading: false,
  patchLoadError: null,
  patchcustomerObject: null,
  deleteLoading: false,
  deleteLoadError: null,
};

const user: Module<CustomerState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default user;
