import { MutationTree } from 'vuex';
import { ReportState } from './types';

// eslint-disable-next-line no-shadow
export enum ReportMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_PDF_URI = 'SET_PDF_URI',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
}

export const mutations: MutationTree<ReportState> = {
  [ReportMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
  [ReportMutations.SET_PDF_URI](state, payload: string) {
    state.data = payload;
  },
  [ReportMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
};
