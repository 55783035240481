












































































































































































































































































































































































































































































































































/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Vue, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import utils from './utils';
import {
  GPS_COLLECTION_TASK,
  VERTUE_COLLECTION_TASK,
  MACP_FORM_COLLECTION_TASK,
  DATASTANDARD_MACP7_LEVEL1,
  DATASTANDARD_MACP7_LEVEL2,
  SOLO_MANHOLE_COLLECTION_TASK,
  P3D_COLLECTION_TASK,
  RESPONDER_COLLECTION_TASK,
  PROFILER_COLLECTION_TASK,
  SOLO_DATA_COLLECTION_TASK,
  PACP_COLLECTION_TASK,
} from '../../common/Constants';
import { customField } from './iMPS';
import {
  DataRequirement, Field, InspectionDetails, Sensors,
} from './types';

@Component({})
export default class MPSSectionData extends Vue {
  @Prop() readonly requiredField;

  @Prop() readonly canEditMPS: boolean;

  @PropSync('valid') synchedValid;

  @PropSync('customFields') synchedCustomFields;

  @PropSync('dataRequirements') synchedDataRequirements;

  @PropSync('gisRadioOptions') synchedGISRadioOptions;

  @PropSync('macpLevel') synchedMacpLevel;

  @PropSync('macpSections') synchedMacpSections;

  @PropSync('gisTypeRadioOptions') synchedGISTypeRadioOptions;

  @PropSync('gisUpdateContact') synchedGISUpdateContact;

  @PropSync('gisExpectations') synchedGISExpectations;

  @PropSync('totalFootage') synchedTotalFootage;

  @PropSync('totalVertical') synchedTotalVertical;

  @PropSync('photoRequirementSurfaceCover') synchedphotoRequirementSurfaceCover;

  @PropSync('photoRequirementRim') synchedphotoRequirementRim;

  @PropSync('photoRequirementConnections') synchedphotoRequirementConnections;

  @PropSync('photoRequirementSurroundingAreas') synchedphotoRequirementSurroundingAreas;

  @PropSync('photoRequirementOtherContent') synchedphotoRequirementOtherContent;

  @PropSync('photoRequirementOtherCheckBox') synchedphotoRequirementOtherCheckBox;

  addCustomFieldDialog = false;

  addItemDialog = false;

  assetType = '';

  assetTypes = ['Manhole', 'Line Segment'];

  cctv = false;

  customDescriptionRule = [(v: string): boolean | string => !this.customFieldRegex.test(v) || 'The field contains invalid characters.']

  customFieldRegex = new RegExp(/["\\]/);

  dataTypes = ['String', 'Numeric', 'Boolean'];

  estimatedFootage = 0.0;

  estimatedQuantity = '';

  gas = false;

  gpsAccuracy = '';

  gpsAccuracyTypes = ['Survey Level', 'Sub-Meter', 'Nearest Meter', 'Custom'];

  horizontalAccuracy = '';

  inspectionDetails = '';

  inspectionType = '';

  laser = false;

  laserOption = '';

  lsInspectionTypes = [
    'PACP',
    'Solo CCTV',
    'CCTV',
    'Multi-Sensor Inspection',
    'P3D Collection',
    'Responder Collection',
    'Profiler Collection',
  ];

  macpLevel = '';

  macpLevelTypes = ['Level 1', 'Level 2'];

  macpLevelRadioOptions = ['Level 1', 'Level 2', 'Custom'];

  measurementUnits = ['Imperial (LF)', 'Metric'];

  mhInspectionTypes = ['MACP', 'GPS', 'Vertue', 'Solo Manhole', 'Premium'];

  newCustomFieldDescription = '';

  newCustomFieldDataType = '';

  pipeSize = 0.0;

  pipeSizeInput = '';

  pipeSizeInputSelections = ['Single Size', 'Size Range'];

  pipeSizeMax = 0.0;

  pipeSizeMin = 0.0;

  selectedMeasurementUnits = '';

  sonar = false;

  temperature = false;

  unitPrice = '';

  validUser = false;

  verticalAccuracy = '';

  editMode = false;

  selectedIndex = 0;

  edited = false;

  @Watch('synchedCustomFields', { deep: true, immediate: true })
  onCustomFieldsChange(): void {
    if (this.synchedCustomFields.length > 0) this.synchedMacpLevel = 'Custom';
  }

  @Watch('inspectionType')
  onInspectionTypeChange(): void {
    if (this.inspectionType !== 'Multi-Sensor Inspection') {
      this.cctv = false;
      this.laser = false;
      this.sonar = false;
      this.gas = false;
      this.temperature = false;
    }
  }

  @Watch('laser')
  onLaserChange(): void {
    if (!this.laser) this.laserOption = '';
  }

  @Watch('synchedMacpLevel')
  onMacpLevelChange(): void {
    let macpLevel = 0;

    switch (this.synchedMacpLevel) {
      case 'Level 1':
        macpLevel = 1;
        break;
      case 'Level 2':
        macpLevel = 2;
        break;
      default:
        break;
    }

    if (macpLevel > 0) {
      this.synchedMacpSections.forEach((section) => {
        section.fields.forEach((field) => {
          field.value = !!field.levels.includes(macpLevel);
        });
      });
    }
  }

  setEdited(): void{
    this.edited = true;
  }

  addCustomFieldInfo(): void{
    const newCustomFieldInfo: customField = {
      dataType: this.newCustomFieldDataType,
      description: this.newCustomFieldDescription,
    };
    this.synchedCustomFields.push(newCustomFieldInfo);
    this.resetCustomFieldForm();
  }

  addItem(): void {
    this.synchedDataRequirements.push(this.createDataRequirementItem());

    this.resetPopout();
  }

  saveItem(): void{
    this.synchedDataRequirements.splice(this.selectedIndex, 1, this.createDataRequirementItem());

    this.resetPopout();
  }

  createDataRequirementItem(): DataRequirement {
    const taskTypes = [];
    let details = {} as InspectionDetails;

    if (this.assetType === 'Manhole') {
      if (this.inspectionType === 'GPS') {
        this.inspectionDetails = this.gpsAccuracy;
        taskTypes.push(GPS_COLLECTION_TASK);

        let vertical;
        let horizontal;
        let unit;

        switch (this.gpsAccuracy) {
          case 'Survey Level':
            vertical = '2';
            horizontal = '2';
            unit = 'mm';
            break;
          case 'Sub-Meter':
            vertical = '1';
            horizontal = '1';
            unit = 'm';
            break;
          case 'Nearest Meter':
            vertical = '1';
            horizontal = '1';
            unit = 'm';
            break;
          case 'Custom':
            vertical = this.horizontalAccuracy;
            horizontal = this.verticalAccuracy;
            unit = 'm';
            break;
          default:
            break;
        }

        // flip the logic based on Type
        details.accuracy = {
          type: this.gpsAccuracy,
          vertical,
          horizontal,
          unit,
        };
      }

      if (this.inspectionType === 'MACP') {
        let macpGuid = '';

        this.inspectionDetails = this.macpLevel;
        taskTypes.push(MACP_FORM_COLLECTION_TASK);

        if (this.macpLevel === 'Level 1') {
          macpGuid = DATASTANDARD_MACP7_LEVEL1;
        }

        if (this.macpLevel === 'Level 2') {
          macpGuid = DATASTANDARD_MACP7_LEVEL2;
        }

        details.type = this.macpLevel;
        details.standardGuid = macpGuid;
      }

      if (this.inspectionType === 'Vertue') {
        this.inspectionDetails = 'Vertue Collection';
        taskTypes.push(VERTUE_COLLECTION_TASK);
        details = {};
      }

      if (this.inspectionType === 'Solo Manhole') {
        this.inspectionDetails = 'Solo Manhole Collection';
        taskTypes.push(SOLO_MANHOLE_COLLECTION_TASK);
        details = {};
      }
    }

    if (this.assetType === 'Line Segment') {
      const sensors = {
        CCTV: false,
        Laser: false,
        LaserOption: '',
        Sonar: false,
        Gas: false,
        Temperature: false,
      } as Sensors;

      if (this.inspectionType === 'PACP') {
        this.inspectionDetails = 'PACP';
        taskTypes.push(PACP_COLLECTION_TASK);
      }

      if (this.inspectionType === 'Solo CCTV' || this.inspectionType === 'CCTV') {
        this.inspectionDetails = 'CCTV';
        taskTypes.push(SOLO_DATA_COLLECTION_TASK);
        sensors.CCTV = true;
      }

      if (this.inspectionType === 'Multi-Sensor Inspection') {
        sensors.CCTV = this.cctv;
        sensors.Laser = this.laser;
        sensors.LaserOption = this.laserOption;
        sensors.Sonar = this.sonar;
        sensors.Gas = this.gas;
        sensors.Temperature = this.temperature;
      }

      details.sensors = sensors;

      if (this.inspectionType === 'P3D Collection') {
        this.inspectionDetails = 'P3D Collection';
        taskTypes.push(P3D_COLLECTION_TASK);
      }

      if (this.inspectionType === 'Responder Collection') {
        this.inspectionDetails = 'Responder Collection';
        taskTypes.push(RESPONDER_COLLECTION_TASK);
      }

      if (this.inspectionType === 'Profiler Collection') {
        this.inspectionDetails = 'Profiler Collection';
        taskTypes.push(PROFILER_COLLECTION_TASK);
      }

      if (this.pipeSizeInput === 'Single Size') {
        details.sizeSelection = this.pipeSizeInput;
        details.pipeSize = this.pipeSize;
        details.estFootage = this.estimatedFootage;
      }

      if (this.pipeSizeInput === 'Size Range') {
        details.sizeSelection = this.pipeSizeInput;
        details.pipeSizeMin = this.pipeSizeMin;
        details.pipeSizeMax = this.pipeSizeMax;
        details.estFootage = this.estimatedFootage;
      }
    }

    const newItem = {
      asset: this.assetType,
      unitOfMeasure: this.selectedMeasurementUnits,
      inspectionType: this.inspectionType,
      inspectionDetails: details,
      estimatedQuantity: Number(this.estimatedQuantity),
      unitPrice: Number(this.unitPrice),
      TaskTypeDefinitions: taskTypes,
    } as DataRequirement;
    return newItem;
  }

  resetPopout(): void{
    this.assetType = '';
    this.selectedMeasurementUnits = '';
    this.inspectionType = '';
    this.gpsAccuracy = '';
    this.macpLevel = '';
    this.estimatedQuantity = '';
    this.estimatedFootage = 0.0;
    this.pipeSizeInputSelections = ['Single Size', 'Size Range'];
    this.pipeSizeMin = 0.0;
    this.pipeSizeMax = 0.0;
    this.pipeSize = 0.0;
    this.unitPrice = '';
    this.addItemDialog = false;
    this.gpsAccuracy = '';
    this.horizontalAccuracy = '';
    this.verticalAccuracy = '';
    this.cctv = false;
    this.laser = false;
    this.laserOption = '';
    this.sonar = false;
    this.gas = false;
    this.temperature = false;
    this.pipeSizeInput = '';
    this.editMode = false;
    this.selectedIndex = 0;
  }

  getRequirementDesc(details: InspectionDetails, type: string): string {
    return utils.formatDesc(details, type);
  }

  getRows(fieldCount: number): string {
    return `grid-template-rows: repeat(${Math.round(fieldCount / 2)}, auto)`;
  }

  removeCustomFieldForm(index: number): void {
    this.synchedCustomFields.splice(index, 1);
  }

  editItem(index: number): void{
    const selectedItem = this.synchedDataRequirements[index];

    this.assetType = selectedItem.asset;
    this.selectedMeasurementUnits = selectedItem.unitOfMeasure;
    this.inspectionType = selectedItem.inspectionType;
    this.estimatedQuantity = selectedItem.estimatedQuantity;
    this.unitPrice = selectedItem.unitPrice;
    this.gpsAccuracy = selectedItem.inspectionDetails?.accuracy?.type;

    this.pipeSize = selectedItem.inspectionDetails?.pipeSize;

    if (selectedItem.inspectionDetails?.standardGuid === DATASTANDARD_MACP7_LEVEL1) {
      this.macpLevel = 'Level 1';
    }

    if (selectedItem.inspectionDetails?.standardGuid === DATASTANDARD_MACP7_LEVEL2) {
      this.macpLevel = 'Level 2';
    }

    this.pipeSizeInput = selectedItem.inspectionDetails?.sizeSelection;
    this.pipeSizeMin = selectedItem.inspectionDetails?.pipeSizeMin;
    this.pipeSizeMax = selectedItem.inspectionDetails?.pipeSizeMax;
    this.estimatedFootage = selectedItem.inspectionDetails?.estFootage;

    if (this.assetType === 'Manhole') {
      if (this.inspectionType === 'GPS') {
        if (this.gpsAccuracy === 'Custom') {
          this.horizontalAccuracy = selectedItem.inspectionDetails?.accuracy?.vertical;
          this.verticalAccuracy = selectedItem.inspectionDetails?.accuracy?.horizontal;
        }
      }
    }

    this.cctv = selectedItem.inspectionDetails?.sensors?.CCTV;
    this.laser = selectedItem.inspectionDetails?.sensors?.Laser;
    this.laserOption = selectedItem.inspectionDetails?.sensors?.LaserOption;
    this.sonar = selectedItem.inspectionDetails?.sensors?.Sonar;
    this.gas = selectedItem.inspectionDetails?.sensors?.Gas;
    this.temperature = selectedItem.inspectionDetails?.sensors?.Temperature;

    this.editMode = true;
    this.selectedIndex = index;
    this.addItemDialog = true;
  }

  removeItem(index: number): void {
    this.synchedDataRequirements.splice(index, 1);
  }

  resetCustomFieldForm(): void{
    this.newCustomFieldDataType = '';
    this.newCustomFieldDescription = '';
    this.addCustomFieldDialog = false;
  }

  @Watch('synchedDataRequirements')
  onSynchedDataRequirementsChange(): void{
    if (!this.synchedDataRequirements) {
      return;
    }
    this.synchedTotalFootage = this.calculatedFootage;
    this.synchedTotalVertical = this.calculatedVertical;
  }

  get calculatedVertical(): number {
    const filteredData = this.synchedDataRequirements
      .filter((dataRequirement) => dataRequirement.asset === 'Manhole');
    const countMap: Map<string, number> = new Map(
      filteredData.map((element) => [element.inspectionType, 0]),
    );
    filteredData.forEach((element) => {
      countMap.set(element.inspectionType,
        countMap.get(element.inspectionType) + element.estimatedQuantity);
    });

    if (countMap.size === 0) {
      return 0;
    }

    return Math.max(...countMap.values());
  }

  get calculatedFootage(): number {
    return this.synchedDataRequirements
      .filter((dataRequirement) => dataRequirement.asset === 'Line Segment')
      .map((dataRequirement) => Number(dataRequirement.inspectionDetails.estFootage))
      .reduce((prev, next) => prev + next, 0);
  }

  checkBoxHandler(toggledField: Field): void {
    // if already custom, skip
    if (this.macpLevel === 'Custom') return;
    // if value is custom, select custom
    if (!(toggledField.value === toggledField.levels.includes(parseInt(this.macpLevel.split(' ')[1], 10)))) {
      this.synchedMacpLevel = 'Custom';
    }
  }

  resetValidation(): void {
    (this.$refs.dataForm as any).reset();
  }
}
