import { MutationTree } from 'vuex';
import {
  CrossSectionData, CrossSectionsState, MSIInspectionData, PVDData,
} from './types';

// eslint-disable-next-line no-shadow
export enum CrossSectionsMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_CROSS_SECTIONS_DATA = 'SET_CROSS_SECTIONS_DATA',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
  SET_DEFECT_DATA = 'SET_DEFECT_DATA',
  SET_DEFECT_LOADING = 'SET_DEFECT_LOADING',
  SET_DEFECT_ERROR = 'SET_DEFECT_ERROR',
  SET_PVD_DATA = 'SET_PVD_DATA',
  SET_PVD_LOADING = 'SET_PVD_LOADING',
  SET_PVD_ERROR = 'SET_PVD_ERROR',
}

export const mutations: MutationTree<CrossSectionsState> = {
  [CrossSectionsMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
  [CrossSectionsMutations.SET_CROSS_SECTIONS_DATA](state, payload: CrossSectionData[]) {
    state.data = payload;
  },
  [CrossSectionsMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
  [CrossSectionsMutations.SET_DEFECT_DATA](state, payload: MSIInspectionData) {
    state.defectData = payload;
  },
  [CrossSectionsMutations.SET_DEFECT_LOADING](state, payload: boolean) {
    state.defectLoading = payload;
  },
  [CrossSectionsMutations.SET_DEFECT_ERROR](state, payload: string) {
    state.defectError = payload;
  },
  [CrossSectionsMutations.SET_PVD_DATA](state, payload: PVDData) {
    state.pvdData = payload;
  },
  [CrossSectionsMutations.SET_PVD_LOADING](state, payload: boolean) {
    state.pvdLoading = payload;
  },
  [CrossSectionsMutations.SET_PVD_ERROR](state, payload: string) {
    state.pvdError = payload;
  },
};
