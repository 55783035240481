import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { ProjectState } from '@/store/project/types';
import getters from '@/store/project/getters';
import { mutations } from '@/store/project/mutations';
import { actions } from '@/store/project/actions';

const state : ProjectState = {
  loading: false,
  loadError: undefined,
  data: undefined,
  loadingAnalyticsData: undefined,
  allProjectsData: undefined,
  planningAnalytics: undefined,
  detailedProjectMetrics: undefined,
  loadingMetrics: false,
  loadingDashboardMetrics: false,
  dashboardMetrics: undefined,
  response: undefined,
  loadGraphics: false,
  graphicsError: undefined,
  graphics: undefined,
  headers: undefined,
  loadHeader: false,
  headerError: undefined,
  selectedAsset: undefined,
  loadAsset: false,
  selectedAssetError: undefined,
  names: undefined,
  loadNames: false,
  namesError: undefined,
  loadGeoJson: false,
  geoJsonError: undefined,
  agolMappingData: undefined,
  agolMappingDataError: undefined,
  agolMappingDataLoading: false,
  postAgolMappingDataError: undefined,
  postAgolMappingDataLoading: false,
  patchAgolMappingDataError: undefined,
  patchAgolMappingDataLoading: false,
  deleteAgolMappingDataError: undefined,
  deleteAgolMappingDataLoading: false,
  mapNeedsUpdated: true,
  turnOffAgolMappingLoading: false,
  turnOffAgolMappingError: undefined,
};

const project: Module<ProjectState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default project;
