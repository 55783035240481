import { Module } from 'vuex';
import { RootState } from '@/store/types';
import getters from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { ScopeHistoryState } from './types';

const state: ScopeHistoryState = {
  loading: false,
  loadError: undefined,
  assetHistory: undefined,
  data: undefined,
  assetHistoryPatchMessage: undefined,
  assetHistoryPatchError: false,
  assetWorkOrderHistory: undefined,
  assetWorkOrderLoading: false,
  assetWorkOrderError: undefined,
  assetPatchWorkOrderLoading: false,
  assetPatchWorkOrderError: undefined,
  projectDeliveryTotalsLoading: false,
  projectDeliveryTotalsError: undefined,
  projectDeliveryTotals: undefined,
  customerPrepReleaseEmails: [],
  customerPrepReleaseEmailsLoading: false,
  customerPrepReleaseEmailsError: undefined,
};

const scopeHistory: Module<ScopeHistoryState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default scopeHistory;
