















































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { BasicSelect } from 'vue-search-select';
import {
  Component, Watch, PropSync,
  Prop,
} from 'vue-property-decorator';
import CreateUser from '@/components/Admin/User/CreateUser.vue';
import EditUsers from '@/components/Admin/User/EditUsers.vue';
import { namespace } from 'vuex-class';
import { DetailedUserData } from '@/store/users/types';
import { DetailedUser } from '@/auth/auth0Types';
import { ProjectListing } from '@/store/project/types';
import { ProjectActions } from '@/store/project/actions';
import {
  getRoleString, SUPER_USER, getRolesArray, AuthUserRoles,
} from '@/auth/roles';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import { UserPermission } from '@/store/userpermissions/types';
import ProjectList from './ProjectList.vue';
import AdminPanelMixin from '../AdminPanelMixin.vue';

const projectModule = namespace('project');

// eslint-disable-next-line no-shadow
export enum UserCondition {
  INACTIVE = 'Inactive',
  PENDING = 'Pending',
  ACTIVE = 'Active',
}

@Component({
  components: {
    BasicSelect,
    CreateUser,
    EditUsers,
    IntegrityTable,
    IntegrityDelete,
    ProjectList,
  },
})
export default class ManageUsers extends AdminPanelMixin {
  @Prop() readonly search: string;

  @PropSync('userData') synchedUserData: Array<{
    role: any,
    auth0Id: any,
    detailedUserData: any,
    detailedUser: any,
    isExpanded: boolean
    visible: boolean
    resetDialog: boolean,
    guid?: string,
    fullName?: string
  }>;

  authUsers = [];

  selectedTab = 0;

  isExpanded = [];

  expanded = [];

  selectedItems = [];

  showSnackbar = false as boolean;

  snackbarColor = '' as string;

  snackbarMessage = '' as string;

  applyDark = false;

  editSelectOpen = false;

  rolesInfo: Array<{id: string, name: string}> = [];

  canManage: Array<string> = [];

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  @projectModule.Action(ProjectActions.FETCH_ALL_PROJECTS_DATA)
  fetchAllProjectsData;

  filterValues = {};

  allHeaders = [
    {
      text: 'Users',
      value: 'fullName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Email',
      value: 'detailedUserData.email',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Role',
      value: 'role',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Last Active',
      value: 'detailedUserData.lastActiveString',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Status',
      value: 'userStatus',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Edit',
      value: 'edit',
      sortable: false,
      filterable: false,
    },
    {
      text: 'Delete',
      value: 'delete',
      sortable: false,
      filterable: false,
    },
    {
      text: 'Password Reset',
      value: 'reset',
      sortable: false,
      filterable: false,
    },
  ];

  matchFilters = [
    {
      header: 'fullName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'detailedUserData.email',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'role',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      conversionFunction: this.getRolesArray,
    },
    {
      header: 'detailedUserData.lastActiveString',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'userStatus',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      conversionFunction: this.getStatusText,
    },
  ];

  resetPasswordDialog(item: any): void {
    const dialogState = this.userDataFiltered.find((u) => u.guid === item.guid).resetDialog;
    this.userDataFiltered.find((u) => u.guid === item.guid).resetDialog = !dialogState;
  }

  editPage(userGuid: any): void {
    const user = this.userDataFiltered.find((u) => u.guid === userGuid);
    window.location.href = this.$router.resolve({
      name: 'editUser',
      params: { id: user.detailedUserData.guid },
    }).href;
  }

  get userDataFiltered(): Array<any> {
    if (!this.synchedUserData) {
      return [];
    }
    return this.synchedUserData
      .filter((value) => value.visible)
      .map((value) => {
        // eslint-disable-next-line no-param-reassign
        value.guid = value.detailedUserData.guid; // Add guid for integrityTable
        // eslint-disable-next-line no-param-reassign
        value.fullName = `${value.detailedUserData.firstname} ${value.detailedUserData.lastname}`;
        return value;
      });
  }

  get managableUsers(): Array<any> {
    if (!this.userDataFiltered && this.canManage.length > 0) {
      return [];
    }
    const customerGuids = this.selectedItems.map(
      (i) => i.detailedUserData.customerGuids,
    ).flat();
    const organizationGuids = this.selectedItems.map(
      (i) => i.detailedUserData.organizationGuids,
    ).flat();
    const selectedUserGuids = this.selectedItems.map((u) => u.guid);
    return this.userDataFiltered.filter((user) => user.role[0]
    && !selectedUserGuids.includes(user.guid)
    && (user.detailedUserData.organizationGuids.some(
      (o) => organizationGuids.some((u) => u === o),
    )
    || (user.detailedUserData.customerGuids.some(
      (o) => customerGuids.some((u) => u === o),
    )))
    && this.canManage.includes(user.role[0]));
  }

  get organizations(): Array<{value: number; text: string, guid: string}> {
    if (this.selectedItems.length === 1 && this.managableUsers.length > 0
    ) {
      const managerOrganizationGuids = this.selectedItems.map(
        (i) => i.detailedUserData.organizationGuids,
      ).flat();
      const managedOrganizationGuids = this.managableUsers.map(
        (i) => i.detailedUserData.organizationGuids,
      ).flat();
      const matchGuids = [...new Set(managerOrganizationGuids.filter(
        (g) => managedOrganizationGuids.includes(g),
      ))];
      return this.organizationDataList.filter((org) => matchGuids.includes(org.guid))
        .map((org, i) => ({ value: i, text: org.name, guid: org.guid }));
    }
    return [];
  }

  get customers(): Array<{value: number; text: string, guid: string}> {
    if (this.selectedItems.length === 1 && this.managableUsers.length > 0
    ) {
      const managerCustomerGuids = this.selectedItems.map(
        (i) => i.detailedUserData.customerGuids,
      ).flat();
      const managedCustomerGuids = this.managableUsers.map(
        (i) => i.detailedUserData.customerGuids,
      ).flat();
      const matchGuids = [...new Set(managerCustomerGuids.filter(
        (g) => managedCustomerGuids.includes(g),
      ))];
      return this.customerDataList.filter((cust) => matchGuids.includes(cust.guid))
        .map((cust, i) => ({ value: i, text: cust.name, guid: cust.guid }));
    }
    return [];
  }

  get headers(): any[] {
    let list = this.allHeaders;
    if (!this.hasPermissionAdminDeleteUser) {
      list = list.filter((item) => item.value !== 'delete');
    }
    if (!this.hasPermissionAdminResetUserPassword) {
      list = list.filter((item) => item.value !== 'reset');
    }
    return list;
  }

  /**
   * @returns true if the user has the permission ADMIN_DELETEUSER
   */
  get hasPermissionAdminDeleteUser(): boolean {
    return this.hasPermission(UserPermission.ADMIN_DELETEUSER);
  }

  /**
   * @returns true if the user has the permission ADMIN_RESETUSERPASSWORD
   */
  get hasPermissionAdminResetUserPassword(): boolean {
    return this.hasPermission(UserPermission.ADMIN_RESETUSERPASSWORD);
  }

  /**
   * @returns true if the user has the permission ADMIN_SETDATERANGEFORACTIVATION
   */
  get hasPermissionAdminSetDateRangeForActivation(): boolean {
    return this.hasPermission(UserPermission.ADMIN_SETDATERANGEFORACTIVATION);
  }

  /**
   * @returns true if the user has the permission ADMIN_SETDATERANGEFORACTIVATION
   */
  get hasPermissionAdminCanSeeInactiveUsers(): boolean {
    return this.hasPermission(UserPermission.ADMIN_SEE_INACTIVE_USERS);
  }

  getStatusColor(user: DetailedUser): string {
    switch (this.getUserCondition(user)) {
      case UserCondition.INACTIVE: return '#f9d4d4';
      case UserCondition.PENDING: return '#d2e4f6';
      case UserCondition.ACTIVE: return '#cae0d7';
      default: return '';
    }
  }

  getStatusTextColor(user: DetailedUser): string {
    switch (this.getUserCondition(user)) {
      case UserCondition.INACTIVE: return '#e61e25';
      case UserCondition.PENDING: return '#086699';
      case UserCondition.ACTIVE: return '#01734b';
      default: return '';
    }
  }

  getStatusText(user: any): string {
    switch (this.getUserCondition(user.detailedUser)) {
      case UserCondition.INACTIVE: return 'Inactive';
      case UserCondition.PENDING: return 'Pending';
      case UserCondition.ACTIVE: return 'Active';
      default: return '';
    }
  }

  getUserCondition(detailedUser: DetailedUser): UserCondition {
    const userDB = this.allDBUsers.find((u) => u.useridentity === detailedUser.userId);
    if (!detailedUser || detailedUser.userId === null || !userDB?.isActive) {
      return UserCondition.INACTIVE;
    }
    if (detailedUser.lastLogin === '') {
      return UserCondition.PENDING;
    }
    return UserCondition.ACTIVE;
  }

  async getCanManage(): Promise<Array<string>> {
    if (this.selectedItems.length === 1) {
      const permissions = await this.$auth.getRolePermission(this.selectedItems[0].role[0]);
      if (this.hasPermissionGroupFromPermissions(permissions, 'AdminCanManage')) {
        return this.roleIdFromPermissions(this.permissionsFromGroupFromPermissions(permissions, 'AdminCanManage'));
      }
    }
    return [];
  }

  async getLastActiveString(dbUser: DetailedUserData): Promise<string> {
    // eslint-disable-next-line no-nested-ternary, no-unreachable
    const user: DetailedUser = dbUser.useridentity != null && dbUser.useridentity !== ''
      ? this.authUsers.find(
        (value) => value.userId === dbUser.useridentity,
      ) != null
        ? this.authUsers.find((value) => value.userId === dbUser.useridentity)
        : null
      : null;

    const lastLogin = user != null && user.lastLogin != null ? user.lastLogin : '';

    const currentDate: number = Date.now();
    const userDate = new Date(lastLogin).getTime();
    const dateDifference: Date = new Date(currentDate - userDate);
    const years = dateDifference.getUTCFullYear() - 1970;
    const months = dateDifference.getUTCMonth();
    const days = dateDifference.getUTCDate();

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(years) || isNaN(months) || isNaN(days)) {
      return 'Unknown';
    }

    if (years > 0) {
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
    if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    }
    if (days >= 7) {
      const weeks = Math.floor(days / 7);
      return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    }

    return `${days} day${days > 1 ? 's' : ''} ago`;
  }

  async deleteUser(userData: any): Promise<boolean> {
    const user = userData.detailedUserData;
    try {
      const auth0UserId = user.useridentity;

      const auth0UserDeleted = await this.$auth.deleteUser(auth0UserId);
      await this.deleteUserAction(user.guid);
      if (!auth0UserDeleted) {
        return false;
      }
    } catch (e) {
      return false;
    }
    // eslint-disable-next-line no-param-reassign
    userData.visible = false;
    // eslint-disable-next-line no-param-reassign
    userData.detailedUserData.isExpanded = false;
    this.$forceUpdate();
    return true;
  }

  deleteUserInterface(guid: string): void {
    this.userDataFiltered.find(
      (user) => user.detailedUserData.guid === guid,
    ).visible = false;
  }

  onAddUser(): void {
    if (this.synchedUserData.length > 0) {
      this.synchedUserData = [];
    }
    this.fetchInactiveDetailedUserData();
    this.fetchAllDetailedUserData().then(this.updateAllUsers);
  }

  async mounted(): Promise<void> {
    this.fetchAllProjectsData();
    this.updateAllUsers();
    this.$root.$on('clearUserTableFilters', () => {
      (this.$refs.usersTable as IntegrityTable).clearAllMatchFilters();
    });
    this.rolesInfo = await this.$auth.getRoleInfo();
    AuthUserRoles.forEach((authRole) => {
      const foundRole = this.rolesInfo.find((role) => role.name === authRole.name);
      // eslint-disable-next-line no-param-reassign
      authRole.id = foundRole.id;
    });
  }

  roleIdFromPermissions(permissions: UserPermission[]): string[] {
    const retVal = [];
    const permissionStrings = permissions.map((p) => p.split(':')[1]);
    AuthUserRoles.forEach((role) => {
      const roleName = role.name.replace(' ', '');
      if (permissionStrings.includes(roleName)) {
        retVal.push(role.id);
      }
    });
    return retVal;
  }

  uniqueValues(value, index, arr) {
    return arr.indexOf(value) === index;
  }

  @Watch('selectedItems')
  async onSelectedItemsChange(): Promise<void> {
    this.canManage = await this.getCanManage();
  }

  @Watch('synchedUserData')
  onUserDataChange(): void {
    this.filterValues['role'] = [];
    this.filterValues['userStatus'] = [];
    this.filterValues['detailedUserData.lastActiveString'] = [];

    this.userDataFiltered.forEach((data) => {
      this.filterValues['role'].push(...getRolesArray(data.role));
      this.filterValues['userStatus'].push(this.getStatusText(data));
      this.filterValues['detailedUserData.lastActiveString'].push(
        data.detailedUserData.lastActiveString,
      );
    });

    this.filterValues['role'] = this.filterValues['role'].filter(
      this.uniqueValues,
    );
    this.filterValues['userStatus'] = this.filterValues['userStatus'].filter(
      this.uniqueValues,
    );
    this.filterValues['detailedUserData.lastActiveString'] = this.filterValues[
      'detailedUserData.lastActiveString'
    ].filter(this.uniqueValues);
  }

  async updateAllUsers(): Promise<void> {
    this.synchedUserData = [];
    this.authUsers = await this.$auth.getAllUsers();
    const roleUsers = await this.$auth.getAllUserRoles();
    let isSysAdmin = true;

    setTimeout(() => {
      const currentUser = this.allDBUsers.find((user) => {
        if (user.useridentity) {
          return user.useridentity.replace('auth0|', '') === this.$auth.user.id;
        }
        return false;
      });

      const filteredUsers = currentUser != null ? this.deepCopyFunction(this.allDBUsers) : [];

      if (currentUser != null) {
        roleUsers
          .filter((rU) => rU.userId === `auth0|${this.$auth.user.id}`)
          .forEach((role) => {
            if (role.role === SUPER_USER) {
              isSysAdmin = false;
            }
          });
        filteredUsers.splice(
          filteredUsers.indexOf(
            filteredUsers.find((fU) => fU.guid === currentUser.guid),
          ),
          1,
        );
        filteredUsers.forEach((user) => {
          const removeMe = [];
          user.role.forEach((role) => {
            let foundMatch = false;
            let skip = false;
            if (isSysAdmin) {
              if (
                role.managerGuid == null
                || role.managerGuid !== currentUser.guid
              ) {
                removeMe.push(role);
                skip = true;
              }
            }
            if (skip) {
              const ru = roleUsers.find(
                (val) => val.userId === currentUser.useridentity,
              );
              if (ru != null && ru.role === SUPER_USER) {
                foundMatch = true;
              }
              currentUser.role.forEach((value) => {
                if (value.projectGuid === role.projectGuid) {
                  foundMatch = true;
                }
              });
              if (!foundMatch) removeMe.push(role);
            }
          });
          removeMe.forEach((role) => {
            user.role.splice(
              user.role.findIndex((value) => value.guid === role.guid),
              1,
            );
          });
        });
      }

      for (let i = 0; i < filteredUsers.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        this.getLastActiveString(filteredUsers[i]).then((str) => {
          filteredUsers[i].lastActiveString = str;
          this.$forceUpdate();
        });
      }
      filteredUsers.forEach(async (user) => {
        let visible = true;
        if (user == null || (user.role.length <= 0 && isSysAdmin)) {
          visible = false;
        }
        if (user.useridentity != null && user.useridentity !== '') {
          const info = this.authUsers.find(
            (value) => value.userId === user.useridentity,
          );
          if (info == null) {
            this.synchedUserData.push({
              role: null,
              auth0Id: null,
              detailedUserData: user,
              detailedUser: null,
              isExpanded: false,
              visible: false,
              resetDialog: false,
            });
          } else if (info.userId != null && info.userId !== '') {
            let role = [];
            try {
              roleUsers.find((value) => {
                if (Array.isArray(value)) {
                  value.forEach((val) => {
                    if (val.userId === user.useridentity) {
                      role.push(val.role);
                    }
                  });
                } else if (value.userId === user.useridentity) {
                  role.push(value.role);
                }
                return '';
              });
            } catch (e) {
              role = [];
            }

            this.synchedUserData.push({
              role,
              auth0Id: info.userId,
              detailedUserData: user,
              detailedUser: info,
              isExpanded: false,
              visible,
              resetDialog: false,
            });
          } else {
            this.synchedUserData.push({
              role: null,
              auth0Id: null,
              detailedUserData: user,
              detailedUser: null,
              isExpanded: false,
              visible: false,
              resetDialog: false,
            });
          }
        } else {
          this.synchedUserData.push({
            role: null,
            auth0Id: null,
            detailedUserData: user,
            detailedUser: null,
            isExpanded: false,
            visible: false,
            resetDialog: false,
          });
        }
      });
    }, 1000);
  }

  getRoleString(role: string): string {
    return role && role !== '' ? getRoleString(role) : '';
  }

  getRolesArray(item): string[] {
    return getRolesArray(item.role);
  }

  resetPassword(userGuid: string): void {
    const user = this.userDataFiltered.find((u) => u.guid === userGuid);
    const { email } = user.detailedUserData;

    this.$auth.sendPasswordReset(email);
    this.snackbarColor = 'green';
    this.snackbarMessage = 'Reset Password E-Mail Sent Successfully';
    this.showSnackbar = true;
  }

  deepCopyFunction(inObject): any {
    let outObject;
    let value;
    let key;

    if (typeof inObject !== 'object' || inObject === null) {
      return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    // eslint-disable-next-line prefer-const
    outObject = Array.isArray(inObject) ? [] : {};

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (key in inObject) {
      value = inObject[key];

      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = this.deepCopyFunction(value);
    }

    return outObject;
  }

  editSelect(): void {
    this.editSelectOpen = !this.editSelectOpen;
  }

  get allDBUsers(): DetailedUserData[] {
    if (this.hasPermissionAdminCanSeeInactiveUsers
    && this.allUsers && this.inactiveDetailedUserData) {
      return [...this.allUsers, ...this.inactiveDetailedUserData];
    }
    return this.allUsers;
  }
}
