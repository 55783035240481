


































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { CrewCountsWithAverages, FetchCrewCountsPayload } from '@/store/metrics/types';
import { Component, Prop, Watch } from 'vue-property-decorator';
import CrewDashboardCard from './CrewDashboardCard.vue';
import CrewDashboardMixin from './CrewDashboardMixin.vue';

@Component({ components: { CrewDashboardCard } })
export default class CrewDashboard extends CrewDashboardMixin {
  @Prop() readonly ids!: string;

  tab = 0;

  id = '';

  @Watch('id')
  async onProjectGuidChange(): Promise<void> {
    if (this.id == null || this.id === '') {
      return;
    }
    this.fetchCrewCountsByDay(this.id);
    this.fetchOverviewTotalCounts(this.id);

    await this.fetchAssetCountMetrics(this.id);
    await this.fetchProjectHeaders(this.projectGuidList);
    await this.fetchPlatformCrewOverview(this.id);
    this.fetchCrewDataDeliveryMetrics({
      projectGuid: this.id,
      platformName: this.selectedPlatformName,
    });
    this.fetchCrewData();
  }

  async mounted(): Promise<void> {
    try {
      this.projectGuidList = JSON.parse(this.ids) as string[];
      // eslint-disable-next-line prefer-destructuring
      this.id = this.projectGuidList[0];
    } catch {
      this.id = this.ids;
      this.projectGuidList = [this.id];
    }
    this.onProjectGuidChange();
  }

  uniqueValuesWithKey(array: any[], key: string): any {
    return [...new Set(array.map((s) => s[key]))];
  }

  uniqueObjectValuesWithKey(array: any[], key: string): any[] {
    return this.uniqueValuesWithKey(array, key).map((id) => array.find((s) => s[key] === id));
  }

  async fetchCrewData(): Promise<void> {
    const tempAverageStartDate = new Date();
    // Only replace if you need to query a larger date range on startup.
    // tempAverageStartDate.setDate(new Date().getDate() - 1000);
    tempAverageStartDate.setDate(new Date().getDate() - 8);
    const averagesStartDate = tempAverageStartDate.toLocaleDateString('en-US', { timeZone: 'America/New_York' });
    const tempAverageEndDate = new Date();
    tempAverageEndDate.setDate(new Date().getDate() - 1);
    const averagesEndDate = tempAverageEndDate.toLocaleDateString('en-US', { timeZone: 'America/New_York' });
    const crewCountsAveragesPayload: FetchCrewCountsPayload = {
      projectGuid: this.id,
      platformName: this.selectedPlatformName,
      startDate: averagesStartDate,
      endDate: averagesEndDate,
    };
    this.fetchCrewCountsAverages(crewCountsAveragesPayload);
  }

  getCrewCountsWithAveragesByCrew(crewName: string): CrewCountsWithAverages | undefined {
    // eslint-disable-next-line no-param-reassign
    const foundValue = this.crewCountsWithAverages?.find((value) => value.crewName === crewName);
    return foundValue || undefined;
  }

  get renderDashboardCards(): boolean {
    return this.id
    && this.crewCountsWithAverages
    && this.crewDataDeliveryProjectMetrics
    && this.uniqueCrewsFromPlatform;
  }

  get tabItems(): any {
    if (!this.platformCrewCounts) {
      return null;
    }

    const unique = this.uniqueObjectValuesWithKey(
      this.platformCrewCounts,
      'platform',
    );

    if (!unique || unique.length === 0) {
      return null;
    }

    return unique.map((platform) => ({
      tab: platform.platform,
      guid: platform.platformGuid,
    }));
  }

  get uniqueCrewsFromPlatform(): any {
    const platformData = this.platformCrewCounts.filter(
      (platform) => platform.platformGuid === this.selectedPlatformGuid,
    );

    platformData.sort((x) => -x.totalCount);

    const uniqueCrews = [
      ...new Set(platformData.map((platforms) => platforms.crewName)),
    ];

    return uniqueCrews;
  }

  get selectedPlatformGuid(): string {
    if (!this.tabItems) {
      return '';
    }
    return this.tabItems[this.tab].guid;
  }

  get selectedPlatformName(): string {
    if (!this.tabItems) {
      return '';
    }
    return this.tabItems[this.tab].tab;
  }

  projectGuidList: string[] = [];

  get assetCountsMetricsLineSegmentTotal(): number {
    return this.assetCountsMetrics?.lineSegments?.total != null
      ? this.assetCountsMetrics.lineSegments.total
      : 0;
  }
}
