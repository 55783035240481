/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { InspectionMutations } from './mutations';
import { InspectionState } from './types';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum InspectionActions {
  FETCH_CODING_DETAIL = 'FETCH_CODING_DETAIL',
  PATCH_CODING_DETAIL = 'PATCH_CODING_DETAIL',
  DELETE_SUB_INSPECTION = 'DELETE_SUB_INSPECTION',
  FETCH_CONDITION_DETAILS = 'FETCH_CONDITION_DETAILS',
  SET_CODING_FORM = 'SET_CODING_FORM',
  SET_SUB_CODING_FORM = 'SET_SUB_CODING_FORM',
}

export const actions: ActionTree<InspectionState, RootState> = {

  async [InspectionActions.FETCH_CODING_DETAIL]({ commit }, payload): Promise<void> {
    commit(InspectionMutations.SET_CODING_STATE, true);
    commit(InspectionMutations.SET_CODING_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getInspectionDetail(payload);

      commit(InspectionMutations.SET_CODING_DETAIL, response.data);
    } catch (e) {
      commit(InspectionMutations.SET_CODING_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(InspectionMutations.SET_CODING_STATE, false);
    }
  },

  async [InspectionActions.PATCH_CODING_DETAIL]({ commit }, payload): Promise<boolean> {
    commit(InspectionMutations.SET_CODING_STATE, true);
    commit(InspectionMutations.SET_CODING_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.patchInspectionDetail(payload.inspectionGuid, payload.payload);
      return true;
    } catch (e: any) {
      if (e.response.status === 400) {
        commit(InspectionMutations.SET_CODING_ERROR, e.response.data.Message);
      } else {
        const { message } = e;
        commit(InspectionMutations.SET_CODING_ERROR, message);
      }
      throw new Error(e);
    } finally {
      commit(InspectionMutations.SET_CODING_STATE, false);
    }
  },

  async [InspectionActions.DELETE_SUB_INSPECTION]({ commit }, payload): Promise<boolean> {
    commit(InspectionMutations.SET_CODING_STATE, true);
    commit(InspectionMutations.SET_CODING_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.deleteInspectionDetail(payload);
      return true;
    } catch (e: any) {
      const { message } = e;
      commit(InspectionMutations.SET_CODING_ERROR, message);
      throw new Error(e);
    } finally {
      commit(InspectionMutations.SET_CODING_STATE, false);
    }
  },

  async [InspectionActions.FETCH_CONDITION_DETAILS]({ commit }, payload): Promise<void> {
    commit(InspectionMutations.SET_CONDITION_STATE, true);
    commit(InspectionMutations.SET_CONDITION_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getConditionDetails(payload);

      commit(InspectionMutations.SET_CONDITION_DETAIL, response.data.result);
    } catch (e) {
      commit(InspectionMutations.SET_CONDITION_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(InspectionMutations.SET_CONDITION_STATE, false);
    }
  },

  async [InspectionActions.SET_CODING_FORM]({ commit }, payload): Promise<void> {
    commit(InspectionMutations.SET_CODING_STATE, true);
    commit(InspectionMutations.SET_CODING_ERROR, undefined);

    try {
      commit(InspectionMutations.SET_CODING_FORM, payload);
    } catch (e) {
      commit(InspectionMutations.SET_CODING_ERROR, (e as Error).message);
      throw new Error('Update state error.');
    } finally {
      commit(InspectionMutations.SET_CODING_STATE, false);
    }
  },

  async [InspectionActions.SET_SUB_CODING_FORM]({ commit }, payload): Promise<void> {
    commit(InspectionMutations.SET_CODING_STATE, true);
    commit(InspectionMutations.SET_CODING_ERROR, undefined);

    try {
      commit(InspectionMutations.SET_SUB_CODING_FORM, payload);
    } catch (e) {
      commit(InspectionMutations.SET_CODING_ERROR, (e as Error).message);
      throw new Error('Update state error.');
    } finally {
      commit(InspectionMutations.SET_CODING_STATE, false);
    }
  },

};
