// eslint-disable-next-line no-shadow
export enum UserPermission {
    // #region Default Permissions
    // #endregion
    //
    // #region GIS Permissions
    GIS_COMMON = 'GISMap:Common',
    GIS_APPLYFILTERS = 'GISMap:ApplyFilters',
    GIS_VIEWASSETDETAILS = 'GISMap:ViewAssetDetails',
    GIS_PDF_MAP_EXPORT = 'GISMap:ExportmaptoPDF',
    GIS_ROUTE_CREATION = 'GISMap:CreateaRoute',
    GIS_NAVIGATE_TO_INSPECTION_VIEWER = 'GISMap:NavigatetoInspectionViewer',
    GIS_SEARCH_FOR_AN_ASSET = 'GISMap:SearchforanAsset',
    GIS_SHOW_TAB = 'GISMap:ShowTab',
    GIS_TAKE_MEASUREMENTS = 'GISMap:TakeMeasurements',
    GIS_USE_SELECTION_TOOLS = 'GISMap:UseSelectionTools',
    GIS_VIEW_LAYERS = 'GISMap:ViewLayers',
    GIS_VIEW_ROUTES = 'GISMap:ViewRoutes',
    GIS_VIEW_COLLECTIONS_TAB = 'GISMap:ViewCollectionsTab',
    GIS_VIEW_MAP = 'GISMap:ViewtheMap',
    // #endregion
    //
    // #region Work Order Permissions
    WORK_ORDER_DELETE = 'WorkOrders:DeleteWorkOrders',
    WORK_ORDER_COMMON = 'WorkOrders:Common',
    WORK_ORDER_RESET_PLANNING = 'WorkOrders:ResetWorkOrderPlanning',
    WORK_ORDER_PLANNING = 'WorkOrders:PlanWorkOrders',
    WORK_ORDER_EDIT_FORM = 'WorkOrders:EditWorkOrderForm',
    WORK_ORDER_EXPORT_PDF = 'WorkOrders:ExportWorkOrderPDF',
    WORK_ORDER_CREATE_WORK_ORDER = 'WorkOrders:CreateNewWorkOrder',
    WORK_ORDER_FILTER_COLUMNS = 'WorkOrders:FilterColumns',
    WORK_ORDER_FILTER_ASSIGNED_TO_ME = 'WorkOrders:FiltertoAssignedtoMe',
    WORK_ORDER_SHOW_TAB = 'WorkOrders:ShowTab',
    WORK_ORDER_VIEW_WORK_ORDER_FORM = 'WorkOrders:ViewWorkOrderForm',
    WORK_ORDER_VIEW_NEW_DEPLOYMENT_FORM = 'WorkOrders:ViewNewDeploymentForm',
    WORK_ORDER_VIEW_WORK_ORDER_TABLE = 'WorkOrders:ViewWorkOrderTable',
    WORK_ORDER_CREATENEWDEPLOYMENT = 'WorkOrders:CreateNewDeployment',
    // #endregion
    //
    // #region Asset Inventory Permissions
    ASSET_INVENTORY_COMMON = 'AssetInventory:Common',
    ASSET_INVENTORY_EDIT_ASSET = 'AssetInventory:EditanAsset',
    ASSET_INVENTORY_DELETEASSET = 'AssetInventory:DeleteanAsset',
    ASSET_INVENTORY_EXPORTDATA = 'AssetInventory:ExportData',
    ASSET_INVENTORY_CREATEROUTE = 'AssetInventory:CreateaRoute',
    ASSET_INVENTORY_FILTER_COLUMNS = 'AssetInventory:Filtercolumns',
    ASSET_INVENTORY_SEARCH_FOR_ASSET = 'AssetInventory:SearchforanAsset',
    ASSET_INVENTORY_SHOW_TAB = 'AssetInventory:ShowTab',
    ASSET_INVENTORY_VIEW_TABLE = 'AssetInventory:ViewTable',
    // #endregion
    //
    // #region Deployments Permissions
    DEPLOYMENTS_COMMON = 'Deployments:Common',
    DEPLOYMENTS_EDITINLINE = 'Deployments:EditDeploymentDetailsInline',
    DEPLOYMENTS_EDITROW = 'Deployments:EditDeploymentDetailsDialog',
    DEPLOYMENTS_EDIT_UNINVOLVED_DEPLOYMENT_DETAILS = 'Deployments:EditUninvolvedDeploymentDetails',
    DEPLOYMENTS_EXPORT = 'Deployments:ExportData',
    DEPLOYMENTS_FILTER_COLUMNS = 'Deployments:FilterColumns',
    DEPLOYMENTS_SHOW_TAB = 'Deployments:ShowTab',
    DEPLOYMENTS_VIEW_TABLE = 'Deployments:ViewTable',
    // #endregion
    //
    // #region Project Selection Permissions
    PROJECT_SELECTION_COMMON = 'ProjectSelection:Common',
    PROJECT_SELECTION_CREATE_A_PROJECT = 'ProjectSelection:CreateaProject',
    PROJECT_SELECTION_DELETE_A_PROJECT = 'ProjectSelection:DeleteaProject',
    PROJECT_SELECTION_EDIT_A_PROJECT = 'ProjectSelection:EditaProject',
    PROJECT_SELECTION_FILTER_COLUMNS = 'ProjectSelection:FilterColumns',
    PROJECT_SELECTION_LAUNCH_A_PROJECT = 'ProjectSelection:LaunchaProject',
    PROJECT_SELECTION_SHOW_TAB = 'ProjectSelection:ShowTab',
    PROJECT_SELECTION_VIEW_PROJECTS = 'ProjectSelection:ViewProjects',
    PROJECT_SELECTION_EDITMPS = 'ProjectSelection:EditMPS',
    PROJECT_SELECTION_CREATE_CUSTOMER = 'ProjectSelection:CreateaCustomer',
    PROJECT_SELECTION_CREATE_ORGANIZATION = 'ProjectSelection:CreateanOrganization',
    PROJECT_SELECTION_VIEW_MPS = 'ProjectSelection:ViewMPS',
    // #endregion
    //
    // #region Admin Panel Permissions
    ADMIN_COMMON = 'Admin:Common',
    ADMIN_ADDUSER = 'Admin:AddNewUser',
    ADMIN_DELETEUSER = 'Admin:DeleteUser',
    ADMIN_RESETUSERPASSWORD = 'Admin:ResetUserPassword',
    ADMIN_SETDATERANGEFORACTIVATION = 'Admin:SetDateRangeforActivation',
    ADMIN_EDITUSER = 'Admin:EditUser',
    ADMIN_FILTERCOLUMNS = 'Admin:FilterColumns',
    ADMIN_SHOWTAB = 'Admin:ShowTab',
    ADMIN_VIEWCURRENTUSERS = 'Admin:ViewCurrentUsers',
    ADMIN_SEE_INACTIVE_USERS = 'Admin:SeeInactiveUsers',
    // #endregion
    //
        //
    // #region Admin Customer Selection Panel Permissions
    ADMIN_CUSTOMER_SECTION_CREATEACUSTOMER = 'AdminCustomerSection:CreateaCustomer',
    ADMIN_CUSTOMER_SECTION_EDITACUSTOMER = 'AdminCustomerSection:EditaCustomer',
    ADMIN_CUSTOMER_SECTION_SHOWCUSTOMERSECTION = 'AdminCustomerSection:ShowCustomerSection',
    // #endregion
    //
    //
    // #region Admin Organization Selection Panel Permissions
    ADMIN_ORGANIZATION_SECTION_CREATEANORGANIZATION = 'AdminOrganizationSection:CreateanOrganization',
    ADMIN_ORGANIZATION_SECTION_EDITANORGANIZATION = 'AdminOrganizationSection:EditanOrganization',
    ADMIN_ORGANIZATION_SECTION_SHOWORGANIZATIONSECTION = 'AdminOrganizationSection:ShowOrganizationSection',
    // #endregion
    //
    //
    // #region Admin User Selection Panel Permissions
    ADMIN_USER_SECTION_ADDNEWUSER = 'AdminUserSection:AddNewUser',
    ADMIN_USER_SECTION_DELETEUSER = 'AdminUserSection:DeleteUser',
    ADMIN_USER_SECTION_EDITUSER = 'AdminUserSection:EditUser',
    ADMIN_USER_SECTION_FILTERCOLUMNS = 'AdminUserSection:FilterColumns',
    ADMIN_USER_SECTION_RESETUSERPASSWORD = 'AdminUserSection:ResetUserPassword',
    ADMIN_USER_SECTION_SETDATERANGEFORACTIVATION = 'AdminUserSection:SetDateRangeforActivation',
    ADMIN_USER_SECTION_SHOWUSERSECTION = 'AdminUserSection:ShowUserSection',
    // #endregion
    //
    // #region Project Management Dashboard Permissions
    PROJECT_MANAGEMENT_DASHBOARD_VIEW_DASHBOARD = 'ProjectManagementDashboard:ViewDashboard',
    // #endregion
    //
    // #region Customer Dashboard Permissions
    CUSTOMER_PROJECT_PROGRESS_DASHBOARD_VIEW_DASHBOARD = 'CustomerProjectProgressDashboard:ViewDashboard',
    CSA_DASHBOARD_CREATE_A_PDF_EXPORT = 'CustomerSystemAssessmentDashboard:CreateaPDFExport',
    CSA_DASHBOARD_FILTERTABLE_DISPLAY = 'CustomerSystemAssessmentDashboard:Filtertabledisplay',
    CSA_DASHBOARD_VIEW_ASSETS_IN_ASSET_INVENTORY_TABLE = 'CustomerSystemAssessmentDashboard:ViewAssetsinAssetInventoryTable',
    CSA_DASHBOARD_VIEW_ASSETS_IN_MAP = 'CustomerSystemAssessmentDashboard:ViewAssetsinMap',
    CSA_DASHBOARD_VIEW_DASHBOARD = 'CustomerSystemAssessmentDashboard:ViewDashboard',
    CWO_DASHBOARD_CREATE_A_PDF_EXPORT = 'CustomerWorkOrderDashboard:CreateaPDFExport',
    CWO_DASHBOARD_FILTER_TABLE_DISPLAY = 'CustomerWorkOrderDashboard:Filtertabledisplay',
    CWO_DASHBOARD_VIEW_ASSETS_IN_MAP = 'CustomerWorkOrderDashboard:ViewAssetsinMap',
    CWO_DASHBOARD_VIEW_ASSETS_IN_WORK_ORDERS_TABLE = 'CustomerWorkOrderDashboard:ViewAssetsinWorkOrdersTable',
    CWO_DASHBOARD_VIEW_DASHBOARD = 'CustomerWorkOrderDashboard:ViewDashboard',
    // #endregion
    //
    // #region Dashboard Tab Permissions
    DASHBOARDS_TAB_COMMON = 'DashboardsTab:Common',
    DASHBOARDS_TAB_SHOW_TAB = 'DashboardsTab:ShowTab',
    // #endregion
    //
    // #region Inspection Viewer Permissions
    INSPECTION_VIEWER_COMMON = 'InspectionViewer:Common',
    INSPECTION_VIEWER_REPORT_EXPORT = 'InspectionViewer:ExportReport',
    INSPECTION_VIEWER_NASSCO_EDIT = 'InspectionViewer:EditPACPMACPForm',
    INSPECTION_VIEWER_FEEDBACK = 'InspectionViewer:ProvideReportingFeedback',
    INSPECTION_VIEWER_CODING = 'InspectionViewer:CreateaCode',
    INSPECTION_VIEWER_EDIT_CODES = 'InspectionViewer:EditCodes',
    INSPECTION_VIEWER_VALIDATE_SCORE = 'InspectionViewer:ValidateandScoreanInspection',
    INSPECTION_VIEWER_SHOW_PAGE = 'InspectionViewer:ShowPage',
    INSPECTION_VIEWER_VIEW_AND_PLAYBACK_VIDEO = 'InspectionViewer:ViewandPlaybackVideo',
    INSPECTION_VIEWER_VIEW_CODES = 'InspectionViewer:ViewCodes',
    INSPECTION_VIEWER_VIEW_MAP = 'InspectionViewer:ViewMap',
    INSPECTION_VIEWER_VIEW_MODELS = 'InspectionViewer:ViewModels',
    INSPECTION_VIEWER_VIEW_MSI_DETAILS = 'InspectionViewer:ViewMSIDetails',
    INSPECTION_VIEWER_VIEW_VIEW_NASSCO_FORM = 'InspectionViewer:ViewPACPMACPForm',
    INSPECTION_VIEWER_VIEW_REPORT = 'InspectionViewer:ViewReport',
    INSPECTION_VIEWER_SELECTINSPECTION = 'InspectionViewer:SelectInspection',
    // #endregion
    //
    // #region Asset History Permissions
    ASSET_HISTORY_COLLECTION_SECTION_CREATE_A_WORK_ORDER = 'AssetHistoryCollectionSection:CreateaWorkOrder',
    ASSET_HISTORY_COLLECTION_SECTION_EDIT_COLLECTION_STATUS = 'AssetHistoryCollectionSection:EditCollectionStatus',
    ASSET_HISTORY_COLLECTION_SECTION_NAVIGATE_TO_DEPLOYMENT_TABLE = 'AssetHistoryCollectionSection:NavigatetoDeploymentTable',
    ASSET_HISTORY_COLLECTION_SECTION_NAVIGATE_TO_INSPECTION_VIEWER = 'AssetHistoryCollectionSection:NavigatetoInspectionViewer',
    ASSET_HISTORY_COLLECTION_SECTION_SHOW_COLLECTION_SECTION = 'AssetHistoryCollectionSection:ShowCollectionSection',
    ASSET_HISTORY_COLLECTION_SECTION_VIEW_TABLE = 'AssetHistoryCollectionSection:ViewTable',
    ASSET_HISTORY_CUSTOMER_PREP_SECTION_CREATE_A_RELEASE = 'AssetHistoryCustomerPrepSection:CreateaRelease',
    ASSET_HISTORY_CUSTOMER_PREP_SECTION_NAVIGATE_TO_INSPECTION_VIEWER = 'AssetHistoryCustomerPrepSection:NavigatetoInspectionViewer',
    ASSET_HISTORY_CUSTOMER_PREP_SECTION_SELECT_ASSETS_FOR_RELEASE = 'AssetHistoryCustomerPrepSection:SelectAssetsforRelease',
    ASSET_HISTORY_CUSTOMER_PREP_SECTION_SHOW_CUSTOMER_PREP_SECTION = 'AssetHistoryCustomerPrepSection:ShowCustomerPrepSection',
    ASSET_HISTORY_CUSTOMER_PREP_SECTION_VIEW_TABLE = 'AssetHistoryCustomerPrepSection:ViewTable',
    ASSET_HISTORY_RELEASES_SECTION_DELETE_A_RELEASE = 'AssetHistoryReleasesSection:DeleteaRelease',
    ASSET_HISTORY_RELEASES_SECTION_EDIT_A_RELEASE = 'AssetHistoryReleasesSection:EditaRelease',
    ASSET_HISTORY_RELEASES_SECTION_NAVIGATE_TO_AN_INDIVIDUAL_RELEASE = 'AssetHistoryReleasesSection:NavigatetoanIndividualRelease',
    ASSET_HISTORY_RELEASES_SECTION_REMOVE_AN_ASSET_FROM_A_RELEASE = 'AssetHistoryReleasesSection:RemoveanAssetfromaRelease',
    ASSET_HISTORY_RELEASES_SECTION_SHOW_RELEASES_SECTION = 'AssetHistoryReleasesSection:ShowReleasesSection',
    ASSET_HISTORY_RELEASES_SECTION_VIEW_ALL_RELEASES = 'AssetHistoryReleasesSection:ViewallReleases',
    ASSET_HISTORY_RELEASES_SECTION_VIEW_INDIVIDUAL_RELEASE_DETAILS = 'AssetHistoryReleasesSection:ViewIndividualReleaseDetails',
    ASSET_HISTORY_TAB_COMMON = 'AssetHistoryTab:Common',
    ASSET_HISTORY_TAB_SHO_TAB = 'AssetHistoryTab:ShowTab',
    // #endregion
    //
    // #region Routing Permissions
    ROUTING_COMMON = 'Routing:Common',
    ROUTING_DELETE_A_ROUTE = 'Routing:DeleteaRoute',
    ROUTING_EDIT_A_ROUTE = 'Routing:EditaRoute',
    ROUTING_SEARCH_FOR_AN_ASSET = 'Routing:SearchforanAsset',
    ROUTING_SHOW_TAB = 'Routing:ShowTab',
    ROUTING_VIEW_ROUTES = 'Routing:ViewRoutes',
    // #endregion
    //
    // #region Import Permissions
    IMPORT_COMMON = 'Import:Common',
    IMPORT_AGOL_FEATURE_SERVER = 'Import:ImportAGOLFeatureServer',
    IMPORT_DB = 'Import:ImportDB',
    IMPORT_MSI_DATA = 'Import:ImportMSIData',
    IMPORT_SCOPE = 'Import:ImportScope',
    IMPORT_SHOW_TAB = 'Import:ShowTab',
    // #endregion
    //
    // #region AI Coding Permissions
    AI_COMMON = 'AICoding:Common',
    AI_EDIT_FIELDS = 'AICoding:EditFields',
    AI_FILTER_COLUMNS = 'AICoding:FilterColumns',
    AI_SHOW_TAB = 'AICoding:ShowTab',
    AI_VIEW_ASSETS = 'AICoding:ViewAssets',

    // #endregion
    //
    // #region Reporting Dashboard Permissions
    REPORTING_COMMON = 'ReportingDashboard:Common',
    REPORTING_FILTERCOLUMNS = 'ReportingDashboard:FilterColumns',
    REPORTING_NAVIGATETOINSPECTIONVIEWER = 'ReportingDashboard:NavigatetoInspectionViewer',
    REPORTING_SHOWTAB = 'ReportingDashboard:ShowTab',
    REPORTING_VIEWASSETS = 'ReportingDashboard:ViewAssets',
    // #endregion

    // # region Can Manage Permissions
    ADMIN_CAN_MANAGE_CREWLEAD = 'AdminCanManage:CrewLead',
    ADMIN_CAN_MANAGE_CREWMEMBER = 'AdminCanManage:CrewMember',
    ADMIN_CAN_MANAGE_OPERATIONSMANAGER = 'AdminCanManage:OperationsManager',
    ADMIN_CAN_MANAGE_PROJECTMANAGER = 'AdminCanManage:ProjectManager',
    ADMIN_CAN_MANAGE_READACCESSUSER = 'AdminCanManage:ReadAccessUser',
    ADMIN_CAN_MANAGE_REPORTINGCONTRACTOR = 'AdminCanManage:ReportingContractor',
    ADMIN_CAN_MANAGE_REPORTINGMANAGER = 'AdminCanManage:ReportingManager',
    ADMIN_CAN_MANAGE_REPORTINGTECH = 'AdminCanManage:ReportingTechnician',
    ADMIN_CAN_MANAGE_SUPERVISOR = 'AdminCanManage:Supervisor',
    ADMIN_CAN_MANAGE_SYSTEMMANAGER = 'AdminCanManage:SystemManager',
    ADMIN_CAN_MANAGE_SYSTEMOWNER = 'AdminCanManage:SystemOwner',
    ADMIN_CAN_MANAGE_WRITEACCESSUSER = 'AdminCanManage:WriteAccessUser',
    // #endregion
}

export interface UserPermissionsState {
    permissions: UserPermission[];
    loading: boolean;
    error: string | undefined;
}
