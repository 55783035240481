import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { MapPagesState } from '@/store/mapPages/types';
import getters from '@/store/mapPages/getters';
import { mutations } from '@/store/mapPages/mutations';
import { actions } from '@/store/mapPages/actions';

const state : MapPagesState = {
  loading: false,
  loadError: undefined,
  data: undefined,
};

const mapPages: Module<MapPagesState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default mapPages;
