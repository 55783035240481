import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { AgolState } from './types';

export const state: AgolState = {
  agolData: undefined,
  agolDataLoading: false,
  agolDataLoadError: undefined,
  agolFeatureServiceData: undefined,
  agolFeatureServiceDataLoading: false,
  agolFeatureServiceDataError: undefined,
  agolLoginData: undefined,
};

const agol: Module<AgolState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default agol;
