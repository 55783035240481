import axios, { AxiosInstance } from 'axios';

export default abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string | undefined) {
    if (!baseURL) { throw new Error('Integrity API url not defined'); }

    this.instance = axios.create({
      baseURL,
    });
  }
}
