
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AgolActions } from './actions';
import { AGOLFeatureService, AgolLoginData } from './types';

const agolModule = namespace('agol');

@Component
export default class AgolStoreMixin extends Vue {
    @agolModule.Action(AgolActions.GET_AGOL_FIELDS) fetchAgolFields;

    @agolModule.Action(AgolActions.GET_AGOL_DATA) fetchAgolFeatures;

    @agolModule.Action(AgolActions.POST_AGOL_DATA) postAgolFeatures;

    @agolModule.Action(AgolActions.GET_AGOL_LOGIN_DATA) getAgolLoginData;

    @agolModule.State('agolFeatureServiceData') agolFeatureServerData: AGOLFeatureService | undefined;

    @agolModule.State('agolFeatureServiceDataLoading') agolFeatureServiceDataLoading: boolean;

    @agolModule.State('agolFeatureServiceDataError') agolFeatureServiceDataError: string | undefined;

    @agolModule.State('agolData') agolData: string | undefined;

    @agolModule.State('agolDataLoading') agolDataLoading: boolean;

    @agolModule.State('agolDataLoadError') agolDataLoadError: string | undefined;

    @agolModule.State('agolLoginData') agolLoginData: AgolLoginData | undefined;
}
