import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { msiPayoutState } from './types';
import { msiPayoutMutations } from './mutations';

// eslint-disable-next-line no-shadow
export enum msiPayoutActions {
  SET_CURRENT_IPD = 'SET_CURRENT_IPD',
}

export const actions: ActionTree<msiPayoutState, RootState> = {
  async [msiPayoutActions.SET_CURRENT_IPD]({ commit }, payload: string): Promise<void> {
    commit(msiPayoutMutations.SET_LOADING_STATE, true);
    commit(msiPayoutMutations.SET_LOAD_ERROR, undefined);

    try {
      commit(msiPayoutMutations.SET_CURRENT_IPD, payload);
    } catch (e) {
      commit(msiPayoutMutations.SET_LOAD_ERROR, (e as Error));
    } finally {
      commit(msiPayoutMutations.SET_LOADING_STATE, false);
    }
  },
};
