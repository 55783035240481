import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { WallLossState } from './types';
import { WallLossMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum WallLossActions {
  GET_WALL_LOSS_DATA = 'GET_WALL_LOSS_DATA',
  CLEAR_WALL_LOSS_DATA = 'CLEAR_WALL_LOSS_DATA',
}

export const actions: ActionTree<WallLossState, RootState> = {
  async [WallLossActions.GET_WALL_LOSS_DATA]({ commit }, payload: string): Promise<void> {
    commit(WallLossMutations.SET_LOADING_STATE, true);
    commit(WallLossMutations.SET_WALL_LOSS_DATA, undefined);
    commit(WallLossMutations.SET_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getWallLossData(payload);

      commit(WallLossMutations.SET_WALL_LOSS_DATA, response.data.result);
    } catch (e) {
      commit(WallLossMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(WallLossMutations.SET_LOADING_STATE, false);
    }
  },

  async [WallLossActions.CLEAR_WALL_LOSS_DATA]({ commit }): Promise<void> {
    commit(WallLossMutations.SET_LOADING_STATE, true);
    commit(WallLossMutations.SET_LOAD_ERROR, undefined);
    try {
      commit(WallLossMutations.SET_WALL_LOSS_DATA, undefined);
    } catch (e) {
      commit(WallLossMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(WallLossMutations.SET_LOADING_STATE, false);
    }
  },
};
