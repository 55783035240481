import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { msiPayoutState } from './types';

const state: msiPayoutState = {
  currentPayout: undefined,
  loading: false,
  loadError: undefined,
};

const msiPayout: Module<msiPayoutState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default msiPayout;
