import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { MPSState } from './types';
import getters from './getters';
import { actions } from './actions';

const state : MPSState = {
  loading: false,
  loadError: undefined,
  macpStandards: undefined,
  pacpStandards: undefined,
  mpsData: undefined,
};

const planning: Module<MPSState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default planning;
