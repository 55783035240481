







































































































































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { BlankMatchFilter, MatchFilter } from '@/types';
import { namespace } from 'vuex-class';
import { uuid } from 'vue-uuid';
import { format } from 'date-fns';
import utils from './utils';
import { DataTableHeader } from '../AssetTable/types';
import getRouteColor from '../Routing/RoutingUtils';
import IntegrityDateSelectTextBox from '../IntegrityDateSelectTextBox/IntegrityDateSelectTextBox.vue';

const userPrefsModule = namespace('userPrefs');

export interface FilterFunction {
  functionVariables: any[];
  // Should be a function, not a string
  // Your function variables will be passed in as they are in function variables
  // We call JSON.stringify on functionVariables so no need to parse
  filterFunction: any;
}

export interface AdditionalFilterFunction {
  updateKey: string;
  filterFunctions: FilterFunction[];
}

export interface FillFunction {
  headerValue: string;
  functionVariables: any[];
  // Should be a function, not a string
  // Your function variables will be passed in as they are in function variables
  // We call JSON.stringify on functionVariables so no need to parse
  fillFunction: any;
}

export interface FillFunctionContainer {
  updateKey: string;
  fillFunctions: FillFunction[];
}

export function processDate(dateString: string): string {
  return !dateString ? null : dateString.substring(0, 10);
}

@Component({
  components: {
    IntegrityDateSelectTextBox,
  },
})
export default class IntegrityTable extends Vue {
  // #region Store Modules
  @userPrefsModule.State('displayImperial') displayImperial: boolean;
  // #endregion

  // #Props and PropSyncs
  /**
   * @description The data to put into the table
   */
  @PropSync('data') synchedData: unknown[];

  /**
   * @description The headers of the table
   */
  @PropSync('headers') synchedHeaders: DataTableHeader[];

  /**
   * @description An array of match filters. The indexes should match your header object.
   */
  @PropSync('matchFilters') synchedMatchFilters: MatchFilter[];

  /**
   * @description An object of blank match filters. The keys are your heaver values. The value is
   * if to include blank values in the filter.
   */
  @PropSync('blankMatchFilters', { default: () => ({}) }) synchedBlankMatchFilters: BlankMatchFilter;

  /**
   * @description The header value representing the primary key of the table. Defaults to 'guid'.
   * !IMPORTANT! Table will die if primary key is duplicated.
   */
  @Prop({ default: 'guid' }) readonly tableUniqueKey: string;

  /**
   * @description Should the table display a spinner
   */
  @Prop() readonly loading: boolean;

  /**
   * @description The id to give the table. Defaults to a uuid
   */
  @Prop({ default: `default-id-${uuid.v4()}` }) readonly tableID: string;

  /**
   * @description The header value the table should should start storted by
   */
  @Prop() readonly sortBy: string;

  /**
   * @description How many items to display before the skeleton scroll kicks in
   */
  @Prop({ default: -1 }) readonly itemsPerPage: number;

  /**
   * @description Display selection boxes
   */
  @Prop({ default: false }) readonly showSelect: boolean;

  /**
   * @description How many can be selected. -1 is no limit
   */
  @Prop({ default: -1 }) readonly selectLimit: number;

  /**
   * @description An array of selected items. Items will populate in the provided array.
   */
  @PropSync('selectedItems') synchedSelectedItems: unknown[];

  /**
   * @description True to show the expand arrow and expanded content
   * Use expanded-content template slot
   */
  @Prop() readonly showExpand: boolean;

  /**
   * @description Only expand one column at a time
   */
  @Prop({ default: true }) readonly singleExpand: boolean;

  /**
   * @description An array of expanded items. Items will populate in the provided array.
   */
  @PropSync('expanded') synchedExpanded: unknown[];

  /**
   * @description Can the table be edited
   */
  @Prop() readonly canEdit: boolean;

  /**
   * @description Disable the edit action
   */
  @Prop({ default: false }) disableActionEdit

  /**
   * @description Disable the delete action
   */
  @Prop({ default: false }) disableActionDelete

  /**
   * @description Disable the info button press action
   */
  @Prop({ default: false }) disableActionInfo

  /**
   * @description The search value to filter by
   */
  @Prop({ default: '' }) readonly search: string | undefined;

  /**
   * @description Any other filtering you want the worker to do.
   * These need to be a javascript friendly string, no typescript.
   * function should look like this, taking in the table data item
   * and returning a boolean
   * function <name>(item) {return boolean}
   * The update key must change every time to update
   *
   * USES AN EVAL SO USE CAUTION
   * THIS MUST BE A COMUTED PROPERTY WITH NO DEPENDENCIES OR A READONLY VALUE.
   */
  @Prop({
    default: () => ({
      updateKey: uuid.v4(),
      filterFunctions: [],
    }),
  }) additionalFilterFunctions: AdditionalFilterFunction;

  /**
   * @description Any data filling you want the worker to do.
   * These need to be a javascript friendly string, no typescript.
   * function should look like this, taking in the table data item
   * and returning a the value to fill
   * function <name>(item) {return any}
   * The update key must change every time to update
   * This will do a silent update, meaning the table will not rerender
   *
   * USES AN EVAL SO USE CAUTION
   * THIS MUST BE A COMUTED PROPERTY WITH NO DEPENDENCIES OR A READONLY VALUE.
   */
  @Prop({
    default: () => ({
      updateKey: uuid.v4(),
      fillFunctions: [],
    }),
  }) dataFillFunctions: FillFunctionContainer;

  /**
   * @description Props to use on the footer of the table
   */
  @Prop() readonly footerProps: any;

  /**
   * @description Hide the default footer for the table to set a custom one or turn off
   */
  @Prop({ default: false }) readonly hideDefaultFooter: boolean;

  /**
   * @description The table height
   */
  @PropSync('height') synchedHeight: number | string | null;

  /**
   * @description Each index is a different css rule
   * For example ['td { background:black; color: white;}', 'td.sticky  { color:red;}']
   */
  @Prop() readonly cssRules: string[];

  /**
   * @description The type of the table. Currently supported value: 'workOrders'
   */
  @Prop({ default: '' }) readonly tableType: string;

  /**
   * @description Override the filter values set by the table.
   * This will stop the table from generating it's own filter values.
   */
  @Prop() filterValues: any;
  // #endregion

  get isJest(): boolean {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return true;
    }
    return false;
  }

  headersOpen: string[] = [];

  filterVirtualScrollItemHeight = 40;

  // This is a const with the max height of the menu
  maxFilterVirtualScrollHeight = 346;

  localSearch = '';

  sortByUpdated: string = undefined;

  sortByDirectionOverride = false;

  tableDataDisplayed = [];

  tableDataDisplayedLength = 0;

  currentDatasetTransactionId = '';

  workerFriendlyTableDataIndexes: {
    finalArrayIndex: number,
    filledValues: any,
  }[] = [];

  currentTableDataTotalLength = 0;

  isWorkerLoading = false;

  mostRecentRecieveDataFromWorkerUUID = uuid.v4();

  mostRecentWorker: Worker | undefined = undefined;

  hasInitialLoadHappened = false;

  pageIndex = 0;

  pageSelectDropDownOptions = [];

  pageSelectDropDownModel: string = undefined;

  rowsPerPageSelectDropDownModel: string = undefined;

  itemsPerSkeletonLoad = 50;

  maxSkeletonLoadItemsOnScreen = 100;

  itemStartIndex = 0;

  itemEndIndex = 0;

  overrideFilterValues = {};

  hasFilterChanges = true;

  matchFilterMethod = {
    number: ['>', '>=', '<=', '<', '=', '!='],
    string: ['Exactly', 'Includes', 'Does Not Include'],
    date: ['Before', 'After', 'Between'],
    color: [],
  }

  tableHeight = 0;

  isSkeletonLoaderLoading = false;

  skeletonRowSize = 1;

  get editableHeaders(): DataTableHeader[] {
    return this.canEdit ? this.synchedHeaders.filter(
      (header) => header.editable,
    ) : [];
  }

  get editableHeadersOptions(): DataTableHeader[] {
    return this.canEdit ? this.synchedHeaders.filter(
      (header) => header.editable && header.options,
    ) : [];
  }

  get selectionLimitReached(): boolean {
    return this.selectLimit !== -1 && this.synchedSelectedItems.length >= this.selectLimit;
  }

  /**
   * @description Can the skeleton scroll load more
   */
  get canLoadMore(): boolean {
    const tableDataLength = this.tableDataDisplayedLength;
    const testEndIndex = tableDataLength + this.itemsPerSkeletonLoad;
    return tableDataLength !== this.getMaxEndIndexOfPage(testEndIndex);
  }

  /**
   * @description Can the skeleton scroll load less
   */
  get canLoadLess(): boolean {
    const tableDataLength = this.tableDataDisplayedLength;
    return tableDataLength > this.maxSkeletonLoadItemsOnScreen;
  }

  /**
   * @description Json stringified prop table data
   */
  get synchedDataString(): string {
    return JSON.stringify(this.synchedData);
  }

  /**
   * @description Is a prop or worker loading
   */
  get isTableLoading(): boolean {
    return this.loading || this.isWorkerLoading;
  }

  /**
   * @description Should display style on the table be
  *  none or visible for loading purposes
   */
  get displayTableStyle(): string {
    return this.isTableLoading ? 'display: none;' : '';
  }

  /**
   * @description The filter values applied on the ui.
   * Override with the prop
   */
  get tableFilterValues(): any {
    if (this.filterValues != null) {
      return this.filterValues;
    }
    return this.overrideFilterValues;
  }

  /**
   * @description The search applied on the ui.
   * Override with the prop
   */
  get tableSearch(): string {
    return this.localSearch != null && this.localSearch !== '' ? this.localSearch : this.search;
  }

  /**
   * @description The length of the table data displayed on the ui
   */
  get totalCurrentDataLength(): number {
    const length = this.currentTableDataTotalLength;
    return length != null ? length : 0;
  }

  /**
   * @description Get a default version of the footer props
   * or an edited version with the "All" options in the items per page.
   */
  get footerPropsEdited(): any {
    if (this.footerProps == null) {
      return {
        'items-per-page-options': this.footerItemsPerPageOptions,
      };
    }
    const footerPropsDupe = { ...this.footerProps };
    this.footerProps['items-per-page-options'] = this.footerItemsPerPageOptions;
    return footerPropsDupe;
  }

  /**
   * @description Get a default version of the items per page options with the "All" option
   */
  get footerItemsPerPageOptions(): Array<string|number> {
    let returnValue = [];
    if (
      this.footerProps != null
      && this.footerProps['items-per-page-options'] != null
    ) {
      if (this.footerProps['items-per-page-options'].findIndex((value) => value === -1) === -1) {
        returnValue.push(-1);
      }
      returnValue = returnValue.concat(this.footerProps['items-per-page-options']);
    } else {
      returnValue.push(-1);
    }
    return returnValue;
  }

  /**
   * @description The options displayed in the rows per page drop down
   */
  get rowsPerPageSelectDropDownOptions(): string[] {
    const returnValue = [];
    if (this.footerItemsPerPageOptions != null) {
      this.footerItemsPerPageOptions.forEach((value) => {
        const valueString = value.toString();
        const parsedNumber = parseInt(valueString, 10);
        if (valueString === '-1') {
          returnValue.push('All');
        } else if (!Number.isNaN(parsedNumber)) {
          returnValue.push(this.formatNumber(parsedNumber));
        }
      });
    }
    if (returnValue.length > 0) {
      let rowsPerPageProp = this.itemsPerPage.toString();
      if (rowsPerPageProp === '-1') {
        rowsPerPageProp = 'All';
      }
      const foundRowsPerPageProp = returnValue.find((value) => value === rowsPerPageProp);
      if (this.rowsPerPageSelectDropDownModel == null) {
        // eslint-disable-next-line prefer-destructuring
        this.rowsPerPageSelectDropDownModel = foundRowsPerPageProp != null
          ? foundRowsPerPageProp : returnValue[0];
      }
    }
    return returnValue;
  }

  get dataFillFunctionsString(): string | undefined {
    let returnValue;
    if (this.dataFillFunctions?.fillFunctions != null) {
      const processedDataFunctions = this.dataFillFunctions
        .fillFunctions.map((filter: FillFunction): FillFunction => ({
          headerValue: filter.headerValue,
          functionVariables: filter.functionVariables,
          fillFunction: filter.fillFunction.toString(),
        }));
      returnValue = JSON.stringify(processedDataFunctions);
    }
    return returnValue;
  }

  get additionalFilterFunctionsString(): string | undefined {
    let returnValue;
    if (this.additionalFilterFunctions?.filterFunctions != null) {
      const processedFilterFunctions = this.additionalFilterFunctions
        .filterFunctions.map((filter: FilterFunction): FilterFunction => ({
          functionVariables: filter.functionVariables,
          filterFunction: filter.filterFunction.toString(),
        }));
      returnValue = JSON.stringify(processedFilterFunctions);
    }
    return returnValue;
  }

  @Watch('synchedMatchFilters', { deep: true })
  onSynchedMatchFiltersChanged() {
    if (!this.hasFilterChanges) {
      // We track all filter value changes in other functions
      // This is specifically to update from external components
      // So we want to update only when the filter values
      // are not changed.
      // Otherwise, filter menu close will take care of it later
      this.onAdditionalFilterChange(true);
    }
  }

  @Watch('dataFillFunctions.updateKey', { deep: true })
  onDataFillFunctionsChange(): void {
    this.onFillDataUpdate();
  }

  @Watch('additionalFilterFunctions.updateKey', { deep: true })
  onAdditionalFilterFunctionChange(): void {
    this.onAdditionalFilterChange(false);
  }

  @Watch('tableSearch')
  onSearchChange() {
    this.onAdditionalFilterChange(true);
  }

  @Watch('synchedData', { deep: true })
  /**
   * @description Watch for a change of the main data from props and rerender the filter buttons.
   * Very important for long load time tables to update which buttons need to rerender.
   */
  onSynchedDataChange(): void {
    this.clearAllMatchFilters(true);
    this.reorganizeTable();
  }

  created(): void {
    window.addEventListener('resize', this.onResize);
  }

  mounted(): void {
    if (this.isJest) {
      return;
    }
    this.onResize();
    window.addEventListener('click', this.onClickCheckOpenFilterMenus, true);
    if (this.cssRules && this.cssRules.length > 0) {
      const sheet = new CSSStyleSheet();
      this.cssRules.forEach((style) => (sheet).insertRule(`#${this.tableID} ${style}`));
      (document as any).adoptedStyleSheets = [...(document as any).adoptedStyleSheets, sheet];
    }
    this.synchedBlankMatchFilters = {};

    if (!this.synchedMatchFilters) {
      return;
    }

    this.synchedMatchFilters.forEach((matchFilter) => {
      this.synchedBlankMatchFilters[matchFilter.header] = false;
    });
    this.clearAllMatchFilters();
    this.startWorkerOrganization();
    this.goToPage(this.pageIndex);
  }

  destroyed(): void {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('click', this.onClickCheckOpenFilterMenus, true);
    if (this.hasInitialLoadHappened && this.mostRecentWorker) {
      this.mostRecentWorker.postMessage({
        type: 'terminate',
      });
    }
  }

  /**
   * @description Watch and track filter menus for the table to see when they open and close
   * to accurately filter
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onClickCheckOpenFilterMenus(_e: any) {
    setTimeout(async () => {
      await this.$nextTick();
      let isClosed = false;
      const newHeadersOpenList = [];
      for (let i = 0; i < this.synchedHeaders.length; i += 1) {
        const headersOpenIndex = this.headersOpen
          .findIndex((h) => h === this.synchedHeaders[i].value);
        const filterMenu = document.getElementById(`menu-card-${i}`)?.parentElement;

        if (filterMenu && Array.from(filterMenu.classList).find((v) => v === 'menuable__content__active')) {
          this.synchedHeaders[i].open = true;
          newHeadersOpenList.push(this.synchedHeaders[i].value);
        } else if (headersOpenIndex >= 0) {
          isClosed = true;
          this.synchedHeaders[i].open = false;
        }
      }
      if (isClosed) {
        this.onFilterValueMenuClose(false);
      }
      this.headersOpen = newHeadersOpenList;
    }, 50);
  }

  /**
   * @description Get the height of the virtual scroll height.
   * Is maxFilterVirtualScrollHeight unless there is a lesser height
   * for the items displayed.
   */
  getFilterVirtualScrollHeight(itemsLength: number, headerValue: string): number {
    let totalItemsLength = itemsLength;
    if (this.doesHeaderHaveBlankOption(headerValue)) {
      totalItemsLength += 1;
    }
    const totalItemHeight = totalItemsLength * this.filterVirtualScrollItemHeight;
    return totalItemHeight > this.maxFilterVirtualScrollHeight
      ? this.maxFilterVirtualScrollHeight : totalItemHeight;
  }

  /**
   * @description Get the items to display in the filter menu
   */
  getFilterVirtualScrollItems(items: any[], headerValue: string): any[] {
    return this.doesHeaderHaveBlankOption(headerValue)
      ? ['Table-Default-Blank'].concat(items) : items;
  }

  /**
   * @description How many rows should be displayed on screen
   * based off the selected option.
   */
  getRowsPerPage(): number {
    let returnValue = this.itemsPerPage;
    const removedFormatting = this.rowsPerPageSelectDropDownModel != null
      ? this.rowsPerPageSelectDropDownModel.replaceAll(',', '') : this.rowsPerPageSelectDropDownModel;
    const rowsPerPageModelNumber = this.rowsPerPageSelectDropDownModel === 'All'
      ? this.currentTableDataTotalLength : parseInt(removedFormatting, 10);

    if (this.itemsPerPage !== rowsPerPageModelNumber
      && !Number.isNaN(rowsPerPageModelNumber)) {
      const allTableDataLength = this.currentTableDataTotalLength != null
        ? this.currentTableDataTotalLength : 0;
      returnValue = rowsPerPageModelNumber === -1
        ? allTableDataLength : rowsPerPageModelNumber;
    }
    return returnValue;
  }

  /**
   * @description Handler for rows per page change
   */
  handlRowsPerPageSelectDropDownChange(selectionText: string): void {
    this.rowsPerPageSelectDropDownModel = selectionText;
    this.updatePageSelectDropDownOptions();
    this.goToPage(0);
  }

  /**
   * @description Update function for the page select drop down options
   */
  updatePageSelectDropDownOptions(): void {
    const returnValue = [];

    for (let i = 0; i < Math.ceil(
      this.totalCurrentDataLength / this.getRowsPerPage(),
    ); i += 1) {
      const startIndex = i * this.getRowsPerPage();
      const endIndex = Math.min(startIndex + this.getRowsPerPage(),
        this.totalCurrentDataLength);
      returnValue.push(`${this.formatNumber(startIndex + 1)}-${this.formatNumber(endIndex)}`);
    }
    if (returnValue.length === 0) {
      returnValue.push('0');
    }
    this.pageSelectDropDownOptions = returnValue;
    // eslint-disable-next-line prefer-destructuring
    this.pageSelectDropDownModel = returnValue[0];
  }

  /**
   * @description Handler for page change
   */
  handlePageSelectDropDownChange(selectionText: string): void {
    let foundIndex = this.pageSelectDropDownOptions?.findIndex(
      (value) => value === selectionText,
    );
    if (!foundIndex) {
      foundIndex = 0;
    }
    this.goToPage(foundIndex, false);
  }

  /**
   * @description Get the start index in the data based off the page index
   * @param pageIndex {number} the page to start on
   */
  getItemStartIndex(pageIndex: number) {
    return pageIndex * this.getRowsPerPage();
  }

  /**
   * @description Get the start index in the data based off the start index
   * @param startIndex {number} the start of the index in the dataset requested
   */
  getItemEndIndex(startIndex: number): number {
    const returnValue = this.getRowsPerPage() + startIndex;
    if (returnValue > this.currentTableDataTotalLength) {
      return this.currentTableDataTotalLength;
    }
    return returnValue;
  }

  /**
   * @description Is the page index 0
   */
  getIsUserOnFirstPage(): boolean {
    return this.pageIndex === 0;
  }

  /**
   * @description Is the page index the last index
   */
  getIsUserOnLastPage(): boolean {
    return this.itemStartIndex + this.getRowsPerPage()
      >= this.currentTableDataTotalLength;
  }

  /**
   * @description Give the end index you want to try to use and return the end index you can use
   * @returns {number} Either the same end index or changes if it
   * exceeds the data set size or the rows per page
   */
  getMaxEndIndexOfPage(testEndIndex: number): number {
    const tableDataLength = this.tableDataDisplayedLength;
    const rowsPerPage = this.getRowsPerPage();
    const isLessThanTableData = this.itemStartIndex + tableDataLength + this.itemsPerSkeletonLoad
      < this.currentTableDataTotalLength;
    if (!isLessThanTableData) {
      return this.currentTableDataTotalLength - this.itemStartIndex;
    }
    const isLessThanRowCount = testEndIndex < rowsPerPage;
    if (!isLessThanRowCount) {
      return rowsPerPage;
    }
    return testEndIndex;
  }

  /**
   * @description Given an item start and end, return back the slice of data and
   * set the table data display length
   * @param itemStartIndex {number} The start index in the data set. If negative, will be 0
   * @param itemEndIndex {number} The start index in the data set. Will cap at table data length.
   */
  async updateTableData(
    itemStartIndex: number,
    itemEndIndex: number,
    isNewDataSet = false,
  ): Promise<void> {
    if (!this.hasInitialLoadHappened || this.synchedData == null || this.synchedData.length === 0) {
      return;
    }
    const itemStartIndexOverride = itemStartIndex < 0
      ? 0
      : itemStartIndex;
    const itemEndIndexOverride = itemEndIndex
      > this.currentTableDataTotalLength
      ? this.currentTableDataTotalLength - itemEndIndex
      : itemEndIndex;
    this.mostRecentRecieveDataFromWorkerUUID = uuid.v4();
    const worker = this.getBaseWorker();
    worker.postMessage({
      type: 'getSlice',
      startIndex: itemStartIndexOverride,
      endIndex: itemEndIndexOverride,
      pageStartIndex: this.getItemStartIndex(this.pageIndex),
      workerId: this.mostRecentRecieveDataFromWorkerUUID,
      isNewDataSet,
    });
  }

  async onRecieveDataSlice(
    itemStartIndex: number,
    itemEndIndex: number,
    e: any = undefined,
  ) {
    const pageStartIndex = this.getItemStartIndex(this.pageIndex);
    // eslint-disable-next-line eqeqeq
    if (e.data?.workerId != this.mostRecentRecieveDataFromWorkerUUID
    ) {
      return;
    }
    if (
      e.data?.data != null
    ) {
      const decoder = new TextDecoder();
      this.workerFriendlyTableDataIndexes = [];
      this.workerFriendlyTableDataIndexes = this.workerFriendlyTableDataIndexes
        .concat(JSON.parse(decoder.decode(e.data.data)));
    }
    const newTableDataLength = e.data.dataSliceEndIndex - pageStartIndex;
    this.tableDataDisplayedLength = newTableDataLength;
    this.tableDataDisplayed = null;
    this.tableDataDisplayed = this.workerFriendlyTableDataIndexes.slice(
      0,
      this.tableDataDisplayedLength,
    ).map((index) => (
      {
        ...(this.synchedData as any[])[index.finalArrayIndex],
        ...(index.filledValues != null ? index.filledValues : {}),
      }));
    await this.$nextTick();
    await this.$nextTick();
    this.isWorkerLoading = false;
    if (e.data?.isNewDataSet) {
      await this.$nextTick();
      this.scrollToRow(-1, true);
    }
  }

  /**
   * @description When a filter changes, call this to update the filter data
   * @param isFilterUpdate {boolean = false} Set to true to use the worker cached dataset
   * (True is faster but may yeild inconsistent results
   * if the dataset was already limited before calling)
   */
  async onAdditionalFilterChange(isFilterUpdate = false, updateData = false): Promise<void> {
    if (!this.hasInitialLoadHappened || this.synchedData == null || this.synchedData.length === 0) {
      return;
    }
    if (this.mostRecentWorker != null) {
      this.isWorkerLoading = true;
      if (!isFilterUpdate) {
        this.clearAllMatchFilters();
      }
      const encoder = new TextEncoder();
      const synchedHeaders = encoder.encode(JSON.stringify(this.synchedHeaders)).buffer;
      const synchedMatchFilters = encoder.encode(JSON.stringify(this.synchedMatchFilters)).buffer;
      const synchedBlankMatchFilters = encoder
        .encode(JSON.stringify(this.synchedBlankMatchFilters)).buffer;
      const postObject = {
        type: 'update',
        data: undefined,
        isFilterUpdate,
        sortBy: this.getSortByOverride(),
        sortByDirection: this.sortByDirectionOverride,
        additionalFilters: this.additionalFilterFunctionsString,
        dataFillFunctionsString: this.dataFillFunctionsString,
        search: this.tableSearch != null ? this.tableSearch : '',
        synchedHeaders,
        synchedMatchFilters,
        synchedBlankMatchFilters,
      };
      if (updateData) {
        const synchedData = encoder.encode(JSON.stringify(this.synchedData)).buffer;
        postObject.isFilterUpdate = true;
        postObject.data = synchedData;
        this.mostRecentWorker.postMessage(postObject, [
          synchedHeaders,
          synchedMatchFilters,
          synchedBlankMatchFilters,
          synchedData,
        ]);
      } else {
        this.mostRecentWorker.postMessage(postObject, [
          synchedHeaders,
          synchedMatchFilters,
          synchedBlankMatchFilters,
        ]);
      }
    }
  }

  async onFillDataUpdate(): Promise<void> {
    if (this.hasInitialLoadHappened && this.mostRecentWorker != null) {
      const postObject = {
        type: 'updateFillData',
        dataFillFunctionsString: this.dataFillFunctionsString,
      };
      this.mostRecentWorker.postMessage(postObject);
    }
  }

  /**
   * @description Fully reorganizes the table and resets the cached dataset
   */
  reorganizeTable(): void {
    if (this.isJest) {
      return;
    }
    this.startWorkerOrganization();
    this.resetDataSetPages();
  }

  /**
   * @description Reset everything back to the beginning. Page 0, scroll 0, etc.
   */
  async resetDataSetPages(updateTableData = true): Promise<void> {
    this.synchedExpanded = [];
    this.toggleSelectAll({ value: false });
    this.goToPage(0, true, updateTableData);
    this.scrollToRow(-1, true);
    // await this.$nextTick;
    // this.scrollToRow(-1, true);
    this.onResize();
  }

  /**
   * @description gets the row height from the first table row to the speciied index
   * @param index {number} the index of the row to get the height from
   * @param includeIndexRow {boolean} include the height of the row with the index
   * @return {number} returns the total height of the range of rows
   */
  getTotalRowHeightToIndex(index: number, includeIndexRow = false): number {
    // Check to see if our table exists
    const tableRef = (this.$refs['integrityTable'] as any);
    if (!tableRef) {
      return 0;
    }
    const updatedIndex = includeIndexRow ? index + 1 : index;
    // A list of all the rows
    const rows = tableRef.$el.querySelectorAll('.v-data-table__wrapper > table > tbody > tr');
    const rowHeightList = (Array.from(rows) as HTMLElement[])
      .filter((r) => r?.clientHeight != null)
      .map((r) => r.clientHeight);
    let returnHeight = 0;

    for (let i = 0; (i < updatedIndex && i < rowHeightList.length); i += 1) {
      returnHeight += rowHeightList[i];
    }

    return returnHeight;
  }

  /**
   * @description gets the height that is encountered the most
   * @return {number} returns the height that is encountered the most
   */
  getModeRowHeight(): number {
    // Check to see if our table exists
    const tableRef = (this.$refs['integrityTable'] as any);
    if (!tableRef) {
      return 0;
    }
    // A list of all the rows
    const rows = tableRef.$el.querySelectorAll('.v-data-table__wrapper > table > tbody > tr');
    const rowHeightList = (Array.from(rows) as HTMLElement[])
      .filter((r) => r?.clientHeight != null)
      .map((r) => r.clientHeight);
    const counts: Map<number, number> = new Map();
    rowHeightList.forEach((height) => {
      if (!counts.has(height)) {
        counts.set(height, 0);
      }
      counts.set(height, counts.get(height) + 1);
    });
    const amounts = Array.from(counts.values());
    const greatestAmountIndex = amounts.indexOf(Math.max(...amounts));
    return Array.from(counts.keys())[greatestAmountIndex];
  }

  /**
   * @description Get the total height of all the rows in the table
   */
  getTotalRowHeight(): number {
    // Check to see if our table exists
    const tableRef = (this.$refs['integrityTable'] as any);
    if (!tableRef) {
      return 0;
    }
    // A list of all the rows
    const rows = tableRef.$el.querySelectorAll('.v-data-table__wrapper > table > tbody > tr');
    let rowHeight = 0;
    (Array.from(rows) as HTMLElement[]).forEach((r) => {
      if (r?.clientHeight != null) {
        rowHeight += r.clientHeight;
      }
    });
    return rowHeight;
  }

  /**
   * @description Scroll to a row in the table
   * @param index {number} The row index to scroll to
   * @param isIndexTopOfTable {boolean} Should the index be the top of the table or bottom
   */
  async scrollToRow(index: number, isIndexTopOfTable: boolean) {
    let storedScrollXPos = 0;
    // Check to see if our table exists
    let tableRef = (this.$refs['integrityTable'] as any);
    if (tableRef && index > -1) {
      // The view box itself
      const tableContainer = tableRef.$el.querySelector('.v-data-table__wrapper');
      if (tableContainer) {
        const currentScrollX = tableContainer.scrollLeft;
        storedScrollXPos = currentScrollX != null ? currentScrollX : 0;
      }
    }
    // Wait until the table is updated to be able to scroll to the right row
    // (More important if amount of rows is changing)
    // We need to recopy the html objects on the dom since it's changed
    await this.$nextTick();
    // Check to see if our table exists
    tableRef = (this.$refs['integrityTable'] as any);
    if (!tableRef) {
      return;
    }
    // The view box itself
    const tableContainer = tableRef.$el.querySelector('.v-data-table__wrapper');
    // What contains all the rows
    const table = tableRef.$el.querySelector('.v-data-table__wrapper > table');
    // The header row
    const headers = table.querySelector('thead');
    // A list of all the rows
    const rows = tableRef.$el.querySelectorAll('.v-data-table__wrapper > table > tbody > tr');
    let rowHeight = 0;
    if (rows.length > 2) {
      // Get row height into memory to adjust scroll bar
      // We check for > 2 here because the first and last rows are always rendered...
      // They are the skeleton rows.
      rowHeight = rows[1].clientHeight;
    }
    const headersHeight = this.itemStartIndex > 0 ? (headers as HTMLElement).clientHeight : 0;
    // The base scroll to value for the scroll bar.
    // The scroll bar works off table size dispite being on tableContainer
    let scrollToValue = (rowHeight * index) + headersHeight;
    if (!isIndexTopOfTable) {
      scrollToValue = scrollToValue - tableContainer.clientHeight + rowHeight;
    }
    tableContainer.scrollTo(0, scrollToValue);
    tableContainer.scrollLeft = storedScrollXPos;
  }

  /**
   * @description Get the code string to call the additional functions in the prop
   */
  async goToPage(pageIndex: number, updatePageDropdown = true, updateTableData = true) {
    this.isWorkerLoading = true;
    this.itemStartIndex = this.getItemStartIndex(pageIndex);
    this.itemEndIndex = this.itemStartIndex + this.itemsPerSkeletonLoad;
    this.pageIndex = pageIndex;
    if (updatePageDropdown && pageIndex < this.pageSelectDropDownOptions?.length) {
      this.pageSelectDropDownModel = this.pageSelectDropDownOptions[pageIndex];
    }
    if (updateTableData) {
      this.updateTableData(this.itemStartIndex, this.itemEndIndex, true);
    }
    this.scrollToRow(0, true);
  }

  /**
   * @description Load the next data set when the load more skeleton scroll is on page
   */
  async loadMore(entries, observer, isIntersecting) {
    // only change the indexes when the bottom skeleton loader is on screen
    if (isIntersecting && this.canLoadMore && !this.isSkeletonLoaderLoading) {
      this.isSkeletonLoaderLoading = true;
      const tableDataLength = this.tableDataDisplayedLength;
      const testEndIndex = tableDataLength + this.itemsPerSkeletonLoad;
      const endIndex = this.getMaxEndIndexOfPage(testEndIndex);
      const dataSetEndIndex = this.itemStartIndex + endIndex;
      await this.updateTableData(dataSetEndIndex - this.maxSkeletonLoadItemsOnScreen,
        dataSetEndIndex);
      await this.$nextTick();
      if (endIndex > this.maxSkeletonLoadItemsOnScreen) {
        this.scrollToRow(this.maxSkeletonLoadItemsOnScreen
        - this.itemsPerSkeletonLoad - (testEndIndex - endIndex) + 1, false);
      }
      this.isSkeletonLoaderLoading = false;
      this.onResize();
    }
  }

  /**
   * @description Load The previous data set when the load less skeleton scroll is on page
   */
  async loadLess(entries, observer, isIntersecting) {
    // only change the indexes when the top skeleton loader is on screen
    if (isIntersecting && this.canLoadLess && !this.isSkeletonLoaderLoading) {
      this.isSkeletonLoaderLoading = true;
      const tableDataLength = this.tableDataDisplayedLength;
      const testEndIndex = tableDataLength - this.itemsPerSkeletonLoad;
      const endIndex = testEndIndex < this.maxSkeletonLoadItemsOnScreen
        ? this.maxSkeletonLoadItemsOnScreen : testEndIndex;
      const dataSetEndIndex = this.itemStartIndex + endIndex;
      await this.updateTableData(dataSetEndIndex - this.maxSkeletonLoadItemsOnScreen,
        dataSetEndIndex);
      await this.$nextTick();
      let rowToScrollTo = 0;
      if (endIndex === this.maxSkeletonLoadItemsOnScreen) {
        rowToScrollTo = this.itemsPerSkeletonLoad - (endIndex - testEndIndex) + 1;
      } else {
        rowToScrollTo = this.itemsPerSkeletonLoad + 1;
      }
      this.scrollToRow(rowToScrollTo, true);
      this.isSkeletonLoaderLoading = false;
      this.onResize();
    }
  }

  /**
   * @description Getter for the skeleton scroll class
   */
  getSkeletonScrollDisplayClass(display: boolean): string {
    return !display ? 'displayHidden' : '';
  }

  /**
   * @description Format filter items for the filter menu
   */
  formatFilterValue(item: any, header: string): string {
    let retVal = '';

    if (item !== null && item !== undefined) {
      if (header.includes('date')) {
        retVal = this.getFormattedDate(new Date(Date.parse(item)));
      } else if ((header === 'score' || header === 'grade') && item === -1) {
        retVal = 'Unscored';
      } else {
        retVal = item.toString();
      }
    }

    return retVal;
  }

  /**
   * @description Handler for when a filter menu closes
   */
  onFilterValueMenuClose(input, isForceClose = false) {
    const isClosing = !input;
    if (isClosing && this.hasFilterChanges) {
      this.onAdditionalFilterChange(!isForceClose);
      this.hasFilterChanges = false;
    }
  }

  /**
   * @description Handler for when the apply filter button is clicked and update table
   */
  applyFilter(header: string): void {
    if (!this.synchedMatchFilters) {
      return;
    }
    const filter = this.synchedMatchFilters.find((f) => f.header === header);
    filter.value = filter.tempValue;
    const foundHeaderIndex = this.synchedHeaders.findIndex((h) => h.value === header);
    if (foundHeaderIndex !== -1) {
      this.closeMenu((this.$refs[`filter-menu-${foundHeaderIndex}`] as any[]));
      this.onFilterValueMenuClose(false);
    }
    if (this.hasFilterChanges) this.resetDataSetPages();
  }

  /**
   * @description Handler for clear button to clear all match filters and update table
   */
  clearAllMatchFilters(preventFilter = false): void{
    if (!this.synchedMatchFilters) {
      return;
    }
    this.synchedMatchFilters.forEach((filter) => {
      this.clearMatchFilter(filter.header);
    });
    this.onFilterValueMenuClose(preventFilter, true);
    if (!preventFilter && this.hasFilterChanges) this.resetDataSetPages();
  }

  /**
   * @description Handler for clear one match filter and update table
   */
  clearMatchFilter(headerValue: string): void {
    if (!this.synchedMatchFilters) {
      return;
    }
    const filter = this.synchedMatchFilters.find((f) => f.header === headerValue);

    filter.value = '';
    filter.method = '';
    filter.tempValue = '';
    filter.options = [];

    this.synchedBlankMatchFilters[headerValue] = false;
    const foundHeaderIndex = this.synchedHeaders.findIndex((h) => h.value === headerValue);
    if (foundHeaderIndex !== -1) {
      this.closeMenu((this.$refs[`filter-menu-${foundHeaderIndex}`] as any[]));
      this.onFilterValueMenuClose(false);
    }
    if (this.hasFilterChanges) this.resetDataSetPages();
  }

  /**
   * @description Get the filter icon for the header value
   */
  getFilterIcon(header: string): string {
    if (!this.synchedMatchFilters) {
      return '';
    }
    const filter = this.synchedMatchFilters.find((f) => f.header === header);

    return filter !== undefined && (filter.value !== '' || filter.options.length > 0 || this.synchedBlankMatchFilters[header])
      ? 'mdi-filter'
      : 'mdi-filter-outline';
  }

  /**
   * @description Get the method the match filter should be using
   */
  getMatchFilterMethod(headerValue: string): string[] {
    if (!this.synchedMatchFilters) {
      return this.matchFilterMethod.string;
    }
    const filter = this.synchedMatchFilters.find((f) => f.header === headerValue);
    switch (filter.type) {
      case 'number':
        return this.matchFilterMethod.number;
      case 'color':
        return this.matchFilterMethod.color;
      case 'date':
        return this.matchFilterMethod.date;
      case 'string':
      default:
        return this.matchFilterMethod.string;
    }
  }

  isUpdated(headerValue: string): boolean {
    if (!this.synchedMatchFilters) {
      return false;
    }
    const filter = this.synchedMatchFilters.find((f) => f.header === headerValue);

    return filter !== null
      ? filter.value !== filter.tempValue
      : false;
  }

  /**
   * @description Get either what the sort by originally was or
   * if it was updated, what the table says it should be
   */
  getSortByOverride(): string | undefined {
    const originalSortBy = this.sortBy != null ? this.sortBy : '';
    return this.sortByUpdated != null ? this.sortByUpdated : originalSortBy;
  }

  /**
   * @description Handler for if the sort by header value is updated
   */
  updateSortBy(sortColumnHeaderDisplayString: string): void {
    this.sortByUpdated = sortColumnHeaderDisplayString != null ? sortColumnHeaderDisplayString : '';
    this.sortByDirectionOverride = false;
    this.resetDataSetPages(false);
    this.onAdditionalFilterChange(true);
  }

  /**
   * @description Handler for if the sort by direction was updated
   */
  updateSortByDirection(isGreatestToLeastSort: string): void {
    this.sortByDirectionOverride = Boolean(isGreatestToLeastSort);
    this.resetDataSetPages(false);
    this.onAdditionalFilterChange(true);
  }

  /**
   * @description Reapplies the default ui overrides
   */
  async onResize(): Promise<void> {
    this.setStickyColumns();
    this.setTableHeight();
  }

  /**
   * @description Reapplies the table height
   */
  async setTableHeight(): Promise<void> {
    await this.$nextTick();
    const tableLayout = document.getElementById('integrity-table-layout');
    this.tableHeight = tableLayout ? tableLayout.offsetHeight : 0;
  }

  /**
   * @description Get how many sticky columns there are
   */
  getStickColsTotals(): number {
    return this.getStickyBeginCols() + this.getStickyEndCols();
  }

  /**
   * @description Get how many sticky columns there in the front
   */
  getStickyBeginCols(): number {
    return this.synchedHeaders.filter((header) => header.class === 'sticky').length;
  }

  /**
   * @description Get how many sticky columns there in the back
   */
  getStickyEndCols(): number {
    return this.synchedHeaders.filter((header) => header.class === 'sticky-end').length;
  }

  /**
   * @description Reapply the sticky columns
   */
  async setStickyColumns(): Promise<void> {
    await this.$nextTick();
    const stickyHeaders = document.querySelectorAll(`#${this.tableID} > div.v-data-table__wrapper > table > thead > tr > th.sticky`);
    const stickyColumns = document.querySelectorAll(`#${this.tableID} > div.v-data-table__wrapper > table > tbody > tr > .sticky, #${this.tableID} > div.v-data-table__wrapper > table > thead > tr > .sticky`);
    const stickyHeadersEnd = document.querySelectorAll(`#${this.tableID} > div.v-data-table__wrapper > table > thead > tr > th.sticky-end`);
    const stickyColumnsEnd = document.querySelectorAll(`#${this.tableID} > div.v-data-table__wrapper > table > tbody > tr > .sticky-end, #${this.tableID} > div.v-data-table__wrapper > table > thead > tr > .sticky-end`);
    let columnOffsets = this.showExpand ? [56] : [0];
    columnOffsets = this.showSelect ? [64] : columnOffsets;
    const columnOffsetsEnd = [0];

    const tableRows = document.querySelectorAll(`#${this.tableID} > div.v-data-table__wrapper > table > tbody > tr, #${this.tableID} > div.v-data-table__wrapper > table > thead > tr`);

    // Make the expand column sticky
    if (tableRows.length > 0 && (this.showExpand || this.showSelect)) {
      Array.from(tableRows).forEach((row, index) => {
        const expandCell = tableRows[index].firstElementChild as HTMLElement;
        expandCell.style.position = 'sticky';
        expandCell.style.left = '0';
        expandCell.style.backgroundColor = expandCell.tagName === 'TD' ? 'inherit' : '#ffffff';
        expandCell.style.borderRight = 'none';
        if (expandCell.tagName === 'TD') {
          expandCell.style.setProperty('z-index', '5');
        }
        if (this.showExpand || this.showSelect) {
          expandCell.className = 'expandCell';
        }
      });
    }
    // set sticky columns at start of table
    if (stickyColumns.length > 0) {
      let coIndex = 0;
      Array.from(stickyHeaders).forEach((header) => {
        const offset = Math.round((header as HTMLElement).offsetWidth);
        columnOffsets.push(columnOffsets[coIndex] + offset - 0.4);
        coIndex += 1;
      });

      columnOffsets.pop();

      Array.from(stickyColumns).forEach((_col, index) => {
        const stickyColumnCell = stickyColumns[index] as HTMLElement;
        stickyColumnCell.style.left = `${columnOffsets[index % columnOffsets.length]}px`;

        if (index % stickyHeaders.length === stickyHeaders.length - 1) {
          stickyColumnCell.style.borderRight = '2px solid #d9d9d9';
        }
      });
    }

    // set sticky columns at end of table
    if (stickyColumnsEnd.length > 0) {
      let coIndexEnd = 0;
      Array.from(stickyHeadersEnd).reverse().forEach((header) => {
        const offset = (header as HTMLElement).offsetWidth;
        columnOffsetsEnd.push(columnOffsetsEnd[coIndexEnd] + offset - 0.4);
        coIndexEnd += 1;
      });

      columnOffsetsEnd.pop();
      columnOffsetsEnd.reverse();

      Array.from(stickyColumnsEnd).forEach((_col, index) => {
        const stickyColumnCell = stickyColumnsEnd[index] as HTMLElement;

        stickyColumnCell.style.right = `${columnOffsetsEnd[index % columnOffsetsEnd.length]}px`;

        if (index % stickyHeadersEnd.length === 0) {
          stickyColumnCell.style.borderLeft = '1px solid #d9d9d9';
        }
      });
    }
  }

  /**
   * @description Handles when the table's select all is toggled.
   */
  toggleSelectAll(event): void {
    if (this.hasInitialLoadHappened && event.value && this.synchedSelectedItems != null) {
      if (this.mostRecentWorker != null) {
        this.mostRecentWorker.postMessage({ type: 'selectAll' });
        this.synchedSelectedItems.splice(0, this.synchedSelectedItems.length);
      }
    } else if (this.synchedSelectedItems != null) {
      this.synchedSelectedItems.splice(0, this.synchedSelectedItems.length);
    }
  }

  handleSelectAllCallaback(data): void {
    if (data != null && this.synchedSelectedItems != null) {
      this.synchedSelectedItems.splice(0, this.synchedSelectedItems.length);
      this.synchedSelectedItems = this.synchedSelectedItems.concat(data
        .map((d) => (this.synchedData as any[])[d.finalArrayIndex]));
    }
    // eslint-disable-next-line no-param-reassign
    data = null;
  }

  // #region Workers

  /**
   * @description Start the organization of the table and resets the cache to the resulting dataset
   */
  async startWorkerOrganization() {
    if (this.synchedData == null || this.synchedData.length === 0) {
      return;
    }
    const worker = this.getBaseWorker();
    this.isWorkerLoading = true;
    await this.$nextTick();
    const encoder = new TextEncoder();
    const synchedHeaders = encoder.encode(JSON.stringify(this.synchedHeaders)).buffer;
    const synchedMatchFilters = encoder.encode(JSON.stringify(this.synchedMatchFilters)).buffer;
    const synchedBlankMatchFilters = encoder
      .encode(JSON.stringify(this.synchedBlankMatchFilters)).buffer;
    const synchedData = encoder.encode(JSON.stringify(this.synchedData)).buffer;
    const postObject = {
      type: 'start',
      data: synchedData,
      sortBy: this.getSortByOverride(),
      sortByDirection: this.sortByDirectionOverride,
      search: this.tableSearch != null ? this.tableSearch : '',
      additionalFilters: this.additionalFilterFunctionsString,
      dataFillFunctionsString: this.dataFillFunctionsString,
      synchedHeaders,
      synchedMatchFilters,
      synchedBlankMatchFilters,
    };
    worker.postMessage(postObject, [
      synchedHeaders,
      synchedMatchFilters,
      synchedBlankMatchFilters,
      synchedData,
    ]);
  }

  /**
   * @description Handles when the base worker returns data
   */
  async handleWorkerMessage(event: any) {
    const decoder = new TextDecoder();
    if (event?.data?.type === 'recieveSlice') {
      await this.onRecieveDataSlice(event.data?.startIndex, event.data?.endIndex, event);
      // eslint-disable-next-line no-param-reassign
      event.data.data = null;
    } else if (event?.data?.type === 'recieveSelectAll') {
      this.handleSelectAllCallaback(JSON.parse(decoder.decode(event.data.data)));
      // eslint-disable-next-line no-param-reassign
      event.data.data = null;
    } else {
      let data = event?.data?.data;
      if (event?.data?.type === 'endSortFilter' && data) {
        if (!this.hasInitialLoadHappened) {
          this.hasInitialLoadHappened = true;
          this.$emit('onLoad');
        }
        this.workerFriendlyTableDataIndexes = [];
        this.workerFriendlyTableDataIndexes = this.workerFriendlyTableDataIndexes
          .concat(JSON.parse(decoder.decode(data)));
        data = null;
        this.currentTableDataTotalLength = event.data.currentTableDataTotalLength;
        this.updatePageSelectDropDownOptions();
        this.goToPage(0);
        // eslint-disable-next-line no-param-reassign
        event.data.data = null;
      } else if (event?.data?.type === 'endGenerateFilterValues' && data) {
        this.overrideFilterValues = null;
        this.overrideFilterValues = JSON.parse(decoder.decode(data));
        data = null;
        // eslint-disable-next-line no-param-reassign
        event.data.data = null;
      }
      data = null;
    }
  }

  /**
   * @description Returns a new worker if one doesn't exist.
   * Otherwise returns the existing worker this.mostRecentWorker
   */
  getBaseWorker() {
    if (this.mostRecentWorker == null) {
      const worker = this.createWorker();
      worker.onmessage = this.handleWorkerMessage;
      this.mostRecentWorker = worker;
      return worker;
    }
    return this.mostRecentWorker;
  }

  /**
   * @description constructs the blob for the worker.
   * It is important the functions are defined in a function instead of using a vue function.
   * Vue functions get injected with some webpack stuff whereas functions in a vue function do not.
   */
  private createWorker(): Worker {
    return new Worker('./IntegrityTableWorker.js', { type: 'module' });
  }
  // #endregion

  /**
   * @description Emit the item in the row clicked
   */
  onClickRow(item: any): void{
    this.$emit('clickRow', item);
  }

  /**
   * @description Wrapper for the route color getter
   */
  getRouteColor(color: string): string | undefined {
    return getRouteColor(color);
  }

  /**
   * @description Return the match filter for the given header
   */
  currentMatchFilter(header: DataTableHeader): MatchFilter {
    return this.synchedMatchFilters.find((f) => f.header === header.value);
  }

  /**
   * @description Update the data in the item in the table row
   */
  updateInlineValue(item: any, header: any, value: any): void {
    // eslint-disable-next-line no-param-reassign
    item[header.value] = value;
    this.$emit('inlineEdit', item);
  }

  /**
   * @description Returns 'ft' or 'm' if we should display imperial
   */
  getUnitLabel(): string {
    return this.displayImperial ? 'ft' : 'm';
  }

  /**
   * @description Returns if a header value has any filter values
   */
  hasFilterValues(headerValue: string): boolean {
    if (this.tableFilterValues != null && this.synchedMatchFilters) {
      const foundMatchFilter = this.synchedMatchFilters
        .find((filter) => filter.header === headerValue);
      const isMatchFilterApplied = foundMatchFilter?.value != null && foundMatchFilter?.value !== '';
      let foundBlankMatchFilter;
      if (this.synchedBlankMatchFilters != null) {
        foundBlankMatchFilter = this.synchedBlankMatchFilters[headerValue];
      }
      const isBlankMatchFilterApplied = foundBlankMatchFilter;
      return this.tableFilterValues[headerValue]?.length > 0
        || isMatchFilterApplied
        || isBlankMatchFilterApplied;
    }
    return false;
  }

  /**
   * @description Closes the v-menu via clicking the button.
   * Pass in a ref list and it will close the first element.
   */
  closeMenu(headerRef: any[]): void {
    if (headerRef == null || headerRef.length === 0 || headerRef[0] == null) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    headerRef[0].save();
  }

  /**
   * @description Wrapper for the utility function
   */
  processDate(dateString: string): string {
    return processDate(dateString);
  }

  /**
   * @description Wrapper for the utility function
   */
  getFormattedDate(date: Date): string {
    let dateString = '';

    if (date) {
      dateString = this.processDate(new Date(date).toISOString());
    }

    return dateString;
  }

  /**
   * @description Get the date formatted as date time
   */
  getFormattedDateTime(date: Date): string {
    let dateString = '';

    if (date) {
      dateString = format(Date.parse(date.toString()), 'yyyy-MM-dd HH:mm');
    }
    return dateString;
  }

  /**
   * @description Add commas to numbers
   */
  formatNumber(num: number): string {
    return utils.formatNumber(num);
  }

  /**
   * @description headerValue != score or hasCustomerDeliverables
   */
  doesHeaderHaveBlankOption(headerValue: string): boolean {
    return headerValue !== 'score' && headerValue !== 'hasCustomerDeliverables';
  }

  /**
   * @description Wrapper to get feet to meters
   */
  getDisplayDistanceFtM(distance: number): number {
    return utils.getDisplayDistanceFtM(this.displayImperial, distance);
  }
}
