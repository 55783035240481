import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { AgolState } from './types';
import { AgolMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum AgolActions {
  GET_AGOL_FIELDS = 'GET_AGOL_FIELDS',
  GET_AGOL_DATA = 'GET_AGOL_DATA',
  POST_AGOL_DATA = 'POST_AGOL_DATA',
  GET_AGOL_LOGIN_DATA = 'GET_AGOL_LOGIN_DATA',
}

export const actions: ActionTree<AgolState, RootState> = {
  async [AgolActions.GET_AGOL_FIELDS]({ commit }, payload): Promise<void> {
    commit(AgolMutations.SET_AGOL_FEATURE_SERVICE_DATA_LOADING_STATE, true);
    commit(AgolMutations.SET_AGOL_FEATURE_SERVICE_DATA_LOAD_ERROR, undefined);
    commit(AgolMutations.SET_AGOL_FEATURE_SERVICE_DATA, undefined);
    commit(AgolMutations.SET_AGOL_DATA_LOADING_STATE, false);
    commit(AgolMutations.SET_AGOL_DATA_LOAD_ERROR, undefined);
    commit(AgolMutations.SET_AGOL_DATA, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getFeatureFields(payload.payload, payload.auth);

      if (response.data == null || response.data.error != null) {
        throw new Error('API Error occurred.');
      }

      commit(AgolMutations.SET_AGOL_FEATURE_SERVICE_DATA, response.data);
    } catch (e) {
      commit(AgolMutations.SET_AGOL_FEATURE_SERVICE_DATA_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AgolMutations.SET_AGOL_FEATURE_SERVICE_DATA_LOADING_STATE, false);
    }
  },

  async [AgolActions.GET_AGOL_DATA]({ commit }, payload): Promise<void> {
    commit(AgolMutations.SET_AGOL_DATA_LOADING_STATE, true);
    commit(AgolMutations.SET_AGOL_DATA_LOAD_ERROR, undefined);
    commit(AgolMutations.SET_AGOL_DATA, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getFeatureData(payload.payload, payload.primaryId, payload.auth);

      commit(AgolMutations.SET_AGOL_DATA, response.data.features);
    } catch (e) {
      commit(AgolMutations.SET_AGOL_DATA_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AgolMutations.SET_AGOL_DATA_LOADING_STATE, false);
    }
  },

  async [AgolActions.POST_AGOL_DATA]({ commit }, payload): Promise<void> {
    commit(AgolMutations.SET_AGOL_DATA_LOADING_STATE, true);
    commit(AgolMutations.SET_AGOL_DATA_LOAD_ERROR, undefined);
    commit(AgolMutations.SET_AGOL_DATA, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postFeatureData(
        payload.projectGuid,
        payload.mappingGuid, {
          assets: payload.assets,
          refreshToken: payload.refreshToken,
          fieldMappings: payload.fieldMappings,
          featureServerLink: payload.featureServerLink,
        },
      );

      if (response.status === 200) {
        commit(AgolMutations.SET_AGOL_DATA, []);
      }
    } catch (e) {
      commit(AgolMutations.SET_AGOL_DATA_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AgolMutations.SET_AGOL_DATA_LOADING_STATE, false);
    }
  },

  async [AgolActions.GET_AGOL_LOGIN_DATA]({ commit }, payload): Promise<void> {
    commit(AgolMutations.SET_AGOL_LOGIN_DATA, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postAgolCreds(payload);

      if (response.status === 200) {
        commit(AgolMutations.SET_AGOL_LOGIN_DATA, response.data);
      }
    } catch (e) {
      throw new Error('API Error occurred.');
    }
  },
};
