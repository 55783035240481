
































import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component({})
export default class SideDrawer extends Vue {
  @PropSync('drawerOpen') synchedDrawerOpen!: boolean;

  toggleDrawer(): void {
    this.synchedDrawerOpen = !this.synchedDrawerOpen;
  }
}
