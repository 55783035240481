import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { CrossSectionsState } from './types';

const state: CrossSectionsState = {
  data: undefined,
  loading: false,
  loadError: undefined,
  defectData: undefined,
  defectLoading: false,
  defectError: undefined,
  pvdData: undefined,
  pvdLoading: false,
  pvdError: undefined,
};

const crossSections: Module<CrossSectionsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default crossSections;
