







































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { MPSActions } from '@/store/mps/actions';
import { User } from '@/store/admin/types';
import { AdminActions } from '@/store/admin/actions';
import {
  DATASTANDARD_MACP7_LEVEL1,
  DATASTANDARD_MACP7_LEVEL2,
  EMPTY_GUID,
} from '@/common/Constants';
import { uuid } from 'vue-uuid';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { OrganizationActions } from '@/store/organizations/actions';
import { CustomerActions } from '@/store/customers/actions';
import { OrganizationData } from '@/store/organizations/types';
import { CustomerData } from '@/store/customers/types';
import MPSSectionInformation from './MPSSectionInformation.vue';
import MPSSectionData from './MPSSectionData.vue';
import MPSSectionContacts from './MPSSectionContacts.vue';
import MPSSectionInvoicing from './MPSSectionInvoicing.vue';
import MPSSectionProject from './MPSSectionProject.vue';
import MPSSectionReporting from './MPSSectionReporting.vue';
import MPSFooter from './MPSFooter.vue';
import {
  SYS_ADMIN,
  SYS_ADMIN_AUTH0,
} from '../../auth/roles';
import {
  ClientInfo,
  DataRequirement,
  Field,
  MacpSection,
  TeamMember,
} from './types';
import { customField, iMPS } from './iMPS';

const MPSModule = namespace('mps');
const adminModule = namespace('admin');
const organizationModule = namespace('organizations');
const customerModule = namespace('customers');

@Component({
  components: {
    MPSSectionInformation,
    MPSSectionData,
    MPSSectionContacts,
    MPSSectionInvoicing,
    MPSSectionProject,
    MPSSectionReporting,
    MPSFooter,
  },
})
export default class MPS extends UserPermissionsMixin {
  @Prop() readonly guid: string | undefined;

  @MPSModule.Action(MPSActions.POST_INSERT_MPS) postMPS;

  @MPSModule.Action(MPSActions.FETCH_MPS_DATA) fetchMPSData;

  @MPSModule.Action(MPSActions.PATCH_MPS) patchMPS;

  @MPSModule.State('loadError') mpsLoadError;

  @MPSModule.State('mpsData') mpsData: iMPS;

  @adminModule.Action(AdminActions.POST_NEW_USER) postUserData;

  @organizationModule.Action(OrganizationActions.FETCH_ORGANIZATION_DATA)
  fetchOrganizationData;

  @organizationModule.State('organizationData') organizationDataList:
    | OrganizationData[]
    | undefined;

  @customerModule.Action(CustomerActions.FETCH_CUSTOMER_DATA)
  fetchCustomerData;

  @customerModule.State('customerData') customerDataList:
    | CustomerData[]
    | undefined;

  readonly NUM_MAX_LENGTH = 9999999999.99;

  readonly STR_MAX_LENGTH = 255;

  additionalBillingDetails = '' as string;

  additionalComments = '';

  additionalReportingNotes = '';

  asBuiltsProvided = false;

  billingFrequency = '';

  bookedDate = '';

  city = '';

  clientInfo = [] as ClientInfo[];

  contractAmount = 0.0;

  contractNumber = '';

  customFields = [] as customField[];

  currencyType = 'US Dollar ($)';

  dataRequirements = [] as DataRequirement[];

  dataTypes = ['String', 'Numeric', 'Boolean'];

  deliverableSchedule = '';

  deliveryMedium = '';

  endDate = '';

  errorMessage = '' as string;

  gisRadioOptions = [
    { key: '0', label: 'Customer GIS Received', model: false as boolean },
  ];

  gisTypeRadioOptions = [
    { key: '0', label: 'Customer GIS Type Received', model: '' },
  ];

  gisUpdateContact = [{ key: '0', label: 'GIS Contact', model: '' }];

  gisExpectations = '';

  invoicingInfo = [] as unknown[];

  isError = false as boolean;

  jobNumber = '';

  liningPlan = '';

  macpLevel = '';

  onsiteMeetingAddr = '' as string;

  opportunityNumber = '';

  otherProjectReason = '';

  poNumber = '';

  projectGuid = '';

  projectName = '';

  projectScope = '';

  projectOwner = '';

  inspectionPurpose = '';

  projectCustomer = '';

  ratesAndWages = [
    {
      key: '0',
      label: 'Prevailing Wage',
      model: false as boolean,
      input: '' as string,
    },
    {
      key: '1',
      label: 'Are Rates Included in the Agreement?',
      model: false as boolean,
    },
  ];

  reasonForProject = '';

  reconRequired = false;

  reportingOutputsOptions = [
    { label: 'ICOM Media', value: false },
    { label: 'Integrity Deliverable', value: false },
    { label: 'MSI Report (standalone PDF)', value: false },
    { label: 'PACP DB (standalone with media)', value: false },
    { label: 'PACP Report (standalone PDF)', value: false },
    {
      label: 'MACP DB',
      value: false,
      subOptions: [
        { label: 'Level 1', value: false },
        { label: 'Level 2', value: false },
        { label: 'Custom', value: false },
      ],
    },
    { label: 'GIS Deliverable / Resolution', value: false },
    { label: 'Additional Detail', value: false },
  ] as Field[];

  reportingOutputsPremiumOptions = [
    { label: 'Alignment', value: false },
    { label: 'Bend Radius', value: false },
    { label: 'High Accuracy', value: false },
    { label: 'Virtual Mandrel', value: false },
  ] as Field[];

  aiCodingOutputsOptions = [
    {
      label: 'Send Data to WinCan AI Sewermatics',
      value: false,
      selectedOption: 'Fast',
      subOptions: [
        { label: 'Fast', value: 'Fast' },
        { label: 'Full', value: 'Full' },
      ],
    },
  ]

  organization = null;

  customer = null;

  reportTurnaroundTime = '';

  requiredField = [
    (v: string | boolean | number): string | boolean => !!v || v === 0 || 'This is a required field.',
  ];

  savingEdit = false;

  startDate = '';

  stateSelected = '';

  contigencyItems = [];

  safetyPlanOptions = [
    { key: '0', label: 'Safety Plan Required', model: false as boolean },
  ];

  siteSafetyOptions = [
    { key: '0', label: 'Site Safety Required', model: false as boolean },
  ];

  rescueTeamOptions = [
    { key: '0', label: 'Rescue Team Required', model: false as boolean },
  ];

  permitsAndAuthorizationLettersOptions = [
    {
      key: '0',
      label: 'Permits/Authorization Letters Required',
      model: false as boolean,
    },
  ];

  permitsAndAuthorizationLettersText = '';

  signageOptions = [
    { key: '0', label: 'Signage Required', model: false as boolean },
  ];

  signageText = '';

  doorHangersOptions = [
    { key: '0', label: 'Door Hangers Required', model: false as boolean },
  ];

  trafficControlOptions = [
    { key: '0', label: 'Traffic Control Required', model: false as boolean },
  ];

  trafficControlResponsibleOptions = [
    { key: '0', label: 'Who is responsible for Traffic Control', model: '' },
  ];

  jetTruckOptions = [
    { key: '0', label: 'Jet Truck Required', model: false as boolean },
  ];

  pipeCleaningOptions = [
    { key: '0', label: 'Pipe Cleaning Required', model: false as boolean },
  ];

  pipeCleaningResponsibilityOptions = [
    { key: '0', label: 'Who is responsible for cleaning', model: '' },
  ];

  surveyCoordinatesProvided = false;

  selectedTypeModel: {component: unknown, name: string, id: string, index: number} = {
    component: MPSSectionInformation,
    name: 'Information',
    id: 'information',
    index: 0,
  };

  options = [
    {
      component: MPSSectionInformation,
      name: 'Information',
      id: 'information',
      index: 0,
    },
    {
      component: MPSSectionData,
      name: 'Data',
      id: 'data',
      index: 1,
    },
    {
      component: MPSSectionContacts,
      name: 'Contacts',
      id: 'contacts',
      index: 2,
    },
    {
      component: MPSSectionInvoicing,
      name: 'Invoicing',
      id: 'invoicing',
      index: 3,
    },
    {
      component: MPSSectionProject,
      name: 'Project',
      id: 'project',
      index: 4,
    },
    {
      component: MPSSectionReporting,
      name: 'Reporting',
      id: 'reporting',
      index: 5,
    },
  ]

  get componentProps(): unknown {
    let componentProps = null;
    try {
      switch (this.selectedTypeModel.name) {
        case 'Information':
          componentProps = {
            ref: 'info',
            canEditMPS: this.hasPermissionEditMps,
            requiredField: this.requiredField,
          };
          break;
        case 'Data':
          componentProps = {
            ref: 'data',
            canEditMPS: this.hasPermissionEditMps,
            requiredField: this.requiredField,
          };
          break;
        case 'Contacts':
          componentProps = {
            ref: 'mpscontacts',
            canEditMPS: this.hasPermissionEditMps,
            requiredField: this.requiredField,
          };
          break;
        case 'Invoicing':
          componentProps = {
            ref: 'invoicing',
            canEditMPS: this.hasPermissionEditMps,
            requiredField: this.requiredField,
          };
          break;
        case 'Project':
          componentProps = {
            ref: 'project',
            canEditMPS: this.hasPermissionEditMps,
            requiredField: this.requiredField,
          };
          break;
        case 'Reporting':
          componentProps = {
            ref: 'reporting',
            canEditMPS: this.hasPermissionEditMps,
            requiredField: this.requiredField,
          };
          break;
        default:
          break;
      }
    } catch (err) {
      return componentProps;
    }

    return componentProps;
  }

  tab = 0;

  tabOptions = [
    'Information',
    'Data',
    'Contacts',
    'Invoicing',
    'Project',
    'Reporting',
  ];

  teamMembers = [] as TeamMember[];

  termsAndConditions = '' as string;

  totalFootage = 0.0;

  totalVertical = 0;

  validInfo = false;

  validData = false;

  validContacts = false;

  validInvoicing = false;

  crewAccommodations = '';

  leinWaver = false;

  certifiedPayroll = false;

  payrollFrequency = null;

  redzoneW9 = false;

  redzoneACH = false;

  validProject = false;

  photoRequirementSurfaceCover = false;

  photoRequirementRim = false;

  photoRequirementConnections = false;

  photoRequirementSurroundingAreas = false;

  photoRequirementOtherContent = '';

  photoRequirementOtherCheckBox = false;

  // workHours = '';

  workHoursOptions = [
    { label: 'M-F (7AM-7PM)', value: false },
    { label: 'Sun-Sat (7AM-7PM)', value: false },
    { label: '24hr', value: false },
    { label: 'Night Work', value: false },
  ];

  validReporting = false;

  macpSections = [
    {
      header: 'General Information',
      fields: [
        { label: '1. Surveyed By', value: false, levels: [1, 2] },
        { label: '2. Certificate Number', value: false, levels: [1, 2] },
        { label: '3. Reviewed By', value: false, levels: [] },
        { label: '4. Reviewer Certificate No.', value: false, levels: [] },
        { label: '5. Owner', value: false, levels: [] },
        { label: '6. Customer', value: false, levels: [] },
        { label: '7. PO Number', value: false, levels: [] },
        { label: '8. Work Order', value: false, levels: [] },
        { label: '9. Media Label', value: false, levels: [] },
        { label: '10. Project', value: false, levels: [] },
        { label: '11. Date', value: false, levels: [1, 2] },
        { label: '12. Time', value: false, levels: [] },
        { label: '13. Sheet Number', value: false, levels: [1, 2] },
        { label: '14. Weather', value: false, levels: [] },
        { label: '15. Pre-Cleaning', value: false, levels: [2] },
        { label: '16. Date Cleaned', value: false, levels: [] },
        { label: '17. Purpose of Survey', value: false, levels: [1, 2] },
        { label: '18. Inspection Level', value: false, levels: [1, 2] },
        { label: '19. Inspection Status', value: false, levels: [1, 2] },
        { label: '20. Consequence of Failure', value: false, levels: [] },
      ],
    },
    {
      header: 'Location and Measurements',
      fields: [
        { label: '21. Drainage Area', value: false, levels: [] },
        { label: '22. MH/Access Point No.', value: false, levels: [1, 2] },
        { label: '23. Street', value: false, levels: [1, 2] },
        { label: '24. City', value: false, levels: [1, 2] },
        { label: '25. Location Code', value: false, levels: [2] },
        { label: '26. Surface Type', value: false, levels: [2] },
        { label: '27. Inflow Potential from Runoff', value: false, levels: [] },
        { label: '28. Location Details', value: false, levels: [] },
        { label: '29. MH Use', value: false, levels: [2] },
        { label: '30. Access Type', value: false, levels: [1, 2] },
        { label: '31. Year Constructed', value: false, levels: [] },
        { label: '32. Year Renewed', value: false, levels: [] },
        { label: '33. Evidence of Surcharge', value: false, levels: [2] },
        { label: '34. Rim to Invert', value: false, levels: [2] },
        { label: '35. Rim to Grade', value: false, levels: [2] },
        { label: '36. Grade to Invert', value: false, levels: [2] },
        { label: '37. Rim to Grade Exposed', value: false, levels: [] },
        { label: '38. Northing', value: false, levels: [] },
        { label: '39. Easting', value: false, levels: [] },
        { label: '40. Elevation', value: false, levels: [] },
        { label: '41. Coordinate System', value: false, levels: [] },
        { label: '42. Vertical Datum', value: false, levels: [] },
        { label: '43. GPS Accuracy', value: false, levels: [] },
        { label: '44. Additional Information ', value: false, levels: [] },
      ],
    },
    {
      header: 'Cover and Frame',
      fields: [
        { label: '45. Type', value: false, levels: [2] },
        { label: '46. Shape', value: false, levels: [2] },
        { label: '47. Size', value: false, levels: [2] },
        { label: '48. Center Cover Size', value: false, levels: [] },
        { label: '49. Size Width', value: false, levels: [2] },
        { label: '50. Cover Material', value: false, levels: [] },
        { label: '51. Hole Diameter (Vent)', value: false, levels: [2] },
        { label: '52. Hole Number', value: false, levels: [2] },
        { label: '53. Bearing Surface Diameter', value: false, levels: [2] },
        { label: '54. Bearing Surface Width', value: false, levels: [2] },
        { label: '55. Cover/Frame Fit', value: false, levels: [2] },
        { label: '56. Cover Condition', value: false, levels: [1, 2] },
        { label: '57. Insert Type', value: false, levels: [2] },
        { label: '58. Cover Insert Condition', value: false, levels: [2] },
        { label: '59. Ring Type', value: false, levels: [2] },
        { label: '60. Ring Material', value: false, levels: [2] },
        { label: '61. Ring Condition', value: false, levels: [1, 2] },
        { label: '62. Ring Height', value: false, levels: [] },
        { label: '63. Frame Material', value: false, levels: [2] },
        { label: '64. Bearing Surface Width', value: false, levels: [2] },
        { label: '65. Bearing Surface Depth', value: false, levels: [2] },
        { label: '66. Clear Opening Diam.', value: false, levels: [2] },
        { label: '67. Clear Opening Width', value: false, levels: [2] },
        { label: '68. Frame Condition', value: false, levels: [1, 2] },
        { label: '69. Seal Condition', value: false, levels: [2] },
        { label: '70. Offset Distance', value: false, levels: [2] },
        { label: '71. Seal Inflow', value: false, levels: [2] },
        { label: '72. Frame Depth', value: false, levels: [] },
      ],
    },
    {
      header: 'Chimney, Cone, Wall, and Bench',
      fields: [
        { label: '73. Chimney Present', value: false, levels: [2] },
        { label: '75. First Material', value: false, levels: [2] },
        { label: '74. Second Material', value: false, levels: [] },
        { label: '76. Chimney I/I', value: false, levels: [] },
        { label: '77. Clear Opening', value: false, levels: [] },
        { label: '78. Chimney Depth', value: false, levels: [2] },
        { label: '79. Chimney Lining Interior', value: false, levels: [] },
        { label: '80. Chimney Lining Exterior', value: false, levels: [] },
        { label: '81. Chimney Condition', value: false, levels: [1] },
        { label: '82. Cone Type', value: false, levels: [2] },
        { label: '83. Cone Material', value: false, levels: [2] },
        { label: '84. Cone Depth', value: false, levels: [] },
        { label: '85. Cone Lining Interior', value: false, levels: [] },
        { label: '86. Cone Lining Exterior', value: false, levels: [] },
        { label: '87. Cone Condition', value: false, levels: [1] },
        { label: '88. Wall Diameter (Length)', value: false, levels: [] },
        { label: '89. Wall by Size (Width)', value: false, levels: [] },
        { label: '90. Wall Material', value: false, levels: [2] },
        { label: '91. Wall Depth', value: false, levels: [2] },
        { label: '92. Wall Lining Interior', value: false, levels: [] },
        { label: '93. Wall Lining Exterior', value: false, levels: [] },
        { label: '94. Wall Condition', value: false, levels: [1] },
        { label: '95. Bench Present', value: false, levels: [2] },
        { label: '96. Bench Material', value: false, levels: [2] },
        { label: '97. Bench Lining', value: false, levels: [] },
        { label: '98. Bench Condition', value: false, levels: [1] },
        { label: '99. Channel Installed', value: false, levels: [2] },
        { label: '100. Channel Material', value: false, levels: [2] },
        { label: '101. Channel Type', value: false, levels: [2] },
        { label: '102. Channel Exposure', value: false, levels: [2] },
        { label: '103. Channel Condition', value: false, levels: [1] },
        { label: '104. Steps Number', value: false, levels: [2] },
        { label: '105. Steps Material', value: false, levels: [2] },
        {
          label: '106. Additional Component Information',
          value: false,
          levels: [],
        },
      ],
    },
    {
      header: 'Pipe Connections',
      fields: [
        { label: '107. Pipe No.', value: false, levels: [2] },
        { label: '108. Clock Pos.', value: false, levels: [2] },
        { label: '109. Rim to Invert', value: false, levels: [2] },
        { label: '110. Direction', value: false, levels: [2] },
        { label: '111. Material', value: false, levels: [2] },
        { label: '112. Shape', value: false, levels: [2] },
        { label: '113. Height (Diam.)', value: false, levels: [2] },
        { label: '114. Width', value: false, levels: [2] },
        { label: '115. Cond.', value: false, levels: [2] },
        { label: '116. Seal Cond.', value: false, levels: [2] },
        { label: '117. Pipe Type', value: false, levels: [2] },
        { label: '118. Struct. ID', value: false, levels: [] },
        { label: '119. Comment', value: false, levels: [] },
      ],
    },
  ] as MacpSection[];

  /*
  @Watch('drawerOpen')
  onDrawerOpenChange(): void {
    if (!this.drawerOpen) this.resetForm();
    else this.update(this.editProjectGuid);
  }
    */
  get dataStandardGuid(): string {
    let retVal = EMPTY_GUID;

    if (this.macpLevel === 'Level 1') retVal = DATASTANDARD_MACP7_LEVEL1;
    if (this.macpLevel === 'Level 2') retVal = DATASTANDARD_MACP7_LEVEL2;

    return retVal;
  }

  get isValidTab(): boolean {
    return this.validTabs[this.tab];
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_VIEW_MPS
   */
  get hasPermissionProjectSelectionViewMps(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_VIEW_MPS);
  }

  validTabs: boolean[] = [
    this.validInfo,
    this.validData,
    this.validContacts,
    this.validInvoicing,
    this.validProject,
    this.validReporting,
  ];

  isSaveMode = false;

  async mounted(): Promise<void> {
    if (this.guid) {
      this.update(this.guid);
      this.isSaveMode = true;
    }
    if (!this.hasPermissionProjectSelectionViewMps) {
      this.goToErrorPage();
    }
  }

  async getRoles(): Promise<string[]> {
    if (!this.$auth?.user?.id) {
      return [];
    }
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  get isFinalTab(): boolean {
    return this.tab === this.validTabs.length - 1;
  }

  createMPSObject(): iMPS {
    const mps = {} as iMPS;

    mps.projectName = this.projectName;
    mps.projectGuid = this.projectGuid !== '' ? this.projectGuid : uuid.v4();
    mps.opportunityNumber = this.opportunityNumber;
    mps.poNumber = this.poNumber;
    mps.jobNumber = this.jobNumber;
    mps.bookedDate = new Date(this.bookedDate);
    mps.contractNumber = this.contractNumber;
    mps.contractAmount = Number(this.contractAmount);
    mps.reasonForProject = this.reasonForProject;
    mps.otherProjectReason = this.otherProjectReason;
    mps.jobSummary = this.projectScope;
    mps.projectOwner = this.projectOwner;
    mps.inspectionPurpose = this.inspectionPurpose;
    mps.projectCustomer = this.projectCustomer;

    mps.dataRequirements = JSON.stringify(this.dataRequirements);
    mps.startDate = new Date(this.startDate);
    mps.endDate = new Date(this.endDate);
    mps.totalFootage = Number(this.totalFootage);
    mps.totalVertical = Number(this.totalVertical);

    mps.gisReceived = this.gisRadioOptions[0].model;
    mps.gisType = this.gisTypeRadioOptions[0].model;
    mps.gisContact = this.gisUpdateContact[0].model;
    mps.gisExpectations = this.gisExpectations;

    mps.projectScope = this.projectScope;
    mps.projectTeam = JSON.stringify(this.teamMembers);
    // mps.additionalComments = this.additionalComments;

    mps.invoicingInfo = JSON.stringify(this.invoicingInfo);
    mps.billingFrequency = this.billingFrequency;
    mps.currencyType = this.currencyType;
    mps.termsAndConditions = this.termsAndConditions;
    mps.additionalBillingDetails = this.additionalBillingDetails;

    mps.projectClient = JSON.stringify(this.clientInfo);
    mps.prevailingRate = this.ratesAndWages[0].model;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mps.prevailingRateDetails = this.ratesAndWages[0].input!;
    mps.ratesIncluded = this.ratesAndWages[1].model;
    mps.city = this.city;
    mps.state = this.stateSelected;

    mps.macpLevel = this.macpLevel;
    mps.dataStandardGuid = this.dataStandardGuid;
    mps.macpSections = JSON.stringify(this.macpSections);
    mps.customFields = this.customFields;

    mps.crewAccommodations = this.crewAccommodations;
    mps.leinWaver = this.leinWaver;
    mps.certifiedPayroll = this.certifiedPayroll;
    mps.payrollFrequency = this.payrollFrequency;
    mps.redzoneW9 = this.redzoneW9;
    mps.redzoneACH = this.redzoneACH;
    mps.contigencyItems = this.contigencyItems;
    mps.workHours = this.workHoursOptions
      .filter((w) => w.value === true)
      .map((w) => w.label);
    mps.reconRequired = this.reconRequired;
    mps.onsiteMeetingAddr = this.onsiteMeetingAddr;
    mps.safetyPlanReceived = this.safetyPlanOptions[0].model;
    mps.siteSafetyReceived = this.siteSafetyOptions[0].model;
    mps.rescueTeamReceived = this.rescueTeamOptions[0].model;
    mps.permitsAndAuthorizationLettersReceived = this
      .permitsAndAuthorizationLettersOptions[0].model;
    mps.permitsAndAuthorizationLettersText = mps.permitsAndAuthorizationLettersReceived
      ? this.permitsAndAuthorizationLettersText
      : '';
    mps.signageReceived = this.signageOptions[0].model;
    mps.signageText = mps.signageReceived ? this.signageText : '';
    mps.doorHangersReceived = this.doorHangersOptions[0].model;
    mps.trafficControlReceived = this.trafficControlOptions[0].model;
    mps.trafficControlResponsibleReceived = mps.trafficControlReceived
      ? this.trafficControlResponsibleOptions[0].model
      : '';
    mps.jetTruckReceived = this.jetTruckOptions[0].model;
    mps.pipeCleaningReceived = this.pipeCleaningOptions[0].model;
    mps.pipeCleaningResponsibilityReceived = mps.pipeCleaningReceived
      ? this.pipeCleaningResponsibilityOptions[0].model
      : '';

    if (
      this.mpsData
      && this.mpsData.dataStandardGuid
      && this.mpsData.dataStandardGuid !== EMPTY_GUID
    ) {
      mps.dataStandardGuid = this.mpsData.dataStandardGuid;
    }

    mps.surveyCoordsProvided = this.surveyCoordinatesProvided;
    mps.liningPlan = this.liningPlan;
    mps.deliverableSched = this.deliverableSchedule;
    mps.deliveryMedium = this.deliveryMedium;
    mps.reportingOutputs = JSON.stringify(this.reportingOutputsOptions);
    mps.premiumOutputs = JSON.stringify(this.reportingOutputsPremiumOptions);
    mps.additionalReportingNotes = this.additionalReportingNotes;
    mps.reportTurnaround = this.reportTurnaroundTime;
    mps.photoRequirementSurfaceCover = this.photoRequirementSurfaceCover;
    mps.photoRequirementRim = this.photoRequirementRim;
    mps.photoRequirementConnections = this.photoRequirementConnections;
    mps.photoRequirementSurroundingAreas = this.photoRequirementSurroundingAreas;
    mps.photoRequirementOtherContent = this.photoRequirementOtherContent;
    mps.aiOutputs = JSON.stringify(this.aiCodingOutputsOptions);

    mps.organizationGuid = this.organization?.guid;
    mps.customerGuid = this.customer?.guid;

    // As per my usual notes, this can definitely be done better.
    // Still figuring things out.
    return mps;
  }

  async createNewSysAdminUser(projectGuid: string): Promise<void> {
    const sysOwner = this.clientInfo.find(
      (client) => client.isSystemOwner === true,
    );

    let newUser = {
      Useridentity: '',
      Nickname: sysOwner.email,
      Firstname: sysOwner.firstName,
      Lastname: sysOwner.lastName,
      Picturelocation: '',
      Fieldname: '',
      Email: sysOwner.email,
      Nasscoid: '',
      UserRoleGuid: null,
      Role: SYS_ADMIN,
      ProjectGuid: projectGuid,
    } as User;
    const users = await this.$auth.getAllUsers();

    try {
      const userCheck = users.find((user) => user.email === newUser.Email);

      if (userCheck === undefined || userCheck === null) {
        newUser = await this.$auth.createUser(newUser);
        await this.$auth.sendPasswordReset(newUser.Email);
      } else {
        await this.$auth.addRoles(userCheck.userId, [SYS_ADMIN_AUTH0]);
      }
    } catch (e) {
      this.errorMessage = 'Error adding user to Auth0';
      this.isError = true;
    }

    try {
      if (
        users.find((user) => user.userId === `auth0|${this.$auth.user.id}`)
        === null
      ) {
        this.postUserData(newUser);
      }
    } catch (e) {
      this.errorMessage = 'Error adding user to Basecamp';
      this.isError = true;
    }
  }

  isTabDisabled(index: number): boolean {
    if (index === 0 || !this.hasPermissionEditMps) return false;

    return !this.validTabs.slice(0, index).every((x) => x);
  }

  updateTab(index: number): void {
    this.tab = index;
  }

  nextTab(): void {
    this.tab += 1;
    this.selectedTypeModel = this.options[this.tab];
  }

  resetForm(): void {
    window.location.href = '/projectSelection';
  }

  resetValues(): void {
    this.bookedDate = '';
    this.city = '';
    this.contractAmount = 0.0;
    this.contractNumber = '';
    this.customFields = [] as customField[];
    this.dataRequirements = [] as DataRequirement[];
    this.endDate = '';
    this.gisRadioOptions = [
      { key: '0', label: 'Customer GIS Received', model: false as boolean },
    ];
    this.gisTypeRadioOptions = [
      { key: '0', label: 'Customer GIS Type Received', model: 'MH' },
    ];
    this.gisUpdateContact = [
      { key: '0', label: 'GIS Contact', model: 'RedZone' },
    ];
    this.gisExpectations = '';
    this.jobNumber = '';
    this.opportunityNumber = '';
    this.otherProjectReason = '';
    this.poNumber = '';
    this.projectName = '';
    this.projectScope = '';
    this.projectOwner = '';
    this.inspectionPurpose = '';
    this.projectCustomer = '';
    this.reasonForProject = '';
    this.startDate = '';
    this.stateSelected = '';
    this.invoicingInfo = [] as unknown[];
    this.billingFrequency = '';
    this.termsAndConditions = '' as string;
    this.currencyType = 'US Dollar ($)';
    this.additionalBillingDetails = '' as string;
    this.clientInfo = [] as ClientInfo[];
    this.teamMembers = [] as TeamMember[];
    this.tab = 0;
    this.totalFootage = 0.0;
    this.totalVertical = 0;
    this.crewAccommodations = '';
    this.contigencyItems = [];
    this.safetyPlanOptions = [
      { key: '0', label: 'Safety Plan Required', model: false as boolean },
    ];
    this.siteSafetyOptions = [
      { key: '0', label: 'Site Safety Required', model: false as boolean },
    ];
    this.rescueTeamOptions = [
      { key: '0', label: 'Rescue Team Required', model: false as boolean },
    ];
    this.permitsAndAuthorizationLettersOptions = [
      {
        key: '0',
        label: 'Permits/Authorization Letters Required',
        model: false as boolean,
      },
    ];
    this.permitsAndAuthorizationLettersText = '';
    this.signageOptions = [
      { key: '0', label: 'Signage Required', model: false as boolean },
    ];
    this.signageText = '';
    this.doorHangersOptions = [
      { key: '0', label: 'Door Hangers Required', model: false as boolean },
    ];
    this.trafficControlOptions = [
      { key: '0', label: 'Traffic Control Required', model: false as boolean },
    ];
    this.trafficControlResponsibleOptions = [
      {
        key: '0',
        label: 'Who is responsible for Traffic Control',
        model: 'Redzone',
      },
    ];
    this.jetTruckOptions = [
      { key: '0', label: 'Jet Truck Required', model: false as boolean },
    ];
    this.pipeCleaningOptions = [
      { key: '0', label: 'Pipe Cleaning Required', model: false as boolean },
    ];
    this.pipeCleaningResponsibilityOptions = [
      { key: '0', label: 'Who is responsible for cleaning', model: 'Redzone' },
    ];
    this.leinWaver = false;
    this.certifiedPayroll = false;
    this.payrollFrequency = undefined;
    this.redzoneW9 = false;
    this.redzoneACH = false;
    this.surveyCoordinatesProvided = false;
    this.reconRequired = false;
    this.liningPlan = '';
    this.deliverableSchedule = '';
    this.deliveryMedium = '';
    this.reportingOutputsOptions.forEach((o) => {
      o.value = false;
    });
    this.reportingOutputsPremiumOptions.forEach((o) => {
      o.value = false;
    });
    this.additionalReportingNotes = this.mpsData
      ? this.mpsData.additionalReportingNotes
      : '';
    this.reportTurnaroundTime = this.mpsData
      ? this.mpsData.reportTurnaround
      : '';
    this.photoRequirementSurfaceCover = false;
    this.photoRequirementRim = false;
    this.photoRequirementConnections = false;
    this.photoRequirementSurroundingAreas = false;
    this.photoRequirementOtherContent = '';
    this.photoRequirementOtherCheckBox = false;
    this.aiCodingOutputsOptions.forEach((o) => {
      o.value = false;
    });
  }

  resetValidation(): void {
    (this.$refs.info as any).resetValidation();
    (this.$refs.data as any).resetValidation();
    (this.$refs.mpscontacts as any).resetValidation();
    (this.$refs.invoicing as any).resetValidation();
    (this.$refs.project as any).resetValidation();
    (this.$refs.reporting as any).resetValidation();
  }

  save(): void {
    this.savingEdit = true;
    const mps = this.createMPSObject();
    this.patchMPS(mps)
      .then(() => {
        this.resetForm();
      })
      .catch(() => {
        if (this.mpsLoadError === '409: The project does not exist') {
          alert('The project number that you have entered does not exists.');
        } else {
          alert(
            'There was a problem with editing your MPS.  Please make sure all data fields'
              + ' have been entered '
              + 'and a Project Manager has been added in the Project Team section.',
          );
        }
        this.savingEdit = false;
      });
  }

  submitForm(): void {
    const mps = this.createMPSObject();

    this.createNewSysAdminUser(mps.projectGuid).then(() => {
      if (this.isError === true) {
        alert(this.errorMessage);
        this.isError = false;
        this.errorMessage = '';
      } else {
        this.postMPS(mps)
          .then(() => {
            this.resetForm();
          })
          .catch(() => {
            if (this.mpsLoadError === '409: The project already exists') {
              alert('The project number that you have entered already exists.');
            } else {
              alert(
                'There was a problem with adding your MPS.  Please make sure all data fields'
                  + ' have been entered '
                  + 'and a Project Manager has been added in the Project Team section.',
              );
            }
            return false;
          });
      }
    });
  }

  update(guid: string): void {
    if (guid === null) {
      try {
        (this.$refs.mpscontacts as any).addUserAsMember();
      } catch (ex) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const silence = ex;
      }
      return;
    }

    this.fetchMPSData(guid)
      .then(() => {
        this.projectGuid = guid;
        this.additionalBillingDetails = this.mpsData.additionalBillingDetails;
        this.additionalComments = this.mpsData.additionalComments;
        this.billingFrequency = this.mpsData.billingFrequency;
        this.bookedDate = new Date(this.mpsData.bookedDate)
          .toISOString()
          .slice(0, 10);
        this.city = this.mpsData.city;
        this.contractAmount = Number(this.mpsData.contractAmount);
        this.currencyType = this.mpsData.currencyType;
        this.dataRequirements = JSON.parse(this.mpsData.dataRequirements);
        this.endDate = this.mpsData.endDate
          ? new Date(this.mpsData.endDate).toISOString().slice(0, 10)
          : '';
        this.gisRadioOptions[0].model = this.mpsData.gisReceived;
        this.gisTypeRadioOptions[0].model = this.mpsData.gisType;
        this.gisUpdateContact[0].model = this.mpsData.gisContact;
        this.gisExpectations = this.mpsData.gisExpectations;
        this.invoicingInfo = JSON.parse(this.mpsData.invoicingInfo);
        this.jobNumber = this.mpsData.jobNumber;
        this.projectScope = this.mpsData.jobSummary;
        this.opportunityNumber = this.mpsData.opportunityNumber;
        this.ratesAndWages[0].model = this.mpsData.prevailingRate;
        this.ratesAndWages[0].input = this.mpsData.prevailingRateDetails;
        this.clientInfo = JSON.parse(this.mpsData.projectClient);
        this.projectName = this.mpsData.projectName;
        this.projectScope = this.mpsData.projectScope;
        this.projectOwner = this.mpsData.projectOwner;
        this.inspectionPurpose = this.mpsData.inspectionPurpose;
        this.projectCustomer = this.mpsData.projectCustomer;
        this.teamMembers = JSON.parse(this.mpsData.projectTeam);
        this.ratesAndWages[1].model = this.mpsData.ratesIncluded;
        this.startDate = new Date(this.mpsData.startDate)
          .toISOString()
          .slice(0, 10);
        this.stateSelected = this.mpsData.state;
        this.termsAndConditions = this.mpsData.termsAndConditions;
        this.totalFootage = Number(this.mpsData.totalFootage);
        this.customFields = this.mpsData.customFields ?? [];
        this.reasonForProject = this.mpsData.reasonForProject;
        this.otherProjectReason = this.mpsData.otherProjectReason;
        this.contractNumber = this.mpsData.contractNumber;
        this.poNumber = this.mpsData.poNumber;
        this.totalVertical = this.mpsData.totalVertical;
        this.macpLevel = this.mpsData.macpLevel;
        if (this.mpsData.workHours && this.mpsData.workHours.length > 0) {
          this.fillWorkHourOptions(this.mpsData.workHours);
        }
        this.reconRequired = this.mpsData.reconRequired ?? false;
        this.onsiteMeetingAddr = this.mpsData.onsiteMeetingAddr;
        this.crewAccommodations = this.mpsData.crewAccommodations == null
          ? ''
          : this.mpsData.crewAccommodations;
        this.leinWaver = this.mpsData.leinWaver ?? false;
        this.certifiedPayroll = this.mpsData.certifiedPayroll ?? false;
        this.payrollFrequency = this.mpsData.payrollFrequency;
        this.redzoneW9 = this.mpsData.redzoneW9 ?? false;
        this.redzoneACH = this.mpsData.redzoneACH ?? false;
        this.photoRequirementSurfaceCover = this.mpsData.photoRequirementSurfaceCover;
        this.photoRequirementRim = this.mpsData.photoRequirementRim;
        this.photoRequirementConnections = this.mpsData.photoRequirementConnections;
        this.photoRequirementSurroundingAreas = this.mpsData.photoRequirementSurroundingAreas;
        this.photoRequirementOtherContent = this.mpsData.photoRequirementOtherContent;
        if (
          this.photoRequirementOtherContent !== ''
          && this.photoRequirementOtherContent != null
        ) {
          this.photoRequirementOtherCheckBox = true;
        }
        if (this.mpsData.macpSections) {
          this.macpSections = JSON.parse(this.mpsData.macpSections);
        }
        this.surveyCoordinatesProvided = this.mpsData.surveyCoordsProvided;
        this.liningPlan = this.mpsData.liningPlan;
        this.deliverableSchedule = this.mpsData.deliverableSched;
        this.deliveryMedium = this.mpsData.deliveryMedium;
        if (this.mpsData.reportingOutputs) {
          this.reportingOutputsOptions = JSON.parse(
            this.mpsData.reportingOutputs,
          );
        }
        if (this.mpsData.premiumOutputs) {
          this.reportingOutputsPremiumOptions = JSON.parse(
            this.mpsData.premiumOutputs,
          );
        }
        this.additionalReportingNotes = this.mpsData.additionalReportingNotes;
        this.reportTurnaroundTime = this.mpsData.reportTurnaround;

        this.contigencyItems = this.mpsData.contigencyItems !== null
          ? this.mpsData.contigencyItems
          : [];
        this.contigencyItems = this.mpsData.contigencyItems;
        this.safetyPlanOptions[0].model = this.mpsData.safetyPlanReceived;
        this.siteSafetyOptions[0].model = this.mpsData.siteSafetyReceived;
        this.rescueTeamOptions[0].model = this.mpsData.rescueTeamReceived;
        this.permitsAndAuthorizationLettersOptions[0].model = this
          .mpsData.permitsAndAuthorizationLettersReceived;
        this.permitsAndAuthorizationLettersText = this.mpsData.permitsAndAuthorizationLettersText;
        this.signageOptions[0].model = this.mpsData.signageReceived;
        this.signageText = this.mpsData.signageText;
        this.doorHangersOptions[0].model = this.mpsData.doorHangersReceived;
        this.trafficControlOptions[0].model = this.mpsData.trafficControlReceived;
        this.trafficControlResponsibleOptions[0].model = this
          .mpsData.trafficControlResponsibleReceived;
        this.jetTruckOptions[0].model = this.mpsData.jetTruckReceived;
        this.pipeCleaningOptions[0].model = this.mpsData.pipeCleaningReceived;
        this.pipeCleaningResponsibilityOptions[0].model = this
          .mpsData.pipeCleaningResponsibilityReceived;
        if (this.mpsData.aiOutputs) {
          this.aiCodingOutputsOptions = JSON.parse(
            this.mpsData.aiOutputs,
          );
        }
        if (this.mpsData.organizationGuid) {
          this.fetchOrganizationData().then(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (_: any) => {
              this.organization = this.organizationDataList
                .find((org) => org.guid === this.mpsData.organizationGuid) ?? null;
            },
          );
        }
        if (this.mpsData.customerGuid) {
          this.fetchCustomerData().then(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (_: any) => {
              this.customer = this.customerDataList
                .find((cust) => cust.guid === this.mpsData.customerGuid) ?? null;
            },
          );
        }
      })
      .catch(() => {
        alert('There was an error opening this MPS project');
        this.$emit('emitClose');
      });
  }

  fillWorkHourOptions(selectedOptions: string[]): void {
    selectedOptions.forEach((option) => {
      const who = this.workHoursOptions.find((w) => w.label === option);
      who.value = true;
    });
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_EDITMPS
   */
  get hasPermissionEditMps(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_EDITMPS);
  }
}
