import {
  AssetHistory, AssetWorkOrderHistory, HistoryEntry, ProjectDeliveryTotals,
} from '@/components/ScopeHistory/types';
import { MutationTree } from 'vuex';
import { ScopeHistoryState } from './types';

// eslint-disable-next-line no-shadow
export enum ScopeHistoryMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_SCOPE_HISTORY_DATA = 'SET_SCOPE_HISTORY_DATA',
  SET_ASSET_HISTORY_DATA = 'SET_ASSET_HISTORY_DATA',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
  SET_PATCH_HISTORY_MESSAGE = 'SET_PATCH_HISTORY_MESSAGE',
  SET_PATCH_HISTORY_ERROR = 'SET_PATCH_HISTORY_ERROR',
  SET_ASSET_WORKORDER_HISTORY_DATA = 'SET_ASSET_WORKORDER_HISTORY_DATA',
  SET_ASSET_WORKORDER_HISTORY_LOADING = 'SET_ASSET_WORKORDER_HISTORY_LOADING',
  SET_ASSET_WORKORDER_HISTORY_ERROR = 'SET_ASSET_WORKORDER_HISTORY_ERROR',
  SET_PATCH_ASSET_WORKORDER_HISTORY_LOADING = 'SET_PATCH_ASSET_WORKORDER_HISTORY_LOADING',
  SET_PATCH_ASSET_WORKORDER_HISTORY_ERROR = 'SET_PATCH_ASSET_WORKORDER_HISTORY_ERROR',
  SET_FETCH_PROJECT_DELIVERY_TOTALS_LOADING = 'SET_FETCH_PROJECT_DELIVERY_TOTALS_LOADING',
  SET_FETCH_PROJECT_DELIVERY_TOTALS_ERROR = 'SET_FETCH_PROJECT_DELIVERY_TOTALS_ERROR',
  SET_FETCH_PROJECT_DELIVERY_TOTALS_DATA = 'SET_FETCH_PROJECT_DELIVERY_TOTALS_DATA',
  SET_CUSTOMER_PREP_RELEASE_EMAILS = 'SET_CUSTOMER_PREP_RELEASE_EMAILS',
  SET_CUSTOMER_PREP_RELEASE_EMAILS_LOADING = 'SET_CUSTOMER_PREP_RELEASE_EMAILS_LOADING',
  SET_CUSTOMER_PREP_RELEASE_EMAILS_ERROR = 'SET_CUSTOMER_PREP_RELEASE_EMAILS_ERROR',
}

export const mutations: MutationTree<ScopeHistoryState> = {
  [ScopeHistoryMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },

  [ScopeHistoryMutations.SET_SCOPE_HISTORY_DATA](state, payload: HistoryEntry[]) {
    state.data = payload;
  },

  [ScopeHistoryMutations.SET_ASSET_HISTORY_DATA](state, payload: AssetHistory[]) {
    state.assetHistory = payload;
  },

  [ScopeHistoryMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },

  [ScopeHistoryMutations.SET_PATCH_HISTORY_MESSAGE](state, payload: string) {
    state.assetHistoryPatchMessage = payload;
  },
  [ScopeHistoryMutations.SET_PATCH_HISTORY_ERROR](state, payload: boolean) {
    state.assetHistoryPatchError = payload;
  },
  [ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_DATA](state,
    payload: AssetWorkOrderHistory[]) {
    state.assetWorkOrderHistory = payload;
  },

  [ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_LOADING](state, payload: boolean) {
    state.assetWorkOrderLoading = payload;
  },

  [ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_ERROR](state, payload: string) {
    state.assetWorkOrderError = payload;
  },

  [ScopeHistoryMutations.SET_PATCH_ASSET_WORKORDER_HISTORY_LOADING](state, payload: boolean) {
    state.assetPatchWorkOrderLoading = payload;
  },

  [ScopeHistoryMutations.SET_PATCH_ASSET_WORKORDER_HISTORY_ERROR](state, payload: string) {
    state.assetPatchWorkOrderError = payload;
  },

  [ScopeHistoryMutations.SET_FETCH_PROJECT_DELIVERY_TOTALS_LOADING](state, payload: boolean) {
    state.projectDeliveryTotalsLoading = payload;
  },

  [ScopeHistoryMutations.SET_FETCH_PROJECT_DELIVERY_TOTALS_ERROR](state, payload: string) {
    state.projectDeliveryTotalsError = payload;
  },

  [ScopeHistoryMutations.SET_FETCH_PROJECT_DELIVERY_TOTALS_DATA](state,
    payload: ProjectDeliveryTotals) {
    state.projectDeliveryTotals = payload;
  },

  [ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS](state, payload: string[]) {
    state.customerPrepReleaseEmails = payload;
  },

  [ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS_LOADING](state, payload: boolean) {
    state.customerPrepReleaseEmailsLoading = payload;
  },

  [ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS_ERROR](state, payload: string) {
    state.customerPrepReleaseEmailsError = payload;
  },
};
