
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MetricsActions } from '@/store/metrics/actions';
import { MetricsMutations } from '@/store/metrics/mutations';
import {
  AssetCountsMetrics,
  CrewCountsWithAverages,
  CrewDataDeliveryProjectMetrics,
  OverviewTotalCounts,
  ProjectCrewPlatform,
  ProjectOverviewMetrics,
} from '@/store/metrics/types';
import { ProjectActions } from '@/store/project/actions';
import { ProjectHeaders, ProjectListing } from '@/store/project/types';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const metricsModule = namespace('metrics');
const projectModule = namespace('project');

@Component({
  components: {},
})
export default class CrewDashboardMixin extends Vue {
    @metricsModule.Action(MetricsActions.FETCH_ASSET_COUNTS_METRICS)
  fetchAssetCountMetrics;

  @metricsModule.Action(MetricsActions.FETCH_PROJECT_OVERVIEW_METRICS)
  fetchProjectOverviewMetrics;

  @metricsModule.Action(MetricsActions.FETCH_OVERVIEW_TOTAL_COUNTS)
  fetchOverviewTotalCounts;

  @metricsModule.Action(MetricsActions.FETCH_PLATFORM_CREW_OVERVIEW)
  fetchPlatformCrewOverview;

  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchProjectHeaders;

  @metricsModule.Action(MetricsActions.FETCH_CREW_COUNTS_BY_DAY)
  fetchCrewCountsByDay;

  @metricsModule.State('assetCountsMetrics')
  assetCountsMetrics: AssetCountsMetrics;

  @metricsModule.State('projectOverviewMetrics')
  projectOverviewMetrics: ProjectOverviewMetrics;

  @metricsModule.State('overviewTotalCounts')
  overviewTotalCounts: OverviewTotalCounts;

  @metricsModule.State('platformCrewCounts')
  platformCrewCounts: ProjectCrewPlatform[];

  @metricsModule.State('crewCountsByDay') crewCountsByDay;

  @projectModule.State('headers') projectHeaders: ProjectHeaders | undefined;

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  @metricsModule.State('crewCountsAveragesData') crewCountsWithAverages: CrewCountsWithAverages[];

  @metricsModule.State('crewCountAveragesUpdateData') updateCrewCountAverageDTO: CrewCountsWithAverages;

  @metricsModule.Action(MetricsActions.FETCH_CREW_COUNTS_AVERAGES_METRICS)
    fetchCrewCountsAverages;

  @metricsModule.Action(MetricsActions.FETCH_UPDATE_CREW_COUNT_AVERAGES_METRIC)
    fetchSingleCrewCountAverage;

  @metricsModule.Mutation(MetricsMutations.SET_CREW_COUNTS_AVERAGES_DATA)
    setCrewCountAveragesData;

  @metricsModule.Action(MetricsActions.FETCH_CREW_DATA_DELIVERY_PROJECT_METRICS)
    fetchCrewDataDeliveryMetrics;

  @metricsModule.State('crewDataDeliveryProjectMetrics') crewDataDeliveryProjectMetrics: CrewDataDeliveryProjectMetrics[] | undefined;

  @metricsModule.State('crewDataDeliveryProjectMetricsLoading') crewDataDeliveryProjectMetricsLoading: boolean;

  @metricsModule.State('crewDataDeliveryProjectMetricsError') crewDataDeliveryProjectMetricsError: string | undefined;
}
