











































/* eslint-disable @typescript-eslint/no-explicit-any */
// import { SnackBarActions } from '@/store/integritySnackBar/actions';
import { Component, Prop, Vue } from 'vue-property-decorator';
// import { namespace } from 'vuex-class';

// const integritySnackBar = namespace('integritySnackBar');

@Component({})
export default class IntegrityConfirm extends Vue {
    @Prop({ default: 'Would you like to continue' }) readonly confirmHeader: string;

    @Prop({ default: 'This operation is not reversable.' }) readonly confirmText: string;

    @Prop() confirmFunction;

    @Prop({ default: false }) readonly loading: boolean;

    @Prop({ default: false }) readonly disabled: boolean;

    // @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

    dialogVisible = false;

    async confirmConfirm(): Promise<void> {
      // confirm function should return a boolean of the success of the confirm
      const res = await this.confirmFunction();

      this.dialogVisible = false;
      if (res === undefined) {
        console.error('Unable to complete function!');
      }

      // TODO: bring this back if we want generic confirmations to push a snackbar
      //   let snackBarMessage;
      //   let snackColor;
      //   if (res) {
      //     snackBarMessage = 'confirm Successful';
      //     snackColor = 'green';
      //   } else {
      //     snackBarMessage = 'confirm Error';
      //     snackColor = 'red';
      //   }

    //   this.showSnackBar({
    //     message: snackBarMessage,
    //     color: snackColor,
    //     showSnackBar: true,
    //   });
    }
}
