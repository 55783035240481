import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { MapPagesState } from './types';
import { MapPagesMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum MapPagesActions {
    FETCH_MAP_PAGES_DATA = 'FETCH_MAP_PAGES_DATA',
}

export const actions: ActionTree<MapPagesState, RootState> = {

  // eslint-disable-next-line spaced-comment
  async [MapPagesActions.FETCH_MAP_PAGES_DATA]({ commit }, projectIDs: string[]): Promise<void> {
    commit(MapPagesMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getMapPages(projectIDs);

      commit(MapPagesMutations.SET_MAP_PAGES_DATA, response.data);
    } catch (e) {
      commit(MapPagesMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MapPagesMutations.SET_LOADING_STATE, false);
    }
  },
};
