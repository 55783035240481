

























import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { namespace } from 'vuex-class';
import { BigPipeSegment } from '@/store/deployments/types';

const deploymentModule = namespace('deployments');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class BigPipeDropdown extends Vue {
    @Prop({ default: [] }) readonly data: BigPipeSegment[];

    @Prop({ default: false }) readonly loading: boolean;

    @Prop({ default: [] }) readonly projectGuids: string[];

    @Prop({ default: '' }) readonly search: string;

    @deploymentModule.State('bigPipeDeploymentDataLoading') bigPipeDeploymentDataLoading: boolean;

    tableData: BigPipeSegment[] = [];

    tableHeaders = [
      {
        text: 'Asset ID',
        value: 'AssetID',
        sortable: true,
        filterable: true,
      },
      {
        text: 'US MH', value: 'UpStreamMh', sortable: true, filterable: true,
      },
      {
        text: 'DS MH', value: 'DnStreamMh', sortable: true, filterable: true,
      },
      {
        text: 'Expected Length', value: 'GisLength', sortable: true, filterable: true,
      },
      {
        text: 'Payout', value: 'Payout', sortable: true, filterable: true,
      },
      {
        text: 'Pipe Material', value: 'PipeMaterial', sortable: true, filterable: true,
      },
      {
        text: 'Pipe Size', value: 'PipeSize', sortable: true, filterable: true,
      },
      {
        text: 'Pipe Shape', value: 'PipeShape', sortable: true, filterable: true,
      },
      {
        text: 'Segment Comments', value: 'SegmentCommments', sortable: true, filterable: true,
      },
      {
        text: 'Coding Status',
        value: 'DoNotCode',
        sortable: true,
        filterable: true,
        editable: true,
        options: [
          { name: 'Yes', value: true },
          { name: 'No', value: false },
        ],
      },
      {
        text: 'Active',
        value: 'isActive',
        sortable: true,
        filterable: true,
        editable: true,
        options: [
          { name: 'Yes', value: true },
          { name: 'No', value: false },
        ],
      },
    ];

    showSnackbar = false as boolean;

    snackbarColor = '' as string;

    snackbarMessage = '' as string;

    get isLoading(): boolean {
      return this.bigPipeDeploymentDataLoading || this.loading;
    }

    @Watch('data', { deep: true })
    onDataChange(): void {
      const tempObject = this.data != null ? this.data : [];
      const returnObject = [];
      // I am changing the text of the booleans to be Yes/No instead of true/false
      tempObject.forEach((dep) => {
        returnObject.push({
          ...dep,
          ...{
            DoNotCode: dep.DoNotCode ? 'Yes' : 'No',
            isActive: 'Yes',
          },
        });
      });
      this.tableData = returnObject;
    }

    mounted(): void {
      this.onDataChange();
    }
}
