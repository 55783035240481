import { MutationTree } from 'vuex';
import { MapPagesData, MapPagesState } from './types';

// eslint-disable-next-line no-shadow
export enum MapPagesMutations {
    SET_LOADING_STATE = 'SET_LOADING_STATE',
    SET_MAP_PAGES_DATA = 'SET_PROJECT_DATA',
    SET_LOAD_ERROR = 'SET_LOAD_ERROR',
}

export const mutations : MutationTree<MapPagesState> = {
  [MapPagesMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },

  [MapPagesMutations.SET_MAP_PAGES_DATA](state, payload: MapPagesData) {
    state.data = payload;
  },

  [MapPagesMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
};
