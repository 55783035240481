


































































































import { AssetData } from '@/store/asset/types';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import util from './util';
import { pacpReportFields } from './types';

const assetModule = namespace('asset');

@Component
export default class ReportObservation extends Vue {
  @Prop(Object) readonly report: Record<string, unknown | undefined> | undefined;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @assetModule.State('asset') asset: AssetData | undefined = undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reportFields: pacpReportFields | any = {};

  @Watch('report', { immediate: true })
  onReportChange(): void{
    this.getReportFields();
  }

  getDisplayDistanceFtM(value: number): string {
    try {
      return value || value === 0 ? util.getDisplayDistanceFtM(this.displayImperial, value)
        + util.getDistanceUnitsFtM(this.displayImperial) : '';
    } catch {
      return '';
    }
  }

  getReportFields(): void {
    this.reportFields.image = this.report['image'] ? this.report['image'] : '';
    this.reportFields.code = this.report['code'] ? this.report['code'] : '';
    this.reportFields.description = this.report['description'] ? this.report['description'] : '';
    this.reportFields.distance = this.report['distance'] ? this.report['distance'] : 0;
    this.reportFields.structuralGrade = this.report['structuralGrade'] ? this.report['structuralGrade'] : '';
    this.reportFields.omGrade = this.report['omGrade'] ? this.report['omGrade'] : '';
    this.reportFields.clockStartFrom = this.report['clockStartFrom'] ? this.report['clockStartFrom'] : '';
    this.reportFields.clockTo = this.report['clockTo'] ? this.report['clockTo'] : '';
    this.reportFields.firstValue = this.report['firstValue'] ? this.report['firstValue'] : '';
    this.reportFields.secondValue = this.report['secondValue'] ? this.report['secondValue'] : '';
    this.reportFields.valuePercent = this.report['valuePercent'] ? this.report['valuePercent'] : '';
    this.reportFields.continuousIndex = this.report['continuousIndex'] ? this.report['continuousIndex'] : '';
    this.reportFields.withinEightOfJoint = this.report['withinEightOfJoint'] ? this.report['withinEightOfJoint'] : '';
    this.reportFields.remarks = this.report['remarks'] ? this.report['remarks'] : '';
  }
}
