















import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class CustomerDashboardHeader extends CustomerDashboardMixin {
  @Prop() readonly projectName: string | undefined;

  @Prop({ default: false }) readonly isLineSegment: boolean;

  @Prop({ default: 2 }) readonly reportType!: number;

  get dateRange(): { start: string, end: string } {
    const retVal = {
      start: '',
      end: '',
    };

    if (this.reportType === 2) {
      const dates = this.getOverviewTotalCountDates();
      if (dates.length === 0) {
        retVal.start = null;
        retVal.end = null;
      } else {
        retVal.start = this.parseDate(new Date(Math.min(...dates)));
        retVal.end = this.parseDate(new Date(Math.max(...dates)));
      }
    } else if (this.reportType === 3) {
      if (!this.scheduledDate.dateValues && !this.completedDate.dateValues) {
        retVal.start = this.parseDate(new Date(this.projectOverviewMetrics.startDate));
        retVal.end = this.parseDate(new Date());
      } else if (this.scheduledDate.dateValues) {
        [retVal.start, retVal.end] = this.scheduledDate.dateValues;
      } else if (this.completedDate.dateValues) {
        [retVal.start, retVal.end] = this.completedDate.dateValues;
      }
    }

    return retVal;
  }

  getSystemCounts(): string {
    const metricCount = (
      this.isLineSegment
        ? Math.round(this.assetCountsMetrics.lineSegments.addressed)
        : Math.round(this.assetCountsMetrics.manholes.addressed)
    ).toLocaleString();
    const units = this.isLineSegment ? 'Feet' : 'Manholes';
    return `${metricCount} ${units} Addressed`;
  }

  getOverviewTotalCountDates(): number[] {
    if (!this.overviewTotalCounts) {
      return [];
    }
    const overViewTotalCountsDates = [];

    this.overviewTotalCounts.platforms.forEach((platform) => {
      platform.dailyCounts.forEach((count) => {
        overViewTotalCountsDates.push(new Date(count.deploymentDate).getTime());
      });
    });
    return overViewTotalCountsDates;
  }

  parseDate(date: Date | undefined): string {
    if (!date) {
      return '';
    }
    return date.toISOString().slice(0, 10);
  }
}
