



























































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  InspectionData, Condition, AssetData,
} from '@/store/asset/types';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import mediaUtils from '@/views/Asset/utils';
import PipeConditionReport from '../../PipeConditionReport/PipeConditionReport.vue';
import ReportObservation from '../ReportObservation.vue';
import util from '../util';
import ReportFooter from '../ReportFooter.vue';
import { observationReportFullFields } from './types';

@Component({
  components: {
    PipeConditionReport,
    ReportObservation,
    ReportFooter,
  },
})
export default class Observation extends Vue {
  @Prop() readonly inspection!: InspectionData;

  @Prop() readonly codingDetail: InspectionResponse;

  @Prop({ default: 'Line Segment' }) readonly assetType!: string;

  @Prop({ default: 'N/A' }) readonly assetName!: string;

  @Prop() readonly asset!: AssetData;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop({ default: -1 }) readonly fullVideoTime: number;

  reportFields: observationReportFullFields | any = {
    street: '',
    city: '',
    overallScoringoverallQuick: '',
    overallScoringoverallIndex: '',
    overallScoringoverallRating: '',
    overallScoringstructuralQuick: '',
    overallScoringstructuralIndex: '',
    overallScoringstructuralRating: '',
    overallScoringomQuick: '',
    overallScoringomIndex: '',
    overallScoringomRating: '',
    generalInformationDate: '',
    generalInformationowner: '',
    generalInformationcustomer: '',
    pipeConnections: [],
    pipeConnectionsMaterial: '',
    pipeConnectionsShape: '',
    generalInformationsurveyedBy: '',
    generalInformationcertificateNumber: '',
    pipeConnectionsDirection: '',
    locationlocationDetails: '',
    locationdrainageArea: '',
    generalInformationPoNumber: '',
    generalInformationpurposeOfSurvey: '',
    generalInformationpreCleaning: '',
    generalInformationdateCleaned: '',
    manholeyearRenewed: '',
    generalInformationweather: '',
    additionalComponentInformation: '',
  };

  howManyIterations = 0;

  inspectionsGrouped: Array<InspectionData> = [];

  get codingDetailReport(): Record<string, unknown> {
    return this.codingDetail ? JSON.parse(this.codingDetail.inspections[0].jsonData) : {};
  }

  get report(): Record<string, unknown> {
    return this.inspection ? this.inspection.report : {};
  }

  get conditionReportPages(): Array<Condition[]> {
    let index = 1;
    let pageCount = 0;
    let page = [];
    const pages = [[]];

    if (this.inspection) {
      this.inspection.conditionReport.forEach((condRep) => {
        pages[pageCount].push(condRep);

        if (index % 3 === 0 && index !== this.inspection.conditionReport.length) {
          page = [];
          pages.push(page);
          pageCount += 1;
        }

        index += 1;
      });
    }
    return pages;
  }

  // eslint-disable-next-line class-methods-use-this
  get dateNow(): string {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    } as const;
    return new Date().toLocaleDateString('en-US', options);
  }

  get maxDepth(): number {
    return mediaUtils.getMaxDepthReached(
      this.inspection,
      this.fullVideoTime,
    );
  }

  get assetScreenshotUrl(): string {
    if (this.$store == null) return '';
    return this.$store.getters['asset/getAssetScreenshotUrl'];
  }

  @Watch('inspection')
  onInspectionChange(): void{
    this.inspectionsGrouped = [];
    this.howManyIterations = 0;
    if (this.inspection != null
      && this.inspection.conditionReport != null
      && this.inspection.conditionReport.length > 0
    ) {
      this.howManyIterations = Math.ceil(this.inspection.conditionReport.length / 30);

      let tempList = [];
      const sortedInspections = this.inspection.conditionReport
        .sort((a, b) => a.distance - b.distance);
      sortedInspections.forEach((e, i) => {
        if (i % 30 === 0 && i !== 0) {
          const newInspection: InspectionData = {
            name: this.inspection.name,
            guid: this.inspection.guid,
            inspectionDate: this.inspection.inspectionDate != null
              ? this.inspection.inspectionDate : null,
            macpLevel: 'Level 1',
            maxPayout: this.inspection.maxPayout,
            measurementUnits: this.inspection.measurementUnits,
            conditionReport: this.inspection.conditionReport,
            flatImage: this.inspection.flatImage,
            video: this.inspection.video,
            wmVideo: this.inspection.wmVideo,
            msiExist: this.inspection.msiExist,
            model: this.inspection.model,
            deliverablesJson: this.inspection.deliverablesJson,
            report: this.inspection.report,
            payoutIPD: this.inspection.payoutIPD != null ? this.inspection.payoutIPD : null,
            version: this.inspection.version,
          };
          newInspection.conditionReport = tempList;
          this.inspectionsGrouped.push(newInspection);
          tempList = [];
        }
        tempList.push(e);
      });
      if (tempList.length > 0) {
        const newInspection: InspectionData = {
          name: this.inspection.name,
          guid: this.inspection.guid,
          inspectionDate: this.inspection.inspectionDate != null
            ? this.inspection.inspectionDate : null,
          macpLevel: 'Level 1',
          maxPayout: this.inspection.maxPayout,
          measurementUnits: this.inspection.measurementUnits,
          conditionReport: this.inspection.conditionReport,
          flatImage: this.inspection.flatImage,
          video: this.inspection.video,
          wmVideo: this.inspection.wmVideo,
          msiExist: this.inspection.msiExist,
          model: this.inspection.model,
          deliverablesJson: this.inspection.deliverablesJson,
          report: this.inspection.report,
          payoutIPD: this.inspection.payoutIPD != null ? this.inspection.payoutIPD : null,
          version: this.inspection.version,
        };
        newInspection.conditionReport = tempList;
        this.inspectionsGrouped.push(newInspection);
      }
    }
    if (this.report !== undefined) {
      this.fillReportFields();
    }
  }

  mounted(): void{
    this.onInspectionChange();
    if (this.report !== undefined) {
      this.fillReportFields();
    }
  }

  getDisplayDistanceInMM(value: string): string {
    try {
      const num = parseInt(value.substring(0, value.length - 1), 10);
      return value || num === 0
        ? `${util.getDisplayDistanceInMM(this.displayImperial, num)} ${util.getDistanceUnitsInMM(this.displayImperial)}`
        : '';
    } catch {
      return '';
    }
  }

  getDisplayDistanceFtM(value: number): string {
    try {
      return value || value === 0
        ? `${util.getDisplayDistanceFtM(this.displayImperial, value)} ${util.getDistanceUnitsFtM(this.displayImperial)}`
        : '';
    } catch {
      return '';
    }
  }

  fillReportFields(): void {
    if (Object.keys(this.codingDetailReport).length > 0) {
      this.reportFields = {
        street: '',
        city: this.codingDetailReport.City ? this.codingDetailReport.City : '',
        overallScoringoverallQuick: '',
        overallScoringoverallIndex: '',
        overallScoringoverallRating: '',
        overallScoringstructuralQuick: '',
        overallScoringstructuralIndex: '',
        overallScoringstructuralRating: '',
        overallScoringomQuick: '',
        overallScoringomIndex: '',
        overallScoringomRating: '',
        generalInformationDate: this.codingDetailReport.InspDate ? this.codingDetailReport.InspDate : '',
        generalInformationowner: this.codingDetailReport.Owner ? this.codingDetailReport.Owner : '',
        generalInformationcustomer: this.codingDetailReport.Customer ? this.codingDetailReport.Customer : '',
        pipeConnections: [],
        pipeConnectionsMaterial: '',
        pipeConnectionsShape: '',
        generalInformationsurveyedBy: this.codingDetailReport.SurveyedBy ? this.codingDetailReport.SurveyedBy : '',
        generalInformationcertificateNumber: this.codingDetailReport.CertificateNumber ? this.codingDetailReport.CertificateNumber : '',
        pipeConnectionsDirection: '',
        locationlocationDetails: this.codingDetailReport.LocationDetails ? this.codingDetailReport.LocationDetails : '',
        locationdrainageArea: this.codingDetailReport.DrainageArea ? this.codingDetailReport.DrainageArea : '',
        generalInformationPoNumber: this.codingDetailReport.PONumber ? this.codingDetailReport.PONumber : '',
        generalInformationpurposeOfSurvey: this.codingDetailReport.MHPurpose ? this.codingDetailReport.MHPurpose : '',
        generalInformationpreCleaning: this.codingDetailReport.PreCleaning ? this.codingDetailReport.PreCleaning : '',
        generalInformationdateCleaned: this.codingDetailReport.DateCleaned ? this.codingDetailReport.DateCleaned : '',
        manholeyearRenewed: this.codingDetailReport.YearRenewed ? this.codingDetailReport.YearRenewed : '',
        generalInformationweather: this.codingDetailReport.WeatherType ? this.codingDetailReport.WeatherType : '',
        additionalComponentInformation: this.codingDetailReport.AdditonalComponentInformation ? this.codingDetailReport.AdditonalComponentInformation : '',
      };
      return;
    }
    if (Object.keys(this.report).length > 0) {
      this.reportFields = {
        street: this.report['location']['street_L1_2'] ? this.report['location']['street_L1_2'] : '',
        city: this.report['location']['city_L1_2'] ? this.report['location']['city_L1_2'] : '',
        overallScoringoverallQuick: this.report['overallScoring']['overallQuick'] ? this.report['overallScoring']['overallQuick'] : '',
        overallScoringoverallIndex: this.report['overallScoring']['overallIndex'] ? this.report['overallScoring']['overallIndex'] : '',
        overallScoringoverallRating: this.report['overallScoring']['overallRating'] ? this.report['overallScoring']['overallRating'] : '',
        overallScoringstructuralQuick: this.report['overallScoring']['structuralQuick'] ? this.report['overallScoring']['structuralQuick'] : '',
        overallScoringstructuralIndex: this.report['overallScoring']['structuralIndex'] ? this.report['overallScoring']['structuralIndex'] : '',
        overallScoringstructuralRating: this.report['overallScoring']['structuralRating'] ? this.report['overallScoring']['structuralRating'] : '',
        overallScoringomQuick: this.report['overallScoring']['omQuick'] ? this.report['overallScoring']['omQuick'] : '',
        overallScoringomIndex: this.report['overallScoring']['omIndex'] ? this.report['overallScoring']['omIndex'] : '',
        overallScoringomRating: this.report['overallScoring']['omRating'] ? this.report['overallScoring']['omRating'] : '',
        generalInformationDate: this.report['generalInformation']['date_L1_2'] ? this.report['generalInformation']['date_L1_2'] : '',
        generalInformationowner: this.report['generalInformation']['owner'] ? this.report['generalInformation']['owner'] : '',
        generalInformationcustomer: this.report['generalInformation']['customer'] ? this.report['generalInformation']['customer'] : '',
        pipeConnections: this.report['pipeConnections'] !== undefined && (this.report['pipeConnections'] as Array<any>).length > 0 ? this.report['pipeConnections'] : [],
        pipeConnectionsMaterial: this.report['pipeConnections'] !== undefined && (this.report['pipeConnections'] as Array<any>).length > 0 ? this.report['pipeConnections'][0]['Material_L2'] : '',
        pipeConnectionsShape: this.report['pipeConnections'] !== undefined && (this.report['pipeConnections'] as Array<any>).length > 0 ? this.report['pipeConnections'][0]['Shape_L2'] : '',
        generalInformationsurveyedBy: this.report['generalInformation']['surveyedBy_L1_2'] ? this.report['generalInformation']['surveyedBy_L1_2'] : '',
        generalInformationcertificateNumber: this.report['generalInformation']['certificateNumber_L1_2'] ? this.report['generalInformation']['certificateNumber_L1_2'] : '',
        pipeConnectionsDirection: this.report['pipeConnections'] !== undefined && (this.report['pipeConnections'] as Array<any>).length > 0 ? this.report['pipeConnections'][0]['Direction_L2'] : '',
        locationlocationDetails: this.report['location']['locationDetails'] ? this.report['location']['locationDetails'] : '',
        locationdrainageArea: this.report['location']['drainageArea'] ? this.report['location']['drainageArea'] : '',
        generalInformationPoNumber: this.report['generalInformation']['p_ONumber'] ? this.report['generalInformation']['p_ONumber'] : '',
        generalInformationpurposeOfSurvey: this.report['generalInformation']['purposeofSurvey_L1_2'] ? this.report['generalInformation']['purposeofSurvey_L1_2'] : '',
        generalInformationpreCleaning: this.report['generalInformation']['pre_Cleaning_L2'] ? this.report['generalInformation']['pre_Cleaning_L2'] : '',
        generalInformationdateCleaned: this.report['generalInformation']['dateCleaned'] ? this.report['generalInformation']['dateCleaned'] : '',
        manholeyearRenewed: this.report['generalInformation'] ? this.report['manhole']['yearRenewed'] : '',
        generalInformationweather: this.report['generalInformation']['weather'] ? this.report['generalInformation']['weather'] : '',
        additionalComponentInformation: this.report['additionalComponentInformation'] ? this.report['additionalComponentInformation'] : '',
        shape: this.report['generalInformation'] ? this.report['generalInformation']['shape'] : '',
        lining: this.report['generalInformation'] ? this.report['generalInformation']['lining'] : '',
        location: this.report['generalInformation'] ? this.report['generalInformation']['location'] : '',
        locationDetails: this.report['generalInformation'] ? this.report['generalInformation']['locationDetails'] : '',
        surveyor: this.report['generalInformation'] ? this.report['generalInformation']['surveyor'] : '',
        direction: this.report['generalInformation'] ? this.report['generalInformation']['direction'] : '',
        purpose: this.report['generalInformation'] ? this.report['generalInformation']['purpose'] : '',
        category: this.report['generalInformation'] ? this.report['generalInformation']['category'] : '',
        flowControl: this.report['generalInformation'] ? this.report['generalInformation']['flowControl'] : '',
        additionalInfo: this.report['generalInformation'] ? this.report['generalInformation']['additionalInfo'] : '',
        po: this.report['generalInformation'] ? this.report['generalInformation']['po'] : '',
        yearBuilt: this.report['generalInformation'] ? this.report['generalInformation']['yearBuilt'] : '',
        version: this.inspection.version,
        surveyedLength: this.report['generalInformation'] ? this.report['generalInformation']['surveyedLength'] : '',
      };
    }
  }

  getQuickStyle(score: string): any {
    try {
      const quickScore = score.length > 0 ? parseInt(score.charAt(0), 10) : 0;
      return {
        color: quickScore === 3 ? 'black' : 'white',
        backgroundColor: util.getScoreColor(quickScore),
      };
    } catch {
      return {
        color: 'white',
        backgroundColor: '#0C6599',
      };
    }
  }

  get firstManhole(): string {
    return this.isUpstreamInspection ? this.downstreamMH : this.upstreamMH;
  }

  get secondManhole(): string {
    return this.isUpstreamInspection ? this.upstreamMH : this.downstreamMH;
  }

  get upstreamMH(): string {
    return this.asset.attributes['Wastewater_Structure_Up'] ?? '';
  }

  get downstreamMH(): string {
    return this.asset.attributes['Wastewater_Structure_Dn'] ?? '';
  }

  get isUpstreamInspection(): boolean {
    const { direction } = this.inspection.report['generalInformation'] as any;
    return direction === 'Upstream';
  }
}
