









import { UserPrefsActions } from '@/store/userPrefs/actions';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UserPermission } from '@/store/userpermissions/types';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import PmDashboard from '../PmDashboard/PmDashboard.vue';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard.vue';

const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
    PmDashboard,
    CustomerDashboard,
  },
})
export default class ProjectDashboard extends UserPermissionsMixin {
  @userPrefsModule.Action(UserPrefsActions.GET_USER_PREFS_DATA) getUserPrefs;

  @Prop() readonly ids!: string;

  async mounted(): Promise<void> {
    if (!this.hasPermissionViewDashboard) {
      this.goToErrorPage();
    }
  }

  /**
   * @returns true if the user has the permission PROJECT_MANAGEMENT_DASHBOARD_VIEW_DASHBOARD
   */
  get hasPermissionViewDashboard(): boolean {
    return this.hasPermission(UserPermission.PROJECT_MANAGEMENT_DASHBOARD_VIEW_DASHBOARD)
    || this.hasPermission(UserPermission.CUSTOMER_PROJECT_PROGRESS_DASHBOARD_VIEW_DASHBOARD)
    || this.hasPermission(UserPermission.CSA_DASHBOARD_VIEW_DASHBOARD)
    || this.hasPermission(UserPermission.CWO_DASHBOARD_VIEW_DASHBOARD);
  }
}
