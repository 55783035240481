




































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import { namespace } from 'vuex-class';
import { AssetData } from '@/store/asset/types';
import { AssetActions } from '@/store/asset/actions';
import ReportObservation from '../ReportObservation.vue';
import ReportFooter from '../ReportFooter.vue';
import { repairReportFields } from './types';
import util from '../util';

const assetModule = namespace('asset');

@Component({
  components: {
    ReportFooter,
    ReportObservation,
  },
})
export default class RepairReport extends Vue {
  @assetModule.State('asset') asset!: AssetData;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  repairReportFields: repairReportFields | any = {};

  workOrderJson: any;

  // eslint-disable-next-line class-methods-use-this
  formatText(text: string): string {
    if (!text) return '';
    let newText = text;

    newText = newText.replace(/&gt;/g, '>');
    newText = newText.replace(/&lt;/g, '<');
    newText = newText.replace(/&quot;/g, '"');

    return newText;
  }

  getDisplayDistanceInMM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceInMM(this.displayImperial, value)
          + util.getDistanceUnitsInMM(this.displayImperial)
      : '';
  }

  getDisplayDistanceFtM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceFtM(this.displayImperial, value)
          + util.getDistanceUnitsFtM(this.displayImperial)
      : '';
  }

  mounted(): void {
    this.workOrderJson = JSON.parse(this.codingDetail.inspections[0].jsonData);
    // fill fields
    this.fillRepairReportFields();
    // this.fetchAsset();
    this.$forceUpdate();
  }

  fillRepairReportFields(): void {
    this.repairReportFields = {
      projectName: this.item ? this.item.projectName : '',
      name: this.item && this.item.nodeName ? this.item.nodeName : '',
      assignedTo: this.workOrderJson ? this.workOrderJson.Users : '',
      inspectionType: this.workOrderJson ? this.workOrderJson.AssetPlanLibrary : '',
      frequency: this.workOrderJson ? this.workOrderJson.Frequency : '',
      fogType: this.workOrderJson ? this.workOrderJson.FOGEstType : '',
      establishment: this.item ? this.item.projectName : '',
      apn: this.workOrderJson ? this.workOrderJson.FSEAPN : '',
      workOrderNumber: this.item ? this.item.nodeName : '',
      dateIssued: this.item ? this.formatDate(this.item.createdDate) : '',
      lastInspected: this.workOrderJson ? this.workOrderJson.LastInspected : '',
      inspectionCompleted: this.workOrderJson?.completeDate ? 'Yes' : 'No',
      address: this.workOrderJson ? this.workOrderJson.FSEAddress : '',
      city: this.workOrderJson ? this.workOrderJson.FSECity : '',
      phone: this.workOrderJson ? this.workOrderJson.FSEPhone : '',
      managerOnDuty: this.workOrderJson ? this.workOrderJson.FSEManagerOnDuty : '',
      inspectionDate: this.workOrderJson ? this.formatDate(this.workOrderJson.InspDate) : '',
      inspectionTime: this.workOrderJson ? this.formatTime(this.workOrderJson.InspTime) : '',
      grdType: this.workOrderJson ? this.workOrderJson.FOGGRDType : '',
      ifOther: this.workOrderJson ? this.workOrderJson.GRDOtherType : '',
      size: this.workOrderJson ? this.workOrderJson.GRDSize : '',
      manufacturer: this.workOrderJson ? this.workOrderJson.GRDManufacturer : '',
      maintenanceLogProvided: this.workOrderJson ? this.workOrderJson.BMPGuideGiven : '',
      maintenanceLogBPMFollowed: this.workOrderJson ? this.workOrderJson.BMPFollowed : '',
      maintenanceLogCurrent: this.workOrderJson ? this.workOrderJson.MaintenanceLogCurrent : '',
      maintenanceLogBPMOnHand: this.workOrderJson ? this.workOrderJson.MaintenanceLogGivenToFSE : '',
      lastGRDMaintenanceDate: this.workOrderJson ? this.formatDate(this.workOrderJson.GRDLastMaintDate) : '',
      maintanedBy: this.workOrderJson ? this.workOrderJson.GRDLastMaintBy : '',
      lastRemovalDate: this.workOrderJson ? this.formatDate(this.workOrderJson.NextScheduledGreaseRemoval) : '',
      removedBy: this.workOrderJson ? this.workOrderJson.GreaseRemovalContractor : '',
      unsatisfactoryInspectionDate: this.workOrderJson ? this.formatDate(this.workOrderJson.DateLastInspectionFail) : '',
      lasResults: this.workOrderJson ? this.workOrderJson.GRDLastMaintResult : '',
      unsatisfactoryMissingAccessCover: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_BrokenMissing : '',
      unsatisfactoryDefectiveInletOutlet: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_InletOutLet : '',
      unsatisfactoryNonApprovedGreaseInterceptor: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_NonAppovInterceptor : '',
      unsatisfactoryMissingFlowRestrictor: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_MissingBrokenRestrict : '',
      unsatisfactoryDishwasherTiedIntoTrap: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_DishwasherTiedtoTrap : '',
      unsatisfactoryNoGreaseTrap: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_NotInstalled : '',
      unsatisfactoryMissingBaffleWall: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_BaffleScreenMissing : '',
      unsatisfactoryDirty: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_DirtyNeedsPumped : '',
      unsatisfactoryNoLog: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_NoLog : '',
      unsatisfactoryNotAccessible: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_NoAccess : '',
      unsatisfactoryReqDrainNotConnectedToGRD: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_DrainNotConnected : '',
      unsatisfactoryNotProperlyLocated: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_NotLocated : '',
      unsatisfactoryProhibitedDrainConnectedtoGRD: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_DrainProhibit : '',
      unsatisfactoryOther: this.workOrderJson ? this.workOrderJson.GRDLastInspFail_Other : '',
      inspectionResults: this.workOrderJson ? this.workOrderJson.FOGInspectionResults : '',
      other: '', // ?
      followUpClean: this.workOrderJson ? this.workOrderJson.FOGFollowup === 'Clean' : '',
      followUpRepair: this.workOrderJson ? this.workOrderJson.FOGFollowup === 'Repair' : '',
      followUpComments: this.workOrderJson ? this.workOrderJson.FollowUp : '',
    };
  }

  fetchAsset(): void {
    this.fetchAssetData({
      assetId: this.item.nodeGuid,
      inspectionId: this.item.guid,
    }).then(() => {
      this.$forceUpdate();
    });
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    const year = date.getFullYear();
    const month = (1 + date.getMonth());
    const day = date.getDate();

    return `${month}/${day}/${year}`;
  }

  formatTime(timeString: string): string {
    if (!timeString) {
      return '';
    }

    const date = new Date(timeString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }
    const formattedTimeString = timeString
      .substring(11, timeString.length - 3)
      .split(':');

    const hours = Number(
      formattedTimeString.length > 1 ? formattedTimeString[0] : '',
    );
    const minutes = Number(
      formattedTimeString.length > 1 ? formattedTimeString[1] : '',
    );

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(hours) || isNaN(minutes)) {
      return '';
    }

    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleTimeString([], { timeStyle: 'short' });
  }
}
