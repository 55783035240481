/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vue, Component } from 'vue-property-decorator';
import createAuth0Client, {
  PopupLoginOptions, Auth0Client, RedirectLoginOptions,
  GetIdTokenClaimsOptions, GetTokenSilentlyOptions,
  GetTokenWithPopupOptions, LogoutOptions, IdToken,
} from '@auth0/auth0-spa-js';
import { User as AdminUser } from '@/store/admin/types';
import User from './User';
import {
  CUSTOMER,
  SYS_ADMIN_AUTH0,
  PROJECT_MANAGER,
  SUPER_USER,
  REPORTING_TECH_AUTH0,
  CREW_LEAD_AUTH0,
  CREW_MEMBER,
  CUSTOMER_SUPPORT,
  DEMO_USER_READ_ONLY,
  DEMO_USER_WRITE,
  OPERATIONS_MANAGER,
  REPORTING_CONTRACTOR,
  REPORTING_MANAGER,
  SUPERVISOR,
  SYSTEM_OWNER,
  AuthUserRoles,
  getRoleId,
  getRoleGuidString,
} from './roles';
import { DetailedUser, UserIdentity, RoleUser } from './auth0Types';
import initPendo from './Pendo';

export type Auth0Options = {
  domain: string
  clientId: string
  audience?: string
  [key: string]: string | undefined
}

export type RedirectCallback = (appState) => void

@Component({})
export class VueAuth extends Vue {
  loading = true

  newBearer = '';

  isAuthenticated? = false

  user?: User

  auth0Client?: Auth0Client

  auth0MasterClient?: Auth0Client

  popupOpen = false

  error?: Error

  roles = []

  hasIssuesGettingUser = false

  auth0Object: {
    VUE_APP_INTEGRITY_AUTH0: string,
    A0ST_UI_CLIENT_ID: string,
    A0ST_UI_CLIENT_SECRET: string,
    A0ST_UI_AUDIENCE: string,
    A0ST_AUDIENCE: string,
    A0ST_COMMON_TENANT_NAME: string,
    A0ST_UI_M2M_CLIENT_ID: string,
    A0ST_UI_M2M_CLIENT_SECRET: string,
    A0ST_COMMON_TENANT_BASE_URL: string,
    VUE_APP_INTEGRITY_API_URL: string,
  } = undefined;

  async getAuth0Configs(): Promise<any> {
    if (this.auth0Object !== undefined) return this.auth0Object;
    if (typeof fetch === 'function') {
      try {
        const test = await fetch('/config.json');
        const text = await test.text();
        const {
          VUE_APP_INTEGRITY_AUTH0,
          A0ST_UI_CLIENT_ID,
          A0ST_UI_CLIENT_SECRET,
          A0ST_UI_AUDIENCE,
          A0ST_AUDIENCE,
          A0ST_COMMON_TENANT_NAME,
          A0ST_UI_M2M_CLIENT_ID,
          A0ST_UI_M2M_CLIENT_SECRET,
          A0ST_COMMON_TENANT_BASE_URL,
          VUE_APP_INTEGRITY_API_URL,
        } = JSON.parse(text);
        if (VUE_APP_INTEGRITY_AUTH0 && VUE_APP_INTEGRITY_AUTH0 !== '') {
          this.auth0Object = {
            VUE_APP_INTEGRITY_AUTH0,
            A0ST_UI_CLIENT_ID,
            A0ST_UI_CLIENT_SECRET,
            A0ST_UI_AUDIENCE,
            A0ST_AUDIENCE,
            A0ST_COMMON_TENANT_NAME,
            A0ST_UI_M2M_CLIENT_ID,
            A0ST_UI_M2M_CLIENT_SECRET,
            A0ST_COMMON_TENANT_BASE_URL,
            VUE_APP_INTEGRITY_API_URL,
          };
          return this.auth0Object;
        }
      } catch (e) {
        console.warn(e);
      }
    }
    return {};
  }

  async setEmail(id: string, email: string): Promise<string> {
    if (!id) return 'No id';
    if (!email) return 'No email';
    const body = {
      email,
      connection: 'Username-Password-Authentication',
    };
    await this.getNewBearer();
    const result = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/users/${id}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${this.newBearer}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return result.ok != null ? result.ok.toString() : result.status.toString();
  }

  async getUser(): Promise<User> {
    if (this.user?.id != null) {
      return this.user;
    }
    let returnUser: User | undefined;
    new Promise((r) => setTimeout(r, 10000)).then(() => {
      if (returnUser?.id == null) {
        this.hasIssuesGettingUser = true;
      }
    }); // 10 Seconds timeout for the user call
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const auth0Promise = this.auth0Client?.getUser().then((value) => {
      returnUser = new User(value);
    }).catch(() => {
      this.hasIssuesGettingUser = true;
    });
    while (!this.hasIssuesGettingUser && returnUser === undefined) {
      // Wait .5 seconds to check if we timed out or have a user.
      await new Promise((r) => setTimeout(r, 500));
    }
    return returnUser;
  }

  async getAllUsers(): Promise<Array<DetailedUser>> {
    const retVal = [] as DetailedUser[];
    let getNextPage = true;
    let i = 0;

    await this.getNewBearer();

    while (getNextPage && i < 10) {
      const auth0Configs = await this.getAuth0Configs();
      const result = await fetch(`${auth0Configs.A0ST_COMMON_TENANT_BASE_URL}/api/v2/users?page=${i}&per_page=100`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.newBearer}`,
        },
      });
      const res = await result.json();

      // only get the next page if the current results reach the max page limit
      getNextPage = res.length === 100;
      res.forEach(async (r) => retVal.push(await this.parseDetailedUser(r)));
      i += 1;
    }

    return retVal;
  }

  async getRoles(id: string): Promise<Array<string>> {
    if (!id) return [];
    await this.getNewBearer();
    const result = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/users/${id}/roles`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.newBearer}`,
      },
    });
    if (!result.ok) {
      if (result.status === 429) {
        throw new Error('No Role Exists');
      }
    }
    if (result != null) {
      return result.json().then((res) => {
        if (!Array.isArray(res)) return [];
        const arr: Array<string> = [];
        res.forEach((element) => {
          arr.push(element['id']);
        });
        this.roles = arr;
        return arr;
      });
    }
    // if result is undefined, this should only happen in tests
    return [];
  }

  async getRole(id: string): Promise<string> {
    if (!id) return '';
    await this.getNewBearer();
    const result = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/users/${id}/roles`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.newBearer}`,
      },
    });
    if (!result.ok) {
      if (result.status === 429) {
        throw new Error('No Role Exists');
      }
    }
    if (result != null) {
      return result.json().then((res) => {
        if (!Array.isArray(res)) return '';
        return res[0]['name'];
      });
    }
    // if result is undefined, this should only happen in tests
    return '';
  }

  // TODO this takes too long to get around rate limitting
  async getAllRolePermissions(): Promise<any> {
    const retVal = {} as any[];
    for (let i = 0; i < AuthUserRoles.length; i += 1) {
      const userRole = AuthUserRoles[i];

      // wait so we don't get rate limited
      await new Promise((resolve) => setTimeout(resolve, 500));
      const t = await this.getRolePermission(userRole.id);
      retVal[userRole.name] = t;
    }

    return retVal;
  }

  async getRolePermission(roleId: string): Promise<any> {
    if (!roleId) return [];
    const retVal = {};
    try {
      await this.getNewBearer();
      const perPage = 100;
      let len = perPage;
      let i = 0;
      while (len >= perPage) {
        len = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/roles/${roleId}/permissions?page=${i}&per_page=${perPage}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.newBearer}`,
          },
        }).then(((result) => result
          .json()
          .then(async (res) => {
            res.forEach(async (r) => {
              const { category, description } = this.parsePermissionData(r);
              if (!(category in retVal)) {
                retVal[category] = [];
              }
              retVal[category].push(description);
            });
            return res.length;
          })));
        // wait so we don't get rate limited
        await new Promise((resolve) => setTimeout(resolve, 500));
        i += 1;
      }
    } catch (error) {
      console.error(error);
    }

    return retVal;
  }

  async getRoleInfo(): Promise<any> {
    try {
      await this.getNewBearer();
      const results = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/roles`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.newBearer}`,
        },
      });
      const resJson = await results.json();
      return resJson;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  parsePermissionData(roleData: any): any {
    const category = roleData['permission_name'].split(':')[0];

    return {
      category,
      description: roleData['description'],
    };
  }

  async getAllUserRoles(): Promise<Array<RoleUser>> {
    const returnValue: Array<RoleUser> = [];
    const roles = [
      CUSTOMER,
      SUPER_USER,
      SYS_ADMIN_AUTH0,
      PROJECT_MANAGER,
      REPORTING_TECH_AUTH0,
      CREW_LEAD_AUTH0,
      CREW_MEMBER,
      CUSTOMER_SUPPORT,
      DEMO_USER_READ_ONLY,
      DEMO_USER_WRITE,
      OPERATIONS_MANAGER,
      REPORTING_CONTRACTOR,
      REPORTING_MANAGER,
      SUPERVISOR,
      SYSTEM_OWNER,
    ];
    const searchParams = new URLSearchParams({
      take: '100',
    });
    const auth0Configs = await this.getAuth0Configs();

    for (let i = 0; i < roles.length; i += 1) {
      const role = roles[i];

      await fetch(`${auth0Configs.A0ST_COMMON_TENANT_BASE_URL}/api/v2/roles/${role}/users?${searchParams}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.newBearer}`,
          Accept: 'application/json',
        },
      }).then(async (result) => {
        const parsedResult = await result.json();

        if (Array.isArray(parsedResult.users)) {
          parsedResult.users.forEach((user) => {
            returnValue.push({
              userId: user.user_id,
              picture: user.picture,
              name: user.name,
              email: user.email,
              role,
            });
          });
        }

        if (parsedResult.next !== null && parsedResult.next !== undefined) {
          let { next } = parsedResult;

          while (next !== null && next !== undefined) {
            const response = await fetch(`${auth0Configs.A0ST_COMMON_TENANT_BASE_URL}/api/v2/roles/${role}/users?take=100&from=${next}`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${this.newBearer}`,
                Accept: 'application/json',
              },
            });
            const data = await response.json();

            if (Array.isArray(data.users)) {
              data.users.forEach((user) => {
                returnValue.push({
                  userId: user.user_id,
                  picture: user.picture,
                  name: user.name,
                  email: user.email,
                  role,
                });
              });
            }

            next = data.next;
          }
        }
      });
      // Timeout between requests to avoid Too Many Requests error
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    return returnValue;
  }

  async addRoles(id: string, roleArray: string[]): Promise<string> {
    if (!id) return 'No id';
    if (roleArray.length === 0) return 'No Roles selected';
    const body = { roles: roleArray };
    await this.getNewBearer();
    // delete current roles
    const result = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/users/${id}/roles`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.newBearer}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (result != null) {
      return result.ok.toString();
    }
    // if result is undefined, this should only happen in tests
    return '';
  }

  async deleteRoles(id: string, roleArray: string[]): Promise<string> {
    if (!id) return 'No id';
    if (roleArray.length === 0) return 'No Roles selected';
    const body = { roles: roleArray };
    await this.getNewBearer();
    // delete current roles
    const result = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/users/${id}/roles`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.newBearer}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (result != null) {
      return result.ok.toString();
    }
    // if result is undefined, this should only happen in tests
    return '';
  }

  async sendPasswordReset(email: string): Promise<void> {
    const body = {
      email,
      connection: 'Username-Password-Authentication',
    };
    const authUrl = `${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/dbconnections/change_password`;
    await fetch(authUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  async createUser(user: AdminUser, customPassword = ''): Promise<AdminUser> {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const passwordLength = 12;
    let password = customPassword;
    let userID = '';

    if (password === '') {
      for (let i = 0; i <= passwordLength; i += 1) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }
    }

    const body = {
      email: `${user.Email}`,
      given_name: `${user.Firstname}`,
      family_name: `${user.Lastname}`,
      name: `${user.Firstname} ${user.Lastname}`,
      nickname: `${user.Nickname}`,
      password: `${password}`,
      connection: 'Username-Password-Authentication',
      verify_email: false,
    };

    await this.getNewBearer();
    let result;
    // eslint-disable-next-line no-useless-catch
    try {
      result = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.newBearer}`,
        },
        body: JSON.stringify(body),
      });
    } catch (err) {
      throw err;
    }
    if (!result.ok) {
      if (result.status === 409) {
        throw new Error('User already exists in Auth0');
      }
    }
    await result.json().then((res) => {
      // eslint-disable-next-line no-param-reassign
      user.Useridentity = res.user_id;
      // eslint-disable-next-line no-param-reassign
      user.Picturelocation = res.picture;
      userID = res.identities[0].user_id;
    });

    if (user.Role) {
      const roleBody = { roles: [getRoleId(getRoleGuidString(user.Role))] };
      await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/users/auth0%7C${userID}/roles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.newBearer}`,
        },
        body: JSON.stringify(roleBody),
      });
    }
    return user;
  }

  async deleteUser(id: string): Promise<boolean> {
    if (!id) return false;
    await this.getNewBearer();
    const result = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/users/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.newBearer}`,
      },
    });
    return result.ok != null ? result.ok : false;
  }

  async deleteUserRole(role: string, id: string): Promise<boolean> {
    if (!id) return false;
    if (!role) throw new Error('Error: Auth0 role does not exist');
    const r = { roles: [role] };
    await this.getNewBearer();
    const result = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/users/${id}/roles`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.newBearer}`,
      },
      body: JSON.stringify(r),
    });
    return result.ok != null ? result.ok : false;
  }

  // eslint-disable-next-line class-methods-use-this
  async getNewBearer(): Promise<void> {
    if (this.newBearer !== '') {
      return;
    }
    const CLIENTCREDENTIALS = 'client_credentials';
    const CLIENTID = (await this.getAuth0Configs()).A0ST_UI_M2M_CLIENT_ID;
    const YOUR_CLIENT_SECRET = (await this.getAuth0Configs()).A0ST_UI_M2M_CLIENT_SECRET;
    const AUDIENCE = `${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/api/v2/`;

    if (fetch != null) {
      const result = await fetch(`${(await this.getAuth0Configs()).A0ST_COMMON_TENANT_BASE_URL}/oauth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `grant_type=${CLIENTCREDENTIALS
        }&client_id=${CLIENTID
        }&client_secret=${YOUR_CLIENT_SECRET
        }&audience=${AUDIENCE}`,
      });
      if (result != null) {
        await result.json().then((json) => { this.newBearer = json['access_token']; });
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async parseDetailedUser(res: any): Promise<DetailedUser> {
    const returnValue = {} as DetailedUser;
    returnValue.email = res.email != null ? res.email : '';
    returnValue.isEmailVerified = res.email_verified != null ? res.email_verified : false;
    returnValue.username = res.username != null ? res.username : '';
    returnValue.phoneNumber = res.phone_number != null ? res.phone_number : '';
    returnValue.isPhoneVerified = res.phone_verified != null ? res.phone_verified : false;
    returnValue.userId = res.user_id != null ? res.user_id : '';
    returnValue.createdAt = res.created_at != null ? res.created_at : '';
    returnValue.updatedAt = res.updated_at != null ? res.updated_at : '';

    const identitiesArr = [] as Array<UserIdentity>;
    if (res.identities != null && res.identities.length > 0) {
      res.identities.forEach((value) => {
        const arrayValue = {} as UserIdentity;
        arrayValue.connection = value.connection != null ? value.connection : '';
        arrayValue.userId = value.user_id != null ? value.user_id : '';
        arrayValue.provider = value.provider != null ? value.provider : '';
        arrayValue.isSocial = value.isSocial != null ? value.isSocial : false;
        identitiesArr.push(arrayValue);
      });
    }
    returnValue.identities = identitiesArr;

    returnValue.appMetadata = res.app_metadata != null ? res.app_metadata : {};
    returnValue.appMetadata = res.app_metadata != null ? res.app_metadata : {};
    returnValue.picture = res.picture != null ? res.picture : '';
    returnValue.name = res.name != null ? res.name : '';
    returnValue.nickname = res.nickname != null ? res.nickname : '';
    returnValue.multifactor = res.multifactor != null ? res.multifactor : [] as Array<string>;
    returnValue.lastIp = res.last_ip != null ? res.last_ip : '';
    returnValue.lastLogin = res.last_login != null ? res.last_login : '';
    returnValue.loginsCount = res.logins_count != null ? res.logins_count : 0;
    returnValue.blocked = res.blocked != null ? res.blocked : false;
    returnValue.givenName = res.given_name != null ? res.given_name : '';
    returnValue.familyName = res.family_name != null ? res.family_name : '';

    return returnValue;
  }

  /** Authenticates the user using a popup window */
  async loginWithPopup(o: PopupLoginOptions): Promise<void> {
    this.popupOpen = true;

    try {
      await this.auth0Client?.loginWithPopup(o);
    } catch (e) {
      console.error(e);
      this.error = e as Error;
    } finally {
      this.popupOpen = false;
    }

    this.user = await this.getUser();
    this.isAuthenticated = true;
  }

  /** Authenticates the user using the redirect method */
  loginWithRedirect(o: RedirectLoginOptions): Promise<void> | undefined {
    return this.auth0Client?.loginWithRedirect(o);
  }

  /** Returns all the claims present in the ID token */
  getIdTokenClaims(o: GetIdTokenClaimsOptions): Promise<IdToken> | undefined {
    return this.auth0Client?.getIdTokenClaims(o);
  }

  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTokenSilently(o: GetTokenSilentlyOptions): Promise<any> | undefined {
    return this.auth0Client?.getTokenSilently(o);
  }

  /** Gets the access token using a popup window */
  getTokenWithPopup(o: GetTokenWithPopupOptions): Promise<string> | undefined {
    return this.auth0Client?.getTokenWithPopup(o);
  }

  /** Logs the user out and removes their session on the authorization server */
  logout(o: LogoutOptions): any {
    return this.auth0Client?.logout(o);
  }

  /** Use this lifecycle method to instantiate the SDK client */
  async init(onRedirectCallback: RedirectCallback,
    redirectUri: string): Promise<void> {
    // Create a new instance of the SDK client using members of the given options object
    this.auth0Client = await createAuth0Client({
      domain: (await this.getAuth0Configs()).A0ST_COMMON_TENANT_NAME,
      client_id: (await this.getAuth0Configs()).A0ST_UI_CLIENT_ID,
      audience: `${(await this.getAuth0Configs()).A0ST_AUDIENCE}`,
      redirect_uri: redirectUri,
      httpTimeoutInSeconds: 7,
    });

    try {
      // If the user is returning to the app after authentication..
      if (
        window.location.search.includes('error=')
        || (window.location.search.includes('code=') && window.location.search.includes('state='))
      ) {
        // handle the redirect and retrieve tokens
        const { appState } = await this.auth0Client?.handleRedirectCallback()
          ?? { appState: undefined };

        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        onRedirectCallback(appState);
      }
    } catch (e) {
      console.error(e);
      this.error = e as Error;
    } finally {
      // Initialize our internal authentication state when the page is reloaded
      this.user = await this.getUser();
      this.isAuthenticated = await this.auth0Client?.isAuthenticated();
      this.user.role = await this.getRole(this.user.sub);
      await initPendo(this.user);
      this.loading = false;
    }
  }
}
