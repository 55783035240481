/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTree } from 'vuex';
import axios, { AxiosError } from 'axios';
import { RootState } from '../types';
import { PlanningMutations } from './mutations';
import { PlanningState } from './types';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum PlanningActions {
    FETCH_ASSIGNMENTS_DATA = 'FETCH_ASSIGNMENTS_DATA',
    PATCH_ASSIGNMENT = 'PATCH_ASSIGNMENT',
    DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT',
    EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT',
    GET_ASSIGNMENT_WORK_ORDER = 'GET_ASSIGNMENT_WORK_ORDER',
    PATCH_ASSIGNMENT_WORK_ORDER = 'PATCH_ASSIGNMENT_WORK_ORDER',
    FETCH_WORK_ORDER_CREW = 'FETCH_WORK_ORDER_CREW',
}

export const actions: ActionTree<PlanningState, RootState> = {

  async [PlanningActions.FETCH_ASSIGNMENTS_DATA]({ commit },
    { dateCutoff, taskTypeGuids, projectGuids }):
  Promise<void> {
    commit(PlanningMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAssignments(dateCutoff, taskTypeGuids, projectGuids);

      commit(PlanningMutations.SET_ASSIGNMENTS_DATA, response.data);
    } catch (e) {
      commit(PlanningMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(PlanningMutations.SET_LOADING_STATE, false);
    }
  },

  async [PlanningActions.PATCH_ASSIGNMENT]({ commit }, payload):
  Promise<void> {
    commit(PlanningMutations.SET_PATCH_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.patchAssignmentList(payload);

      commit(PlanningMutations.SET_PATCH_DATA, response.data);
    } catch (e) {
      commit(PlanningMutations.SET_PATCH_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(PlanningMutations.SET_PATCH_STATE, false);
    }
  },

  async [PlanningActions.DELETE_ASSIGNMENT]({ commit }, payload):
  Promise<void> {
    commit(PlanningMutations.SET_DELETE_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.deleteAssignment(payload);
    } catch (e) {
      throw Error('API Error occurred.');
    } finally {
      commit(PlanningMutations.SET_DELETE_STATE, false);
    }
  },

  async [PlanningActions.EDIT_ASSIGNMENT]({ commit }, payload):
  Promise<void> {
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.editAssignment(payload);
    } catch (e) {
      throw Error('API Error occurred.');
    }
  },

  async [PlanningActions.FETCH_WORK_ORDER_CREW]({ commit },
    workOrderGuids: string[]):
  Promise<void> {
    commit(PlanningMutations.SET_WORK_ORDER_CREW_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.fetchWorkOrderCrew(workOrderGuids);

      commit(PlanningMutations.SET_WORK_ORDER_CREW_DATA, response?.data?.data?.crew);
    } catch (e) {
      commit(PlanningMutations.SET_WORK_ORDER_CREW_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(PlanningMutations.SET_WORK_ORDER_CREW_LOADING, false);
    }
  },

  async [PlanningActions.GET_ASSIGNMENT_WORK_ORDER]({ commit }, payload):
  Promise<void> {
    commit(PlanningMutations.SET_ASSIGNMENT_WORK_ORDER_STATE, true);
    commit(PlanningMutations.SET_ASSIGNMENT_WORK_ORDER_ERROR, '');
    commit(PlanningMutations.SET_ASSIGNMENT_WORK_ORDER_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAssignmentDataStandards(payload);
      commit(PlanningMutations.SET_ASSIGNMENT_WORK_ORDER_DATA, response.data);
    } catch (e) {
      commit(PlanningMutations.SET_ASSIGNMENT_WORK_ORDER_ERROR, (e as Error).message);
    } finally {
      commit(PlanningMutations.SET_ASSIGNMENT_WORK_ORDER_STATE, false);
    }
  },

  async [PlanningActions.PATCH_ASSIGNMENT_WORK_ORDER]({ commit }, payload):
  Promise<void> {
    commit(PlanningMutations.SET_PATCH_ASSIGNMENT_WORK_ORDER_STATE, true);
    commit(PlanningMutations.SET_PATCH_ASSIGNMENT_WORK_ORDER_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.patchAssignmentDataStandards(payload.guid, payload.payload);

      commit(PlanningMutations.SET_PATCH_ASSIGNMENT_WORK_ORDER_DATA, response.data);
    } catch (e) {
      if (!axios.isAxiosError(e)) {
        commit(PlanningMutations.SET_PATCH_ASSIGNMENT_WORK_ORDER_ERROR, 'Generic Error');
      }
      const error = (e as AxiosError);
      if (error.response?.data?.Message == null) {
        commit(PlanningMutations.SET_PATCH_ASSIGNMENT_WORK_ORDER_ERROR, 'An error occured while patching the work order form.');
      } else {
        commit(PlanningMutations.SET_PATCH_ASSIGNMENT_WORK_ORDER_ERROR,
          error.response?.data?.Message);
      }
      // throw Error('API Error occurred.');
    } finally {
      commit(PlanningMutations.SET_PATCH_ASSIGNMENT_WORK_ORDER_STATE, false);
    }
  },

};
