import { MutationTree } from 'vuex';
import { DefectCodingModel, DefectCodingState } from './types';

// eslint-disable-next-line no-shadow
export enum DefectCodingMutations {
  SET_DEFECT_CODING_LOADING_STATE = 'SET_DEFECT_CODING_LOADING_STATE',
  SET_DEFECT_CODING_DATA = 'SET_DEFECT_CODING_DATA',
  SET_DEFECT_CODING_LOAD_ERROR = 'SET_LOAD_ERROR',
  SET_DEFECT_CODING_POST_ERROR = 'SET_DEFECT_CODING_POST_ERROR',
  SET_DEFECT_CODING_POST_LOADING = 'SET_DEFECT_CODING_POST_LOADING',
  SET_DEFECT_DELETE_ERROR = 'SET_DEFECT_DELETE_ERROR',
  SET_DEFECT_DELETE_LOADING = 'SET_DEFECT_DELETE_LOADING',
  SET_DEFECT_PATCH_ERROR = 'SET_DEFECT_PATCH_ERROR',
  SET_DEFECT_PATCH_LOADING = 'SET_DEFECT_PATCH_LOADING',
  SET_VALIDATE_AND_SCORE_ERROR = 'SET_VALIDATE_AND_SCORE_ERROR',
  SET_VALIDATE_AND_SCORE_LOADING = 'SET_VALIDATE_AND_SCORE_LOADING',
  SET_CONFIRM_SCORE_RESET = 'SET_CONFIRM_SCORE_RESET',
}

export const mutations: MutationTree<DefectCodingState> = {
  [DefectCodingMutations.SET_DEFECT_CODING_DATA](state, payload: Array<DefectCodingModel>) {
    state.defectCodingData = payload;
  },
  [DefectCodingMutations.SET_DEFECT_CODING_LOADING_STATE](state, payload: boolean) {
    state.defectCodingLoading = payload;
  },
  [DefectCodingMutations.SET_DEFECT_CODING_LOAD_ERROR](state, payload: string) {
    state.defectCodingLoadError = payload;
  },
  [DefectCodingMutations.SET_DEFECT_CODING_POST_ERROR](state, payload: string) {
    state.defectCodingPostError = payload;
  },
  [DefectCodingMutations.SET_DEFECT_CODING_POST_LOADING](state, payload: boolean) {
    state.defectCodingPostLoading = payload;
  },
  [DefectCodingMutations.SET_DEFECT_DELETE_ERROR](state, payload: string) {
    state.defectCodingDeleteError = payload;
  },
  [DefectCodingMutations.SET_DEFECT_DELETE_LOADING](state, payload: boolean) {
    state.defectCodingDeleteLoading = payload;
  },
  [DefectCodingMutations.SET_DEFECT_PATCH_ERROR](state, payload: string) {
    state.defectCodingPatchError = payload;
  },
  [DefectCodingMutations.SET_DEFECT_PATCH_LOADING](state, payload: boolean) {
    state.defectCodingPatchLoading = payload;
  },
  [DefectCodingMutations.SET_VALIDATE_AND_SCORE_ERROR](state, payload: string) {
    state.validateAndScoreError = payload;
  },
  [DefectCodingMutations.SET_VALIDATE_AND_SCORE_LOADING](state, payload: boolean) {
    state.validateAndScoreLoading = payload;
  },
  [DefectCodingMutations.SET_CONFIRM_SCORE_RESET](state, payload: boolean) {
    state.confirmScoreReset = payload;
  },
};
