import { MutationTree } from 'vuex';
import { WallLossData, WallLossState } from './types';

// eslint-disable-next-line no-shadow
export enum WallLossMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_WALL_LOSS_DATA = 'SET_WALL_LOSS_DATA',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
}

export const mutations: MutationTree<WallLossState> = {
  [WallLossMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
  [WallLossMutations.SET_WALL_LOSS_DATA](state, payload: WallLossData) {
    state.wallLossData = payload;
  },
  [WallLossMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
};
