var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reportingPage"},[_c('div',{attrs:{"id":"page-title"}},[_vm._v("Reporting Dashboard")]),_c('div',{staticClass:"page-header"},[_c('div',{attrs:{"id":"reporting-table"}},[_vm._v("TO DO LIST")]),_c('v-text-field',{staticClass:"search-bar",attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('IntegrityTable',{attrs:{"data":_vm.readyForCodingAssignmentData,"headers":_vm.headers,"matchFilters":_vm.matchFilters,"filterValues":_vm.filterValues,"search":_vm.search,"tableUniqueKey":'inspectionGuid',"height":'calc(100vh - 218px)',"tableID":'reporting-table'},on:{"update:data":function($event){_vm.readyForCodingAssignmentData=$event},"update:headers":function($event){_vm.headers=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event},"update:filterValues":function($event){_vm.filterValues=$event},"update:filter-values":function($event){_vm.filterValues=$event}},scopedSlots:_vm._u([{key:"projectName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.projectName)+" ")])]}},{key:"inspectionDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.processDate(item))+" ")])]}},{key:"guid",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.hasCustomerDeliverables)?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"tag":"button","href":_vm.$router.resolve({
              name: 'assetsList',
              params: _vm.getAssetParams(item),
            }).href}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }