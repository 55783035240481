/* eslint-disable no-eval */
export default class DateHelper {
  /**
   *
   * Checks if a a date is within a range of dates
   *
   * @param Date date
   * @param minDate the minimum date
   * @param maxDate the maximum date
   * @returns String format of date
   */
  static IsDateWithinRange(date: Date, minDate: Date, maxDate: Date): boolean {
    return date >= minDate && date <= maxDate;
  }

  /**
   *
   * Formats a date or datetime into a display string
   *
   * @param Date date
   * @param string format y-year, M-month, d-day, h-hour, m-minute, s-second
   * @returns String format of date
   */

  static Format(date: Date, format: string): string {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const z = {
      M: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      m: date.getMinutes(),
      s: date.getSeconds(),
    };

    // eslint-disable-next-line no-param-reassign
    format = format.replace(/(M+|d+|h+|m+|s+)/g, (v) => ((v.length > 1 ? '0' : '') + eval(`z.${v.slice(-1)}`)).slice(-2));

    return format.replace(/(y+)/g, (v) => date
      .getFullYear()
      .toString()
      .slice(-v.length));
  }
}
