













































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, VModel, Vue, Watch,
} from 'vue-property-decorator';

@Component({})
export default class IntegrityDateSelectTextBox extends Vue {
  @VModel() modelValue!: string | string[] | undefined

  @Prop({ default: false }) readonly range: boolean;

  @Prop({ default: 'Select Date' }) label: string;

  selectedDate = null as string | undefined;

  dateSelectMenu = false as boolean;

  @Watch('range')
  onRangeChange(): void {
    this.reset();
  }

  reset(): void {
    this.modelValue = null;
  }
}
