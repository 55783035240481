




































































import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { DetailedUserData } from '@/store/users/types';
import { PhoneInfo } from '@/components/IntegrityPhoneNumberInput/types';
import { OrganizationData } from '@/store/organizations/types';
import IntegrityPhoneNumberInput from '../../IntegrityPhoneNumberInput/IntegrityPhoneNumberInput.vue';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import NewUserPopup from '../NewUserPopup.vue';
import CustomerDialogSelectOrganization from './CustomerDialogSelectOrganization.vue';

@Component({
  components: {
    NewUserPopup,
    IntegrityPhoneNumberInput,
    CustomerDialogSelectOrganization,
  },
})
export default class CustomerEditDialog extends AdminPanelMixin {
  @PropSync('show') showDialog: boolean;

  @Prop() saveCustomerData;

  @Prop({ default: false }) customerLoading: boolean;

  @Prop() title: string;

  @Prop({
    default: () => ({
      customerName: '',
      address: '',
      systemOwner: null,
      email: '',
      phoneNumber: null,
      organization: null,
      sameAsOrganization: false,
    }),
  })
  editItem: {
    guid: string | undefined;
    customerName: string;
    address: string;
    systemOwner: DetailedUserData | undefined;
    email: string;
    phoneNumber: PhoneInfo | undefined;
    organization: OrganizationData | undefined;
    sameAsOrganization: boolean;
  };

  valid = false as boolean;

  @Watch('editItem.sameAsOrganization')
  onSameAsOrganizationChange(): void {
    this.setCustomerNameAsOrganizationName();
  }

  @Watch('editItem.organization')
  onOrganizationChange(): void {
    this.setCustomerNameAsOrganizationName();
  }

  /**
   * @description sets customer to organization name
   * @remarks only sets value if sameAsOrganization is true and there is an organization set
   */
  setCustomerNameAsOrganizationName(): void {
    if (this.editItem.sameAsOrganization && this.editItem.organization) {
      this.editItem.customerName = this.editItem.organization.name;
    }
  }

  /**
   * @description Calls endpoint to post or patch an customer and fetches new customer data.
   */
  async saveCustomer(): Promise<void> {
    const phoneCode = this.editItem.phoneNumber.isInternational
      ? `${this.editItem.phoneNumber.phoneCode} `
      : '';

    const customerSaveObject = {
      guid: this.editItem.guid,
      name: this.editItem.customerName,
      address: this.editItem.address,
      systemOwnerGuid: this.editItem.systemOwner.guid,
      email: this.editItem.email,
      phoneNumber: `${phoneCode}${this.editItem.phoneNumber.phone}`,
      organizationGuid: this.editItem.organization.guid,
    };

    await this.saveCustomerData(customerSaveObject);
    await this.fetchCustomerData();
    this.$emit('customerSaved', customerSaveObject);
    this.showDialog = false;
  }
}
