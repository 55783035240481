





























































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import { InspectionData } from '@/store/asset/types';
import Ajv2019 from 'ajv/dist/2019';
import {
  Component, Prop, Watch, Vue,
} from 'vue-property-decorator';
import addFormats from 'ajv-formats';
import {
  CodingFormData,
  ConditionalRequirement,
  ConditionalType,
  FormPageData,
} from './types';

// Need to import like this in order to not get a circular import error
@Component({
  components: { FormPage: () => import('./FormPage/FormPage.vue') },
})
export default class CodingTableForm extends Vue {
  @Prop() tableItem: FormPageData;

  @Prop() codingForm: CodingFormData;

  @Prop() dataStandardSchema: any;

  @Prop({ default: null }) inspection!: InspectionData;

  @Prop() dataStandardJson: any;

  @Prop() readonly canEdit: boolean;

  dialog = false;

  dialogDelete = false;

  editedIndex = -1;

  editedItem = {};

  defaultItem = {};

  checkBoxHeaderNumbers = [];

  get headers(): any[] {
    const headerData = this.currentFormPageData.map((data) => ({
      text: data.description,
      value: data.headername,
    }));
    headerData.push({ text: 'Actions', value: 'actions', sortable: false });
    return headerData;
  }

  get formTitle(): string {
    return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
  }

  mounted(): void {
    this.initialize();
  }

  get currentFormPageData(): any {
    return this.tableItem?.items ?? [];
  }

  get tableData(): any {
    if (!this.codingForm?.value) {
      return [];
    }

    const itemFormats = {};
    this.tableItem.items.forEach((i) => {
      if (i.format) {
        itemFormats[i.headername] = i.format;
      }
    });

    const filteredFormats = Object.keys(itemFormats);
    const formattedTableData = (this.codingForm.value as any[]).map((cf) => {
      const cfCopy = { ...cf };
      filteredFormats.forEach((format) => {
        const formatType = itemFormats[format];
        if (formatType === 'date-time') {
          cfCopy[format] = cfCopy[format].slice(0, 10);
        }
      });
      return cfCopy;
    });

    return formattedTableData;
  }

  initialize(): void {
    this.currentFormPageData.forEach((data) => {
      this.editedItem[data.headername] = '';
      this.defaultItem[data.headername] = '';
    });

    this.requiredFieldObjects = this.findRequiredFields(
      this.dataStandardSchema,
    );

    // Push enum data
    this.tableItem.items.forEach((i) => {
      if (i.InputType === 'Dropdown') {
        const schema = this.dataStandardSchema.properties[i.headername];
        i.enum = schema.enum;
      }
    });

    // Ajv throws an error if we try to add formats if they already exist
    if (Object.keys(this.ajv.formats).length === 0) {
      addFormats(this.ajv);
    }
    this.validate = this.ajv.compile(this.dataStandardSchema);

    this.validateForm();
  }

  editItem(item) {
    this.editedIndex = this.tableData.indexOf(item);
    this.editedItem = (this.codingForm.value as any[])[this.editedIndex];
    this.dialog = true;
  }

  deleteItem(item) {
    this.editedIndex = this.tableData.indexOf(item);
    this.editedItem = (this.codingForm.value as any[])[this.editedIndex];
    this.dialogDelete = true;
  }

  deleteItemConfirm(): void {
    (this.codingForm.value as any[]).splice(this.editedIndex, 1);
    this.closeDelete();
  }

  close(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
    });
  }

  closeDelete(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
    });
  }

  save(): void {
    const dataForTable = {};
    this.currentFullCodingForm.forEach((data) => {
      dataForTable[data.header] = data.value;
    });

    if (!Array.isArray(this.codingForm.value)) {
      this.codingForm.value = [];
    }

    if (this.editedIndex > -1) {
      Object.assign(this.codingForm.value[this.editedIndex], dataForTable);
    } else {
      this.codingForm.value.push(dataForTable);
    }
    this.close();
  }

  @Watch('dialog')
  onDialogChange(): void {
    if (!this.dialog) this.close();
  }

  @Watch('dialogDelete')
  onDialogDeleteChange(): void {
    if (!this.dialogDelete) this.closeDelete();
  }

  get codingFormValidBool(): boolean {
    return this.errors.length > 0;
  }

  get currentFullCodingForm(): any {
    return this.currentFormPageData.map((data, i) => ({
      header: data.headername,
      value: this.editedItem[data.headername] ?? '',
      isChanged: false,
      headerNumber: data.headernumber,
    }));
  }

  // TODO Combine this with CodingForm.vue to not duplicate code

  ajv = new Ajv2019({
    strict: false,
    strictSchema: false,
    strictTypes: false,
    strictRequired: false,
    strictNumbers: false,
    validateFormats: false,
    useDefaults: 'empty',
    allErrors: true,
    multipleOfPrecision: 6,
    unicodeRegExp: false,
  });

  errors = [];

  requiredFieldObjects: ConditionalRequirement;

  ALWAYSREQUIRED = 'alwaysRequired';

  validate = null;

  // Field names for required fields
  requiredFields = new Map<string, boolean>();

  findRequiredFields(schema: any): ConditionalRequirement {
    if (schema?.required) {
      return {
        conditionalField: this.ALWAYSREQUIRED,
        comparerValue: true,
        requiredOnThen: schema.required as string[],
        // everything else can be empty
        requiredOnElse: [],
        conditionalsOnElse: [],
        conditionalsOnThen: [],
        optionalOnElse: [],
        optionalOnThen: [],
      };
    }
    return this.findRequiredFieldsRecursive(schema);
  }

  findRequiredFieldsRecursive(schema: any): ConditionalRequirement {
    if (schema == null) return null;
    const isOf = this.checkForOf(schema);
    if (isOf) {
      return {
        conditionalField: this.ALWAYSREQUIRED,
        comparerValue: true,
        conditionalsOnThen: (() => {
          const retVal: ConditionalRequirement[] = [];
          schema[isOf].forEach((element) => {
            const newRequirement = this.findRequiredFieldsRecursive(element);
            if (newRequirement != null) retVal.push(newRequirement);
          });
          return retVal;
        })(),
        // everything else can be empty
        requiredOnElse: [],
        requiredOnThen: [],
        conditionalsOnElse: [],
        optionalOnElse: [],
        optionalOnThen: [],
      };
    }
    const conditionalRequirement: ConditionalRequirement = {
      conditionalField: '',
      comparerValue: '',
      requiredOnElse: [],
      conditionalsOnElse: [],
      requiredOnThen: [],
      conditionalsOnThen: [],
      optionalOnElse: [],
      optionalOnThen: [],
    };
    // check for property and const or enum
    if (schema.if) {
      // eslint-disable-next-line prefer-destructuring
      const propName = Object.entries(schema.if.properties)[0][0];
      const path = schema.if.properties[propName];
      conditionalRequirement.conditionalField = propName;
      // eslint-disable-next-line prefer-destructuring
      conditionalRequirement.comparerValue = Object.entries(path)[0][1] as any;
    }
    if (schema.else) {
      if (schema.else.required) {
        conditionalRequirement.requiredOnElse = schema.else.required;
      }
      const keys = Object.keys(schema.else);
      keys.forEach((key) => {
        if (ConditionalType.includes(key)) {
          conditionalRequirement.conditionalsOnElse.push(
            this.findRequiredFieldsRecursive(schema.else),
          );
        }
        if (key === 'properties') {
          Object.entries(schema.else.properties).forEach((entry) => {
            const property = this.checkProperties(entry);
            if (property) {
              conditionalRequirement.optionalOnElse.push(property);
            }
          });
        }
      });
    }
    if (schema.then) {
      if (schema.then.required) {
        conditionalRequirement.requiredOnThen = schema.then.required;
      }
      const keys = Object.keys(schema.then);
      keys.forEach((key) => {
        if (ConditionalType.includes(key)) {
          conditionalRequirement.conditionalsOnThen.push(
            this.findRequiredFieldsRecursive(schema.then),
          );
        }
        if (key === 'properties') {
          Object.entries(schema.then.properties).forEach((entry) => {
            const property = this.checkProperties(entry);
            if (property) {
              conditionalRequirement.optionalOnThen.push(property);
            }
          });
        }
      });
    }
    return conditionalRequirement;
  }

  // return property name when minlength is 0 or type includes null
  checkProperties(entry: any): string | undefined {
    if (entry[1].minLength !== undefined || entry[1].type !== undefined) {
      if (entry[1].minLength === 0) {
        return entry[0];
      }
      if (Array.isArray(entry[1].type) && entry[1].type.includes('null')) {
        return entry[0];
      }
    }
    return undefined;
  }

  checkForOf(schema: any): string | null {
    if (schema.allOf) {
      return 'allOf';
    }
    if (schema.anyOf) {
      return 'anyOf';
    }
    return null;
  }

  applyRequiredFields(conditionalRequirement: ConditionalRequirement): void {
    // clear required fields
    this.requiredFields.clear();
    this.currentFormPageData.forEach((fpd) => {
      fpd.required = false;
    });
    this.applyRequiredFieldsRecursive(conditionalRequirement);
    // apply required to current form page
    this.requiredFields.forEach((value, requiredField) => {
      const field = this.currentFormPageData.find(
        (fpd) => fpd.headername === requiredField,
      );
      if (field !== undefined) {
        field.required = value;
      }
    });
  }

  applyRequiredFieldsRecursive(
    conditionalRequirement: ConditionalRequirement,
  ): void {
    let conditionalMet = false;
    const { conditionalField, comparerValue } = conditionalRequirement;
    // find field value
    const fieldValue = conditionalField === this.ALWAYSREQUIRED
      ? true
      : this.currentFullCodingForm.find(
        (cf) => cf.header === conditionalField,
      )?.value;

    // compare to comparer values
    // skip if field is undefined
    if (fieldValue !== undefined) {
      // see if comparer value is valid depending on the conditional type
      // have to check it differently if it's an array
      if (Array.isArray(comparerValue)) {
        if (comparerValue.some((cv) => cv === (fieldValue as string))) {
          conditionalMet = true;
        }
      } else if (comparerValue === fieldValue) {
        conditionalMet = true;
      }
      // true, process on thens
      if (conditionalMet) {
        const { requiredOnThen, conditionalsOnThen, optionalOnThen } = conditionalRequirement;
        requiredOnThen.forEach((field) => {
          this.requiredFields.set(field, true);
        });
        optionalOnThen.forEach((field) => {
          this.requiredFields.set(field, false);
        });
        conditionalsOnThen.forEach((conditional) => {
          this.applyRequiredFieldsRecursive(conditional);
        });
      }
      // false, process on elses
      if (!conditionalMet) {
        const { requiredOnElse, conditionalsOnElse, optionalOnElse } = conditionalRequirement;
        requiredOnElse.forEach((field) => {
          this.requiredFields.set(field, true);
        });
        optionalOnElse.forEach((field) => {
          this.requiredFields.set(field, false);
        });
        conditionalsOnElse.forEach((conditional) => {
          this.applyRequiredFieldsRecursive(conditional);
        });
      }
    }
  }

  applyHintFields(): void {
    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for hints
      if (
        this.dataStandardJson?.Requirements
        && Object.keys(this.dataStandardJson.Requirements.HeaderValues).includes(
          form.headername,
        )
      ) {
        form.hint = this.dataStandardJson.Requirements.HeaderValues[form.headername];
      }
    });
  }

  formDataArrayToObject(codingForm: any, mainInspection: boolean): any {
    if (!codingForm) {
      return false;
    }
    const retVal = codingForm.reduce(
      (a, v) => ({ ...a, [v.header]: v.value }),
      {},
    );
    return retVal;
  }

  autoFillAutoProcessedFields(): void {
    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for hints
      if (form.hint != null && form.hint !== '') {
        const splitHint = form.hint.split(' ');
        const item = this.currentFullCodingForm.find(
          (f) => f.header === form.headername,
        );
        const val1 = this.currentFullCodingForm.find(
          (f) => f.header === splitHint[0],
        ).value;
        const val2 = this.currentFullCodingForm.find(
          (f) => f.header === splitHint[2],
        ).value;
        const operator = splitHint[1];
        switch (operator) {
          case '+':
            item.value = (val1 as number) + (val2 as number);
            break;
          case '-':
            item.value = (val1 as number) - (val2 as number);
            break;
          default:
            throw new Error('Invalid operator');
        }
      }
    });
  }

  validateForm(): void {
    this.applyRequiredFields(this.requiredFieldObjects);
    this.applyHintFields();
    this.autoFillAutoProcessedFields();
    const valid = this.validate(
      this.formDataArrayToObject(this.currentFullCodingForm, true),
    );
    if (!valid && this.validate.errors != null) {
      this.errors = this.validate.errors;
    } else {
      this.errors = [];
    }
  }
}
