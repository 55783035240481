import { MutationTree } from 'vuex';
import { UserPermission, UserPermissionsState } from './types';

// eslint-disable-next-line no-shadow
export enum UserPermissionsMutations {
    SET_PERMISSIONS = 'SET_PERMISSIONS',
    SET_LOADING = 'SET_LOADING',
    SET_ERROR = 'SET_ERROR',
}

export const mutations : MutationTree<UserPermissionsState> = {
  [UserPermissionsMutations.SET_PERMISSIONS](state, payload: UserPermission[]) {
    state.permissions = payload;
  },
  [UserPermissionsMutations.SET_LOADING](state, payload: boolean) {
    state.loading = payload;
  },
  [UserPermissionsMutations.SET_ERROR](state, payload: string | undefined) {
    state.error = payload;
  },
};
