import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { mutations } from './mutations';
import { UserPermissionsState } from './types';

const state : UserPermissionsState = {
  permissions: [],
  loading: false,
  error: undefined,
};

const integrityPermissions: Module<UserPermissionsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default integrityPermissions;
