










import { TASK_RESULT_DETAILS } from '@/common/Constants';
import { DeploymentData, EditFieldObject } from '@/store/deployments/types';
import { ProjectData } from '@/store/project/types';
import { DetailedUserData } from '@/store/users/types';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EditDeploymentForm from './EditDeploymentForm.vue';

const userModule = namespace('users');
const projectModule = namespace('project');
@Component({ components: { EditDeploymentForm } })
export default class DeploymentEditMHVertue extends Vue {
  @userModule.State('allDetailedUserData') allUsers: DetailedUserData[];

  @userModule.State('loading') allUsersLoading: boolean;

  @projectModule.State('data') project: ProjectData | undefined;

  @projectModule.State('loading') projectLoading: boolean;

  @Prop() readonly deployment: DeploymentData;

  @PropSync('isOpen') showDialog: boolean;

  get editFields(): EditFieldObject[] {
    return [
      {
        name: 'Asset',
        value: '',
        inputType: 'combo',
        desc: 'name',
        options: this.manholeAssets,
      },
      {
        name: 'Date Inspected',
        value: '',
        inputType: 'date',
        desc: 'date',
      },
      {
        name: 'Result',
        value: '',
        inputType: 'select',
        desc: 'result',
        options: TASK_RESULT_DETAILS.map((tr) => tr.description),
      },
      {
        name: 'Additional Information',
        value: '',
        inputType: 'text',
        desc: 'additionalInfo',
      },
      {
        name: 'Operator',
        value: '',
        inputType: 'select',
        desc: 'operator',
        options: this.allUserNames,
      },
      {
        name: 'MACP?',
        value: '',
        inputType: 'boolean',
        desc: 'macp',
        options: [
          {
            label: 'Yes',
            value: 'Yes',
          },
          {
            label: 'No',
            value: 'No',
          },
        ],
      },
    ];
  }

  get allUserNames(): string[] {
    if (!this.allUsers) {
      return [];
    }
    const formattedUsers = this.allUsers.map((u) => `${u.firstname} ${u.lastname}`);

    if (this.deployment && !formattedUsers.includes(this.deployment.operator)) {
      formattedUsers.push(this.deployment.operator);
    }

    return formattedUsers.sort();
  }

  get manholeAssets(): string[] {
    if (!this.project) {
      return [];
    }
    return this.project?.assets.filter((asset) => asset.type === 'Manhole').map((asset) => asset.name).sort();
  }

  updateDeploymentData(deploymentUpdate: DeploymentData): void {
    const newDeployment = { ...deploymentUpdate };
    newDeployment.macp = deploymentUpdate.macp ? 'Yes' : 'No';
    this.$emit('updateDeploymentData', newDeployment);
  }
}
