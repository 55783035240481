











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Doughnut as DoughnutGenerator } from 'vue-chartjs/legacy';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartData,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Roboto';

@Component({ components: { DoughnutGenerator } })
export default class Doughnut extends Vue {
  @Prop({ default: 'label' }) readonly datasetIdKey: string;

  @Prop() readonly chartData: ChartData | undefined;

  @Prop({ default: undefined }) readonly height: number | undefined;

  chartId = 'lineChart';

  chartClass = 'line-chart';

  plugins = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get chartOptions(): any {
    return {
      responsive: true,
      maintainAspectRatio: false,
    };
  }
}

