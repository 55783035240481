import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { mutations } from './mutations';
import { SnackBarState } from './types';

const state : SnackBarState = {
  snackBarMessage: {
    message: '',
    color: 'success',
    showSnackBar: false,
  },
  snackBarError: '',
};

const integritySnackBar: Module<SnackBarState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default integritySnackBar;
