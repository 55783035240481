










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
})
export default class Error extends Vue {
  @Prop() readonly message!: string;
}
