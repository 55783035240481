import { camelCase } from 'lodash';

export default class User {
  sub: string;

  name: string;

  names: string;

  nickname: string;

  picture: string;

  updatedAt: string;

  email: string;

  emailVerified: boolean;

  provider?: string;

  id?: string;

  givenName?: string;

  familyName?: string;

  locale?: string;

  role?: string;

  [key: string]: string | boolean | undefined

  constructor(auth0User: { [key: string]: string | boolean | undefined } | undefined) {
    this.name = '';
    this.names = '';
    this.sub = '';
    this.nickname = '';
    this.picture = '';
    this.updatedAt = '';
    this.email = '';
    this.emailVerified = false;

    if (!auth0User) return;

    Object.keys(auth0User).forEach((key) => {
      this[camelCase(key)] = auth0User[key];
    });

    this.sub = auth0User.sub as string;
    const provider = this.sub.split('|')[0];
    const id = this.sub.split('|')[1];

    this.provider = provider;
    this.id = id;
  }
}
