








































































































import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';

@Component
export default class DataReport extends Vue {
  selectedTab = 0 as number;

  showSnackbar = false as boolean;

  showExpandedImage = false as boolean;

  expandedImageUrl = '' as string;

  @Prop(Object) readonly report: Record<string, unknown> | undefined;

  @Prop(String) readonly assetName: string | undefined;

  expandImage(url: string): void {
    this.expandedImageUrl = url;
    this.showExpandedImage = true;
  }

  closeExpandedImage(): void {
    this.showExpandedImage = false;
  }

  downloadReport(): void {
    this.showReport('Test');
  }

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  showReport(reportType: string) : string {
    return reportType;
  }
}
