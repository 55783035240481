import { ActionTree } from 'vuex';
import { RootState } from '../types';
import storeUtility from '../utils';
import { ReleasesMutations } from './mutations';
import { ReleasesState } from './types';

// eslint-disable-next-line no-shadow
export enum ReleasesActions {
  POST_RELEASE_INSPECTION = 'POST_RELEASE_INSPECTION',
  PATCH_RELEASE_INSPECTION = 'PATCH_RELEASE_INSPECTION',
  FETCH_RELEASES_OVERVIEW = 'FETCH_RELEASES_OVERVIEW',
  FETCH_RELEASE_TABLE_DATA = 'FETCH_RELEASE_TABLE_DATA',
  GET_INSPECTION_RELEASES = 'GET_INSPECTION_RELEASES',
  POST_SEND_CSV_EMAIL = 'POST_SEND_CSV_EMAIL',
  DELETE_RELEASE = 'DELETE_RELEASE',
  DELETE_RELEASE_INSPECTION = 'DELETE_RELEASE_INSPECTION',
  POST_SEND_EXPORT_EMAIL = 'POST_SEND_EXPORT_EMAIL',
  POST_SEND_NOTIFICATION_EMAIL = 'POST_SEND_NOTIFICATION_EMAIL',
}

export const actions: ActionTree<ReleasesState, RootState> = {
  async [ReleasesActions.POST_RELEASE_INSPECTION]({ commit },
    payload: any): Promise<void> {
    commit(ReleasesMutations.SET_POST_RELEASE_INSPECTION_LOADING, true);
    commit(ReleasesMutations.SET_POST_RELEASE_INSPECTION_ERROR, undefined);
    commit(ReleasesMutations.SET_POST_RELEASE_INSPECTION, []);
    try {
      const formData = new FormData();
      payload.inspectionGuids.forEach((inspectionGuid, i) => {
        formData.append(`inspectionGuids[${i}]`, inspectionGuid);
      });
      formData.append('releaseName', payload.releaseName);
      formData.append('shouldExport', payload.shouldExport);
      formData.append('transmittalLetter', payload.transmittalLetter);
      payload.notificationRecipients.forEach((notificationRecipient, i) => {
        formData.append(`notificationRecipients[${i}]`, notificationRecipient);
      });
      formData.append('userEmail', payload.userEmail);
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postReleaseInspection(formData);
      commit(ReleasesMutations.SET_POST_RELEASE_INSPECTION, response.data.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ReleasesMutations.SET_POST_RELEASE_INSPECTION_ERROR,
          'Inspections could not be found.');
        throw new Error('API Error occurred. Inspections could not be found.');
      }
      commit(ReleasesMutations.SET_POST_RELEASE_INSPECTION_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ReleasesMutations.SET_POST_RELEASE_INSPECTION_LOADING, false);
    }
  },

  async [ReleasesActions.PATCH_RELEASE_INSPECTION]({ commit },
    payload: any): Promise<void> {
    commit(ReleasesMutations.SET_PATCH_RELEASE_INSPECTION_LOADING, true);
    commit(ReleasesMutations.SET_PATCH_RELEASE_INSPECTION_ERROR, undefined);
    commit(ReleasesMutations.SET_PATCH_RELEASE_INSPECTION, []);
    try {
      const formData = new FormData();
      formData.append('releaseGuid', payload.releaseGuid);
      formData.append('projectGuid', payload.projectGuid);
      formData.append('releaseName', payload.releaseName);
      if (payload.dateShipped) {
        formData.append('dateShipped', payload.dateShipped);
      }
      if (payload.trackingInformation) {
        formData.append('trackingInformation', payload.trackingInformation);
      }
      if (payload.transmittalLetter) {
        formData.append('transmittalLetter', payload.transmittalLetter);
      }
      payload.notificationRecipients.forEach((notificationRecipient, i) => {
        formData.append(`notificationRecipients[${i}]`, notificationRecipient);
      });
      formData.append('userEmail', payload.userEmail);

      const api = await storeUtility.useIntegrityAPI();
      const response = await api.patchReleaseInspection(formData);
      commit(ReleasesMutations.SET_PATCH_RELEASE_INSPECTION, response.data.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(ReleasesMutations.SET_PATCH_RELEASE_INSPECTION_ERROR,
          'Inspections could not be found.');
        throw new Error('API Error occurred. Inspections could not be found.');
      }
      commit(ReleasesMutations.SET_PATCH_RELEASE_INSPECTION_ERROR, 'An API Error Occured');
      throw new Error('API Error occurred.');
    } finally {
      commit(ReleasesMutations.SET_PATCH_RELEASE_INSPECTION_LOADING, false);
    }
  },

  // eslint-disable-next-line spaced-comment
  async [ReleasesActions.FETCH_RELEASES_OVERVIEW]({ commit }, projectIDs: string): Promise<void> {
    commit(ReleasesMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.fetchReleasesOverviewData(projectIDs);
      commit(ReleasesMutations.SET_RELEASES_DATA, response.data.data);
    } catch (e) {
      commit(ReleasesMutations.SET_RELEASES_ERROR, (e as Error).message);
      throw Error('Fetch Releases Error occurred.');
    } finally {
      commit(ReleasesMutations.SET_LOADING_STATE, false);
    }
  },
  // eslint-disable-next-line spaced-comment
  async [ReleasesActions.FETCH_RELEASE_TABLE_DATA]({ commit }, projectGuid: string): Promise<void> {
    commit(ReleasesMutations.SET_RELEASE_TABLE_DATA, []);
    commit(ReleasesMutations.SET_RELEASE_TABLE_DATA_LOADING, true);
    commit(ReleasesMutations.SET_RELEASE_TABLE_DATA_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.fetchReleaseTableData(projectGuid);
      commit(ReleasesMutations.SET_RELEASE_TABLE_DATA, response.data.data);
    } catch (e) {
      commit(ReleasesMutations.SET_RELEASE_TABLE_DATA_ERROR, (e as Error).message);
      throw Error('Fetch Releases Error occurred.');
    } finally {
      commit(ReleasesMutations.SET_RELEASE_TABLE_DATA_LOADING, false);
    }
  },
  async [ReleasesActions.GET_INSPECTION_RELEASES]({ commit }, projectGuid: string): Promise<void> {
    commit(ReleasesMutations.SET_INSPECTION_RELEASES_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getInspectionReleases(projectGuid);
      commit(ReleasesMutations.SET_INSPECTION_RELEASES_DATA, response.data.data);
    } catch (e) {
      commit(ReleasesMutations.SET_RELEASES_ERROR, (e as Error).message);
      throw Error('Get Inspection Releases Error occurred.');
    } finally {
      commit(ReleasesMutations.SET_INSPECTION_RELEASES_LOADING, false);
    }
  },
  async [ReleasesActions.POST_SEND_CSV_EMAIL]({ commit },
    payload: {
      projectGuid: string,
      releaseGuid: string,
      notificationEmails: string[],
    }): Promise<void> {
    commit(ReleasesMutations.SET_SEND_CSV_EMAIL_LOADING, true);
    commit(ReleasesMutations.SET_SEND_CSV_EMAIL_ERROR, undefined);
    try {
      const { projectGuid, releaseGuid, notificationEmails } = payload;
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postSendCsvEmail(projectGuid, releaseGuid, notificationEmails);
    } catch (e) {
      commit(ReleasesMutations.SET_SEND_CSV_EMAIL_ERROR, (e as Error).message);
      throw Error('Get Inspection Releases Error occurred.');
    } finally {
      commit(ReleasesMutations.SET_SEND_CSV_EMAIL_LOADING, false);
    }
  },
  async [ReleasesActions.DELETE_RELEASE]({ commit }, releaseGuid: string): Promise<void> {
    commit(ReleasesMutations.SET_DELETE_RELEASE_LOADING, true);
    commit(ReleasesMutations.SET_DELETE_RELEASE_RESPONSE, '');
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.deleteRelease(releaseGuid);
      commit(ReleasesMutations.SET_DELETE_RELEASE_RESPONSE, response.data.message);
    } catch (e) {
      commit(ReleasesMutations.SET_DELETE_RELEASE_RESPONSE, (e as Error).message);
      throw Error('Delete Release Error occurred.');
    } finally {
      commit(ReleasesMutations.SET_DELETE_RELEASE_LOADING, false);
    }
  },
  async [ReleasesActions.DELETE_RELEASE_INSPECTION]({ commit },
    payload: {releaseGuid: string, assetGuid: string}): Promise<void> {
    commit(ReleasesMutations.SET_DELETE_RELEASE_INSPECTION_LOADING, true);
    commit(ReleasesMutations.SET_DELETE_RELEASE_INSPECTION_ERROR, '');
    try {
      const { releaseGuid, assetGuid } = payload;
      const api = await storeUtility.useIntegrityAPI();
      await api.deleteReleaseInspection(releaseGuid, assetGuid);
    } catch (e) {
      commit(ReleasesMutations.SET_DELETE_RELEASE_INSPECTION_ERROR, (e as Error).message);
      throw Error('Delete Release Error occurred.');
    } finally {
      commit(ReleasesMutations.SET_DELETE_RELEASE_INSPECTION_LOADING, false);
    }
  },
  async [ReleasesActions.POST_SEND_EXPORT_EMAIL]({ commit },
    payload: {releaseGuid: string, userEmail: string}): Promise<void> {
    commit(ReleasesMutations.SET_POST_SEND_EXPORT_EMAIL_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postSendExportEmail(payload.releaseGuid, payload.userEmail);
    } catch (e) {
      commit(ReleasesMutations.SET_POST_SEND_EXPORT_EMAIL_ERROR, (e as Error).message);
      throw Error('Get Inspection Releases Error occurred.');
    } finally {
      commit(ReleasesMutations.SET_POST_SEND_EXPORT_EMAIL_LOADING, false);
    }
  },

  async [ReleasesActions.POST_SEND_NOTIFICATION_EMAIL]({ commit },
    payload: {releaseGuid: string, notificationEmails: string[]}): Promise<void> {
    commit(ReleasesMutations.SET_POST_SEND_NOTIFICATION_EMAIL_LOADING, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postSendNotificationEmail(
        payload.releaseGuid,
        payload.notificationEmails,
      );
    } catch (e) {
      commit(ReleasesMutations.SET_POST_SEND_NOTIFICATION_EMAIL_ERROR, (e as Error).message);
      throw Error('Get Inspection Releases Error occurred.');
    } finally {
      commit(ReleasesMutations.SET_POST_SEND_NOTIFICATION_EMAIL_LOADING, false);
    }
  },
};
