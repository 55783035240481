/* eslint-disable @typescript-eslint/ban-ts-comment */
import User from './User';

/**
   * @description Initialize Pendo
   */
export default async function initPendo(user: User): Promise<void> {
  if (user != null) {
    const userEmailSplit = user.email.split('@');
    try {
      // @ts-ignore
      await window.pendo.initialize({
        visitor: {
          id: user.id,
          email: user.email,
          full_name: user.name,
          role: user.role,
        },

        account: {
          id: userEmailSplit != null && userEmailSplit.length > 0
            ? userEmailSplit[userEmailSplit.length - 1]
            : user.id,
          accountName: userEmailSplit != null && userEmailSplit.length > 0
            ? userEmailSplit[userEmailSplit.length - 1]
            : user.id,
        },
      });
    } catch (e) {
      console.warn('Could not initialize pendo.');
    }
  }
}
