





































import VerticalBarChart from '@/components/MSIGraph/VerticalBarChart.vue';
import {
  CrewCountsByDate, CrewCountsWithAverages, RobotCounts,
} from '@/store/metrics/types';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component({
  components: {
    VerticalBarChart,
  },
})
export default class CrewCountsAverageCard extends Vue {
  @Prop() readonly crewCountsAverages: CrewCountsWithAverages;

  @Prop() readonly crewName: string;

  @Prop() readonly expectedJobName: string;

  startDate: Date;

  endDate: Date;

  dateRangeStart = 1;

  isRerenderingChart = false;

  dateSelectionOptions = [
    {
      text: 'This week',
      func: (): void => {
        this.setDateRangeStart(1);
        const endDate = new Date();
        endDate.setDate(new Date().getDate() - 1);
        const startDate = new Date();
        startDate.setDate(new Date().getDate() - 8);
        this.setDate(startDate, endDate);
      },
    },
    {
      text: 'Last Week',
      func: (): void => {
        this.setDateRangeStart(8);
        const endDate = new Date();
        endDate.setDate(new Date().getDate() - 9);
        const startDate = new Date();
        startDate.setDate(new Date().getDate() - 16);
        this.setDate(startDate, endDate);
      },
    },
  ]

  robotColors = [
    '#06324B',
    '#0C6599',
    '#55B8F1',
    '#A1D8F7',
    '#D9EFFC',
  ]

  selection: string = this.dateSelectionOptions[0].text;

  @Watch('crewCountsAverages', { deep: true })
  async onCrewCountsAveragesChange(): Promise<void> {
    this.isRerenderingChart = true;
    await this.$nextTick();
    this.isRerenderingChart = false;
  }

  get jobName(): string {
    return this.crewCountsAverages?.jobName != null && this.crewCountsAverages?.jobName !== '' ? this.crewCountsAverages.jobName : this.expectedJobName;
  }

  get historicAverage(): number {
    const returnValue = this.crewCountsAverages?.historicAverage;
    return returnValue != null ? Math.round(returnValue * 100) / 100 : 0;
  }

  get dailyAverage(): number {
    const returnValue = this.crewCountsAverages?.dailyAverage;
    return returnValue != null ? Math.round(returnValue * 100) / 100 : 0;
  }

  get crewCounts(): CrewCountsByDate[] {
    let returnArray = [];
    if (this.crewCountsAverages?.crewCounts != null) {
      returnArray = returnArray.concat(this.crewCountsAverages.crewCounts);
    }
    return returnArray;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get barChartAnnotations(): any[] {
    const returnValue = [];

    if (this.dailyAverage) {
      returnValue.push(this.dailyAverage);
    }

    return returnValue;
  }

  getBarChartLabels(crewCounts: CrewCountsByDate[]): string[] {
    const labelObjects = this.getChatDataByDateAndRobot(crewCounts).datasets.filter((data) => data.type === 'line');
    return labelObjects.map((item) => item.label);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDailyAverageLine(amountOfDataEntries: number): any {
    const label = 'Daily Average';
    return this.crewCountsAverages?.dailyAverage == null ? null
      : {
        label: `${label} (${this.dailyAverage})`,
        data: Array(amountOfDataEntries).fill(this.dailyAverage),
        borderColor: 'black',
        type: 'line',
        pointRadius: 0,
        pointHitRadius: 0,
        borderDash: [5, 5],
        order: 0,
      };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getHistoricalAverageLine(amountOfDataEntries: number): any {
    const label = 'Historic Average';
    return this.crewCountsAverages?.historicAverage == null ? null
      : {
        label: `${label} (${this.historicAverage})`,
        data: Array(amountOfDataEntries).fill(this.historicAverage),
        borderColor: '#d9d9d9',
        type: 'line',
        pointRadius: 0,
        pointHitRadius: 0,
        borderDash: [5, 5],
        order: 1,
      };
  }

  reset(): void {
    const firstOptions = this.dateSelectionOptions[0];
    this.selection = firstOptions.text;
    firstOptions.func();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getChatDataByDateAndRobot(crews: CrewCountsByDate[]): any {
    const labels: string[] = [];
    const datasets = [];

    // set to 8 because we are adding a blank column at the beginning and then 7 date columns.
    // We'll add the one at the end later
    let largestDatasetAmount = 8;

    // pushing blank column
    labels.push('');
    datasets.push({
      data: Array(largestDatasetAmount).fill(0),
      type: 'bar',
      backgroundColor: 'white',
      label: '',
      order: 2,
    });

    // Pushing Date Columns
    for (let i = 7; i > 0; i -= 1) {
      const date = new Date();
      date.setDate(new Date().getDate() - (i - 1 + this.dateRangeStart));
      labels.push(this.getDateToString(date));
    }

    crews.forEach((crew) => {
      crew.robotCounts.forEach((robot, robotIndex) => {
        const foundDataSet = datasets.find((value) => value.label === robot.robotName);
        if (foundDataSet == null) {
          const dataToAdd = Array(largestDatasetAmount).fill(0);
          const foundLabelIndex = labels.findIndex(
            (value) => value === this.getDateToString(crew.date),
          );
          if (foundLabelIndex > -1) {
            dataToAdd[foundLabelIndex] = robot.completed;
            datasets.push({
              data: dataToAdd,
              type: 'bar',
              backgroundColor: this.getColorFromIndex(robotIndex),
              label: robot.robotName,
              order: 2,
            });
          }
        } else {
          const foundLabelIndex = labels.findIndex(
            (value) => value === this.getDateToString(crew.date),
          );
          if (foundLabelIndex > -1) {
            foundDataSet.data[foundLabelIndex] = robot.completed;
          }
        }
      });
    });

    // pushing end blank column
    labels.push('');
    datasets.push({
      data: Array(largestDatasetAmount).fill(0),
      type: 'bar',
      backgroundColor: 'white',
      label: '',
      order: 2,
    });
    largestDatasetAmount += 1;

    // Addiung the blank column data to the end of all data
    datasets.forEach((value) => value.data.push(0));

    const dailyAverageData = this.getDailyAverageLine(largestDatasetAmount);
    const historicAverageData = this.getHistoricalAverageLine(largestDatasetAmount);

    if (dailyAverageData) {
      datasets.push(dailyAverageData);
    }
    if (historicAverageData) {
      datasets.push(historicAverageData);
    }

    return { labels, datasets };
  }

  getDateToString(pasedDate: Date): string {
    const date = new Date(pasedDate);
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);

    return `${year}-${month}-${day}`;
  }

  getColorFromIndex(index: number): string {
    return index <= this.robotColors.length - 1
      ? this.robotColors[index]
      : this.robotColors[this.robotColors.length - 1];
  }

  getFilteredRobots(robots: RobotCounts[]): RobotCounts[] {
    return robots.sort((a, b) => a.completed - b.completed);
  }

  setDate(startDate: Date, endDate: Date): void {
    if (startDate <= endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
    } else {
      this.startDate = endDate;
      this.endDate = startDate;
    }
    this.emitNewDates();
  }

  pickDateSelection(val: string): void {
    this.selection = val;
    const selectedOption = this.dateSelectionOptions.find((value) => value.text === this.selection);
    if (selectedOption != null) {
      selectedOption.func();
    }
  }

  setDateRangeStart(value: number): void {
    this.dateRangeStart = value;
  }

  async emitNewDates(): Promise<void> {
    await this.$emit('refresh',
      this.jobName,
      this.crewName,
      this.startDate.toLocaleDateString('en-US', { timeZone: 'America/New_York' }),
      this.endDate.toLocaleDateString('en-US', { timeZone: 'America/New_York' }));
    await this.$nextTick();
  }
}
