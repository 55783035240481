
















import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component({})
export default class MPSFooter extends Vue {
  @Prop() readonly isSaveMode: boolean;

  @Prop() readonly savingEdit: boolean;

  @Prop() readonly valid: boolean;

  @Prop() readonly isSubmitButton: boolean;

  get buttonText(): string {
    let retVal = '';

    if (!this.isSubmitButton) retVal = 'Next';
    else if (!this.isSaveMode) retVal = 'Submit';
    else retVal = 'Save';

    return retVal;
  }

  handleClick(): void {
    if (!this.isSubmitButton) this.$emit('nextTab');
    else if (!this.isSaveMode) this.$emit('submitForm');
    else this.$emit('save');
  }

  cancel(): void {
    this.$emit('resetForm');
  }
}
