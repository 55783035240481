import { MutationTree } from 'vuex';
import { DataStandardViewReturn } from '@/components/DataStandards/types';
import {
  BigPipeDeploymentData, DeploymentCount, DeploymentData, DeploymentsState,
} from './types';

// eslint-disable-next-line no-shadow
export enum DeploymentsMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_COUNTS_LOADING_STATE = 'SET_COUNTS_LOADING_STATE',
  SET_DEPLOYMENT_COUNTS = 'SET_DEPLOYMENT_COUNTS',
  SET_PATCH_LOADING_STATE = 'SET_PATCH_LOADING_STATE',
  SET_DEPLOYMENTS_DATA = 'SET_DEPLOUMENTS_DATA',
  SET_DEPLOYMENT_STANDARDS = 'SET_DEPLOYMENT_STANDARDS',
  SET_ERROR_STATE = 'SET_ERROR_STATE',
  SET_BIG_PIPE_DEPLOYMENT_DATA = 'SET_BIG_PIPE_DEPLOYMENT_DATA',
  SET_BIG_PIPE_DEPLOYMENT_DATA_LOADING = 'SET_BIG_PIPE_DEPLOYMENT_DATA_LOADING',
  SET_BIG_PIPE_DEPLOYMENT_DATA_ERROR = 'SET_BIG_PIPE_DEPLOYMENT_DATA_ERROR',
  SET_BIG_PIPE_DEPLOYMENT_PATCH_LOADING = 'SET_BIG_PIPE_DEPLOYMENT_PATCH_LOADING',
  SET_BIG_PIPE_DEPLOYMENT_PATCH_ERROR = 'SET_BIG_PIPE_DEPLOYMENT_PATCH_ERROR',
  SET_DEPLOYMENT_NUMBER_LOADING = 'SET_DEPLOYMENT_NUMBER_LOADING',
  SET_DEPLOYMENT_NUMBER = 'SET_DEPLOYMENT_NUMBER',
  SET_DEPLOYMENT_NUMBER_ERROR = 'SET_DEPLOYMENT_NUMBER_ERROR',
  SET_DEPLOYMENT_ID_LOADING = 'SET_DEPLOYMENT_ID_LOADING',
  SET_DEPLOYMENT_ID = 'SET_DEPLOYMENT_ID',
  SET_DEPLOYMENT_ID_ERROR = 'SET_DEPLOYMENT_ID_ERROR',
}

export const mutations: MutationTree<DeploymentsState> = {
  [DeploymentsMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },

  [DeploymentsMutations.SET_COUNTS_LOADING_STATE](state, payload: boolean) {
    state.countsLoading = payload;
  },

  [DeploymentsMutations.SET_DEPLOYMENT_COUNTS](state, payload: DeploymentCount[]) {
    state.deploymentCounts = payload;
  },

  [DeploymentsMutations.SET_PATCH_LOADING_STATE](state, payload: boolean) {
    state.patchLoading = payload;
  },

  [DeploymentsMutations.SET_DEPLOYMENTS_DATA](state, payload: DeploymentData[]) {
    state.data = payload;
  },

  [DeploymentsMutations.SET_ERROR_STATE](state, payload: string) {
    state.error = payload;
  },

  [DeploymentsMutations.SET_DEPLOYMENT_STANDARDS](state, payload: DataStandardViewReturn) {
    state.deploymentStandards = payload;
  },

  [DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_DATA](state,
    payload: BigPipeDeploymentData | undefined) {
    state.bigPipeDeploymentData = payload;
  },

  [DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_DATA_LOADING](state, payload: boolean) {
    state.bigPipeDeploymentDataLoading = payload;
  },

  [DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_DATA_ERROR](state, payload: string | undefined) {
    state.bigPipeDeploymentDataError = payload;
  },

  [DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_PATCH_LOADING](state, payload: boolean) {
    state.bigPipeDeploymentPatchLoading = payload;
  },

  [DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_PATCH_ERROR](state, payload: string | undefined) {
    state.bigPipeDeploymentPatchError = payload;
  },

  [DeploymentsMutations.SET_DEPLOYMENT_NUMBER_LOADING](state, payload: boolean) {
    state.deploymentNumberPatchLoading = payload;
  },

  [DeploymentsMutations.SET_DEPLOYMENT_NUMBER](state, payload: string | undefined) {
    state.deploymentNumberData = payload;
  },

  [DeploymentsMutations.SET_DEPLOYMENT_NUMBER_ERROR](state, payload: string | undefined) {
    state.deploymentNumberPatchError = payload;
  },

  [DeploymentsMutations.SET_DEPLOYMENT_ID_LOADING](state, payload: boolean) {
    state.deploymentIdLoading = payload;
  },

  [DeploymentsMutations.SET_DEPLOYMENT_ID](state, payload: string | undefined) {
    state.deploymentId = payload;
  },

  [DeploymentsMutations.SET_DEPLOYMENT_ID_ERROR](state, payload: string | undefined) {
    state.deploymentIdError = payload;
  },
};
