











































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import CustomerDashboardCollectionTable from '../CustomerDashboardCollectionTable.vue';
import ProjectOverviewGraph from '../ProjectOverviewGraph.vue';
import WorkOrderPieChart from '../WorkOrderPieChart.vue';
import PlatformCollectionTable from './PlatformCollectionTable.vue';
import ClientActions from './ClientActions.vue';

@Component({
  components: {
    WorkOrderPieChart,
    ProjectOverviewGraph,
    CustomerDashboardCollectionTable,
    PlatformCollectionTable,
    ClientActions,
  },
})
export default class CustomerDashboardProjectProgress extends CustomerDashboardMixin {
  @Prop() readonly projectId: string;

  @Watch('projectId')
  onProjectIdChange(): void {
    if (this.projectId == null || this.projectId === '') {
      return;
    }
    this.fetchAssetCountsMetrics(this.projectId);

    this.fetchWorkOrderProjectMetrics(this.projectId);
    this.fetchProjectOverviewMetrics(this.projectId);
    this.fetchOverviewTotalCounts(this.projectId);

    this.fetchDataDeliveryMetrics(this.projectId);
    this.fetchClientActionItems(this.projectId);

    this.fecthProjectTotals(this.projectId);

    this.$forceUpdate();
  }

  async mounted(): Promise<void> {
    this.onProjectIdChange();
  }

  get assetCountsMetricsLineSegmentTotal(): number {
    return this.assetCountsMetrics?.lineSegments?.total != null
      ? this.assetCountsMetrics.lineSegments.total : 0;
  }
}
