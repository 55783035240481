







































































































































import { ReleasesActions } from '@/store/releases/actions';
import { ScopeHistoryActions } from '@/store/scopeHistory/actions';
import { ScopeHistoryMutations } from '@/store/scopeHistory/mutations';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AssetHistory } from '../types';

const scopeHistoryModule = namespace('scopeHistory');
const releaseModule = namespace('releases');

@Component({})
export default class CustomerPrepDialog extends Vue {
  @scopeHistoryModule.Action(ScopeHistoryActions.FETCH_CUSTOMER_PREP_RELEASE_EMAILS)
    fetchCustomerPrepReleasesEmails;

  @scopeHistoryModule.Mutation(ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS)
    setCustomerPrepReleasesEmails;

  @releaseModule.Action(ReleasesActions.POST_RELEASE_INSPECTION)
    postReleaseInspection;

  @releaseModule.Action(ReleasesActions.FETCH_RELEASES_OVERVIEW) fetchAssignmentsData;

  @releaseModule.Action(ReleasesActions.GET_INSPECTION_RELEASES) getInspectionReleases;

  @releaseModule.Action(ReleasesActions.FETCH_RELEASE_TABLE_DATA) fetchReleaseTableData;

  @releaseModule.State('postReleaseLoading') postReleaseLoading: boolean;

  @scopeHistoryModule.State('customerPrepReleaseEmails') recipients: string[];

  @Prop({ }) readonly projectGuid: string;

  @Prop({ default: true }) readonly isManhole: boolean;

  @Prop({ default: [] }) readonly tableModel: Array<AssetHistory>;

  @PropSync('isOpen') showCustomerPrepDialog: boolean;

  search = null;

  // Card Models

  releaseNameModel = '';

  exportCheckboxModel = true;

  recipientsModel = [];

  // Transmittal Letter

  hasTransmittalLetter = false;

  transmittalLetterFileName = 'No File Selected!';

  get exportCheckboxText(): string {
    return this.isManhole ? 'MACP' : 'PACP';
  }

  get isSubmitDisabled(): boolean {
    return this.releaseNameModel == null
    || this.releaseNameModel === ''
    || this.recipientsModel == null
    || this.recipientsModel.length === 0
    || !this.hasTransmittalLetter;
  }

  async mounted(): Promise<void> {
    this.fetchEmailsAndSet();
  }

  async fetchEmailsAndSet(): Promise<void> {
    this.fetchCustomerPrepReleasesEmails(this.projectGuid).then(() => {
      this.recipientsModel = [...this.recipients];
    });
  }

  isSearchEmail(email: string): boolean {
    if (email == null) {
      return false;
    }
    return email.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ) != null;
  }

  setTransmittalFile(): void {
    const ref = this.$refs.transmittalLetter as HTMLInputElement;
    this.hasTransmittalLetter = ref?.files != null && ref.files.length > 0;
    if (this.hasTransmittalLetter) {
      this.transmittalLetterFileName = ref.files[0].name;
    }
  }

  async onSubmit(): Promise<void> {
    const ref = this.$refs.transmittalLetter as HTMLInputElement;
    const postReleaseInspectionObject = {
      inspectionGuids: this.tableModel.map((x) => x.guid),
      releaseName: this.releaseNameModel,
      shouldExport: this.exportCheckboxModel,
      transmittalLetter: ref.files[0],
      notificationRecipients: this.recipientsModel,
      userEmail: this.$auth.user.email,
    };
    await this.postReleaseInspection(postReleaseInspectionObject);
    if (postReleaseInspectionObject.shouldExport) {
      // eslint-disable-next-line no-alert
      alert('Your export request has been received. The download links will be sent shortly to the email associated with this account.');
    }

    this.fetchAssignmentsData(this.projectGuid);
    this.getInspectionReleases(this.projectGuid);
    this.fetchReleaseTableData(this.projectGuid);

    this.closeDialog();
    this.reset();
    this.$emit('resetCustomerPrep');
  }

  checkForNewRecipientsAndAdd(): void {
    for (let i = 0; i < this.recipientsModel.length; i += 1) {
      if (!this.isSearchEmail(this.recipientsModel[i])) {
        this.recipientsModel.splice(i, 1);
        break;
      }
      if (!this.recipients.includes(this.recipientsModel[i])) {
        const tempRecipients = [...this.recipients];
        tempRecipients.push(this.recipientsModel[i]);
        this.setCustomerPrepReleasesEmails(tempRecipients);
        break;
      }
    }
  }

  onSelectFile(): void {
    const ref = this.$refs.transmittalLetter as HTMLInputElement;
    if (ref != null) {
      ref.click();
    }
  }

  reset(): void {
    this.releaseNameModel = '';
    this.exportCheckboxModel = true;
    this.fetchEmailsAndSet();

    const ref = this.$refs.transmittalLetter as HTMLInputElement;
    ref.value = null;
    this.hasTransmittalLetter = false;
    this.transmittalLetterFileName = 'No File Selected!';
  }

  closeDialog(): void {
    this.showCustomerPrepDialog = false;
  }
}
