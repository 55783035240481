

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { RobotDetail } from '@/store/asset/types';

@Component({
  components: {
  },
})
export default class OtherInfoReport extends Vue {
    @Prop() readonly robotSerialNumber: string | undefined;

    @Prop({ default: () => ({}) }) readonly robotDetail: RobotDetail | undefined;
}
