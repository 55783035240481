






























































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import Vue from 'vue';
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DefectCodingAction } from '@/store/defectCoding/actions';
import {
  AssetData,
  Condition,
  InspectionData,
  IPDEntry,
} from '@/store/asset/types';
import { v4 as uuidv4 } from 'uuid';
import convert from 'convert-units';
import Utils from '@/views/Asset/utils';
import {
  DefectCodingModel,
  DefectCodingPostModel,
  DefectPanTiltZoom,
  DefectValidationObject,
} from '../../store/defectCoding/types';
import IntegrityConfirm from '../IntegrityConfirm/IntegrityConfirm.vue';

const defectCodingModule = namespace('defectCoding');

// eslint-disable-next-line no-shadow
export enum DefectCodingFieldType {
  DEFAULT = 'DEFAULT',
  CLOCK_TO = 'CLOCK_TO',
  CLOCK_FROM = 'CLOCK_FROM',
  DIMENSION = 'DIMENSION',
  PERCENTAGE = 'PERCENTAGE',
}

@Component({
  components: {
    IntegrityConfirm,
  },
})
export default class DefectCoding extends Vue {
  @defectCodingModule.Action(DefectCodingAction.RESET_DEFECT_CODING_POST_ERROR)
  resetDefectCodingPostError;

  @defectCodingModule.Action(DefectCodingAction.POST_DEFECT_CODING_DATA)
  postDefectCodingData;

  @defectCodingModule.Action(DefectCodingAction.PATCH_DEFECT_DATA)
  patchDefectData;

  @defectCodingModule.Action(DefectCodingAction.SET_CONFIRM_SCORE_RESET)
  setConfirmationFlag;

  @defectCodingModule.State('defectCodingData') defectCodingData:
    | DefectCodingModel[]
    | undefined;

  @defectCodingModule.State('defectCodingPostLoading')
  defectCodingPostLoading: boolean;

  @defectCodingModule.State('defectCodingPostError') defectCodingPostError:
    | string
    | undefined;

  @defectCodingModule.State('defectCodingPatchLoading')
  defectCodingPatchLoading: boolean;

  @defectCodingModule.State('confirmScoreReset') confirmationFlag;

  @Prop() imageLink: string;

  @Prop({ default: 0 }) payout: number;

  @Prop({ default: false }) isEditing: boolean;

  @Prop() readonly asset!: AssetData;

  @Prop() readonly displayImperial: boolean;

  @Prop({ default: '' }) readonly quickObservationCode: string;

  @Prop() readonly ipd: IPDEntry[];

  @Prop({ default: undefined }) readonly panTiltZoom?: DefectPanTiltZoom;

  @PropSync('syncedInspection') inspection!: InspectionData;

  @PropSync('popupModel') displayDefectCodingPopup: boolean;

  @PropSync('isMountedSync') isMounted: boolean;

  isFormValid = false;

  defectCodes: Array<{
    text: string;
    code: string;
    description: string;
    isContinuous: boolean;
  }> = [];

  selectedDefectCode: { text: string; code: string; description: string } =
    null;

  defectComponents = [
    'Bench',
    'Channel',
    'Chimney Exterior',
    'Chimney Interior',
    'Cone Exterior',
    'Cone Interior',
    'Wall Exterior',
    'Wall Interior',
  ];

  selectedComponent: string = null;

  clockFrom: number | undefined = null;

  clockTo: number | undefined = null;

  percentage: number | undefined = null;

  dimensionOne: number | undefined = null;

  dimensionTwo: number | undefined = null;

  remarks: string = null;

  joint = false;

  step = false;

  continuous = false;

  endContinuous = false;

  isRearCamera = false;

  selectedStartingIndex: string = null;

  continuousIndex: string = null;

  DVO: DefectValidationObject | undefined = null;

  editCondition: Condition | undefined = null;

  editablePayout = 0;

  editableImage: string = null;

  originalCode: string = null;

  editMatchingContinuousDefect = false;

  fieldTypeClockTo = DefectCodingFieldType.CLOCK_TO;

  fieldTypeClockFrom = DefectCodingFieldType.CLOCK_FROM;

  fieldTypeDimension = DefectCodingFieldType.DIMENSION;

  fieldTypePercentage = DefectCodingFieldType.PERCENTAGE;

  percentageIncrement = 5;

  requiredString = 'Required';

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  requiredField = [(v) => !!v || v === 0 || 'This is a required field.'];

  showConfirmation = true;

  confirmText =
    'Creating or modifying defects will reset the Overall Inspection Score until it is validated';

  defectItems = [];

  searchInput = '';

  get isDamLevee(): boolean {
    if (
      this.inspection.initialPipeUse != null
      && this.inspection.initialPipeUse !== 'Dam Pipe'
      && this.inspection.initialPipeUse !== 'Levee Gravity Pipe'
      && this.inspection.initialPipeUse !== 'Levee Pressure Pipe'
    ) {
      return false;
    }
    return true;
  }

  getDefectItems(): void {
    let items = this.defectCodes;
    if (!this.isDamLevee) {
      items = items.filter(
        (value) => value.description !== 'Joint Angular Small'
          && value.description !== 'Joint Offset Small'
          && value.description !== 'Joint Offset Small Defective'
          && value.description !== 'Joint Separated Small',
      );
    }
    if (this.continuousIndex) {
      items = items.filter((value) => value.isContinuous);
    }

    // If search input is set, filter by it
    if (this.searchInput !== null && this.searchInput !== '') {
      const frontItems = [];
      const secondItems = [];
      items.forEach((item) => {
        if (
          item.code.toLowerCase().startsWith(this.searchInput.toLowerCase())
        ) {
          frontItems.push(item);
        } else if (
          item.code.toLowerCase().includes(this.searchInput.toLowerCase())
        ) {
          secondItems.push(item);
        }
      });
      items = items.filter(
        (item) => frontItems.findIndex((fi) => fi.code === item.code) === -1,
      );
      items = items.filter(
        (item) => secondItems.findIndex((fi) => fi.code === item.code) === -1,
      );
      items.unshift(...(secondItems as any));
      items.unshift(...(frontItems as any));
    }
    this.defectItems = items;
  }

  get nonEndDefects(): Array<string> {
    const returnArray: Array<string> = [];

    const startCIndex: Array<number> = [];
    const startCIndexCounter: Array<number> = [];
    const startFIndex: Array<number> = [];
    if (this.inspection != null && this.inspection.conditionReport != null) {
      this.inspection.conditionReport.forEach((report, i) => {
        let index = report.report.continuousIndex as string;
        if (index != null && index !== '' && index.length === 3) {
          if (index.charAt(0) === 'S') {
            index = index.replace('S', '');
            startCIndex.push(parseInt(index, 10));
            startCIndexCounter.push(i);
          }
          if (index.charAt(0) === 'F') {
            if (
              !this.isEditing
              || !this.endContinuous
              || index !== this.continuousIndex
            ) {
              index = index.replace('F', '');
              startFIndex.push(parseInt(index, 10));
            }
          }
        }
      });
      startCIndex.forEach((index: number, i) => {
        if (startFIndex.indexOf(index) === -1) {
          const condition = this.inspection.conditionReport[startCIndexCounter[i]];
          const { report } = condition;
          returnArray.push(
            `${condition.distance.toFixed(1)}ft | ${condition.code} | ${
              report.continuousIndex
            }`,
          );
        }
      });
    }

    return returnArray;
  }

  get isEndContinuousActive(): boolean {
    const length = this.nonEndDefects?.length;
    return length != null ? length > 0 : false;
  }

  get largestStartingIndexNumber(): number {
    const startCIndex: Array<number> = [];
    let largestNumber = 0;
    if (this.inspection != null && this.inspection.conditionReport != null) {
      this.inspection.conditionReport.forEach((report) => {
        let index = report.report.continuousIndex as string;
        if (index != null && index !== '' && index.length === 3) {
          if (index.charAt(0) === 'S') {
            index = index.replace('S', '');
            startCIndex.push(parseInt(index, 10));
          }
        }
      });
      startCIndex.forEach((index: number) => {
        if (largestNumber < index) {
          largestNumber = index;
        }
      });
    }

    return largestNumber;
  }

  get newContinuousDefect(): string {
    const nextNumber = (this.largestStartingIndexNumber + 1).toString();
    return `S${nextNumber.padStart(2, '0')}`;
  }

  @Watch('defectCodingData')
  onDefectCodingDataChange(): void {
    if (this.defectCodingData != null && this.defectCodingData.length > 0) {
      this.defectCodingData.forEach((value) => {
        this.defectCodes.push({
          text: `${value.code} | ${value.description}`,
          code: value.code,
          description: value.description,
          isContinuous:
            JSON.parse(value.jsonAttributes)['Continuous'] !== 'Disabled',
        });
      });
      this.defectCodes.sort((a, b) => a.text.localeCompare(b.text));
      this.getDefectItems();
      this.validate();
      this.isMounted = true;
    }
  }

  @Watch('endContinuous')
  async onEndContinuousToggle(): Promise<void> {
    if (this.endContinuous && this.continuous) {
      await this.$nextTick();
      this.endContinuous = false;
    }
    this.selectedStartingIndex = null;
    this.continuousIndex = null;
    if (this.$refs['selectedStartingIndex'] != null) {
      (this.$refs['selectedStartingIndex'] as any).reset();
    }
    this.validate();
  }

  @Watch('continuous')
  async onContinuousToggle(): Promise<void> {
    if (this.endContinuous && this.continuous) {
      await this.$nextTick();
      this.continuous = false;
      this.validate();
    }
  }

  get isNewDefectForContinuous(): boolean {
    return (
      this.continuousIndex
      && this.selectedDefectCode.description !== this.originalCode
    );
  }

  @Watch('selectedDefectCode')
  async onSelectedDefectCodeChange(): Promise<void> {
    this.importDVO();
    await this.$nextTick();
    if (this.isNewDefectForContinuous) {
      this.resetForContinuous();
      this.validate();
    } else {
      this.resetForm(false);
    }
    this.setRequiredCheckboxesModels();
  }

  @Watch('defectCodingPostLoading')
  onPostLoading(): void {
    if (!this.defectCodingPostLoading && this.defectCodingPostError == null) {
      this.resetForm();
    }
  }

  @Watch('quickObservationCode')
  onQuickObservationCodeChange(): void {
    this.setQuickObCode();
  }

  @Watch('searchInput')
  onSearchInputChange(): void {
    this.getDefectItems();
  }

  mounted(): void {
    this.onDefectCodingDataChange();
    this.setQuickObCode();
  }

  importDVO(): void {
    if (
      this.selectedDefectCode == null
      || this.selectedDefectCode.description == null
    ) {
      return;
    }

    const mockDataObject = this.defectCodingData.find(
      (value) => value.description === this.selectedDefectCode.description,
    );

    if (mockDataObject == null && mockDataObject.jsonAttributes == null) {
      return;
    }

    const rawJSONAttributes = mockDataObject.jsonAttributes;
    rawJSONAttributes.replaceAll('\\', '');

    this.DVO = JSON.parse(rawJSONAttributes) as DefectValidationObject;
  }

  setQuickObCode(): void {
    if (this.quickObservationCode !== '') {
      this.selectedDefectCode = this.defectCodes.find(
        (dc) => dc.text.includes(this.quickObservationCode),
      );
    }
  }

  async setFromModel(model: Condition): Promise<void> {
    this.originalCode = model.description;
    this.selectedDefectCode = {
      text: `${model.code} | ${model.description}`,
      code: model.code,
      description: model.description,
    };
    setTimeout(async () => {
      this.importDVO();
      await this.$nextTick();
      this.selectedComponent = model.component;
      this.editablePayout = this.displayImperial
        ? model.distance
        : Math.round(convert(model.distance).from('ft').to('m') * 100) / 100;
      this.clockFrom = model.report['clockStartFrom'] != null
        && model.report['clockStartFrom'] !== ''
        ? (model.report['clockStartFrom'] as number)
        : null;
      this.clockTo = model.report['clockTo'] != null && model.report['clockTo'] !== ''
        ? (model.report['clockTo'] as number)
        : null;
      this.dimensionOne = this.getUnitsConvertedImport(
        model.report['firstValue'] as number,
      );
      this.dimensionTwo = this.getUnitsConvertedImport(
        model.report['secondValue'] as number,
      );
      this.percentage = model.report['valuePercent'] as number;
      this.continuous = model.report['continuousIndex'] != null
        && model.report['continuousIndex'] !== ''
        && (model.report['continuousIndex'] as string).charAt(0) === 'S';
      this.endContinuous = model.report['continuousIndex'] != null
        && model.report['continuousIndex'] !== ''
        && (model.report['continuousIndex'] as string).charAt(0) === 'F';
      await this.$nextTick();
      this.continuousIndex = model.report['continuousIndex'] != null
        && model.report['continuousIndex'] !== ''
        ? (model.report['continuousIndex'] as string)
        : null;
      if (this.endContinuous) {
        this.selectedStartingIndex = this.nonEndDefects.find((value) => value.indexOf(this.continuousIndex.replace('F', 'S')));
      }
      this.editMatchingContinuousDefect = false;
      this.step = !(
        model.report['step'] === null
        || model.report['step'] === ''
        || model.report['step'] === 'False'
        || model.report['step'] === 'false'
        || model.report['step'] === false
      );
      this.joint = !(
        model.report['withinEightOfJoint'] === null
        || model.report['withinEightOfJoint'] === ''
        || model.report['withinEightOfJoint'] === 'False'
        || model.report['withinEightOfJoint'] === 'false'
        || model.report['withinEightOfJoint'] === false
      );
      this.remarks = model.report['remarks'] as string;
      this.editableImage = model.report['image'] as string;
      this.editCondition = model;
      this.isRearCamera = model.report['isRearCamera'] != null
        ? (model.report['isRearCamera'] as boolean)
        : false;
    }, 100);
  }

  findMatchingContinuousDefect(condReport: Condition): any {
    if (condReport['report']['continuousIndex'] === null) {
      return false;
    }
    const reportContinuousIndex = condReport['report'][
      'continuousIndex'
    ] as string;
    return (
      reportContinuousIndex.charAt(0) !== this.continuousIndex.charAt(0)
      && reportContinuousIndex.slice(-2) === this.continuousIndex.slice(-2)
    );
  }

  submit(): boolean {
    if (!this.isFormValid) {
      return false;
    }

    this.setConfirmationFlag(true);

    if (this.continuousIndex == null) {
      if (this.endContinuous) {
        this.continuousIndex = this.selectedStartingIndex
          .split(' | ')[2]
          .replace('S', 'F');
      } else if (this.continuous) {
        this.continuousIndex = this.newContinuousDefect;
      }
    } else if (
      this.isNewDefectForContinuous
      && !this.editMatchingContinuousDefect
      && !this.endContinuous
    ) {
      this.continuousIndex = this.newContinuousDefect;
    }

    let guid = '';
    const allGuids: Array<string> = [];
    this.inspection.conditionReport.forEach((tempCO) => {
      if (tempCO.guid != null && tempCO.guid !== '') {
        allGuids.push(tempCO.guid);
      }
    });
    while (allGuids.indexOf(guid) !== -1 || guid === '') {
      guid = uuidv4();
    }

    // eslint-disable-next-line no-nested-ternary
    const distance = this.displayImperial
      ? this.isEditing
        ? parseFloat(this.editablePayout.toFixed(1))
        : parseFloat(this.payout.toFixed(1))
      : this.isEditing
        ? parseFloat(convert(this.editablePayout).from('m').to('ft').toFixed(1))
        : parseFloat(convert(this.payout).from('m').to('ft').toFixed(1));

    const videoCounterMilliseconds = Utils.getClosestTime(this.ipd, distance);
    const videoCounterSeconds = Math.round((videoCounterMilliseconds / 1000) * 10) / 10;

    let runningTotalSeconds = videoCounterSeconds;

    const hours = Math.floor(runningTotalSeconds / 60 / 60);
    runningTotalSeconds -= hours * 60 * 60;
    const minutes = Math.floor(runningTotalSeconds / 60);
    runningTotalSeconds -= minutes * 60;
    const seconds = Math.floor(runningTotalSeconds);

    const vcrTime = (hours < 10 ? `0${hours.toString()}` : hours.toString())
      + (minutes < 10 ? `0${minutes.toString()}` : minutes.toString())
      + (seconds < 10 ? `0${seconds.toString()}` : seconds.toString());

    const model: DefectCodingPostModel = {
      guid,
      code: this.selectedDefectCode.code,
      description: this.selectedDefectCode.description,
      component: this.selectedComponent,
      type: 'Defect',
      distance,
      clockStartFrom: this.clockFrom,
      clockTo: this.clockTo,
      firstValue: this.displayImperial
        ? this.dimensionOne
        : this.getUnitsConverted(this.dimensionOne),
      secondValue: this.displayImperial
        ? this.dimensionTwo
        : this.getUnitsConverted(this.dimensionTwo),
      valuePercent: this.percentage,
      continuousIndex: this.continuousIndex,
      step: this.step,
      joint: this.joint,
      remarks: this.remarks,
      videoCounter: videoCounterSeconds,
      vcrTime,
      image64: this.isEditing
        ? this.editableImage
        : this.imageLink.replace('data:image/png;base64,', ''),
      isRearCamera: this.isRearCamera,
    };

    if (!this.isEditing) {
      model.v360CamPanAngle = this.panTiltZoom?.pan != null ? this.panTiltZoom.pan : undefined;
      model.v360CamTiltAngle = this.panTiltZoom?.tilt != null ? this.panTiltZoom.tilt : undefined;
      model.v360CamZoomAngle = this.panTiltZoom?.zoom != null ? this.panTiltZoom.zoom : undefined;
      this.postDefectCodingData({
        inspectionGuid: this.inspection.guid,
        DCPM: model,
      }).then(() => {
        const report: Record<string, unknown> = {
          code: model.code,
          description: model.description,
          distance: model.distance,
          structuralGrade: null,
          omGrade: null,
          clockStartFrom: model.clockStartFrom,
          clockTo: model.clockTo,
          firstValue: model.firstValue,
          secondValue: model.secondValue,
          valuePercent: model.valuePercent,
          continuousIndex: model.continuousIndex,
          step: model.step,
          remarks: model.remarks,
          withinEightOfJoint: model.joint,
          image: this.imageLink,
          isRearCamera: model.isRearCamera,
          v360CamPanAngle: model.v360CamPanAngle,
          v360CamTiltAngle: model.v360CamTiltAngle,
          v360CamZoomAngle: model.v360CamZoomAngle,
        };
        const conditionReport: Condition = {
          guid,
          component: model.component,
          type: model.type,
          description: model.description,
          distance: model.distance,
          severity: 'low',
          code: model.code,
          report,
          v360CamPanAngle: model.v360CamPanAngle,
          v360CamTiltAngle: model.v360CamTiltAngle,
          v360CamZoomAngle: model.v360CamZoomAngle,
        };
        this.inspection.conditionReport.push(conditionReport);
        this.displayDefectCodingPopup = false;
      });
    } else {
      model.guid = this.editCondition.guid;
      this.patchDefectData({
        inspectionGuid: this.inspection.guid,
        defectGuid: model.guid,
        defect: model,
      }).then(() => {
        const report: Record<string, unknown> = {
          code: model.code,
          description: model.description,
          distance: model.distance,
          structuralGrade: this.editCondition.report['structuralGrade'],
          omGrade: this.editCondition.report['omGrade'],
          clockStartFrom: model.clockStartFrom,
          clockTo: model.clockTo,
          firstValue: model.firstValue,
          secondValue: model.secondValue,
          valuePercent: model.valuePercent,
          continuousIndex: model.continuousIndex,
          step: model.step,
          remarks: model.remarks,
          withinEightOfJoint: model.joint,
          image: model.image64,
          isRearCamera: model.isRearCamera,
          v360CamPanAngle:
            model.v360CamPanAngle ?? this.editCondition.v360CamPanAngle,
          v360CamTiltAngle:
            model.v360CamTiltAngle ?? this.editCondition.v360CamTiltAngle,
          v360CamZoomAngle:
            model.v360CamZoomAngle ?? this.editCondition.v360CamZoomAngle,
        };
        const conditionReport: Condition = {
          guid: model.guid,
          component: model.component,
          type: model.type,
          description: model.description,
          distance: model.distance,
          severity: 'low',
          code: model.code,
          report,
          v360CamPanAngle:
            model.v360CamPanAngle ?? this.editCondition.v360CamPanAngle,
          v360CamTiltAngle:
            model.v360CamTiltAngle ?? this.editCondition.v360CamTiltAngle,
          v360CamZoomAngle:
            model.v360CamZoomAngle ?? this.editCondition.v360CamZoomAngle,
        };
        this.inspection.conditionReport.splice(
          this.inspection.conditionReport.findIndex(
            (value) => value.guid === conditionReport.guid,
          ),
          1,
        );
        this.inspection.conditionReport.push(conditionReport);

        if (
          this.isNewDefectForContinuous
          && this.editMatchingContinuousDefect
        ) {
          this.changeMatchingDefect(model);
        }
        this.displayDefectCodingPopup = false;
      });
    }
    return true;
  }

  changeMatchingDefect(originalModel: DefectCodingPostModel): void {
    const matchingDefect = this.inspection.conditionReport.find(
      this.findMatchingContinuousDefect,
    );

    if (!matchingDefect) {
      return;
    }

    const videoCounterMilliseconds = Utils.getClosestTime(
      this.ipd,
      matchingDefect.distance,
    );
    const videoCounterSeconds = Math.round((videoCounterMilliseconds / 1000) * 10) / 10;

    let runningTotalSeconds = videoCounterSeconds;

    const hours = Math.floor(runningTotalSeconds / 60 / 60);
    runningTotalSeconds -= hours * 60 * 60;
    const minutes = Math.floor(runningTotalSeconds / 60);
    runningTotalSeconds -= minutes * 60;
    const seconds = Math.floor(runningTotalSeconds);

    const vcrTime = (hours < 10 ? `0${hours.toString()}` : hours.toString())
      + (minutes < 10 ? `0${minutes.toString()}` : minutes.toString())
      + (seconds < 10 ? `0${seconds.toString()}` : seconds.toString());

    const selectedModel = this.editMatchingContinuousDefect
      ? originalModel
      : matchingDefect.report;
    const model: DefectCodingPostModel = {
      guid: matchingDefect.guid,
      code: originalModel.code as string,
      description: originalModel.description as string,
      component: originalModel.component as string,
      type: 'Defect',
      distance: matchingDefect.distance,
      clockStartFrom: selectedModel.clockStartFrom as number,
      clockTo: selectedModel.clockTo as number,
      firstValue: selectedModel.firstValue as number,
      secondValue: selectedModel.secondValue as number,
      valuePercent: selectedModel.valuePercent as number,
      continuousIndex: matchingDefect.report.continuousIndex as string,
      step: originalModel.step,
      joint: originalModel.joint,
      remarks: originalModel.remarks as string,
      videoCounter: videoCounterSeconds,
      vcrTime,
      image64: '',
      isRearCamera: this.isRearCamera,
    };
    this.patchDefectData({
      inspectionGuid: this.inspection.guid,
      defectGuid: matchingDefect.guid,
      defect: model,
    }).then(() => {
      const report: Record<string, unknown> = {
        code: model.code,
        description: model.description,
        distance: model.distance,
        structuralGrade: this.editCondition.report['structuralGrade'],
        omGrade: this.editCondition.report['omGrade'],
        clockStartFrom: model.clockStartFrom,
        clockTo: model.clockTo,
        firstValue: model.firstValue,
        secondValue: model.secondValue,
        valuePercent: model.valuePercent,
        continuousIndex: model.continuousIndex,
        step: model.step,
        remarks: model.remarks,
        withinEightOfJoint: model.joint,
        isRearCamera: model.isRearCamera,
        v360CamPanAngle:
          model.v360CamPanAngle ?? this.editCondition.v360CamPanAngle,
        v360CamTiltAngle:
          model.v360CamTiltAngle ?? this.editCondition.v360CamTiltAngle,
        v360CamZoomAngle:
          model.v360CamZoomAngle ?? this.editCondition.v360CamZoomAngle,
      };
      const conditionReport: Condition = {
        guid: model.guid,
        component: model.component,
        type: model.type,
        description: model.description,
        distance: model.distance,
        severity: 'low',
        code: model.code,
        report,
        v360CamPanAngle:
          model.v360CamPanAngle ?? this.editCondition.v360CamPanAngle,
        v360CamTiltAngle:
          model.v360CamTiltAngle ?? this.editCondition.v360CamTiltAngle,
        v360CamZoomAngle:
          model.v360CamZoomAngle ?? this.editCondition.v360CamZoomAngle,
      };
      this.inspection.conditionReport.splice(
        this.inspection.conditionReport.findIndex(
          (value) => value.guid === conditionReport.guid,
        ),
        1,
      );
      this.inspection.conditionReport.push(conditionReport);
    });
  }

  async handlePercentageChange(): Promise<void> {
    const remainder = this.percentage % this.percentageIncrement;
    const hasRemainder = remainder > 0;
    if (hasRemainder) {
      if (remainder >= Math.ceil(this.percentageIncrement / 2)) {
        this.percentage = Math.ceil(this.percentage / this.percentageIncrement)
          * this.percentageIncrement;
      } else {
        this.percentage = Math.floor(this.percentage / this.percentageIncrement)
          * this.percentageIncrement;
      }
    }
    await this.validate();
  }

  async validate(): Promise<void> {
    setTimeout(() => {
      (this.$refs.defectCodingCardInternal as any).validate();
    }, 100);
  }

  async fillStartingDefectCode(): Promise<void> {
    const startingIndex = this.selectedStartingIndex;

    const startindDefectCode = startingIndex.split(' | ')[1];
    const startingContinuousIndex = startingIndex.split(' | ')[2];

    this.continuousIndex = startingContinuousIndex.replace('S', 'F');
    this.selectedDefectCode = this.defectCodes.find(
      (code) => code.code === startindDefectCode,
    );

    const startingReport = this.inspection.conditionReport.find(
      (condition) => condition.report.continuousIndex === startingContinuousIndex,
    );

    await this.$nextTick();

    this.endContinuous = true;

    await this.$nextTick();

    this.selectedStartingIndex = startingIndex;
    this.selectedComponent = startingReport.component;

    const startingClockFrom = parseInt(
      `${startingReport.report.clockStartFrom}`,
      10,
    );
    this.clockFrom = Number.isNaN(startingClockFrom) ? null : startingClockFrom;

    const startingClockTo = parseInt(`${startingReport.report.clockTo}`, 10);
    this.clockTo = Number.isNaN(startingClockTo) ? null : startingClockTo;

    const startingPercent = parseInt(
      `${startingReport.report.valuePercent}`,
      10,
    );
    this.percentage = Number.isNaN(startingPercent) ? null : startingPercent;
  }

  getValidDefectCode(): string | any[] {
    if (this.endContinuous) {
      let index = this.selectedStartingIndex;
      if (index != null) {
        // eslint-disable-next-line prefer-destructuring
        index = index.split(' | ')[2];
      } else {
        return "Can't validate field. There is no starting index for this defect";
      }
      const findStartingIndex = this.inspection.conditionReport.find(
        (value) => value.report['continuousIndex'] === index,
      );
      if (
        findStartingIndex != null
        && findStartingIndex.description !== this.selectedDefectCode.description
        && !this.isNewDefectForContinuous
      ) {
        return "This defect's code must match the starting index code";
      }
      let greaterThan = 0;
      if (this.asset.type === 'Manhole') {
        greaterThan = 1;
      } else {
        greaterThan = 3;
      }
      if (
        Math.abs(
          findStartingIndex.distance
            - (this.isEditing ? this.editablePayout : this.payout),
        ) < greaterThan
        && !this.editMatchingContinuousDefect
      ) {
        return `This defect's payout must be ${greaterThan} or more feet from the starting index`;
      }
    }
    return this.requiredField;
  }

  getValidComponent(): string | any[] {
    if (this.endContinuous) {
      let index = this.selectedStartingIndex;
      if (index != null) {
        // eslint-disable-next-line prefer-destructuring
        index = index.split(' | ')[2];
      } else {
        return "Can't validate field. There is no starting index for this defect";
      }
      const findStartingIndex = this.inspection.conditionReport.find(
        (value) => value.report['continuousIndex'] === index,
      );
      if (
        findStartingIndex != null
        && findStartingIndex.component !== this.selectedComponent
        && !this.isNewDefectForContinuous
      ) {
        return `This defect's component must match the starting defect's component (${findStartingIndex.component})`;
      }
    }
    return this.requiredField;
  }

  getValid(
    required: string,
    requirements: Array<unknown>,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    model: any,
    type: string,
    toFixed = 0,
    fieldType = DefectCodingFieldType.DEFAULT,
  ): string | boolean {
    if (typeof this.getDisabled(required) === 'string') {
      return true;
    }
    const requiredValue = this.getRequired(required, model);
    if (typeof requiredValue === 'string') {
      return requiredValue;
    }
    if (fieldType === DefectCodingFieldType.DIMENSION) {
      if (
        this.DVO != null
        && this.DVO.units != null
        && this.DVO.units !== ''
        && this.DVO.units.indexOf('/') !== -1
      ) {
        const split = this.DVO.units.split('/');
        if (split.length === 2) {
          const metricUnit = split[1];

          if (!this.displayImperial && metricUnit === 'mm') {
            // eslint-disable-next-line no-param-reassign
            toFixed = 0;
          }
        }
      } else if (this.DVO == null) {
        // eslint-disable-next-line no-param-reassign
        toFixed = 0;
      }
    }
    if (type !== 'STRING') {
      switch (type) {
        case 'INTEGER':
          if (model != null) {
            if (model % 1 !== 0) {
              return 'Value must be an integer';
            }
          }
          break;
        case 'DOUBLE':
          if (model != null) {
            if (model % 1 !== 0) {
              if (this.countDecimals(model) > toFixed) {
                return `Value can have up to ${toFixed} decimal places`;
              }
            }
          }
          break;
        default:
          break;
      }
    }
    if (fieldType === DefectCodingFieldType.PERCENTAGE) {
      if (model < 0) {
        return 'Percentage can not be negative';
      }
      if (model > 100) {
        return 'Percentage must be <100';
      }
      if (this.endContinuous && this.selectedStartingIndex) {
        let index = this.selectedStartingIndex;
        if (index != null) {
          // eslint-disable-next-line prefer-destructuring
          index = index.split(' | ')[2];
        } else {
          return "Can't validate field. There is no starting index for this defect";
        }
        const findStartingIndex = this.inspection.conditionReport.find(
          (value) => value.report['continuousIndex'] === index,
        );
        if (findStartingIndex != null) {
          const percentageFromReport = findStartingIndex.report['valuePercent'] != null
            && findStartingIndex.report['valuePercent'] !== ''
            ? parseInt(
              findStartingIndex.report['valuePercent'].toString(),
              10,
            )
            : null;
          if (percentageFromReport != null) {
            if (
              model != null
              && model !== ''
              && model >= percentageFromReport - 15
              && model <= percentageFromReport + 15
            ) {
              return true;
            }
            return 'Percentage must be within 15 percentage points of the starting defect';
          }
        } else {
          return "Can't validate field. There is no starting index for this defect";
        }
      }
    }
    const isClockFromNull = this.clockFrom == null || this.clockFrom.toString() === '';
    if (
      fieldType === DefectCodingFieldType.CLOCK_TO
      && isClockFromNull
      && model != null
    ) {
      return 'Clock From must have a value to have a Clock To';
    }
    const isClockPosition = fieldType === DefectCodingFieldType.CLOCK_TO
      || fieldType === DefectCodingFieldType.CLOCK_FROM;
    if (this.endContinuous && this.selectedStartingIndex && isClockPosition) {
      let index = this.selectedStartingIndex;
      if (index != null) {
        // eslint-disable-next-line prefer-destructuring
        index = index.split(' | ')[2];
      } else {
        return "Can't validate field. There is no starting index for this defect";
      }
      const findStartingIndex = this.inspection.conditionReport.find(
        (value) => value.report['continuousIndex'] === index,
      );
      if (findStartingIndex != null) {
        const clockFrom = findStartingIndex.report['clockStartFrom'] != null
          && findStartingIndex.report['clockStartFrom'] !== ''
          ? parseInt(
            findStartingIndex.report['clockStartFrom'].toString(),
            10,
          )
          : null;
        const clockTo = findStartingIndex.report['clockTo'] != null
          && findStartingIndex.report['clockTo'] !== ''
          ? parseInt(findStartingIndex.report['clockTo'].toString(), 10)
          : null;
        if (clockFrom == null && clockTo != null) {
          return 'The Clock From on the starting defect must have a value';
        }
        if (
          fieldType === DefectCodingFieldType.CLOCK_FROM
          && clockFrom == null
        ) {
          return 'The Clock From on the starting defect must be valid';
        }
        if (
          fieldType === DefectCodingFieldType.CLOCK_TO
          && clockTo == null
          && model != null
          && model !== ''
        ) {
          return 'The Clock To on the starting defect must be valid';
        }
        if (clockFrom == null && clockTo == null && model != null) {
          return 'The starting defect must have at least a Clock From';
        }
        let startclockFromMock = clockFrom;
        let startclockToMock = clockTo;
        let endclockFromMock = this.clockFrom;
        let endclockToMock = this.clockTo;

        if (startclockFromMock >= 10) {
          startclockFromMock -= 12;
        }
        if (startclockToMock >= 10) {
          startclockToMock -= 12;
        }
        if (endclockFromMock >= 10) {
          endclockFromMock -= 12;
        }
        if (endclockToMock >= 10) {
          endclockToMock -= 12;
        }
        if (
          fieldType === DefectCodingFieldType.CLOCK_FROM
          && endclockFromMock > startclockFromMock + 2
          && !this.editMatchingContinuousDefect
        ) {
          return "This value must be within 2 from the starting index's clock from.";
        }
        if (
          fieldType === DefectCodingFieldType.CLOCK_TO
          && endclockToMock > startclockToMock + 2
          && !this.editMatchingContinuousDefect
        ) {
          return "This value must be within 2 from the starting index's clock to.";
        }
        startclockFromMock = clockFrom;
        startclockToMock = clockTo;
        endclockFromMock = this.clockFrom;
        endclockToMock = this.clockTo;

        if (startclockFromMock <= 2) {
          startclockFromMock += 12;
        }
        if (startclockToMock <= 2) {
          startclockToMock += 12;
        }
        if (endclockFromMock <= 2) {
          endclockFromMock += 12;
        }
        if (endclockToMock <= 2) {
          endclockToMock += 12;
        }
        if (!this.editMatchingContinuousDefect) {
          if (
            fieldType === DefectCodingFieldType.CLOCK_FROM
            && endclockFromMock < startclockFromMock - 2
          ) {
            return "This value must be within 2 from the starting index's clock from.";
          }
          if (
            fieldType === DefectCodingFieldType.CLOCK_TO
            && endclockToMock < startclockToMock - 2
          ) {
            return "This value must be within 2 from the starting index's clock to.";
          }

          if (
            fieldType === DefectCodingFieldType.CLOCK_FROM
            && clockFrom > 2
            && clockFrom < 10
          ) {
            if (Math.abs(clockFrom - this.clockFrom) > 2) {
              return "This value must be within 2 from the starting index's clock from.";
            }
          }
          if (
            fieldType === DefectCodingFieldType.CLOCK_TO
            && clockTo > 2
            && clockTo < 10
          ) {
            if (Math.abs(clockTo - this.clockTo) > 2) {
              return "This value must be within 2 from the starting index's clock to.";
            }
          }
        }
      } else {
        return "Can't validate field. There is no starting index for this defect";
      }
    }
    if (
      fieldType === DefectCodingFieldType.CLOCK_TO
      || fieldType === DefectCodingFieldType.CLOCK_FROM
    ) {
      if (model != null && model !== '') {
        if (model < 1 || model > 12) {
          if (model != null) {
            return 'Clock position must be between 1-12';
          }
        }
      }
    }
    if (typeof requirements !== 'string' && Array.isArray(requirements)) {
      if (requirements.length > 0 && typeof requirements[0] === 'number') {
        const isNull = model == null || model === '';
        const isOptional = required === 'Optional';
        let isRequired: boolean;
        if (isOptional && isNull) {
          isRequired = !isNull && isOptional;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          isRequired = isNull;
        }
        // eslint-disable-next-line eqeqeq
        if (requirements.findIndex((value) => value == model) !== -1) {
          return true;
        }
        return `This value needs to be one of the following: ${requirements.join(
          ', ',
        )}`;
      }
      if (requirements.length > 0 && typeof requirements[0] === 'string') {
        let returnValue: string | boolean = true;
        const failMessage = `This value needs to be one of the following: ${requirements.join(
          ', ',
        )}`;
        const isNull = model == null || model === '';
        const isOptional = required === 'Optional';
        let isRequired: boolean;
        if (isOptional && isNull) {
          isRequired = !isNull && isOptional;
        } else {
          isRequired = isNull;
        }
        (requirements as string[]).forEach((req: string) => {
          if (req.includes('<=')) {
            let strNumber = req;
            strNumber = strNumber.replace('<=', '');
            const num = parseInt(strNumber, 10);
            if (isRequired || model > num) {
              returnValue = failMessage;
            }
          } else if (req.includes('<')) {
            let strNumber = req;
            strNumber = strNumber.replace('<', '');
            const num = parseInt(strNumber, 10);
            if (isRequired || model >= num) {
              returnValue = failMessage;
            }
          } else if (req.includes('>=')) {
            let strNumber = req;
            strNumber = strNumber.replace('>=', '');
            const num = parseInt(strNumber, 10);
            if (isRequired || model < num) {
              returnValue = failMessage;
            }
          } else if (req.includes('>')) {
            let strNumber = req;
            strNumber = strNumber.replace('>', '');
            const num = parseInt(strNumber, 10);
            if (isRequired || model <= num) {
              returnValue = failMessage;
            }
          }
        });
        return returnValue;
      }
    } else if (requirements !== '') {
      if (model === requirements) {
        return true;
      }
      return `This value should be: ${requirements}`;
    }
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getRequired(mainreq: string, model: any): string | boolean {
    if (
      mainreq != null
      && mainreq === this.requiredString
      && model != null
      && model !== ''
    ) {
      return true;
    }
    if (mainreq != null && mainreq === this.requiredString) {
      return 'This is a required field';
    }
    return true;
  }

  getDisabled(mainDisable: string): string | boolean {
    if (this.selectedDefectCode != null) {
      if (mainDisable != null && mainDisable !== 'Disabled') {
        return false;
      }
      return 'disabled';
    }
    return 'disabled';
  }

  resetForm(resetDefect = true): void {
    if (resetDefect) {
      (this.$refs['selectDefect'] as any).reset();
      this.selectedDefectCode = undefined;
    }
    if (this.$refs['selectComponent'] != null) {
      (this.$refs['selectComponent'] as any).reset();
      this.selectedComponent = undefined;
    }
    (this.$refs['clockFrom'] as any).reset();
    this.clockFrom = undefined;
    (this.$refs['clockTo'] as any).reset();
    this.clockTo = undefined;
    (this.$refs['percentage'] as any).reset();
    this.percentage = undefined;
    (this.$refs['dimensionOne'] as any).reset();
    this.dimensionOne = undefined;
    (this.$refs['dimensionTwo'] as any).reset();
    this.dimensionTwo = undefined;
    (this.$refs['remarks'] as any).reset();
    this.remarks = undefined;
    this.joint = false;
    this.step = false;
    this.continuous = false;
    this.endContinuous = false;
    this.isRearCamera = false;
    if (this.$refs['selectedStartingIndex'] != null) {
      (this.$refs['selectedStartingIndex'] as any).reset();
    }
    this.selectedStartingIndex = null;
    this.continuousIndex = null;
    if (resetDefect) {
      this.editCondition = null;
      this.editablePayout = 0;
      this.editableImage = null;
    }
    this.validate();
    this.resetDefectCodingPostError();
  }

  resetForContinuous(): void {
    if (this.getDisabled(this.DVO.ClockAtFrom)) {
      (this.$refs['clockFrom'] as any).reset();
      this.clockFrom = undefined;
    }
    if (this.getDisabled(this.DVO.ClockTo)) {
      (this.$refs['clockTo'] as any).reset();
      this.clockTo = undefined;
    }
    if (this.getDisabled(this.DVO.ValuePercent)) {
      (this.$refs['percentage'] as any).reset();
      this.percentage = undefined;
    }
    if (this.getDisabled(this.DVO.Value1stDimension)) {
      (this.$refs['dimensionOne'] as any).reset();
      this.dimensionOne = undefined;
    }
    if (this.getDisabled(this.DVO.Value2ndDimension)) {
      (this.$refs['dimensionTwo'] as any).reset();
      this.dimensionTwo = undefined;
    }
    if (this.getDisabled(this.DVO.Remarks)) {
      (this.$refs['remarks'] as any).reset();
      this.remarks = undefined;
    }

    if (this.getDisabled(this.DVO.Joint)) {
      this.joint = undefined;
    }
    if (this.getDisabled(this.DVO.Step)) {
      this.step = undefined;
    }
    this.isRearCamera = false;
    this.validate();
  }

  getDimensionUnits(): string {
    if (
      this.DVO != null
      && this.DVO.units != null
      && this.DVO.units !== ''
      && this.DVO.units.indexOf('/') !== -1
    ) {
      const split = this.DVO.units.split('/');
      if (split.length === 2) {
        const imperialUnit = split[0];
        const metricUnit = split[1];
        if (this.displayImperial) {
          return `(${imperialUnit})`;
        }
        return `(${metricUnit})`;
      }
    } else if (this.DVO == null) {
      return '';
    }
    return this.DVO.units;
  }

  getUnitsConverted(value: number): number {
    if (
      this.DVO != null
      && this.DVO.units != null
      && this.DVO.units !== ''
      && this.DVO.units.indexOf('/') !== -1
    ) {
      const split = this.DVO.units.split('/');
      if (split.length === 2) {
        const metricUnit = split[1];
        if (!this.displayImperial) {
          switch (metricUnit) {
            case 'mm':
              return (
                Math.round(convert(value).from('mm').to('in') * 1000) / 1000
              );
            case 'm':
              return (
                Math.round(convert(value).from('m').to('ft') * 1000) / 1000
              );
            case 'l':
              return (
                Math.round(convert(value).from('l').to('gal') * 1000) / 1000
              );
            default:
              return Math.round(value * 1000) / 1000;
          }
        }
      }
    }
    return value;
  }

  getUnitsConvertedImport(value: number): number {
    if (
      this.DVO != null
      && this.DVO.units != null
      && this.DVO.units !== ''
      && this.DVO.units.indexOf('/') !== -1
    ) {
      const split = this.DVO.units.split('/');
      if (split.length === 2) {
        const metricUnit = split[1];
        if (!this.displayImperial) {
          switch (metricUnit) {
            case 'mm':
              return Math.round(convert(value).from('in').to('mm') * 1) / 1;
            case 'm':
              return (
                Math.round(convert(value).from('ft').to('m') * 1000) / 1000
              );
            case 'l':
              return (
                Math.round(convert(value).from('gal').to('l') * 1000) / 1000
              );
            default:
              return Math.round(value * 1000) / 1000;
          }
        }
      }
    }
    return value;
  }

  getDistanceUnits(): string {
    return this.displayImperial ? 'ft' : 'm';
  }

  getDisplayDistance(value: number): number {
    return this.displayImperial
      ? Math.round(value * 10) / 10
      : Math.round(convert(value).from('ft').to('m') * 100) / 100;
  }

  countDecimals(value: number): number {
    const text = value.toString();
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [base, trail] = text.split('e-');
      const deg = parseInt(trail, 10);
      return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
      return value.toString().split('.')[1].length || 0;
    }
    return 0;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  filterDefectCodes(item: any, queryText: string): boolean {
    return (
      item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  async setRequiredCheckboxesModels(): Promise<void> {
    if (this.DVO) {
      this.joint = this.DVO.Joint === this.requiredString;
      this.step = this.DVO.Step === this.requiredString;
    }
  }
}
