export interface PhoneInfo {
    phone: string,
    phoneCode: string,
    isInternational: boolean,
}

export default function phoneNumberToPhoneInfo(phoneNumber: string | null): PhoneInfo {
  if (phoneNumber) {
    const internationalRegex = /\+\d+/;
    const internationalPhoneMatch = phoneNumber.match(internationalRegex);
    let phoneCode = '+1';
    let isInternational = false;
    let phone = phoneNumber;

    if (internationalPhoneMatch) {
    // eslint-disable-next-line prefer-destructuring
      phoneCode = internationalPhoneMatch[0];
      isInternational = true;
      phone = phoneNumber.substring(phoneCode.length + 1);
    }
    return {
      phone,
      phoneCode,
      isInternational,
    };
  }
  return {
    phone: '',
    phoneCode: '+1',
    isInternational: false,
  };
}
