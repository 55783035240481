import { render, staticRenderFns } from "./ClientActions.vue?vue&type=template&id=7bfc3e5f&scoped=true&"
import script from "./ClientActions.vue?vue&type=script&lang=ts&"
export * from "./ClientActions.vue?vue&type=script&lang=ts&"
import style0 from "./ClientActions.vue?vue&type=style&index=0&id=7bfc3e5f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bfc3e5f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VDatePicker,VIcon,VList,VListItem,VListItemTitle,VMenu})
