




































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Vue, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { DataRequirement, Field } from './types';

@Component({})
export default class MPSSectionReporting extends Vue {
  @Prop() readonly canEditMPS: boolean;

  @Prop() readonly dataRequirements: DataRequirement[];

  @Prop() readonly requiredField;

  @PropSync('additionalReportingNotes') synchedAdditionalReportingNotes: string;

  @PropSync('asBuiltsProvided') synchedAsBuiltsProvided: boolean;

  @PropSync('deliverableSchedule') synchedDeliverableSchedule: string;

  @PropSync('deliveryMedium') synchedDeliveryMedium: string;

  @PropSync('liningPlan') synchedLiningPlan: string;

  @PropSync('reportingOutputsOptions') synchedReportingOutputsOptions: Field[]

  @PropSync('reportingOutputsPremiumOptions') synchedReportingOutputsPremiumOptions: Field[];

  @PropSync('reportTurnaroundTime') synchedReportTurnaroundTime: string;

  @PropSync('aiCodingOutputsOptions') synchedAICodingOutputsOptions: Field[];

  @PropSync('surveyCoordinatesProvided') synchedSurveyCoordinatesProvided: boolean;

  @PropSync('valid') synchedValid: boolean;

  formValid = false;

  @Watch('synchedReportingOutputsOptions', { deep: true })
  onSynchedReportingOutputsOptionsChange(): void {
    const macpDbOption = this.synchedReportingOutputsOptions.find((o) => o.label === 'MACP DB');

    if (macpDbOption.value === false) {
      // eslint-disable-next-line no-param-reassign
      macpDbOption.subOptions.forEach((so) => { so.value = false; });
    }
  }

  @Watch('allValid')
  onValidChange(): void {
    this.synchedValid = this.allValid;
  }

  get allValid(): boolean {
    return this.reportingOutputsValid
      && this.deliverableSchedValid
      && this.formValid
      && this.premiumValid;
  }

  get deliverableSchedValid(): boolean {
    return this.synchedDeliverableSchedule !== undefined
      && this.synchedDeliverableSchedule !== null
      && this.synchedDeliverableSchedule !== '';
  }

  get premiumReporting(): boolean {
    return this.synchedReportingOutputsPremiumOptions.some((option) => option.value === true);
  }

  get premiumRequired(): boolean {
    return this.dataRequirements.some((req) => req.inspectionType === 'Premium');
  }

  get premiumValid(): boolean {
    return !this.premiumRequired
      ? true
      : this.synchedReportingOutputsPremiumOptions.some((o) => o.value === true);
  }

  get reportingOutputsValid(): boolean {
    return this.synchedReportingOutputsOptions.some((o) => o.value === true);
  }

  resetValidation(): void {
    (this.$refs.reportingForm as any).reset();
  }
}
