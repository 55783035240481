import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import { authGuard } from '@/auth/authGuard';
import AdminPanel from '@/components/Admin/AdminPanel.vue';
import Project from '../views/Project/Project.vue';
import Asset from '../views/Asset/Asset.vue';
import Profile from '../views/Profile/Profile.vue';
import ProjectSelection from '../views/Profile/ProjectSelection.vue';
import EmbeddedViewer from '../components/EmbeddedViewer/EmbeddedViewer.vue';
import Error from '../views/Error/Error.vue';
import Forbidden from '../views/Error/Forbidden.vue';
import UserEdit from '../views/Admin/EditUser.vue';
import ScopeHistory from '../components/ScopeHistory/ScopeHistory.vue';
import storeUtility from '../store/utils';
import CodingForm from '../components/CodingForm/CodingForm.vue';
import WorkOrder from '../components/WorkOrder/WorkOrder.vue';
import Routing from '../components/Routing/Routing.vue';
import ProjectDashboard from '../views/Dashboards/ProjectDashboard/ProjectDashboard.vue';
import CrewDashboard from '../views/Dashboards/CrewDashboard/CrewDashboard.vue';
import ImportBase from '../views/ImportBase/ImportBase.vue';
import MPSBase from '../views/MasterProjectSummary/MPSBase.vue';
import Reporting from '../views/Reporting/Reporting.vue';
import Deployments from '../views/Deployments/Deployments.vue';
import Inventory from '../views/Inventory/Inventory.vue';
import WinCanWorkOrder from '../views/WinCanWorkOrder/WinCanWorkOrder.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    // Then for the time being we will be routed to the first project.
    path: '/',
    beforeEnter: async (_to, _from, next) => {
      let path = '/projectSelection';

      try {
        const api = await storeUtility.useIntegrityAPI();
        const { data } = await api.getProjectsSimple();
        if (data.length === 1) {
          path = `/projects/["${data[0]}"]`;
        }
      } catch (e) {
        path = '/error';
      }

      next(path);
    },
  },
  {
    path: '/projects/:ids',
    name: 'projects',
    props: true,
    component: Project,
    meta: {
      breadCrumb() {
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
          },
        ];
      },
    },
  },
  {
    path: '/projects/:ids/dashboard',
    name: 'dashboard',
    props: true,
    component: ProjectDashboard,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.ids;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Dashboard',
          },
        ];
      },
    },
  },
  {
    path: '/projects/:ids/dashboard/crew',
    name: 'crewDashboard',
    props: true,
    component: CrewDashboard,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.ids;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Dashboard',
          },
        ];
      },
    },
  },
  {
    path: '/assets/:idList/:id',
    name: 'assetsList',
    props: true,
    component: Asset,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.idList;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Asset',
          },
        ];
      },
    },
  },
  {
    path: '/assets/:id',
    name: 'assets',
    props: true,
    component: Asset,
  },
  {
    path: '/profile',
    name: 'profile',
    props: true,
    component: Profile,
  },
  {
    path: '/editUser/:id',
    name: 'editUser',
    props: true,
    component: UserEdit,
  },
  {
    path: '/projectSelection',
    name: 'projectSelection',
    component: ProjectSelection,
    meta: {
      breadCrumb: [
        { text: 'Project Selection' },
      ],
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPanel,
  },
  {
    path: '/embed',
    component: EmbeddedViewer,
    props: {
      embedLink: 'https://redzone.com/',
    },
  },
  {
    path: '/codingForm',
    component: CodingForm,
    props: {
      isPopout: true,
    },
  },
  {
    path: '/workOrders/:id',
    name: 'workOrders',
    props: true,
    component: WorkOrder,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.id;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            to: {
              name: 'projects',
              params: { ids: `["${paramToProject}"]` },
            },
          },
          {
            text: 'Work Orders',
          },
        ];
      },
    },
  },
  {
    path: '/routing/:id',
    name: 'routing',
    props: true,
    component: Routing,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.id;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            to: {
              name: 'projects',
              params: { ids: `["${paramToProject}"]` },
            },
          },
          {
            text: 'Routing',
          },
        ];
      },
    },
  },
  {
    path: '/aicoding/:id',
    name: 'aicoding',
    props: true,
    component: WinCanWorkOrder,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.id;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            to: {
              name: 'projects',
              params: { ids: `["${paramToProject}"]` },
            },
          },
          {
            text: 'AI Coding',
          },
        ];
      },
    },
  },
  {
    path: '/projects/:projectGuid/import',
    name: 'import',
    props: true,
    component: ImportBase,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.projectGuid;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: `["${paramToProject}"]` },
            },
          },
          {
            text: 'Import',
          },
        ];
      },
    },
  },
  {
    path: '/projects/:projectGuids/assetHistory',
    name: 'assetManagement',
    props: true,
    component: ScopeHistory,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.projectGuids;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Asset History',
          },
        ];
      },
    },
  },
  {
    path: '/projectSelection/mps/:guid?',
    name: 'MPS',
    props: true,
    component: MPSBase,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.guid;
        return [
          {
            text: 'Project Selection',
            exact: true,
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            to: {
              name: 'projects',
              params: { ids: `["${paramToProject}"]` },
            },
          },
          {
            text: 'MPS',
          },
        ];
      },
    },
  },
  {
    path: '/reporting',
    name: 'reporting',
    component: Reporting,
    meta: {
      breadCrumb: [
        { text: 'Reporting' },
      ],
    },
  },
  {
    path: '/projects/:ids/inventory',
    name: 'inventory',
    component: Inventory,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.ids;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Asset Inventory',
          },
        ];
      },
    },
  },
  {
    path: '/projects/:ids/deployments',
    name: 'deployments',
    props: true,
    component: Deployments,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.ids;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Deployments',
          },
        ];
      },
    },
  },
  {
    path: '/Error',
    name: 'Error',
    props: true,
    component: Error,
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden,
    props: (route) => ({
      ...route.params,
    }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'Catchall',
    component: Error,
    props: (route) => ({
      ...route.params,
    }),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  authGuard(to, from, next);
});

export default router;
