import convert from 'convert-units';

function formatNumber(val: number): string {
  return Math.round(val).toLocaleString();
}

function getConvertedDistance(distance: number, displayImperial: boolean): string {
  let retVal = 0;
  const suffix = displayImperial ? 'ft' : 'm';

  if (distance) {
    retVal = displayImperial
      ? Math.round(distance + Number.EPSILON)
      : Math.round(((distance / 3.2808) + Number.EPSILON) * 100) / 100;
  }

  return `${formatNumber(retVal)} ${suffix}`;
}

function getDisplayDistanceFtM(displayImperial: boolean, value: number): number {
  return displayImperial
    ? Math.round(value * 10) / 10
    : Math.round(convert(value).from('ft').to('m') * 100) / 100;
}

export default {
  formatNumber,
  getConvertedDistance,
  getDisplayDistanceFtM,
};
