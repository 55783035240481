

































// Emitted Events
// changeImageHeight: Number
// changeViewerCanvasWidth: Number
// changeViewerCanvasHeight: Number
// changeZoomCanvasWidth: Number
// changeZoomCanvasHeight: Number
// changeRectangleWidth: Number
// changeRectangleHeight: Number
// changeRectangleX: Number
// changeRectangleY: Number
// changeMouseX: Number
// changeMouseY: Number
// changeZoomBool: Boolean
// changeZoomPercent: Number

import Vue from 'vue';

export default Vue.extend({
  name: 'FlatViewer',
  props: {
    imageSource: {
      type: String,
      required: true,
    },
    toggleHUD: {
      type: Boolean,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    payout: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    if (document.getElementsByClassName('fvz').length !== 0) {
      window.onresize = this.updateHtml;
      window.onmouseup = this.onMouseUp;
      window.onmouseup = this.onMouseUpZoom;
      this.getPixelDensity();
    }
    this.updateHtml();
    this.onUpdatePayout((this.payout / this.depth) * 100);
  },
  data: () => ({
    dpp: 0,
    enableWindow: true,
    lastx: 0,
    lasty: 0,
    lastZoomx: 0,
    lastZoomy: 0,
    firstMeasurex: 0,
    lastMeasurex: 0,
    firstMeasurey: 0,
    lastMeasurey: 0,
    rectWidth: 0,
    rectHeight: 0,
    rectx: 0,
    recty: 0,
    zoomed: false,
    zoomPercent: 0,
    zoomPercentWidth: 0,
    zoomPercentHeight: 0,
    zoomLimit: 4,
    dragging: false,
    measuring: false,
    instruction: true,
  }),
  watch: {
    imageSource: {
      immediate: false,
      handler(value: string) {
        (document.getElementById(
          'flatviewerlookimage',
        ) as HTMLImageElement).src = value;
        this.updateHtml();
        this.getPixelDensity();
      },
    },
    depth: {
      immediate: false,
      handler() {
        this.getPixelDensity();
      },
    },
    payout: {
      immediate: true,
      handler(value: number) {
        this.onUpdatePayout((value / this.depth) * 100.0);
      },
    },
    toggleHUD: {
      immediate: true,
      handler(value: boolean) {
        this.enableWindow = value;
      },
    },
  },
  methods: {
    onButtonClick() {
      this.zoomPercentWidth = 1;
      this.zoomPercentHeight = 1;
      this.zoomed = false;
      this.zoomPercent = 0;
      this.updateHtml();
    },
    onUpdatePayout(value: number) {
      const canvas = document.getElementById(
        'flatviewerlook',
      ) as HTMLCanvasElement;

      this.lasty = ((canvas.height - this.rectHeight) * (value / 100)) + (this.rectHeight / 2);
      this.updateHtml();
    },
    updateHtml() {
      this.getPixelDensity();
      const canvas = document.getElementById(
        'flatviewerlook',
      ) as HTMLCanvasElement;
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      const image = document.getElementById(
        'flatviewerlookimage',
      ) as HTMLImageElement;
      const flex = document.getElementsByClassName(
        'flatviewer',
      )[0] as HTMLElement;
      const fvzdiv = document.getElementById(
        'flatviewerzoomdiv',
      ) as HTMLElement;
      this.$emit('changeImageHeight', image.height);
      canvas.width = parseInt(
        window.getComputedStyle(flex, null).getPropertyValue('width'),
        10,
      );
      canvas.height = parseInt(
        window.getComputedStyle(flex, null).getPropertyValue('height'),
        10,
      );
      this.$emit('changeViewerCanvasWidth', canvas.width);
      this.$emit('changeViewerCanvasHeight', canvas.height);
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      const canvasZoom = document.getElementById(
        'flatviewerzoom',
      ) as HTMLCanvasElement;
      const contextZoom = canvasZoom.getContext(
        '2d',
      ) as CanvasRenderingContext2D;
      canvasZoom.width = parseInt(
        window.getComputedStyle(fvzdiv, null).getPropertyValue('width'),
        10,
      );
      canvasZoom.height = parseInt(
        window.getComputedStyle(fvzdiv, null).getPropertyValue('height'),
        10,
      );
      this.$emit('changeZoomCanvasWidth', canvasZoom.width);
      this.$emit('changeZoomCanvasHeight', canvasZoom.height);

      if (!this.zoomed) {
        this.rectWidth = canvas.width;
        this.rectHeight = canvas.height / 4;
      } else {
        this.rectWidth = canvas.width / this.zoomPercent;
        this.rectHeight = canvas.height / 4 / this.zoomPercent;
      }
      if (this.zoomPercentWidth !== 0 && this.zoomPercentHeight !== 0) {
        this.zoomPercentWidth = canvasZoom.width / (image.width / canvas.width);
        this.zoomPercentHeight = canvasZoom.height / (image.height / canvas.height);
        this.rectWidth = this.zoomPercentWidth;
        this.rectHeight = this.zoomPercentHeight;
      }
      this.computeRectPosition();
      this.$emit('changeRectangleWidth', this.rectWidth);
      this.$emit('changeRectangleHeight', this.rectHeight);
      this.$emit('changeRectangleX', this.rectx);
      this.$emit('changeRectangleY', this.recty);
      this.$emit(
        'flatViewerPercent',
        (this.recty / (canvas.height - this.rectHeight)) * 100,
      );
      this.$emit(
        'changeAngle',
        180
          * ((this.rectx + this.rectWidth / 2 - canvas.width / 2)
            / (canvas.width / 2)),
      );
      context.beginPath();
      context.strokeStyle = '#e61e25';
      context.lineWidth = 3;
      context.rect(this.rectx, this.recty + 1, this.rectWidth, this.rectHeight);
      context.stroke();

      contextZoom.globalAlpha = 1;
      contextZoom.drawImage(
        image,
        this.rectx * (image.width / canvas.width),
        this.recty * (image.height / canvas.height),
        this.rectWidth * (image.width / canvas.width),
        this.rectHeight * (image.height / canvas.height),
        0,
        0,
        canvasZoom.width,
        canvasZoom.height,
      );
      const widthRatio = canvasZoom.width / this.rectWidth;
      const heightRatio = canvasZoom.height / this.rectHeight;
      if (this.firstMeasurex !== 0 && this.firstMeasurey !== 0
      && this.lastMeasurex !== 0 && this.lastMeasurey !== 0) {
        contextZoom.beginPath();
        contextZoom.strokeStyle = 'green';
        contextZoom.lineWidth = 3;
        contextZoom.moveTo((this.firstMeasurex - this.rectx) * widthRatio,
          (this.firstMeasurey - this.recty) * heightRatio);
        contextZoom.lineTo((this.lastMeasurex - this.rectx) * widthRatio,
          (this.lastMeasurey - this.recty) * heightRatio);
        contextZoom.stroke();
        contextZoom.beginPath();
        contextZoom.strokeStyle = '#e61e25';
        contextZoom.lineWidth = 3;
        contextZoom.arc((this.firstMeasurex - this.rectx) * widthRatio,
          (this.firstMeasurey - this.recty) * heightRatio, 1, 0, 2 * Math.PI);
        contextZoom.stroke();
        contextZoom.beginPath();
        contextZoom.arc((this.lastMeasurex - this.rectx) * widthRatio,
          (this.lastMeasurey - this.recty) * heightRatio, 1, 0, 2 * Math.PI);
        contextZoom.stroke();
        const widthPercent = canvas.width / image.width;
        const heightPercent = canvas.height / image.height;
        const distance = Math.sqrt(
          ((this.firstMeasurex - this.lastMeasurex) / widthPercent) ** 2
          + ((this.firstMeasurey - this.lastMeasurey) / heightPercent) ** 2,
        );
        const measure = (document.getElementById('measure') as HTMLElement);
        measure.innerHTML = `${String(distance * this.dpp)}ft`;
      } else {
        const measure = (document.getElementById('measure') as HTMLElement);
        measure.innerHTML = 'Shift + Click to Measure';
      }
      if (this.instruction) {
        contextZoom.beginPath();
        contextZoom.strokeStyle = 'black';
        contextZoom.fillStyle = 'black';
        contextZoom.lineWidth = 1;
        contextZoom.globalAlpha = 0.5;
        contextZoom.fillRect(0, 0, canvasZoom.width, canvasZoom.height);
        contextZoom.stroke();
        contextZoom.beginPath();
        contextZoom.strokeStyle = 'white';
        contextZoom.fillStyle = 'white';
        contextZoom.globalAlpha = 1;
        contextZoom.font = '20px Arial';
        contextZoom.textAlign = 'center';
        contextZoom.fillText('Click and Drag to Move',
          canvasZoom.width / 2, canvasZoom.height / 2 - 25);
        contextZoom.fillText('Scroll to Zoom',
          canvasZoom.width / 2, canvasZoom.height / 2);
        contextZoom.fillText('Shift Click to Start/End Measure Tool',
          canvasZoom.width / 2, canvasZoom.height / 2 + 25);
        contextZoom.fillText('Click to Continue',
          canvasZoom.width / 2, canvasZoom.height / 2 + 50);
      }
    },
    computeRectPosition() {
      const canvas = document.getElementById(
        'flatviewerlook',
      ) as HTMLCanvasElement;
      this.rectx = this.lastx - this.rectWidth / 2;
      this.recty = this.lasty - this.rectHeight / 2;
      if (this.rectx < 0) {
        this.rectx = 0;
      }
      if (this.recty < 0) {
        this.recty = 0;
      }
      if (this.lastx + this.rectWidth / 2 > canvas.width) {
        this.rectx = canvas.width - this.rectWidth;
      }
      if (this.lasty + this.rectHeight / 2 > canvas.height) {
        this.recty = canvas.height - this.rectHeight;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateMouse(event: any) {
      const canvas = document.getElementById(
        'flatviewerlook',
      ) as HTMLCanvasElement;
      const rect = canvas.getBoundingClientRect();
      this.lastx = event.clientX - rect.left;
      this.lasty = event.clientY - rect.top;
      this.$emit('changeMouseX', this.lastx);
      this.$emit('changeMouseY', this.lasty);
      this.updateHtml();
    },
    onMouseDown(event: MouseEvent) {
      if (!this.instruction) {
        this.dragging = true;
        this.updateMouse(event);
      }
    },
    onMouseUp(event: MouseEvent) {
      if (!this.instruction) {
        this.dragging = false;
        this.updateMouse(event);
      }
    },
    onMouseMove(event: MouseEvent) {
      if (this.dragging) {
        this.updateMouse(event);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateMouseZoom(event: any) {
      const canvas = document.getElementById(
        'flatviewerlook',
      ) as HTMLCanvasElement;
      this.lastx = this.rectx + (this.rectWidth / 2);
      this.lasty = this.recty + (this.rectHeight / 2);
      this.$emit('changeMouseX', this.lastx);
      this.$emit('changeMouseY', this.lasty);
      const rect = canvas.getBoundingClientRect();
      if (this.lastZoomx < event.clientX - rect.left) {
        this.lastx -= Math.abs(this.lastZoomx - (event.clientX - rect.left)) / 2;
      } else {
        this.lastx += Math.abs(this.lastZoomx - (event.clientX - rect.left)) / 2;
      }
      if (this.lastZoomy < event.clientY - rect.top) {
        this.lasty -= Math.abs(this.lastZoomy - (event.clientY - rect.top)) / 2;
      } else {
        this.lasty += Math.abs(this.lastZoomy - (event.clientY - rect.top)) / 2;
      }
      this.lastZoomx = event.clientX - rect.left;
      this.lastZoomy = event.clientY - rect.top;
      this.updateHtml();
    },
    onMouseDownZoom(event: MouseEvent) {
      if (!this.instruction) {
        const canvas = document.getElementById(
          'flatviewerlook',
        ) as HTMLCanvasElement;
        const canvaszoom = document.getElementById(
          'flatviewerzoom',
        ) as HTMLCanvasElement;
        const rect = canvas.getBoundingClientRect();
        const rectzoom = canvaszoom.getBoundingClientRect();
        const widthRatio = canvaszoom.width / this.rectWidth;
        const heightRatio = canvaszoom.height / this.rectHeight;
        if (event.shiftKey) {
          if (this.measuring) {
            this.lastMeasurex = ((event.clientX - rectzoom.left) / widthRatio) + this.rectx;
            this.lastMeasurey = ((event.clientY - rectzoom.top) / heightRatio) + this.recty;
            this.measuring = false;
          } else {
            this.firstMeasurex = ((event.clientX - rectzoom.left) / widthRatio) + this.rectx;
            this.firstMeasurey = ((event.clientY - rectzoom.top) / heightRatio) + this.recty;
            this.lastMeasurex = ((event.clientX - rectzoom.left) / widthRatio) + this.rectx;
            this.lastMeasurey = ((event.clientY - rectzoom.top) / heightRatio) + this.recty;
            this.measuring = true;
          }
          this.updateHtml();
        } else {
          this.lastZoomx = event.clientX - rect.left;
          this.lastZoomy = event.clientY - rect.top;
          this.dragging = true;
        }
      } else {
        this.instruction = false;
        this.updateHtml();
      }
    },
    onMouseUpZoom() {
      this.dragging = false;
      this.lastZoomx = 0;
      this.lastZoomy = 0;
    },
    onMouseMoveZoom(event: MouseEvent) {
      if (this.dragging) {
        this.updateMouseZoom(event);
      }
      if (this.measuring) {
        const canvaszoom = document.getElementById(
          'flatviewerzoom',
        ) as HTMLCanvasElement;
        const rectzoom = canvaszoom.getBoundingClientRect();
        const widthRatio = canvaszoom.width / this.rectWidth;
        const heightRatio = canvaszoom.height / this.rectHeight;
        this.lastMeasurex = ((event.clientX - rectzoom.left) / widthRatio) + this.rectx;
        this.lastMeasurey = ((event.clientY - rectzoom.top) / heightRatio) + this.recty;
        this.updateHtml();
      }
    },
    preUpdateZoom(event: WheelEvent) {
      if (!this.instruction) {
        if (event.cancelable) {
          event.preventDefault();
        }
        this.lastx = this.rectx + (this.rectWidth / 2);
        this.lasty = this.recty + (this.rectHeight / 2);
        this.$emit('changeMouseX', this.lastx);
        this.$emit('changeMouseY', this.lasty);
        this.updateZoom(event);
      }
    },
    updateZoom(event: WheelEvent) {
      if (!this.instruction) {
        if (event.cancelable) {
          event.preventDefault();
        }
        this.zoomPercentWidth = 0;
        this.zoomPercentHeight = 0;
        const direction = Math.sign(event.deltaY) * -0.1;
        if (direction !== undefined) {
          if (this.zoomed && this.zoomPercent < this.zoomLimit) {
            this.zoomPercent += direction;
            if (this.zoomPercent <= 1) {
              this.zoomPercent = 0;
              this.zoomed = false;
            }
          } else if (this.zoomed && direction < 0 && this.zoomPercent >= 2) {
            this.zoomPercent += direction;
          } else if (!this.zoomed && direction > 0) {
            this.zoomPercent += 1;
            this.zoomed = true;
            this.zoomPercent += direction;
          }
          this.updateHtml();
          this.$emit('changeZoomBool', this.zoomed);
          this.$emit('changeZoomPercent', this.zoomPercent);
        }
      }
    },
    toggleZoomWindow() {
      this.enableWindow = !this.enableWindow;
    },
    isWindowVisibleStyle(): string {
      return (this.enableWindow) ? 'visible' : 'invisible';
    },
    getPixelDensity() {
      const image = document.getElementById(
        'flatviewerlookimage',
      ) as HTMLImageElement;
      this.dpp = this.depth / image.height;
      this.$emit('depthPerPixel', this.dpp);
    },
  },

});
