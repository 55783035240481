



















import { Component } from 'vue-property-decorator';
import IntegrityPhoneNumberInput from '../../IntegrityPhoneNumberInput/IntegrityPhoneNumberInput.vue';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import OrganizationEditDialog from './OrganizationEditDialog.vue';

@Component({
  components: {
    IntegrityPhoneNumberInput,
    OrganizationEditDialog,
  },
})
export default class NewOrganizationPopup extends AdminPanelMixin {
  showNewOrgPopupDialog = false;
}
