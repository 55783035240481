import { MutationTree } from 'vuex';
import { DataStandardState } from './types';

// eslint-disable-next-line no-shadow
export enum DataStandardMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_DATA_STANDARD_DATA = 'SET_DATA_STANDARD_DATA',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
}

export const mutations: MutationTree<DataStandardState> = {
  [DataStandardMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
  [DataStandardMutations.SET_DATA_STANDARD_DATA](state, payload: string) {
    state.data = payload;
  },
  [DataStandardMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
};
