/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationTree } from 'vuex';
import { SnackBarMessage, SnackBarState } from './types';
// eslint-disable-next-line no-shadow
export enum SnackBarMutations {
  SET_SNACK_BAR_MESSAGE = 'SET_SNACK_BAR_MESSAGE',
  SET_ERROR='SET_ERROR',
}

export const mutations : MutationTree<SnackBarState> = {
  [SnackBarMutations.SET_SNACK_BAR_MESSAGE](state, payload: SnackBarMessage) {
    state.snackBarMessage = payload;
  },
  [SnackBarMutations.SET_ERROR](state, payload: any) {
    state.snackBarError = payload;
  },
};
