



















































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { AssetActions } from '@/store/asset/actions';
import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SnackBarActions } from '@/store/integritySnackBar/actions';
import { AssetData, ReportingFeedback } from '../../store/asset/types';

const assetModule = namespace('asset');
const integritySnackBar = namespace('integritySnackBar');
@Component
export default class ReportingFeedbackPopout extends Vue {
  @PropSync('popupModel') displayPopup: boolean;

  @Prop() readonly asset!: AssetData;

  @assetModule.State('reportingFeedbackError') reportingFeedbackError: string =
    undefined;

  @assetModule.State('reportingFeedbackLoaded') reportingFeedbackLoaded:
    | boolean
    | undefined = undefined;

  @assetModule.Action(AssetActions.POST_REPORTING_FEEDBACK)
  postReportingFeedback;

  @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

  failureItems = [
    { name: 'Missing Video', value: false },
    { name: 'Poor Quality', value: false },
    { name: 'Purple Images', value: false },
  ];

  otherItem = { name: 'Other (Please Specify)', value: false };

  failureReasonOther: string | undefined = undefined;

  get shouldSpecifyOther(): boolean {
    return this.otherItem.value;
  }

  mounted(): void {
    this.reset();
  }

  reset(): void {
    this.failureReasonOther = this.asset.reportFeedback?.customResponse;
    this.otherItem.value = this.asset.reportFeedback.customResponse !== null;
    if (!this.asset.reportFeedback?.issues) {
      return;
    }
    this.asset.reportFeedback.issues.forEach((x) => {
      this.failureItems.find((item) => item.name === x).value = true;
    });
  }

  submit(): void {
    const reportingData = {
      issues: this.failureItems
        .filter((item) => item.value)
        .map((item) => item.name),
      customResponse: this.shouldSpecifyOther
        ? this.failureReasonOther
        : undefined,
    } as ReportingFeedback;
    this.postReportingFeedback({
      assetGuid: this.asset.guid,
      reportingData,
    }).then(() => {
      this.asset.reportFeedback = reportingData;
      const feedbackMessage = this.reportingFeedbackError
        ? 'Unable to Submit Feedback'
        : 'Feedback Submitted';
      this.showSnackBar({
        message: feedbackMessage,
        color: this.reportingFeedbackError ? 'red' : 'green',
        showSnackBar: true,
      });
      this.displayPopup = false;
    });
  }
}
