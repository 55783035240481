











/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  ChartData,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  Filler,
  PointElement,
);

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Roboto';

@Component({ components: { LineChartGenerator } })
export default class Distribution extends Vue {
  @Prop({ default: 'label' }) readonly datasetIdKey: string;

  @Prop() readonly chartData: ChartData | undefined;

  chartId = 'distributionChart';

  chartClass = 'distribution-chart';

  backgroundBuckets = [
    {
      value: 0,
      color: 'rgba(240, 240, 240, .4)',
    },
    {
      value: 15,
      color: 'rgba(254, 229, 153, .4)',
    },
    {
      value: 33,
      color: 'rgba(247, 177, 106, .4)',
    },
    {
      value: 50,
      color: 'rgba(224, 103, 102, .4)',
    },
  ];

  plugins = [
    {
      id: 'custom_canvas_background_color',
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      beforeDraw: (chart: any) => {
        const {
          ctx,
          chartArea,
        } = chart;

        let xPos = 0;
        let bucketColor = this.backgroundBuckets[0].color;

        const xScale = chart.scales['x'];
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        const gradient = ctx.createLinearGradient(0, 0, chartArea.width, 0);

        this.backgroundBuckets.forEach((bucket) => {
          gradient.addColorStop(xPos, bucketColor);

          xPos = xScale.getPixelForValue(bucket.value) / chartArea.width;
          xPos = Math.min(xPos, 1);

          gradient.addColorStop(xPos, bucketColor);

          bucketColor = bucket.color;
        });
        gradient.addColorStop(xPos, bucketColor);

        ctx.fillStyle = gradient;
        ctx.fillRect(chartArea.left, chartArea.top, chartArea.width, chartArea.height);
        ctx.restore();
      },
    },
  ];

  get chartOptions(): any {
    return {
      pointRadius: 0,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          onClick(e: any) {
            return undefined;
          },
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              let label = '';
              if (context[0].parsed.x !== null) {
                label += (`${context[0].parsed.x}%`);
              }
              return label;
            },
            label: (context) => {
              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += (`${context.parsed.y}`);
              }
              return label;
            },
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          position: 'left',
        },
        x: {
          type: 'linear',
          title: {
            display: true,
            text: 'Wall %',
          },
          ticks: {
            callback(value: string): number {
              return parseInt(value, 10);
            },
            autoSkip: true,
            stepSize: 10,
          },
        },
      },
      spanGaps: true,
    };
  }
}

