



































































































































































/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AssignmentActions } from '@/store/assignments/actions';
import { RoutingActions } from '@/store/routing/actions';
import { RoutingData, RoutingDuplicateObject } from '@/store/routing/types';
import { AssetRef, AssetData } from '@/types';
import Vue from 'vue';
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RoutingDetails } from './types';

const assignmentModule = namespace('assignments');
const routingModule = namespace('routing');

@Component({})
export default class RouteOptions extends Vue {
  @Prop() readonly type: string;

  @Prop({ default: false }) readonly open: boolean;

  @Prop() readonly assets: AssetRef[] | AssetData[];

  @Prop({ default: false }) isEditing: boolean;

  @Prop({ default: '' }) assetType: string;

  @PropSync('synchedColor', { default: '#FFFFFF' }) color: string;

  @PropSync('synchedRouteDescription', { default: '' }) routeDescription: string;

  @PropSync('synchedRouteName', { default: '' }) routeName: string;

  @PropSync('synchedRouteGuid', { default: '' }) routeGuid: string;

  @assignmentModule.Action(AssignmentActions.POST_ROUTE) postRoute;

  @assignmentModule.Action(AssignmentActions.PATCH_ROUTE) patchRoute;

  @assignmentModule.State('routeLoading') routeLoading;

  @assignmentModule.State('routeError') routeError;

  @routingModule.State('routingData') routingData: RoutingData[] | undefined;

  @routingModule.Action(RoutingActions.CHECK_ASSET_DUPLICATES) checkAssetDupes;

  dialog = false;

  assetEdit = false;

  colorSelect = false;

  errorModal = false;

  drop = true;

  swatches = [
    ['#FFFFFF', '#5CF1FF'],
    ['#FF6D6D', '#609DFF'],
    ['#FFB964', '#8771FF'],
    ['#FAFF76', '#FF83FF'],
    ['#90FF7A', '#CCCCCC'],
  ];

  duplicatesFound = false;

  duplicateAssets = [];

  @Watch('open')
  async onOpenChange(): Promise<void> {
    if (this.open) await this.checkPlannedRoutes();
    this.dialog = this.open;
  }

  @Watch('dialog')
  onDialogChange(): void {
    this.$emit('toggleRoute', this.dialog);
  }

  // @Watch('drop')
  // onDropChange(): void {
  //   if (this.drop === false) {
  //     document.querySelector<HTMLElement>('.assets-box').style.height = '100%';
  //   } else {
  //     document.querySelector<HTMLElement>('.assets-box').style.height = '48px';
  //   }
  // }

  @Watch('assets')
  onAssetsChange(): void {
    if (this.assets.length === 0) this.dialog = false;
  }

  async saveRoute(): Promise<void> {
    const exportObject: RoutingDetails = {
      name: this.routeName,
      description: this.routeDescription,
      assetGuids: this.assets.map((asset) => asset.guid),
      assetType: this.assets[0].type,
      detailsJson: JSON.stringify({ color: this.color }),
    };
    if (this.isEditing) {
      exportObject.guid = this.routeGuid;
      exportObject.assetType = this.type;
      await this.patchRoute(JSON.stringify(exportObject));
    } else {
      await this.postRoute(JSON.stringify(exportObject));
    }
    if (this.routeError !== undefined) {
      if (this.routeError.includes('DuplicateDbEntry')) {
        // Duplicate error. Display modal
        this.errorModal = true;
        return;
      }
      // Generic error
      this.$emit('postResult', 'error');
    } else {
      this.resetState();
      this.$emit('postResult', '');
    }
    this.dialog = false;
  }

  removeAsset(asset: AssetRef): void {
    this.$emit('removeAsset', asset);
  }

  resetState(): void {
    this.routeName = '';
    this.routeDescription = '';
    this.color = '#FFFFFF';
  }

  async checkPlannedRoutes(): Promise<void> {
    this.duplicateAssets = [];
    const selectedAssets = this.assets.map((asset) => ({ guid: asset.guid, name: asset.name }));
    const foundDuplicates: RoutingDuplicateObject[] = await this.checkAssetDupes(
      selectedAssets.map((asset) => asset.guid),
    );

    foundDuplicates.forEach((duplicateAsset) => {
      this.duplicateAssets.push({
        name: selectedAssets.find(
          (asset) => (asset.guid === duplicateAsset.nodeGuid),
        ).name,
        route: this.routingData.find((route) => route.guid === duplicateAsset.routeGuid).routeName,
      });
    });

    if (this.duplicateAssets.length > 0) {
      this.duplicatesFound = true;
    } else {
      this.duplicatesFound = false;
    }
  }
}
