export default function getRouteColor(color: string): string | undefined {
  const hexReg = /^#([0-9a-f]{3,4}){1,2}$/i;
  if (color == null || color === '') {
    return '#FFFFFFFF';
  }
  if (hexReg.test(color)) {
    return color;
  } if (hexReg.test(`#${color}`)) {
    return `#${color}`;
  }
  return '#FFFFFFFF';
}
