import { MutationTree } from 'vuex';
import { CustomerData, CustomerState } from './types';

// eslint-disable-next-line no-shadow
export enum CustomerMutations {
  SET_GET_LOADING = 'SET_GET_LOADING',
  SET_GET_LOAD_ERROR = 'SET_GET_LOAD_ERROR',
  SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA',
  SET_POST_LOADING = 'SET_POST_LOADING',
  SET_POST_LOAD_ERROR = 'SET_POST_LOAD_ERROR',
  SET_POST_CUSTOMER_OBJECT = 'SET_POST_CUSTOMER_OBJECT',
  SET_PATCH_LOADING = 'SET_PATCH_LOADING',
  SET_PATCH_LOAD_ERROR = 'SET_PATCH_LOAD_ERROR',
  SET_PATCH_CUSTOMER_OBJECT = 'SET_PATCH_CUSTOMER_OBJECT',
  SET_DELETE_LOADING = 'SET_DELETE_LOADING',
  SET_DELETE_LOAD_ERROR = 'SET_DELETE_LOAD_ERROR',
}

export const mutations: MutationTree<CustomerState> = {
  [CustomerMutations.SET_GET_LOADING](state, payload: boolean) {
    state.getLoading = payload;
  },
  [CustomerMutations.SET_GET_LOAD_ERROR](state, payload: string | undefined) {
    state.getLoadError = payload;
  },
  [CustomerMutations.SET_CUSTOMER_DATA](state, payload: CustomerData[] | undefined) {
    state.customerData = payload;
  },
  [CustomerMutations.SET_POST_LOADING](state, payload: boolean) {
    state.postLoading = payload;
  },
  [CustomerMutations.SET_POST_LOAD_ERROR](state, payload: string | undefined) {
    state.postLoadError = payload;
  },
  [CustomerMutations.SET_POST_CUSTOMER_OBJECT](state,
    payload: CustomerData | undefined) {
    state.postcustomerObject = payload;
  },
  [CustomerMutations.SET_PATCH_LOADING](state, payload: boolean) {
    state.patchLoading = payload;
  },
  [CustomerMutations.SET_PATCH_LOAD_ERROR](state, payload: string | undefined) {
    state.patchLoadError = payload;
  },
  [CustomerMutations.SET_PATCH_CUSTOMER_OBJECT](state,
    payload: CustomerData | undefined) {
    state.patchcustomerObject = payload;
  },
  [CustomerMutations.SET_DELETE_LOADING](state, payload: boolean) {
    state.deleteLoading = payload;
  },
  [CustomerMutations.SET_DELETE_LOAD_ERROR](state, payload: string | undefined) {
    state.deleteLoadError = payload;
  },
};
