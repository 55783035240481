














import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';

@Component({})
export default class TaskTypeTable extends Vue {
  @Prop() readonly type: string;

  @PropSync('toggle') synchedToggle: number;
}
