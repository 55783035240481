















































































































































import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import { UserPrefsActions } from '@/store/userPrefs/actions';
import { AssetMutations } from '@/store/asset/mutations';
import { UserActions } from '@/store/users/actions';
import { DetailedUserData } from '@/store/users/types';
import { AdminActions } from '@/store/admin/actions';

const userPrefsModule = namespace('userPrefs');
const assetModule = namespace('asset');
const userModule = namespace('users');
const adminModule = namespace('admin');

@Component
export default class Profile extends Vue {
  @assetModule.State('hudEnabled') hudEnabled: boolean;

  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  @userPrefsModule.State('useZoomFilter') useZoomFilter: boolean;

  @userPrefsModule.State('useDefectCodeDesc') useDefectCodeDesc: boolean;

  @userModule.State('allDetailedUserData') allUsers: DetailedUserData[];

  @userModule.State('loading') userLoading: boolean;

  @adminModule.State('loading') adminLoading: boolean;

  @assetModule.Mutation(AssetMutations.SET_HUD_ENABLED) setHUDEnabled;

  @userPrefsModule.Action(UserPrefsActions.SET_METRIC_PREFS) setMetricPrefs;

  @userPrefsModule.Action(UserPrefsActions.SET_ZOOM_FILTER) setZoomFilter;

  @userPrefsModule.Action(UserPrefsActions.GET_ZOOM_FILTER) getZoomFilter;

  @userPrefsModule.Action(UserPrefsActions.SET_DEFECT_CODEDESC)
  setDefectCodeDesc;

  @userPrefsModule.Action(UserPrefsActions.GET_DEFECT_CODEDESC)
  getDefectCodeDesc;

  @userModule.Action(UserActions.FETCH_ALL_DETAILED_USER_DATA)
  fetchAllDetailedUserData;

  @adminModule.Action(AdminActions.PATCH_USER) patchUser;

  @Watch('userDetails')
  onUserDetailChange(): void {
    this.userNameEdit = `${this.userDetails.firstname} ${this.userDetails.lastname}`;
    this.userNicknameEdit = this.userDetails.nickname;
    this.nasscoIdEdit = this.userDetails.nasscoid;
  }

  baseURI = window.location.origin;

  isEditing = false;

  userNameEdit = '';

  userNicknameEdit = '';

  nasscoIdEdit = '';

  nasscoIdPattern = /(^U[0-9]*$)|(^P[0-9]*$)/;

  get userDetails(): DetailedUserData {
    let user = null;

    if (this.allUsers) {
      user = this.allUsers.find(
        (u) => u.useridentity && u.useridentity.includes(this.$auth.user.id),
      );
    }

    return user;
  }

  get loading(): boolean {
    return this.userLoading || this.adminLoading;
  }

  mounted(): void {
    if (!this.allUsers) this.fetchAllDetailedUserData();
    this.getZoomFilter();
    this.getDefectCodeDesc();
  }

  async saveEdit(): Promise<void> {
    const userEdit = {
      Guid: this.userDetails.guid,
      Firstname: this.userNameEdit.split(' ')[0],
      Lastname: this.userNameEdit.split(' ')[1],
      nickname: this.userNicknameEdit,
      userRoleObjects: this.userDetails.role,
      nasscoId: this.nasscoIdEdit,
    };

    await this.patchUser(userEdit);
    this.$forceUpdate();
    this.isEditing = false;
  }

  cancelEdit(): void {
    this.isEditing = false;
    this.userNameEdit = `${this.userDetails.firstname} ${this.userDetails.lastname}`;
    this.userNicknameEdit = this.userDetails.nickname;
    this.nasscoIdEdit = this.userDetails.nasscoid;
  }
}
