
































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DeploymentsActions } from '@/store/deployments/actions';
import { AssignmentShort } from '@/store/planning/types';
import { UserActions } from '@/store/users/actions';
import { UserData } from '@/store/users/types';
import {
  Component, Prop, Vue, PropSync, Watch, Emit,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DeploymentCodingForm from '../DeploymentCodingForm/DeploymentCodingForm.vue';

const userModule = namespace('users');
const deploymentModule = namespace('deployments');

@Component({
  components: {
    DeploymentCodingForm,
  },
})

export default class DeploymentForm extends Vue {
  @Prop() readonly assetType: string;

  @Prop() readonly projectWorkOrders: AssignmentShort[];

  @Prop({ default: false }) readonly isEditing: boolean;

  @Prop() readonly editDeploymentGuid: string;

  @PropSync('selectedItems') synchedSelectedItems: AssignmentShort[];

  @PropSync('newDeploymentDialog') synchedNewDeploymentDialog: boolean;

  @userModule.Action(UserActions.FETCH_ALL_USER_DATA) fetchAllUserData;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @userModule.State('loading') usersLoading: boolean;

  @deploymentModule.Action(
    DeploymentsActions.FETCH_BIG_PIPE_DEPLOYMENT_DATA,
  ) fetchBigPipeDeploymentData;

  @deploymentModule.Action(
    DeploymentsActions.FETCH_DEPLOYMENT_NUMBER,
  ) fetchDeploymentNumber;

  @deploymentModule.State('deploymentNumberData') deploymentNumberData: string | undefined;

  closeDialog(): void {
    this.synchedNewDeploymentDialog = false;
  }

  @Watch('synchedNewDeploymentDialog')
  async onDialogChange(): Promise<void> {
    if (this.synchedNewDeploymentDialog) {
      this.fetchDeploymentNumber(this.synchedSelectedItems[0].guid);
      // check users on open, if empty, get users
      if (this.allUsers.length === 0
        && this.usersLoading === false) {
        await this.fetchAllUserData();
      }
    }
  }

  @Watch('editDeploymentGuid')
  async onEditDeploymentGuidChange(): Promise<void> {
    if (this.editDeploymentGuid != null && this.editDeploymentGuid !== '') {
      this.fetchBigPipeDeploymentData(this.editDeploymentGuid);
    }
  }

  mounted(): void {
    if (this.editDeploymentGuid != null && this.editDeploymentGuid !== '') {
      this.fetchBigPipeDeploymentData(this.editDeploymentGuid);
    }
  }

  @Emit('saveResults')
  saveResults(result: string): string {
    return result;
  }
}
