import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { mutations } from './mutations';
import { RoutingState } from './types';

const state : RoutingState = {
  routingData: null,
  routingError: '',
  routingLoading: false,
  deleteRoutingData: undefined,
  deleteRoutingLoading: false,
  deleteRoutingError: undefined,
  simpleRoutingData: undefined,
  routeAssets: [],
};

const integrityRouting: Module<RoutingState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default integrityRouting;
