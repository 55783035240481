






























































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import { namespace } from 'vuex-class';
import { AssetData } from '@/store/asset/types';
import { AssetActions } from '@/store/asset/actions';
import ReportObservation from '../ReportObservation.vue';
import ReportFooter from '../ReportFooter.vue';
import { repairReportFields } from './types';
import util from '../util';

const assetModule = namespace('asset');

@Component({
  components: {
    ReportFooter,
    ReportObservation,
  },
})
export default class RepairReport extends Vue {
  @assetModule.State('asset') asset!: AssetData;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  repairReportFields: repairReportFields | any = {};

  workOrderJson: any;

  // eslint-disable-next-line class-methods-use-this
  formatText(text: string): string {
    if (!text) return '';
    let newText = text;

    newText = newText.replace(/&gt;/g, '>');
    newText = newText.replace(/&lt;/g, '<');
    newText = newText.replace(/&quot;/g, '"');

    return newText;
  }

  getDisplayDistanceInMM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceInMM(this.displayImperial, value)
          + util.getDistanceUnitsInMM(this.displayImperial)
      : '';
  }

  getDisplayDistanceFtM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceFtM(this.displayImperial, value)
          + util.getDistanceUnitsFtM(this.displayImperial)
      : '';
  }

  mounted(): void {
    this.workOrderJson = JSON.parse(this.codingDetail.inspections[0].jsonData);
    // fill fields
    this.fillRepairReportFields();
    // this.fetchAsset();
    this.$forceUpdate();
  }

  fillRepairReportFields(): void {
    this.repairReportFields = {
      name: this.workOrderJson ? this.workOrderJson.PipeSegment : '',
      assignedTo: this.workOrderJson ? this.workOrderJson.Surveyor : '',
      activity: this.workOrderJson ? this.workOrderJson.Activity : '',
      priority: this.item ? this.item.priorityDescription : '',
      area: this.workOrderJson ? this.workOrderJson.Area : '',
      status: this.item ? this.item.status : '',
      workOrderNumber: this.item ? this.item.workOrderNumber : '',
      dateIssued: this.item ? this.formatDate(this.item.createdDate) : '',
      completeBy: this.workOrderJson ? this.formatDate(this.workOrderJson.InspDate) : '',
      workDescription: this.workOrderJson ? this.workOrderJson.Description : '',
      specialInstructions: this.workOrderJson ? this.workOrderJson.SpecialInstruction : '',
      assetNumber: this.workOrderJson ? this.workOrderJson.AssetID : '',
      addressLocation: this.workOrderJson ? this.workOrderJson.Address : '',
      crossStreet: this.workOrderJson ? this.workOrderJson.CrossSt : '',
      size: this.workOrderJson ? this.workOrderJson.Size : '',
      material: this.workOrderJson ? this.workOrderJson.Material : '',
      length: this.workOrderJson ? this.workOrderJson.Length : '',
      inStreet: this.workOrderJson ? this.workOrderJson.SpecialConditions === 'In Street' : '',
      inEasement: this.workOrderJson ? this.workOrderJson.SpecialConditions === 'In Easement' : '',
      workPerformedBy: this.workOrderJson ? this.workOrderJson.WorkCompletedBy : '',
      constructionBegins: this.workOrderJson ? this.formatDate(this.workOrderJson.ConstructionStart) : '',
      dateConstructionCompleted: this.workOrderJson ? this.formatDate(this.workOrderJson.ConstructionEnd) : '',
      inspectionPerformedBy: this.workOrderJson ? this.formatDate(this.workOrderJson.InspectionCompletedBy) : '',
      inspectionDate: this.workOrderJson ? this.formatDate(this.workOrderJson.InspDate) : '',
      descriptionOfWorkPerformed: this.workOrderJson ? this.workOrderJson.WorkCompletedBy : '',
      followUpRootCutTreatment: this.workOrderJson ? this.workOrderJson.FollowUp_RootCut : '',
      followUpPerformInvestigation: this.workOrderJson ? this.workOrderJson.FollowUp_Investigation : '',
      followUpNone: '', // ?
      followUpClean: this.workOrderJson ? this.workOrderJson.FollowUp_Clean : '',
      followUpIncreaseCleaning: this.workOrderJson ? this.workOrderJson.FollowUp_IncreaseClean : '',
      followUpMapChanges: this.workOrderJson ? this.workOrderJson.FollowUp_MapChange : '',
      followUpRepairRehab: this.workOrderJson ? this.workOrderJson.FollowUp_RepairRehab : '',
      followUpDecreaseCleaning: this.workOrderJson ? this.workOrderJson.FollowUp_DecreaseClean : '',
      followUpOther: this.workOrderJson ? this.workOrderJson.FollowUp_Other : '',
      followUpManholeInspection: this.workOrderJson ? this.workOrderJson.FollowUp_MHInspection : '',
      followUpChangeFrequency: this.workOrderJson ? this.workOrderJson.FollowUp_ChangeFreq : '',
      followUpComments: this.workOrderJson ? this.workOrderJson.Comments : '',
    };
  }

  fetchAsset(): void {
    this.fetchAssetData({
      assetId: this.item.nodeGuid,
      inspectionId: this.item.guid,
    }).then(() => {
      this.$forceUpdate();
    });
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    const year = date.getFullYear();
    const month = (1 + date.getMonth());
    const day = date.getDate();

    return `${month}/${day}/${year}`;
  }
}
