/* eslint-disable class-methods-use-this */
import { Condition } from '../../store/asset/types';
import { Marker, MarkerType } from './types';
import util from '../Maps/utils';

export default class ConditionMarker implements Marker {
    Type: MarkerType;

    Condition: Condition;

    constructor(condition:Condition) {
      this.Type = MarkerType.CONDITION;
      this.Condition = condition;
    }

    getReportData(): Record<string, unknown | undefined> {
      return this.Condition.report;
    }

    getTitle(): string {
      return this.Condition.code;
    }

    getDescription(): string {
      return this.Condition.description;
    }

    getDistance(): number {
      if (this.Condition == null || this.Condition.distance == null) {
        return -1;
      }
      return this.Condition.distance;
    }

    getColor(): string {
      if (this.getGrade() === -1) {
        return null;
      }
      return util.getScoreColor(this.getGrade());
    }

    getGrade(): number {
      if (this.Condition == null || this.Condition.report == null) {
        return -1;
      }
      let priority = 0;
      if (this.Condition.report['grade'] == null) {
        if (parseInt(this.Condition.report['omGrade'] as string, 10) > priority) priority = parseInt(this.Condition.report['omGrade'] as string, 10);
        if (parseInt(this.Condition.report['structuralGrade'] as string, 10) > priority) priority = parseInt(this.Condition.report['structuralGrade'] as string, 10);
        if (this.Condition.report['structuralGrade'] == null && this.Condition.report['omGrade'] == null) {
          priority = -1;
        }
      } else if (this.Condition.report['grade'] === '') {
        priority = -1;
      } else {
        priority = parseInt(this.Condition.report['grade'] as string, 10);
      }

      return priority;
    }

    getGuid(): string {
      return this.Condition.guid;
    }
}
