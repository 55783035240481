var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checkbox-group"},[(_vm.checkBoxGroupTitle.length > 0)?_c('div',{class:_vm.checkboxClasses},[_vm._v(" "+_vm._s(_vm.checkBoxGroupTitle)+" ")]):_vm._e(),(_vm.checkBoxFormPageData[0].headername === 'DoNotCode')?_c('div',[_c('v-checkbox',{attrs:{"label":_vm.getItemText(_vm.checkBoxFormPageData[0]),"readonly":_vm.readonly || !_vm.canEdit,"hide-details":""},on:{"change":function($event){_vm.checkBoxChange(
        _vm.checkBoxFormPageData[0].headername,
        _vm.checkBoxCodingForm.find(function (f) { return f.header === _vm.checkBoxFormPageData[0].headername; }).value,
        _vm.checkBoxFormPageData[0].headernumber
      )}},model:{value:(_vm.checkBoxCodingForm[0].value),callback:function ($$v) {_vm.$set(_vm.checkBoxCodingForm[0], "value", $$v)},expression:"checkBoxCodingForm[0].value"}})],1):(_vm.checkBoxFormPageData.length === 1)?_c('div',[_c('v-radio-group',{attrs:{"row":"","hide-details":"","readonly":_vm.readonly || !_vm.canEdit},on:{"change":function($event){return _vm.checkBoxChange(
        _vm.checkBoxFormPageData[0].headername,
        _vm.checkBoxCodingForm[0].value,
        _vm.checkBoxFormPageData[0].headernumber
      )}},model:{value:(_vm.checkBoxCodingForm[0].value),callback:function ($$v) {_vm.$set(_vm.checkBoxCodingForm[0], "value", $$v)},expression:"checkBoxCodingForm[0].value"}},[_c('v-radio',{attrs:{"label":_vm.trueLabel,"value":true}}),_c('v-radio',{attrs:{"label":_vm.falseLabel,"value":false}})],1)],1):_vm._l((_vm.checkBoxFormPageData),function(item,i){return _c('div',{key:i+10000,staticClass:"option",attrs:{"value":item}},[_c('v-checkbox',{attrs:{"label":_vm.getItemText(item),"hide-details":"","readonly":_vm.readonly || !_vm.canEdit},on:{"change":function($event){_vm.checkBoxChange(
        item.headername,
        _vm.checkBoxCodingForm.find(function (f) { return f.header === item.headername; }).value,
        item.headernumber
      )}},model:{value:(_vm.checkBoxCodingForm.find(function (f) { return f.header === item.headername; }).value),callback:function ($$v) {_vm.$set(_vm.checkBoxCodingForm.find(function (f) { return f.header === item.headername; }), "value", $$v)},expression:"checkBoxCodingForm.find((f) => f.header === item.headername).value"}})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }