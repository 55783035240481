



































import {
  CrewCountsDTO, CrewCountsWithAverages, FetchCrewCountsPayload, ProjectTotalsObject,
} from '@/store/metrics/types';
import PmDashboardMixin from '@/views/Dashboards/PmDashboard/PmDashboardMixin.vue';
import {
  Component, Prop,
} from 'vue-property-decorator';
import CrewCountsCard from './CrewCounts/CrewCountsCard.vue';
import ProjectTotalsCard from './ProjectTotalsCard.vue';

@Component({
  components: {
    ProjectTotalsCard,
    CrewCountsCard,
  },
})
export default class ProjectTotals extends PmDashboardMixin {
    @Prop() readonly projectGuid: string;

    get crewCounts(): CrewCountsDTO[] {
      if (this.crewCountsDTOList == null) {
        this.setCrewCountsData([]);
      }
      return this.crewCountsDTOList != null ? this.crewCountsDTOList : [];
    }

    get crewCountAverages(): CrewCountsWithAverages[] {
      if (this.crewCountsDTOList == null) {
        this.setCrewCountAveragesData([]);
      }
      return this.crewCountsWithAverages != null ? this.crewCountsWithAverages : [];
    }

    get projectTotals(): ProjectTotalsObject[] {
      return this.projectTotalsList != null ? [...this.projectTotalsList] : [];
    }

    get projectStartDate(): Date {
      if (this.allProjects) {
        return new Date(this.allProjects.find((p) => p.guid === this.projectGuid).startDate);
      } return new Date();
    }

    // Returns project end date or today's date if project end date is null
    get projectEndDate(): Date {
      const { endDate } = this.allProjects.find((p) => p.guid === this.projectGuid);
      return endDate ? new Date(endDate) : new Date();
    }

    async refreshCrewCounts(
      platformName: string,
      startDate: string,
      endDate: string,
    ): Promise<void> {
      const dto = this.crewCountsDTOList.find((value) => value.jobName === platformName);

      const crewCountsPayload: FetchCrewCountsPayload = {
        projectGuid: this.projectGuid,
        platformName,
        startDate,
        endDate,
      };
      await this.fetchSingleCrewCount(crewCountsPayload);
      if (this.updateCrewCountsDTO == null) {
        return;
      }
      if (dto == null) {
        if (this.crewCountsDTOList == null) {
          this.setCrewCountsData([]);
        }
        const newCrewCountsDTOList = [...this.crewCountsDTOList];
        newCrewCountsDTOList.push({ ...this.updateCrewCountsDTO });
        this.setCrewCountsData(newCrewCountsDTOList);
        return;
      }
      const newCrewCountsDTOList = [...this.crewCountsDTOList];
      const indexToReplace = newCrewCountsDTOList.findIndex(
        (value) => value.jobName === platformName,
      );
      newCrewCountsDTOList[indexToReplace] = { ...this.updateCrewCountsDTO };
      this.setCrewCountsData(newCrewCountsDTOList);
    }

    async refreshCrewCountAverages(
      platformName: string,
      startDate: string,
      endDate: string,
    ): Promise<void> {
      const dto = this.crewCountsWithAverages.find((value) => value.jobName === platformName);

      const crewCountsPayload: FetchCrewCountsPayload = {
        projectGuid: this.projectGuid,
        platformName,
        startDate,
        endDate,
      };
      await this.fetchSingleCrewCountAverage(crewCountsPayload);
      if (this.updateCrewCountAverageDTO == null) {
        return;
      }
      if (dto == null) {
        if (this.crewCountsWithAverages == null) {
          this.setCrewCountAveragesData([]);
        }
        const newCrewCountAveragesDTOList = [...this.crewCountsWithAverages];
        newCrewCountAveragesDTOList.push({ ...this.updateCrewCountAverageDTO });
        this.setCrewCountAveragesData(newCrewCountAveragesDTOList);
        return;
      }
      const newCrewCountAveragesDTOList = [...this.crewCountsWithAverages];
      const indexToReplace = newCrewCountAveragesDTOList.findIndex(
        (value) => value.jobName === platformName,
      );
      newCrewCountAveragesDTOList[indexToReplace] = { ...this.updateCrewCountAverageDTO };
      this.setCrewCountAveragesData(newCrewCountAveragesDTOList);
    }

    getCrewCountByProjectTotalByPlatformName(platformName: string): CrewCountsDTO {
      return this.crewCounts.find((value) => value.jobName === platformName);
    }

    getCrewCountAveragesByProjectTotalByPlatformName(platformName: string): CrewCountsWithAverages {
      return this.crewCountAverages.find((value) => value.jobName === platformName);
    }
}
