import { render, staticRenderFns } from "./CheckBoxGroup.vue?vue&type=template&id=b2224f4c&scoped=true&"
import script from "./CheckBoxGroup.vue?vue&type=script&lang=ts&"
export * from "./CheckBoxGroup.vue?vue&type=script&lang=ts&"
import style0 from "./CheckBoxGroup.vue?vue&type=style&index=0&id=b2224f4c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2224f4c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VCheckbox,VRadio,VRadioGroup})
