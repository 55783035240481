











































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Prop, Watch } from 'vue-property-decorator';
import StackedHorizontalGraph from '@/components/MSIGraph/StackedHorizontalGraph.vue';

import WorkOrderPieChart from '@/components/Dashboards/WorkOrderPieChart.vue';
import DataDeliveryChart from '@/components/Dashboards/PmDashboardComponents/DataDeliveryChart.vue';
import CustomerDashboardCollectionTable from '@/components/Dashboards/CustomerDashboardCollectionTable.vue';
import { FetchCrewCountsPayload } from '@/store/metrics/types';
import ProjectTotals from '@/components/Dashboards/PmDashboardComponents/ProjectTotals/ProjectTotals.vue';
import ProjectOverviewGraph from '@/components/Dashboards/ProjectOverviewGraph.vue';
import PmDashboardMixin from './PmDashboardMixin.vue';

@Component({
  components: {
    StackedHorizontalGraph,
    DataDeliveryChart,
    ProjectTotals,
    WorkOrderPieChart,
    ProjectOverviewGraph,
    CustomerDashboardCollectionTable,
  },
})
export default class PmDashboard extends PmDashboardMixin {
  @Prop() readonly ids!: string;

  id = '';

  projectGuidList: string[] = [];

  @Watch('id')
  async onProjectGuidChange(): Promise<void> {
    this.nonAwaitMounted();
    await this.awaitedMounted();

    this.$forceUpdate();
  }

  async mounted(): Promise<void> {
    await this.setProjectGuid();

    this.onProjectGuidChange();
  }

  async nonAwaitMounted(): Promise<void> {
    // REST calls that don't need awaited
    this.fetchProjectOverviewMetrics(this.id);
    this.fetchOverviewTotalCounts(this.id);
    this.fetchAssetCountsMetrics(this.id);
    this.fetchWorkOrderProjectMetrics(this.id);
    this.fetchDataDeliveryMetrics(this.id);
    this.fetchProjectTotalsData();
  }

  async awaitedMounted(): Promise<void> {
    // REST calls that need awaited
    await this.fetchProjectHeaders(this.projectGuidList);
  }

  async setProjectGuid(): Promise<void> {
    try {
      this.projectGuidList = JSON.parse(this.ids) as string[];
      // eslint-disable-next-line prefer-destructuring
      this.id = this.projectGuidList[0];
    } catch {
      this.id = this.ids;
      this.projectGuidList = [this.id];
    }
  }

  async fetchProjectTotalsData(): Promise<void> {
    if (this.id == null || this.id === '') {
      return;
    }
    this.fetchProjectTotals(this.id);
    const date = new Date().toLocaleDateString('en-US', { timeZone: 'America/New_York' });
    const crewCountsPayload: FetchCrewCountsPayload = {
      projectGuid: this.id,
      platformName: '',
      startDate: date,
      endDate: date,
    };
    this.fetchCrewCounts(crewCountsPayload);
  }

  get assetCountsMetricsLineSegmentTotal(): number {
    return this.assetCountsMetrics?.lineSegments?.total != null
      ? this.assetCountsMetrics.lineSegments.total : 0;
  }
}
